// @flow
import React, { cloneElement } from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import type { Node } from 'react';
import styles from './styles';

type Props = {
    action?: ?React$Element<any>,
    children?: Node,
    className?: string,
    variant?: 'error' | 'info' | 'success' | 'warning',
}

const useStyles = makeStyles(styles, { name: 'CallToAction' });

export default function CallToAction(props: Props) {
    const {
        action,
        children,
        className: classNameProp,
        variant,
        ...other
    } = props;

    const classes = useStyles(props);

    const className = cx(classNameProp, {
        [classes.error]: variant === 'error',
        [classes.info]: variant === 'info',
        [classes.success]: variant === 'success',
        [classes.warning]: variant === 'warning',
    });

    return (
        <div {...other} className={cx(className, classes.root)}>
            <div className={classes.text} {...other}>
                {children}
            </div>
            {action ? cloneElement(action, { className: classes.button }) : null}
        </div>
    );
}
