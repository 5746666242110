// @flow
export default (theme: Object) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    navigation: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(2, 0),
    },
    nextButton: {
        position: 'relative',
        '&:only-child': {
            marginLeft: 'auto',
        },
    },
    progress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});
