// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
// $FlowTypedIssue update react-redux libdef
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Button, Dialog, DialogTitle } from '@material-ui/core';
import {
    Clear as ClearIcon,
    Person as PersonIcon,
} from '@material-ui/icons';
import type { $AxiosError } from 'axios';
import getDisplayName from '../../utils/getDisplayName';
import {
    createEntitySelector,
    createWorkerRating,
    updateTickets,
    updateWorkerRating,
} from '../../ducks/gigwalk';
import { enqueue as enqueueSnackbar } from '../../ducks/snackbar';
import { register as registerDialog, unregister as unregisterDialog } from '../../ducks/dialog';
import ApprovalForm from './ApprovalForm';
import RatingForm from './RatingForm';
import styles from './styles';
import type { State as RootState } from '../../store';

type Props = {
    onClose: () => void,
    onSubmitFail: () => void,
    onSubmitSuccess: () => void,
    open: boolean,
    ticketId: ?number,
};

const ReviewSchema = yup.object().shape({
    approvalStatus: yup.string()
        .oneOf([null, 'APPROVED', 'REJECTED']),
    rating: yup.number()
        .nullable(),
    ticketId: yup.number(),
});

const useStyles = makeStyles(styles, { name: 'ReviewDialog' });

export default function ReviewDialog(props: Props) {
    const dispatch = useDispatch();
    const classes = useStyles(props);

    const [currentStep, setCurrentStep] = useState('reviewGig');

    const dialogProps = useSelector((state: RootState) => ({ ...props, ...state.dialog.registered[ReviewDialog.NAME] }));
    const ticket = useSelector((state: RootState) => {
        const getTicket = createEntitySelector('tickets', () => dialogProps.ticketId);
        return getTicket(state);
    });

    const {
        onClose,
        onSubmitFail,
        onSubmitSuccess,
        open,
        ticketId,
    } = dialogProps;

    const assigneeId = ticket ? ticket.assigned_customer_id : -1;
    const needsApproval = ticket && ticket.subscription ? ticket.subscription.needs_approval : false;
    const twoWayRatingEnabled = ticket && ticket.subscription ? ticket.subscription.two_way_rating_enabled : false;
    const needsPublicWorkforce = ticket && ticket.subscription ? ticket.subscription.needs_public_workforce : false;
    const displayName = ticket && ticket.customer
        ? getDisplayName(ticket.customer, { obfuscate: needsPublicWorkforce })
        : null;

    useEffect(() => {
        setCurrentStep(!needsApproval && twoWayRatingEnabled ? 'rateWorker' : 'reviewGig');
    }, [needsApproval, twoWayRatingEnabled]);

    useEffect(() => {
        dispatch(registerDialog(ReviewDialog.NAME));
        return () => {
            dispatch(unregisterDialog(ReviewDialog.NAME));
        };
    }, [dispatch]);

    return (
        <Formik
          enableReinitialize
          initialValues={{ ticketId }}
          onSubmit={(values, { setStatus, setSubmitting }) => {
              if (currentStep === 'reviewGig' && twoWayRatingEnabled) {
                  setCurrentStep('rateWorker');
                  setSubmitting(false);
              } else {
                  const { approvalStatus, rating } = values;
                  const promises = [];

                  if (needsApproval) {
                      const action = approvalStatus === 'APPROVED' ? 'approve' : 'reject';
                      const params = {
                          action,
                          ticket_ids: [ticketId],
                      };
                      promises.push(dispatch(updateTickets(params)));
                  }

                  if (twoWayRatingEnabled) {
                      const defaultRating = approvalStatus === 'APPROVED' ? 4 : 2;
                      const params = {
                          rating: rating || defaultRating,
                          ticket_id: ticketId,
                      };

                      if (ticket.rating != null) {
                          promises.push(dispatch(updateWorkerRating({ ...params, customer_id: assigneeId })));
                      } else {
                          promises.push(dispatch(createWorkerRating(params)));
                      }
                  }

                  return Promise.all(promises)
                      .then(() => {
                          // if (resp.original && resp.original.gw_api_response && resp.original.gw_api_response.length) {
                          //     const message = format(resp.original.gw_api_response);
                          //     dispatch(snackbar.actions.enqueue(message, { variant: 'warning' }));
                          // }
                          setStatus({ success: true });
                          onSubmitSuccess();
                      })
                      .catch((error: $AxiosError<Object>) => {
                          const resp = error ? error.response : null;
                          if (resp && resp.data && resp.data.gw_api_response) {
                              dispatch(enqueueSnackbar(resp.data.gw_api_response, { variant: 'error' }));
                          }
                          setStatus({ error });
                          onSubmitFail();
                          return Promise.reject(error);
                      });
              }
          }}
          validationSchema={ReviewSchema}
        >
            {(formikProps: Object) => {
                const { handleReset } = formikProps;
                const handleExited = () => {
                    setCurrentStep('reviewGig');
                    handleReset();
                };

                return (
                    <Dialog
                      classes={{ paper: classes.dialogPaper }}
                      fullWidth
                      maxWidth="xs"
                      onClose={onClose}
                      onExited={handleExited}
                      open={open}
                    >
                        <Button className={classes.cancel} color="inherit" onClick={onClose}>
                            <ClearIcon />
                        </Button>
                        <DialogTitle className={classes.dialogTitle} disableTypography>
                            <Avatar className={classes.avatar}>
                                <PersonIcon className={classes.avatarIcon} />
                            </Avatar>
                            <h1>{displayName}</h1>
                        </DialogTitle>
                        {currentStep === 'reviewGig'
                            ? <ApprovalForm {...formikProps} />
                            : <RatingForm {...formikProps} />}
                    </Dialog>
                );
            }}
        </Formik>
    );
}

ReviewDialog.NAME = 'review';

ReviewDialog.defaultProps = {
    onClose: () => {},
    onSubmitFail: () => {},
    onSubmitSuccess: () => {},
    open: false,
    ticketId: null,
};
