// @flow
const namespace = 'g2/snackbar';

export const ENQUEUE_SNACKBAR = `${namespace}/ENQUEUE`;
export const REMOVE_SNACKBAR = `${namespace}/REMOVE`;

export default {
    ENQUEUE_SNACKBAR,
    REMOVE_SNACKBAR,
};
