// @flow
import { handleActions } from 'redux-actions';
import type { ActionType } from 'redux-actions';
import types from './types';
import typeof {
    fetchAllSubscriptionsSuccess,
    fetchTicketStatsSuccess,
    resolveAddressSuccess,
    unsubscribeSuccess,
} from './actions';

export type State = {
    activeTicketCount: number,
    addressLine1: string,
    addressLine2: string,
    city: string,
    state: string,
    submittedTicketCount: number,
    subscriptionIds: number[],
    zipCode: string,
};

export const init: State = {
    activeTicketCount: 0,
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    submittedTicketCount: 0,
    subscriptionIds: [],
    zipCode: '',
};

export default handleActions({
    [types.RESOLVE_ADDRESS_SUCCESS]: (state: State, action: ActionType<resolveAddressSuccess>) => ({
        ...state,
        ...action.payload,
    }),
    [types.FETCH_TICKET_STATS_SUCCESS]: (state: State, action: ActionType<fetchTicketStatsSuccess>) => ({
        ...state,
        ...action.payload,
    }),
    [types.FETCH_ALL_SUBSCRIPTIONS_SUCCESS]: (state: State, action: ActionType<fetchAllSubscriptionsSuccess>) => ({
        ...state,
        subscriptionIds: action.payload,
    }),
    [types.UNSUBSCRIBE_SUCCESS]: (state: State, action: ActionType<unsubscribeSuccess>) => ({
        ...state,
        subscriptionIds: state.subscriptionIds.filter((id: number) => id !== action.payload),
    }),
}, init);
