// @flow
import React, { Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
    Avatar,
    Card,
    CardHeader,
} from '@material-ui/core';
import {
    AssignmentInd as AssignmentIndIcon,
    AssignmentLate as AssignmentLateIcon,
    AssignmentReturn as AssignmentReturnIcon,
    AssignmentTurnedIn as AssignmentTurnedInIcon,
    AttachMoney as AttachMoneyIcon,
    Cancel as CancelIcon,
    Edit as EditIcon,
    Forward as ForwardIcon,
    Group as GroupIcon,
    HourglassEmpty as HourglassEmptyIcon,
    PlayArrow as PlayArrowIcon,
    Schedule as ScheduleIcon,
    Star as StarIcon,
    ThumbDown as ThumbDownIcon,
    ThumbUp as ThumbUpIcon,
} from '@material-ui/icons';
import { Rocket as RocketIcon } from 'mdi-material-ui';
import getDisplayName from '../../../../utils/getDisplayName';
import styles from './styles';

type Props = {
    data: Object,
    date: string,
    type: string,
};

const iconsByEventType = {
    CREATED: RocketIcon,
    CANCELED: CancelIcon,
    STARTED: PlayArrowIcon,
    SUBMITTED: AssignmentTurnedInIcon,
    SCHEDULED: ScheduleIcon,
    UNSCHEDULED: ScheduleIcon,
    EXTENDED: ForwardIcon,
    ASSIGNED: AssignmentIndIcon,
    UNASSIGNED: AssignmentLateIcon,
    AUTO_ASSIGNED: AssignmentIndIcon,
    AUTO_UNASSIGNED: AssignmentLateIcon,
    'GROUPS CHANGED': GroupIcon,
    'START DATE CHANGED IN PROJECT': EditIcon,
    'DUE DATE CHANGED IN PROJECT': EditIcon,
    'TIME ESTIMATE CHANGED IN PROJECT': EditIcon,
    START_DATE_EDITED: EditIcon,
    END_DATE_EDITED: EditIcon,
    TIME_ESTIMATE_EDITED: EditIcon,
    APPROVED: ThumbUpIcon,
    REJECTED: ThumbDownIcon,
    REOPENED: AssignmentReturnIcon,
    SUBMIT_DEADLINE_EDITED: EditIcon,
    RATED: StarIcon,
    PAID: AttachMoneyIcon,
    DEADLINE_SET: HourglassEmptyIcon,
};

const useStyles = makeStyles(styles, { name: 'TicketEvent' });

export default function TicketEvent(props: Props) {
    const { data, date, type } = props;

    const classes = useStyles(props);
    const { t } = useTranslation();

    const renderContent = () => {
        switch (type) {
            case 'APPROVED': {
                const { createdCustomer } = data;
                const customer = getDisplayName(createdCustomer);
                const customerProfile = createdCustomer.profile_link;
                return (
                    <Trans
                      defaults={t('ticketDetail.comments.workApproved', { customer })}
                      components={[
                          customerProfile ? <Link to={customerProfile}>customer</Link> : <span>customer</span>,
                          'approved the work done for this gig',
                      ]}
                    />
                );
            }

            case 'ASSIGNED': {
                const { assignedCustomer, createdCustomer } = data;
                const customer = getDisplayName(createdCustomer);
                const customerProfile = createdCustomer.profile_link;
                const isSelfAssigned = createdCustomer.id === assignedCustomer.id;
                const assignee = isSelfAssigned ? t('glossary.themselves') : getDisplayName(assignedCustomer);
                const assigneeProfile = isSelfAssigned ? null : assignedCustomer.profile_link;
                return (
                    <Trans
                      defaults={t('ticketDetail.comments.gigAssigned', { customer, assignee })}
                      components={[
                          customerProfile ? <Link to={customerProfile}>customer</Link> : <span>customer</span>,
                          'assigned this gig to',
                          assigneeProfile ? <Link to={assigneeProfile}>assignee</Link> : <span>assignee</span>,
                      ]}
                    />
                );
            }

            case 'AUTO_ASSIGNED': {
                const { assignedCustomer } = data;
                const assignee = getDisplayName(assignedCustomer);
                const assigneeProfile = assignedCustomer.profile_link;
                return (
                    <Trans
                      defaults={t('ticketDetail.comments.gigAutoAssigned', { assignee })}
                      components={[
                          'This gig was auto-assigned to',
                          assigneeProfile ? <Link to={assigneeProfile}>assignee</Link> : <span>assignee</span>,
                      ]}
                    />
                );
            }

            case 'CANCELED': {
                const { createdCustomer } = data;
                const customer = getDisplayName(createdCustomer);
                const customerProfile = createdCustomer.profile_link;
                return (
                    <Trans
                      defaults={t('ticketDetail.comments.gigCanceled', { customer })}
                      components={[
                          customerProfile ? <Link to={customerProfile}>customer</Link> : <span>customer</span>,
                          'canceled this gig',
                      ]}
                    />
                );
            }

            case 'DEADLINE_SET': {
                const { assignedCustomer, deadline, triggerEvent } = data;
                const assignee = getDisplayName(assignedCustomer);
                const assigneeProfile = assignedCustomer.profile_link;
                const i18nKey = triggerEvent === 'extend'
                    ? 'ticketDetail.comments.deadlineExtended'
                    : 'ticketDetail.comments.deadlineSet';
                return (
                    <Trans
                      defaults={t(i18nKey, { assignee, deadline })}
                      components={[
                          assigneeProfile ? <Link to={assigneeProfile}>assignee</Link> : <span>assignee</span>,
                          'reservation window ends at',
                          <span>deadline</span>,
                      ]}
                    />
                );
            }

            case 'END_DATE_EDITED':
            case 'DUE DATE CHANGED IN PROJECT': {
                const { createdCustomer, newEndDate, oldEndDate } = data;
                const customer = getDisplayName(createdCustomer);
                const customerProfile = createdCustomer.profile_link;
                return (
                    <Trans
                      defaults={t('ticketDetail.comments.endDateChanged', { customer, newEndDate, oldEndDate })}
                      components={[
                          customerProfile ? <Link to={customerProfile}>customer</Link> : <span>customer</span>,
                          'changed the end date from',
                          <span>originalEndDate</span>,
                          'to',
                          <span>newEndDate</span>,
                      ]}
                    />
                );
            }

            case 'EXTENDED': {
                const { createdCustomer, extendedDate, extendedFromDate } = data;
                const customer = getDisplayName(createdCustomer);
                const customerProfile = createdCustomer.profile_link;
                return (
                    <Trans
                      defaults={t('ticketDetail.comments.gigExtended', { customer, extendedDate, extendedFromDate })}
                      components={[
                          customerProfile ? <Link to={customerProfile}>customer</Link> : <span>customer</span>,
                          'extended this gig from',
                          <span>extendedFromDate</span>,
                          'to',
                          <span>extendedDate</span>,
                      ]}
                    />
                );
            }

            case 'GROUPS CHANGED': {
                const { createdCustomer, groupsAdded, groupsRemoved } = data;
                const customer = getDisplayName(createdCustomer);
                const customerProfile = createdCustomer.profile_link;

                let i18nKey = 'ticketDetail.comments.groupsChanged';
                if (groupsAdded.length === 0) {
                    i18nKey = 'ticketDetail.comments.groupsRemoved';
                } else if (groupsRemoved.length === 0) {
                    i18nKey = 'ticketDetail.comments.groupsAdded';
                }

                const join = (list: string, item: string, index: number, arr: string[]) => {
                    if (index === 0) {
                        return item;
                    }
                    return index === arr.length - 1
                        ? t('format.joinLast', { list, item })
                        : t('format.join', { list, item });
                };

                return (
                    <Trans
                      defaults={t(i18nKey, { customer, groupsAdded: groupsAdded.reduce(join, ''), groupsRemoved: groupsRemoved.reduce(join, '') })}
                      components={[
                          customerProfile ? <Link to={customerProfile}>customer</Link> : <span>customer</span>,
                          'updated groups',
                      ]}
                    />
                );
            }

            case 'PAID': {
                const { amount, createdCustomer } = data;
                const customer = getDisplayName(createdCustomer);
                const customerProfile = createdCustomer.profile_link;
                return (
                    <Trans
                      defaults={t('ticketDetail.comments.workerPaid', { amount, customer })}
                      components={[
                          customerProfile ? <Link to={customerProfile}>customer</Link> : <span>customer</span>,
                          'paid out',
                          <span>amount</span>,
                          'for completing this gig',
                      ]}
                    />
                );
            }

            case 'RATED': {
                const { createdCustomer, rating } = data;
                const customer = getDisplayName(createdCustomer);
                const customerProfile = createdCustomer.profile_link;
                return (
                    <Trans
                      defaults={t('ticketDetail.comments.workerRated', { customer, rating })}
                      components={[
                          customerProfile ? <Link to={customerProfile}>customer</Link> : <span>customer</span>,
                          'submitted a worker rating of',
                          <span>rating</span>,
                          'stars',
                      ]}
                    />
                );
            }

            case 'SCHEDULED': {
                const { createdCustomer, scheduledDate } = data;
                const customer = getDisplayName(createdCustomer);
                const customerProfile = createdCustomer.profile_link;
                return (
                    <Trans
                      defaults={t('ticketDetail.comments.gigScheduled', { customer, scheduledDate })}
                      components={[
                          customerProfile ? <Link to={customerProfile}>customer</Link> : <span>customer</span>,
                          'scheduled this gig for',
                          <span>scheduledDate</span>,
                      ]}
                    />
                );
            }

            case 'START_DATE_EDITED':
            case 'START DATE CHANGED IN PROJECT': {
                const { createdCustomer, newStartDate, oldStartDate } = data;
                const customer = getDisplayName(createdCustomer);
                const customerProfile = createdCustomer.profile_link;
                return (
                    <Trans
                      defaults={t('ticketDetail.comments.startDateChanged', { customer, newStartDate, oldStartDate })}
                      components={[
                          customerProfile ? <Link to={customerProfile}>customer</Link> : <span>customer</span>,
                          'changed the start date from',
                          <span>oldStartDate</span>,
                          'to',
                          <span>newStartDate</span>,
                      ]}
                    />
                );
            }

            case 'SUBMIT_DEADLINE_EDITED': {
                const { createdCustomer, newEndDate, oldEndDate } = data;
                const customer = getDisplayName(createdCustomer);
                const customerProfile = createdCustomer.profile_link;
                return (
                    <Trans
                      defaults={t('ticketDetail.comments.submitDeadlineChanged', { customer, newEndDate, oldEndDate })}
                      components={[
                          customerProfile ? <Link to={customerProfile}>customer</Link> : <span>customer</span>,
                          'updated the submit deadline from',
                          <span>originalEndDate</span>,
                          'to',
                          <span>newEndDate</span>,
                      ]}
                    />
                );
            }

            case 'TIME_ESTIMATE_EDITED':
            case 'TIME ESTIMATE CHANGED IN PROJECT': {
                const { createdCustomer } = data;
                const customer = getDisplayName(createdCustomer);
                const customerProfile = createdCustomer.profile_link;
                // Convert from seconds to minutes
                const newTimeEstimate = data.newTimeEstimate / 60;
                const oldTimeEstimate = data.oldTimeEstimate / 60;
                return (
                    <Trans
                      defaults={t('ticketDetail.comments.timeEstimateChanged', { customer, newTimeEstimate, oldTimeEstimate })}
                      components={[
                          customerProfile ? <Link to={customerProfile}>customer</Link> : <span>customer</span>,
                          'updated the time estimate from',
                          <span>oldTimeEstimate</span>,
                          'to',
                          <span>newTimeEstimate</span>,
                      ]}
                    />
                );
            }

            case 'REOPENED': {
                const { createdCustomer } = data;
                const customer = getDisplayName(createdCustomer);
                const customerProfile = createdCustomer.profile_link;
                return (
                    <Trans
                      defaults={t('ticketDetail.comments.gigReopened', { customer })}
                      components={[
                          customerProfile ? <Link to={customerProfile}>customer</Link> : <span>customer</span>,
                          'reopened this gig',
                      ]}
                    />
                );
            }

            case 'STARTED': {
                const { createdCustomer } = data;
                const customer = getDisplayName(createdCustomer);
                const customerProfile = createdCustomer.profile_link;
                return (
                    <Trans
                      defaults={t('ticketDetail.comments.gigStarted', { customer })}
                      components={[
                          customerProfile ? <Link to={customerProfile}>customer</Link> : <span>customer</span>,
                          'started this gig',
                      ]}
                    />
                );
            }

            case 'SUBMITTED': {
                const { createdCustomer } = data;
                const customer = getDisplayName(createdCustomer);
                const customerProfile = createdCustomer.profile_link;
                return (
                    <Trans
                      defaults={t('ticketDetail.comments.gigSubmitted', { customer })}
                      components={[
                          customerProfile ? <Link to={customerProfile}>customer</Link> : <span>customer</span>,
                          'submitted this gig',
                      ]}
                    />
                );
            }

            case 'UNASSIGNED': {
                const { createdCustomer } = data;

                if (!createdCustomer || createdCustomer.id === 0) {
                    return t('ticketDetail.comments.gigAutoUnassigned');
                }

                const customer = getDisplayName(createdCustomer);
                const customerProfile = createdCustomer.profile_link;
                return (
                    <Trans
                      defaults={t('ticketDetail.comments.gigUnassigned', { customer })}
                      components={[
                          customerProfile ? <Link to={customerProfile}>customer</Link> : <span>customer</span>,
                          'unassigned this gig',
                      ]}
                    />
                );
            }

            case 'UNSCHEDULED': {
                const { createdCustomer } = data;
                const customer = getDisplayName(createdCustomer);
                const customerProfile = createdCustomer.profile_link;
                return (
                    <Trans
                      defaults={t('ticketDetail.comments.gigUnscheduled', { customer })}
                      components={[
                          customerProfile ? <Link to={customerProfile}>customer</Link> : <span>customer</span>,
                          'unscheduled this gig',
                      ]}
                    />
                );
            }

            case 'REJECTED':
                return t('ticketDetail.comments.workRejected');

            case 'AUTO_UNASSIGNED':
                return t('ticketDetail.comments.gigAutoUnassigned');

            case 'CREATED':
                return t('ticketDetail.comments.gigLaunched');

            default:
                break;
        }
    };

    const EventIcon = iconsByEventType[type];
    const title = (
        <Fragment>
            <span className={classes.author}>Gigwalk</span>
            <span className={classes.date}>{date}</span>
        </Fragment>
    );

    return (
        <Card elevation={0}>
            <CardHeader
              classes={{
                  avatar: classes.avatar,
                  title: classes.header,
                  subheader: classes.content,
              }}
              avatar={<Avatar><EventIcon /></Avatar>}
              title={title}
              subheader={renderContent()}
            />
        </Card>
    );
}
