// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useRef } from 'react';
// $FlowTypedIssue need to update the react-dnd libdef
import { useDrag, useDrop } from 'react-dnd';
import { IconButton } from '@material-ui/core';
import { Reorder as ReorderIcon } from '@material-ui/icons';
import type { DragSourceMonitor, DropTargetMonitor } from 'react-dnd';
import QuestionCard from './QuestionCard';

type Props = {
    description: string,
    dragButton?: React$Element<*>,
    draggable: boolean,
    expanded?: boolean,
    formKey: string, // the unique key that identifies this question in the form
    icon: string,
    id: number | string, // the dataType id
    isDragging?: boolean,
    index: number,
    moveCard: (indexA: number, indexB: number) => void,
    onClick: () => void,
    onDelete: () => void,
    onDuplicate: () => void,
    questionText: string,
    saving: boolean,
    valueType: string,
};

export function DraggableQuestionCard(props: Props) {
    const { draggable, id, index, moveCard, ...other } = props;

    const cardRef = useRef(null);

    const [, drop] = useDrop({
        accept: 'CARD',
        hover(item: Object, monitor: DropTargetMonitor) {
            const card = cardRef.current;

            if (!card) {
                return;
            }

            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Determine rectangle on screen
            const hoverBoundingRect = card.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex);

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations, but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex; // eslint-disable-line no-param-reassign
        },
    });

    const [cardProps, drag, preview] = useDrag({
        item: {
            id,
            index,
            type: 'CARD',
        },
        collect: (monitor: DragSourceMonitor) => ({
            isDragging: monitor.isDragging(),
        }),
        canDrag: () => draggable,
    });

    preview(drop(cardRef));

    return (
        <div ref={cardRef}>
            <QuestionCard
              {...other}
              {...cardProps}
              dragButton={(
                  <IconButton component="div" disableRipple ref={(node) => drag(node)}>
                      <ReorderIcon />
                  </IconButton>
              )}
              id={id}
              index={index}
            />
        </div>
    );
}

export default DraggableQuestionCard;
