// @flow
const namespace = 'g2/profile';

export const LOAD_CUSTOMER = `${namespace}/LOAD_CUSTOMER`;
export const LOAD_CUSTOMER_SUCCESS = `${LOAD_CUSTOMER}_SUCCESS`;
export const LOAD_CUSTOMER_ERROR = `${LOAD_CUSTOMER}_ERROR`;

export const FETCH_TICKET_STATS = `${namespace}/FETCH_TICKET_STATS`;
export const FETCH_TICKET_STATS_SUCCESS = `${FETCH_TICKET_STATS}_SUCCESS`;
export const FETCH_TICKET_STATS_ERROR = `${FETCH_TICKET_STATS}_ERROR`;

export const UPDATE_ACCOUNT = `${namespace}/UPDATE_ACCOUNT`;
export const UPDATE_ACCOUNT_SUCCESS = `${UPDATE_ACCOUNT}_SUCCESS`;
export const UPDATE_ACCOUNT_ERROR = `${UPDATE_ACCOUNT}_ERROR`;

export const SAVE_PREFERENCES = `${namespace}/SAVE_PREFERENCES`;
export const SAVE_PREFERENCES_SUCCESS = `${SAVE_PREFERENCES}_SUCCESS`;
export const SAVE_PREFERENCES_ERROR = `${SAVE_PREFERENCES}_ERROR`;

export const UNSUBSCRIBE = `${namespace}/UNSUBSCRIBE`;
export const UNSUBSCRIBE_SUCCESS = `${UNSUBSCRIBE}_SUCCESS`;
export const UNSUBSCRIBE_ERROR = `${UNSUBSCRIBE}_ERROR`;

export const FETCH_ALL_SUBSCRIPTIONS = `${namespace}/FETCH_ALL_SUBSCRIPTIONS`;
export const FETCH_ALL_SUBSCRIPTIONS_SUCCESS = `${FETCH_ALL_SUBSCRIPTIONS}_SUCCESS`;
export const FETCH_ALL_SUBSCRIPTIONS_ERROR = `${FETCH_ALL_SUBSCRIPTIONS}_ERROR`;

export const RESOLVE_ADDRESS = `${namespace}/RESOLVE_ADDRESS`;
export const RESOLVE_ADDRESS_SUCCESS = `${RESOLVE_ADDRESS}_SUCCESS`;
export const RESOLVE_ADDRESS_ERROR = `${RESOLVE_ADDRESS}_ERROR`;

export default {
    FETCH_ALL_SUBSCRIPTIONS,
    FETCH_ALL_SUBSCRIPTIONS_SUCCESS,
    FETCH_ALL_SUBSCRIPTIONS_ERROR,
    FETCH_TICKET_STATS,
    FETCH_TICKET_STATS_ERROR,
    FETCH_TICKET_STATS_SUCCESS,
    LOAD_CUSTOMER,
    LOAD_CUSTOMER_ERROR,
    LOAD_CUSTOMER_SUCCESS,
    RESOLVE_ADDRESS,
    RESOLVE_ADDRESS_ERROR,
    RESOLVE_ADDRESS_SUCCESS,
    SAVE_PREFERENCES,
    SAVE_PREFERENCES_ERROR,
    SAVE_PREFERENCES_SUCCESS,
    UNSUBSCRIBE,
    UNSUBSCRIBE_ERROR,
    UNSUBSCRIBE_SUCCESS,
    UPDATE_ACCOUNT,
    UPDATE_ACCOUNT_ERROR,
    UPDATE_ACCOUNT_SUCCESS,
};
