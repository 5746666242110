// @flow
import { createAction } from 'redux-actions';
import types from './types';

export const open = createAction(types.OPEN_DIALOG, (name: string, props: Object) => ({ name, props }));
export const close = createAction(types.CLOSE_DIALOG);
export const register = createAction(types.REGISTER_DIALOG);
export const unregister = createAction(types.UNREGISTER_DIALOG);

export default {
    close,
    open,
    register,
    unregister,
};
