// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useEffect, useMemo } from 'react';
import cx from 'classnames';
import moment from 'moment';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
// $FlowTypedIssue update react-redux libdef
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import {
    DateRange as DateRangeIcon,
    Error as ErrorIcon,
    Warning as WarningIcon,
} from '@material-ui/icons';
import type { $AxiosError } from 'axios';
import { createEntitySelector, updateTickets } from '../../ducks/gigwalk';
import { enqueue as enqueueSnackbar } from '../../ducks/snackbar';
import { register as registerDialog, unregister as unregisterDialog } from '../../ducks/dialog';
import SubmitButton from '../SubmitButton';
import FormikDateTimePicker from '../FormikDateTimePicker';
import styles from './styles';
import type { State as RootState } from '../../store';

type Props = {
    onClose: () => void,
    onSubmitFail: () => void,
    onSubmitSuccess: () => void,
    open: boolean,
    ticketIds: number[],
}

const ExtendSchema = yup.object().shape({
    dueDate: yup.date()
        .test({
            message: '${path} field must be in the future', // eslint-disable-line no-template-curly-in-string
            name: 'futureDate',
            test(value) {
                const date = moment(value);
                return date.isSameOrAfter(moment());
            },
        })
        .required('validation.required'),
    ticketIds: yup.array().of(yup.number()),
});

const useStyles = makeStyles(styles, { name: 'ExtendDialog' });

export default function ExtendDialog(props: Props) {
    const dispatch = useDispatch();
    const classes = useStyles(props);
    const { t } = useTranslation();

    const dialogProps = useSelector((state: RootState) => ({ ...props, ...state.dialog.registered[ExtendDialog.NAME] }));
    const extendableTicketIds = useSelector((state: RootState) => {
        const getTickets = createEntitySelector('tickets', () => dialogProps.ticketIds);
        const tickets = getTickets(state);
        return tickets.reduce((ids: number[], ticket: ?Object) => (
            ticket && ticket.can_reschedule ? [...ids, ticket.id] : ids
        ), []);
    });

    const {
        onClose,
        onSubmitFail,
        onSubmitSuccess,
        open,
        ticketIds,
    } = dialogProps;

    useEffect(() => {
        dispatch(registerDialog(ExtendDialog.NAME));
        return () => {
            dispatch(unregisterDialog(ExtendDialog.NAME));
        };
    }, [dispatch]);

    const extendWarning = useMemo(() => {
        // @todo There may be other conditions that prevent tickets from being extended
        const canExtendAllTickets = extendableTicketIds.length === ticketIds.length;
        return !canExtendAllTickets
            ? 'Some of the selected tickets cannot be extended'
            : undefined;
    }, [extendableTicketIds, ticketIds]);

    const extendError = useMemo(() => {
        // @todo There may be other conditions that prevent tickets from being extended
        const canExtendSomeTickets = extendableTicketIds.length > 0;
        return !canExtendSomeTickets
            ? 'The tickets you selected cannot be extended'
            : undefined;
    }, [extendableTicketIds]);

    const displayWarning = !extendError && extendWarning;
    const displayError = !!extendError;

    const errorMessage = (
        <DialogContentText className={classes.error}>
            <ErrorIcon className={classes.icon} />
            <span>{extendError}</span>
        </DialogContentText>
    );

    const warningMessage = (
        <DialogContentText className={classes.warning}>
            <WarningIcon className={classes.icon} />
            <span>{extendWarning}</span>
        </DialogContentText>
    );

    return (
        <Formik
          enableReinitialize
          initialValues={{ ticketIds: extendableTicketIds }}
          onSubmit={(values, { setStatus }) => {
              const { dueDate, submitDeadline } = values;
              const params = {
                  ...(submitDeadline ? { submit_deadline: moment(submitDeadline).format('YYYY-MM-DDTHH:mm:ss') } : undefined),
                  action: 'edit',
                  due_date: moment(dueDate).format('YYYY-MM-DDTHH:mm:ss'),
                  ticket_ids: ticketIds,
              };
              return dispatch(updateTickets(params))
                  .then((resp: Object) => {
                      if (resp.original && resp.original.gw_api_response) {
                          dispatch(enqueueSnackbar(resp.original.gw_api_response, { variant: 'warning' }));
                      }
                      setStatus({ success: true });
                      onSubmitSuccess();
                  })
                  .catch((error: $AxiosError<Object>) => {
                      const resp = error ? error.response : null;
                      if (resp && resp.data && resp.data.gw_api_response) {
                          dispatch(enqueueSnackbar(resp.data.gw_api_response, { variant: 'error' }));
                      }
                      setStatus({ error });
                      onSubmitFail();
                      return Promise.reject(error);
                  });
          }}
          validationSchema={ExtendSchema}
        >
            {(formikProps: Object) => {
                const { isSubmitting, isValid, handleReset, handleSubmit } = formikProps;
                return (
                    <Dialog open={open} onClose={onClose} onExited={handleReset}>
                        <Form onSubmit={handleSubmit}>
                            <DialogTitle>{t('extendDialog.title')}</DialogTitle>
                            <DialogContent className={cx({ [classes.disablePaddingBottom]: displayError || displayWarning })}>
                                <DialogContentText>
                                    {t('extendDialog.text', { count: ticketIds.length })}
                                </DialogContentText>
                                <FormikDateTimePicker
                                  name="dueDate"
                                  label={t('extendDialog.dueDate')}
                                  customInputIcon={<DateRangeIcon />}
                                />
                                {displayWarning ? warningMessage : null}
                                {displayError ? errorMessage : null}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={onClose}>
                                    {t('extendDialog.cancel')}
                                </Button>
                                <SubmitButton
                                  className={classes.submitButton}
                                  color="primary"
                                  disabled={isSubmitting || !isValid}
                                  submitting={isSubmitting}
                                  type="submit"
                                  variant="contained"
                                >
                                    {t('extendDialog.confirm')}
                                </SubmitButton>
                            </DialogActions>
                        </Form>
                    </Dialog>
                );
            }}
        </Formik>
    );
}

ExtendDialog.NAME = 'extend';

ExtendDialog.defaultProps = {
    onClose: () => {},
    onSubmitFail: () => {},
    onSubmitSuccess: () => {},
    open: false,
    ticketIds: [],
};
