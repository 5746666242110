// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useMemo } from 'react';
import GeoPoint from 'geopoint';
import { Tooltip } from '@material-ui/core';
import { Place as PlaceIcon } from '@material-ui/icons';

type Props = {
    dataItem: Object,
    gigLocation: {
        latitude: number,
        longitude: number,
    },
};

export default function LocationTooltip(props: Props) {
    const { dataItem, gigLocation } = props;

    const distanceFromGig = useMemo(() => {
        const {
            data_item_latitude: latitude,
            data_item_longitude: longitude,
        } = dataItem;

        if (latitude != null && longitude != null) {
            const p1 = new GeoPoint(latitude, longitude);
            const p2 = new GeoPoint(gigLocation.latitude, gigLocation.longitude);
            return Math.round(p1.distanceTo(p2) * 100) / 100;
        }

        return null;
    }, [dataItem, gigLocation]);

    if (!distanceFromGig) {
        return null;
    }

    return (
        <Tooltip title={`Answer was submitted ${distanceFromGig} miles from gig location`} placement="left">
            <PlaceIcon fontSize="small" htmlColor="#8e9499" />
        </Tooltip>
    );
}
