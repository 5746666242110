var Handlebars = require('/tmp/build_a9d70681abb25b82ca4489607bd6ccee/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"ti\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":1,"column":16},"end":{"line":1,"column":27}}}) : helper)))
    + "</div>\n<div class=\"description\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"select_target") || (depth0 != null ? lookupProperty(depth0,"select_target") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"select_target","hash":{},"data":data,"loc":{"start":{"line":2,"column":25},"end":{"line":2,"column":44}}}) : helper)))
    + "</div>\n<div class=\"type dropdown\">\n    <div data-target=\"targetDropdown\"></div>\n</div>\n\n";
},"useData":true});