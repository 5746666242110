// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback } from 'react';
import { Form } from 'formik';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import {
    Star as StarIcon,
    ThumbUp as ThumbUpIcon,
} from '@material-ui/icons';
import type { Connector } from 'react-redux';
import type { ContextRouter } from 'react-router';
import ToggleButton from '../../../../components/ToggleButton';
import entitySelector from '../../../../redux/entities/entitySelector';
import Step from '../../components/Step';
import styles from './styles';
import type { State as RootState } from '../../../../redux/initialState';

type OwnProps = ContextRouter & {
    setFieldValue: (field: string, value: any) => void,
    values: Object,
};
type StateProps = {
    editMode: boolean,
};
type Props = OwnProps & StateProps;

const useStyles = makeStyles(styles, { name: 'Approval' });

export function Approval(props: Props) {
    const { editMode, setFieldValue, values } = props;

    const classes = useStyles(props);
    const { t } = useTranslation();

    const handleChange = useCallback((event: SyntheticEvent<any>, selected: boolean) => {
        const { name } = event.currentTarget;
        setFieldValue(name, selected);
    }, [setFieldValue]);

    return (
        <Step
          title={t('projectBuilder.people.approval.header')}
          subheader={t('projectBuilder.people.approval.subheader')}
        >
            <Form className={classes.form}>
                <Typography className={classes.label} align="center">
                    {t('projectBuilder.people.approval.selectAny')}
                </Typography>
                <div className={classes.grid}>
                    <ToggleButton
                      classes={{
                          root: classes.toggleButton,
                          label: classes.toggleButtonLabel,
                          selected: classes.selected,
                      }}
                      color="primary"
                      disabled={editMode}
                      name="needsApproval"
                      onChange={handleChange}
                      selected={values.needsApproval === true}
                    >
                        <ThumbUpIcon className={classes.icon} />
                        <p className={classes.caption}>
                            {t('projectBuilder.people.approval.approveWork')}
                        </p>
                    </ToggleButton>
                    <ToggleButton
                      classes={{
                          root: classes.toggleButton,
                          label: classes.toggleButtonLabel,
                          selected: classes.selected,
                      }}
                      color="primary"
                      disabled={editMode}
                      name="twoWayRatingEnabled"
                      onChange={handleChange}
                      selected={values.twoWayRatingEnabled === true}
                    >
                        <StarIcon className={classes.icon} />
                        <p className={classes.caption}>
                            {t('projectBuilder.people.approval.rateWorker')}
                        </p>
                    </ToggleButton>
                </div>
            </Form>
        </Step>
    );
}

const subscriptionSelector = entitySelector('subscriptions');
const mapStateToProps = (state: RootState, props: OwnProps): StateProps => {
    const { match } = props;
    return {
        editMode: subscriptionSelector(state, match.params.subscriptionId, 'state') === 'ACTIVE',
    };
};

const connector: Connector<OwnProps, Props> = connect(mapStateToProps);
export default withRouter(connector(Approval));
