// @flow
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'react-dates/initialize';
// import 'cleave.js/dist/addons/cleave-phone.i18n';
import React from 'react';
import moment from 'moment';
import merge from 'lodash/merge';
// $FlowIssue https://github.com/facebook/flow/issues/5035
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { ConnectedRouter } from 'connected-react-router';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@material-ui/styles';
// $FlowFixMe need to update react-dnd libdef
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import ThemedStyleSheet from 'react-with-styles/lib/ThemedStyleSheet';
import cssInterface from 'react-with-styles-interface-css';
import DefaultTheme from 'react-dates/lib/theme/DefaultTheme';
import typeof $i18next from 'i18next';
import './vendors';
import './shared/plugins/handlebars-helpers';
import logger from '../common/util/logger';
import configureStore from '../common/store';
import initI18next from '../common/util/i18n-client';
import App from '../common/containers/App';
import * as session from '../common/ducks/session';
import theme from '../styles/theme';

const GWP_CLIENT_NAMESPACE = process.env.GWP_CLIENT_NAMESPACE || '';
const NODE_ENV = process.env.NODE_ENV || '';
const DISABLE_SERVICE_WORKER = process.env.DISABLE_SERVICE_WORKER && process.env.DISABLE_SERVICE_WORKER === 'true';

const initialStateEl = document.getElementById('initialState');
let initialState: Object;

// istanbul ignore if
if (initialStateEl) {
    try {
        // We use eval here since initialState was serialized using serialize-javascript. This module
        // ensures serialized content is safe to embed using a <script> tag
        initialState = eval(`(${initialStateEl.innerHTML})`); // eslint-disable-line no-eval
    } catch (e) {
        logger.error(e);
    }
} else {
    logger.warn('Could not find the initial state script with id "initialState"');
}

const { store, history } = configureStore({ initialState });
const rootElement = document.getElementById('app');

// Prevent this from running in unit tests
// istanbul ignore if
if (NODE_ENV !== 'test') {
    if (NODE_ENV === 'production' && !DISABLE_SERVICE_WORKER) {
        require('offline-plugin/runtime').install(); // eslint-disable-line global-require
    }

    if (initialState && initialState.session.user != null) {
        store.dispatch(session.actions.start());
    }

    initI18next.then((i18n: $i18next) => {
        logger.trace('Render login app on root node.');

        const locale = i18n.language;
        moment.locale(locale);

        const mainBundle = i18n.getResourceBundle(locale, 'app');
        const clientBundle = GWP_CLIENT_NAMESPACE ? i18n.getResourceBundle(locale, GWP_CLIENT_NAMESPACE) : {};
        global.GW = {
            ...global.GW,
            localisation: merge({}, mainBundle, clientBundle),
        };

        ThemedStyleSheet.registerInterface(cssInterface);
        ThemedStyleSheet.registerTheme(DefaultTheme);

        if (rootElement) {
            hydrate(
                <Provider store={store}>
                    <I18nextProvider i18n={i18n}>
                        <ThemeProvider theme={theme}>
                            <SnackbarProvider hideIconVariant preventDuplicate>
                                <DndProvider backend={HTML5Backend}>
                                    <ConnectedRouter history={history}>
                                        <App />
                                    </ConnectedRouter>
                                </DndProvider>
                            </SnackbarProvider>
                        </ThemeProvider>
                    </I18nextProvider>
                </Provider>,
                rootElement
            );
        }
    }).catch((err: Error) => { logger.error(err); });
}
