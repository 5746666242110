// @flow
// $FlowIssue need to update to a more recent flow version
import React, { forwardRef } from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { Button, CircularProgress, Fab } from '@material-ui/core';
import type { Node } from 'react';
import styles from './styles';

type Props = {
    children: Node,
    classes?: Object,
    disabled?: boolean,
    submitting?: boolean,
    variant?: string,
};

const useStyles = makeStyles(styles, { name: 'SubmitButton' });

export const SubmitButton = forwardRef((props: Props, ref: Object) => {
    const {
        children,
        classes: classesProp,
        disabled,
        submitting,
        variant,
        ...other
    } = props;

    const { fabProgress, buttonProgress, ...classes } = useStyles(props);

    const ButtonComponent = variant === 'fab' ? Fab : Button;
    const buttonProps = {
        classes,
        disabled: submitting || disabled,
        ...(variant !== 'fab' ? { variant } : undefined),
        ...other,
    };
    const progressProps = {
        className: cx({
            [fabProgress]: variant === 'fab',
            [buttonProgress]: variant !== 'fab',
        }),
        size: variant === 'fab' ? 60 : 24,
    };

    return (
        <ButtonComponent {...buttonProps} ref={ref}>
            {children}
            {submitting ? <CircularProgress {...progressProps} /> : null}
        </ButtonComponent>
    );
});

export default SubmitButton;
