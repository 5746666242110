// @flow
import { createAction } from 'redux-actions';
import type { Dispatch } from 'redux';
import type { $AxiosError } from 'axios';
import types from './types';
import {
    search as searchSubscriptions,
    update as updateSubscription,
} from '../../../../../redux/entities/subscriptions';
import logger from '../../../../../util/logger';
import * as api from '../../../../../ducks/api';
import { update as updateCustomer } from '../../../../../redux/entities/customers';
import type { State as RootState } from '../../../../../redux/initialState';

export const fetchAllSubscriptionsSuccess = createAction(types.FETCH_ALL_SUBSCRIPTIONS_SUCCESS);
export const fetchAllSubscriptionsError = createAction(types.FETCH_ALL_SUBSCRIPTIONS_ERROR);
export const fetchAllSubscriptions = createAction(
    types.FETCH_ALL_SUBSCRIPTIONS,
    (customerId: number) => (
        (dispatch: Dispatch<any>) => {
            const apiFilters = [{ key: 'subscriber_ids', value: customerId }];
            const ids = [];

            return dispatch(searchSubscriptions({ filters: apiFilters, limit: 200, offset: 0 }))
                .then((resp: Object) => {
                    const promises = [];
                    if (resp.original && resp.original._metadata) {
                        const { page_count: pageCount } = resp.original._metadata;
                        for (let i = 1; i < pageCount; i += 1) {
                            promises.push(dispatch(searchSubscriptions({ filters: apiFilters, limit: 200, offset: i })));
                        }
                    }
                    ids.push(...resp.result);
                    return Promise.all(promises);
                })
                .then((results: Object[]) => {
                    results.forEach((resp: Object) => {
                        ids.push(...resp.result);
                    });
                    dispatch(fetchAllSubscriptionsSuccess(ids));
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(fetchAllSubscriptionsError());
                    return Promise.reject(err);
                });
        }
    )
);

export const unsubscribeError = createAction(types.UNSUBSCRIBE_ERROR);
export const unsubscribeSuccess = createAction(types.UNSUBSCRIBE_SUCCESS);
export const unsubscribe = createAction(
    types.UNSUBSCRIBE,
    (subscriptionId: number, customerId: number) => (
        (dispatch: Dispatch<any>, getState: () => RootState) => {
            const state = getState();
            const subscription = state.entities.subscriptions[`${subscriptionId}`];
            const { subscriber_ids: subscriberIds } = subscription;

            const params = {
                organization_subscription_id: subscriptionId,
                subscription: {
                    subscriber_ids: subscriberIds.filter((id: number) => id !== customerId),
                },
            };

            return dispatch(updateSubscription(params))
                .then(() => {
                    dispatch(unsubscribeSuccess(subscriptionId));
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(unsubscribeError());
                    return Promise.reject(err);
                });
        }
    )
);

export const submitError = createAction(types.SUBMIT_ERROR);
export const submitSuccess = createAction(types.SUBMIT_SUCCESS);
export const submit = createAction(
    types.SUBMIT,
    (values: Object): Function => (
        (dispatch: Dispatch<any>, getState: () => RootState): Promise<any> => (
            Promise.resolve()
                .then(() => {
                    const { disabledNotifications, enabledNotifications, id, idealWeeklyHours, maxWeeklyHours } = values;

                    const params = {
                        ideal_hours_week: parseInt(idealWeeklyHours, 10),
                        max_hours_week: parseInt(maxWeeklyHours, 10),
                        notification_preferences: {
                            disabled: disabledNotifications,
                            enabled: enabledNotifications,
                        },
                    };

                    const { user } = getState().session;
                    const action = user && user.id === id
                        ? api.actions.updateUser(params)
                        : updateCustomer({ customer_id: id, ...params });

                    return dispatch(action)
                        .then(() => {
                            dispatch(submitSuccess());
                        })
                        .catch((err: $AxiosError<Object>) => {
                            dispatch(submitError(err));
                            return Promise.reject(err);
                        });
                })
        )
    )
);

export default {
    fetchAllSubscriptions,
    fetchAllSubscriptionsError,
    fetchAllSubscriptionsSuccess,
    submit,
    submitError,
    submitSuccess,
    unsubscribe,
    unsubscribeError,
    unsubscribeSuccess,
};
