// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback, useEffect, useMemo, useState } from 'react';
// $FlowTypedIssue update react-redux libdef
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { CircularProgress, Divider, Fab, List, ListItem, Paper, Toolbar } from '@material-ui/core';
import { Publish as PublishIcon } from '@material-ui/icons';
import {
    getAnsweredCount,
    getAvailableActions,
    getQuestionCount,
    getTicket,
    getTicketId,
    loadTicket,
    saveChanges,
    toggleLocationEnabled,
} from '../../../ducks/ticketDetail';
import Question from './Question';
import type { State as RootState } from '../../../store';
import styles from './styles';

type Props = {};

const useStyles = makeStyles(styles, { name: 'Survey' });

export default function Survey(props: Props) {
    const dispatch = useDispatch();
    const classes = useStyles(props);
    const { t } = useTranslation();

    const [saving, setSaving] = useState(false);

    const ticket = useSelector((state: RootState) => getTicket(state));
    const ticketId = useSelector((state: RootState) => getTicketId(state));
    const questionCount = useSelector((state: RootState) => getQuestionCount(state));
    const answeredCount = useSelector((state: RootState) => getAnsweredCount(state));
    const availableActions = useSelector((state: RootState) => getAvailableActions(state));

    const saveDisabled = useMemo(() => (
        !availableActions.includes('editResponse') && !availableActions.includes('submit')
    ), [availableActions]);

    const disableValidation = useMemo(() => {
        availableActions.includes('editResponse');
    }, [availableActions]);

    const handleSave = useCallback(() => {
        setSaving(true);
        dispatch(saveChanges())
            .finally(() => {
                setSaving(false);
                dispatch(loadTicket(ticketId));
            });
    }, [dispatch, ticketId]);

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            () => { dispatch(toggleLocationEnabled(true)); },
            () => { dispatch(toggleLocationEnabled(false)); },
        );
    }, [dispatch]);

    const questions = ticket ? ticket.data_types : [];

    return (
        <Paper className={classes.root}>
            <Toolbar variant="dense">
                <Trans
                  parent="span"
                  defaults={t('ticketDetail.survey.questionCount', { answeredCount, questionCount })}
                  components={[
                      <strong style={{ fontWeight: 600 }}>questionCount</strong>,
                      'Questions - {{answeredCount}} answered',
                  ]}
                />
            </Toolbar>
            <Divider />
            <List className={classes.list} disablePadding>
                {questions.map((question: Object, index: number) => (
                    <ListItem className={classes.listItem} divider={index < questions.length - 1}>
                        <Question
                          disabled={saveDisabled}
                          disableValidation={disableValidation}
                          json={question}
                        />
                    </ListItem>
                ))}
                {!saveDisabled
                    ? (
                        <Fab className={classes.saveButton} onClick={handleSave} variant="extended" disabled={saving}>
                            <PublishIcon className={classes.saveIcon} />
                            <span>{t('ticketDetail.survey.saveChanges')}</span>
                            {saving ? <CircularProgress className={classes.progress} size={30} /> : null}
                        </Fab>
                    )
                    : null}
            </List>
        </Paper>
    );
}
