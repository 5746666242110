// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import type { Dispatch } from 'redux';
import type { Connector } from 'react-redux';
import LoadingPage from '../../../../components/LoadingPage';
import FormikInput from '../../../../components/FormikInput';
import FormikMaskedInput from '../../../../components/FormikMaskedInput';
import SubmitButton from '../../../../components/SubmitButton';
import * as snackbar from '../../../../ducks/snackbar';
import { actions, selectors } from './duck';
import styles from './styles';
import type { State as RootState } from '../../../../redux/initialState';

type OwnProps = {};
type StateProps = {
    customerAddress: string,
    initialValues: Object,
    readOnly: boolean,
};
type DispatchProps = {
    enqueueSnackbar: (message: string, option: Object) => void,
    resolveAddress: (address: string) => Promise<void>,
    submit: (values: Object) => Promise<void>,
};
type Props = OwnProps & StateProps & DispatchProps;

const AccountSchema = yup.object().shape({
    firstName: yup.string()
        .required('validation.required'),
    lastName: yup.string()
        .required('validation.required'),
    phoneNumber: yup.string(),
    addressLine1: yup.string(),
    addressLine2: yup.string(),
    city: yup.string(),
    state: yup.string(),
    zipCode: yup.string(),
    currentPassword: yup.string()
        .when(['newPassword', 'confirmNewPassword'], {
            is: (newPassword, confirmNewPassword) => newPassword || confirmNewPassword,
            then: yup.string()
                .min(6, 'validation.passwordTooShort')
                .required('validation.required'),
        }),
    newPassword: yup.string()
        .min(6, 'validation.passwordTooShort')
        .when(['currentPassword', 'confirmNewPassword'], {
            is: (currentPassword, confirmNewPassword) => currentPassword || confirmNewPassword,
            then: yup.string()
                .min(6, 'validation.passwordTooShort')
                .required('validation.required'),
        }),
    confirmNewPassword: yup.string()
        .min(6, 'validation.passwordTooShort')
        .when(['currentPassword', 'newPassword'], {
            is: (currentPassword, newPassword) => currentPassword || newPassword,
            then: yup.string()
                .min(6, 'validation.passwordTooShort')
                .oneOf([yup.ref('newPassword')], 'validation.doesNotMatchPassword')
                .required('validation.required'),
        }),
}, [['currentPassword', 'newPassword'], ['currentPassword', 'confirmNewPassword'], ['newPassword', 'confirmNewPassword']]);

const useStyles = makeStyles(styles, { name: 'Account' });

export function Account(props: Props) {
    const { customerAddress, enqueueSnackbar, initialValues, readOnly, resolveAddress, submit } = props;

    const { t } = useTranslation();
    const classes = useStyles(props);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        resolveAddress(customerAddress)
            .catch(() => {})
            .then(() => {
                setLoading(false);
            });
    }, [customerAddress, resolveAddress]);

    if (loading) {
        return <LoadingPage />;
    }

    return (
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { resetForm, setStatus }) => (
              submit(values)
                  .then(() => {
                      setStatus({ success: true });
                      resetForm();
                      enqueueSnackbar(t('profilePage.account.updateSuccessful'), { variant: 'success' });
                  })
                  .catch((error: string) => {
                      setStatus({ error });
                      return Promise.reject(error);
                  })
          )}
          validationSchema={AccountSchema}
        >
            {({ dirty, handleSubmit, isSubmitting }) => (
                <Form className={classes.root}>
                    <section className={classes.section}>
                        <Typography className={classes.sectionHeader} variant="subtitle1">
                            {t('profilePage.account.personalInfo')}
                        </Typography>
                        <fieldset className={cx({ [classes.readOnly]: readOnly })}>
                            <FormikInput
                              fullWidth
                              label={t('profilePage.account.firstName')}
                              margin="dense"
                              name="firstName"
                              readOnly={readOnly}
                              type="text"
                              variant="outlined"
                            />
                            <FormikInput
                              fullWidth
                              label={t('profilePage.account.lastName')}
                              margin="dense"
                              name="lastName"
                              readOnly={readOnly}
                              type="text"
                              variant="outlined"
                            />
                            <FormikMaskedInput
                              fullWidth
                              label={t('profilePage.account.phoneNumber')}
                              margin="dense"
                              name="phoneNumber"
                              options={{
                                  numericOnly: true,
                                  blocks: [0, 3, 3, 4],
                                  delimiters: ['(', ') ', '-'],
                              }}
                              readOnly={readOnly}
                              type="tel"
                              variant="outlined"
                            />
                            <FormikInput
                              disabled
                              fullWidth
                              label={t('profilePage.account.email')}
                              margin="dense"
                              name="email"
                              readOnly={readOnly}
                              type="email"
                              variant="outlined"
                            />
                        </fieldset>
                    </section>
                    <section className={classes.section}>
                        <Typography className={classes.sectionHeader} variant="subtitle1">
                            {t('profilePage.account.address')}
                        </Typography>
                        <fieldset className={cx({ [classes.readOnly]: readOnly })}>
                            <FormikInput
                              fullWidth
                              label={t('profilePage.account.addressLine1')}
                              margin="dense"
                              name="addressLine1"
                              readOnly={readOnly}
                              type="text"
                              variant="outlined"
                            />
                            <FormikInput
                              fullWidth
                              label={t('profilePage.account.addressLine2')}
                              margin="dense"
                              name="addressLine2"
                              readOnly={readOnly}
                              type="text"
                              variant="outlined"
                            />
                            <FormikInput
                              fullWidth
                              label={t('profilePage.account.city')}
                              margin="dense"
                              name="city"
                              readOnly={readOnly}
                              type="text"
                              variant="outlined"
                            />
                            <FormikInput
                              fullWidth
                              label={t('profilePage.account.state')}
                              margin="dense"
                              name="state"
                              readOnly={readOnly}
                              type="text"
                              variant="outlined"
                            />
                            <FormikInput
                              fullWidth
                              label={t('profilePage.account.zipCode')}
                              margin="dense"
                              name="zipCode"
                              readOnly={readOnly}
                              type="text"
                              variant="outlined"
                            />
                        </fieldset>
                    </section>
                    <section className={classes.section}>
                        <Typography className={classes.sectionHeader} variant="subtitle1">
                            {t('profilePage.account.password')}
                        </Typography>
                        <fieldset className={cx({ [classes.readOnly]: readOnly })}>
                            <FormikInput
                              fullWidth
                              label={t('profilePage.account.currentPassword')}
                              margin="dense"
                              minLength={6}
                              name="currentPassword"
                              readOnly={readOnly}
                              type="password"
                              variant="outlined"
                            />
                            <FormikInput
                              fullWidth
                              label={t('profilePage.account.newPassword')}
                              margin="dense"
                              minLength={6}
                              name="newPassword"
                              readOnly={readOnly}
                              type="password"
                              variant="outlined"
                            />
                            <FormikInput
                              fullWidth
                              label={t('profilePage.account.confirmNewPassword')}
                              minLength={6}
                              margin="dense"
                              name="confirmNewPassword"
                              readOnly={readOnly}
                              type="password"
                              variant="outlined"
                            />
                        </fieldset>
                    </section>
                    {!readOnly
                        ? (
                            <SubmitButton
                              className={classes.submitButton}
                              color="secondary"
                              disabled={!dirty || isSubmitting}
                              onClick={handleSubmit}
                              size="large"
                              submitting={isSubmitting}
                              type="submit"
                              variant="contained"
                            >
                                {t('profilePage.account.updateProfile')}
                            </SubmitButton>
                        )
                        : null}
                </Form>
            )}
        </Formik>
    );
}

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => ({
    customerAddress: selectors.getCustomerAddress(state, props),
    initialValues: selectors.getInitialValues(state, props),
    readOnly: selectors.isReadOnly(state, props),
});
const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    enqueueSnackbar: (message: string, options: Object) => dispatch(snackbar.actions.enqueue(message, options)),
    resolveAddress: (address: string) => dispatch(actions.resolveAddress(address)),
    submit: (values: Object) => dispatch(actions.submit(values)),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
export default connector(Account);
