// @flow
import { createSelector } from 'reselect';
import { createEntitySelector } from '../gigwalk';
import type { State as RootState } from '../../store';

const getUser = (state: RootState): ?Object => state.session.user;
const getCustomerId = (state: RootState, props: any) => parseInt(props.match.params.customerId, 10);

export const getCustomer = createSelector(
    getUser,
    getCustomerId,
    createEntitySelector('customers', getCustomerId),
    (user: ?Object, customerId: number, customer: Object) => (
        user && user.id === customerId ? user : customer
    )
);

export const getCertifications = createEntitySelector(
    'certifications',
    createSelector(
        getCustomer,
        (customer: ?Object): number[] => (
            customer ? (customer.certifications || []).map((certification: Object) => certification.id) : []
        )
    )
);

export const getSubscriptions = createEntitySelector(
    'subscriptions',
    (state: RootState): number[] => state.profile.subscriptionIds,
);

export const getPreferences = createSelector(
    getCustomer,
    (customer: Object) => {
        if (!customer) {
            return {
                disabledNotifications: [],
                enabledNotifications: [],
                idealWeeklyHours: '',
                id: null,
                maxWeeklyHours: '',
            };
        }

        const {
            id,
            ideal_hours_week: idealWeeklyHours,
            max_hours_week: maxWeeklyHours,
            notification_preferences: notificationPreferences,
        } = customer;

        const { disabled, enabled } = notificationPreferences;
        const enabledNotifications = enabled.sort();
        const disabledNotifications = disabled.sort();

        return {
            disabledNotifications,
            enabledNotifications,
            id,
            idealWeeklyHours: idealWeeklyHours || '',
            maxWeeklyHours: maxWeeklyHours || '',
        };
    }
);

export const getAccountInfo = createSelector(
    getCustomer,
    (state: RootState): string => state.profile.addressLine1,
    (state: RootState): string => state.profile.addressLine2,
    (state: RootState): string => state.profile.city,
    (state: RootState): string => state.profile.state,
    (state: RootState): string => state.profile.zipCode,
    (customer: Object, addressLine1: string, addressLine2: string, city: string, state: string, zipCode: string) => {
        if (!customer) {
            return {
                addressLine1: '',
                addressLine2: '',
                city: '',
                confirmNewPassword: '',
                currentPassword: '',
                email: '',
                firstName: '',
                id: null,
                lastName: '',
                newPassword: '',
                phoneNumber: '',
                state: '',
                zipCode: '',
            };
        }

        const {
            id,
            email,
            first_name: firstName,
            last_name: lastName,
            phonenumber: phoneNumber,
        } = customer;

        return {
            addressLine1,
            addressLine2,
            city,
            confirmNewPassword: '',
            currentPassword: '',
            email,
            firstName: firstName || '',
            id,
            lastName: lastName || '',
            newPassword: '',
            phoneNumber: phoneNumber || '',
            state,
            zipCode,
        };
    }
);


export const getFormattedAddress = createSelector(
    getCustomer,
    (customer: Object): string => {
        if (!customer) {
            return '';
        }

        const {
            address_line_1: addressLine1,
            address_line_2: addressLine2,
        } = customer;

        return [addressLine1, addressLine2].join(' ').trim();
    }
);

export const isReadOnly = createSelector(
    getUser,
    getCustomer,
    (user: ?Object, customer: Object) => (
        user ? user.id !== customer.id && user.role !== 'PLATFORM_ADMIN' : true
    )
);

export default {
    getAccountInfo,
    getCertifications,
    getCustomer,
    getFormattedAddress,
    getPreferences,
    getSubscriptions,
    isReadOnly,
};
