var Handlebars = require('/tmp/build_a9d70681abb25b82ca4489607bd6ccee/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Ticket Instructions panel -->\n<div class=\"ticket-instructions\">\n\n	<div data-target=\"widgets\"></div>\n\n	<!-- ---------------------- Submit this Ticket button ----------------------- -->\n	<a href=\"javascript:;\" class=\"answerButton submit disabled\" data-action=\"submit\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"submit_this_ticket") || (depth0 != null ? lookupProperty(depth0,"submit_this_ticket") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"submit_this_ticket","hash":{},"data":data,"loc":{"start":{"line":7,"column":82},"end":{"line":7,"column":106}}}) : helper)))
    + "</a>\n\n</div>\n<!-- ----------------- END Ticket Instructions panel ---------------- -->\n";
},"useData":true});