// @flow
export default (theme: Object) => ({
    container: {
        display: 'flex',
        alignItems: 'center',

        '& > *': {
            margin: theme.spacing(0, 0.5),

            '&:first-child': { marginLeft: 0 },
            '&:last-child': { marginRight: 0 },
        },
    },
    input: {
        maxWidth: 100,

        '&$disabled': {
            color: 'rgba(0, 0, 0, 0.48)',
            backgroundColor: '#e5eaed',
        },

        '@global input': {
            textAlign: 'center',
        },
    },
    readOnly: {
        color: theme.palette.text.secondary,
    },
    icon: {
        color: 'rgba(0, 0, 0, 0.64)', // theme.palette.action.active,
        fontSize: 22,
    },
    label: {
        fontFamily: 'ProximaNova',
        fontSize: 16,
        fontWeight: 600,
        color: 'rgba(0, 0, 0, 0.64)', // theme.palette.text.secondary,

        '&::after': {
            content: '":"',
        },
    },
    successIcon: {
        fontSize: 18,
        color: theme.palette.success.main,
    },
    errorIcon: {
        fontSize: 18,
        color: theme.palette.error.main,
    },
    disabled: {},
});
