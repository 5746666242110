// @flow
import { darken } from '@material-ui/core';

export default (theme: Object) => ({
    root: {},
    list: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
    },
    listItem: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    saveButton: {
        alignSelf: 'center',
        position: 'sticky',
        bottom: theme.spacing(2),
        margin: theme.spacing(2, 0),
        padding: theme.spacing(0, 3),
        fontSize: 16,
        backgroundColor: theme.palette.success.main,
        color: theme.palette.common.white,

        '&:hover': {
            backgroundColor: darken(theme.palette.success.main, theme.palette.action.hoverOpacity),

            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: theme.palette.success.main,
            },
        },
    },
    saveIcon: {
        marginRight: theme.spacing(1),
    },
    progress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -15,
        marginLeft: -15,
    },
});
