// @flow
export default (theme: Object) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    schedule: {},
    notifications: {},
    section: {
        marginBottom: theme.spacing(3),

        '& > fieldset': {
            margin: 0,
        },
    },
    sectionHeader: {
        textTransform: 'uppercase',
    },
    formControl: {
        display: 'flex',
        marginBottom: theme.spacing(2),
    },
    formGroup: {
        padding: theme.spacing(0, 0, 1, 4),
    },
    listPadding: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    list: {
        backgroundColor: theme.palette.grey[100],
        border: [[1, 'solid', theme.palette.divider]],
        borderRadius: 3,
    },
    listItem: {
        '&:hover $unsubscribeButton': {
            opacity: 1,
        },
    },
    unsubscribeButton: {
        '&:not($submitting)': {
            opacity: 0,
        },
    },
    info: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1, 0, 1),
    },
    infoIcon: {
        color: theme.palette.primary.light,
        marginRight: theme.spacing(0.5),
    },
    submitButton: {
        marginLeft: 'auto',
    },
    readOnly: {
        pointerEvents: 'none',
    },
    submitting: {},
});
