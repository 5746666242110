// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useState, useCallback } from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { Avatar } from '@material-ui/core';
import styles from './styles';

type Props = {
    className?: string,
    size?: 'small' | 'medium' | 'large',
    user: Object,
};

const useStyles = makeStyles(styles, { name: 'UserAvatar' });

export default function UserAvatar(props: Props) {
    const { className, size, user, ...other } = props;
    const { photo_url: photoUrl } = user;
    const firstName = user.first_name || '';
    const lastName = user.last_name || '';

    const classes = useStyles(props);

    const [orientation, setOrientation] = useState('portrait');
    const [displayDefaultAvatar, toggleDefaultAvatar] = useState(true);

    const handleImageError = useCallback(() => {
        toggleDefaultAvatar(true);
    }, []);

    const handleImageLoad = useCallback((event: SyntheticEvent<HTMLImageElement>) => {
        const img = event.currentTarget;
        const { height, width } = img.getBoundingClientRect();
        setOrientation(height < width ? 'landscape' : 'portrait');
        toggleDefaultAvatar(false);
    }, []);

    const imgSrc = photoUrl && photoUrl.endsWith('/img/default-user-image.gif') ? null : photoUrl;
    const rootClassName = cx(
        classes.root,
        className,
        {
            [classes.landscape]: orientation === 'landscape',
            [classes.portrait]: orientation === 'portrait',
            [classes.sizeMedium]: size === 'medium',
            [classes.sizeLarge]: size === 'large',
        }
    );

    return (
        <Avatar className={rootClassName} {...other}>
            {displayDefaultAvatar
                ? <span className={classes.initials}>{firstName[0] || lastName[0] || ''}</span>
                : null}
            {imgSrc && !displayDefaultAvatar
                ? (
                    <img
                      className={classes.image}
                      src={imgSrc}
                      alt="avatar"
                      onError={handleImageError}
                      onLoad={handleImageLoad}
                    />
                )
                : null}
        </Avatar>
    );
}
