// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback, useState } from 'react';
import cx from 'classnames';
// $FlowTypedIssue update react-redux libdef
import { useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router';
import { makeStyles } from '@material-ui/styles';
import {
    Collapse,
    IconButton,
    Paper,
    Toolbar,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import type { ContextRouter } from 'react-router';
import AddComment from './AddComment';
import Comment from './Comment';
import TicketEvent from './TicketEvent';
import { deleteComment, getComments } from '../../../ducks/ticketDetail';
import styles from './styles';
import type { State as RootState } from '../../../store';

type Props = ContextRouter & {
    className?: string,
};

const useStyles = makeStyles(styles, { name: 'Comments' });

export default function Comments(props: Props) {
    const dispatch = useDispatch();
    const classes = useStyles(props);

    const [expanded, setExpanded] = useState(true);

    const user = useSelector((state: RootState) => state.session.user);
    const comments = useSelector((state: RootState) => getComments(state));

    const handleExpandClick = useCallback(() => {
        setExpanded(!expanded);
    }, [expanded]);

    const handleDelete = useCallback((id: string) => {
        dispatch(deleteComment(id));
    }, [dispatch]);

    return (
        <Paper>
            <Toolbar variant="dense" className={classes.toolbar}>
                <span>Comments</span>
                <IconButton
                  className={cx(classes.expand, { [classes.expandOpen]: expanded })}
                  onClick={handleExpandClick}
                >
                    <ExpandMoreIcon />
                </IconButton>
            </Toolbar>
            <Collapse in={expanded}>
                <div className={classes.comments}>
                    {comments.map((comment) => {
                        const { author, data, date, id, type } = comment;

                        if (type === 'COMMENT') {
                            const isAuthor = user ? user.id === author.id : false;
                            return (
                                <Comment
                                  id={id}
                                  author={author}
                                  date={date}
                                  {...(isAuthor ? { onDelete: handleDelete } : undefined)}
                                >
                                    {data.comment}
                                </Comment>
                            );
                        }

                        return <TicketEvent type={type} data={data} date={date} />;
                    })}
                    <Route component={AddComment} />
                </div>
            </Collapse>
        </Paper>
    );
}
