// @flow
// $FlowIssue need to update to a more recent flow version
import React, { Fragment, useCallback } from 'react';
import { FieldArray, Form } from 'formik';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { ListItemText, Typography } from '@material-ui/core';
import {
    Check as CheckIcon,
    Search as SearchIcon,
} from '@material-ui/icons';
import type { $AxiosXHR } from 'axios';
import type { Dispatch } from 'redux';
import type { Connector } from 'react-redux';
import type { ContextRouter } from 'react-router';
import type { Group } from 'gigwalk/lib/api/groups/types';
import { search as searchGroups } from '../../../../redux/entities/groups';
import entitySelector from '../../../../redux/entities/entitySelector';
import highlightMatch from '../../../../util/highlightMatch';
import Select from '../../../../components/Select';
import Step from '../../components/Step';
import GroupList from './components/GroupList';
import styles from './styles';
import type { State as RootState } from '../../../../redux/initialState';

type OwnProps = ContextRouter & {
    setFieldValue: (field: string, value: any) => void,
    values: Object,
};
type StateProps = {
    editMode: boolean,
};
type DispatchProps = {
    searchGroups: (params: Object) => Promise<Object>
}
type Props = OwnProps & StateProps & DispatchProps;

const preventDefault = (event: SyntheticEvent<any>) => {
    event.preventDefault();
};

const formatOptionLabel = (option: Object, meta: Object) => {
    const { label } = option;
    const primary = highlightMatch(label, meta.inputValue);

    return (
        <Fragment>
            <ListItemText primary={primary} />
            {meta.isSelected ? <CheckIcon /> : null}
        </Fragment>
    );
};

const useStyles = makeStyles(styles, { name: 'Groups' });

export function Groups(props: Props) {
    const {
        match,
        searchGroups: _searchGroups,
        setFieldValue,
        values,
    } = props;

    const { groups } = values;
    const orgId = parseInt(match.params.orgId, 10);

    const classes = useStyles(props);
    const { t } = useTranslation();

    const loadOptions = useCallback((inputValue: string): Promise<Object[]> => {
        const params = {
            organization_id: orgId,
            q: `*${inputValue}*`,
            limit: 20,
        };
        return _searchGroups(params)
            .then((resp: $AxiosXHR<Object>) => {
                const { entities, result } = resp;
                return result.map((id: number) => ({
                    label: entities.groups[id].name,
                    value: entities.groups[id],
                }));
            });
    }, [_searchGroups, orgId]);

    const handleGroupsChange = useCallback((value: Object[]) => {
        setFieldValue('groups', value.map((option: Object) => option.value));
    }, [setFieldValue]);

    const memberCount = groups.reduce((count: number, group: Group) => count + group.member_count, 0);
    const selected = groups.map((group: Object) => ({
        label: group.name,
        value: group,
    }));

    return (
        <Step title={t('projectBuilder.people.groups.header')} subheader={t('projectBuilder.people.groups.subheader')}>
            <Form className={classes.form} onSubmit={preventDefault}>
                <FieldArray name="groups" component={GroupList} />
                <Select
                  backspaceRemovesValue={false}
                  classes={{
                      input: classes.selectInput,
                      placeholder: classes.selectPlaceholder,
                      root: classes.select,
                  }}
                  ControlProps={{ fullWidth: true }}
                  controlShouldRenderValue={false}
                  defaultOptions
                  disableSelectedOptions
                  dropdownIcon={<SearchIcon />}
                  formatOptionLabel={formatOptionLabel}
                  isMulti
                  loadOptions={loadOptions}
                  onChange={handleGroupsChange}
                  placeholder={t('projectBuilder.people.groups.addGroup')}
                  value={selected}
                />
                <Typography className={classes.invitedCount} align="center" variant="caption">
                    {t('projectBuilder.people.groups.invitedCount', { count: memberCount })}
                </Typography>
            </Form>
        </Step>
    );
}

const subscriptionSelector = entitySelector('subscriptions');
const mapStateToProps = (state: RootState, props: OwnProps): StateProps => {
    const { match } = props;
    return {
        editMode: subscriptionSelector(state, match.params.subscriptionId, 'state') === 'ACTIVE',
    };
};
const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    searchGroups: (params: Object) => dispatch(searchGroups(params)),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connector(Groups));
