// @flow
import { emphasize } from '@material-ui/core';

export default (theme: Object) => ({
    root: {},
    fullHeight: {
        height: '100%',
    },
    outlined: {
        '& $placeholder': {
            left: 14,
        },
    },
    menuItem: {
        whiteSpace: 'unset',
    },
    menu: {
        position: 'absolute',
        width: '100%',
        zIndex: 1,
    },
    list: {
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 40px)', // 40px = height of input/indicator + marginTop of paper
    },
    input: {},
    inputElement: {
        minHeight: 'auto',
        background: 0,
        border: 0,
        fontSize: 'inherit',
        outline: 0,
        padding: 0,
        color: 'inherit',
        lineHeight: 'inherit',

        '&:focus': {
            boxShadow: 'none',
        },
    },
    valueContainer: {},
    loadingIndicator: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 24,
    },
    dropdownIndicator: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 24,
    },
    chip: {
        margin: theme.spacing(0.5, 0.25),
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        ),
    },
    noOptionsMessage: {
        padding: theme.spacing(1, 2),
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        color: 'rgba(0, 0, 0, 0.4)',
        position: 'absolute',
        left: 0,
    },
    paper: {
        marginTop: 4,
    },
});
