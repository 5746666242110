// @flow
const namespace = 'g2/dialog';

export const OPEN_DIALOG = `${namespace}/OPEN`;
export const CLOSE_DIALOG = `${namespace}/CLOSE`;
export const REGISTER_DIALOG = `${namespace}/REGISTER`;
export const UNREGISTER_DIALOG = `${namespace}/UNREGISTER`;

export default {
    CLOSE_DIALOG,
    OPEN_DIALOG,
    REGISTER_DIALOG,
    UNREGISTER_DIALOG,
};
