// @flow
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Modal } from '@material-ui/core';
import Container from './Container';
import styles from './styles';

type Props = {
    classes?: Object,
    currentImage: number,
    images: Array<{
        src: string,
        title?: string,
        description?: string,
    }>,
    onChange: (index: number) => void,
    onClose: () => void,
    onEditClick?: ?(index: number) => void,
    onInfoClick?: ?(index: number) => void,
    open: boolean,
};

const useStyles = makeStyles(styles, { name: 'Lightbox' });

export default function Lightbox(props: Props) {
    const { classes: otherClasses, onClose, open, ...other } = props;
    const classes = useStyles(props);
    return (
        <Modal BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.8)' } }} onClose={onClose} open={open}>
            <Container {...other} classes={classes} onClose={onClose} />
        </Modal>
    );
}

Lightbox.defaultProps = {
    images: [],
    currentImage: 0,
    onClose: () => {},
    onChange: () => {},
    open: false,
};
