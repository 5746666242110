// @flow
// $FlowIssue need to update to a more recent flow version
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import platform from 'platform';
import Helmet from 'react-helmet';
import * as yup from 'yup';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import type { ContextRouter } from 'react-router';
import type { Connector } from 'react-redux';
import LoadingPage from '../../components/LoadingPage';
import MobileRedirect from './components/MobileRedirect';
import InvalidLink from './components/InvalidLink';
import PasswordChanged from './components/PasswordChanged';
import ResetPasswordForm from './components/ResetPasswordForm';
import actions from './ducks/actions';
import selectors from './ducks/selectors';
import styles from './styles';
import type { State as RootState } from '../../redux/initialState';

/* Analytics */
// import { trackEvent } from '../../../browser/shared/util/GoogleTagManager';
// import type { dataLayerValueType } from '../../../browser/shared/util/GoogleTagManager';
// import { LOGIN } from '../../constant/analytics/AuthPages';
//
// const onClickLogin = (): dataLayerValueType => trackEvent(LOGIN);

type FormValues = {
    email: string,
    token: string,
    password: string,
};

type OwnProps = ContextRouter & {};
type StateProps = {
    initialValues: Object,
};
type DispatchProps = {
    checkExpired: (values: FormValues) => Promise<void>,
    submit: (values: FormValues) => Promise<void>,
};
type Props = OwnProps & StateProps & DispatchProps;

const logoSrc = process.env.GWP_COMPANY_LOGO || '/public/images/gigwalk-logo.png';
const osFamily = platform.os.family ? platform.os.family.toLowerCase() : '';
const isMobile = osFamily === 'android' || osFamily === 'ios';
const useStyles = makeStyles(styles, { name: 'ResetPassword' });

const ResetPasswordSchema = yup.object().shape({
    confirmPassword: yup.string()
        .min(6, 'resetPassword.validation.password.tooShort')
        .oneOf([yup.ref('password')], 'resetPassword.validation.password.doesNotMatch')
        .required('validation.required'),
    email: yup.string().email().required(),
    password: yup.string()
        .min(6, 'resetPassword.validation.password.tooShort')
        .required('validation.required'),
    token: yup.string().required(),
});

export function ResetPassword(props: Props) {
    const { checkExpired, initialValues, location, submit } = props;

    const classes = useStyles(props);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [invalidLink, setInvalidLink] = useState(false);
    const [continueOnWeb, setContinueOnWeb] = useState(false);

    useEffect(() => {
        if (!isMobile || continueOnWeb) {
            setLoading(true);
            checkExpired(initialValues)
                .catch(() => setInvalidLink(true))
                .then(() => setLoading(false));
        } else {
            setLoading(false);
        }
    }, [checkExpired, continueOnWeb, initialValues]);

    const handleContinueOnWeb = useCallback((event: SyntheticEvent<any>) => {
        event.preventDefault();
        setContinueOnWeb(true);
    }, []);

    return (
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setStatus }) => (
              submit(values)
                  .then(() => setStatus({ success: true }))
                  .catch((error: string) => {
                      setStatus({ error });
                      return Promise.reject(error);
                  })
          )}
          validationSchema={ResetPasswordSchema}
        >
            {(formikProps: Object) => {
                const { status } = formikProps;
                const submitSuccess = status ? status.success || false : false;

                let mainContent;

                if (isMobile && !continueOnWeb) {
                    const appPath = location.pathname.startsWith('/') ? location.pathname.slice(1) : location.pathname;
                    mainContent = (
                        <Fragment>
                            <MobileRedirect appLink={`gw-enterprise://${appPath}${location.search}`} />
                            <div className={classes.footer}>
                                <p>{t('mobileRedirect.notAGigwalker')}</p>
                                <a className={classes.link} onClick={handleContinueOnWeb}>
                                    {t('mobileRedirect.continueOnWeb')}
                                </a>
                            </div>
                        </Fragment>
                    );
                } else if (submitSuccess) {
                    mainContent = <PasswordChanged />;
                } else if (invalidLink) {
                    mainContent = <InvalidLink />;
                } else {
                    mainContent = <ResetPasswordForm {...formikProps} />;
                }

                return (
                    <main>
                        <Helmet title={t('resetPassword.title')} />
                        <img alt="Gigwalk" className={classes.logo} src={logoSrc} />
                        {loading
                            ? <LoadingPage className={classes.loading} indicatorColor="inherit" />
                            : mainContent}
                    </main>
                );
            }}
        </Formik>
    );
}

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => ({
    initialValues: selectors.getInitialValues(state, props),
});
const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    checkExpired: (values: Object): Promise<any> => dispatch(actions.checkExpired(values)),
    submit: (values: Object) => dispatch(actions.submit(values)),
});
const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
export default connector(ResetPassword);
