// @flow
import type { DataType } from 'gigwalk/lib/api/dataTypes/types';
import CondExpression from '../../../../browser/shared/data_structures/CondExpression';

type Proposition = {
    alert: boolean,
    choice: string,
};

type Question = {
    attachments: Object[],
    children?: Object[],
    description: string,
    globalDataTypeId: number,
    id: number | string,
    propositions: Proposition[],
    questionText: string,
    required: boolean,
    targetListId?: number,
    valueType: string,
};

export default function mapEntityToQuestion(dataType: DataType): Question {
    const {
        attachments,
        description,
        expected_value_expression: expectedValueExpression,
        global_data_type_id: globalDataTypeId,
        id,
        is_required: required,
        // $FlowFixMe need to update gigwalk-node type definitions
        project_or_global_type: type,
        questions: {
            question_text: questionText,
            propositions,
        },
        value_type: valueType,
    } = dataType;

    const condExpression = expectedValueExpression ? new CondExpression(expectedValueExpression) : null;
    return {
        attachments: attachments || [],
        description,
        globalDataTypeId: type === 'global' ? id : globalDataTypeId || -1,
        id,
        propositions: propositions
            ? propositions.map((p) => ({ choice: p, alert: condExpression ? !condExpression.evaluate([p]) : false }))
            : [],
        questionText,
        required: required || false,
        valueType,
    };
}
