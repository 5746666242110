// @flow
/* eslint-disable max-len */
import React, { Component, Fragment } from 'react';
import uniqueId from 'lodash/uniqueId';
import { pure } from 'recompose';
import { SvgIcon } from '@material-ui/core';

type Props = {
    value?: number,
}

class Star extends Component<Props> {
    static muiName = 'SvgIcon';

    _id: string;

    static displayName = 'Star';

    static defaultProps = {
        value: 1,
    };

    constructor(props: Object) {
        super(props);
        this._id = uniqueId();
    }

    render() {
        const { value = 1, ...other } = this.props;
        const offset = `${value * 100}%`;
        const gradientId = `star-gradient-${this._id}`;
        const maskId = `star-mask-${this._id}`;

        return (
            <SvgIcon {...other}>
                <Fragment>
                    <defs>
                        <linearGradient id={gradientId}>
                            <stop offset={offset} stopColor="white" stopOpacity="1" />
                            <stop offset={offset} stopColor="white" stopOpacity="0" />
                        </linearGradient>
                        <mask id={maskId}>
                            <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" style={{ transform: 'scale(10)', transformOrigin: 'center' }} fill={`url(#${gradientId})`} />
                        </mask>
                    </defs>
                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" fill="#e5eaed" />
                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" mask={`url(#${maskId})`} />
                </Fragment>
            </SvgIcon>
        );
    }
}

export default pure(Star);
