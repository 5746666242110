// @flow
export const SELECT = 'g/customerList/SELECT';
export const DESELECT = 'g/customerList/DESELECT';
export const DESELECT_ALL = 'g/customerList/DESELECT_ALL';

export const TOGGLE_FILTER_DRAWER = 'g/customerList/TOGGLE_FILTER_DRAWER';

export const FETCH_DATA = 'g/customerList/FETCH_DATA';
export const FETCH_DATA_SUCCESS = `${FETCH_DATA}_SUCCESS`;
export const FETCH_DATA_ERROR = `${FETCH_DATA}_ERROR`;

export const LOAD_FILTERS = 'g/customerList/LOAD_FILTERS';
export const LOAD_FILTERS_SUCCESS = `${LOAD_FILTERS}_SUCCESS`;
export const LOAD_FILTERS_ERROR = `${LOAD_FILTERS}_ERROR`;

export const CHANGE_ROLE = 'g/customerLst/CHANGE_ROLE';
export const CHANGE_ROLE_SUCCESS = `${CHANGE_ROLE}_SUCCESS`;
export const CHANGE_ROLE_ERROR = `${CHANGE_ROLE}_ERROR`;

export default {
    CHANGE_ROLE,
    CHANGE_ROLE_ERROR,
    CHANGE_ROLE_SUCCESS,
    DESELECT,
    DESELECT_ALL,
    FETCH_DATA,
    FETCH_DATA_ERROR,
    FETCH_DATA_SUCCESS,
    LOAD_FILTERS,
    LOAD_FILTERS_ERROR,
    LOAD_FILTERS_SUCCESS,
    SELECT,
    TOGGLE_FILTER_DRAWER,
};
