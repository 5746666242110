// @flow
export default (theme: Object) => ({
    chip: {
        fontFamily: 'ProximaNova',
        fontSize: 14,
        color: theme.palette.primary.main,
        background: theme.palette.common.white,
        marginTop: theme.spacing(1),
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
    },
    chipLabel: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    checkIcon: {
        color: '#00c7ae', // $success-color,
        marginLeft: 5,
    },
    loading: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.24)',
    },
});
