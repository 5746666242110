// @flow
import * as yup from 'yup';
import moment from 'moment';
import sanitizeHtml from 'sanitize-html';
import i18next from 'i18next';

yup.addMethod(yup.mixed, 'skipValidation', function skipValidation() {
    this._skipValidation = true;
    return this;
});

const _validate = yup.mixed.prototype.validate;
yup.addMethod(yup.mixed, 'validate', function validate(...args) {
    let promise = _validate.call(this, ...args);
    if (this._skipValidation) {
        promise = promise.catch((err) => {
            if (err instanceof yup.ValidationError) {
                return err.value;
            }
            throw err;
        });
    }
    return promise;
});

const isAbsent = (value: any): boolean => value == null;

const QuestionListSchema = yup.object().shape({
    description: yup.string().required('validation.required'),
    propositions: yup.array()
        .of(
            yup.object()
                .shape({
                    choice: yup.string().required('validation.required'),
                    alert: yup.boolean(),
                })
                .test({
                    message: '${path} must be unique', // eslint-disable-line no-template-curly-in-string
                    name: 'unique',
                    exclusive: true,
                    test(value) {
                        if (!value || !value.choice) {
                            return true;
                        }

                        const notUnique = this.parent
                            .filter((v) => v !== value)
                            .some((v) => v.choice === value.choice);

                        if (notUnique) {
                            const message = i18next.t('projectBuilder.data.validation.proposition.notUnique');
                            return this.createError({ message });
                        }

                        return true;
                    },
                })
        )
        .when('valueType', {
            is: (value) => value === 'MULTIPLE_CHOICE' || value === 'MULTI_SELECT',
            then: yup.array().min(2),
        }),
    questionText: yup.string()
        .test({
            message: 'validation.required',
            name: 'required',
            exclusive: true,
            test(value) {
                const textContent = typeof value === 'string'
                    ? sanitizeHtml(value, { allowedTags: [], allowedAttributes: [] })
                    : value;

                return this.schema._isPresent(textContent);
            },
        }),
    valueType: yup.string(),
});

const ProjectBuilderSchema = yup.object().shape({
    asapEndDate: yup.date()
        .transform((value, originalValue) => (originalValue === '' ? null : value))
        .nullable()
        .when('scheduleType', {
            is: 'ASAP',
            then: yup.date()
                .required('validation.required')
                .test({
                    message: '${path} field must be later than ${min}', // eslint-disable-line no-template-curly-in-string
                    name: 'min',
                    exclusive: true,
                    test(value) {
                        if (isAbsent(value)) {
                            return true;
                        }

                        const { context } = this.options;
                        const subscription = context ? context.subscription : null;
                        let limit;

                        if (subscription && subscription.state === 'ACTIVE') {
                            const startDate = moment(subscription.start_date);
                            const diffAsHours = moment().diff(startDate, 'hours');
                            limit = startDate.add(1 - diffAsHours, 'hours');
                        } else {
                            limit = moment().add(1, 'hours');
                        }

                        if (!moment(value).isSameOrAfter(limit)) {
                            const options = { n: limit.diff(moment(), 'hours') };
                            const message = i18next.t('projectBuilder.info.validation.asapEndDate.lessThanNHoursAfterDate', options);
                            return this.createError({ message });
                        }

                        return true;
                    },
                }),
        }),
    description: yup.string()
        .test({
            message: 'validation.required',
            name: 'required',
            exclusive: true,
            test(value) {
                const textContent = typeof value === 'string'
                    ? sanitizeHtml(value, { allowedTags: [], allowedAttributes: [] })
                    : value;

                return this.schema._isPresent(textContent);
            },
        })
        .test({
            message: '${path} must be at least ${min} characters', // eslint-disable-line no-template-curly-in-string
            name: 'min',
            exclusive: true,
            params: { min: 10 },
            test(value) {
                const textContent = typeof value === 'string'
                    ? sanitizeHtml(value, { allowedTags: [], allowedAttributes: [] })
                    : value;

                return isAbsent(textContent) || textContent.length >= 10;
            },
        }),
    rangeStartDate: yup.date()
        .transform((value, originalValue) => (originalValue === '' ? null : value))
        .nullable()
        .when('scheduleType', {
            is: 'RANGE',
            then: yup.date()
                .required('validation.required')
                .test({
                    message: '${path} field must be earlier than ${max}', // eslint-disable-line no-template-curly-in-string
                    name: 'max',
                    exclusive: true,
                    test(value) {
                        if (isAbsent(value)) {
                            return true;
                        }

                        const rangeEndDate = this.resolve(yup.ref('rangeEndDate'));
                        const limit = moment(rangeEndDate).subtract(1, 'hours');

                        if (!moment(value).isSameOrBefore(limit)) {
                            const options = { n: moment(rangeEndDate).diff(limit, 'hours') };
                            const message = i18next.t('projectBuilder.info.validation.rangeStartDate.lessThanNHoursBeforeDate', options);
                            return this.createError({ message });
                        }

                        return true;
                    },
                }),
        }),
    rangeEndDate: yup.date()
        .transform((value, originalValue) => (originalValue === '' ? null : value))
        .nullable()
        .when('scheduleType', {
            is: 'RANGE',
            then: yup.date()
                .required('validation.required')
                .test({
                    message: '${path} field must be later than ${min}', // eslint-disable-line no-template-curly-in-string
                    name: 'min',
                    exclusive: true,
                    test(value) {
                        if (isAbsent(value)) {
                            return true;
                        }

                        const rangeStartDate = this.resolve(yup.ref('rangeStartDate'));
                        const limit = moment(rangeStartDate).add(1, 'hours');

                        if (!moment(value).isSameOrAfter(limit)) {
                            const options = { n: limit.diff(moment(rangeStartDate), 'hours') };
                            const message = i18next.t('projectBuilder.info.validation.rangeEndDate.lessThanNHoursAfterDate', options);
                            return this.createError({ message });
                        }

                        return true;
                    },
                }),
        }),
    scheduleType: yup.string().required(),
    title: yup.string()
        .required('validation.required')
        .min(3),
    locationCount: yup.number().min(1),
    locationListId: yup.number(),
    needsPublicWorkforce: yup.boolean(),
    needsApproval: yup.boolean(),
    twoWayRatingEnabled: yup.boolean(),
    optinType: yup.string(),
    autoassign: yup.boolean(),
    certifications: yup.array(),
    groups: yup.array()
        .when('needsPublicWorkforce', {
            is: false,
            then: yup.array().min(1),
        }),
    questionList: yup.array().of(QuestionListSchema).min(1),
    amountDue: yup.number()
        .transform((value, originalValue) => (originalValue === '' ? null : value))
        .nullable()
        .when('needsPublicWorkforce', {
            is: true,
            then: yup.number().required().max(yup.ref('$organization.current_balance')),
        }),
    gigPrice: yup.number()
        .transform((value, originalValue) => (originalValue === '' ? null : value))
        .nullable()
        .when('needsPublicWorkforce', {
            is: true,
            then: yup.number()
                .required('validation.required')
                .moreThan(0, ({ more }) => (
                    i18next.t('validation.notGreaterThan', { value: more })
                )),
        }),
    nearTicketDistance: yup.number()
        .transform((value, originalValue) => (originalValue === '' ? null : value))
        .nullable()
        .when('needsPublicWorkforce', {
            is: true,
            then: yup.number()
                .required('validation.required')
                .moreThan(0, ({ more }) => (
                    i18next.t('validation.notGreaterThan', { value: more })
                )),
        }),
    oversamplingOverage: yup.number()
        .transform((value, originalValue) => (originalValue === '' ? null : value))
        .nullable()
        .when('needsPublicWorkforce', {
            is: true,
            then: yup.number()
                .when('oversamplingTarget', {
                    is: (value) => value,
                    then: yup.number()
                        .required('validation.required')
                        .min(yup.ref('oversamplingTarget'), ({ min }) => (
                            i18next.t('validation.notGreaterThanOrEqual', { value: min })
                        )),
                    otherwise: yup.number()
                        .moreThan(0, ({ more }) => (
                            i18next.t('validation.notGreaterThan', { value: more })
                        )),
                }),
        }),
    oversamplingTarget: yup.number()
        .transform((value, originalValue) => (originalValue === '' ? null : value))
        .nullable()
        .when('needsPublicWorkforce', {
            is: true,
            then: yup.number()
                .when('oversamplingOverage', {
                    is: (value) => value,
                    then: yup.number()
                        .required('validation.required')
                        .min(0, ({ min }) => (
                            i18next.t('validation.notGreaterThanOrEqual', { value: min })
                        )),
                    otherwise: yup.number()
                        .moreThan(0, ({ more }) => (
                            i18next.t('validation.notGreaterThan', { value: more })
                        )),
                }),
        }),
    submitDeadline: yup.date()
        .transform((value, originalValue) => (originalValue === '' ? null : value))
        .nullable()
        .when('scheduleType', {
            is: 'RANGE',
            then: yup.date().min(yup.ref('rangeEndDate')),
        })
        .when('scheduleType', {
            is: 'ASAP',
            then: yup.date().min(yup.ref('asapEndDate')),
        }),
    resWindowHours: yup.array()
        .of(
            yup.number()
                .transform((value, originalValue) => (originalValue === '' ? null : value))
                .nullable()
                .required('validation.required')
                .min(0.5, ({ min }) => (
                    i18next.t('validation.notGreaterThanOrEqual', { value: min })
                ))
                .max(336, ({ max }) => (
                    i18next.t('validation.notLessThanOrEqual', { value: max })
                ))
                .test({
                    message: '${path} must be unique', // eslint-disable-line no-template-curly-in-string
                    name: 'unique',
                    exclusive: true,
                    test(value) {
                        if (isAbsent(value)) {
                            return true;
                        }

                        let index = -1;
                        const notUnique = this.parent
                            .filter((v, i) => {
                                if (v === value) {
                                    index = index >= 0 ? index : i;
                                    return i !== index;
                                }
                                return true;
                            })
                            .some((v) => v === value);

                        if (notUnique) {
                            // const message = i18next.t('projectBuilder.data.validation.proposition.notUnique');
                            return this.createError(/* { message } */);
                        }

                        return true;
                    },
                })
        ),
    fifoAssign: yup.boolean(),
    allowGalleryUpload: yup.boolean(),
}, ['oversamplingOverage', 'oversamplingTarget']);

export default () => ProjectBuilderSchema;
