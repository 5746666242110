// @flow
import reduceReducers from 'reduce-reducers';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import config from '../../client/ducks/config';
import dialog from '../../client/ducks/dialog';
import entities from '../../client/ducks/gigwalk';
import gigwalkerList from '../../client/ducks/gigwalkerList';
import googleMaps from '../../client/ducks/googleMaps';
import header from '../../client/ducks/header';
import profile from '../../client/ducks/profile';
import session from '../../client/ducks/session';
import sideNav from '../../client/ducks/sideNav';
import snackbar from '../../client/ducks/snackbar';
import subscriptionList from '../../client/ducks/subscriptionList';
import ticketDetail from '../../client/ducks/ticketDetail';
import ticketList from '../../client/ducks/ticketList';
import legacyAddFunds from '../containers/AddFunds/duck';
import legacyConfig from '../ducks/config';
import legacyCustomerList from '../containers/CustomerList/duck';
import legacyDialog from '../ducks/dialog';
import legacyEntities from './entities';
import legacyGigwalkerList from '../containers/GigwalkerList/duck';
import legacyGoogleMaps from '../ducks/googleMaps';
import legacyLayout from '../containers/Layout/duck';
import legacyLocationListUpload from '../../browser/shared/components/LocationListUpload/redux';
import legacyPayoutList from '../containers/PayoutList/duck';
import legacyProfilePage from '../containers/ProfilePage/duck';
import legacyProjectBuilder from '../containers/ProjectBuilder/duck';
import legacyProjectSummary from '../../browser/dashboard/view/active/Summary/duck';
import legacyProjectManagement from '../../browser/dashboard/view/active/Management/duck';
import legacySession from '../ducks/session';
import legacySignup from '../containers/Signup/duck';
import legacySnackbar from '../ducks/snackbar';
import legacySubscriptionList from '../containers/SubscriptionList/duck';
import legacyTicketList from '../containers/TicketList/duck';
import legacyTransactionList from '../containers/TransactionList/duck';
import legacyUi from '../ducks/ui';

const allReducers = {
    addFunds: legacyAddFunds,
    config: reduceReducers(legacyConfig, config),
    customerList: legacyCustomerList,
    dialog: reduceReducers(legacyDialog, dialog),
    entities: reduceReducers(legacyEntities, entities),
    gigwalkerList: reduceReducers(legacyGigwalkerList, gigwalkerList),
    googleMaps: reduceReducers(legacyGoogleMaps, googleMaps),
    header,
    layout: legacyLayout,
    locationListUpload: legacyLocationListUpload,
    payoutList: legacyPayoutList,
    profile,
    profilePage: legacyProfilePage,
    projectBuilder: legacyProjectBuilder,
    projectSummary: legacyProjectSummary,
    projectManagement: legacyProjectManagement,
    session: reduceReducers(legacySession, session),
    sideNav,
    signup: legacySignup,
    snackbar: reduceReducers(legacySnackbar, snackbar),
    subscriptionList: reduceReducers(legacySubscriptionList, subscriptionList),
    ticketDetail,
    ticketList: reduceReducers(legacyTicketList, ticketList),
    transactionList: legacyTransactionList,
    ui: legacyUi,
};

export default (history: Object) => combineReducers({
    ...allReducers,
    router: connectRouter(history),
});
