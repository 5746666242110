// @flow
export default (theme: Object) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    section: {
        marginBottom: theme.spacing(3),

        '& > fieldset': {
            margin: 0,
        },
    },
    sectionHeader: {
        textTransform: 'uppercase',
    },
    submitButton: {
        marginLeft: 'auto',
    },
    readOnly: {
        pointerEvents: 'none',
    },
});
