// @flow
import React from 'react';
import cx from 'classnames';
import type { Node } from 'react';

type Props = {
    children: Node,
    classes: Object,
    className?: string,
    data: Object[],
    loading: boolean,
    selected: [],
};

export function Row(props: Props) {
    const { children, classes, className, data, loading, selected, ...other } = props;
    return (
        <div {...other} className={cx(classes.row, className)}>
            {children}
        </div>
    );
}

export default Row;
