// @flow
// $FlowIssue need to update to a more recent flow version
import React, { Fragment, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardHeader,
    IconButton,
} from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import type { ChildrenArray } from 'react';
import UserAvatar from '../../../../components/UserAvatar';
import getDisplayName from '../../../../utils/getDisplayName';
import styles from './styles';

type Props = {
    id: string,
    author: Object,
    children: ChildrenArray<any>,
    date: string,
    onDelete?: (id: string) => void,
};

const useStyles = makeStyles(styles, { name: 'Comment' });

export default function Comment(props: Props) {
    const { author, children, date, id, onDelete } = props;

    const classes = useStyles(props);

    const handleDelete = useCallback(() => {
        if (typeof onDelete === 'function') {
            onDelete(id);
        }
    }, [id, onDelete]);

    const displayName = getDisplayName(author);
    const title = (
        <Fragment>
            <span className={classes.author}>
                {author.profile_link
                    ? <Link to={author.profile_link}>{displayName}</Link>
                    : displayName}
            </span>
            <span className={classes.date}>{date}</span>
        </Fragment>
    );

    return (
        <Card elevation={0}>
            <CardHeader
              classes={{
                  action: classes.action,
                  avatar: classes.avatar,
                  subheader: classes.content,
                  title: classes.header,
              }}
              avatar={<UserAvatar user={author} />}
              action={typeof onDelete === 'function'
                  ? (
                      <IconButton onClick={handleDelete}>
                          <DeleteIcon />
                      </IconButton>
                  )
                  : null}
              title={title}
              subheader={children}
            />
        </Card>
    );
}
