// @flow
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import type { ContextRouter } from 'react-router';
import { USER_ROLES } from '../../../../../../browser/shared/constant/UserRoles';
import {
    customer as customerSchema,
    subscription as subscriptionSchema,
} from '../../../../../redux/entities/schemas';
import type { State as RootState } from '../../../../../redux/initialState';

const getUser = (state: RootState): ?Object => state.session.user;
const getEntities = (state: RootState): Object => state.entities;
const getSubscriptionIds = (state: RootState): number[] => state.profilePage.preferences.subscriptionIds;
const getCustomerId = <P: {}>(state: RootState, props: { ...P, ...ContextRouter }): number => {
    const { match } = props;
    return parseInt(match.params.customerId, 10);
};

export const getCustomer = createSelector(
    getUser,
    getEntities,
    getCustomerId,
    (user: ?Object, entities: Object, customerId: number) => (
        user && user.id === customerId ? user : denormalize(customerId, customerSchema, entities)
    )
);

export const getSubscriptions = createSelector(
    getEntities,
    getSubscriptionIds,
    (entities: Object, subscriptionsIds: number[]) => denormalize(subscriptionsIds, [subscriptionSchema], entities),
);

export const getInitialValues = createSelector(
    getCustomer,
    (customer: Object) => {
        if (!customer) {
            return {
                disabledNotifications: [],
                enabledNotifications: [],
                idealWeeklyHours: '',
                id: null,
                maxWeeklyHours: '',
            };
        }

        const {
            id,
            ideal_hours_week: idealWeeklyHours,
            max_hours_week: maxWeeklyHours,
            notification_preferences: notificationPreferences,
        } = customer;

        const { disabled, enabled } = notificationPreferences;
        const enabledNotifications = enabled.sort();
        const disabledNotifications = disabled.sort();

        return {
            disabledNotifications,
            enabledNotifications,
            id,
            idealWeeklyHours: idealWeeklyHours || '',
            maxWeeklyHours: maxWeeklyHours || '',
        };
    }
);

export const isReadOnly = createSelector(
    getUser,
    getCustomer,
    (user: ?Object, customer: Object) => (
        user ? user.id !== customer.id && user.role !== USER_ROLES.PLATFORM_ADMIN : true
    )
);

export default {
    getInitialValues,
    getSubscriptions,
    isReadOnly,
};
