// @flow
import React, { Component } from 'react';
import moment from 'moment';
import cx from 'classnames';
import { SingleDatePickerInput } from 'react-dates';
import { withStyles } from '@material-ui/styles';
import { FormControl, FormHelperText, FormLabel } from '@material-ui/core';
import reactDatesTheme from './theme.scss';

type Props = {
    className?: string,
    classes: Object,
    disabled?: boolean,
    error?: boolean | string,
    format?: string,
    label?: string,
    onChange?: (value: string) => void,
    onFocus?: () => void,
    onBlur?: () => void,
    placeholder?: string,
    value?: string | moment$Moment | Date,
};

const defaultInputProps = {
    placeholder: '',
    showDefaultInputIcon: false,
    small: true,
};

const styles = () => ({
    error: {
        marginTop: 5,
        lineHeight: 'inherit',
    },
});

class DateInput extends Component<Props> {
    handleChange = (value: string) => {
        const { onChange } = this.props;
        if (typeof onChange === 'function') {
            onChange(moment(value).format('YYYY-MM-DD'));
        }
    };

    handleClearDate = () => {
        const { onChange } = this.props;
        if (typeof onChange === 'function') {
            onChange('');
        }
    };

    render() {
        const { className, classes, error, label, value, ...inputProps } = this.props;
        const { disabled } = inputProps;
        const invalid = !!error && !disabled;

        let date = moment(value);
        date = date.isValid() ? date : null;

        return (
            <FormControl
              className={cx(reactDatesTheme.dateInput, { invalid, disabled }, className)}
              margin={label ? 'normal' : 'none'}
              error={invalid}
            >
                {label ? <FormLabel>{label}</FormLabel> : null}
                <SingleDatePickerInput
                  {...defaultInputProps}
                  {...inputProps}
                  displayValue={date ? date.format('M/D/YYYY h:mma') : ''}
                  onChange={this.handleChange}
                  onClearDate={this.handleClearDate}
                />
                {typeof error === 'string'
                    ? <FormHelperText className={classes.error}>{error}</FormHelperText>
                    : null}
            </FormControl>
        );
    }
}

export default withStyles(styles, { name: 'DateInput' })(DateInput);
