// @flow
const namespace = 'g2/config';

export const FETCH_API_CONFIG = `${namespace}/FETCH_API_CONFIG`;
export const FETCH_API_CONFIG_SUCCESS = `${FETCH_API_CONFIG}_SUCCESS`;
export const FETCH_API_CONFIG_ERROR = `${FETCH_API_CONFIG}_ERROR`;

export default {
    FETCH_API_CONFIG,
    FETCH_API_CONFIG_SUCCESS,
    FETCH_API_CONFIG_ERROR,
};
