// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useMemo } from 'react';
import { Tooltip } from '@material-ui/core';
import {
    Android as AndroidIcon,
    Apple as AppleIcon,
    DeviceUnknown as DeviceUnknownIcon,
    WebAsset as WebAssetIcon,
} from '@material-ui/icons';
import parseUserAgent from '../../../../utils/parseUserAgent';

type Props = {
    dataItem: Object,
};

export default function DeviceTooltip(props: Props) {
    const { dataItem } = props;
    const { user_agent: userAgent } = dataItem;

    const { device, browser, os } = useMemo(() => {
        const result = parseUserAgent(userAgent);
        return result;
    }, [userAgent]);

    let Icon = DeviceUnknownIcon;
    let iconFontSize = '1.15rem';
    let tooltip = 'Answered on an unknown device';

    if (device.type === 'mobile' && !browser.name) {
        switch (os.name) {
            case 'Android':
                Icon = AndroidIcon;
                iconFontSize = '1rem';
                tooltip = `Answered on an Android device (${device.model})`;
                break;
            case 'iOS':
                Icon = AppleIcon;
                iconFontSize = '1.2rem';
                tooltip = `Answered on an iOS device (${device.model})`;
                break;
            default:
                break;
        }
    } else if (browser.name) {
        Icon = WebAssetIcon;
        iconFontSize = '1.25rem';
        tooltip = 'Answered on the web app';
    }

    return (
        <Tooltip title={tooltip} placement="left">
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Icon htmlColor="#8e9499" style={{ fontSize: iconFontSize }} />
            </div>
        </Tooltip>
    );
}
