// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { findDOMNode } from 'react-dom';
import noop from 'lodash/noop';
import cx from 'classnames';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import {
    FilledInput,
    FormControl,
    FormHelperText,
    FormLabel,
    OutlinedInput,
    Input,
    InputLabel,
} from '@material-ui/core';
import styles from './styles';

type Props = {
    classes?: Object,
    className?: string,
    disabled?: boolean,
    fullWidth?: boolean,
    label?: string,
    labelPlacement: 'default' | 'top',
    margin?: string,
    onBlur: (event: SyntheticInputEvent<any>) => void,
    onChange: (event: SyntheticInputEvent<any>) => void,
    required?: boolean,
    variant: 'standard' | 'outlined' | 'filled',
};

const useStyles = makeStyles(styles, { name: 'FormikInput' });

const variantComponent = {
    standard: Input,
    filled: FilledInput,
    outlined: OutlinedInput,
};

export default function FormikInput(props: Props) {
    const {
        classes: classesProp,
        className,
        disabled,
        fullWidth,
        label,
        labelPlacement,
        margin,
        onBlur,
        onChange,
        required,
        variant,
        ...other
    } = props;

    const classes = useStyles(props);
    const { i18n, t } = useTranslation();
    const [field, meta] = useField(props);

    const [labelWidth, setLabelWidth] = useState(0);
    const labelRef = useRef(null);

    useEffect(() => {
        if (variant === 'outlined' && labelPlacement === 'default') {
            const labelNode = findDOMNode(labelRef.current);
            setLabelWidth(labelNode instanceof HTMLElement ? labelNode.offsetWidth : 0);
        }
    }, [label, labelPlacement, required, variant]);

    const { error, touched } = meta;
    const {
        onBlur: fieldOnBlur,
        onChange: fieldOnChange,
    } = field;

    const handleBlur = useCallback((event: SyntheticInputEvent<any>) => {
        onBlur(event);
        if (!event.defaultPrevented) {
            fieldOnBlur(event);
        }
    }, [fieldOnBlur, onBlur]);

    const handleChange = useCallback((event: SyntheticInputEvent<any>) => {
        onChange(event);
        if (!event.defaultPrevented) {
            fieldOnChange(event);
        }
    }, [fieldOnChange, onChange]);

    let errorMessage = null;
    if (touched && error) {
        if (typeof error === 'string') {
            errorMessage = i18n.exists(error) ? t(error) : error;
        } else if ('message' in error) {
            const { message, ...translateOptions } = error;
            errorMessage = i18n.exists(message) ? t(message, { ...translateOptions }) : message;
        }
    }

    const {
        root: rootClassName,
        label: labelClassName,
        input: inputClassName,
        errorMessage: errorClassName,
        ...otherClasses
    } = classes;

    const LabelComponent = labelPlacement === 'top' ? FormLabel : InputLabel;
    const InputComponent = variantComponent[variant] || Input;

    return (
        <FormControl
          className={cx(rootClassName, className)}
          disabled={disabled}
          error={!!errorMessage}
          fullWidth={fullWidth}
          margin={margin}
          required={required}
          variant={variant}
        >
            {label
                ? <LabelComponent className={labelClassName} ref={labelRef}>{label}</LabelComponent>
                : null}
            <InputComponent
              {...other}
              {...field}
              {...(variant === 'outlined' ? { labelWidth } : undefined)}
              classes={{ root: inputClassName, ...otherClasses }}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errorMessage
                ? <FormHelperText className={errorClassName}>{errorMessage}</FormHelperText>
                : null}
        </FormControl>
    );
}

FormikInput.defaultProps = {
    labelPlacement: 'default',
    onBlur: noop,
    onChange: noop,
    variant: 'standard',
};
