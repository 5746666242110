// @flow
import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import reduceReducers from 'reduce-reducers';
import types from './types';
import locations, { init as locationsInitState } from '../containers/Locations/duck/reducer';
import questions, { init as questionsInitState } from '../containers/Questions/duck/reducer';
import launch, { init as launchInitState } from '../containers/Launch/duck/reducer';
import type { State as LocationsState } from '../containers/Locations/duck/reducer';
import type { State as QuestionsState } from '../containers/Questions/duck/reducer';
import type { State as LaunchState } from '../containers/Launch/duck/reducer';

export type State = {
    launch: LaunchState,
    locations: LocationsState,
    questions: QuestionsState,
};

export const init: State = {
    launch: launchInitState,
    locations: locationsInitState,
    questions: questionsInitState,
};

const reducer = handleActions({
    [types.RESET_STATE]: () => ({
        ...init,
    }),
}, init);

export default reduceReducers(
    reducer,
    combineReducers({
        locations,
        questions,
        launch,
    })
);
