// @flow
export default (theme: Object) => ({
    paper: {
        maxWidth: 540,
        maxHeight: 1000,
        padding: theme.spacing(4, 3),
        margin: [[0, 'auto', theme.spacing(3)]],
        color: theme.palette.common.black,

        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(4, 2),
        },
    },
    error: {
        padding: theme.spacing(1),
        border: [[2, 'solid', theme.palette.error.main]],
        color: theme.palette.error.main,
        marginBottom: theme.spacing(1),
    },
    fieldset: {
        textAlign: 'left',
        margin: theme.spacing(0, 0, 3),

        '& > div': {
            display: 'flex',
            flexFlow: 'row wrap',
            justifyContent: 'space-between',
            alignItems: 'flex-start',

            '& > *:not(:last-child)': {
                marginBottom: theme.spacing(2),
            },
        },
    },
    firstName: {
        width: '48%',

        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    lastName: {
        width: '48%',

        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    submitButton: {
        boxShadow: ['none', '!important'],
        width: 180,
    },
    footer: {
        display: 'flex',
        justifyContent: 'center',
        maxWidth: 400,
        margin: [[0, 'auto']],
        color: theme.palette.primary.contrastText,
        fontWeight: 300,
        lineHeight: 1.2,

        '& > *': {
            flex: 1,
            padding: theme.spacing(1.5, 0),
        },

        '& $link': {
            color: 'inherit',
            fontSize: 18,
            textDecoration: 'underline',
        },
    },
    link: {},
});
