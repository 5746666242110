var Handlebars = require('/tmp/build_a9d70681abb25b82ca4489607bd6ccee/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	    <div class=\"answer\">\n	      <textarea data-input name=\"answer text\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"text") || (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text","hash":{},"data":data,"loc":{"start":{"line":6,"column":54},"end":{"line":6,"column":64}}}) : helper)))
    + "\" data-action=\"text\" placeholder=\""
    + alias4(container.lambda(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"placeholders") : depths[1])) != null ? lookupProperty(stack1,"enter_your_answer") : stack1), depth0))
    + "\" >"
    + alias4(((helper = (helper = lookupProperty(helpers,"text") || (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text","hash":{},"data":data,"loc":{"start":{"line":6,"column":140},"end":{"line":6,"column":150}}}) : helper)))
    + "</textarea>\n	    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- question type is Free Text -->\n<div class=\"type text\">\n  <div class=\"answers\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"answers") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":3},"end":{"line":8,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n</div><!-- END free text -->\n<div class=\"validation error\" data-target=\"validationHint\">\n  <!--\n    \"Must be a number\"\n    \"Must be at least 2 characters long\"\n  -->\n</div>";
},"useData":true,"useDepths":true});