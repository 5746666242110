// @flow
const namespace = 'g2/googleMaps';

export const GET_PLACE_DETAILS = `${namespace}/GET_PLACE_DETAILS`;
export const GET_PLACE_DETAILS_SUCCESS = `${GET_PLACE_DETAILS}_SUCCESS`;
export const GET_PLACE_DETAILS_ERROR = `${GET_PLACE_DETAILS}_ERROR`;

export const GET_PLACE_PREDICTIONS = `${namespace}/GET_PLACE_PREDICTIONS`;
export const GET_PLACE_PREDICTIONS_SUCCESS = `${GET_PLACE_PREDICTIONS}_SUCCESS`;
export const GET_PLACE_PREDICTIONS_ERROR = `${GET_PLACE_PREDICTIONS}_ERROR`;

export const FIND_PLACE_FROM_QUERY = `${namespace}/FIND_PLACE_FROM_QUERY`;
export const FIND_PLACE_FROM_QUERY_SUCCESS = `${FIND_PLACE_FROM_QUERY}_SUCCESS`;
export const FIND_PLACE_FROM_QUERY_ERROR = `${FIND_PLACE_FROM_QUERY}_ERROR`;

export const GEOCODE = `${namespace}/GEOCODE`;
export const GEOCODE_SUCCESS = `${GEOCODE}_SUCCESS`;
export const GEOCODE_ERROR = `${GEOCODE}_ERROR`;

export default {
    FIND_PLACE_FROM_QUERY,
    FIND_PLACE_FROM_QUERY_ERROR,
    FIND_PLACE_FROM_QUERY_SUCCESS,
    GEOCODE,
    GEOCODE_SUCCESS,
    GEOCODE_ERROR,
    GET_PLACE_DETAILS,
    GET_PLACE_DETAILS_ERROR,
    GET_PLACE_DETAILS_SUCCESS,
    GET_PLACE_PREDICTIONS,
    GET_PLACE_PREDICTIONS_ERROR,
    GET_PLACE_PREDICTIONS_SUCCESS,
};
