// @flow
import React, { Fragment } from 'react';
import { ListItemAvatar, ListItemText } from '@material-ui/core';
import {
    Check as CheckIcon,
    Search as SearchIcon,
} from '@material-ui/icons';
import highlightMatch from '../../../../util/highlightMatch';
import UserAvatar from '../../../../components/UserAvatar';
import Select from '../../../../components/Select';

type Props = {};

const formatOptionLabel = (option: Object, meta: Object) => {
    const { label, value } = option;
    const primary = highlightMatch(label, meta.inputValue);
    const secondary = highlightMatch(value.email, meta.inputValue);

    return (
        <Fragment>
            <ListItemAvatar>
                <UserAvatar user={value} />
            </ListItemAvatar>
            <ListItemText primary={primary} secondary={secondary} />
            {meta.isSelected ? <CheckIcon /> : null}
        </Fragment>
    );
};

export default (props: Props) => (
    <Select
      {...props}
      backspaceRemovesValue={false}
      ControlProps={{ fullWidth: true }}
      controlShouldRenderValue={false}
      dropdownIcon={<SearchIcon />}
      formatOptionLabel={formatOptionLabel}
      menuIsOpen
      menuType="list"
      minMenuHeight={400}
      maxMenuHeight={400}
    />
);
