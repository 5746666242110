// @flow
// $FlowIssue need to update to a more recent flow version
import React, { cloneElement, forwardRef } from 'react';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Tooltip } from '@material-ui/core';
import { OpenInNew as OpenInNewIcon } from '@material-ui/icons';
import type { LocationShape } from 'react-router';
import styles from './styles';

type Props = {
    href?: string,
    to?: string | LocationShape,
    isActive?: Function,
    className?: string,
    icon: React$Element<any>,
    label: string,
    mini?: boolean,
};

/* eslint-disable jsx-a11y/anchor-has-content, react/no-multi-comp */
const RouterNavLink = forwardRef((props, ref) => <NavLink innerRef={ref} {...props} />);
const ExternalLink = forwardRef((props, ref) => <a ref={ref} {...props} />);
/* eslint-enable */

const useStyles = makeStyles(styles, { name: 'NavItem' });

export default function NavItem(props: Props) {
    const { className, href, icon, isActive, label, mini, to } = props;

    const classes = useStyles();

    const isExternal = typeof href !== 'undefined';
    const Link = isExternal ? ExternalLink : RouterNavLink;

    let linkProps = { className: cx(classes.navItem, className) };
    if (isExternal) {
        linkProps = { ...linkProps, href, target: '_blank' };
    } else {
        linkProps = { ...linkProps, activeClassName: classes.active, isActive, to };
    }

    return mini
        ? (
            <Tooltip title={label} placement="right">
                <Link {...linkProps}>
                    {cloneElement(icon, { className: classes.icon })}
                </Link>
            </Tooltip>
        )
        : (
            <Link {...linkProps}>
                {cloneElement(icon, { className: classes.icon })}
                <span>{label}</span>
                {isExternal
                    ? (
                        <span className={classes.externalLinkIcon}>
                            <OpenInNewIcon />
                        </span>
                    )
                    : null}
            </Link>
        );
}
