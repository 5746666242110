// @flow
import { createAction } from 'redux-actions';
import axios from 'axios';
import type { Dispatch } from 'redux';
import type { $AxiosXHR, $AxiosError } from 'axios';
import { client as gigwalk } from '../../../client/api/createGigwalkClient';
import { client as legacyGigwalk } from '../../api/createGigwalkClient';
import logger from '../../util/logger';
import types from './types';
import type { State as RootState } from '../../redux/initialState';

const heartRate = 1000 * 60 * 60;
let pulse: ?number = null;

export const initialize = createAction(
    types.INITIALIZE,
    (payload: Object) => {
        const { user } = payload;
        gigwalk.authenticate({ token: user ? user.auth_token : '' });
        legacyGigwalk.authenticate({ token: user ? user.auth_token : '' });
        return payload;
    },
);

export const logout = createAction(
    types.LOGOUT,
    () => {
        if (global.__SERVER__) {
            logger.warn('You can only log out of an active session');
            return;
        }

        gigwalk.authenticate({ token: '' });
        legacyGigwalk.authenticate({ token: '' });

        if (pulse) {
            clearTimeout(pulse);
            pulse = null;
            document.location.assign('/logout');
        }
    }
);

export const refreshSuccess = createAction(types.REFRESH_SUCCESS);
export const refreshError = createAction(types.REFRESH_ERROR);
export const refresh = createAction(
    types.REFRESH,
    (): Function => (
        (dispatch: Dispatch<*>): Promise<Object> => {
            if (global.__SERVER__) {
                logger.warn('You can only refresh an active session');
                dispatch(refreshError());
                return Promise.reject();
            }

            return axios.get('/auth/session')
                .then((resp: $AxiosXHR<*>) => {
                    const session = resp.data;
                    dispatch(refreshSuccess(session));
                    return Promise.resolve(session);
                })
                .catch((err: $AxiosError<*>) => {
                    logger.error(err);
                    dispatch(refreshError(err));
                    dispatch(logout());
                    return Promise.reject(err);
                });
        }
    )
);

export const start = createAction(
    types.START,
    (): Function => (
        (dispatch: Dispatch<*>, getState: () => RootState) => {
            if (global.__SERVER__) {
                logger.warn('You can only start a session on the client');
                return;
            }

            // Authenticate both here and in `initialize` in case user is already logged
            // in (and session state has already been initialized)
            const state = getState();
            const { user } = state.session;
            gigwalk.authenticate({ token: user ? user.auth_token : '' });
            legacyGigwalk.authenticate({ token: user ? user.auth_token : '' });

            const heartbeat = () => {
                dispatch(refresh());
                pulse = setTimeout(heartbeat, heartRate);
            };
            clearTimeout(pulse);
            pulse = setTimeout(heartbeat, heartRate);
        }
    )
);

export default {
    initialize,
    logout,
    refresh,
    refreshError,
    refreshSuccess,
    start,
};
