var Handlebars = require('/tmp/build_a9d70681abb25b82ca4489607bd6ccee/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	    <div class=\"answer\">\n	      <input type=\"text\" data-barcode data-input name=\"answer text\" value=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"text") || (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"text","hash":{},"data":data,"loc":{"start":{"line":6,"column":76},"end":{"line":6,"column":86}}}) : helper)))
    + "\" data-action=\"text\" placeholder=\""
    + alias1(container.lambda(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"placeholders") : depths[1])) != null ? lookupProperty(stack1,"enter_a_barcode") : stack1), depth0))
    + "\" >\n        <div class=\"validation\"> \n          <input type=\"checkbox\" class=\"supercheck\" data-target=\"validationMark\"><span data-target=\"validationHint\"></span>\n        </div>\n	    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- question type is Free Text -->\n<div class=\"type text barcode\">\n  <div class=\"answers\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"answers") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":3},"end":{"line":11,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n</div><!-- END free text -->";
},"useData":true,"useDepths":true});