var Handlebars = require('/tmp/build_a9d70681abb25b82ca4489607bd6ccee/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"instruction survey widget-panel\">\n    <div class=\"header\">\n        <div class=\"header\">\n            <div class=\"controls\">\n                <div class=\"ti type\">\n                    <span class=\"optional\"></span>\n                    <span class=\"type\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"titleBar") || (depth0 != null ? lookupProperty(depth0,"titleBar") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"titleBar","hash":{},"data":data,"loc":{"start":{"line":7,"column":39},"end":{"line":7,"column":53}}}) : helper)))
    + "</span>\n                </div>\n                <div class=\"arrow\"><span class=\"im icon-arrow-up\" data-action=\"collapse\"></span></div>\n                <div class=\"map\">\n                    <span class=\"im icon-map-pin\" data-action=\"viewLatLon\" style=\"display:none\"></span>\n                </div>\n                <div class=\"dragger\"><span class=\"im icon-empty\"></span></div>\n            </div>\n            <div class=\"actions\">\n                <div class=\"ti template\">\n                    "
    + alias4(((helper = (helper = lookupProperty(helpers,"template") || (depth0 != null ? lookupProperty(depth0,"template") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"template","hash":{},"data":data,"loc":{"start":{"line":17,"column":20},"end":{"line":17,"column":34}}}) : helper)))
    + "\n                </div>\n                <div class=\"viewing icon-buttons\">\n                    <a class=\"im icon-trash\" href=\"javascript:;\" data-action=\"back\"></a>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"widget-panel-content\" data-target=\"body\">\n\n        <div class=\"inner\" data-target=\"currentStep\"></div>\n\n        <!-- main action button -->\n        <a href=\"javascript:;\" class=\"answerButton\" data-action=\"save\"><span class=\"glyphicon glyphicon-refresh loading-xsm\"></span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"save") || (depth0 != null ? lookupProperty(depth0,"save") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"save","hash":{},"data":data,"loc":{"start":{"line":31,"column":132},"end":{"line":31,"column":142}}}) : helper)))
    + "</a>\n        <a href=\"javascript:;\" class=\"answerButton\" data-action=\"next\" style=\"display: none\"><span class=\"glyphicon glyphicon-refresh loading-xsm\"></span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"next") || (depth0 != null ? lookupProperty(depth0,"next") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"next","hash":{},"data":data,"loc":{"start":{"line":32,"column":154},"end":{"line":32,"column":164}}}) : helper)))
    + "</a>\n    </div>\n</div>\n";
},"useData":true});