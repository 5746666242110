// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback, useMemo } from 'react';
import axios from 'axios';
import qs from 'qs';
import type { ContextRouter } from 'react-router';
import { dataUriToBlob } from '../../../browser/shared/util/FileUtil';
import ImageEditor from '../../components/ImageEditor';

export default function ImageEditTool(props: ContextRouter) {
    const { location } = props;

    const { url } = useMemo(
        () => qs.parse(location.search, { ignoreQueryPrefix: true }),
        [location.search]
    );

    const handleSaveImage = useCallback((dataURL: string) => {
        const originalSrc = url;
        const blob = dataUriToBlob(dataURL, 'image/jpeg');
        const formData = new FormData();

        formData.append('url', originalSrc);
        formData.append('file', blob);

        return axios.put('/upload', formData);
    }, [url]);

    return (
        <ImageEditor
          imageUrl={url}
          onSave={handleSaveImage}
          open
        />
    );
}
