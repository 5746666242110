// @flow
import React from 'react';
import { MenuItem } from '@material-ui/core';

export default function Option(props: Object) {
    const { children, clearValue, innerProps, innerRef, isFocused, isMulti, isSelected, selectProps } = props;
    const { classes, disableSelectedOptions } = selectProps;
    const { onClick } = innerProps;

    innerProps.onClick = () => {
        if (disableSelectedOptions && isSelected) {
            return;
        }
        onClick();
        if (!isMulti && isSelected) {
            clearValue();
        }
    };

    return (
        <MenuItem
          {...innerProps}
          className={classes.menuItem}
          buttonRef={innerRef}
          selected={isFocused}
          component="div"
          style={{ fontWeight: isSelected ? 500 : 400 }}
        >
            {children}
        </MenuItem>
    );
}
