// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useRef, useState } from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import type { Node } from 'react';
import styles from './styles';

type Props = {
    children: Node,
    classes?: Object,
    className?: string,
    disabled?: boolean,
    onChange?: (event: SyntheticEvent<any>, selected: boolean) => void,
    onClick?: (event: SyntheticEvent<any>) => void,
    selected?: boolean,
    variant?: any,
};

const useStyles = makeStyles(styles, { name: 'ToggleButton' });

export function ToggleButton(props: Props) {
    const {
        children,
        classes: classesProp,
        className: classNameProp,
        disabled,
        onChange,
        onClick,
        selected: selectedProp,
        variant,
        ...other
    } = props;

    const classes = useStyles(props);
    const isControlled = useRef(selectedProp != null);
    const [selected, setSelected] = useState(false);

    const handleChange = (event: SyntheticEvent<any>) => {
        if (onClick) {
            onClick(event);
            if (event.isDefaultPrevented()) {
                return;
            }
        }

        const value = isControlled.current ? !selectedProp : !selected;
        if (!isControlled.current) setSelected(value);
        if (onChange) onChange(event, value);
    };

    return (
        <Button
          className={cx(
              {
                  [classes.selected]: isControlled.current ? selectedProp : selected,
                  [classes.disabled]: disabled,
              },
              classNameProp,
          )}
          classes={{
              contained: classes.root,
              containedPrimary: classes.colorPrimary,
              containedSecondary: classes.colorSecondary,
              label: classes.label,
          }}
          disabled={disabled}
          tabIndex={null}
          role={undefined}
          onClick={handleChange}
          variant="contained"
          {...other}
        >
            {children}
        </Button>
    );
}


export default ToggleButton;
