// @flow
export default (theme: Object) => ({
    root: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
    },
    question: {
        flex: 1,
    },
    metadata: {
        flex: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        '& > :not(:last-child)': {
            marginBottom: theme.spacing(2),
        },
    },
    badge: {
        transform: 'scale(1) translate(35%, -35%)',
    },
    removeButton: {
        minHeight: 'unset',
        height: 28,
        width: 28,
        fontSize: 18,
    },
    button: {
        width: 90,
        height: 90,
        borderStyle: 'dashed',
        borderWidth: 2,
    },
    gridList: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: theme.spacing(-0.5, -1.5),

        '& > *': {
            margin: theme.spacing(1.5),
            // box-shadow: 0px 0px 3px 1px #cd1e3d
        },
    },
    image: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        backgroundColor: theme.palette.grey[300],
        borderRadius: 4,
        width: 90,
        height: 90,
        justifyContent: 'center',
        overflow: 'hidden',

        '& > img': {
            objectFit: 'cover',
            width: '100%',
            height: '100%',
        },
    },
});
