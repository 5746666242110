var Handlebars = require('/tmp/build_a9d70681abb25b82ca4489607bd6ccee/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<strong>Q"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"questionNumber") || (depth0 != null ? lookupProperty(depth0,"questionNumber") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"questionNumber","hash":{},"data":data,"loc":{"start":{"line":22,"column":43},"end":{"line":22,"column":63}}}) : helper)))
    + "</strong>";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"files\">\n        <!-- div \"attachment\" uses the same HTML as in Project Builder -->\n        <div class=\"attachment\">\n          <div class=\"plainLabel\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"attached_files") || (depth0 != null ? lookupProperty(depth0,"attached_files") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"attached_files","hash":{},"data":data,"loc":{"start":{"line":39,"column":34},"end":{"line":39,"column":54}}}) : helper)))
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"files") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":10},"end":{"line":45,"column":19}}})) != null ? stack1 : "")
    + "        </div><!-- ends div \"attachment\" -->\n      </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"attached-file\" data-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":41,"column":48},"end":{"line":41,"column":56}}}) : helper)))
    + "\">\n              <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"url") || (depth0 != null ? lookupProperty(depth0,"url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":42,"column":23},"end":{"line":42,"column":32}}}) : helper)))
    + "\" target=\"_blank\" class=\"filename\" data-action=\"download\"><span class=\"im icon-paperclip\"></span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"file_name") || (depth0 != null ? lookupProperty(depth0,"file_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"file_name","hash":{},"data":data,"loc":{"start":{"line":42,"column":129},"end":{"line":42,"column":144}}}) : helper)))
    + "</a>\n              <a href=\"javascript:;\" class=\"remove\" data-action=\"remove-attachment\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"remove") || (depth0 != null ? lookupProperty(depth0,"remove") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"remove","hash":{},"data":data,"loc":{"start":{"line":43,"column":84},"end":{"line":43,"column":96}}}) : helper)))
    + "</a>\n            </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"images\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"images") : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":8},"end":{"line":57,"column":17}}})) != null ? stack1 : "")
    + "      </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"attachment image\">\n            <img src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"url") || (depth0 != null ? lookupProperty(depth0,"url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":54,"column":22},"end":{"line":54,"column":31}}}) : helper)))
    + "\" />\n            <span class=\"im icon-magnify\" data-url=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"url") || (depth0 != null ? lookupProperty(depth0,"url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":55,"column":52},"end":{"line":55,"column":61}}}) : helper)))
    + "\" data-action=\"magnify\"></span>\n          </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!--\n\n  {\n    title: \"\",\n    body: \"\",\n    attachments: [\n      url: \"\"\n    ]\n\n  }\n\n-->\n\n<!-- -------------------- Hint --------------------- -->\n<div class=\"hint widget-panel\">\n  <!-- widget header -->\n  <div class=\"header\">\n    <div class=\"controls\">\n      <div class=\"ti\">\n        <span class=\"im icon-shield\"></span>\n          <span class=\"tip-title\">\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"questionNumber") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":12},"end":{"line":22,"column":79}}})) != null ? stack1 : "")
    + "\n            <span>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":23,"column":18},"end":{"line":23,"column":29}}}) : helper)))
    + "</span>\n        </span>\n      </div>\n      <div data-action=\"collapse\" class=\"arrow\"><span class=\"im icon-arrow-up\"></span></div>\n      <div class=\"dragger\"><span class=\"im icon-empty\"></span></div>\n    </div>\n  </div>\n\n  <!-- widget content -->\n  <div class=\"widget-panel-content\" data-target=\"body\">\n    <div class=\"hint-copy u-break-word\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"body") || (depth0 != null ? lookupProperty(depth0,"body") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"body","hash":{},"data":data,"loc":{"start":{"line":33,"column":40},"end":{"line":33,"column":52}}}) : helper))) != null ? stack1 : "")
    + "</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"files") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":4},"end":{"line":48,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"images") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":4},"end":{"line":59,"column":11}}})) != null ? stack1 : "")
    + "\n  </div><!-- END widget content -->\n</div><!-- END hint -->\n";
},"useData":true});