// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useMemo, useState } from 'react';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
// $FlowTypedIssue update react-redux libdef
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { CircularProgress, Fade, InputAdornment } from '@material-ui/core';
import {
    Check as CheckIcon,
    Clear as ClearIcon,
    Timer as TimerIcon,
} from '@material-ui/icons';
import type { ContextRouter } from 'react-router';
import {
    getAvailableActions,
    getPermittedActions,
    getTicket,
    updateTicket,
} from '../../../../ducks/ticketDetail';
import FormikMaskedInput from '../../../../components/FormikMaskedInput';
import styles from './styles';
import type { State as RootState } from '../../../../store';

type Props = ContextRouter & {
    className?: string,
};

const useStyles = makeStyles(styles, { name: 'TimeEstimate' });

const TimeEstimateSchema = yup.object().shape({
    timeEstimate: yup.number().required(''),
    timeUnit: yup.string().oneOf(['minutes', 'hours']),
});

export default function TimeEstimate(props: Props) {
    const { match } = props;
    const ticketId = parseInt(match.params.ticketId, 10);

    const dispatch = useDispatch();
    const classes = useStyles(props);
    const { t } = useTranslation();

    const [statusTimer, setStatusTimer] = useState(null);

    const ticket = useSelector((state: RootState) => getTicket(state));
    const permittedActions = useSelector((state: RootState) => getPermittedActions(state));
    const availableActions = useSelector((state: RootState) => getAvailableActions(state));

    const disabled = useMemo(() => {
        const fields = [];
        if (!permittedActions.includes('editTimeEstimate') || !availableActions.includes('editTimeEstimate')) {
            fields.push('timeEstimate');
        }
        return fields;
    }, [availableActions, permittedActions]);

    const initialValues = useMemo(() => {
        if (!ticket) {
            return {
                timeEstimate: undefined,
                timeUnit: 'minutes',
            };
        }

        const { time_estimate: timeEstimate } = ticket;

        return {
            timeEstimate: Math.round((timeEstimate / 60) * 100) / 100,
            timeUnit: 'minutes',
        };
    }, [ticket]);

    return (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={(values, { setStatus }) => (
              dispatch(updateTicket(ticketId, values))
                  .then(() => {
                      clearTimeout(statusTimer);
                      setStatusTimer(setTimeout(() => setStatusTimer(null), 1500));
                      setStatus({ success: true });
                  })
                  .catch((error: string) => {
                      clearTimeout(statusTimer);
                      setStatusTimer(setTimeout(() => setStatusTimer(null), 1500));
                      setStatus({ error });
                      return Promise.reject(error);
                  })
          )}
          validationSchema={TimeEstimateSchema}
        >
            {(formikProps) => {
                const { dirty, isSubmitting, isValid, status, submitForm, values } = formikProps;
                const { timeUnit } = values;

                const handleBlur = () => {
                    if (dirty && isValid) {
                        submitForm();
                    }
                };

                return (
                    <Form className={classes.container}>
                        <TimerIcon className={classes.icon} />
                        <span className={classes.label}>{t('ticketDetail.summary.timeEstimate')}</span>
                        <FormikMaskedInput
                          classes={{
                              input: classes.input,
                              disabled: classes.disabled,
                          }}
                          disabled={disabled.includes('timeEstimate')}
                          endAdornment={(
                              <InputAdornment position="end">
                                  {timeUnit === 'minutes' ? 'min' : 'hr'}
                              </InputAdornment>
                          )}
                          margin="dense"
                          name="timeEstimate"
                          onBlur={handleBlur}
                          options={{
                              numeral: true,
                              numeralThousandsGroupStyle: 'thousand',
                              numeralPositiveOnly: true,
                          }}
                          variant="outlined"
                        />
                        {isSubmitting
                            ? <CircularProgress size={18} thickness={4} />
                            : (
                                <Fade in={!!statusTimer} timeout={{ exit: 250 }}>
                                    {status && status.success
                                        ? <CheckIcon className={classes.successIcon} />
                                        : <ClearIcon className={classes.errorIcon} />}
                                </Fade>
                            )}
                    </Form>
                );
            }}
        </Formik>
    );
}
