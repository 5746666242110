// @flow
import { createAction } from 'redux-actions';
import type { Dispatch } from 'redux';
import type { $AxiosError } from 'axios';
import logger from '../../../util/logger';
import { fetch as fetchCustomer } from '../../../redux/entities/customers';
import { fetch as fetchCertification } from '../../../redux/entities/certifications';
import types from './types';

export const loadCustomerSuccess = createAction(types.LOAD_CUSTOMER_SUCCESS);
export const loadCustomerError = createAction(types.LOAD_CUSTOMER_ERROR);
export const loadCustomer = createAction(
    types.LOAD_CUSTOMER,
    (customerId: number, orgId: number): Function => (
        (dispatch: Dispatch<any>): Promise<void> => (
            dispatch(fetchCustomer({ customer_id: customerId, organization_id: orgId }))
                .then((resp: Object) => {
                    const customer = resp.entities.customers[customerId];
                    const { certifications } = customer;
                    const promises = [];

                    if (certifications) {
                        certifications.forEach((certification: Object) => {
                            promises.push(dispatch(fetchCertification({ certification_id: certification.id })));
                        });
                    }

                    return Promise.all(promises);
                })
                .then(() => {
                    dispatch(loadCustomerSuccess());
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(loadCustomerError());
                    return Promise.reject(err);
                })
        )
    )
);

export default {
    loadCustomer,
    loadCustomerError,
    loadCustomerSuccess,
};
