// @flow
import React, { Children, Component } from 'react';
import memoize from 'lodash/memoize';
import cx from 'classnames';
import { withStyles } from '@material-ui/styles';
import { AutoSizer, InfiniteLoader, List } from 'react-virtualized';
import type { ChildrenArray, ElementRef, Node } from 'react';

type Props = {
    children: ChildrenArray<any>,
    className?: string,
    classes: Object,
    maxHeight: number,
    rowHeight: number,
    onInfiniteLoad: () => Promise<void>,
};

type RefCallback = (c: ElementRef<typeof List>) => void;

const styles = () => ({
    root: {
        '&:focus': {
            outline: 'none',
        },
    },
});

class InfiniteList extends Component<Props> {
    list: ?ElementRef<typeof List>;

    createRef = memoize(
        (registerChild: RefCallback): RefCallback => (
            (c: ElementRef<typeof List>) => {
                this.list = c;
                registerChild(c);
            }
        )
    );

    componentDidUpdate() {
        // @todo Find a more effecient solution for re-rendering list
        // Forcefully re-render the inner Grid component in case any child props have changed
        if (this.list) {
            this.list.forceUpdateGrid();
        }
    }

    isRowLoaded = (row: Object): boolean => {
        const { children } = this.props;
        const { index } = row;
        return !!children[index];
    };

    renderRow = (row: Object): Node => {
        const { children } = this.props;
        const { index, key, style } = row;
        const childrenArray = Children.toArray(children);

        return (
            <div key={key} style={style}>
                {childrenArray[index]}
            </div>
        );
    };

    render() {
        const { children, className, classes, maxHeight, rowHeight, onInfiniteLoad } = this.props;
        const rowCount = Children.count(children);
        const height = Math.min(rowCount * rowHeight, maxHeight);

        return (
            <InfiniteLoader
              isRowLoaded={this.isRowLoaded}
              loadMoreRows={onInfiniteLoad}
              rowCount={Number.MAX_VALUE}
            >
                {({ onRowsRendered, registerChild }) => (
                    <AutoSizer disableHeight>
                        {({ width }) => (
                            <List
                              className={cx(classes.root, className)}
                              ref={this.createRef(registerChild)}
                              height={height}
                              onRowsRendered={onRowsRendered}
                              rowCount={rowCount}
                              rowHeight={rowHeight}
                              rowRenderer={this.renderRow}
                              threshold={10}
                              width={width}
                            />
                        )}
                    </AutoSizer>
                )}
            </InfiniteLoader>
        );
    }
}

export default withStyles(styles, { name: 'InfiniteList' })(InfiniteList);
