// @flow
import { createAction } from 'redux-actions';
import identity from 'lodash/identity';
import omitBy from 'lodash/omitBy';
import type { Dispatch } from 'redux';
import type { $AxiosError } from 'axios';
import logger from '../../../util/logger';
import { search as searchAuditEvents } from '../../../redux/entities/auditEvents';
import types from './types';
import { fetch as fetchOrganization } from '../../../redux/entities/organizations';
import { search as searchCustomers } from '../../../redux/entities/customers';

export const toggleFilterDrawer = createAction(types.TOGGLE_FILTER_DRAWER);

export const fetchDataSuccess = createAction(types.FETCH_DATA_SUCCESS, identity, (data: Object, meta: Object): Object => meta);
export const fetchDataError = createAction(types.FETCH_DATA_ERROR);
export const fetchData = createAction(
    types.FETCH_DATA,
    (filters: ?Object, sort: ?Object[], limit: ?number, offset: ?number): Function => (
        (dispatch: Dispatch<any>): Promise<void> => {
            const [sortField, sortOrder] = sort && sort[0] ? Object.entries(sort[0])[0] : [null, null];
            const params = omitBy({ ...filters, limit, offset, sort_field: sortField, sort_order: sortOrder }, (value) => value == null);
            return dispatch(searchAuditEvents(params))
                .then((resp: Object) => dispatch(fetchDataSuccess(resp.result, resp.metadata)))
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(fetchDataError());
                    return Promise.reject(err);
                });
        }
    )
);

export const loadFiltersSuccess = createAction(types.LOAD_FILTERS_SUCCESS);
export const loadFiltersError = createAction(types.LOAD_FILTERS_ERROR);
export const loadFilters = createAction(
    types.LOAD_FILTERS,
    (search: Object) => (
        (dispatch: Dispatch<any>) => Promise.resolve()
            .then(() => {
                const promises = [];
                if (search.organization) {
                    const id = parseInt(search.organization, 10);
                    promises.push(dispatch(fetchOrganization({ organization_id: id })));
                }
                if (search.customer) {
                    const apiFilters = [{ key: 'id', value: search.customer.map((id) => parseInt(id, 10)) }];
                    promises.push(dispatch(searchCustomers({ filters: apiFilters })));
                }
                return Promise.all(promises);
            })
            .then(() => {
                dispatch(loadFiltersSuccess());
            })
            .catch((err: $AxiosError<any>) => {
                logger.error(err);
                dispatch(loadFiltersError());
                return Promise.reject(err);
            })
    )
);

export default {
    fetchData,
    fetchDataError,
    fetchDataSuccess,
    loadFilters,
    loadFiltersError,
    loadFiltersSuccess,
};
