// @flow
import React, { Component } from 'react';
import classnames from 'classnames';
import { LinearProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import type { Connector } from 'react-redux';
import type { Dispatch } from 'redux';
import type { TFunction } from 'react-i18next';
import Dropzone from '../Dropzone';
import { getMimeTypes } from '../../util/FileUtil';
import type { State as RootState } from '../../../../common/redux/initialState';

import {
    reset,
    addFile,
    removeFile,
    updateProcessing,
    startUpload,
    checkProgress,
} from './redux';

import {
    getUploadStatus,
    getUploadProgress,
} from './redux/selectors';

import type { DropzoneFile } from '../Dropzone';

type OwnProps = {
    listName: string,
    onCancel: () => void,
    onComplete: (id: number) => void,
    onUpload: () => void,
    t: TFunction,
};

type StateProps = {
    files: Array<DropzoneFile>,
    isFetching: boolean,
    listId: number,
    processing: Array<DropzoneFile>,
    uploadProgress: number,
    uploadStatus: 'NEW' | 'PENDING' | 'PROCESSING' | 'FAILED' | 'COMPLETE',
};

type DispatchProps = {
    addFile: (file: DropzoneFile) => void,
    checkProgress: () => void,
    removeFile: (file: DropzoneFile) => void,
    reset: () => void,
    startUpload: (listName: string) => void,
    updateProcessing: (file: DropzoneFile) => void,
};

type Props = OwnProps & StateProps & DispatchProps;

export class LocationListUpload extends Component<Props> {
    timeout: number = 0;

    UNSAFE_componentWillMount() {
        this.props.reset(); // eslint-disable-line react/destructuring-assignment
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        const { uploadStatus } = this.props;
        const { uploadStatus: nextUploadStatus } = nextProps;
        const inProgress = uploadStatus === 'PENDING' || uploadStatus === 'PROCESSING';
        const willBeInProgress = nextUploadStatus === 'PENDING' || nextUploadStatus === 'PROCESSING';

        if ((uploadStatus === 'NEW' && willBeInProgress) || (inProgress && willBeInProgress)) {
            if (!nextProps.isFetching) {
                this.timeout = setTimeout(() => {
                    this.props.checkProgress(); // eslint-disable-line react/destructuring-assignment
                }, 500);
            }
        }

        if (inProgress && !willBeInProgress) {
            clearTimeout(this.timeout);
        }
    }

    componentDidUpdate(prevProps: Props) {
        const { listId, onComplete, uploadStatus } = this.props;
        const isComplete = prevProps.uploadStatus !== 'COMPLETE' && uploadStatus === 'COMPLETE';
        if (isComplete) {
            onComplete(listId);
        }
    }

    onUpload = (e: SyntheticEvent<>) => {
        e.preventDefault();
        const { listName, onUpload } = this.props;
        this.props.startUpload(listName); // eslint-disable-line react/destructuring-assignment
        onUpload();
    };

    onCancel = (e: SyntheticEvent<>) => {
        e.preventDefault();
        const { onCancel } = this.props;
        onCancel();
    };

    renderDropzone(): ?React$Element<any> {
        const { files, processing, t } = this.props;

        return (
            <div>
                <Dropzone
                  bucket="locationList"
                  accept={getMimeTypes(['xls', 'xlsx']).join(',')}
                  multiple
                  onUploadError={this.props.updateProcessing} // eslint-disable-line react/destructuring-assignment
                  onUploadSuccess={this.props.addFile} // eslint-disable-line react/destructuring-assignment
                  onRemoveFile={this.props.removeFile} // eslint-disable-line react/destructuring-assignment
                  onProcessingFile={this.props.updateProcessing} // eslint-disable-line react/destructuring-assignment
                  className="u-margin-bottom-lg"
                />
                <div className="c-cta-buttons c--right">
                    <a href="#cancel" className="c-button c--white" onClick={this.onCancel}>{t('shared.locationListUpload.cancel')}</a>
                    <a
                      href="#upload"
                      className={classnames('c-button c--cta', { 'c--disabled': files.length === 0 || processing.length > 0 })}
                      onClick={this.onUpload}
                    >
                        {t('shared.locationListUpload.upload')}
                    </a>
                </div>
            </div>
        );
    }

    render() {
        // @todo What should we do when uploadStatus === 'FAILED'? What causes a FAILED upload?
        const { uploadProgress, uploadStatus, t } = this.props;
        const inProgress = uploadStatus === 'PENDING' || uploadStatus === 'PROCESSING';

        return (
            <div style={{ width: '100%' }}>
                {uploadStatus === 'NEW' ? this.renderDropzone() : null }
                {inProgress
                    ? (
                        <div>
                            <p>{`${t('shared.locationListUpload.uploadInProgress')} ${uploadProgress}%`}</p>
                            <LinearProgress
                              color="secondary"
                              value={uploadProgress}
                              mode={uploadStatus === 'PENDING' ? 'indeterminate' : 'determinate'}
                            />
                        </div>
                    )
                    : null}
            </div>
        );
    }
}

const mapStateToProps = (state: RootState): StateProps => ({
    ...state.locationListUpload,
    uploadProgress: getUploadProgress(state),
    uploadStatus: getUploadStatus(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    addFile: (file: DropzoneFile) => dispatch(addFile(file)),
    removeFile: (file: DropzoneFile) => dispatch(removeFile(file)),
    updateProcessing: (file: DropzoneFile) => dispatch(updateProcessing(file)),
    startUpload: (listName: string) => dispatch(startUpload(listName)),
    checkProgress: () => dispatch(checkProgress()),
    reset: () => dispatch(reset()),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
export default withTranslation()(connector(LocationListUpload));
