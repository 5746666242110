// @flow
import { createSelector } from 'reselect';
import { matchPath } from 'react-router';
import jstz from 'jstimezonedetect';
import moment from 'moment-timezone';
import numeral from 'numeral';
import {
    AssignmentReturn as AssignmentReturnIcon,
    BarChart as BarChartIcon,
    Cancel as CancelIcon,
    Check as CheckIcon,
    Gavel as GavelIcon,
    HourglassEmpty as HourglassEmptyIcon,
    Info as InfoIcon,
    Print as PrintIcon,
} from '@material-ui/icons';
import traverseSkipLogic from '../../utils/traverseSkipLogic';
import logger from '../../utils/logger';
import { createEntitySelector } from '../gigwalk';
import type { State as RootState } from '../../store';

type Comment = {
    author: ?Object,
    data: ?Object,
    date: string,
    id: string,
    type: string,
}

const getActiveUser = (state: RootState): ?Object => state.session.user;

const getPathParams = createSelector(
    (state: RootState) => state.router.location.pathname,
    (pathname: string): ?Object => {
        const match = matchPath(pathname, { path: '/tickets/:orgId/:page(detail|info)/:ticketId' });
        return match ? match.params : null;
    },
);

export const getOrganizationId = createSelector(
    getPathParams,
    (params: ?Object) => (params ? parseInt(params.orgId, 10) : -1),
);

export const getTicketId = createSelector(
    getPathParams,
    (params: ?Object) => (params ? parseInt(params.ticketId, 10) : -1),
);

export const getTicket = createEntitySelector('tickets', (state: RootState) => getTicketId(state));

/**
 * Returns a list of valid actions for the current ticket. Note that this only takes into account
 * properties of the ticket's subscription and NOT its status. In order to determine which actions
 * are available to the user at a particular point in the ticket's lifecycle (ie. ASSIGNED,
 * SUBMITTED, etc), use the getAvailableAction selector.
 */
// $FlowIssue weird error message - could be a bug in flow or reselect typedef
export const getValidActions = createSelector(
    getTicket,
    (ticket: ?Object): string[] => {
        if (!ticket) {
            return [];
        }

        const actions = [];
        const { subscription } = ticket;
        const {
            is_double_optin: isDoubleOptin,
            needs_approval: needsApproval,
            optin_type: optinType,
        } = subscription;
        const isHardScheduled = !subscription.can_reschedule;
        const needsPublicWorkforce = subscription.needs_public_workforce || subscription.needs_core;
        const reservationWindowEnabled = optinType === 'SYSTEM_APPROVED_OPTIN' && !isHardScheduled
            ? !!subscription.res_window_hours
            : false;

        actions.push(
            'assign',
            'cancel',
            'comment',
            'editResponse', // allows admins to edit response while preserving metadata (lat/lng, device info, etc)
            'editStartDate',
            'editTimeEstimate',
            'reopen',
            'submit',
            'unassign',
        );

        if (isHardScheduled) {
            actions.push('reschedule');
        } else {
            actions.push('editDueDate', 'extend', 'schedule');
        }

        if (needsPublicWorkforce) actions.push('editPrice');
        if (needsApproval) actions.push('reject', 'approve');
        if (reservationWindowEnabled) actions.push('extendReservationWindow');
        if (isDoubleOptin) actions.push('apply', 'withdraw');
        if (optinType === 'SIMPLE_OPTIN') actions.push('optIn');

        return actions;
    }
);

/**
 * Returns a list of allowed ticket actions for the current user. This is mostly determined by user role,
 * but group membership, assigned status, and workforce type (private vs public) also plays a role.
 * This is a subset of the actions returned by the getValidActions selector
 */
// $FlowIssue weird error message - could be a bug in flow or reselect typedef
export const getPermittedActions = createSelector(
    getValidActions,
    getTicket,
    getActiveUser,
    (actions: string[], ticket: ?Object, user: ?Object) => {
        if (!ticket || !user) {
            return [];
        }

        const { customer: assignee, is_applicant: isApplicant, subscription } = ticket;
        const { group_memberships: groupMemberships, id: userId, organization, role: userRole } = user;
        const { cannot_opt_out: cannotOptOut } = organization.config;
        const { groups } = subscription;
        const needsPublicWorkforce = subscription.needs_public_workforce || subscription.needs_core;

        const isAssigned = !!assignee;
        const isAssignedToUser = assignee ? assignee.id === userId : false;
        const isTicketInGroup = groups.some((group: Object) => (
            groupMemberships.some((membership: Object) => membership.group_id === group.id)
        ));

        switch (userRole) {
            case 'PLATFORM_ADMIN':
            case 'SELF_SERVICE':
                return actions.filter((action: ?string) => {
                    switch (action) {
                        case 'submit':
                        case 'schedule':
                            return isAssignedToUser;

                        case 'unassign':
                            return isAssignedToUser ? !cannotOptOut : true;

                        case 'withdraw':
                            return isApplicant;

                        case 'apply':
                            return !isApplicant && isTicketInGroup;

                        case 'optIn':
                            return isTicketInGroup;

                        default:
                            return true;
                    }
                });

            case 'SUPER_ADMIN':
                return actions.filter((action: string): boolean => {
                    if (needsPublicWorkforce) return false;
                    switch (action) {
                        case 'submit':
                        case 'schedule':
                            return isAssignedToUser;

                        case 'unassign':
                            return isAssignedToUser ? !cannotOptOut : true;

                        case 'withdraw':
                            return isApplicant;

                        case 'apply':
                            return !isApplicant && isTicketInGroup;

                        case 'optIn':
                            return isTicketInGroup;

                        default:
                            return true;
                    }
                });

            case 'ADMIN':
                return actions.filter((action: string): boolean => {
                    if (needsPublicWorkforce) return false;
                    switch (action) {
                        case 'cancel':
                        case 'editStartDate':
                        case 'editDueDate':
                        case 'editTimeEstimate':
                        case 'editPrice':
                        case 'reject':
                        case 'approve':
                        case 'extend':
                        case 'reschedule':
                        case 'assign':
                        case 'reopen':
                            return isTicketInGroup || isAssigned;

                        case 'submit':
                        case 'schedule':
                            return isAssignedToUser;

                        case 'unassign':
                            return isAssignedToUser ? !cannotOptOut : isTicketInGroup || isAssigned;

                        case 'withdraw':
                            return isApplicant;

                        case 'apply':
                            return !isApplicant && isTicketInGroup;

                        case 'optIn':
                            return isTicketInGroup;

                        case 'extendReservationWindow':
                        case 'editResponse':
                            return false;

                        default:
                            return true;
                    }
                });

            case 'OPERATOR':
                return actions.filter((action: string): boolean => {
                    if (needsPublicWorkforce) return false;
                    switch (action) {
                        case 'comment':
                        case 'assign':
                        case 'reopen':
                            return isTicketInGroup || isAssigned;

                        case 'submit':
                        case 'schedule':
                            return isAssignedToUser;

                        case 'unassign':
                            return isAssignedToUser ? !cannotOptOut : isTicketInGroup || isAssigned;

                        case 'withdraw':
                            return isApplicant;

                        case 'apply':
                            return !isApplicant && isTicketInGroup;

                        case 'reschedule':
                        case 'optIn':
                            return isTicketInGroup;

                        case 'cancel':
                        case 'editStartDate':
                        case 'editDueDate':
                        case 'editTimeEstimate':
                        case 'editPrice':
                        case 'editResponse':
                        case 'reject':
                        case 'approve':
                        case 'extend':
                        case 'extendReservationWindow':
                            return false;

                        default:
                            return true;
                    }
                });

            case 'ANALYST':
                return actions.filter((action: string): boolean => {
                    if (needsPublicWorkforce) return false;
                    switch (action) {
                        case 'comment':
                        case 'reopen':
                            return isTicketInGroup || isAssigned;

                        case 'submit':
                        case 'schedule':
                            return isAssignedToUser;

                        case 'unassign':
                            return isAssignedToUser ? !cannotOptOut : false;

                        case 'withdraw':
                            return isApplicant;

                        case 'apply':
                            return !isApplicant && isTicketInGroup;

                        case 'assign':
                        case 'optIn':
                            return isTicketInGroup;

                        case 'cancel':
                        case 'editStartDate':
                        case 'editDueDate':
                        case 'editTimeEstimate':
                        case 'editPrice':
                        case 'editResponse':
                        case 'reject':
                        case 'approve':
                        case 'extend':
                        case 'extendReservationWindow':
                        case 'reschedule':
                            return false;

                        default:
                            return true;
                    }
                });

            case 'WORKER':
                return actions.filter((action: string): boolean => {
                    switch (action) {
                        case 'comment':
                        case 'submit':
                        case 'schedule':
                            return isAssignedToUser;

                        case 'unassign':
                            return isAssignedToUser ? !cannotOptOut : false;

                        case 'withdraw':
                            return isApplicant;

                        case 'apply':
                            return !isApplicant && isTicketInGroup;

                        case 'optIn':
                            return isTicketInGroup;

                        case 'cancel':
                        case 'editStartDate':
                        case 'editDueDate':
                        case 'editTimeEstimate':
                        case 'editPrice':
                        case 'editResponse':
                        case 'reject':
                        case 'approve':
                        case 'extend':
                        case 'extendReservationWindow':
                        case 'reschedule':
                        case 'assign':
                        case 'reopen':
                            return false;

                        default:
                            return true;
                    }
                });

            default:
                return [];
        }
    }
);

/**
 * Returns a list of available actions for the current user at this point in the ticket's lifecycle.
 * This is a subset of the list returned by getPermittedActions and takes into account the ticket's
 * status and approval_status.
 */
// $FlowIssue weird error message - could be a bug in flow or reselect typedef
export const getAvailableActions = createSelector(
    getPermittedActions,
    getTicket,
    (actions: string[], ticket: ?Object) => {
        if (!ticket) {
            return [];
        }

        const { approval_status: approvalStatus, status, subscription } = ticket;
        const needsPublicWorkforce = subscription.needs_public_workforce || subscription.needs_core;

        switch (status) {
            case 'CANCELED':
                return actions.filter((action: string) => action === 'comment');

            case 'SCHEDULED':
            case 'ASSIGNED':
                return actions.filter((action: string) => {
                    switch (action) {
                        case 'cancel':
                        case 'comment':
                        case 'editDueDate':
                        case 'editPrice':
                        case 'editStartDate':
                        case 'editTimeEstimate':
                        case 'extend':
                        case 'extendReservationWindow':
                        case 'reschedule':
                        case 'schedule':
                        case 'unassign':
                        case 'withdraw':
                            return true;

                        default:
                            return false;
                    }
                });

            case 'UNASSIGNED':
                return actions.filter((action: string) => {
                    switch (action) {
                        case 'apply':
                        case 'assign':
                        case 'cancel':
                        case 'comment':
                        case 'editDueDate':
                        case 'editPrice':
                        case 'editStartDate':
                        case 'editTimeEstimate':
                        case 'extend':
                        case 'optIn':
                            return true;

                        default:
                            return false;
                    }
                });

            case 'STARTED':
                return actions.filter((action: string) => {
                    switch (action) {
                        case 'comment':
                        case 'extend':
                        case 'extendReservationWindow':
                        case 'reschedule':
                        case 'submit':
                        case 'unassign':
                        case 'withdraw':
                            return true;

                        case 'cancel':
                            return !needsPublicWorkforce;

                        default:
                            return false;
                    }
                });

            case 'SUBMITTED':
                return actions.filter((action: string) => {
                    switch (action) {
                        case 'editResponse':
                        case 'comment':
                            return true;

                        case 'approve':
                        case 'reject':
                        case 'reopen':
                            return approvalStatus !== 'APPROVED';

                        default:
                            return false;
                    }
                });

            default:
                return [];
        }
    }
);

export const getComments = createSelector(
    getOrganizationId,
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    getTicket,
    getActiveUser,
    (orgId: number, ticket: ?Object, user: ?Object): Comment[] => {
        const needsPublicWorkforce = ticket ? ticket.subscription.needs_public_workforce : false;
        const events = ticket ? ticket.events || [] : [];
        const ticketTimezone = ticket ? ticket.location.tzid : '';
        const localTimezone = jstz.determine().name();

        // Simple helper function to add additional info to customer object.
        // Because the API does not return org info or the user role for a customer in the
        // ticket_events GET response, we cannot assume the orgId for needsPublicWorkforce tickets.
        const getCustomerInfo = (customer: Object) => {
            const hideDetails = user && user.role !== 'PLATFORM_ADMIN' && user.id !== customer.id && needsPublicWorkforce;
            const lastName = customer.last_name || '';
            return {
                ...customer,
                last_name: hideDetails ? lastName.charAt(0) : lastName,
                profile_link: hideDetails ? null : `/member/${orgId}/${customer.id}/account`,
            };
        };

        return events
            .sort((a: Object, b: Object) => (
                (new Date(a.ticket_event_date)) - (new Date(b.ticket_event_date))
            ))
            .reduce((list: Comment[], event: Object) => {
                const createdCustomer = getCustomerInfo(event.created_customer);
                const {
                    id: eventId,
                    ticket_event_date: eventDate,
                    ticket_event_data: eventData,
                    ticket_event_type: eventType,
                } = event;

                let data: ?Object = null;
                let author: ?Object = null;

                switch (eventType) {
                    case 'ASSIGNED': {
                        const { assigned_customer: assignedCustomer } = eventData;
                        data = {
                            assignedCustomer: getCustomerInfo(assignedCustomer),
                            createdCustomer,
                        };
                        break;
                    }

                    case 'AUTO_ASSIGNED': {
                        const { assigned_customer: assignedCustomer } = eventData;
                        data = { assignedCustomer: getCustomerInfo(assignedCustomer) };
                        break;
                    }

                    case 'COMMENT': {
                        const { comment } = eventData;
                        author = { ...createdCustomer };
                        data = { comment };
                        break;
                    }

                    case 'DEADLINE_SET': {
                        const { assigned_customer: assignedCustomer, deadline, trigger_event: triggerEvent } = eventData;
                        data = {
                            assignedCustomer: getCustomerInfo(assignedCustomer),
                            deadline: moment.tz(deadline, ticketTimezone).format('lll'),
                            triggerEvent,
                        };
                        break;
                    }

                    case 'END_DATE_EDITED': {
                        const { new_end_date: newEndDate, original_end_date: oldEndDate } = eventData;
                        data = {
                            createdCustomer,
                            newEndDate: moment.tz(newEndDate, ticketTimezone).format('lll'),
                            oldEndDate: moment.tz(oldEndDate, ticketTimezone).format('lll'),
                        };
                        break;
                    }

                    case 'EXTENDED': {
                        const { extended_date: extendedDate, extended_from_date: extendedFromDate } = eventData;
                        data = {
                            createdCustomer,
                            extendedDate: moment.tz(extendedDate, ticketTimezone).format('lll'),
                            extendedFromDate: moment.tz(extendedFromDate, ticketTimezone).format('lll'),
                        };
                        break;
                    }

                    case 'GROUPS CHANGED': {
                        const { new_groups: newGroups, old_groups: oldGroups } = eventData;
                        const groupsAdded = newGroups.filter((group: string) => !oldGroups.includes(group));
                        const groupsRemoved = oldGroups.filter((group: string) => !newGroups.includes(group));
                        data = {
                            createdCustomer,
                            groupsAdded,
                            groupsRemoved,
                        };
                        break;
                    }

                    case 'PAID': {
                        const { amount } = eventData;
                        data = {
                            amount: numeral(amount).format('$0,0.00'),
                            createdCustomer,
                        };
                        break;
                    }

                    case 'DUE DATE CHANGED IN PROJECT': {
                        const { new_date: newEndDate, old_date: oldEndDate } = eventData;
                        data = {
                            createdCustomer,
                            newEndDate: moment.tz(newEndDate, ticketTimezone).format('lll'),
                            oldEndDate: moment.tz(oldEndDate, ticketTimezone).format('lll'),
                        };
                        break;
                    }

                    case 'START DATE CHANGED IN PROJECT': {
                        const { new_date: newStartDate, old_date: oldStartDate } = eventData;
                        data = {
                            createdCustomer,
                            newStartDate: moment.tz(newStartDate, ticketTimezone).format('lll'),
                            oldStartDate: moment.tz(oldStartDate, ticketTimezone).format('lll'),
                        };
                        break;
                    }

                    case 'TIME ESTIMATE CHANGED IN PROJECT': {
                        const { new_time_estimate: newTimeEstimate, old_time_estimate: oldTimeEstimate } = eventData;
                        data = {
                            createdCustomer,
                            newTimeEstimate,
                            oldTimeEstimate,
                        };
                        break;
                    }

                    case 'RATED': {
                        const { rating } = eventData;
                        data = {
                            createdCustomer,
                            rating,
                        };
                        break;
                    }

                    case 'SCHEDULED': {
                        const { scheduled_date: scheduledDate } = eventData;
                        data = {
                            createdCustomer,
                            scheduledDate: moment.tz(scheduledDate, ticketTimezone).format('lll'),
                        };
                        break;
                    }

                    case 'START_DATE_EDITED': {
                        const { new_start_date: newStartDate, original_start_date: oldStartDate } = eventData;
                        data = {
                            createdCustomer,
                            newStartDate: moment.tz(newStartDate, ticketTimezone).format('lll'),
                            oldStartDate: moment.tz(oldStartDate, ticketTimezone).format('lll'),
                        };
                        break;
                    }

                    case 'SUBMIT_DEADLINE_EDITED': {
                        const { new_end_date: newEndDate, original_end_date: oldEndDate } = eventData;
                        data = {
                            createdCustomer,
                            newEndDate: moment.tz(newEndDate, ticketTimezone).format('lll'),
                            oldEndDate: moment.tz(oldEndDate, ticketTimezone).format('lll'),
                        };
                        break;
                    }

                    case 'TIME_ESTIMATE_EDITED': {
                        const { new_time_estimate: newTimeEstimate, original_time_estimate: oldTimeEstimate } = eventData;
                        data = {
                            createdCustomer,
                            newTimeEstimate,
                            oldTimeEstimate,
                        };
                        break;
                    }

                    case 'APPROVED':
                    case 'CANCELED':
                    case 'REJECTED':
                    case 'REOPENED':
                    case 'STARTED':
                    case 'SUBMITTED':
                    case 'UNASSIGNED':
                    case 'UNSCHEDULED':
                        data = { createdCustomer };
                        break;

                    // These events don't have a created_customer
                    case 'AUTO_UNASSIGNED':
                    case 'CREATED':
                        break;

                    // Events that we don't support anymore or want to display to users
                    case 'EDIT':
                    case 'PAUSED':
                    case 'PRINTED':
                    case 'PROJECT_UPDATE':
                    case 'RESTARTED':
                        return list;

                    default:
                        logger.error(`Unsupported ticket event type: ${eventType}`);
                        return list;
                }

                list.push({
                    author,
                    data,
                    date: moment.utc(eventDate).tz(localTimezone).format('lll zz'),
                    id: eventId,
                    type: eventType,
                });

                return list;
            }, []);
    }
);

export const getProgress = createSelector(
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    getTicket,
    (ticket: ?Object): number => {
        if (!ticket) {
            return 0;
        }

        const {
            data_type_map: dataTypeMap,
            data_types: dataTypes,
            data_items: dataItems,
        } = ticket;

        let total = 0;
        let answered = 0;
        const callback = (node: Object) => {
            const {
                data_type_id: dataTypeId,
                observation_target_id: observationTargetId,
                template_id: templateId,
            } = node;

            // @todo: Add support for bulk barcode and self-directed templates
            if (dataTypeId == null) {
                return;
            }

            // Only consider required questions when calculating progress
            const dataType = dataTypeMap[dataTypeId];
            if (!dataType.is_required) {
                return;
            }

            const dataItem = dataItems.find((di: Object): boolean => (
                di.data_type_id === dataTypeId
                && di.observation_target_id === observationTargetId
                && (templateId ? di.template_id === templateId : true)
            ));

            total += 1;
            if (dataItem && dataItem.data_item_answered) {
                answered += 1;
            }
        };

        dataTypes.forEach((d: Object) => traverseSkipLogic(d, callback));
        return total > 0 ? Math.round((answered / total) * 100) : 0;
    }
);

export const getCallToAction = createSelector(
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    getTicket,
    getActiveUser,
    getAvailableActions,
    (ticket: ?Object, user: ?Object, availableActions: string[]): ?Object => {
        if (!ticket || !user) {
            return null;
        }

        switch (ticket.status) {
            case 'UNASSIGNED': {
                if (availableActions.includes('assign')) {
                    return {
                        action: null,
                        icon: null,
                        key: 'assignGig',
                        variant: 'warning',
                    };
                }

                // Although other user roles are allowed to opt-in and apply/withdraw from gigs,
                // we only want to display a call-to-action for worker
                if (user.role === 'WORKER') {
                    switch (true) {
                        case availableActions.includes('apply'):
                            return {
                                action: 'apply',
                                icon: null,
                                key: 'applyToGig',
                                variant: 'warning',
                            };
                        case availableActions.includes('withdraw'):
                            return {
                                action: 'withdraw',
                                icon: null,
                                key: 'withdrawFromGig',
                                variant: 'warning',
                            };
                        case availableActions.includes('optIn'):
                            return {
                                action: 'optIn',
                                icon: null,
                                key: 'optIntoGig',
                                variant: 'warning',
                            };
                        default:
                            break;
                    }
                }

                return null;
            }

            case 'SUBMITTED': {
                switch (true) {
                    case availableActions.includes('approve'):
                    case availableActions.includes('reject'):
                        return {
                            action: 'review',
                            icon: GavelIcon,
                            key: 'reviewGig',
                            variant: 'warning',
                        };
                    case availableActions.includes('reopen'):
                        return {
                            action: 'reopen',
                            icon: CheckIcon,
                            key: 'gigCompleted',
                            variant: 'success',
                        };
                    default:
                        if (ticket.approval_status === 'APPROVED') {
                            return {
                                action: null,
                                icon: CheckIcon,
                                key: 'gigApproved',
                                variant: 'success',
                            };
                        }
                        return null;
                }
            }

            default:
                return null;
        }
    }
);

export const getMenuActions = createSelector(
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    getPermittedActions,
    getAvailableActions,
    getActiveUser,
    (permittedActions: string[], availableActions: string[], user: ?Object): Object[] => {
        const actions = [];
        const supervisorRoles = [
            'PLATFORM_ADMIN',
            'SUPER_ADMIN',
            'SELF_SERVICE',
            'ADMIN',
            'OPERATOR',
        ];

        if (permittedActions.includes('cancel')) {
            actions.push({
                name: 'cancel',
                icon: CancelIcon,
                disabled: !availableActions.includes('cancel'),
            });
        }

        if (permittedActions.includes('reopen')) {
            actions.push({
                name: 'reopen',
                icon: AssignmentReturnIcon,
                disabled: !availableActions.includes('reopen'),
            });
        }

        if (permittedActions.includes('extendReservationWindow')) {
            actions.push({
                name: 'extendReservationWindow',
                icon: HourglassEmptyIcon,
                disabled: !availableActions.includes('extendReservationWindow'),
            });
        }

        actions.push(
            { name: 'print', icon: PrintIcon },
            { name: 'viewMetadata', icon: InfoIcon },
        );

        if (user && supervisorRoles.includes(user.role)) {
            actions.push({
                name: 'viewProjectSummary',
                icon: BarChartIcon,
            });
        }

        return actions;
    }
);

export const getDataItems = createSelector(
    getTicket,
    (ticket: ?Object) => (ticket ? ticket.data_items : []),
);

export const getDataItemMap = createSelector(
    getDataItems,
    (dataItems: Object[]) => (
        dataItems.reduce((map, dataItem) => {
            const {
                data_type_id: dataTypeId,
                observation_target_id: observationTargetId,
                template_id: templateId,
            } = dataItem;

            const key = [dataTypeId, observationTargetId, templateId].join('_');
            return { ...map, [key]: dataItem };
        }, {})
    )
);

export const getQuestionCount = createSelector(
    getTicket,
    (ticket: ?Object): number => {
        if (!ticket) {
            return 0;
        }

        const { data_type_map: dataTypeMap } = ticket;
        return Object.keys(dataTypeMap).length;
    }
);

// export const getRequiredCount = createSelector(
//     getTicket,
//     (ticket: ?Object): Object => {
//         if (!ticket) {
//             return 0;
//         }
//
//         const { data_type_map: dataTypeMap } = ticket;
//
//         return Object.values(dataTypeMap)
//             .reduce((count, dataType) => (
//                 dataType.is_required ? count + 1 : count
//             ), 0);
//     }
// );

export const getAnsweredCount = createSelector(
    getDataItems,
    (dataItems: Object[]) => (
        dataItems.reduce((cnt: number, dataItem: Object) => {
            const isAnswered = dataItem.data_item_answered || false;
            if (isAnswered) {
                return cnt + 1;
            }
            return cnt;
        }, 0)
    )
);

export default {
    getAnsweredCount,
    getAvailableActions,
    getCallToAction,
    getDataItemMap,
    getDataItems,
    getMenuActions,
    getOrganizationId,
    getQuestionCount,
    getPermittedActions,
    getProgress,
    getTicket,
    getTicketId,
    getValidActions,
};
