// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useEffect, useMemo } from 'react';
import cx from 'classnames';
import moment from 'moment';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { hoistStatics } from 'recompose';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import {
    DateRange as DateRangeIcon,
    Error as ErrorIcon,
    Warning as WarningIcon,
} from '@material-ui/icons';
import type { Dispatch } from 'redux';
import type { Connector } from 'react-redux';
import SubmitButton from '../../components/SubmitButton';
import FormikDateTimePicker from '../../components/FormikDateTimePicker';
import * as dialog from '../../ducks/dialog';
import { actions, selectors } from './duck';
import styles from './styles';
import type { State as RootState } from '../../redux/initialState';

type OwnProps = {}
type StateProps = {
    extendableTicketIds: number[],
    onClose: () => void,
    onSubmitFail: () => void,
    onSubmitSuccess: () => void,
    open: boolean,
    ticketIds: number[],
}
type DispatchProps = {
    registerDialog: (name: string) => void,
    submit: (value: Object) => Promise<void>,
    unregisterDialog: (name: string) => void,
};
type Props = OwnProps & StateProps & DispatchProps;

const ExtendSchema = yup.object().shape({
    dueDate: yup.date()
        .test({
            message: '${path} field must be in the future', // eslint-disable-line no-template-curly-in-string
            name: 'futureDate',
            test(value) {
                const date = moment(value);
                return date.isSameOrAfter(moment());
            },
        })
        .required('validation.required'),
    ticketIds: yup.array().of(yup.number()),
});

const useStyles = makeStyles(styles, { name: 'ExtendDialog' });

export function ExtendDialog(props: Props) {
    const {
        extendableTicketIds,
        onClose,
        onSubmitFail,
        onSubmitSuccess,
        open,
        registerDialog,
        submit,
        ticketIds,
        unregisterDialog,
    } = props;

    const classes = useStyles(props);
    const { t } = useTranslation();

    useEffect(() => {
        registerDialog(ExtendDialog.NAME);
        return () => {
            unregisterDialog(ExtendDialog.NAME);
        };
    }, [registerDialog, unregisterDialog]);

    const extendWarning = useMemo(() => {
        // @todo There may be other conditions that prevent tickets from being extended
        const canExtendAllTickets = extendableTicketIds.length === ticketIds.length;
        return !canExtendAllTickets
            ? 'Some of the selected tickets cannot be extended'
            : undefined;
    }, [extendableTicketIds, ticketIds]);

    const extendError = useMemo(() => {
        // @todo There may be other conditions that prevent tickets from being extended
        const canExtendSomeTickets = extendableTicketIds.length > 0;
        return !canExtendSomeTickets
            ? 'The tickets you selected cannot be extended'
            : undefined;
    }, [extendableTicketIds]);

    const displayWarning = !extendError && extendWarning;
    const displayError = !!extendError;

    const errorMessage = (
        <DialogContentText className={classes.error}>
            <ErrorIcon className={classes.icon} />
            <span>{extendError}</span>
        </DialogContentText>
    );

    const warningMessage = (
        <DialogContentText className={classes.warning}>
            <WarningIcon className={classes.icon} />
            <span>{extendWarning}</span>
        </DialogContentText>
    );

    return (
        <Formik
          enableReinitialize
          initialValues={{ ticketIds: extendableTicketIds }}
          onSubmit={(values, { setStatus }) => (
              submit(values)
                  .then(() => {
                      setStatus({ success: true });
                      onSubmitSuccess();
                  })
                  .catch((error) => {
                      setStatus({ error });
                      onSubmitFail();
                      return Promise.reject(error);
                  })
          )}
          validationSchema={ExtendSchema}
        >
            {(formikProps: Object) => {
                const { isSubmitting, isValid, handleReset, handleSubmit } = formikProps;
                return (
                    <Dialog open={open} onClose={onClose} onExited={handleReset}>
                        <Form onSubmit={handleSubmit}>
                            <DialogTitle>{t('extendDialog.title')}</DialogTitle>
                            <DialogContent className={cx({ [classes.disablePaddingBottom]: displayError || displayWarning })}>
                                <DialogContentText>
                                    {t('extendDialog.text', { count: ticketIds.length })}
                                </DialogContentText>
                                <FormikDateTimePicker
                                  name="dueDate"
                                  label={t('extendDialog.dueDate')}
                                  customInputIcon={<DateRangeIcon />}
                                />
                                {displayWarning ? warningMessage : null}
                                {displayError ? errorMessage : null}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={onClose}>
                                    {t('extendDialog.cancel')}
                                </Button>
                                <SubmitButton
                                  className={classes.submitButton}
                                  color="primary"
                                  disabled={isSubmitting || !isValid}
                                  submitting={isSubmitting}
                                  type="submit"
                                  variant="contained"
                                >
                                    {t('extendDialog.confirm')}
                                </SubmitButton>
                            </DialogActions>
                        </Form>
                    </Dialog>
                );
            }}
        </Formik>
    );
}

ExtendDialog.NAME = 'extend';
ExtendDialog.defaultProps = {
    onClose: () => {},
    onSubmitFail: () => {},
    onSubmitSuccess: () => {},
    open: false,
    ticketIds: [],
};

const mapStateToProps = (state: RootState): StateProps => {
    const props = {
        ...ExtendDialog.defaultProps,
        ...state.dialog.registered[ExtendDialog.NAME],
    };
    return {
        ...props,
        extendableTicketIds: selectors.getExtendableTicketIds(state, props),
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    registerDialog: (name: string) => dispatch(dialog.actions.register(name)),
    submit: (values: Object) => dispatch(actions.submit(values)),
    unregisterDialog: (name: string) => dispatch(dialog.actions.unregister(name)),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
export default hoistStatics(connector)(ExtendDialog);
