var Handlebars = require('/tmp/build_a9d70681abb25b82ca4489607bd6ccee/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a data-control=\"close\" class=\"buttonly cancel\" href=\"javascript:;\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? lookupProperty(stack1,"no") : stack1), depth0))
    + "</a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a data-control=\"confirm\" class=\"buttonly\" href=\"javascript:;\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? lookupProperty(stack1,"yes") : stack1), depth0))
    + "</a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-confirm\">\n    <div>\n        <div style=\"padding:2px 5px;\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? lookupProperty(stack1,"confirm") : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? lookupProperty(stack1,"action") : stack1), depth0))
    + "</div>\n        <div style=\"padding:2px 5px;\" data-target=\"what\" class=\"highlight popup-content\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? lookupProperty(stack1,"subject") : stack1), depth0))
    + "</div>\n        "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? lookupProperty(stack1,"subtext") : stack1), depth0))
    + "\n\n        <div class=\"primary actions\" style=\"margin-top:20px;\">\n            <div class=\"right\">\n                <div class=\"loading-xsm gray\" data-buffering=\"\" style=\"display: none;\"><span class=\"glyphicon glyphicon-refresh\"></span></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"showCancelButton") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":16},"end":{"line":12,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"showConfirmationButton") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":16},"end":{"line":15,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n\n    <div class=\"action messages\">\n        <div class=\"message confirmation\">\n            <h1><span class=\"im icon-checkmark\"></span><span data-target=\"confirmation-msg\"></span></h1>\n        </div>\n        <div class=\"message problem\">\n            <h1><span class=\"im icon-warning\">!</span><span data-target=\"problem-msg\"></span></h1>\n        </div>\n    </div>\n</div>\n<div class=\"modal-background\" data-control=\"close\"></div>\n";
},"useData":true});