// @flow
export default (theme: Object) => ({
    form: {
        width: '100%',
        maxWidth: 600,
    },
    title: {},
    description: {},
    asap: {
        '& $control': {
            marginLeft: `calc(${theme.spacing(1)} + 20px)`,
        },
    },
    range: {
        '& $control': {
            marginLeft: `calc(${theme.spacing(1)} + 20px)`,
            display: 'flex',
            alignItems: 'baseline',
            flexWrap: 'wrap',

            '& > $datePicker:first-child': {
                marginBottom: theme.spacing(1),
                marginRight: theme.spacing(2),
            },
        },
    },
    control: {},
    datePicker: {
        maxWidth: 200,
    },
    datePickerLabel: {
        fontSize: 12,
    },
});
