// @flow
import axios from 'axios';
import Component from '../Component';
import { createBlobFromFile, dataUriToBlob } from '../../util/FileUtil';
import template from '../../templates/Upload.hbs';
import logger from '../../../../common/util/logger';

type UploadProps = {
    mimeTypes?: Array<string>,
    onSave: Function,
    onUpload: Function,
    onSignature: Function,
    onClose: Function,
    bucket: string,
    saveOnUpload?: boolean,
    maxMBs?: number,
};

export type SavedFile = {
    file_name: string,
    file_type: Array<string>,
    url: string,
};

/**
 * @class (View) Upload
 * @extends (View) Component
 * @constructor
 */
const Upload = Component.extend({

    events: {
        'click [data-action="filePicker"]': 'pickFile',
        'click [data-action="takePhoto"]': 'camera',
        'click [data-action="close"]': 'close',
        'click [data-action="save"]': 'save',
    },

    className: '__modal-upload__ modal upload',

    /**
     * @method initialize
     *
     * @param {Object} [options] Options object
     * @param {Function} [options.onSave] onSave callback
     * @param {Function} [options.onUpload] onUpload callback
     * @param {Function} [options.onSignature] onSignature callback
     * @param {Function} [options.onClose] onClose callback
     * @param {String} [options.bucket] AWS bucket where to store the file
     * @param {Number} [options.maxMBs]
     */
    initialize(options: UploadProps) {
        this.options = options;

        // called when the user clicks "Save" | passed file_name, file_type, and url
        this.onSave = options.onSave || (() => {});

        // called when the Blob is ready for upload | passed the Blob
        this.onUpload = options.onUpload;
        this.bucket = options.bucket;
        this.onSignature = options.onSignature;
        this.onClose = options.onClose;

        this.maxMBs = options.maxMBs || this.maxMBs;

        this.initMimeTypes();

        this.render();
        this.reset();
        this.show();
    },

    /**
     * @method initMimeTypes
     */
    initMimeTypes() {
        this.mimeTypes = this.options.mimeTypes || this.mimeTypes;
        this.mimeTypes = this.mimeTypes.slice(0);

        /* Windows 8 shim for CSV */
        if (this.mimeTypes.indexOf('text/csv') > -1) {
            this.mimeTypes = this.mimeTypes.concat(this.mimeTypesCSV);
        }
    },

    /**
     * @method clearInput
     */
    clearInput() {
        this.url = '';
        this.dataURL = '';
        this.$els.filePicker.val('');
        this.canSave();
    },

    /**
     * @method reset
     */
    reset() {
        this.clearInput();
        this.$els.video.hide();
        this.$els.buttons.show();
        this.$els.preview.hide();

        // no 'take photo' for Uploaders that don't accept images
        this.$els.takePhoto.toggleClass('hidden', this.mimeTypes.toString().indexOf('image') === -1);
    },

    url: '',
    mimeType: '',
    mimeTypes: ['image/gif', 'image/jpeg', 'image/png', 'application/pdf'],
    mimeTypesCSV: [
        'text/x-csv',
        'text/x-comma-separated-values',
        'text/comma-separated-values',
        'application/csv',
        'application/x-csv',
    ],
    filename: '',
    stream: null,
    maxMBs: 15,

    /**
     * @method camera
     */
    camera() {
        this.$els.error.html('');
        this.$els.buffering.hide();
        this.$els.preview.show();
        this.$els.attachment.html('');
        this.$els.video.show();

        // already streaming input
        if (this.stream) {
            return;
        }

        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            const video = document.createElement('video');
            const container = document.getElementById('webcam-video-stream');

            if (container) {
                container.innerHTML = '';
                container.append(video);

                video.style.height = '240px';
                video.style.width = '290px';

                const constraints = {
                    audio: false,
                    video: {
                        width: 290,
                        height: 240,
                    },
                };

                // $FlowIssue I've already checked if getUserMedia is available but flow still complains
                navigator.mediaDevices.getUserMedia(constraints)
                    .then((stream: MediaStream) => {
                        video.srcObject = stream;

                        video.onloadedmetadata = () => {
                            video.play();
                        };

                        video.onerror = () => {
                            stream.getVideoTracks().forEach((track: MediaStreamTrack) => {
                                track.stop();
                            });

                            this.$els.video.hide();
                            this.$el.find('video').remove();
                            this.stream = null;
                        };

                        this.stream = stream;
                    })
                    .catch((err: Error) => {
                        logger.error(err);
                    });
            }
        } else {
            logger.error('MediaDevices.getUserMedia() is not supported on your browser.');
        }

        const getSnapshot = () => {
            const canvas = document.createElement('canvas');
            const container = document.getElementById('webcam-video-stream');
            const video = container ? container.firstElementChild : null;

            if (video instanceof HTMLVideoElement) {
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;

                const ctx = canvas.getContext('2d');
                ctx.drawImage(video, 0, 0);

                this.previewSnapshot(canvas.toDataURL());
            }
        };

        this.$els.getStill.off('click').on('click', getSnapshot);
    },

    /**
     * @method pickFile
     */
    pickFile() {
        this.$els.error.html('');

        this.$file.trigger('click');
        if (this.stream) {
            this.$els.video.hide();
            this.$el.find('video').remove();
            this.stream.getVideoTracks().forEach((track: MediaStreamTrack) => {
                track.stop();
            });
            this.stream = null;
        }
    },

    /**
     * @method showError
     */
    showError(message: string) {
        this.$file.val('');
        this.$els.buffering.hide();
        this.$els.save.addClass('disabled');
        this.$els.error.html(message);
    },

    /**
     * @method validate
     */
    validate() {
        // Delete previously validated file
        delete this.validatedFile;

        if (!this.$file.length || !this.$file[0].files.length) {
            return false;
        }

        const file = this.$file[0].files[0];
        const blob = createBlobFromFile(file);

        if (file.size > (this.maxMBs * 1024 * 1024)) {
            this.showError(`File size exceeds current limit of ${this.maxMBs}mb`);
            return false;
        }

        if (this.mimeTypes.indexOf(blob.type) === -1) {
            this.showError('File type unsupported');
            return false;
        }

        this.validatedFile = blob.type === 'text/csv' ? blob : file;

        return true;
    },

    /**
     * @method upload
     */
    upload() {
        this.buffering();

        const blob = dataUriToBlob(this.dataURL, this.mimeType);
        const data = new FormData();
        data.append('file', blob);
        data.append('bucket', this.bucket);

        if (this.onUpload) {
            this.onUpload(blob, this.save);
            return;
        }

        axios.post('/upload', data)
            .then((resp: Object) => {
                const { url, key } = resp.data;

                if (typeof this.onSignature === 'function') {
                    this.onSignature({ data: [{ key }] });
                }

                this.url = url;
                this.preview();
                if (this.saveOnUpload && this.canSave()) {
                    this.save();
                }
            });
    },

    /**
     * @method onChangeFile
     */
    onChangeFile() {
        if (!this.validate()) {
            return;
        }

        const file = this.validatedFile;
        const fileReader = new FileReader();

        fileReader.onloadend = () => {
            this.dataURL = fileReader.result;
            this.filename = file.name;
            this.mimeType = file.type;
            this.saveOnUpload = this.options.saveOnUpload !== undefined ? this.options.saveOnUpload : false;
            this.upload();
        };

        fileReader.readAsDataURL(file);
    },

    /**
     * @method preview
     */
    preview() {
        let html;

        if (this.mimeType.indexOf('image') === 0) {
            html = document.createElement('img');
            html.setAttribute('src', this.url || this.dataURL || '');
        } else {
            html = document.createElement('a');
            html.setAttribute('href', this.url || this.dataURL || '');
            html.setAttribute('target', '_blank');
            if (this.filename) {
                html.appendChild(document.createTextNode(this.filename));
            }
        }

        this.$els.attachment.html(html);

        this.loaded();
    },

    saveOnUpload: false,

    previewSnapshot(url: string) {
        this.$els.video.hide();
        this.dataURL = url;
        this.mimeType = 'image/jpeg';
        this.preview();
        this.url = '';
        this.saveOnUpload = this.options.saveOnUpload !== undefined ? this.options.saveOnUpload : true;
        this.canSave();
    },

    buffering() {
        this.$els.video.hide();
        this.$els.attachment.hide();
        this.$els.preview.show();
        this.$els.buffering.show();
        this.canSave();
    },

    loaded() {
        this.$els.video.hide();
        this.$els.attachment.show();
        this.$els.buffering.hide();
        this.canSave();
    },

    close(e?: Event) {
        this.$el.hide();
        this.$el.remove();
        if (this.stream) {
            this.stream.getVideoTracks().forEach((track: MediaStreamTrack) => {
                track.stop();
            });
            this.stream = null;
        }

        // Only call `onClose` if it was invoked through clicking the `cancel` button.
        if (typeof this.onClose === 'function' && e && $(e.target).hasClass('cancel')) {
            this.onClose();
        }
    },

    show() {
        $('body').append(this.$el);
        this.$el.show();
    },

    canSave(): boolean {
        this.$els.save.removeClass('disabled');

        if (!this.url && this.dataURL) {
            return false;
        }

        if (!this.url) {
            this.$els.save.addClass('disabled');
            return false;
        }

        return true;
    },

    save(e?: Event) {
        if (!this.url && this.dataURL) {
            this.upload();
            return;
        }

        if (!this.canSave()) {
            return;
        }

        if (this.options.onSave) {
            // file_name and file_type are the key names expected by the server
            // when saving attachments to a data_type
            this.options.onSave({
                file_name: this.filename,
                file_type: this.mimeType,
                url: this.url,
            });
        }

        this.close(e);
    },

    /**
     * @method render
     */
    render() {
        const text = GW.localisation.shared.photoChooser;
        this.$el.html(template(text));

        this.$file = $(document.createElement('input'))
            .attr({
                type: 'file',
                accept: this.mimeTypes.join(','),
            })
            .on('change', (...args: Array<any>) => { this.onChangeFile(...args); })
            .hide();

        this.$el.append(this.$file);

        this.$els = {
            getStill: this.$el.find('[data-action="getStill"]'),
            save: this.$el.find('[data-action="save"]'),
            buffering: this.$el.find('[data-buffering]'),
            preview: this.$el.find('[data-target="preview"]'),
            video: this.$el.find('[data-target="video"]'),
            buttons: this.$el.find('[data-target="buttons"]'),
            cancel: this.$el.find('[data-action="cancel"]'),
            attachment: this.$el.find('[data-target="attachment"]'),
            filePicker: this.$el.find('[data-action="filePicker"]'),
            takePhoto: this.$el.find('[data-action="takePhoto"]'),
            error: this.$el.find('[data-target="errors"]'),
        };
    },

});

export default Upload;
