// @flow
const namespace = 'g2/session';

export const REFRESH = `${namespace}/REFRESH`;
export const REFRESH_SUCCESS = `${REFRESH}_SUCCESS`;
export const REFRESH_ERROR = `${REFRESH}_ERROR`;

export const INITIALIZE = `${namespace}/INITIALIZE`;
export const LOGOUT = `${namespace}/LOGOUT`;
export const START = `${namespace}/START`;

export default {
    INITIALIZE,
    LOGOUT,
    REFRESH,
    REFRESH_SUCCESS,
    REFRESH_ERROR,
    START,
};
