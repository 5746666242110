// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useEffect, useMemo, useRef } from 'react';
import { validateYupSchema, yupToFormErrors } from 'formik';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Button, CircularProgress } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import ProgressTracker from '../ProgressTracker';
import styles from './styles';

type Props = {
    config: Object[],
    context: ?Object,
    currentStep: string,
    dirty: boolean,
    isSubmitting: boolean,
    isValid: boolean,
    onNextClick: (event: SyntheticEvent<any>, nextStep: ?string) => void,
    onPrevClick: (event: SyntheticEvent<any>, prevStep: ?string) => void,
    onStepChange: (step: string, reason: string) => void,
    setErrors: (errors: Object) => void,
    validationSchemaRef: Object,
    values: Object,
};

const computeSteps = (config: Object[], values: Object, context: Object) => (
    config.reduce((arr: Object[], stepConfig: Object) => {
        const { skip, ...step } = stepConfig;
        if (skip === true || (typeof skip === 'function' && skip(values, context))) {
            return arr;
        }
        return [...arr, step];
    }, [])
);

const useStyles = makeStyles(styles, { name: 'WizardForm' });

export default function WizardForm(props: Props) {
    const {
        config,
        context,
        currentStep,
        onNextClick,
        onPrevClick,
        onStepChange,
        setErrors,
        validationSchemaRef,
        ...formikProps
    } = props;

    const { isSubmitting, isValid, values } = formikProps;

    const classes = useStyles(props);
    const { t } = useTranslation();

    // We only want to run validations when the page changes. By storing values using a
    // ref, we can prevent annoying eslint errors in useEffect below
    const valuesRef = useRef(null);
    const contextRef = useRef(null);

    valuesRef.current = values;
    contextRef.current = context;

    const { pageIndex, stepIndex, steps } = useMemo(() => {
        const computedSteps = computeSteps(config, valuesRef.current, contextRef.current);
        const parts = currentStep.split('/');
        const name = parts[0] || (computedSteps[0] ? computedSteps[0].name : '');
        const page = parseInt(parts[1], 10) || 0;

        const pages = computedSteps.filter((step: Object) => step.name === name);
        const current = pages[page] || pages[0] || computedSteps[0];

        return {
            pageIndex: pages.indexOf(current),
            stepIndex: computedSteps.indexOf(current),
            steps: computedSteps,
        };
    }, [config, currentStep]);

    const handleStepClick = (event, step: string) => {
        const [name, page] = step.split('/');
        const [currentName, currentPage] = currentStep.split('/');

        if (`${currentName}/${currentPage || 0}` !== `${name}/${page || 0}`) {
            onStepChange(step, 'navigation');
        }
    };

    const handleNextClick = (event) => {
        const computedSteps = computeSteps(config, valuesRef.current, contextRef.current);
        const nextStep = computedSteps[stepIndex + 1];

        if (nextStep) {
            const pages = computedSteps.filter((step: Object) => step.name === nextStep.name);
            const page = pages.indexOf(nextStep);
            onNextClick(event, `${nextStep.name}/${page}`);
        } else {
            onNextClick(event, null);
        }
    };

    const handlePrevClick = (event) => {
        const computedSteps = computeSteps(config, valuesRef.current, contextRef.current);
        const prevStep = computedSteps[stepIndex - 1];

        if (prevStep) {
            const pages = computedSteps.filter((step: Object) => step.name === prevStep.name);
            const page = pages.indexOf(prevStep);
            onPrevClick(event, `${prevStep.name}/${page}`);
        } else {
            onPrevClick(event, null);
        }
    };

    const { name: stepName, component: StepComponent, validationSchema } = steps[stepIndex];
    validationSchemaRef.current = validationSchema;

    useEffect(() => {
        if (currentStep !== `${stepName}/${pageIndex}`) {
            onStepChange(`${stepName}/${pageIndex}`, 'redirect');
        }
    }, [currentStep, onStepChange, pageIndex, stepName]);

    useEffect(() => {
        const emptyErrors = {};
        validateYupSchema(valuesRef.current, validationSchema, false, contextRef.current)
            .then(() => {
                setErrors(emptyErrors);
            })
            .catch((err: any) => {
                if (err.name === 'ValidationError') {
                    setErrors(yupToFormErrors(err));
                }
            });
    }, [setErrors, validationSchema]);

    const editMode = false;
    const isLastStep = stepIndex === steps.length - 1;
    let nextButtonText = '';

    if (editMode) {
        nextButtonText = isLastStep
            ? t('projectBuilder.navigation.relaunch')
            : t('projectBuilder.navigation.continue');
    } else {
        nextButtonText = isLastStep
            ? t('projectBuilder.navigation.launch')
            : t('projectBuilder.navigation.saveAndContinue');
    }

    return (
        <div className={classes.root}>
            <ProgressTracker
              {...formikProps}
              context={context}
              currentStep={currentStep}
              onStepClick={handleStepClick}
              steps={steps}
            />
            <StepComponent {...formikProps} />
            <nav className={classes.navigation}>
                {stepIndex > 0
                    ? (
                        <Button onClick={handlePrevClick} variant="contained">
                            <KeyboardArrowLeft />
                            {t('projectBuilder.navigation.back')}
                        </Button>
                    )
                    : null}
                <Button
                  className={classes.nextButton}
                  color={isLastStep ? 'secondary' : 'primary'}
                  disabled={isSubmitting || !isValid}
                  onClick={handleNextClick}
                  variant="contained"
                >
                    {isSubmitting ? <CircularProgress size={24} className={classes.progress} /> : null}
                    {nextButtonText}
                    <KeyboardArrowRight />
                </Button>
            </nav>
        </div>
    );
}
