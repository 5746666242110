// @flow
import { handleActions } from 'redux-actions';
import type { ActionType } from 'redux-actions';
import types from './types';
import typeof { fetchAllSubscriptionsSuccess, unsubscribeSuccess } from './actions';

export type State = {
    subscriptionIds: number[],
};
export const init: State = {
    subscriptionIds: [],
};

export default handleActions({
    [types.FETCH_ALL_SUBSCRIPTIONS_SUCCESS]: (state: State, action: ActionType<fetchAllSubscriptionsSuccess>) => ({
        ...state,
        subscriptionIds: action.payload,
    }),
    [types.UNSUBSCRIBE_SUCCESS]: (state: State, action: ActionType<unsubscribeSuccess>) => ({
        ...state,
        subscriptionIds: state.subscriptionIds.filter((id: number) => id !== action.payload),
    }),
}, init);
