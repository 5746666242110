// @flow
/* eslint-disable max-len */
import React, { Fragment } from 'react';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';

const UploadSuccess = createSvgIcon(
    <Fragment>
        <path d="M23.5,31.8431458 L17.5852419,25.9283877 C16.0248253,24.3679711 13.4910294,24.366835 11.9289322,25.9289322 C10.3700136,27.4878508 10.3665912,30.0234455 11.9283877,31.5852419 L20.4147581,40.0716123 C20.5133999,40.1702541 20.6159315,40.2626649 20.7218615,40.3488435 C22.2835669,41.8725651 24.794234,41.8626202 26.3461564,40.3106978 L43.3106978,23.3461564 C44.8771021,21.7797521 44.8758057,19.2483887 43.3137085,17.6862915 C41.7547899,16.1273729 39.2176035,16.1255422 37.6538436,17.6893022 L23.5,31.8431458 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z" style={{ stroke: '#747474', strokeOpacity: 0.2, strokeWidth: 1, fill: '#ffffff', fillOpacity: 0.82, fillRule: 'evenodd' }} />
    </Fragment>,
    'UploadSuccess'
);

UploadSuccess.defaultProps = {
    viewBox: '0 0 54 54',
};

export default UploadSuccess;
