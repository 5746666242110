// @flow
const namespace = 'g2/ticketList';

export const SELECT = `${namespace}/SELECT`;
export const SELECT_ALL = `${namespace}/SELECT_ALL`;
export const DESELECT = `${namespace}/DESELECT`;
export const DESELECT_ALL = `${namespace}/DESELECT_ALL`;
export const CLEAR_SELECTED = `${namespace}/CLEAR_SELECTED`;

export const RESET_COLUMNS = `${namespace}/RESET_COLUMNS`;
export const TOGGLE_COLUMN = `${namespace}/TOGGLE_COLUMN`;
export const TOGGLE_FILTER_DRAWER = `${namespace}/TOGGLE_FILTER_DRAWER`;

export const FETCH_DATA = `${namespace}/FETCH_DATA`;
export const FETCH_DATA_SUCCESS = `${FETCH_DATA}_SUCCESS`;
export const FETCH_DATA_ERROR = `${FETCH_DATA}_ERROR`;

export const LOAD_FILTERS = `${namespace}/LOAD_FILTERS`;
export const LOAD_FILTERS_SUCCESS = `${LOAD_FILTERS}_SUCCESS`;
export const LOAD_FILTERS_ERROR = `${LOAD_FILTERS}_ERROR`;

export const REFRESH = `${namespace}/REFRESH`;
export const REFRESH_SUCCESS = `${REFRESH}_SUCCESS`;
export const REFRESH_ERROR = `${REFRESH}_ERROR`;

export default {
    CLEAR_SELECTED,
    DESELECT,
    DESELECT_ALL,
    FETCH_DATA,
    FETCH_DATA_ERROR,
    FETCH_DATA_SUCCESS,
    LOAD_FILTERS,
    LOAD_FILTERS_ERROR,
    LOAD_FILTERS_SUCCESS,
    REFRESH,
    REFRESH_ERROR,
    REFRESH_SUCCESS,
    RESET_COLUMNS,
    SELECT,
    SELECT_ALL,
    TOGGLE_COLUMN,
    TOGGLE_FILTER_DRAWER,
};
