// @flow
import React from 'react';
import cx from 'classnames';
import { Paper, RootRef } from '@material-ui/core';

export default function Menu(props: Object) {
    const { children, innerProps, innerRef, placement, selectProps } = props;
    const { classes, menuType } = selectProps;

    const className = cx(classes.paper, {
        [classes.menu]: menuType === 'menu',
        [classes.list]: menuType === 'list',
    });

    const style = placement === 'top'
        ? { bottom: 40 }
        : null;

    return (
        <RootRef rootRef={innerRef}>
            <Paper
              {...innerProps}
              className={className}
              elevation={menuType === 'list' ? 0 : 8}
              square
              style={style}
            >
                {children}
            </Paper>
        </RootRef>
    );
}
