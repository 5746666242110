// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useEffect } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
// $FlowTypedIssue update react-redux libdef
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Button, Dialog, DialogTitle } from '@material-ui/core';
import { Clear as ClearIcon, Person as PersonIcon } from '@material-ui/icons';
import type { $AxiosError } from 'axios';
import { updateTickets } from '../../ducks/gigwalk';
import { enqueue as enqueueSnackbar } from '../../ducks/snackbar';
import { register as registerDialog, unregister as unregisterDialog } from '../../ducks/dialog';
import AssignForm from './AssignForm';
import styles from './styles';
import type { State as RootState } from '../../store';

type Props = {
    onClose: () => void,
    onSubmitFail: () => void,
    onSubmitSuccess: () => void,
    open: boolean,
    ticketIds: Object[],
};

const AssignSchema = yup.object().shape({
    assignee: yup.object()
        .required('validation.required'),
    force: yup.boolean(),
    ticketIds: yup.array().of(yup.number()),
});

const useStyles = makeStyles(styles, { name: 'AssignDialog' });

export default function AssignDialog(props: Props) {
    const dispatch = useDispatch();
    const classes = useStyles(props);
    const { t } = useTranslation();

    const dialogProps = useSelector((state: RootState) => ({ ...props, ...state.dialog.registered[AssignDialog.NAME] }));

    const {
        onClose,
        onSubmitFail,
        onSubmitSuccess,
        open,
        ticketIds,
    } = dialogProps;

    useEffect(() => {
        dispatch(registerDialog(AssignDialog.NAME));
        return () => {
            dispatch(unregisterDialog(AssignDialog.NAME));
        };
    }, [dispatch]);

    return (
        <Formik
          enableReinitialize
          initialValues={{ assignee: null, force: false, ticketIds }}
          onSubmit={(values, { setStatus }) => {
              const { assignee, force } = values;
              const params = {
                  customer_id: assignee.value.id,
                  ticket_ids: ticketIds,
                  action: 'assign',
                  force: !!force,
              };
              return dispatch(updateTickets(params))
                  .then((resp: Object) => {
                      if (resp.original && resp.original.gw_api_response) {
                          dispatch(enqueueSnackbar(resp.original.gw_api_response, { variant: 'warning' }));
                      }
                      setStatus({ success: true });
                      onSubmitSuccess();
                  })
                  .catch((error: $AxiosError<Object>) => {
                      const resp = error ? error.response : null;
                      if (resp && resp.data && resp.data.gw_api_response) {
                          dispatch(enqueueSnackbar(resp.data.gw_api_response, { variant: 'error' }));
                      }
                      setStatus({ error });
                      onSubmitFail();
                      return Promise.reject(error);
                  });
          }}
          validationSchema={AssignSchema}
        >
            {(formikProps: Object) => {
                const { handleReset } = formikProps;
                return (
                    <Dialog
                      classes={{ paper: classes.dialogPaper }}
                      fullWidth
                      maxWidth="xs"
                      onClose={onClose}
                      onExited={handleReset}
                      open={open}
                    >
                        <Button className={classes.cancel} color="inherit" onClick={onClose}>
                            <ClearIcon />
                        </Button>
                        <DialogTitle className={classes.dialogTitle} disableTypography>
                            <Avatar className={classes.avatar}>
                                <PersonIcon className={classes.avatarIcon} />
                            </Avatar>
                            <h1>{t('assignDialog.text')}</h1>
                        </DialogTitle>
                        <AssignForm {...formikProps} />
                    </Dialog>
                );
            }}
        </Formik>
    );
}

AssignDialog.NAME = 'assign';

AssignDialog.defaultProps = {
    onClose: () => {},
    onSubmitFail: () => {},
    onSubmitSuccess: () => {},
    open: false,
    ticketIds: [],
};
