// @flow
export default (theme: Object) => ({
    '@global body': {
        background: theme.palette.background.default, // theme.palette.background.appBar, $neutral-2
    },
    datetime: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexShrink: 0,

        '& > :nth-child(2)': {
            color: 'rgba(0, 0, 0, 0.54)',
        },
    },
    type: {
        fontStyle: 'italic',
    },
    search: {
        '&:not(:only-child)': {
            minHeight: 0,
        },
    },
    filters: {},
});
