// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    List,
    ListItem,
    ListItemText,
    Typography,
} from '@material-ui/core';
import {
    Close as CloseIcon,
    InfoOutlined as InfoOutlinedIcon,
} from '@material-ui/icons';
import { STATE } from '../../../../../../../browser/shared/constant/ProjectConstant';
import FormikMaskedInput from '../../../../../../components/FormikMaskedInput';
import SubmitButton from '../../../../../../components/SubmitButton';
import UnsubscribeButton from '../UnsubscribeButton';
import styles from './styles';

type Props = {
    dirty: boolean,
    handleSubmit: Function,
    isSubmitting: boolean,
    isValid: boolean,
    onUnsubscribe: (id: number) => ?Promise<void>,
    readOnly: boolean,
    setFieldValue: (field: string, value: any) => void,
    setStatus: (status: any) => void,
    subscriptions: Object[],
    status?: any,
    values: Object,
};

const notificationEvents = [
    { label: 'gigAssigned', value: 'ASSIGNED' },
    { label: 'commentPosted', value: 'COMMENT' },
    { label: 'paymentFailed', value: 'PAYMENT_FAILED' },
    { label: 'paymentSuccessful', value: 'PAYOUT' },
    { label: 'gigRejected', value: 'REJECTED' },
    { label: 'gigReopened', value: 'REOPENED' },
    { label: 'gigSubmitted', value: 'SUBMITTED' },
    { label: 'gigUnassigned', value: 'UNASSIGNED' },
];

const useStyles = makeStyles(styles, { name: 'PreferencesForm' });

export function PreferencesForm(props: Props) {
    const { dirty, handleSubmit, isSubmitting, onUnsubscribe, readOnly, setFieldValue, subscriptions, values } = props;

    const classes = useStyles(props);
    const { t } = useTranslation();

    const handleUnsubscribe = useCallback((event: SyntheticEvent<any>) => {
        const subscriptionId = parseInt(event.currentTarget.dataset.id, 10);
        return onUnsubscribe(subscriptionId);
    }, [onUnsubscribe]);

    const handleCheckboxChange = useCallback((event: SyntheticInputEvent<any>) => {
        const { checked, value } = event.target;

        if (readOnly) {
            return;
        }

        const disabled = new Set(values.disabledNotifications);
        const enabled = new Set(values.enabledNotifications);

        if (checked) {
            enabled.add(value);
            disabled.delete(value);
        } else {
            enabled.delete(value);
            disabled.add(value);
        }

        setFieldValue('enabledNotifications', Array.from(enabled).sort());
        setFieldValue('disabledNotifications', Array.from(disabled).sort());
    }, [readOnly, setFieldValue, values.disabledNotifications, values.enabledNotifications]);

    return (
        <Form className={classes.root}>
            <section className={cx(classes.section, classes.schedule)}>
                <Typography className={classes.sectionHeader} variant="subtitle1">
                    {t('profilePage.preferences.schedule')}
                </Typography>
                <fieldset className={cx({ [classes.readOnly]: readOnly })}>
                    <FormikMaskedInput
                      fullWidth
                      label={t('profilePage.preferences.maxWeeklyHours')}
                      margin="dense"
                      name="maxWeeklyHours"
                      options={{
                          numeral: true,
                          numeralThousandsGroupStyle: 'thousand',
                          numeralPositiveOnly: true,
                      }}
                      readOnly={readOnly}
                      type="number"
                      variant="outlined"
                    />
                    <FormikMaskedInput
                      fullWidth
                      label={t('profilePage.preferences.idealWeeklyHours')}
                      margin="dense"
                      name="idealWeeklyHours"
                      options={{
                          numeral: true,
                          numeralThousandsGroupStyle: 'thousand',
                          numeralPositiveOnly: true,
                      }}
                      readOnly={readOnly}
                      type="number"
                      variant="outlined"
                    />
                </fieldset>
            </section>
            <section className={cx(classes.section, classes.notifications)}>
                <Typography className={classes.sectionHeader} variant="subtitle1">
                    {t('profilePage.preferences.notifications')}
                </Typography>
                <FormControl className={cx(classes.formControl, { [classes.readOnly]: readOnly })} component="fieldset">
                    <FormLabel component="legend">
                        {t('profilePage.preferences.notificationTriggers')}
                    </FormLabel>
                    <FormGroup className={classes.formGroup}>
                        {notificationEvents.map((option: Object) => (
                            <FormControlLabel
                              control={<Checkbox checked={values.enabledNotifications.includes(option.value)} color="primary" />}
                              label={t(`profilePage.preferences.notificationEvents.${option.label}`)}
                              name="enabledNotifications"
                              onChange={handleCheckboxChange}
                              value={option.value}
                            />
                        ))}
                    </FormGroup>
                </FormControl>
                <FormControl className={classes.formControl} component="fieldset">
                    <FormLabel component="legend">
                        {t('profilePage.preferences.currentSubscriptions')}
                    </FormLabel>
                    {subscriptions.length > 0
                        ? (
                            <List classes={{ root: classes.list, padding: classes.listPadding }}>
                                {subscriptions.map((subscription: Object, index: number) => {
                                    const { id, organization_id: orgId, state, title } = subscription;
                                    let projectState;

                                    switch (state) {
                                        case STATE.DRAFT:
                                            projectState = 'create';
                                            break;

                                        case STATE.ACTIVE:
                                            projectState = 'active';
                                            break;

                                        default:
                                            projectState = 'archive';
                                            break;
                                    }

                                    return (
                                        <ListItem
                                          button
                                          className={classes.listItem}
                                          data-index={index}
                                          disableRipple
                                          disableFocuseRipple
                                          divider={index < subscriptions.length - 1}
                                          key={index}
                                        >
                                            <ListItemText
                                              primary={(
                                                  <Link to={`/projects/${orgId}/${projectState}/${id}`}>
                                                      {title}
                                                  </Link>
                                              )}
                                            />
                                            {!readOnly
                                                ? (
                                                    <UnsubscribeButton
                                                      classes={{
                                                          root: classes.unsubscribeButton,
                                                          submitting: classes.submitting,
                                                      }}
                                                      data-id={id}
                                                      onClick={handleUnsubscribe}
                                                      size="small"
                                                    >
                                                        <CloseIcon fontSize="inherit" />
                                                    </UnsubscribeButton>
                                                )
                                                : null}
                                        </ListItem>
                                    );
                                })}
                            </List>
                        )
                        : (
                            <div className={classes.info}>
                                <InfoOutlinedIcon className={classes.infoIcon} />
                                <Typography variant="body2">
                                    {t('profilePage.preferences.noSubscriptions')}
                                </Typography>
                            </div>
                        )}
                </FormControl>
            </section>
            {!readOnly
                ? (
                    <SubmitButton
                      className={classes.submitButton}
                      color="secondary"
                      disabled={!dirty || isSubmitting}
                      onClick={handleSubmit}
                      size="large"
                      submitting={isSubmitting}
                      type="submit"
                      variant="contained"
                    >
                        {t('profilePage.preferences.updateProfile')}
                    </SubmitButton>
                )
                : null}
        </Form>
    );
}

export default PreferencesForm;
