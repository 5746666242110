// @flow

/**
 * @property BrowserUtil
 * @type {origin:string}
 */
const BrowserUtil: {
    origin: string,
    openNewWindow: (event: JQueryEventObject) => void
} = {
    get origin() {
        let { origin } = window.location;
        if (!origin) {
            const port = window.location.port ? `:${window.location.port}` : '';
            origin = `${window.location.protocol}//${window.location.hostname}${port}`;
        }
        return origin;
    },

    openNewWindow(event: JQueryEventObject) {
        const link = $(event.currentTarget).attr('href');
        if (link) {
            event.preventDefault();
            window.open(link, '_blank');
        }
    },
};

export default BrowserUtil;
