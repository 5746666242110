// @flow
import moment from 'moment';
import { setIn } from 'formik';

function setProperty(ticket: Object, path: string, value: any, transform?: (value: any) => any) {
    const transformedValue = transform && value !== undefined ? transform(value) : value;
    const retVal = setIn(ticket, path, transformedValue);
    Object.assign(ticket, retVal);
}

export default function mapValuesToTicket(values: Object): Object {
    const data = {};

    let timeEstimate;
    if (values.hasOwnProperty('timeEstimate')) {
        const multiplier = values.timeUnit === 'minutes' ? 60 : 3600;
        timeEstimate = Math.round(values.timeEstimate * multiplier);
    }

    setProperty(data, 'date_scheduled', values.scheduledDate, (value) => moment(value).format('YYYY-MM-DDTHH:mm'));
    setProperty(data, 'due_date', values.dueDate, (value) => moment(value).format('YYYY-MM-DDTHH:mm'));
    setProperty(data, 'price', values.price);
    setProperty(data, 'start_date', values.startDate, (value) => moment(value).format('YYYY-MM-DDTHH:mm'));
    setProperty(data, 'time_estimate', timeEstimate);
    setProperty(data, 'time_zone', values.timezone);

    return Object.entries(data)
        .reduce((acc: Object, [key, value]: [string, mixed]) => (
            value !== undefined ? { ...acc, [key]: value } : acc
        ), {});
}
