// @flow
import { handleActions } from 'redux-actions';
import type { ActionType } from 'redux-actions';
import types from './types';
import type { fetchTicketStatsSuccess } from './actions';

export type State = {
    activeCount: number,
    submittedCount: number,
};
export const init: State = {
    activeCount: 0,
    submittedCount: 0,
};

export default handleActions({
    [types.FETCH_TICKET_STATS_SUCCESS]: (state: State, action: ActionType<fetchTicketStatsSuccess>) => ({
        ...state,
        ...action.payload,
    }),
}, init);
