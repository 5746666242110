// @flow
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import type { ContextRouter } from 'react-router';
import { USER_ROLES } from '../../../../../../browser/shared/constant/UserRoles';
import { customer as customerSchema } from '../../../../../redux/entities/schemas';
import type { State as RootState } from '../../../../../redux/initialState';

const getUser = (state: RootState): ?Object => state.session.user;
const getEntities = (state: RootState): Object => state.entities;
const getCustomerId = <P: {}>(state: RootState, props: { ...P, ...ContextRouter }): number => {
    const { match } = props;
    return parseInt(match.params.customerId, 10);
};

const getCustomer = createSelector(
    getUser,
    getEntities,
    getCustomerId,
    (user: ?Object, entities: Object, customerId: number) => (
        user && user.id === customerId ? user : denormalize(customerId, customerSchema, entities)
    )
);

export const getCustomerAddress = createSelector(
    getCustomer,
    (customer: Object): string => {
        if (!customer) {
            return '';
        }

        const {
            address_line_1: addressLine1,
            address_line_2: addressLine2,
        } = customer;

        return [addressLine1, addressLine2].join(' ').trim();
    }
);

export const getInitialValues = createSelector(
    getCustomer,
    (state: RootState): string => state.profilePage.account.addressLine1,
    (state: RootState): string => state.profilePage.account.addressLine2,
    (state: RootState): string => state.profilePage.account.city,
    (state: RootState): string => state.profilePage.account.state,
    (state: RootState): string => state.profilePage.account.zipCode,
    (customer: Object, addressLine1: string, addressLine2: string, city: string, state: string, zipCode: string) => {
        if (!customer) {
            return {
                addressLine1: '',
                addressLine2: '',
                city: '',
                confirmNewPassword: '',
                currentPassword: '',
                email: '',
                firstName: '',
                id: null,
                lastName: '',
                newPassword: '',
                phoneNumber: '',
                state: '',
                zipCode: '',
            };
        }

        const {
            id,
            email,
            first_name: firstName,
            last_name: lastName,
            phonenumber: phoneNumber,
        } = customer;

        return {
            addressLine1,
            addressLine2,
            city,
            confirmNewPassword: '',
            currentPassword: '',
            email,
            firstName: firstName || '',
            id,
            lastName: lastName || '',
            newPassword: '',
            phoneNumber: phoneNumber || '',
            state,
            zipCode,
        };
    }
);

export const isReadOnly = createSelector(
    getUser,
    getCustomer,
    (user: ?Object, customer: Object) => (
        user ? user.id !== customer.id && user.role !== USER_ROLES.PLATFORM_ADMIN : true
    )
);

export default {
    getCustomerAddress,
    getInitialValues,
    isReadOnly,
};
