// @flow
// $FlowIssue need to update to a more recent flow version
import React, { Component, Fragment, useCallback, useState } from 'react';
import moment from 'moment';
import numeral from 'numeral';
import qs from 'qs';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import { Checkbox, Popover, Tooltip } from '@material-ui/core';
import { Check as CheckIcon } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import type { Dispatch } from 'redux';
import type { Connector } from 'react-redux';
import type { TFunction } from 'react-i18next';
import type { ContextRouter } from 'react-router';
import { USER_ROLES } from '../../../../../browser/shared/constant/UserRoles';
import getDisplayName from '../../../../util/getDisplayName';
import DataTable from '../../../../components/DataTable';
import Tag from '../../../../components/Tag';
import * as dialog from '../../../../ducks/dialog';
import { actions, selectors } from '../../duck';
import styles from './styles';
import type { State as RootState } from '../../../../redux/initialState';

type OwnProps = ContextRouter & {
    classes: Object,
    t: TFunction,
};
type StateProps = {
    config: Object,
    data: Object[],
    fetching: boolean,
    filters?: Object[],
    keywords?: string,
    limit?: number,
    offset?: number,
    pages: number,
    recordCount: number,
    search: {
        size?: string,
        page?: string,
        sort?: string,
        order?: string,
    },
    selected: number[],
    settings: {
        columns: string[],
    },
    sort?: Object[],
    user: ?Object,
};
type DispatchProps = {
    closeDialog: () => void,
    deselect: (id: number) => void,
    deselectAll: () => void,
    openDialog: (name: string, props: Object) => void,
    refresh: () => Promise<void>,
    select: (id: number) => void,
    selectAll: () => void,
};
type Props = OwnProps & StateProps & DispatchProps;

const stopPropagation = (event: SyntheticEvent<any>) => {
    event.stopPropagation();
    event.preventDefault();
};

function TagCell(props: Object) {
    const { columnProps, value } = props;
    const { classes, location, history, search } = columnProps.rest;

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = useCallback((event: SyntheticEvent<any>) => {
        const { name } = event.currentTarget.dataset;

        history.replace({
            pathname: location.pathname,
            search: qs.stringify(
                { ...search, tag: [name] },
                { addQueryPrefix: true, encodeValuesOnly: true },
            ),
        });
    }, [location, history, search]);

    const handlePopoverOpen = useCallback((event: SyntheticEvent<any>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handlePopoverClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const tagList = value.map(([id, name]: [number, string]) => (
        <Tag
          data-name={name}
          id={id}
          key={id}
          label={name}
          onClick={handleClick}
        />
    ));

    return (
        <div className={classes.tagListPreview} onClick={stopPropagation}>
            {tagList.slice(0, 3)}
            {tagList.length > 3
                ? (
                    <div>
                        <Tooltip title="View all tags">
                            <Tag
                              color="#f0f3f6"
                              label={`+${tagList.length - 3}`}
                              onClick={handlePopoverOpen}
                            />
                        </Tooltip>
                        <Popover
                          open={!!anchorEl}
                          anchorEl={anchorEl}
                          onClose={handlePopoverClose}
                          anchorOrigin={{
                              vertical: 'center',
                              horizontal: 'center',
                          }}
                          transformOrigin={{
                              vertical: 'center',
                              horizontal: 'center',
                          }}
                        >
                            <div className={classes.tagList}>
                                {tagList}
                            </div>
                        </Popover>
                    </div>
                )
                : null}
        </div>
    );
}

export class ListView extends Component<Props> {
    handlePageChange = (page: number) => {
        const { location, history, search } = this.props;
        const query = page >= 0 ? { page: page + 1 } : {}; // Don't forget to convert from zero-based index!

        history.replace({
            pathname: location.pathname,
            search: qs.stringify(
                { ...search, ...query },
                { addQueryPrefix: true, encodeValuesOnly: true }
            ),
        });
    };

    handlePageSizeChange = (pageSize: number) => {
        const { location, history, search } = this.props;
        const { size, ...query } = search;

        history.replace({
            pathname: location.pathname,
            search: qs.stringify(
                pageSize ? { ...query, size: pageSize } : query,
                { addQueryPrefix: true, encodeValuesOnly: true }
            ),
        });
    };

    handleSortedChange = (sorted: Object[]) => {
        const { location, history, search } = this.props;
        const { sort, order, ...query } = search;
        const { id, desc } = sorted.length > 0 ? sorted[0] : {};

        history.replace({
            pathname: location.pathname,
            search: qs.stringify(
                id ? { ...query, sort: id, order: desc ? 'desc' : 'asc' } : query,
                { addQueryPrefix: true, encodeValuesOnly: true }
            ),
        });
    };

    handleSelect = (item: Object) => {
        const { deselect, select, selected } = this.props;
        const { id } = item;

        if (selected.includes(id)) {
            deselect(id);
        } else {
            select(id);
        }
    };

    handleSelectAll = () => {
        const { data, deselectAll, selectAll, selected } = this.props;
        const selectedOnPage = data.reduce((acc: number, { id }: Object) => (
            selected.includes(id) ? acc + 1 : acc
        ), 0);

        if (selectedOnPage > 0) {
            deselectAll();
        } else {
            selectAll();
        }
    };

    handleActionClick = (event: SyntheticMouseEvent<any>) => {
        stopPropagation(event);

        const { closeDialog, openDialog, refresh } = this.props;
        const { action, ticket: ticketId } = event.currentTarget.dataset;
        const dialogProps = {
            onClose: () => closeDialog(),
            onSubmitSuccess: () => {
                closeDialog();
                refresh();
            },
            ticketId,
        };

        openDialog(action, dialogProps);
    };

    _getColumns() {
        const { config, data, classes, history, location, search, selected, settings, t, user } = this.props;

        const select = {
            Cell: (props: Object) => (
                <Checkbox checked={selected.includes(props.value)} />
            ),
            Header: () => {
                const selectedOnPage = data.reduce((acc: number, { id }: Object) => (
                    selected.includes(id) ? acc + 1 : acc
                ), 0);

                return (
                    <Checkbox
                      checked={data.length !== 0 && selectedOnPage === data.length}
                      disabled={data.length === 0}
                      indeterminate={selectedOnPage > 0 && selectedOnPage < data.length}
                      onChange={this.handleSelectAll}
                    />
                );
            },
            accessor: 'id',
            className: classes.select,
            sortable: false,
            width: 48,
            padding: 'checkbox',
        };
        const status = {
            Cell: (props: Object) => t(`ticketList.statusEnum.${props.value}`),
            Header: t('ticketList.status'),
            accessor: 'status',
            className: classes.status,
            minWidth: 120,
        };
        const approvalStatus = {
            Cell: (props: Object) => t(`ticketList.approvalStatusEnum.${props.value}`),
            Header: t('ticketList.approvalStatus'),
            accessor: 'approvalStatus',
            className: classes.approvalStatus,
            minWidth: 120,
        };
        const gigTitle = {
            Cell: (props: Object) => {
                const { id, organizationId } = props.original;
                const to = {
                    pathname: `/tickets/${organizationId}/info/${id}`,
                    state: {
                        breadcrumb: `${location.pathname}${location.search || ''}`,
                    },
                };
                return <Link to={to}>{props.value}</Link>;
            },
            Header: t('ticketList.title'),
            accessor: 'title',
            className: classes.title,
            minWidth: 150,
        };
        const gigLocation = {
            Cell: (props: Object) => {
                const { title: locationTitle, formatted_address: locationAddress } = props.value;
                return (
                    <Fragment>
                        <div className={classes.locationTitle}>{locationTitle}</div>
                        {locationTitle !== locationAddress
                            ? <div className={classes.locationAddress}>{locationAddress}</div>
                            : null}
                    </Fragment>
                );
            },
            Header: t('ticketList.location'),
            accessor: 'location',
            className: classes.location,
            minWidth: 200,
        };
        const assignee = {
            Cell: (props: Object) => {
                const customer = props.value;
                const { applications, id, organizationId } = props.original;

                if (!customer) {
                    let applicantsInfo = null;

                    if (applications) {
                        applicantsInfo = applications.length === 0
                            ? <span>{t('ticketList.noApplicants')}</span>
                            : (
                                <a
                                  className={classes.link}
                                  data-action="assignApplicant"
                                  data-ticket={id}
                                  onClick={this.handleActionClick}
                                >
                                    {t('ticketList.workersApplied', { count: applications.length })}
                                </a>
                            );

                        return (
                            <Fragment>
                                <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>{t('ticketList.unassigned')}</span>
                                {applicantsInfo}
                            </Fragment>
                        );
                    }

                    return null;
                }

                const { needsPublicWorkforce } = props.original;
                const hideDetails = !user || (user.role !== USER_ROLES.PLATFORM_ADMIN && user.id !== customer.id && needsPublicWorkforce);
                const assigneeName = getDisplayName(customer, { obfuscate: hideDetails });

                return (
                    hideDetails
                        ? <span>{assigneeName}</span>
                        : <Link to={`/member/${organizationId}/${customer.id}/account`}>{assigneeName}</Link>
                );
            },
            Header: t('ticketList.assignee'),
            accessor: 'assignee',
            className: classes.assignee,
            minWidth: 120,
        };
        const scheduledDate = {
            Cell: (props: Object) => {
                const date = moment(props.value);
                if (!date.isValid()) {
                    return null;
                }
                return (
                    <Fragment>
                        <div>{date.format('MMM DD YYYY')}</div>
                        <div>{date.format('hh:mm A')}</div>
                    </Fragment>
                );
            },
            Header: t('ticketList.scheduledDate'),
            accessor: 'scheduledDate',
            className: classes.datetime,
        };
        const startDate = {
            Cell: (props: Object) => {
                const date = moment(props.value);
                if (!date.isValid()) {
                    return null;
                }
                return (
                    <Fragment>
                        <div>{date.format('MMM DD YYYY')}</div>
                        <div>{date.format('hh:mm A')}</div>
                    </Fragment>
                );
            },
            Header: t('ticketList.startDate'),
            accessor: 'startDate',
            className: classes.datetime,
        };
        const dueDate = {
            Cell: (props: Object) => {
                const date = moment(props.value);
                if (!date.isValid()) {
                    return null;
                }
                return (
                    <Fragment>
                        <div>{date.format('MMM DD YYYY')}</div>
                        <div>{date.format('hh:mm A')}</div>
                    </Fragment>
                );
            },
            Header: t('ticketList.endDate'),
            accessor: 'dueDate',
            className: classes.datetime,
        };
        const submittedDate = {
            Cell: (props: Object) => {
                const { needsPublicWorkforce } = props.original;
                const date = moment(props.value);
                const autoPayDate = moment(date).add(config.CORE_EXPIRY_GRACE_SECONDS, 'seconds');
                const warning = needsPublicWorkforce && autoPayDate.isBetween(moment(), moment().add(3, 'days'))
                    ? t('ticketList.autoPayWarning', { date: autoPayDate.format('M/D/YYYY') })
                    : null;

                if (!date.isValid()) {
                    return null;
                }

                return (
                    <Fragment>
                        <div>{date.format('MMM DD YYYY')}</div>
                        <div>{date.format('hh:mm A')}</div>
                        {warning ? <div className={classes.autoPayWarning}>{warning}</div> : null}
                    </Fragment>
                );
            },
            Header: t('ticketList.submittedDate'),
            accessor: 'submittedDate',
            className: classes.datetime,
        };
        const tags = {
            Cell: TagCell,
            Header: t('ticketList.tags'),
            accessor: 'tags',
            className: classes.tags,
            getProps: () => ({ classes, history, location, search }),
            minWidth: 125,
            sortable: false,
        };
        const gigPrice = {
            Cell: (props: Object) => (
                props.value != null
                    ? numeral(props.value).format('$0,0.00')
                    : <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>{t('ticketList.notApplicable')}</span>
            ),
            Header: t('ticketList.price'),
            accessor: 'price',
            className: classes.price,
        };
        const pausedStatus = {
            Cell: (props: Object) => (props.value ? <CheckIcon color="primary" /> : null),
            Header: t('ticketList.paused'),
            accessor: 'paused',
            className: classes.pausedStatus,
            sortable: false,
            minWidth: 100,
        };

        const columns = user && user.role === USER_ROLES.WORKER
            ? [select, status, gigTitle, gigLocation, scheduledDate, startDate, dueDate]
            : [select, status, approvalStatus, gigTitle, gigLocation, gigPrice, assignee, startDate, dueDate, submittedDate, tags, pausedStatus];

        return columns.filter((column: Object, index: number) => (
            index === 0 || settings.columns.includes(column.accessor)
        ));
    }

    render() {
        const { data, fetching, pages, recordCount, search, selected } = this.props;
        const { order, sort } = search;
        const page = Math.max(parseInt(search.page, 10) - 1, 0) || 0;
        const pageSize = Math.max(parseInt(search.size, 10), 0) || 10;

        const columns = this._getColumns();
        const sorted = [{
            id: sort || 'title',
            desc: order === 'desc',
        }];

        return (
            <DataTable
              sortable
              columns={columns}
              count={recordCount}
              data={data}
              loading={fetching}
              onPageChange={this.handlePageChange}
              onPageSizeChange={this.handlePageSizeChange}
              onSelect={this.handleSelect}
              onSortedChange={this.handleSortedChange}
              pageSizeOptions={[10, 20, 50]}
              defaultPageSize={10}
              page={page}
              pages={pages}
              pageSize={pageSize}
              selected={selected.map((id: number) => ({ id }))}
              sorted={sorted}
            />
        );
    }
}

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => ({
    ...selectors.getAPIParams(state, props),
    config: state.config,
    data: selectors.getTableData(state, props),
    fetching: state.ticketList.fetching,
    pages: state.ticketList.metadata.page_count || 0,
    recordCount: state.ticketList.metadata.record_count || 0,
    search: selectors.parseSearchParams(state, props),
    selected: state.ticketList.selected,
    settings: state.ticketList.settings,
    user: state.session.user,
});

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    closeDialog: () => dispatch(dialog.actions.close()),
    deselect: (id: number) => dispatch(actions.deselect(id)),
    deselectAll: () => dispatch(actions.deselectAll()),
    openDialog: (name: string, props: Object) => dispatch(dialog.actions.open(name, props)),
    refresh: () => dispatch(actions.refresh()),
    select: (id: number) => dispatch(actions.select(id)),
    selectAll: () => dispatch(actions.selectAll()),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
const enhance = compose(
    withStyles(styles, { name: 'ListView' }),
    withTranslation(),
    connector,
);

export default enhance(ListView);
