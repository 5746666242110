// @flow
import Tree from './Tree';

const OPERATORS = {
    EQ: '$eq',
    NE: '$ne',
    GT: '$gt',
    GTE: '$gte',
    LT: '$lt',
    LTE: '$lte',
    OR: '$or',
    AND: '$and',
};

const coerceType = (value: any, type: string) => {
    switch (type) {
        case 'string':
            return String(value);

        case 'number':
            return Number(value);

        default:
            return null;
    }
};

export default class CondExpression extends Tree {
    // $FlowFixMe how do you define Tree such that subclass typing works?
    children: CondExpression[] = [];

    constructor(expression: Object) {
        super({ operator: null, expected_value: null });
        this.parse(expression);
    }

    parse(expression: Object = {}) {
        const keys = Object.keys(expression);

        if (keys.length !== 1) {
            throw new Error('There can only be one element in a dictionary expression');
        }

        const type = keys[0];
        const operands = expression[type];

        switch (type) {
            case OPERATORS.EQ:
            case OPERATORS.NE:
                if (operands.length !== 2) {
                    throw new Error(`A ${type} expression must have exactly two elements`);
                }

                if (operands[0] !== '$data_item_value') {
                    throw new Error(`First element of a ${type} expression must be "$data_item_value"`);
                }

                // TODO: Check if second operand is number, string, or boolean?

                this.value.operator = type;
                this.value.expected_value = operands[1]; // eslint-disable-line prefer-destructuring
                break;

            case OPERATORS.GT:
            case OPERATORS.GTE:
            case OPERATORS.LT:
            case OPERATORS.LTE: {
                if (operands.length !== 2) {
                    throw new Error(`A ${type} expression must have exactly two elements`);
                }

                if (operands[0] !== '$data_item_value') {
                    throw new Error(`First element of a ${type} expression must be "$data_item_value"`);
                }

                const expectedValue = parseFloat(operands[1]);

                if (Number.isNaN(expectedValue) || expectedValue !== Number(operands[1])) {
                    throw new Error(`A ${type} expression is valid only for numeric values`);
                }

                this.value.operator = type;
                this.value.expected_value = expectedValue;
                break;
            }

            case OPERATORS.OR:
            case OPERATORS.AND:
                if (operands.length < 1) {
                    throw new Error(`A ${type} expression must have at least one element`);
                }

                this.value.operator = type;
                operands.forEach((operand: Object) => {
                    this.addChild(new CondExpression(operand));
                });

                break;

            default:
                throw new Error(`Unrecognized operator: ${type}`);
        }
    }

    evaluate(input: any[]) {
        let result;
        let i;

        switch (this.value.operator) {
            default:
            case OPERATORS.EQ:
                return input.some((v) => {
                    const value = coerceType(v, typeof this.value.expected_value);
                    return value === this.value.expected_value;
                });

            case OPERATORS.NE:
                return input.some((v) => {
                    const value = coerceType(v, typeof this.value.expected_value);
                    return value !== this.value.expected_value;
                });

            case OPERATORS.GT:
                return input.some((v) => {
                    const value = coerceType(v, typeof this.value.expected_value);
                    return value > this.value.expected_value;
                });

            case OPERATORS.GTE:
                return input.some((v) => {
                    const value = coerceType(v, typeof this.value.expected_value);
                    return value >= this.value.expected_value;
                });

            case OPERATORS.LT:
                return input.some((v) => {
                    const value = coerceType(v, typeof this.value.expected_value);
                    return value < this.value.expected_value;
                });

            case OPERATORS.LTE:
                return input.some((v) => {
                    const value = coerceType(v, typeof this.value.expected_value);
                    return value <= this.value.expected_value;
                });

            case OPERATORS.OR:
                return input.some((v) => {
                    result = false;
                    for (i = 0; i < this.children.length; i += 1) {
                        result = result || this.children[i].evaluate([v]);
                        if (result) {
                            return result;
                        }
                    }
                    return result;
                });

            case OPERATORS.AND:
                return input.every((v) => {
                    result = true;
                    for (i = 0; i < this.children.length; i += 1) {
                        result = result && this.children[i].evaluate([v]);
                        if (!result) {
                            return result;
                        }
                    }
                    return result;
                });
        }
    }

    toJSON() {
        let json = {};

        switch (this.value.operator) {
            case OPERATORS.EQ:
            case OPERATORS.NE:
                json = { [this.value.operator]: ['$data_item_value', this.value.expected_value] };
                break;

            case OPERATORS.GT:
            case OPERATORS.GTE:
            case OPERATORS.LT:
            case OPERATORS.LTE:
                // TODO: Validation check? Expected value should be a number
                json = { [this.value.operator]: ['$data_item_value', parseFloat(this.value.expected_value)] };
                break;

            case OPERATORS.OR:
            case OPERATORS.AND:
                json = { [this.value.operator]: [] };
                this.children.forEach((child) => {
                    json[this.value.operator].push(child.toJSON());
                });
                break;

            default:
                break;
        }

        return json;
    }

    humanize() {
        let result = '';

        // @todo Support multiple languages by accepting a custom dict as a param?
        const dict = {
            [OPERATORS.EQ]: 'equal to',
            [OPERATORS.NE]: 'not equal to',
            [OPERATORS.GT]: 'greater than',
            [OPERATORS.GTE]: 'greater than or equal to',
            [OPERATORS.LT]: 'less than',
            [OPERATORS.LTE]: 'less than or equal to',
            [OPERATORS.OR]: 'OR',
            [OPERATORS.AND]: 'AND',
        };

        switch (this.value.operator) {
            case OPERATORS.EQ:
            case OPERATORS.NE:
                result = `${dict[this.value.operator]} ${this.value.expected_value}`;
                break;

            case OPERATORS.GT:
            case OPERATORS.GTE:
            case OPERATORS.LT:
            case OPERATORS.LTE:
                // TODO: Validation check? Expected value should be a number
                result = `${dict[this.value.operator]} ${parseFloat(this.value.expected_value)}`;
                break;

            case OPERATORS.OR:
            case OPERATORS.AND:
                result = this.children
                    .map((child) => child.humanize())
                    .join(` ${dict[this.value.operator]} `);
                break;

            default:
                break;
        }

        return result;
    }
}
