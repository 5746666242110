// @flow
import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import { Trans, withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import {
    IconButton,
    Toolbar,
} from '@material-ui/core';
import { Refresh as RefreshIcon } from '@material-ui/icons';
import { compose } from 'recompose';
import type { Dispatch } from 'redux';
import type { Connector } from 'react-redux';
import type { TFunction } from 'react-i18next';
import type { ContextRouter } from 'react-router';
import { actions, selectors } from './duck';
import styles from './styles';
import type { State as RootState } from '../../../../redux/initialState';

type OwnProps = ContextRouter & {
    classes: Object,
    t: TFunction,
};
type StateProps = {
    recordCount: number,
    search: {
        size?: string,
        page?: string,
    },
};
type DispatchProps = {
    refresh: () => Promise<void>,
};
type Props = OwnProps & StateProps & DispatchProps;

export class ActionToolbar extends Component<Props> {
    constructor(props: Props) {
        super(props);
        this.handleRefresh = debounce(this.handleRefresh, 500);
    }

    handleRefresh = () => {
        const { refresh } = this.props;
        refresh();
    };

    renderRecordCount = () => {
        const { classes, recordCount, search, t } = this.props;
        const page = Math.max(parseInt(search.page, 10) - 1, 0) || 0;
        const pageSize = Math.max(parseInt(search.size, 10), 0) || 10;

        const translateOptions = {
            count: recordCount,
            from: recordCount === 0 ? 0 : (page * pageSize) + 1,
            to: Math.min(recordCount, (page + 1) * pageSize),
        };

        return (
            <div className={classes.recordCount}>
                <Trans
                  parent="span"
                  defaults={t('payoutList.toolbar.recordCount', translateOptions)}
                  components={[
                      <strong style={{ fontWeight: 600 }}>from</strong>, '-',
                      <strong style={{ fontWeight: 600 }}>to</strong>, 'of',
                      <strong style={{ fontWeight: 600 }}>count</strong>, 'payouts',
                  ]}
                />
                <IconButton onClick={this.handleRefresh} size="small">
                    <RefreshIcon fontSize="small" />
                </IconButton>
            </div>
        );
    };

    render() {
        const { classes } = this.props;
        return (
            <Toolbar className={classes.root}>
                {this.renderRecordCount()}
            </Toolbar>
        );
    }
}

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => ({
    recordCount: state.payoutList.metadata.record_count || 0,
    search: selectors.parseSearchParams(state, props),
});
const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    refresh: () => dispatch(actions.refresh()),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
const enhance = compose(
    withStyles(styles, { name: 'ActionToolbar' }),
    withTranslation(),
    connector,
);

export default enhance(ActionToolbar);
