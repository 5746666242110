// @flow
import React, { Component, Fragment } from 'react';
import numeral from 'numeral';
import qs from 'qs';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import { Route } from 'react-router';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import { Paper } from '@material-ui/core';
import type { Dispatch } from 'redux';
import type { $AxiosError } from 'axios';
import type { Connector } from 'react-redux';
import type { TFunction } from 'react-i18next';
import type { ContextRouter } from 'react-router';
import { USER_ROLES } from '../../../browser/shared/constant/UserRoles';
import { format } from '../../../browser/shared/util/gigwalkApiErrorUtil';
import FilterDrawer from './containers/FilterDrawer';
import SearchToolbar from './containers/SearchToolbar';
import ActionToolbar from './containers/ActionToolbar';
import LoadingPage from '../../components/LoadingPage';
import SimplePane from '../../components/SimplePane';
import DataTable from '../../components/DataTable';
import * as snackbar from '../../ducks/snackbar';
import { actions, selectors } from './duck';
import styles from './styles';
import type { State as RootState } from '../../redux/initialState';

type State = {
    loading: boolean,
};

type OwnProps = ContextRouter & {
    classes: Object,
    t: TFunction,
};

type StateProps = {
    data: Object[],
    fetching: boolean,
    filters: {
        audit_types: string[],
        charge_token: ?string,
        customer_ids: ?number[],
        organization_id: ?number,
    },
    limit: number,
    offset: number,
    pages: number,
    recordCount: number,
    search: {
        order?: string,
        page?: string,
        q?: string,
        size?: string,
        sort?: string,
    },
    sort: Object[],
    user: ?Object,
};

type DispatchProps = {
    enqueueSnackbar: (message: string, options: Object) => void,
    fetchData: (filters: ?Object, sort: ?Object[], limit: ?number, offset: ?number) => Promise<void>,
    loadFilters: (search: Object) => Promise<void>,
};

type Props = OwnProps & StateProps & DispatchProps;

export class TransactionList extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        // this._fetchData = debounce(this._fetchData, 500);
        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
        const { filters, limit, loadFilters, offset, search, sort } = this.props;
        const promises = [
            this._fetchData(filters, sort, limit, offset),
            loadFilters(search),
        ];

        Promise.all(promises)
            .then(() => { this.setState({ loading: false }); });
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        const { filters, limit, offset, sort } = this.props;
        const {
            filters: nextFilters,
            limit: nextLimit,
            offset: nextOffset,
            sort: nextSort,
        } = nextProps;

        // If any filter, sort, or pagination parameters changed, we should fetch new data.
        const shouldFetchData = !isEqual(nextFilters, filters)
            || !isEqual(nextSort, sort)
            || nextLimit !== limit
            || nextOffset !== offset;

        if (shouldFetchData) {
            this._fetchData(nextFilters, nextSort, nextLimit, nextOffset);
        }
    }

    handlePageChange = (page: number) => {
        const { location, history, search } = this.props;
        const query = page >= 0 ? { page: page + 1 } : {}; // Don't forget to convert from zero-based index!

        history.replace({
            pathname: location.pathname,
            search: qs.stringify(
                { ...search, ...query },
                { addQueryPrefix: true, encodeValuesOnly: true }
            ),
        });
    };

    handlePageSizeChange = (pageSize: number) => {
        const { location, history, search } = this.props;
        const { size, ...query } = search;

        history.replace({
            pathname: location.pathname,
            search: qs.stringify(
                pageSize ? { ...query, size: pageSize } : query,
                { addQueryPrefix: true, encodeValuesOnly: true }
            ),
        });
    };

    handleSortedChange = (sorted: Object[]) => {
        const { location, history, search } = this.props;
        const { sort, order, ...query } = search;
        const { id, desc } = sorted.length > 0 ? sorted[0] : {};

        history.replace({
            pathname: location.pathname,
            search: qs.stringify(
                id ? { ...query, sort: id, order: desc ? 'desc' : 'asc' } : query,
                { addQueryPrefix: true, encodeValuesOnly: true }
            ),
        });
    };

    _fetchData = (filters: ?Object, sort: ?Object[], limit: ?number, offset: ?number) => {
        const { enqueueSnackbar, fetchData } = this.props;
        return fetchData(filters, sort, limit, offset)
            .catch((err: $AxiosError<any>) => {
                const resp = err ? err.response : null;
                if (resp && resp.data && resp.data.gw_api_response) {
                    const message = format(resp.data.gw_api_response);
                    enqueueSnackbar(message, { variant: 'error' });
                }
            });
    };

    _getColumns() {
        const { classes, t, user } = this.props;

        const type = {
            sortable: false,
            Header: t('transactionList.type'),
            accessor: 'type',
            className: classes.type,
            Cell: (props: Object) => t(`transactionList.typeEnum.${props.value}`),
        };
        const id = {
            sortable: false,
            Header: t('transactionList.id'),
            accessor: 'id',
            minWidth: 120,
        };
        const referenceId = {
            sortable: false,
            Header: t('transactionList.referenceId'),
            accessor: 'referenceId',
            minWidth: 120,
        };
        const organizationId = {
            sortable: false,
            Header: t('transactionList.organization'),
            accessor: 'organizationId',
            Cell: (props: Object) => {
                const orgId = user ? user.organization.id : -1;
                return <Link to={`/admin/${orgId}/organizations/edit/${props.value}`}>{props.value}</Link>;
            },
        };
        const customerId = {
            sortable: false,
            Header: t('transactionList.customer'),
            accessor: 'customerId',
        };
        const subscriptionId = {
            sortable: false,
            Header: t('transactionList.subscription'),
            accessor: 'subscriptionId',
            Cell: (props: Object) => {
                const { organizationId: orgId } = props.original;
                return <Link to={`/projects/${orgId}/active/${props.value}`}>{props.value}</Link>;
            },
        };
        const date = {
            Header: t('transactionList.date'),
            accessor: 'date',
            className: classes.datetime,
            minWidth: 120,
            Cell: (props: Object) => {
                const transactionDate = moment(props.value);
                if (!transactionDate.isValid()) {
                    return null;
                }
                return (
                    <Fragment>
                        <div>{transactionDate.format('MMM DD YYYY')}</div>
                        <div>{transactionDate.format('hh:mm A')}</div>
                    </Fragment>
                );
            },
        };
        const amount = {
            sortable: false,
            Header: t('transactionList.amount'),
            accessor: 'amount',
            Cell: (props: Object) => {
                const style = {
                    color: props.value < 0 ? '#cd1e3d' : '#00c7ae',
                    fontWeight: 600,
                };
                return <span style={style}>{numeral(props.value).format('$0,0.00')}</span>;
            },
        };
        const balance = {
            sortable: false,
            Header: t('transactionList.balance'),
            accessor: 'balance',
            Cell: (props: Object) => numeral(props.value).format('$0,0.00'),
        };

        return user && user.role === USER_ROLES.PLATFORM_ADMIN
            ? [type, id, referenceId, organizationId, customerId, subscriptionId, date, amount, balance]
            : [type, id, referenceId, customerId, subscriptionId, date, amount, balance];
    }

    render() {
        const { loading } = this.state;
        const { data, fetching, pages, recordCount, search } = this.props;
        const { order, sort } = search;
        const page = Math.max(parseInt(search.page, 10) - 1, 0) || 0;
        const pageSize = Math.max(parseInt(search.size, 10), 0) || 10;

        const columns = this._getColumns();
        const sorted = [{
            id: sort || 'date',
            desc: order ? order === 'desc' : true,
        }];

        if (loading) {
            return (
                <SimplePane>
                    <LoadingPage />
                </SimplePane>
            );
        }

        return (
            <SimplePane>
                <Route component={SearchToolbar} />
                <Route component={FilterDrawer} />
                <Paper elevation={2}>
                    <Route component={ActionToolbar} />
                    <DataTable
                      columns={columns}
                      count={recordCount}
                      data={data}
                      loading={fetching}
                      onPageChange={this.handlePageChange}
                      onPageSizeChange={this.handlePageSizeChange}
                      onSortedChange={this.handleSortedChange}
                      pageSizeOptions={[10, 20, 50]}
                      defaultPageSize={10}
                      page={page}
                      pages={pages}
                      pageSize={pageSize}
                      sorted={sorted}
                    />
                </Paper>
            </SimplePane>
        );
    }
}

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => {
    const {
        limit,
        offset,
        sort_field: sortField,
        sort_order: sortOrder,
        ...filters
    } = selectors.getAPIParams(state, props);

    return {
        data: selectors.getTableData(state, props),
        fetching: state.transactionList.fetching,
        filters,
        limit,
        offset,
        pages: state.transactionList.metadata.page_count || 0,
        recordCount: state.transactionList.metadata.record_count,
        search: selectors.parseSearchParams(state, props),
        sort: [{ [sortField]: sortOrder }],
        user: state.session.user,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    enqueueSnackbar: (message: string, options: Object) => dispatch(snackbar.actions.enqueue(message, options)),
    fetchData: (filters: ?Object, sort: ?Object[], limit: ?number, offset: ?number) => (
        dispatch(actions.fetchData(filters, sort, limit, offset))
    ),
    loadFilters: (search: Object) => dispatch(actions.loadFilters(search)),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
const enhance = compose(
    withStyles(styles, { name: 'TransactionList' }),
    withTranslation(),
    connector
);

export default enhance(TransactionList);
