// @flow
export default (theme: Object): Object => ({
    root: {
        marginBottom: theme.spacing(2),
    },
    label: {
        fontWeight: 600,
        marginBottom: theme.spacing(1),
    },
    group: {
        width: '100%',
        '&:not(:last-child)': {
            marginBottom: theme.spacing(1),
        },
    },
    chip: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        background: 'white',
        height: '60px',
        border: [['1px', 'solid', theme.palette.grey[100]]],
        borderRadius: '30px',
        boxShadow: theme.shadows[1],
    },
    chipAvatar: {
        position: 'absolute',
        left: 0,
        background: 'transparent',
        color: theme.palette.common.black,
        height: 60,
        width: 60,
        fontSize: 24,
        paddingLeft: 24,
        justifyContent: 'flex-start',
    },
    chipLabel: {
        fontSize: 18,
    },
    chipDeleteIcon: {
        position: 'absolute',
        right: 0,
    },
});
