// @flow

export default function fetchAsDataURL(url: string): Promise<string> {
    if (!global.hasOwnProperty('fetch') || !global.hasOwnProperty('FileReader')) {
        return Promise.reject();
    }

    // Append query string param to prevent cors issue in older versions of Chrome
    // See https://serverfault.com/questions/856904/chrome-s3-cloudfront-no-access-control-allow-origin-header-on-initial-xhr-req
    const _url = new URL(url);
    _url.searchParams.append('cachebuster', `${Math.random()}`);

    return fetch(new Request(_url.toString(), { mode: 'cors' }))
        .then((resp: Response) => resp.blob())
        .then((blob: Blob) => (
            new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.onload = (event) => resolve(event.target.result);
                fileReader.onerror = (err) => reject(err);
                fileReader.readAsDataURL(blob);
            })
        ));
}
