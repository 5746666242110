// @flow
export const SUBMIT = 'g/preferences/SUBMIT';
export const SUBMIT_SUCCESS = `${SUBMIT}_SUCCESS`;
export const SUBMIT_ERROR = `${SUBMIT}_ERROR`;

export const FETCH_ALL_SUBSCRIPTIONS = 'g/preferences/FETCH_ALL_SUBSCRIPTIONS';
export const FETCH_ALL_SUBSCRIPTIONS_SUCCESS = `${FETCH_ALL_SUBSCRIPTIONS}_SUCCESS`;
export const FETCH_ALL_SUBSCRIPTIONS_ERROR = `${FETCH_ALL_SUBSCRIPTIONS}_ERROR`;

export const UNSUBSCRIBE = 'g/preferences/UNSUBSCRIBE';
export const UNSUBSCRIBE_SUCCESS = `${UNSUBSCRIBE}_SUCCESS`;
export const UNSUBSCRIBE_ERROR = `${UNSUBSCRIBE}_ERROR`;

export default {
    FETCH_ALL_SUBSCRIPTIONS,
    FETCH_ALL_SUBSCRIPTIONS_ERROR,
    FETCH_ALL_SUBSCRIPTIONS_SUCCESS,
    SUBMIT,
    SUBMIT_ERROR,
    SUBMIT_SUCCESS,
    UNSUBSCRIBE,
    UNSUBSCRIBE_ERROR,
    UNSUBSCRIBE_SUCCESS,
};
