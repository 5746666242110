// @flow
export default (theme: Object) => ({
    container: {
        display: 'flex',
        alignItems: 'center',

        '& > *': {
            margin: theme.spacing(0, 0.5),

            '&:first-child': { marginLeft: 0 },
            '&:last-child': { marginRight: 0 },
        },
    },
    readOnly: {
        color: theme.palette.text.secondary,
    },
    icon: {
        color: 'rgba(0, 0, 0, 0.64)', // theme.palette.action.active,
        fontSize: 22,
    },
    label: {
        fontFamily: 'ProximaNova',
        fontSize: 16,
        fontWeight: 600,
        color: 'rgba(0, 0, 0, 0.64)', // theme.palette.text.secondary,

        '&::after': {
            content: '":"',
        },
    },
    input: {
        cursor: 'pointer',

        '&$disabled': {
            cursor: 'default',
            color: 'rgba(0, 0, 0, 0.48)',
            backgroundColor: '#e5eaed',
        },
    },
    inputElement: {
        minWidth: 120,
        textAlign: 'center',
        color: theme.palette.grey[500],
        cursor: 'inherit',

        '&$assigned': {
            color: theme.palette.primary.main,
        },
    },
    clearButton: {
        padding: 6,
        fontSize: 18,
    },
    assigned: {},
    disabled: {},
});
