// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback } from 'react';
import { Form } from 'formik';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import {
    Assignment as AssignmentIcon,
    People as PeopleIcon,
    VerifiedUser as VerifiedUserIcon,
} from '@material-ui/icons';
import { BriefcaseCheck as BriefcaseCheckIcon } from 'mdi-material-ui';
import type { Connector } from 'react-redux';
import type { ContextRouter } from 'react-router';
import { OPTIN_TYPES } from '../../../../../browser/shared/constant/ProjectConstant';
import ToggleButton from '../../../../components/ToggleButton';
import entitySelector from '../../../../redux/entities/entitySelector';
import Step from '../../components/Step';
import styles from './styles';
import type { State as RootState } from '../../../../redux/initialState';

type OwnProps = ContextRouter & {
    setFieldValue: (field: string, value: any) => void,
    values: Object,
};
type StateProps = {
    editMode: boolean,
    user: ?Object,
};
type Props = OwnProps & StateProps;

const useStyles = makeStyles(styles, { name: 'WorkAllocation' });

export function WorkAllocation(props: Props) {
    const { editMode, setFieldValue, user, values } = props;
    const { needsPublicWorkforce } = values;
    const orgAllowsAutoassign = user ? user.organization.config.autoassign.enabled : false;

    const classes = useStyles(props);
    const { t } = useTranslation();

    const handleChange = useCallback((event: SyntheticEvent<any>) => {
        const { name } = event.currentTarget;

        switch (name) {
            case 'doubleOptIn':
                setFieldValue('optinType', OPTIN_TYPES.DOUBLE_OPTIN);
                setFieldValue('autoassign', false);
                setFieldValue('fifoAssign', false);
                setFieldValue('resWindowHours', []);
                break;

            case 'systemApprovedOptIn':
                setFieldValue('optinType', OPTIN_TYPES.SYSTEM_APPROVED_OPTIN);
                setFieldValue('autoassign', false);
                break;

            case 'simpleOptIn':
                setFieldValue('optinType', OPTIN_TYPES.SIMPLE_OPTIN);
                setFieldValue('autoassign', false);
                setFieldValue('fifoAssign', false);
                setFieldValue('resWindowHours', []);
                break;

            case 'autoassign':
                setFieldValue('optinType', OPTIN_TYPES.NO_OPTIN);
                setFieldValue('autoassign', true);
                setFieldValue('fifoAssign', false);
                setFieldValue('resWindowHours', []);
                break;

            default:
                break;
        }
    }, [setFieldValue]);

    return (
        <Step
          title={t('projectBuilder.people.workAllocation.header')}
          subheader={t('projectBuilder.people.workAllocation.subheader')}
        >
            <Form className={classes.form}>
                <Typography className={classes.label} align="center">
                    {t('projectBuilder.people.workAllocation.selectOne')}
                </Typography>
                <div className={classes.grid}>
                    <ToggleButton
                      classes={{
                          root: classes.toggleButton,
                          label: classes.toggleButtonLabel,
                          selected: classes.selected,
                      }}
                      color="primary"
                      disabled={editMode}
                      name="doubleOptIn"
                      onChange={handleChange}
                      selected={values.optinType === OPTIN_TYPES.DOUBLE_OPTIN && values.autoassign === false}
                    >
                        <AssignmentIcon className={classes.icon} />
                        <p className={classes.caption}>
                            {t('projectBuilder.people.workAllocation.doubleOptIn')}
                        </p>
                    </ToggleButton>
                    <ToggleButton
                      classes={{
                          root: classes.toggleButton,
                          label: classes.toggleButtonLabel,
                          selected: classes.selected,
                      }}
                      color="primary"
                      disabled={editMode}
                      name="systemApprovedOptIn"
                      onChange={handleChange}
                      selected={values.optinType === OPTIN_TYPES.SYSTEM_APPROVED_OPTIN && values.autoassign === false}
                    >
                        <VerifiedUserIcon className={classes.icon} />
                        <p className={classes.caption}>
                            {t('projectBuilder.people.workAllocation.systemApprovedOptIn')}
                        </p>
                    </ToggleButton>
                    {!needsPublicWorkforce
                        ? (
                            <ToggleButton
                              classes={{
                                  root: classes.toggleButton,
                                  label: classes.toggleButtonLabel,
                                  selected: classes.selected,
                              }}
                              color="primary"
                              disabled={editMode}
                              name="simpleOptIn"
                              onChange={handleChange}
                              selected={values.optinType === OPTIN_TYPES.SIMPLE_OPTIN && values.autoassign === false}
                            >
                                <PeopleIcon className={classes.icon} />
                                <p className={classes.caption}>
                                    {t('projectBuilder.people.workAllocation.simpleOptIn')}
                                </p>
                            </ToggleButton>
                        )
                        : <span style={{ display: 'none' }} />}
                    {!needsPublicWorkforce && orgAllowsAutoassign
                        ? (
                            <ToggleButton
                              classes={{
                                  root: classes.toggleButton,
                                  label: classes.toggleButtonLabel,
                                  selected: classes.selected,
                              }}
                              color="primary"
                              disabled={editMode}
                              name="autoassign"
                              onChange={handleChange}
                              selected={values.optinType === OPTIN_TYPES.NO_OPTIN && values.autoassign === true}
                            >
                                <BriefcaseCheckIcon className={classes.icon} />
                                <p className={classes.caption}>
                                    {t('projectBuilder.people.workAllocation.autoassign')}
                                </p>
                            </ToggleButton>
                        )
                        : <span style={{ display: 'none' }} />}
                </div>
            </Form>
        </Step>
    );
}

const subscriptionSelector = entitySelector('subscriptions');
const mapStateToProps = (state: RootState, props: OwnProps): StateProps => {
    const { match } = props;
    return {
        editMode: subscriptionSelector(state, match.params.subscriptionId, 'state') === 'ACTIVE',
        user: state.session.user,
    };
};

const connector: Connector<OwnProps, Props> = connect(mapStateToProps);
export default withRouter(connector(WorkAllocation));
