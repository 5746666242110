// @flow
export default (theme: Object) => ({
    link: {
        cursor: 'pointer',
    },
    title: {
        wordBreak: 'break-word',
    },
    location: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    locationTitle: {
        // fontWeight: 600,
    },
    locationAddress: {
        color: 'rgba(0, 0, 0, 0.54)',
    },
    assignee: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    status: {
        fontStyle: 'italic',
    },
    approvalStatus: {
        fontStyle: 'italic',
    },
    pausedStatus: {
        // justifyContent: 'center',
    },
    datetime: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexShrink: 0,

        '& > :nth-child(2)': {
            color: 'rgba(0, 0, 0, 0.54)',
        },
    },
    autoPayWarning: {
        fontSize: 14,
        color: theme.palette.error.main,
    },
    tags: {
        display: 'flex',
        alignItems: 'center',
    },
    tagListPreview: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        margin: -3,

        '& > *': {
            margin: 3,
            maxWidth: '100%',
        },
    },
    tagList: {
        display: 'flex',
        flexWrap: 'wrap',
        maxWidth: 200,
        margin: -3,
        padding: 12,

        '& > *': {
            margin: 3,
            maxWidth: '100%',
        },
    },
});
