// @flow
export default (theme: Object) => ({
    MuiCssBaseline: {
        '@global': {
            html: {
                WebkitFontSmoothing: 'auto',
                MozOsxFontSmoothing: 'auto',
            },
        },
    },
    MuiTooltip: {
        popper: { width: 'max-content' },
        tooltip: {
            position: 'relative',
            borderColor: theme.palette.common.black,
            backgroundColor: theme.palette.common.black,
            padding: theme.spacing(1),
            fontSize: theme.typography.pxToRem(14),
            lineHeight: `${theme.typography.round(16 / 14)}em`,

            [theme.breakpoints.up('sm')]: {
                padding: theme.spacing(1),
                fontSize: theme.typography.pxToRem(14),
                lineHeight: `${theme.typography.round(16 / 14)}em`,
            },

            '&::before': {
                content: '""',
                position: 'absolute',
            },
        },
        tooltipPlacementLeft: {
            '&::before': {
                top: '50%',
                right: -7,
                marginTop: -7,
                borderTop: [[7, 'solid', 'transparent']],
                borderBottom: [[7, 'solid', 'transparent']],
                borderLeft: [[7, 'solid']],
                borderLeftColor: 'inherit',
            },
        },
        tooltipPlacementRight: {
            '&::before': {
                top: '50%',
                left: -7,
                marginTop: -7,
                borderTop: [[7, 'solid', 'transparent']],
                borderBottom: [[7, 'solid', 'transparent']],
                borderRight: [[7, 'solid']],
                borderRightColor: 'inherit',
            },
        },
        tooltipPlacementTop: {
            '&::before': {
                bottom: -7,
                left: '50%',
                marginLeft: -7,
                borderLeft: [[7, 'solid', 'transparent']],
                borderRight: [[7, 'solid', 'transparent']],
                borderTop: [[7, 'solid']],
                borderTopColor: 'inherit',
            },
        },
        tooltipPlacementBottom: {
            '&::before': {
                top: -7,
                left: '50%',
                marginLeft: -7,
                borderLeft: [[7, 'solid', 'transparent']],
                borderRight: [[7, 'solid', 'transparent']],
                borderBottom: [[7, 'solid']],
                borderBottomColor: 'inherit',
            },
        },
    },
    MuiCardHeader: {
        root: {
            width: '100%',
            '&:last-child': { paddingBottom: theme.spacing(2) },
        },
    },
    MuiCardContent: {
        root: {
            '&:last-child': {
                paddingBottom: 16,
            },
        },
    },
    MuiLinearProgress: {
        secondaryColor: { backgroundColor: theme.palette.grey[300] },
        secondaryColorBar: { backgroundColor: theme.palette.secondary.light },
    },
    MuiAvatar: {
        colorDefault: {
            backgroundColor: theme.palette.grey[500],
        },
    },
    MuiChip: {
        root: { backgroundColor: theme.palette.grey[100] },
        label: { fontSize: 14 },
    },
    MuiListItemIcon: {
        root: {
            minWidth: 40,
        },
    },

    /**
     * Override styles applied by gigwalk scss modules (_form.scss, _button.scss, etc)
     * which don't really fit the material design spec
     */
    MuiInput: {
        multiline: {
            '@global textarea': { minHeight: 0 },
        },
        inputMultiline: { minHeight: 0 },
        input: {
            minHeight: 'auto',
            '&:focus': { boxShadow: 'unset' },
        },
    },
    MuiOutlinedInput: {
        multiline: {
            '@global textarea': { minHeight: 0 },
        },
        inputMultiline: { minHeight: 0 },
        input: {
            minHeight: 'auto',
            '&:focus': { boxShadow: 'unset' },
        },
    },
    MuiFilledInput: {
        multiline: {
            '@global textarea': { minHeight: 0 },
        },
        inputMultiline: { minHeight: 0 },
        input: {
            minHeight: 'auto',
            '&:focus': { boxShadow: 'unset' },
        },
    },
    MuiButton: {
        root: {
            textTransform: 'none', // Override uppercase transform
            minWidth: 0,
        },
        containedPrimary: {
            '&:hover': { color: theme.palette.primary.contrastText },
        },
        containedSecondary: {
            '&:hover': { color: theme.palette.secondary.contrastText },
        },
    },
    MuiFab: {
        root: {
            textTransform: 'none', // Override uppercase transform
        },
    },
    MuiToggleButton: {
        root: {
            textTransform: 'none', // Override uppercase transform
        },
    },
    MuiFormControlLabel: {
        root: { marginBottom: 'unset' },

        // In material-ui v4, the default Typography variant changed from body2 to body1
        // but I think body2 looks better for selection controls.
        // label: {
        //     fontSize: '0.875rem',
        //     lineHeight: 1.43,
        //     letterSpacing: '0.01071em',
        // },
    },
    MuiFormLabel: {
        root: { marginBottom: 5 },
    },
    MuiTouchRipple: {
        root: { color: 'rgba(0, 0, 0, 0.87)' },
    },
    MuiListItem: {
        root: {
            '&:focus': { outline: 'none' },
        },
    },
    MuiListItemText: {
        dense: { fontSize: 12.5 },
        primary: {
            textTransform: 'none',
        },
    },
    MuiDialogContentText: {
        root: {
            marginBottom: [theme.spacing(2), '!important'],
        },
    },
});
