// @flow
import React, { Component } from 'react';
import isEqual from 'lodash/isEqual';
import { Route } from 'react-router';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import { CircularProgress, Paper } from '@material-ui/core';
import { compose } from 'recompose';
import type { Dispatch } from 'redux';
import type { Connector } from 'react-redux';
import type { TFunction } from 'react-i18next';
import type { ContextRouter } from 'react-router';
import type { $AxiosError } from 'axios';
import { format } from '../../../browser/shared/util/gigwalkApiErrorUtil';
import waitForCondition from '../../components/waitForCondition';
import SimplePane from '../../components/SimplePane';
import FilterDrawer from './containers/FilterDrawer';
import SearchToolbar from './containers/SearchToolbar';
import ActionToolbar from './containers/ActionToolbar';
import ListView from './containers/ListView';
import MapView from './containers/MapView';
import * as snackbar from '../../ducks/snackbar';
import { actions, selectors } from './duck';
import styles from './styles';
import type { State as RootState } from '../../redux/initialState';

type State = {
    loading: boolean,
};

type OwnProps = ContextRouter & {
    classes: Object,
    t: TFunction,
};
type StateProps = {
    data: Object[],
    filters?: Object[],
    keywords?: string,
    limit?: number,
    offset?: number,
    pages: number,
    search: {
        size?: string,
        page?: string,
        sort?: string,
        order?: string,
    },
    sort?: Object[],
};
type DispatchProps = {
    deselectAll: () => void,
    enqueueSnackbar: (message: string, options: Object) => void,
    fetchData: (filters: ?Object[], keywords: ?string, sort: ?Object[], limit: ?number, offset: ?number) => Promise<void>,
    loadFilters: (search: Object) => Promise<void>,
};
type Props = OwnProps & StateProps & DispatchProps;

export class TicketList extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        // this._fetchData = debounce(this._fetchData, 500);
        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
        const { filters, keywords, search, sort, limit, loadFilters, offset } = this.props;
        const promises = [
            this._fetchData(filters, keywords, sort, limit, offset),
            loadFilters(search),
        ];

        Promise.all(promises)
            .then(() => { this.setState({ loading: false }); });
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        const { filters, keywords, sort, limit, offset } = this.props;
        const {
            filters: nextFilters,
            keywords: nextKeywords,
            sort: nextSort,
            limit: nextLimit,
            offset: nextOffset,
        } = nextProps;

        // @todo Check if page changed and previous page was out of range
        // If the previous page is out of range and the new page is the last page, that
        // means we updated the url to match the data fetched, and no fetch is needed

        // If any filter, sort, or pagination parameters changed, we should fetch new data.
        // Otherwise, if the table data has changed at all (for instance, an entity was deleted
        // from state), fetch the current page again.
        const shouldFetchData = !isEqual(nextFilters, filters)
            || !isEqual(nextSort, sort)
            || nextKeywords !== keywords
            || nextLimit !== limit
            || nextOffset !== offset;

        if (shouldFetchData) {
            this._fetchData(nextFilters, nextKeywords, nextSort, nextLimit, nextOffset);
        }
    }

    componentWillUnmount() {
        const { deselectAll } = this.props;
        deselectAll();
    }

    _fetchData = (filters: ?Object[], keywords: ?string, sort: ?Object[], limit: ?number, offset: ?number) => {
        const { enqueueSnackbar, fetchData } = this.props;
        return fetchData(filters, keywords, sort, limit, offset)
            .catch((err: $AxiosError<any>) => {
                const resp = err ? err.response : null;
                if (resp && resp.data && resp.data.gw_api_response) {
                    const message = format(resp.data.gw_api_response);
                    enqueueSnackbar(message, { variant: 'error' });
                }
            });
    };

    render() {
        const { classes, match } = this.props;
        const { loading } = this.state;
        const basePath = match.path.split('/:view(list|map)')[0];

        if (loading) {
            return (
                <SimplePane>
                    <div className={classes.loading}>
                        <CircularProgress />
                    </div>
                </SimplePane>
            );
        }

        return (
            <SimplePane classes={classes.ticketList}>
                <Route component={SearchToolbar} />
                <Route component={FilterDrawer} />
                <Paper elevation={2}>
                    <Route component={ActionToolbar} />
                    <Route path={`${basePath}/list`} component={ListView} />
                    <Route path={`${basePath}/map`} component={MapView} />
                </Paper>
            </SimplePane>
        );
    }
}

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => ({
    ...selectors.getAPIParams(state, props),
    data: selectors.getTableData(state, props),
    pages: state.ticketList.metadata.page_count || 0,
    search: selectors.parseSearchParams(state, props),
});

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    deselectAll: () => dispatch(actions.deselectAll()),
    enqueueSnackbar: (message: string, options: Object) => dispatch(snackbar.actions.enqueue(message, options)),
    fetchData: (filters: ?Object[], keywords: ?string, sort: ?Object[], limit: ?number, offset: ?number) => (
        dispatch(actions.fetchData(filters, keywords, sort, limit, offset))
    ),
    loadFilters: (search: Object) => dispatch(actions.loadFilters(search)),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
const enhance = compose(
    withStyles(styles, { name: 'TicketList' }),
    withTranslation(),
    waitForCondition(() => global.hasOwnProperty('google'), 100),
    connector,
);

export default enhance(TicketList);
