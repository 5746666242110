// @flow
import { handleActions } from 'redux-actions';
import type { ActionType } from 'redux-actions';
import { UPDATE_PROFILE_SUCCESS, UPDATE_USER_SUCCESS } from '../api';
import types from './types';
import typeof { refreshSuccess, initialize } from './actions';

type FeatureMap = { [key: string]: boolean };

export type State = {
    user: ?Object,
    features: FeatureMap,
    profile: Object,
};

export const init: State = Object.freeze({
    user: null,
    features: {},
    profile: {},
});

// todo: only use handleActions after session has its own key in the state tree
export default handleActions({
    [types.REFRESH_SUCCESS]: (state: State, action: ActionType<refreshSuccess>) => ({
        ...state,
        features: action.payload.features,
        user: action.payload.user,
        profile: action.payload.profile,
    }),
    [types.INITIALIZE]: (state: State, action: ActionType<initialize>) => ({
        ...state,
        features: action.payload.features,
        user: action.payload.user,
        profile: action.payload.profile,
    }),
    [types.LOGOUT]: () => ({
        ...init,
    }),
    [UPDATE_PROFILE_SUCCESS]: (state: State, action: ActionType<*>) => ({
        ...state,
        profile: action.payload,
    }),
    [UPDATE_USER_SUCCESS]: (state: State, action: ActionType<*>) => ({
        ...state,
        user: action.payload,
    }),
}, init);
