// @flow
// $FlowIssue need to update to a more recent flow version
import React, { Component, createRef } from 'react';
import moment from 'moment-timezone';
import cx from 'classnames';
import { DayPickerSingleDateController } from 'react-dates';
import { withStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import {
    AccessTime as AccessTimeIcon,
    DateRange as DateRangeIcon,
} from '@material-ui/icons';
import TimePicker from './TimePicker';
import styles from './styles';
import theme from './theme.scss';

type State = {
    date: string,
    openView: string,
    time: string,
};

type Props = {
    className?: string,
    classes: Object,
    disabled?: boolean,
    disableTime?: boolean,
    error?: string,
    focused?: boolean,
    format?: string,
    maxDate?: string | moment$Moment | Date,
    minDate?: string | moment$Moment | Date,
    onChange?: (value: string) => void,
    onFocus?: () => void,
    onBlur?: () => void,
    placeholder?: string,
    updatePosition: () => void,
    value?: string | moment$Moment | Date,
};

const defaultControllerProps = {
    hideKeyboardShortcutsPanel: true,
    numberOfMonths: 1,
    showDefaultInputIcon: false,
};

class DayPicker extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const { format, value } = this.props;
        const datetime = moment(value, format);
        const isValid = datetime.isValid();

        this.state = {
            date: isValid ? datetime.format('YYYY-MM-DD') : '',
            openView: 'date',
            time: isValid ? datetime.format('HH:mm') : '',
        };

        // $FlowIssue need to update to more recent flow version
        this.containerRef = createRef();
    }

    componentDidMount() {
        // For whatever reason, the height of DayPickerSingleDateController is not known until
        // after the component has been rendered (and perhaps some state updated). As a result,
        // the Popover component doesn't have all the information needed to correctly position
        // itself at render time. We can call updatePosition here to fix any positioning issues.
        const { updatePosition } = this.props;
        updatePosition();
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        const { focused, format, value } = this.props;
        let nextState = null;

        if (value !== nextProps.value || format !== nextProps.format) {
            const datetime = moment(nextProps.value, nextProps.format);
            const isValid = datetime.isValid();

            nextState = {
                ...nextState,
                date: isValid ? datetime.format('YYYY-MM-DD') : '',
                time: isValid ? datetime.format('HH:mm') : '',
            };
        }

        if (focused !== nextProps.focused && !nextProps.focused) {
            nextState = {
                ...nextState,
                openView: 'date',
            };
        }

        if (nextState) {
            this.setState(nextState);
        }
    }

    handleDateChange = (date: moment$Moment) => {
        const { onChange, format } = this.props;
        const { time } = this.state;
        const dateStr = date.format('YYYY-MM-DD');
        const datetimeStr = `${dateStr} ${time}`.trim();
        const datetime = moment(datetimeStr, 'YYYY-MM-DD hh:mm a');

        this.setState({ date: dateStr }, () => {
            if (typeof onChange === 'function') {
                onChange(datetime.format(format));
            }
        });
    };

    handleTimeChange = (time: string) => {
        const { date } = this.state;
        const { format, onChange } = this.props;
        const datetimeStr = `${date || moment().format('YYYY-MM-DD')} ${time}`.trim();
        const datetime = moment(datetimeStr, 'YYYY-MM-DD hh:mm a');

        this.setState({ time }, () => {
            if (typeof onChange === 'function') {
                onChange(datetime.format(format));
            }
        });
    };

    handleFocusChange = ({ focused }: { focused: boolean }) => {
        const { disableTime } = this.props;

        if (!disableTime && !focused) {
            this.setState({ openView: 'time' });
        } else if (focused) {
            this.setState({ openView: 'date' });
        } else {
            this.handleBlur();
        }
    };

    handleViewChange = () => {
        const { openView } = this.state;
        this.setState({ openView: openView === 'time' ? 'date' : 'time' });
    };

    // handleFocus = () => {
    //     const { onFocus } = this.props;
    //     if (typeof onFocus === 'function') {
    //         onFocus();
    //     }
    // };

    handleBlur = () => {
        const { onBlur } = this.props;
        if (typeof onBlur === 'function') {
            onBlur();
        }
    };

    renderTimePicker = () => {
        const { openView, time } = this.state;
        const { classes, disableTime } = this.props;

        if (disableTime) {
            return null;
        }

        // $FlowIssue need to update to more recent flow version
        const node = this.containerRef.current;
        const daypicker = node ? node.getElementsByClassName('DayPicker')[0] : null;
        const { height } = daypicker ? daypicker.getBoundingClientRect() : { height: 0 };

        const style = {
            position: 'relative',
            transition: 'unset',
            transform: 'unset',
        };
        if (openView === 'time') {
            style.transition = 'transform 225ms cubic-bezier(0.0, 0, 0.2, 1)';
            style.transform = `translateY(${-height + 42}px)`;
        } else {
            style.transition = 'transform 195ms cubic-bezier(0.4, 0, 0.6, 1)';
            style.transform = 'translateY(0px)';
        }

        return (
            <div className={classes.calendarInfo} style={style}>
                <Button
                  classes={{
                      root: classes.button,
                      contained: classes.buttonContained,
                      containedPrimary: classes.buttonContainedPrimary,
                  }}
                  variant="contained"
                  color="primary"
                  onClick={this.handleViewChange}
                >
                    {openView === 'date' ? <AccessTimeIcon /> : <DateRangeIcon />}
                </Button>
                <div className={classes.timePicker} style={{ height: height - 42, overflow: 'hidden' }}>
                    <TimePicker value={time} onChange={this.handleTimeChange} />
                    <Button className={classes.confirmButton} onClick={this.handleBlur}>Okay</Button>
                </div>
            </div>
        );
    };

    render() {
        const { date } = this.state;
        const { error, focused, className, classes, disableTime, ...controllerProps } = this.props;
        const { disabled } = controllerProps;

        const containerClassName = cx(theme.dayPicker, classes.dayPicker, className, {
            [classes.dateOnly]: disableTime === true,
            invalid: !!error && !disabled,
            disabled,
        });

        return (
            // $FlowIssue need to update to more recent flow version
            <div className={containerClassName} ref={this.containerRef}>
                <DayPickerSingleDateController
                  {...defaultControllerProps}
                  {...controllerProps}
                  date={date ? moment(date) : null}
                  focused={focused}
                  onDateChange={this.handleDateChange}
                  onFocusChange={this.handleFocusChange}
                  renderCalendarInfo={this.renderTimePicker}
                />
            </div>
        );
    }
}

export default withStyles(styles, { name: 'DayPicker' })(DayPicker);
