// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback } from 'react';
import qs from 'qs';
import stringify from 'json-stable-stringify';
import { isEmail } from 'validator';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import {
    Checkbox,
    Chip,
    Drawer,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    IconButton,
    Toolbar,
    Tooltip,
} from '@material-ui/core';
import {
    Close as CloseIcon,
    ClearAll as ClearAllIcon,
    Search as SearchIcon,
} from '@material-ui/icons';
import type { Dispatch } from 'redux';
import type { Connector } from 'react-redux';
import type { ContextRouter } from 'react-router';
import { USER_ROLES } from '../../../../../browser/shared/constant/UserRoles';
import { search as searchOrganizations } from '../../../../redux/entities/organizations';
import { search as searchCustomers } from '../../../../redux/entities/customers';
import getDisplayName from '../../../../util/getDisplayName';
import Select from '../../../../components/Select';
import { actions, selectors } from './duck';
import styles from './styles';
import type { State as RootState } from '../../../../redux/initialState';

type OwnProps = ContextRouter & {};
type StateProps = {
    customerFilterValue: Object[],
    organizationFilterValue: ?Object,
    open: boolean,
    search: {
        customer?: string[],
        organization?: string,
        type?: string[],
    },
    typeFilterOptions: Object[],
    typeFilterValue: Object[],
    user: ?Object,
};
type DispatchProps = {
    searchCustomers: (params: Object) => Promise<Object>,
    searchOrganizations: (params: Object) => Promise<Object>,
    toggleFilterDrawer: (open: boolean) => void,
};
type Props = OwnProps & StateProps & DispatchProps;

// @todo Move to a utils folder
const getOptionValue = (option: Object): string => {
    let { value } = option;
    if (typeof value === 'object') {
        value = value.hasOwnProperty('id') ? value.id : value;
        value = value.hasOwnProperty('place_id') ? value.place_id : value;
    }
    return typeof value === 'string' ? value : stringify(value);
};

// @todo Move to a utils folder
const isOptionSelected = (option: Object, value: Object[]): boolean => {
    if (value.indexOf(option) > -1) return true;
    const candidate = getOptionValue(option);
    return value.some((i: Object) => getOptionValue(i) === candidate);
};

const noFilters = {
    customer: undefined,
    organization: undefined,
    type: undefined,
};

const useStyles = makeStyles(styles, { name: 'FilterDrawer' });

export function FilterDrawer(props: Props) {
    const {
        customerFilterValue,
        history,
        location,
        organizationFilterValue,
        open,
        search,
        searchCustomers: _searchCustomers,
        searchOrganizations: _searchOrganizations,
        typeFilterOptions,
        typeFilterValue,
        toggleFilterDrawer,
        user,
    } = props;

    const classes = useStyles(props);
    const { t } = useTranslation();

    const loadOrganizationFilterOptions = useCallback((inputValue: string) => {
        const params = {
            q: inputValue ? `*${inputValue}*` : null,
            from: 0,
            size: 20,
            sort_field: 'organization_name',
            sort_order: 'asc',
        };

        return _searchOrganizations(params)
            .then((resp) => {
                const { entities, result } = resp;
                // @todo: Consider using denormalize to get denormalized entity
                return result.map((id: number) => ({
                    label: entities.organizations[id].organization_name,
                    value: entities.organizations[id],
                }));
            });
    }, [_searchOrganizations]);

    const loadCustomerFilterOptions = useCallback((inputValue: string) => {
        const params = {};
        params.limit = 20;
        params.offset = 0;
        params.sort = [{ first_name: 'asc' }];

        if (inputValue) {
            if (isEmail(inputValue)) {
                params.filters.push(
                    {
                        filter_type: 'or',
                        filters: [
                            { key: 'email', value: inputValue },
                            { key: 'paypal_email', value: inputValue },
                        ],
                    }
                );
            } else {
                params.keywords = inputValue;
            }
        }

        // Use searchCustomers to ensure entities are stored in state
        return _searchCustomers(params)
            .then((resp) => {
                // @todo: Consider using entitySelector to get denormalized entity...
                const { entities, result } = resp;
                return result.map((id: number) => ({
                    label: getDisplayName(entities.customers[id]),
                    value: entities.customers[id],
                }));
            });
    }, [_searchCustomers]);

    const updateFilters = useCallback((filters: Object) => {
        history.replace({
            pathname: location.pathname,
            search: qs.stringify(
                { ...search, ...filters },
                { addQueryPrefix: true, encodeValuesOnly: true },
            ),
        });
    }, [history, location.pathname, search]);

    const handleClear = useCallback(() => {
        updateFilters(noFilters);
    }, [updateFilters]);

    const handleOrganizationFilterChange = useCallback((value: ?Object) => {
        const organizationFilter = value ? value.value.id : undefined;
        updateFilters({ organization: organizationFilter });
    }, [updateFilters]);

    const handleCustomerFilterChange = useCallback((value: Object[]) => {
        const customerFilters = value.map((option: Object) => option.value.id);
        updateFilters({ customer: customerFilters });
    }, [updateFilters]);

    const handleCheckboxChange = useCallback((event: SyntheticInputEvent<HTMLInputElement>) => {
        const { checked, name, value } = event.target;
        let filterValue;

        switch (name) {
            case 'type':
                filterValue = typeFilterValue;
                break;
            default:
                return;
        }

        const index = filterValue.findIndex((option: Object) => (
            getOptionValue(option) === value
        ));

        if (checked && index === -1) {
            const filter = [
                ...filterValue.map((option: Object) => getOptionValue(option)),
                value,
            ];
            updateFilters({ [name]: filter });
        } else if (!checked && index >= 0) {
            const filter = filterValue
                .reduce((acc: string[], option: Object): string[] => {
                    const optionValue = getOptionValue(option);
                    if (optionValue !== value) return [...acc, optionValue];
                    return acc;
                }, []);
            updateFilters({ [name]: filter });
        }
    }, [typeFilterValue, updateFilters]);

    const handleChipDelete = useCallback((event: SyntheticEvent<any>) => {
        const { name, value } = event.currentTarget.parentElement.dataset;
        let filterValue;

        switch (name) {
            case 'organization':
                filterValue = organizationFilterValue;
                break;
            case 'customer':
                filterValue = customerFilterValue;
                break;
            default:
                return;
        }

        if (Array.isArray(filterValue)) {
            const index = filterValue.findIndex((option: Object) => (
                getOptionValue(option) === value
            ));

            if (index >= 0) {
                const filter = search[name].filter((el, i) => i !== index);
                updateFilters({ [name]: filter });
            }
        } else {
            updateFilters({ [name]: undefined });
        }
    }, [customerFilterValue, organizationFilterValue, search, updateFilters]);

    const handleDrawerClose = useCallback(() => {
        toggleFilterDrawer(false);
    }, [toggleFilterDrawer]);

    return (
        <Drawer
          anchor="right"
          classes={{ paper: classes.paper }}
          onClose={handleDrawerClose}
          open={open}
        >
            <Toolbar>
                <div className={classes.title}>
                    {t('transactionList.filters.filterTransactions')}
                </div>
                <div className={classes.actions}>
                    <Tooltip title={t('transactionList.filters.clearFilters')}>
                        <IconButton onClick={handleClear}>
                            <ClearAllIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('transactionList.filters.close')}>
                        <IconButton onClick={handleDrawerClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </div>
            </Toolbar>
            <div className={classes.filters}>
                {user && user.role === USER_ROLES.PLATFORM_ADMIN
                    ? (
                        <FormControl className={classes.organizationFilter}>
                            <FormLabel>{t('transactionList.filters.organization')}</FormLabel>
                            <Select
                              ControlProps={{
                                  fullWidth: true,
                                  margin: 'dense',
                                  variant: 'outlined',
                              }}
                              controlShouldRenderValue={false}
                              defaultOptions
                              dropdownIcon={<SearchIcon />}
                              loadOptions={loadOrganizationFilterOptions}
                              menuPlacement="auto"
                              onChange={handleOrganizationFilterChange}
                              placeholder="Search organizations..."
                              showSelectedOptions={false}
                              value={organizationFilterValue}
                            />
                            <div className={classes.chipArray}>
                                {organizationFilterValue
                                    ? (
                                        <Chip
                                          classes={{
                                              root: classes.chip,
                                              label: classes.chipLabel,
                                          }}
                                          color="primary"
                                          data-name="organization"
                                          data-value={getOptionValue(organizationFilterValue)}
                                          key={getOptionValue(organizationFilterValue)}
                                          label={organizationFilterValue.label}
                                          onDelete={handleChipDelete}
                                        />
                                    )
                                    : null}
                            </div>
                        </FormControl>
                    )
                    : null}
                <FormControl className={classes.customerFilter}>
                    <FormLabel>{t('transactionList.filters.customer')}</FormLabel>
                    <Select
                      ControlProps={{
                          fullWidth: true,
                          margin: 'dense',
                          variant: 'outlined',
                      }}
                      controlShouldRenderValue={false}
                      defaultOptions
                      dropdownIcon={<SearchIcon />}
                      isMulti
                      loadOptions={loadCustomerFilterOptions}
                      menuPlacement="auto"
                      onChange={handleCustomerFilterChange}
                      placeholder="Search customers..."
                      showSelectedOptions={false}
                      value={customerFilterValue}
                    />
                    <div className={classes.chipArray}>
                        {customerFilterValue.map((option: Object) => (
                            <Chip
                              classes={{
                                  root: classes.chip,
                                  label: classes.chipLabel,
                              }}
                              color="primary"
                              data-name="customer"
                              data-value={getOptionValue(option)}
                              key={getOptionValue(option)}
                              label={option.label}
                              onDelete={handleChipDelete}
                            />
                        ))}
                    </div>
                </FormControl>
                <FormControl className={classes.statusFilter}>
                    <FormLabel>{t('transactionList.filters.type')}</FormLabel>
                    <FormGroup className={classes.filterOptions}>
                        {typeFilterOptions.map((option) => (
                            <FormControlLabel
                              className={classes.checkboxContainer}
                              control={(
                                  <Checkbox
                                    checked={isOptionSelected(option, typeFilterValue)}
                                    className={classes.checkbox}
                                    name="type"
                                    onChange={handleCheckboxChange}
                                    value={getOptionValue(option)}
                                  />
                              )}
                              key={getOptionValue(option)}
                              label={option.label}
                            />
                        ))}
                    </FormGroup>
                </FormControl>
            </div>
        </Drawer>
    );
}

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => ({
    open: state.transactionList.drawerOpen,
    organizationFilterValue: selectors.getOrganizationFilterValue(state, props),
    customerFilterValue: selectors.getCustomerFilterValue(state, props),
    search: selectors.parseSearchParams(state, props),
    typeFilterOptions: selectors.getTypeFilterOptions(),
    typeFilterValue: selectors.getTypeFilterValue(state, props),
    user: state.session.user,
});

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    searchOrganizations: (params: Object) => dispatch(searchOrganizations(params)),
    searchCustomers: (params: Object) => dispatch(searchCustomers(params)),
    toggleFilterDrawer: (open: boolean) => dispatch(actions.toggleFilterDrawer(open)),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
export default connector(FilterDrawer);
