// @flow
export default (theme: Object) => ({
    root: {},
    paper: {
        width: 300,
    },
    title: {
        fontSize: 18,
        textTransform: 'capitalize',
    },
    actions: {
        display: 'flex',
        alightItems: 'center',
        marginLeft: 'auto',
        marginRight: -12,

        '& > *:not(:last-child)': {
            marginRight: -4,
        },
    },
    filters: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),

        '& > *': {
            display: 'flex',
            marginBottom: theme.spacing(2),
        },
    },
    organizationFilter: {},
    chipArray: {
        display: 'flex',
        flexFlow: 'row wrap',
        margin: [[4, -4, 0, -4]],
    },
    chip: {
        margin: 4,
        maxWidth: 'calc(100% - 8px)',
    },
    chipLabel: {
        display: 'block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
});
