// @flow
import React from 'react';

export default function SingleValue(props: Object) {
    const { children, innerProps, selectProps } = props;
    const { classes } = selectProps;

    return (
        <div {...innerProps} className={classes.singleValue}>
            {children}
        </div>
    );
}
