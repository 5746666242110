// @flow
import React from 'react';
import { Form } from 'formik';
import { makeStyles } from '@material-ui/styles';
import { Help as HelpIcon } from '@material-ui/icons';
import styles from './styles';

type Props = {
    dataItem: ?Object,
    expectedValueExpression: ?Object,
    id: string,
    questionText: string,
};

const useStyles = makeStyles(styles, { name: 'Hint' });

export default function Hint(props: Props) {
    const { id, questionText } = props;
    const classes = useStyles(props);

    return (
        <Form className={classes.root} name={id}>
            <div className={classes.question}>
                <span dangerouslySetInnerHTML={{ __html: `<span style="font-weight:600;font-style:italic">Hint: </span>${questionText}` }} />
            </div>
            <div className={classes.metadata}>
                <HelpIcon color="action" fontSize="small" />
            </div>
        </Form>
    );
}
