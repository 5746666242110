// @flow
import union from 'lodash/union';
import { handleActions } from 'redux-actions';
import type { ActionType } from 'redux-actions';
import types from './types';
import typeof { getMoreLocationsSuccess } from './actions';

export type State = {
    locationIds: number[],
    updatingPrice: boolean,
};

export const init: State = {
    locationIds: [],
    updatingPrice: false,
};

export default handleActions({
    [types.RESET_LOCATIONS]: (state: State) => ({
        ...state,
        locationIds: [],
    }),
    [types.GET_MORE_LOCATIONS_SUCCESS]: (state: State, action: ActionType<getMoreLocationsSuccess>) => {
        if (action.payload == null) {
            return state;
        }

        return {
            ...state,
            locationIds: union(state.locationIds, action.payload),
        };
    },
    [types.GET_PRICE_ESTIMATE]: (state: State) => ({
        ...state,
        updatingPrice: true,
    }),
    [types.GET_PRICE_ESTIMATE_SUCCESS]: (state: State) => ({
        ...state,
        updatingPrice: false,
    }),
    [types.GET_PRICE_ESTIMATE_ERROR]: (state: State) => ({
        ...state,
        updatingPrice: false,
    }),
}, init);
