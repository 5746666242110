// @flow
import { handleActions } from 'redux-actions';
import mergeWith from 'lodash/mergeWith';
import uniqBy from 'lodash/uniqBy';
// import blacklist from 'blacklist';
import reduceReducers from 'reduce-reducers';
import type { ActionType } from 'redux-actions';
import types from './types';
import typeof {
    addDatatypeAttachmentSuccess,
    deleteDatatypeAttachmentSuccess,
    // removeLocationListItemSuccess,
    // deleteSubscriptionSuccess,
    getApplicantsSuccess,
    getTicketEventsSuccess,
    createTicketEventSuccess,
    deleteTicketEventSuccess,
    removeTicketTagSuccess,
} from './actions';

export type State = {
    auditEvents: Object,
    certifications: Object,
    customers: Object,
    dataTypes: Object,
    fileUploads: Object,
    groups: Object,
    locationLists: Object,
    locations: Object,
    notifications: Object,
    organizations: Object,
    payouts: Object,
    relations: Object,
    subscriptions: Object,
    tags: Object,
    targetLists: Object,
    targets: Object,
    templates: Object,
    tickets: Object,
    unresolvedLocations: Object,
};

export const init: State = Object.freeze({
    auditEvents: {},
    certifications: {},
    customers: {},
    dataTypes: {},
    fileUploads: {},
    groups: {},
    locationLists: {},
    locations: {},
    notifications: {},
    organizations: {},
    payouts: {},
    relations: {},
    subscriptions: {},
    tags: {},
    targetLists: {},
    targets: {},
    templates: {},
    tickets: {},
    unresolvedLocations: {},
});

const customizer = (objValue: any, srcValue: any, key: string): any => {
    if (Array.isArray(objValue) || key.includes('metadata')) {
        return srcValue;
    }
};

export function updateEntities(state: State = init, action: ActionType<any>): State {
    if (action.payload && action.payload.entities) {
        return mergeWith({}, state, action.payload.entities, customizer);
    }
    return state;
}

const reducer = handleActions({
    [types.ADD_DATATYPE_ATTACHMENT_SUCCESS]: (state: State, action: ActionType<addDatatypeAttachmentSuccess>): State => {
        const { data_type_id: dataTypeId, attachment } = action.meta;
        const { attachments, ...dataType } = state.dataTypes[dataTypeId];
        return {
            ...state,
            dataTypes: {
                ...state.dataTypes,
                [dataTypeId]: {
                    ...dataType,
                    attachments: Array.isArray(attachments)
                        ? [...attachments, attachment]
                        : [attachment],
                },
            },
        };
    },
    [types.DELETE_DATATYPE_ATTACHMENT_SUCCESS]: (state: State, action: ActionType<deleteDatatypeAttachmentSuccess>): State => {
        const { data_type_id: dataTypeId, attachment } = action.meta;
        const { attachments, ...dataType } = state.dataTypes[dataTypeId];
        return {
            ...state,
            dataTypes: {
                ...state.dataTypes,
                [dataTypeId]: {
                    ...dataType,
                    attachments: Array.isArray(attachments)
                        ? attachments.filter(({ url }) => url !== attachment.url)
                        : attachments,
                },
            },
        };
    },
    // [types.REMOVE_LOCATION_LIST_ITEM_SUCCESS]: (state: State, action: ActionType<removeLocationListItemSuccess>): State => {
    //     if (action.payload && Array.isArray(action.payload.result) && action.payload.result.length) {
    //         const keys: Array<string> = action.payload.result.map((item: RemovedLocation): string => `${item.relation}`);
    //         return {
    //             ...state,
    //             relations: blacklist(state.relations, ...keys),
    //         };
    //     }
    //
    //     return state;
    // },
    // [types.DELETE_SUBSCRIPTION_SUCCESS]: (state: State, action: ActionType<deleteSubscriptionSuccess>): State => {
    //     if (action.payload && Array.isArray(action.payload.result)) {
    //         const keys = action.payload.result.map((id: number): string => `${id}`);
    //         return {
    //             ...state,
    //             subscriptions: blacklist(state.subscriptions, ...keys),
    //         };
    //     }
    //
    //     return state;
    // },
    [types.GET_APPLICANTS_SUCCESS]: (state: State, action: ActionType<getApplicantsSuccess>): State => {
        const { meta, payload } = action;
        const ticketId = meta.ticket_id;
        const ticket = state.tickets[ticketId];
        const applicants = ticket.applicants || [];

        return {
            ...state,
            tickets: {
                ...state.tickets,
                [ticketId]: {
                    ...ticket,
                    // Since applicants are normalized to an array of customer ids, we can use
                    // a Set to prevent duplicates
                    applicants: Array.from(new Set([...applicants, ...payload.result])),
                },
            },
        };
    },
    [types.GET_TICKET_EVENTS_SUCCESS]: (state: State, action: ActionType<getTicketEventsSuccess>): State => {
        const { meta, payload } = action;
        const ticketId = meta.ticket_id;
        const ticket = state.tickets[ticketId];
        const events = ticket ? ticket.events || [] : [];

        return {
            ...state,
            tickets: {
                ...state.tickets,
                [ticketId]: {
                    ...ticket,
                    events: uniqBy([...events, ...payload.result], (event) => event.id),
                },
            },
        };
    },
    [types.CREATE_TICKET_EVENT_SUCCESS]: (state: State, action: ActionType<createTicketEventSuccess>): State => {
        const { meta, payload } = action;
        const ticketId = meta.ticket_id;
        const ticket = state.tickets[ticketId];
        const ticketEvents = ticket ? ticket.events || [] : [];

        return {
            ...state,
            tickets: {
                ...state.tickets,
                [ticketId]: {
                    ...ticket,
                    events: [
                        ...payload.result,
                        ...ticketEvents,
                    ],
                },
            },
        };
    },
    [types.DELETE_TICKET_EVENT_SUCCESS]: (state: State, action: ActionType<deleteTicketEventSuccess>): State => {
        const eventId = action.meta.ticket_event_id;

        // Remove deleted event from all tickets that include this event (should only be 1 such ticket)
        // const updatedTickets = { ...state.tickets };
        const updatedTickets = Object.entries(state.tickets)
            .reduce((tickets, [id, ticket]) => {
                // $FlowIssue https://github.com/facebook/flow/issues/2174
                const events = ticket.events || [];
                const index = events.findIndex((event) => event.id === eventId);
                if (index !== -1) {
                    return {
                        ...tickets,
                        [id]: {
                            ...ticket,
                            events: [
                                ...events.slice(0, index),
                                ...events.slice(index + 1),
                            ],
                        },
                    };
                }
                return tickets;
            }, {});

        return {
            ...state,
            tickets: updatedTickets,
        };
    },
    [types.REMOVE_TICKET_TAG_SUCCESS]: (state: State, action: ActionType<removeTicketTagSuccess>): State => {
        const { tag_id: tagId, ticket_id: ticketId } = action.meta;
        const ticket = state.tickets[ticketId];

        return {
            ...state,
            tickets: {
                ...state.tickets,
                [ticketId]: {
                    ...ticket,
                    tags: ticket.tags.filter(([id]) => (id !== tagId)),
                },
            },
        };
    },
}, init);

// Keep updateEntities as the first argument to ensure new/updated entities are merged
// into state before any of the other reducers are executed
export default reduceReducers(updateEntities, reducer);
