// @flow
import { handleActions } from 'redux-actions';
import type { ActionType } from 'redux-actions';
import types from './types';
import { registerForm, toggleLocationEnabled, unregisterForm } from './actions';

type FormHelpers = {
    submitForm: () => Promise<void>,
};

export type State = {
    locationEnabled: boolean,
    forms: {
        [name: string]: FormHelpers,
    },
    formErrors: {},
    submitting: boolean,
};

export const init: State = {
    locationEnabled: false,
    forms: {},
    formErrors: {},
    submitting: false,
};

export default handleActions({
    [types.TOGGLE_LOCATION_ENABLED]: (state: State, action: ActionType<toggleLocationEnabled>) => ({
        ...state,
        locationEnabled: action.payload,
    }),
    [types.REGISTER_FORM]: (state: State, action: ActionType<registerForm>) => ({
        ...state,
        forms: {
            ...state.forms,
            [action.payload.name]: action.payload.helpers,
        },
    }),
    [types.UNREGISTER_FORM]: (state: State, action: ActionType<unregisterForm>) => {
        const forms = { ...state.forms };
        delete forms[action.payload];
        return { ...state, forms };
    },
    [types.SAVE_CHANGES]: (state: State) => ({
        ...state,
        submitting: true,
    }),
    [types.SAVE_CHANGES_ERROR]: (state: State) => ({
        ...state,
        submitting: false,
    }),
}, init);
