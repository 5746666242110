var Handlebars = require('/tmp/build_a9d70681abb25b82ca4489607bd6ccee/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"ti\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":1,"column":16},"end":{"line":1,"column":27}}}) : helper)))
    + "</div>\n<div class=\"description\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"more_work_to_complete") || (depth0 != null ? lookupProperty(depth0,"more_work_to_complete") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"more_work_to_complete","hash":{},"data":data,"loc":{"start":{"line":2,"column":25},"end":{"line":2,"column":52}}}) : helper)))
    + "</div>\n\n<div class=\"status-prompt type item-grid\" data-action=\"toggleNext\">\n    <div class=\"answers\">\n        <div class=\"answer\">\n            <label><input type=\"radio\" name=\"continue\" value=\"yes\"> &nbsp;"
    + alias4(((helper = (helper = lookupProperty(helpers,"yes") || (depth0 != null ? lookupProperty(depth0,"yes") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"yes","hash":{},"data":data,"loc":{"start":{"line":7,"column":74},"end":{"line":7,"column":83}}}) : helper)))
    + "</label>\n        </div>\n        <div class=\"answer\">\n            <label><input type=\"radio\" name=\"continue\" value=\"no\" checked> &nbsp;"
    + alias4(((helper = (helper = lookupProperty(helpers,"no") || (depth0 != null ? lookupProperty(depth0,"no") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"no","hash":{},"data":data,"loc":{"start":{"line":10,"column":81},"end":{"line":10,"column":89}}}) : helper)))
    + "</label>\n        </div>\n    </div>\n</div>";
},"useData":true});