// @flow
export const SUBMIT = 'g/account/SUBMIT';
export const SUBMIT_SUCCESS = `${SUBMIT}_SUCCESS`;
export const SUBMIT_ERROR = `${SUBMIT}_ERROR`;

export const RESOLVE_ADDRESS = 'g/account/RESOLVE_ADDRESS';
export const RESOLVE_ADDRESS_SUCCESS = `${RESOLVE_ADDRESS}_SUCCESS`;
export const RESOLVE_ADDRESS_ERROR = `${RESOLVE_ADDRESS}_ERROR`;

export default {
    RESOLVE_ADDRESS,
    RESOLVE_ADDRESS_ERROR,
    RESOLVE_ADDRESS_SUCCESS,
    SUBMIT,
    SUBMIT_ERROR,
    SUBMIT_SUCCESS,
};
