// @flow
import * as yup from 'yup';

export default function filterSchema(schema: Object, fields: string[], deps?: string[] = []) {
    const schemaDef = {};

    fields.forEach((key: string) => {
        const field = schema.fields[key];
        if (field) {
            schemaDef[key] = field.clone();
        }
    });

    deps.forEach((key: string) => {
        const field = schema.fields[key];
        if (field) {
            schemaDef[key] = field.clone().skipValidation();
        }
    });

    return yup.object().shape(schemaDef);
}
