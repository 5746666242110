// @flow
import { createSelector } from 'reselect';
import qs from 'qs';
import GeoPoint from 'geopoint';
import moment from 'moment-timezone';
import { isAdmin, isOperator, isWorker } from '../session';
import { createEntitySelector } from '../gigwalk';
import type { State as RootState } from '../../store';

type TableData = Array<{
    applications: ?Object[],
    approvalStatus: string,
    assignee: ?Object,
    deadline: ?string,
    description: string,
    dueDate: string,
    id: number,
    location: Object,
    paused: boolean,
    price: ?number,
    scheduledDate: ?string,
    startDate: string,
    status: string,
    submittedDate: ?string,
    timeEstimate: ?number,
    title: string,
}>;

type SearchParams = {
    applicants?: string,
    approval?: string[],
    assignee?: string[],
    dueDate?: {
        from?: string,
        to?: string,
    },
    geo?: {
        lat: string,
        lng: string,
        r: string,
    },
    group?: string[],
    location?: Object[],
    order?: string,
    organization?: string[],
    page?: string,
    q?: string,
    size?: string,
    sort?: string,
    scheduledDate?: {
        from?: string,
        to?: string,
    },
    status?: string[],
    subscription?: string[],
    tag?: string[],
};

type PathParams = {
    orgId: string,
};

type APIParams = {
    filters?: Object[],
    keywords?: string,
    sort?: Object[],
    limit?: number,
    offset?: number,
};

type LatLng = {
    lat: number,
    lng: number,
}

// Maps the key we use in the url query to the key expected by the API
const apiParams = {
    assignee: 'assigned_customer_name',
    dueDate: 'due_date',
    location: 'location_formatted_address',
    price: 'price',
    scheduledDate: 'calendar_event_start',
    startDate: 'start_date',
    status: 'ticket_status',
    submittedDate: 'date_submitted',
    title: 'title',
};

export const getSelectedTickets = createEntitySelector(
    'tickets',
    (state: RootState) => state.ticketList.selected
);

export const getTableData = createSelector(
    createEntitySelector('tickets', (state: RootState) => state.ticketList.data),
    (tickets: Object[]): TableData => (
        tickets
            .filter((ticket) => !!ticket)
            .map((ticket) => {
                const {
                    applications,
                    approval_status: approvalStatus,
                    calendar_event: calendarEvent,
                    customer: assignee,
                    date_submitted: submittedDate,
                    deadline,
                    description,
                    due_date: dueDate,
                    id,
                    is_paused: isPaused,
                    location,
                    organization_id: organizationId,
                    price,
                    start_date: startDate,
                    status,
                    subscription,
                    tags,
                    time_estimate: timeEstimate,
                    title,
                } = ticket;

                const pendingApplications = subscription.is_double_optin
                    ? applications.filter((application: Object) => application.status === 'PENDING')
                    : null;

                return {
                    applications: pendingApplications,
                    approvalStatus,
                    assignee,
                    deadline: deadline ? moment.tz(deadline, location.tzid).format('YYYY-MM-DDTHH:mm:ss') : null,
                    description,
                    dueDate: moment.tz(dueDate, location.tzid).format('YYYY-MM-DDTHH:mm:ss'),
                    id,
                    location,
                    needsApproval: subscription.needs_approval,
                    needsPublicWorkforce: subscription.needs_public_workforce,
                    organizationId,
                    paused: isPaused,
                    price,
                    scheduledDate: calendarEvent.start ? moment.tz(calendarEvent.start, location.tzid).format('YYYY-MM-DDTHH:mm:ss') : null,
                    startDate: moment.tz(startDate, location.tzid).format('YYYY-MM-DDTHH:mm:ss'),
                    submittedDate: submittedDate ? moment.utc(submittedDate).tz(location.tzid).format('YYYY-MM-DDTHH:mm:ss') : null,
                    status,
                    tags,
                    timeEstimate,
                    title,
                };
            })
    )
);

export const parseSearchParams = createSelector(
    (state: RootState, props: any): string => {
        const { location } = props;
        return location.search;
    },
    (search: string): SearchParams => qs.parse(search, { ignoreQueryPrefix: true })
);

const getOrgIdFromPath = (state: RootState, props: any): string => {
    const { match } = props;
    return match.params.orgId || '';
};

const getMatchParams = createSelector(
    getOrgIdFromPath,
    (orgId: string): PathParams => ({ orgId }),
);

export const getAPIParams = createSelector(
    parseSearchParams,
    getMatchParams,
    (state: RootState) => state.session.user,
    (search: SearchParams, path: PathParams, user: ?Object): APIParams => {
        const { order, page, q, size, sort, ...filters } = search;
        const offset = Math.max(parseInt(page, 10) - 1, 0) || 0;
        const limit = Math.max(parseInt(size, 10), 0) || 10;
        const defaultSort = [{ title: order || 'asc' }];
        const apiFilters = [];

        if (user && user.role === 'PLATFORM_ADMIN') {
            if (filters.organization) {
                apiFilters.push({
                    key: 'organization_id',
                    value: filters.organization.map((id: string): number => parseInt(id, 10)),
                });
            }
        } else {
            apiFilters.push({
                key: 'organization_id',
                value: parseInt(path.orgId, 10),
            });
        }

        if (filters.status) {
            apiFilters.push({
                key: 'ticket_status',
                value: filters.status,
            });
        }

        if (filters.approval) {
            apiFilters.push({
                key: 'approval_status',
                value: filters.approval,
            });
        }

        if (filters.applicants && filters.applicants === 'true') {
            apiFilters.push({
                key: 'applications.status',
                value: 'PENDING',
            });
        }

        if (filters.group) {
            apiFilters.push({
                key: 'group_id',
                value: filters.group.map((id: string): number => parseInt(id, 10)),
            });
        }

        if (filters.dueDate) {
            const { from, to } = filters.dueDate;
            apiFilters.push({
                filter_type: 'range',
                key: 'due_date',
                value: [
                    from ? moment(from).startOf('day').format('YYYY-MM-DDTHH:mm:ss') : null,
                    to ? moment(to).endOf('day').format('YYYY-MM-DDTHH:mm:ss') : null,
                ],
            });
        }

        if (filters.subscription) {
            apiFilters.push({
                key: 'organization_subscription_id',
                value: filters.subscription.map((id: string): number => parseInt(id, 10)),
            });
        }

        if (filters.assignee) {
            apiFilters.push({
                key: 'assigned_customer_id',
                value: filters.assignee.map((id: string): number => parseInt(id, 10)),
            });
        }

        if (filters.geo) {
            // value must be a bounding box [topLeftLat, topLeftLong, bottomRightLat, bottomRightLong]
            const { lat, lng, r } = filters.geo;
            const center = new GeoPoint(parseFloat(lat), parseFloat(lng));
            const [sw, ne] = center.boundingCoordinates(parseInt(r, 10), null, true);
            const boundingBox = [
                ...[ne.latitude(), sw.longitude()],
                ...[sw.latitude(), ne.longitude()],
            ];

            apiFilters.push({
                filter_type: 'viewbox',
                key: 'location_geopoint',
                value: boundingBox,
            });
        }

        if (filters.location) {
            if (filters.location.length > 1) {
                apiFilters.push({
                    filter_type: 'or',
                    filters: filters.location.reduce((locationFilters: Object[], location: Object): Object[] => {
                        const [specificity, value] = Object.entries(location)[0];
                        const filter = specificity === 'name'
                            ? { key: 'location_title', value }
                            : { filter_type: 'address', key: specificity, value };

                        return [...locationFilters, filter];
                    }, []),
                });
            } else {
                const location = filters.location[0];
                const [specificity, value] = Object.entries(location)[0];
                const filter = specificity === 'name'
                    ? { key: 'location_title', value }
                    : { filter_type: 'address', key: specificity, value };

                apiFilters.push(filter);
            }
        }

        if (filters.scheduledDate) {
            const { from, to } = filters.scheduledDate;
            apiFilters.push({
                filter_type: 'range',
                key: 'calendar_event_start',
                value: [
                    from ? moment(from).startOf('day').format('YYYY-MM-DDTHH:mm:ss') : null,
                    to ? moment(to).endOf('day').format('YYYY-MM-DDTHH:mm:ss') : null,
                ],
            });
        }

        if (filters.tag) {
            apiFilters.push({
                key: 'tag_names',
                value: filters.tag,
            });
        }

        return {
            filters: apiFilters,
            keywords: q,
            sort: (sort && sort in apiParams) ? [{ [apiParams[sort]]: order || 'asc' }] : defaultSort,
            limit,
            offset,
        };
    }
);

const ticketActions = Object.freeze([
    { name: 'assign', icon: 'assignment_ind' },
    { name: 'cancel', icon: 'cancel' },
    { name: 'extend', icon: 'forward' },
    { name: 'optIn', icon: 'assignment_ind' },
    { name: 'reopen', icon: 'assignment_return' },
    { name: 'reschedule', icon: 'access_time' },
    { name: 'schedule', icon: 'access_time' },
    { name: 'unassign', icon: 'remove_circle' },
]);

export const getTicketActions = createSelector(
    isWorker,
    isOperator,
    isAdmin,
    (userIsWorker: boolean, userIsOperator: boolean, userIsAdmin: boolean): Object[] => {
        if (userIsAdmin) {
            return ticketActions.filter((action: Object) => ['assign', 'cancel', 'extend', 'reopen', 'reschedule', 'unassign'].includes(action.name));
        } else if (userIsOperator) {
            return ticketActions.filter((action: Object) => ['assign', 'reopen'].includes(action.name));
        } else if (userIsWorker) {
            return ticketActions.filter((action: Object) => ['optIn', 'schedule', 'unassign'].includes(action.name));
        }

        return [];
    }
);

export const getActiveFilters = createSelector(
    createEntitySelector('organizations', createSelector(parseSearchParams, (search: SearchParams) => search.organization)),
    createEntitySelector('subscriptions', createSelector(parseSearchParams, (search: SearchParams) => search.subscription)),
    createEntitySelector('customers', createSelector(parseSearchParams, (search: SearchParams) => search.assignee)),
    createSelector(parseSearchParams, (search: SearchParams) => search.tag),
    createSelector(parseSearchParams, (search: SearchParams) => search.applicants),
    createSelector(parseSearchParams, (search: SearchParams) => search.status),
    createSelector(parseSearchParams, (search: SearchParams) => search.approval),
    createSelector(
        createSelector(parseSearchParams, (search: SearchParams) => search.dueDate),
        (filter: ?Object): ?[?moment$Moment, ?moment$Moment] => (
            filter
                ? [
                    filter.from ? moment(filter.from) : null,
                    filter.to ? moment(filter.to) : null,
                ]
                : undefined
        ),
    ),
    createSelector(
        createSelector(parseSearchParams, (search: SearchParams) => search.group),
        (state: RootState) => (state.session.user ? state.session.user.group_memberships || [] : []),
        (filter: ?string[], groupMemberships: Object[]): ?Object[] => (
            filter
                ? (
                    filter.reduce((acc: Object[], value: string): Object[] => {
                        const membership = groupMemberships.find((m) => m.group_id === parseInt(value, 10));
                        if (membership) {
                            acc.push({
                                id: membership.group_id,
                                name: membership.group,
                            });
                        }
                        return acc;
                    }, [])
                )
                : undefined
        )
    ),
    createSelector(
        createSelector(parseSearchParams, (search: SearchParams) => search.scheduledDate),
        (filter: ?Object): ?[?moment$Moment, ?moment$Moment] => (
            filter
                ? [
                    filter.from ? moment(filter.from) : null,
                    filter.to ? moment(filter.to) : null,
                ]
                : undefined
        ),
    ),
    createSelector(
        createSelector(parseSearchParams, (search: SearchParams) => search.location),
        createSelector((state: RootState) => state.googleMaps.places, (places: Object) => Object.values(places)),
        // $FlowFixMe - places is actually mixed[]
        (filter: ?Object[], places: Object[]): ?(string | Object)[] => (
            filter
                ? (
                    filter.reduce((acc: Object[], location: Object) => {
                        const [specificity, value] = Object.entries(location)[0];

                        if (specificity === 'name') {
                            // $FlowFixMe - value is mixed and incompatible with return type
                            return [...acc, value];
                        }

                        const place = places.find((p) => p.formatted_address === value);
                        if (place != null) {
                            return [...acc, place];
                        }

                        return acc;
                    }, [])
                )
                : undefined
        ),
    ),
    (...args: any[]): ?Object => {
        const [organization, subscription, assignee, tag, applicants, status, approval, group, scheduledDate, dueDate, location] = args;
        const filters = {
            applicants,
            approval,
            assignee,
            dueDate,
            group,
            location,
            organization,
            status,
            scheduledDate,
            subscription,
            tag,
        };
        return Object.entries(filters)
            .reduce((acc: Object, [key, value]: [string, mixed]) => (
                value !== undefined ? { ...acc, [key]: value } : acc
            ), {});
    }
);

export const getActiveFilterCount = createSelector(
    parseSearchParams,
    (search: Object): number => (
        Object.entries(search).reduce((cnt: number, [key, filter]: [string, mixed]): number => {
            if (['q', 'page', 'size', 'sort', 'order'].includes(key)) {
                return cnt;
            }
            return Array.isArray(filter) ? cnt + filter.length : cnt + 1;
        }, 0)
    )
);

export const getDefaultCenter = createSelector(
    getTableData,
    (data: Object[]): LatLng => (
        data.reduce((sum: LatLng, row: Object, index: number): LatLng => {
            const { location } = row;
            const latSum = sum.lat + location.latitude;
            const lngSum = sum.lng + location.longitude;

            return index === data.length - 1
                ? { lat: latSum / data.length, lng: lngSum / data.length }
                : { lat: latSum, lng: lngSum };
        }, { lat: 0, lng: 0 })
    )
);

export default {
    getActiveFilterCount,
    getActiveFilters,
    getAPIParams,
    getDefaultCenter,
    getSelectedTickets,
    getTableData,
    getTicketActions,
    parseSearchParams,
};
