// @flow
import React from 'react';
import BodyClassName from 'react-body-classname';
import * as yup from 'yup';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import type { Dispatch } from 'redux';
import type { Connector } from 'react-redux';
import type { ContextRouter } from 'react-router';
import EmailSent from './components/EmailSent';
import SignupForm from './components/SignupForm';
import MarketingHero from './components/MarketingHero';
import Header from './components/Header';
import Footer from './components/Footer';
import actions from './duck/actions';

type OwnProps = ContextRouter & {};
type DispatchProps = {
    submit: (values: Object) => Promise<void>,
}
type Props = OwnProps & DispatchProps;

const SignupSchema = yup.object().shape({
    companyName: yup.string()
        .required('validation.required'),
    email: yup.string()
        .email('validation.invalidEmail')
        .required('validation.required'),
    firstName: yup.string()
        .required('validation.required'),
    industry: yup.string()
        .required('validation.required'),
    lastName: yup.string()
        .required('validation.required'),
    termsOfService: yup.boolean()
        .oneOf([true], 'signup.validation.terms'),
});

const initialValues = {
    companyName: '',
    email: '',
    firstName: '',
    industry: '',
    lastName: '',
    termsOfService: false,
};

export function Signup(props: Props) {
    const { submit } = props;
    const { t } = useTranslation();

    return (
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setStatus }) => (
              submit(values)
                  .then(() => setStatus({ success: true }))
                  .catch((error: string) => {
                      setStatus({ error });
                      return Promise.reject(error);
                  })
          )}
          validationSchema={SignupSchema}
        >
            {(formikProps: Object) => {
                const { status, values } = formikProps;
                return (
                    <main>
                        <BodyClassName className="marketing-signup" />
                        <Helmet title={t('signup.title')} />
                        <Header />
                        <MarketingHero>
                            {status && status.success
                                ? <EmailSent email={values.email} />
                                : <SignupForm {...formikProps} />}
                        </MarketingHero>
                        <Footer />
                    </main>
                );
            }}
        </Formik>
    );
}

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    submit: (values: Object) => dispatch(actions.submit(values)),
});
const connector: Connector<OwnProps, Props> = connect(null, mapDispatchToProps);
export default connector(Signup);
