// @flow
import safe from 'safe-regex';
import uniq from 'lodash/uniq';

export type GWError = {
    severity_level: number,
    severity: string,
    message: string,
    error_code: string,
    classname: string,
    error_args: Array<Object>,
};

function _stringReplace(input: string = '', replace: { [key: string]: string } = {}): string {
    return Object.entries(replace)
        .reduce((result: string, [key, value]: [string, mixed]): string => {
            if (typeof value === 'string') {
                const regex = new RegExp(`${key}`, 'g');
                return safe(regex) ? result.replace(regex, value) : result;
            }
            return result;
        }, `${input}`);
}

export default function formatAPIError(apiErrorResponse: Array<GWError>): string {
    // Example
    // apiErrorResponse = [
    //    {
    //        severity_level: 2,
    //        severity: 'minor',
    //        message: 'You cannot add an existing user {email} into {organization_name}.',
    //        error_code: '0x00000002',
    //        classname: 'DefaultErrors',
    //        error_args:[
    //            { email: 'email1@test.com', organization_name: 'Gigwalk' },
    //            { email: 'email2@test.com', organization_name: 'Gigwalk' }
    //        ]
    //    },
    //     {
    //         severity_level: 2,
    //         severity: 'minor',
    //         message: 'ticket status is currently none and cannot be started',
    //         error_code: '0x00010004',
    //         classname: 'TicketErrors',
    //         error_args:[]
    //     }
    // ];

    const fallBackMsgs = apiErrorResponse.map((gwError: GWError) => {
        let replaced = gwError.message;
        const groupedErrorsArg = {};

        gwError.error_args.forEach((errorArgs: Object) => {
            // check type because some error_args are [[{}]] instead or [{}]
            // BE needs to stick to the defined schema
            if (!Array.isArray(errorArgs)) {
                Object.keys(errorArgs).forEach((errorArgKey: string) => {
                    if (errorArgKey && typeof errorArgKey === 'string') {
                        groupedErrorsArg[errorArgKey] = groupedErrorsArg[errorArgKey] || [];
                        groupedErrorsArg[errorArgKey].push(errorArgs[errorArgKey]);
                    }
                });
            }
        });

        Object.keys(groupedErrorsArg).forEach((arg: string) => {
            const list = uniq(groupedErrorsArg[arg]);
            if (list.length > 2) {
                const message = `${list.slice(0, list.length - 1).join(', ')}, and ${list[list.length - 1]}`;
                replaced = _stringReplace(replaced, { [`{${arg}}`]: message });
            } else {
                replaced = _stringReplace(replaced, { [`{${arg}}`]: list.join(' and ') });
            }
        });

        return replaced;
    });

    // create the fallBack message that will be used if there is no localized error available for this error code
    return fallBackMsgs.join('<br>');
}
