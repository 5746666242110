// @flow
// $FlowIssue need to update to a more recent flow version
import React, { Fragment, useCallback } from 'react';
import moment from 'moment';
import qs from 'qs';
import stringify from 'json-stable-stringify';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import {
    Checkbox,
    Chip,
    Drawer,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    IconButton,
    ListItemIcon,
    ListItemText,
    Switch,
    Toolbar,
    Tooltip,
} from '@material-ui/core';
import {
    Close as CloseIcon,
    ClearAll as ClearAllIcon,
    Place as PlaceIcon,
    Search as SearchIcon,
} from '@material-ui/icons';
import type { Dispatch } from 'redux';
import type { Connector } from 'react-redux';
import type { ContextRouter } from 'react-router';
import highlightMatch from '../../../../util/highlightMatch';
import { STATE } from '../../../../../browser/shared/constant/ProjectConstant';
import { USER_ROLES } from '../../../../../browser/shared/constant/UserRoles';
import * as googleMaps from '../../../../ducks/googleMaps';
import { search as searchOrganizations } from '../../../../redux/entities/organizations';
import { search as searchSubscriptions } from '../../../../redux/entities/subscriptions';
import Select from '../../../../components/Select';
import DateRangePicker from '../../../../components/DateRangePicker';
import { actions, selectors } from './duck';
import styles from './styles';
import type { State as RootState } from '../../../../redux/initialState';

type OwnProps = ContextRouter & {};
type StateProps = {
    approvalStatusFilterOptions: Object[],
    approvalStatusFilterValue: Object[],
    dueDateFilterValue: {
        startDate: ?moment$Moment,
        endDate: ?moment$Moment,
    },
    groupFilterOptions: Object[],
    groupFilterValue: Object[],
    locationFilterValue: Object[],
    open: boolean,
    organizationFilterValue: Object[],
    search: {
        applicants?: string,
        approval?: string[],
        assignee?: string[],
        dueDate?: {
            from?: string,
            to?: string,
        },
        group?: string[],
        organization?: string[],
        status?: string[],
        subscription?: string[],
        tag?: string[],
    },
    statusFilterOptions: Object[],
    statusFilterValue: Object[],
    subscriptionFilterValue: Object[],
    user: ?Object,
};
type DispatchProps = {
    getPlaceDetails: (params: Object) => Promise<Object>,
    getPlacePredictions: (params: Object) => Promise<Object[]>,
    searchOrganizations: (params: Object) => Promise<Object>,
    searchSubscriptions: (params: Object) => Promise<Object>,
    toggleFilterDrawer: (open: boolean) => void,
};
type Props = OwnProps & StateProps & DispatchProps;

// @todo Move to a utils folder
const getOptionValue = (option: Object): string => {
    let { value } = option;
    if (typeof value === 'object') {
        value = value.hasOwnProperty('id') ? value.id : value;
        value = value.hasOwnProperty('place_id') ? value.place_id : value;
    }
    return typeof value === 'string' ? value : stringify(value);
};

// @todo Move to a utils folder
const isOptionSelected = (option: Object, value: Object[]): boolean => {
    if (value.indexOf(option) > -1) return true;
    const candidate = getOptionValue(option);
    return value.some((i: Object) => getOptionValue(i) === candidate);
};

const formatOptionLabel = (option: Object, meta: Object) => {
    const { value } = option;

    if (typeof value === 'string') {
        return (
            <Fragment>
                <ListItemIcon>
                    <SearchIcon />
                </ListItemIcon>
                <ListItemText primary={`Name matches '${value}'`} />
            </Fragment>
        );
    }

    const primary = highlightMatch(value.structured_formatting.main_text, meta.inputValue);
    const secondary = highlightMatch(value.structured_formatting.secondary_text || '', meta.inputValue);
    return (
        <Fragment>
            <ListItemIcon>
                <PlaceIcon />
            </ListItemIcon>
            <ListItemText primary={primary} secondary={secondary} />
        </Fragment>
    );
};

const noFilters = {
    applicants: undefined,
    approval: undefined,
    assignee: undefined,
    dueDate: undefined,
    geo: undefined,
    group: undefined,
    location: undefined,
    organization: undefined,
    status: undefined,
    scheduledDate: undefined,
    subscription: undefined,
    tag: undefined,
};

// See https://developers.google.com/maps/documentation/geocoding/intro#Types for more info
const whitelistedTypes = [
    'street_address', // indicates a precise street address
    'country', // indicates the national political entity, and is typically the highest order type returned by the geocoder
    'administrative_area_level_1', // indicates a first-order civil entity below the country level. Within the United States, these are states
    'locality', // indicates an incorporated city or town political entity
    'premise', // indicates a named location, usually a building or collection of buildings with a common name
    'subpremise', // indicates a first-order entity below a named location, usually a singular building within a collection of buildings with a common name
    'postal_code', // indicates a postal code as used to address postal mail within the country
];

const useStyles = makeStyles(styles, { name: 'FilterDrawer' });

export function FilterDrawer(props: Props) {
    const {
        approvalStatusFilterOptions,
        approvalStatusFilterValue,
        dueDateFilterValue,
        getPlaceDetails,
        getPlacePredictions,
        groupFilterOptions,
        groupFilterValue,
        history,
        locationFilterValue,
        location,
        organizationFilterValue,
        open,
        search,
        searchOrganizations: _searchOrganizations,
        searchSubscriptions: _searchSubscriptions,
        statusFilterOptions,
        statusFilterValue,
        subscriptionFilterValue,
        toggleFilterDrawer,
        user,
    } = props;

    const classes = useStyles(props);
    const { t } = useTranslation();

    const loadSubscriptionFilterOptions = useCallback((inputValue: string) => {
        const params = {
            filters: [{ key: 'state', value: [STATE.ACTIVE, STATE.CANCELED, STATE.ARCHIVED] }],
            limit: 20,
            offset: 0,
            sort: [{ title: 'asc' }],
        };

        if (user && user.role !== USER_ROLES.PLATFORM_ADMIN) {
            const orgId = user.organization.id;
            params.filters.push({ key: 'organization_id', value: orgId });
        }

        if (inputValue) {
            params.filters.push({ key: 'title', value: `${inputValue}` });
        }

        return _searchSubscriptions(params)
            .then((resp) => {
                // @todo: Consider using denormalize to get denormalized entity
                const { entities, result } = resp;
                return result.map((id: number) => ({
                    label: entities.subscriptions[id].title,
                    value: entities.subscriptions[id],
                }));
            });
    }, [_searchSubscriptions, user]);

    const loadOrganizationFilterOptions = useCallback((inputValue: string) => {
        const params = {
            q: inputValue ? `*${inputValue}*` : null,
            from: 0,
            size: 20,
            sort_field: 'organization_name',
            sort_order: 'asc',
        };

        return _searchOrganizations(params)
            .then((resp) => {
                const { entities, result } = resp;
                // @todo: Consider using denormalize to get denormalized entity
                return result.map((id: number) => ({
                    label: entities.organizations[id].organization_name,
                    value: entities.organizations[id],
                }));
            });
    }, [_searchOrganizations]);

    const loadLocationFilterOptions = useCallback((inputValue: string): Promise<Object[]> => (
        getPlacePredictions({ input: inputValue, types: ['geocode'] })
            .then((predictions: Object[]): Object[] => (
                predictions
                    .filter((prediction: Object): Object => (
                        prediction.types.some((type: string) => (
                            whitelistedTypes.includes(type)
                        ))
                    ))
                    .map((prediction: Object): Object => ({
                        label: prediction.structured_formatting.main_text,
                        value: prediction,
                    }))
            ))
            .then((options: Object[]) => {
                if (inputValue) {
                    return [
                        { label: inputValue, value: inputValue },
                        ...options,
                    ];
                }
                return options;
            })
    ), [getPlacePredictions]);

    const updateFilters = useCallback((filters: Object) => {
        history.replace({
            pathname: location.pathname,
            search: qs.stringify(
                { ...search, ...filters },
                { addQueryPrefix: true, encodeValuesOnly: true },
            ),
        });
    }, [history, location.pathname, search]);

    const handleClear = useCallback(() => {
        updateFilters(noFilters);
    }, [updateFilters]);

    const handleOrganizationFilterChange = useCallback((value: Object[]) => {
        const organizationFilters = value.map((option: Object) => option.value.id);
        updateFilters({ organization: organizationFilters });
    }, [updateFilters]);

    const handleCheckboxChange = useCallback((event: SyntheticInputEvent<HTMLInputElement>) => {
        const { checked, name, value } = event.target;
        let filterValue;

        switch (name) {
            case 'status':
                filterValue = statusFilterValue;
                break;
            case 'approval':
                filterValue = approvalStatusFilterValue;
                break;
            default:
                return;
        }

        const index = filterValue.findIndex((option: Object) => (
            getOptionValue(option) === value
        ));

        if (checked && index === -1) {
            const filter = [
                ...filterValue.map((option: Object) => getOptionValue(option)),
                value,
            ];
            updateFilters({ [name]: filter });
        } else if (!checked && index >= 0) {
            const filter = filterValue
                .reduce((acc: string[], option: Object): string[] => {
                    const optionValue = getOptionValue(option);
                    if (optionValue !== value) return [...acc, optionValue];
                    return acc;
                }, []);
            updateFilters({ [name]: filter });
        }
    }, [approvalStatusFilterValue, statusFilterValue, updateFilters]);

    const handleSwitchChange = useCallback((event: SyntheticInputEvent<HTMLInputElement>) => {
        const { checked, name } = event.target;
        updateFilters({ [name]: checked || undefined });
    }, [updateFilters]);

    const handleLocationFilterChange = useCallback((value: Object[], meta: Object) => {
        const { action, option } = meta;

        const typeReducer = (key: string, type: string) => {
            if (type === 'street_address' || type === 'premise' || type === 'subpremise') return 'address';
            if (type === 'postal_code') return 'zip';
            if (type === 'locality') return 'city';
            if (type === 'administrative_area_level_1') return 'state';
            if (type === 'country') return 'country';
            return key;
        };

        Promise.resolve()
            .then(() => {
                const locationFilters = locationFilterValue.map((opt: Object) => {
                    const place = opt.value;

                    if (typeof place === 'string') {
                        return { name: place };
                    }

                    const filterKey = place.types.reduce(typeReducer, '');
                    return { [filterKey]: place.formatted_address };
                });

                if (action === 'select-option') {
                    if (typeof option.value === 'string') {
                        locationFilters.push({ name: option.value });
                    } else {
                        const params = { placeId: option.value.place_id };
                        return getPlaceDetails(params)
                            .then((selectedPlace: ?Object) => {
                                if (selectedPlace) {
                                    const filterKey = selectedPlace.types.reduce(typeReducer, '');
                                    const filter = { [filterKey]: selectedPlace.formatted_address };
                                    locationFilters.push(filter);
                                }

                                return locationFilters;
                            });
                    }
                }

                return locationFilters;
            })
            .then((locationFilters: Object[]) => {
                updateFilters({ location: locationFilters });
            });
    }, [getPlaceDetails, locationFilterValue, updateFilters]);

    const handleGroupFilterChange = useCallback((value: Object[]) => {
        const groupFilters = value.map((option: Object) => option.value);
        updateFilters({ group: groupFilters });
    }, [updateFilters]);

    const handleSubscriptionFilterChange = useCallback((value: Object[]) => {
        const subscriptionFilters = value.map((option: Object) => option.value.id);
        updateFilters({ subscription: subscriptionFilters });
    }, [updateFilters]);

    const handleDateFilterChange = useCallback((value: Object) => {
        const { endDate, startDate } = value;
        const dueDateFilter = {};

        if (startDate) dueDateFilter.from = moment(startDate).format('YYYY-MM-DD');
        if (endDate) dueDateFilter.to = moment(endDate).format('YYYY-MM-DD');

        updateFilters({ dueDate: dueDateFilter });
    }, [updateFilters]);

    const handleChipDelete = useCallback((event: SyntheticEvent<any>) => {
        const { name, value } = event.currentTarget.parentElement.dataset;
        let filterValue;

        switch (name) {
            case 'group':
                filterValue = groupFilterValue;
                break;
            case 'location':
                filterValue = locationFilterValue;
                break;
            case 'organization':
                filterValue = organizationFilterValue;
                break;
            case 'subscription':
                filterValue = subscriptionFilterValue;
                break;
            default:
                return;
        }

        const index = filterValue.findIndex((option: Object) => (
            getOptionValue(option) === value
        ));

        if (index >= 0) {
            const filter = search[name].filter((el, i) => i !== index);
            updateFilters({ [name]: filter });
        }
    }, [groupFilterValue, locationFilterValue, organizationFilterValue, search, subscriptionFilterValue, updateFilters]);

    const handleDrawerClose = useCallback(() => {
        toggleFilterDrawer(false);
    }, [toggleFilterDrawer]);

    return (
        <Drawer
          anchor="right"
          classes={{ paper: classes.paper }}
          onClose={handleDrawerClose}
          open={open}
        >
            <Toolbar>
                <div className={classes.title}>
                    {t('ticketList.filters.filterGigs')}
                </div>
                <div className={classes.actions}>
                    <Tooltip title={t('ticketList.filters.clearFilters')}>
                        <IconButton onClick={handleClear}>
                            <ClearAllIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('ticketList.filters.close')}>
                        <IconButton onClick={handleDrawerClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </div>
            </Toolbar>
            <div className={classes.filters}>
                {user && user.role === USER_ROLES.PLATFORM_ADMIN
                    ? (
                        <FormControl className={classes.organizationFilter}>
                            <FormLabel>{t('ticketList.filters.organization')}</FormLabel>
                            <Select
                              ControlProps={{
                                  fullWidth: true,
                                  margin: 'dense',
                                  variant: 'outlined',
                              }}
                              controlShouldRenderValue={false}
                              defaultOptions
                              dropdownIcon={<SearchIcon />}
                              isMulti
                              loadOptions={loadOrganizationFilterOptions}
                              menuPlacement="auto"
                              onChange={handleOrganizationFilterChange}
                              placeholder="Search organizations..."
                              showSelectedOptions={false}
                              value={organizationFilterValue}
                            />
                            <div className={classes.chipArray}>
                                {organizationFilterValue.map((option: Object) => (
                                    <Chip
                                      classes={{
                                          root: classes.chip,
                                          label: classes.chipLabel,
                                      }}
                                      color="primary"
                                      data-name="organization"
                                      data-value={getOptionValue(option)}
                                      key={getOptionValue(option)}
                                      label={option.label}
                                      onDelete={handleChipDelete}
                                    />
                                ))}
                            </div>
                        </FormControl>
                    )
                    : null}
                <FormControl className={classes.applicantsFilter}>
                    <FormLabel>{t('ticketList.filters.applicants')}</FormLabel>
                    <FormGroup className={classes.filterOptions}>
                        <FormControlLabel
                          control={(
                              <Switch
                                checked={search.applicants === 'true'}
                                name="applicants"
                                onChange={handleSwitchChange}
                              />
                          )}
                          label={t('ticketList.filters.hasApplicants')}
                        />
                    </FormGroup>
                </FormControl>
                <FormControl className={classes.statusFilter}>
                    <FormLabel>{t('ticketList.filters.status')}</FormLabel>
                    <FormGroup className={classes.filterOptions}>
                        {statusFilterOptions.map((option) => (
                            <FormControlLabel
                              className={classes.checkboxContainer}
                              control={(
                                  <Checkbox
                                    checked={isOptionSelected(option, statusFilterValue)}
                                    className={classes.checkbox}
                                    name="status"
                                    onChange={handleCheckboxChange}
                                    value={getOptionValue(option)}
                                  />
                              )}
                              key={getOptionValue(option)}
                              label={option.label}
                            />
                        ))}
                    </FormGroup>
                </FormControl>
                <FormControl className={classes.approvalStatusFilter}>
                    <FormLabel>{t('ticketList.filters.approvalStatus')}</FormLabel>
                    <FormGroup className={classes.filterOptions}>
                        {approvalStatusFilterOptions.map((option) => (
                            <FormControlLabel
                              className={classes.checkboxContainer}
                              control={(
                                  <Checkbox
                                    checked={isOptionSelected(option, approvalStatusFilterValue)}
                                    className={classes.checkbox}
                                    name="approval"
                                    onChange={handleCheckboxChange}
                                    value={getOptionValue(option)}
                                  />
                              )}
                              key={getOptionValue(option)}
                              label={option.label}
                            />
                        ))}
                    </FormGroup>
                </FormControl>
                <FormControl className={classes.groupFilter}>
                    <FormLabel>{t('ticketList.filters.group')}</FormLabel>
                    <Select
                      ControlProps={{
                          fullWidth: true,
                          margin: 'dense',
                          variant: 'outlined',
                      }}
                      controlShouldRenderValue={false}
                      dropdownIcon={<SearchIcon />}
                      isMulti
                      menuPlacement="auto"
                      onChange={handleGroupFilterChange}
                      options={groupFilterOptions}
                      placeholder="Search groups..."
                      showSelectedOptions={false}
                      value={groupFilterValue}
                    />
                    <div className={classes.chipArray}>
                        {groupFilterValue.map((option: Object) => (
                            <Chip
                              classes={{
                                  root: classes.chip,
                                  label: classes.chipLabel,
                              }}
                              color="primary"
                              data-name="group"
                              data-value={getOptionValue(option)}
                              key={getOptionValue(option)}
                              label={option.label}
                              onDelete={handleChipDelete}
                            />
                        ))}
                    </div>
                </FormControl>
                <FormControl className={classes.subscriptionFilter}>
                    <FormLabel>{t('ticketList.filters.project')}</FormLabel>
                    <Select
                      ControlProps={{
                          fullWidth: true,
                          margin: 'dense',
                          variant: 'outlined',
                      }}
                      controlShouldRenderValue={false}
                      defaultOptions
                      dropdownIcon={<SearchIcon />}
                      isMulti
                      loadOptions={loadSubscriptionFilterOptions}
                      menuPlacement="auto"
                      onChange={handleSubscriptionFilterChange}
                      placeholder="Search projects..."
                      showSelectedOptions={false}
                      value={subscriptionFilterValue}
                    />
                    <div className={classes.chipArray}>
                        {subscriptionFilterValue.map((option: Object) => (
                            <Chip
                              classes={{
                                  root: classes.chip,
                                  label: classes.chipLabel,
                              }}
                              color="primary"
                              data-name="subscription"
                              data-value={getOptionValue(option)}
                              key={getOptionValue(option)}
                              label={option.label}
                              onDelete={handleChipDelete}
                            />
                        ))}
                    </div>
                </FormControl>
                <FormControl className={classes.locationFilter}>
                    <FormLabel>{t('ticketList.filters.location')}</FormLabel>
                    <Select
                      ControlProps={{
                          fullWidth: true,
                          margin: 'dense',
                          variant: 'outlined',
                      }}
                      controlShouldRenderValue={false}
                      dropdownIcon={<SearchIcon />}
                      formatOptionLabel={formatOptionLabel}
                      isMulti
                      loadOptions={loadLocationFilterOptions}
                      menuPlacement="auto"
                      onChange={handleLocationFilterChange}
                      placeholder={t('Search locations...')}
                      showSelectedOptions={false}
                      value={locationFilterValue}
                    />
                    <div className={classes.chipArray}>
                        {locationFilterValue.map((option: Object) => (
                            <Chip
                              classes={{
                                  root: classes.chip,
                                  label: classes.chipLabel,
                              }}
                              color="primary"
                              data-name="location"
                              data-value={getOptionValue(option)}
                              key={getOptionValue(option)}
                              label={option.label}
                              onDelete={handleChipDelete}
                            />
                        ))}
                    </div>
                </FormControl>
                <FormControl className={classes.dueDateFilter}>
                    <FormLabel>{t('ticketList.filters.dueDate')}</FormLabel>
                    <DateRangePicker
                      startDatePlaceholder={t('ticketList.filters.any')}
                      endDatePlaceholder={t('ticketList.filters.any')}
                      onChange={handleDateFilterChange}
                      value={dueDateFilterValue}
                      showClearDates
                    />
                </FormControl>
            </div>
        </Drawer>
    );
}

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => ({
    approvalStatusFilterOptions: selectors.getApprovalStatusFilterOptions(),
    approvalStatusFilterValue: selectors.getApprovalStatusFilterValue(state, props),
    dueDateFilterValue: selectors.getDueDateFilterValue(state, props),
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    groupFilterOptions: selectors.getGroupFilterOptions(state, props),
    groupFilterValue: selectors.getGroupFilterValue(state, props),
    locationFilterValue: selectors.getLocationFilterValue(state, props),
    open: state.ticketList.drawerOpen,
    organizationFilterValue: selectors.getOrganizationFilterValue(state, props),
    search: selectors.parseSearchParams(state, props),
    statusFilterOptions: selectors.getStatusFilterOptions(),
    statusFilterValue: selectors.getStatusFilterValue(state, props),
    subscriptionFilterValue: selectors.getSubscriptionFilterValue(state, props),
    user: state.session.user,
});

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    getPlaceDetails: (params: Object) => dispatch(googleMaps.actions.getPlaceDetails(params)),
    getPlacePredictions: (params: Object) => dispatch(googleMaps.actions.getPlacePredictions(params)),
    searchOrganizations: (params: Object) => dispatch(searchOrganizations(params)),
    searchSubscriptions: (params: Object) => dispatch(searchSubscriptions(params)),
    toggleFilterDrawer: (open: boolean) => dispatch(actions.toggleFilterDrawer(open)),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
export default connector(FilterDrawer);
