// @flow
import { darken } from '@material-ui/core';

export default (theme: Object) => ({
    root: {},
    colorDefault: {
        color: theme.palette.getContrastText(theme.palette.common.white),
        backgroundColor: theme.palette.common.white,

        '&:hover': {
            color: theme.palette.getContrastText(theme.palette.common.white),
            backgroundColor: darken(theme.palette.common.white, 0.1),
        },

        '&$selected': {
            color: theme.palette.getContrastText(theme.palette.grey[300]),
            backgroundColor: theme.palette.grey[300],

            '&:hover': {
                backgroundColor: darken(theme.palette.grey[300], 0.1),
            },
        },
    },
    colorPrimary: {
        color: theme.palette.getContrastText(theme.palette.common.white),
        backgroundColor: theme.palette.common.white,

        '&:hover': {
            color: theme.palette.getContrastText(theme.palette.common.white),
            backgroundColor: darken(theme.palette.common.white, 0.1),
        },

        '&$selected': {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,

            '&:hover': {
                // backgroundColor: theme.palette.grey.A200,
                backgroundColor: darken(theme.palette.primary.main, 0.1),
            },
        },
    },
    colorSecondary: {
        color: theme.palette.getContrastText(theme.palette.common.white),
        backgroundColor: theme.palette.common.white,

        '&:hover': {
            color: theme.palette.getContrastText(theme.palette.common.white),
            backgroundColor: darken(theme.palette.common.white, 0.1),
        },

        '&$selected': {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.secondary.main,

            '&:hover': {
                // backgroundColor: theme.palette.grey.A200,
                backgroundColor: darken(theme.palette.secondary.main, 0.1),
            },
        },
    },
    label: {},
    disabled: {},
    selected: {},
});
