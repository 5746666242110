// @flow
export default (theme: Object) => ({
    dialogPaper: {
        margin: 0,
        maxWidth: 366,
    },
    dialogContent: {
        padding: [theme.spacing(1), '!important'], // https://github.com/mui-org/material-ui/pull/16980
        minWidth: 300,
        minHeight: 200,
    },
    loading: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    error: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    errorIcon: {
        marginRight: 8,
    },
});
