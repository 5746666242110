// @flow
import { createAction } from 'redux-actions';
import identity from 'lodash/identity';
import type { Dispatch } from 'redux';
import logger from '../../../../../util/logger';
import {
    list as listNotifications,
    update as updateNotification,
} from '../../../../../redux/entities/notifications';
import types from './types';

export const checkNotificationsSuccess = createAction(types.CHECK_NOTIFICATIONS_SUCCESS, identity, (data: Object, meta: Object): Object => meta);
export const checkNotificationsError = createAction(types.CHECK_NOTIFICATIONS_ERROR);
export const checkNotifications = createAction(
    types.CHECK_NOTIFICATIONS,
    () => (
        (dispatch: Dispatch<any>) => {
            const params = {
                sort_field: 'date_created',
                sort_order: 'desc',
                status: 'UNREAD',
            };

            return dispatch(listNotifications(params))
                .then((unread: Object) => {
                    const metadata = { unreadCount: unread.metadata.record_count };
                    const notificationIds = [...unread.result];
                    dispatch(checkNotificationsSuccess(notificationIds, metadata));
                })
                .catch((err) => {
                    logger.error(err);
                    dispatch(checkNotificationsError());
                    return Promise.reject(err);
                });
        }
    )
);

export const markAsReadSuccess = createAction(types.MARK_AS_READ_SUCCESS);
export const markAsReadError = createAction(types.MARK_AS_READ_ERROR);
export const markAsRead = createAction(
    types.MARK_AS_READ,
    (notificationId: number) => (
        (dispatch: Dispatch<any>) => {
            const params = {
                notification_id: notificationId,
                status: 'READ',
            };
            return dispatch(updateNotification(params))
                .then(() => {
                    dispatch(markAsReadSuccess(notificationId));
                })
                .catch((err) => {
                    logger.error(err);
                    dispatch(markAsReadError());
                    return Promise.reject(err);
                });
        }
    )
);


export default {
    checkNotifications,
    checkNotificationsError,
    checkNotificationsSuccess,
    markAsRead,
    markAsReadError,
    markAsReadSuccess,
};
