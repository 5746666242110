// @flow
import { normalize } from 'normalizr';
import { createAction, handleActions } from 'redux-actions';
import blacklist from 'blacklist';
import omitBy from 'lodash/omitBy';
import type { Dispatch } from 'redux';
import type { ActionType } from 'redux-actions';
import type { $AxiosXHR, $AxiosError } from 'axios';
import type { APIResponse, APIPromise } from 'gigwalk/lib/api/resource';
import type {
    CreateSubscriptionsParams,
    GetSubscriptionParams,
    Subscription,
    DeleteSubscriptionParams,
} from 'gigwalk/lib/api/subscriptions/types';
import type {
    RequestProjectReviewParams,
} from 'gigwalk/lib/api/requestProjectReview/types';
import logger from '../../util/logger';
import { client as gigwalk } from '../../api/createGigwalkClient';
import { init } from './initialState';
import { subscription as subscriptionSchema } from './schemas';
import type { State } from './initialState';

// Actions
// -------
export const FETCH = 'g/subscriptions/FETCH';
export const FETCH_SUCCESS = `${FETCH}_SUCCESS`;
export const FETCH_ERROR = `${FETCH}_ERROR`;

export const CREATE = 'g/subscriptions/CREATE';
export const CREATE_SUCCESS = `${CREATE}_SUCCESS`;
export const CREATE_ERROR = `${CREATE}_ERROR`;

export const UPDATE = 'g/subscriptions/UPDATE';
export const UPDATE_SUCCESS = `${UPDATE}_SUCCESS`;
export const UPDATE_ERROR = `${UPDATE}_ERROR`;

export const REQUEST_PROJECT_REVIEW = 'g/subscriptions/REQUEST_PROJECT_REVIEW';
export const REQUEST_PROJECT_REVIEW_SUCCESS = `${REQUEST_PROJECT_REVIEW}_SUCCESS`;
export const REQUEST_PROJECT_REVIEW_ERROR = `${REQUEST_PROJECT_REVIEW}_ERROR`;

export const SEARCH = 'g/subscriptions/SEARCH';
export const SEARCH_SUCCESS = `${SEARCH}_SUCCESS`;
export const SEARCH_ERROR = `${SEARCH}_ERROR`;

export const DELETE_SUBSCRIPTION = 'g/subscriptions/DELETE';
export const DELETE_SUBSCRIPTION_SUCCESS = `${DELETE_SUBSCRIPTION}_SUCCESS`;
export const DELETE_SUBSCRIPTION_ERROR = `${DELETE_SUBSCRIPTION}_ERROR`;


// Action Creators
// ---------------
export const fetchSuccess = createAction(FETCH_SUCCESS);
export const fetchError = createAction(FETCH_ERROR);
export const fetch = createAction(
    FETCH,
    (params: GetSubscriptionParams): Function => (
        (dispatch: Dispatch<any>): APIPromise<[Subscription]> => (
            gigwalk.subscriptions.get(params)
                .then((resp: $AxiosXHR<APIResponse<[Subscription]>>) => {
                    const normalized = normalize(resp.data.data, [subscriptionSchema]);
                    normalized.entities.dataTypes = resp.data._metadata.data_types;
                    dispatch(fetchSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(fetchError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export const createSuccess = createAction(CREATE_SUCCESS);
export const createError = createAction(CREATE_ERROR);
export const create = createAction(
    CREATE,
    (params: CreateSubscriptionsParams) => (
        (dispatch: Dispatch<any>): APIPromise<Subscription[]> => (
            gigwalk.subscriptions.bulkCreate(params)
                .then((resp: $AxiosXHR<APIResponse<Subscription[]>>) => {
                    const normalized = normalize(resp.data.data, [subscriptionSchema]);
                    dispatch(createSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(createError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export const updateSuccess = createAction(UPDATE_SUCCESS);
export const updateError = createAction(UPDATE_ERROR);
export const update = createAction(
    UPDATE,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): APIPromise<[Object]> => (
            gigwalk.subscriptions.update(params)
                .then((resp: $AxiosXHR<APIResponse<[Object]>>) => {
                    const normalized = normalize(resp.data.data, [subscriptionSchema]);
                    normalized.entities.dataTypes = resp.data._metadata.data_types;
                    normalized.metadata = resp.data._metadata;
                    normalized.original = resp.data;
                    dispatch(updateSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(updateError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export const requestProjectReviewSuccess = createAction(REQUEST_PROJECT_REVIEW_SUCCESS);
export const requestProjectReviewError = createAction(REQUEST_PROJECT_REVIEW_ERROR);
export const requestProjectReview = createAction(
    REQUEST_PROJECT_REVIEW,
    (params: RequestProjectReviewParams): Function => (
        (dispatch: Dispatch<any>): APIPromise<void> => (
            gigwalk.requestProjectReview.request(params)
                .then(() => {
                    dispatch(requestProjectReviewSuccess());
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(requestProjectReviewError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export const searchSuccess = createAction(SEARCH_SUCCESS);
export const searchError = createAction(SEARCH_ERROR);
export const search = createAction(
    SEARCH,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): APIPromise<Subscription[]> => {
            const { offset, limit, ...data } = params;
            const config = {
                params: omitBy({ offset, limit }, (value) => value == null),
            };
            return gigwalk.client.post('/v1/search/subscriptions', data, config)
                .then((resp: $AxiosXHR<APIResponse<Subscription[]>>) => {
                    // Note: schema is little different for search, but this should still work
                    const normalized = normalize(resp.data.data, [subscriptionSchema]);
                    normalized.metadata = resp.data._metadata;
                    dispatch(searchSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(searchError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const deleteSubscriptionSuccess = createAction(DELETE_SUBSCRIPTION_SUCCESS);
export const deleteSubscriptionError = createAction(DELETE_SUBSCRIPTION_ERROR);
export const deleteSubscription = createAction(
    DELETE_SUBSCRIPTION,
    (params: DeleteSubscriptionParams): Function => (
        (dispatch: Dispatch<any>): APIPromise<[number]> => (
            gigwalk.subscriptions.delete(params)
                .then(() => {
                    const id = params.organization_subscription_id;
                    const normalized = normalize([id], [{}]);
                    dispatch(deleteSubscriptionSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(deleteSubscriptionError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export default handleActions({
    [DELETE_SUBSCRIPTION_SUCCESS]: (state: State, action: ActionType<typeof deleteSubscriptionSuccess>): State => {
        if (action.payload && Array.isArray(action.payload.result)) {
            const keys = action.payload.result.map((id: number): string => `${id}`);
            return {
                ...state,
                subscriptions: blacklist(state.subscriptions, ...keys),
            };
        }

        return state;
    },
}, init);
