var Handlebars = require('/tmp/build_a9d70681abb25b82ca4489607bd6ccee/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "has-metadata";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<strong>Q"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"questionNumber") || (depth0 != null ? lookupProperty(depth0,"questionNumber") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"questionNumber","hash":{},"data":data,"loc":{"start":{"line":32,"column":47},"end":{"line":32,"column":67}}}) : helper)))
    + "</strong>";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"files\">\n                    <!-- div \"attachment\" uses the same HTML as in Project Builder -->\n                    <div class=\"attachment\">\n                        <div class=\"plainLabel\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"attached_files") || (depth0 != null ? lookupProperty(depth0,"attached_files") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"attached_files","hash":{},"data":data,"loc":{"start":{"line":41,"column":48},"end":{"line":41,"column":68}}}) : helper)))
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"files") : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":24},"end":{"line":47,"column":33}}})) != null ? stack1 : "")
    + "                    </div>\n                    <!-- ends div \"attachment\" -->\n                </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"attached-file\" data-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":43,"column":64},"end":{"line":43,"column":72}}}) : helper)))
    + "\">\n                                <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"url") || (depth0 != null ? lookupProperty(depth0,"url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":44,"column":41},"end":{"line":44,"column":50}}}) : helper)))
    + "\" target=\"_blank\" class=\"filename\" data-action=\"download\"><span class=\"im icon-paperclip\"></span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"file_name") || (depth0 != null ? lookupProperty(depth0,"file_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"file_name","hash":{},"data":data,"loc":{"start":{"line":44,"column":147},"end":{"line":44,"column":162}}}) : helper)))
    + "</a>\n                                <a href=\"javascript:;\" class=\"remove\" data-action=\"remove-attachment\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"remove") || (depth0 != null ? lookupProperty(depth0,"remove") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"remove","hash":{},"data":data,"loc":{"start":{"line":45,"column":102},"end":{"line":45,"column":114}}}) : helper)))
    + "</a>\n                            </div>\n";
},"8":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"answer example\" data-target=\"attachments\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"images") : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":24},"end":{"line":63,"column":33}}})) != null ? stack1 : "")
    + "                    </div>\n";
},"9":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <label class=\"plainLabel\">"
    + alias1(container.lambda((depths[1] != null ? lookupProperty(depths[1],"example") : depths[1]), depth0))
    + "</label>\n\n                            <div class=\"attachment image\">\n                                <img src=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"url") || (depth0 != null ? lookupProperty(depth0,"url") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":60,"column":42},"end":{"line":60,"column":51}}}) : helper)))
    + "\"/>\n                                <span class=\"im icon-magnify\" data-url=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"url") || (depth0 != null ? lookupProperty(depth0,"url") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":61,"column":72},"end":{"line":61,"column":81}}}) : helper)))
    + "\" data-action=\"magnify\"></span>\n                            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- -------------------- Photo --------------------- -->\n<div class=\"instruction required photo widget-panel edit-mode\">\n    <!-- widget header -->\n    <div class=\"header\">\n        <div class=\"controls\">\n            <div class=\"ti type\">\n                <span class=\"optional\"></span>\n                <span class=\"type\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"questionType") || (depth0 != null ? lookupProperty(depth0,"questionType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"questionType","hash":{},"data":data,"loc":{"start":{"line":8,"column":35},"end":{"line":8,"column":53}}}) : helper)))
    + "</span>\n            </div>\n            <div class=\"arrow\" data-action=\"collapse\"><span class=\"im icon-arrow-up\"></span></div>\n            <div class=\"map\">\n                <span class=\"im icon-map-pin\" data-action=\"viewLatLon\" style=\"display:none\"></span>\n            </div>\n            <div class=\"dragger\"><span class=\"im icon-empty\"></span></div>\n        </div>\n        <div class=\"actions\">\n            <div class=\"ti target "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasMetadata") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":34},"end":{"line":17,"column":72}}})) != null ? stack1 : "")
    + "\" data-action=\"view-metadata\">\n                "
    + alias4((lookupProperty(helpers,"dynamicTarget")||(depth0 && lookupProperty(depth0,"dynamicTarget"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"target") : depth0),{"name":"dynamicTarget","hash":{},"data":data,"loc":{"start":{"line":18,"column":16},"end":{"line":18,"column":42}}}))
    + "\n                <div class=\"metadata popup\" data-target=\"target-metadata\" style=\"display:none;\"></div>\n            </div>\n            <div class=\"viewing icon-buttons\">\n                <a class=\"im icon-edit\" href=\"javascript:;\"></a>\n            </div>\n        </div>\n    </div>\n\n    <!-- widget content -->\n    <div class=\"widget-panel-content\" data-target=\"body\">\n\n        <div class=\"inner\">\n            <div class=\"ti\">\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"questionNumber") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":16},"end":{"line":32,"column":83}}})) != null ? stack1 : "")
    + "\n                <span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":33,"column":22},"end":{"line":33,"column":33}}}) : helper)))
    + "</span>\n            </div>\n            <div class=\"description\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"body") || (depth0 != null ? lookupProperty(depth0,"body") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"body","hash":{},"data":data,"loc":{"start":{"line":35,"column":37},"end":{"line":35,"column":49}}}) : helper))) != null ? stack1 : "")
    + "</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"files") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":12},"end":{"line":51,"column":19}}})) != null ? stack1 : "")
    + "\n            <div class=\"answers divider\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"images") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":16},"end":{"line":65,"column":23}}})) != null ? stack1 : "")
    + "                <!-- the image the worker takes -->\n                <div class=\"answer image\">\n                    <label class=\"plainLabel\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"questionType") || (depth0 != null ? lookupProperty(depth0,"questionType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"questionType","hash":{},"data":data,"loc":{"start":{"line":68,"column":46},"end":{"line":68,"column":64}}}) : helper)))
    + "</label>\n\n                    <div class=\"wrapper\">\n                        <a href=\"javascript:;\" class=\"bigButton photo\" data-action=\"uploadImage\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"uploadLabel") || (depth0 != null ? lookupProperty(depth0,"uploadLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"uploadLabel","hash":{},"data":data,"loc":{"start":{"line":71,"column":97},"end":{"line":71,"column":114}}}) : helper)))
    + "</a>\n                    </div>\n                    <div data-target=\"added\">\n                        <div data-target=\"images\"></div>\n                    </div>\n                </div>\n            </div>\n            <!-- END answers -->\n\n        </div>\n        <!-- END inner -->\n\n        <!-- main action button -->\n        <a href=\"javascript:;\" class=\"answerButton disabled\" data-action=\"save\"><span class=\"glyphicon glyphicon-refresh loading-xsm\" data-buffering=\"dataItem\"></span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"save") || (depth0 != null ? lookupProperty(depth0,"save") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"save","hash":{},"data":data,"loc":{"start":{"line":84,"column":167},"end":{"line":84,"column":177}}}) : helper)))
    + "</a>\n\n    </div>\n    <!-- END widget content -->\n</div><!-- END photo -->\n";
},"useData":true,"useDepths":true});