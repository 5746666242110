// @flow
import { fade } from '@material-ui/core';

export default (theme: Object) => ({
    navItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: [[12, 22]],
        textTransform: 'uppercase',
        fontSize: 14,
        color: theme.palette.common.white,

        '&:not(:first-child)': {
            borderTop: [[1, 'solid']],
            borderColor: fade(theme.palette.common.white, 0.15),
        },

        '&:hover': {
            backgroundColor: fade(theme.palette.primary.main, 0.3),
            color: theme.palette.common.white,
        },

        '&:active, &:focus': {
            color: '$pale-brand-color',
        },

        '&$active': {
            borderTop: 'none',
            backgroundColor: theme.palette.primary.main,

            fontWeight: 400,

            '& + $navItem': {
                borderTopColor: theme.palette.primary.main,
            },
        },
    },
    link: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1, 2, 1, 1.5),

        color: theme.palette.common.white,

        '&:hover': {},

        '&:active, &:focus': {
            color: theme.palette.primary.xlight,
        },
    },
    icon: {
        color: theme.palette.primary.xlight,
        marginRight: theme.spacing(1),
    },
    tooltipInner: {
        backgroundColor: theme.palette.common.black,
    },
    externalLinkIcon: {
        display: 'flex',
        marginLeft: 'auto',
        opacity: 0.5,
    },
});
