// @flow
import React from 'react';
import cx from 'classnames';
import { Chip } from '@material-ui/core';

export default function MultiValue(props: Object) {
    const { children, isFocused, removeProps, selectProps } = props;
    const { classes } = selectProps;
    const { onClick, onMouseDown } = removeProps;

    return (
        <Chip
          tabIndex={-1}
          label={children}
          className={cx(classes.chip, { [classes.chipFocused]: isFocused })}
          onDelete={(event: SyntheticEvent<any>) => {
              onClick();
              onMouseDown(event);
          }}
        />
    );
}
