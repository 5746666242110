// @flow
const namespace = 'g2/gigwalk';

export const SEARCH_AUDIT_EVENTS = `${namespace}/SEARCH_AUDIT_EVENTS`;
export const SEARCH_AUDIT_EVENTS_SUCCESS = `${SEARCH_AUDIT_EVENTS}_SUCCESS`;
export const SEARCH_AUDIT_EVENTS_ERROR = `${SEARCH_AUDIT_EVENTS}_ERROR`;

export const FETCH_CERTIFICATION = `${namespace}/FETCH_CERTIFICATION`;
export const FETCH_CERTIFICATION_SUCCESS = `${FETCH_CERTIFICATION}_SUCCESS`;
export const FETCH_CERTIFICATION_ERROR = `${FETCH_CERTIFICATION}_ERROR`;

export const FETCH_ALL_CERTIFICATIONS = `${namespace}/FETCH_ALL_CERTIFICATIONS`;
export const FETCH_ALL_CERTIFICATIONS_SUCCESS = `${FETCH_ALL_CERTIFICATIONS}_SUCCESS`;
export const FETCH_ALL_CERTIFICATIONS_ERROR = `${FETCH_ALL_CERTIFICATIONS}_ERROR`;

export const ASSIGN_CERTIFICATIONS = `${namespace}/ASSIGN_CERTIFICATIONS`;
export const ASSIGN_CERTIFICATIONS_SUCCESS = `${ASSIGN_CERTIFICATIONS}_SUCCESS`;
export const ASSIGN_CERTIFICATIONS_ERROR = `${ASSIGN_CERTIFICATIONS}_ERROR`;

export const FETCH_CUSTOMER = `${namespace}/FETCH_CUSTOMER`;
export const FETCH_CUSTOMER_SUCCESS = `${FETCH_CUSTOMER}_SUCCESS`;
export const FETCH_CUSTOMER_ERROR = `${FETCH_CUSTOMER}_ERROR`;

export const SEARCH_CUSTOMERS = `${namespace}/SEARCH_CUSTOMERS`;
export const SEARCH_CUSTOMERS_SUCCESS = `${SEARCH_CUSTOMERS}_SUCCESS`;
export const SEARCH_CUSTOMERS_ERROR = `${SEARCH_CUSTOMERS}_ERROR`;

export const INVITE_CUSTOMERS = `${namespace}/INVITE_CUSTOMERS`;
export const INVITE_CUSTOMERS_SUCCESS = `${INVITE_CUSTOMERS}_SUCCESS`;
export const INVITE_CUSTOMERS_ERROR = `${INVITE_CUSTOMERS}_ERROR`;

export const UPDATE_USER = `${namespace}/UPDATE_USER`;
export const UPDATE_USER_SUCCESS = `${UPDATE_USER}_SUCCESS`;
export const UPDATE_USER_ERROR = `${UPDATE_USER}_ERROR`;

export const UPDATE_CUSTOMER = `${namespace}/UPDATE_CUSTOMER`;
export const UPDATE_CUSTOMER_SUCCESS = `${UPDATE_CUSTOMER}_SUCCESS`;
export const UPDATE_CUSTOMER_ERROR = `${UPDATE_CUSTOMER}_ERROR`;

export const UPDATE_CUSTOMERS = `${namespace}/UPDATE_CUSTOMERS`;
export const UPDATE_CUSTOMERS_SUCCESS = `${UPDATE_CUSTOMERS}_SUCCESS`;
export const UPDATE_CUSTOMERS_ERROR = `${UPDATE_CUSTOMERS}_ERROR`;

export const FETCH_ALL_DATATYPES = `${namespace}/FETCH_ALL_DATATYPES`;
export const FETCH_ALL_DATATYPES_SUCCESS = `${FETCH_ALL_DATATYPES}_SUCCESS`;
export const FETCH_ALL_DATATYPES_ERROR = `${FETCH_ALL_DATATYPES}_ERROR`;

export const FETCH_DATATYPE = `${namespace}/FETCH_DATATYPE`;
export const FETCH_DATATYPE_SUCCESS = `${FETCH_DATATYPE}_SUCCESS`;
export const FETCH_DATATYPE_ERROR = `${FETCH_DATATYPE}_ERROR`;

export const CREATE_DATATYPE = `${namespace}/CREATE_DATATYPE`;
export const CREATE_DATATYPE_SUCCESS = `${CREATE_DATATYPE}_SUCCESS`;
export const CREATE_DATATYPE_ERROR = `${CREATE_DATATYPE}_ERROR`;

export const UPDATE_DATATYPE = `${namespace}/UPDATE_DATATYPE`;
export const UPDATE_DATATYPE_SUCCESS = `${UPDATE_DATATYPE}_SUCCESS`;
export const UPDATE_DATATYPE_ERROR = `${UPDATE_DATATYPE}_ERROR`;

export const ADD_DATATYPE_ATTACHMENT = `${namespace}/ADD_DATATYPE_ATTACHMENT`;
export const ADD_DATATYPE_ATTACHMENT_SUCCESS = `${ADD_DATATYPE_ATTACHMENT}_SUCCESS`;
export const ADD_DATATYPE_ATTACHMENT_ERROR = `${ADD_DATATYPE_ATTACHMENT}_ERROR`;

export const DELETE_DATATYPE_ATTACHMENT = `${namespace}/DELETE_DATATYPE_ATTACHMENT`;
export const DELETE_DATATYPE_ATTACHMENT_SUCCESS = `${DELETE_DATATYPE_ATTACHMENT}_SUCCESS`;
export const DELETE_DATATYPE_ATTACHMENT_ERROR = `${DELETE_DATATYPE_ATTACHMENT}_ERROR`;

export const FETCH_GROUP = `${namespace}/FETCH_GROUP`;
export const FETCH_GROUP_SUCCESS = `${FETCH_GROUP}_SUCCESS`;
export const FETCH_GROUP_ERROR = `${FETCH_GROUP}_ERROR`;

export const GET_ORGANIZATION_GROUPS = `${namespace}/GET_ORGANIZATION_GROUP`;
export const GET_ORGANIZATION_GROUPS_SUCCESS = `${GET_ORGANIZATION_GROUPS}_SUCCESS`;
export const GET_ORGANIZATION_GROUPS_ERROR = `${GET_ORGANIZATION_GROUPS}_ERROR`;

export const CREATE_GROUP = `${namespace}/CREATE_GROUP`;
export const CREATE_GROUP_SUCCESS = `${CREATE_GROUP}_SUCCESS`;
export const CREATE_GROUP_ERROR = `${CREATE_GROUP}_ERROR`;

export const ADD_GROUP_MEMBER = `${namespace}/ADD_GROUP_MEMBER`;
export const ADD_GROUP_MEMBER_SUCCESS = `${ADD_GROUP_MEMBER}_SUCCESS`;
export const ADD_GROUP_MEMBER_ERROR = `${ADD_GROUP_MEMBER}_ERROR`;

export const ADD_GROUP_MEMBERS = `${namespace}/ADD_GROUP_MEMBERS`;
export const ADD_GROUP_MEMBERS_SUCCESS = `${ADD_GROUP_MEMBERS}_SUCCESS`;
export const ADD_GROUP_MEMBERS_ERROR = `${ADD_GROUP_MEMBERS}_ERROR`;

export const SEARCH_GROUPS = `${namespace}/SEARCH_GROUPS`;
export const SEARCH_GROUPS_SUCCESS = `${SEARCH_GROUPS}_SUCCESS`;
export const SEARCH_GROUPS_ERROR = `${SEARCH_GROUPS}_ERROR`;

export const FETCH_LOCATION_LIST = `${namespace}/FETCH_LOCATION_LIST`;
export const FETCH_LOCATION_LIST_SUCCESS = `${FETCH_LOCATION_LIST}_SUCCESS`;
export const FETCH_LOCATION_LIST_ERROR = `${FETCH_LOCATION_LIST}_ERROR`;

export const CREATE_LOCATION_LIST = `${namespace}/CREATE_LOCATION_LIST`;
export const CREATE_LOCATION_LIST_SUCCESS = `${CREATE_LOCATION_LIST}_SUCCESS`;
export const CREATE_LOCATION_LIST_ERROR = `${CREATE_LOCATION_LIST}_ERROR`;

export const UPDATE_LOCATION_LIST = `${namespace}/UPDATE_LOCATION_LIST`;
export const UPDATE_LOCATION_LIST_SUCCESS = `${UPDATE_LOCATION_LIST}_SUCCESS`;
export const UPDATE_LOCATION_LIST_ERROR = `${UPDATE_LOCATION_LIST}_ERROR`;

export const ADD_LOCATION_LIST_ITEM = `${namespace}/ADD_LOCATION_LIST_ITEM`;
export const ADD_LOCATION_LIST_ITEM_SUCCESS = `${ADD_LOCATION_LIST_ITEM}_SUCCESS`;
export const ADD_LOCATION_LIST_ITEM_ERROR = `${ADD_LOCATION_LIST_ITEM}_ERROR`;

export const REMOVE_LOCATION_LIST_ITEM = `${namespace}/REMOVE_LOCATION_LIST_ITEM`;
export const REMOVE_LOCATION_LIST_ITEM_SUCCESS = `${REMOVE_LOCATION_LIST_ITEM}_SUCCESS`;
export const REMOVE_LOCATION_LIST_ITEM_ERROR = `${REMOVE_LOCATION_LIST_ITEM}_ERROR`;

export const GET_LOCATION_LIST_ITEMS = `${namespace}/GET_LOCATION_LIST_ITEMS`;
export const GET_LOCATION_LIST_ITEMS_SUCCESS = `${GET_LOCATION_LIST_ITEMS}_SUCCESS`;
export const GET_LOCATION_LIST_ITEMS_ERROR = `${GET_LOCATION_LIST_ITEMS}_ERROR`;

export const UPLOAD_LOCATION_LIST = `${namespace}/UPLOAD_LOCATION_LIST`;
export const UPLOAD_LOCATION_LIST_SUCCESS = `${UPLOAD_LOCATION_LIST}_SUCCESS`;
export const UPLOAD_LOCATION_LIST_ERROR = `${UPLOAD_LOCATION_LIST}_ERROR`;

export const GET_LOCATION_LIST_UPLOAD = `${namespace}/GET_LOCATION_LIST_UPLOAD`;
export const GET_LOCATION_LIST_UPLOAD_SUCCESS = `${GET_LOCATION_LIST_UPLOAD}_SUCCESS`;
export const GET_LOCATION_LIST_UPLOAD_ERROR = `${GET_LOCATION_LIST_UPLOAD}_ERROR`;

export const GET_UNRESOLVED_LOCATIONS = `${namespace}/GET_UNRESOLVED_LOCATIONS`;
export const GET_UNRESOLVED_LOCATIONS_SUCCESS = `${GET_UNRESOLVED_LOCATIONS}_SUCCESS`;
export const GET_UNRESOLVED_LOCATIONS_ERROR = `${GET_UNRESOLVED_LOCATIONS}_ERROR`;

export const CREATE_LOCATION = `${namespace}/CREATE_LOCATION`;
export const CREATE_LOCATION_SUCCESS = `${CREATE_LOCATION}_SUCCESS`;
export const CREATE_LOCATION_ERROR = `${CREATE_LOCATION}_ERROR`;

export const LIST_NOTIFICATIONS = `${namespace}/LIST_NOTIFICATIONS`;
export const LIST_NOTIFICATIONS_SUCCESS = `${LIST_NOTIFICATIONS}_SUCCESS`;
export const LIST_NOTIFICATIONS_ERROR = `${LIST_NOTIFICATIONS}_ERROR`;

export const UPDATE_NOTIFICATION = `${namespace}/UPDATE_NOTIFICATION`;
export const UPDATE_NOTIFICATION_SUCCESS = `${UPDATE_NOTIFICATION}_SUCCESS`;
export const UPDATE_NOTIFICATION_ERROR = `${UPDATE_NOTIFICATION}_ERROR`;

export const FETCH_ORGANIZATION = `${namespace}/FETCH_ORGANIZATION`;
export const FETCH_ORGANIZATION_SUCCESS = `${FETCH_ORGANIZATION}_SUCCESS`;
export const FETCH_ORGANIZATION_ERROR = `${FETCH_ORGANIZATION}_ERROR`;

export const GET_BALANCE = `${namespace}/GET_BALANCE`;
export const GET_BALANCE_SUCCESS = `${GET_BALANCE}_SUCCESS`;
export const GET_BALANCE_ERROR = `${GET_BALANCE}_ERROR`;

export const ADD_FUNDS = `${namespace}/ADD_FUNDS`;
export const ADD_FUNDS_SUCCESS = `${ADD_FUNDS}_SUCCESS`;
export const ADD_FUNDS_ERROR = `${ADD_FUNDS}_ERROR`;

export const SEARCH_ORGANIZATIONS = `${namespace}/SEARCH`;
export const SEARCH_ORGANIZATIONS_SUCCESS = `${SEARCH_ORGANIZATIONS}_SUCCESS`;
export const SEARCH_ORGANIZATIONS_ERROR = `${SEARCH_ORGANIZATIONS}_ERROR`;

export const SEARCH_PAYOUTS = `${namespace}/SEARCH_PAYOUTS`;
export const SEARCH_PAYOUTS_SUCCESS = `${SEARCH_PAYOUTS}_SUCCESS`;
export const SEARCH_PAYOUTS_ERROR = `${SEARCH_PAYOUTS}_ERROR`;

export const FETCH_SUBSCRIPTION = `${namespace}/FETCH_SUBSCRIPTION`;
export const FETCH_SUBSCRIPTION_SUCCESS = `${FETCH_SUBSCRIPTION}_SUCCESS`;
export const FETCH_SUBSCRIPTION_ERROR = `${FETCH_SUBSCRIPTION}_ERROR`;

export const CREATE_SUBSCRIPTION = `${namespace}/CREATE_SUBSCRIPTION`;
export const CREATE_SUBSCRIPTION_SUCCESS = `${CREATE_SUBSCRIPTION}_SUCCESS`;
export const CREATE_SUBSCRIPTION_ERROR = `${CREATE_SUBSCRIPTION}_ERROR`;

export const UPDATE_SUBSCRIPTION = `${namespace}/UPDATE_SUBSCRIPTION`;
export const UPDATE_SUBSCRIPTION_SUCCESS = `${UPDATE_SUBSCRIPTION}_SUCCESS`;
export const UPDATE_SUBSCRIPTION_ERROR = `${UPDATE_SUBSCRIPTION}_ERROR`;

export const REQUEST_PROJECT_REVIEW = `${namespace}/REQUEST_PROJECT_REVIEW`;
export const REQUEST_PROJECT_REVIEW_SUCCESS = `${REQUEST_PROJECT_REVIEW}_SUCCESS`;
export const REQUEST_PROJECT_REVIEW_ERROR = `${REQUEST_PROJECT_REVIEW}_ERROR`;

export const SEARCH_SUBSCRIPTIONS = `${namespace}/SEARCH`;
export const SEARCH_SUBSCRIPTIONS_SUCCESS = `${SEARCH_SUBSCRIPTIONS}_SUCCESS`;
export const SEARCH_SUBSCRIPTIONS_ERROR = `${SEARCH_SUBSCRIPTIONS}_ERROR`;

export const DELETE_SUBSCRIPTION = `${namespace}/DELETE_SUBSCRIPTION`;
export const DELETE_SUBSCRIPTION_SUCCESS = `${DELETE_SUBSCRIPTION}_SUCCESS`;
export const DELETE_SUBSCRIPTION_ERROR = `${DELETE_SUBSCRIPTION}_ERROR`;

export const FETCH_TEMPLATE = `${namespace}/FETCH_TEMPLATE`;
export const FETCH_TEMPLATE_SUCCESS = `${FETCH_TEMPLATE}_SUCCESS`;
export const FETCH_TEMPLATE_ERROR = `${FETCH_TEMPLATE}_ERROR`;

export const FETCH_TICKET = `${namespace}/FETCH_TICKET`;
export const FETCH_TICKET_SUCCESS = `${FETCH_TICKET}_SUCCESS`;
export const FETCH_TICKET_ERROR = `${FETCH_TICKET}_ERROR`;

export const SEARCH_TICKETS = `${namespace}/SEARCH_TICKETS`;
export const SEARCH_TICKETS_SUCCESS = `${SEARCH_TICKETS}_SUCCESS`;
export const SEARCH_TICKETS_ERROR = `${SEARCH_TICKETS}_ERROR`;

export const UPDATE_TICKETS = `${namespace}/UPDATE_TICKETS`;
export const UPDATE_TICKETS_SUCCESS = `${UPDATE_TICKETS}_SUCCESS`;
export const UPDATE_TICKETS_ERROR = `${UPDATE_TICKETS}_ERROR`;

export const SUBMIT_TICKET = `${namespace}/SUBMIT_TICKET`;
export const SUBMIT_TICKET_SUCCESS = `${SUBMIT_TICKET}_SUCCESS`;
export const SUBMIT_TICKET_ERROR = `${SUBMIT_TICKET}_ERROR`;

export const CREATE_DATA_ITEM = `${namespace}/CREATE_DATA_ITEM`;
export const CREATE_DATA_ITEM_SUCCESS = `${CREATE_DATA_ITEM}_SUCCESS`;
export const CREATE_DATA_ITEM_ERROR = `${CREATE_DATA_ITEM}_ERROR`;

export const DELETE_DATA_ITEM = `${namespace}/DELETE_DATA_ITEM`;
export const DELETE_DATA_ITEM_SUCCESS = `${DELETE_DATA_ITEM}_SUCCESS`;
export const DELETE_DATA_ITEM_ERROR = `${DELETE_DATA_ITEM}_ERROR`;

export const GET_APPLICANTS = `${namespace}/GET_APPLICANTS`;
export const GET_APPLICANTS_SUCCESS = `${GET_APPLICANTS}_SUCCESS`;
export const GET_APPLICANTS_ERROR = `${GET_APPLICANTS}_ERROR`;

export const UPDATE_APPLICATION = `${namespace}/UPDATE_APPLICATION`;
export const UPDATE_APPLICATION_SUCCESS = `${UPDATE_APPLICATION}_SUCCESS`;
export const UPDATE_APPLICATION_ERROR = `${UPDATE_APPLICATION}_ERROR`;

export const GET_TICKET_EVENTS = `${namespace}/GET_TICKET_EVENTS`;
export const GET_TICKET_EVENTS_SUCCESS = `${GET_TICKET_EVENTS}_SUCCESS`;
export const GET_TICKET_EVENTS_ERROR = `${GET_TICKET_EVENTS}_ERROR`;

export const CREATE_TICKET_EVENT = `${namespace}/CREATE_TICKET_EVENT`;
export const CREATE_TICKET_EVENT_SUCCESS = `${CREATE_TICKET_EVENT}_SUCCESS`;
export const CREATE_TICKET_EVENT_ERROR = `${CREATE_TICKET_EVENT}_ERROR`;

export const DELETE_TICKET_EVENT = `${namespace}/DELETE_TICKET_EVENT`;
export const DELETE_TICKET_EVENT_SUCCESS = `${DELETE_TICKET_EVENT}_SUCCESS`;
export const DELETE_TICKET_EVENT_ERROR = `${DELETE_TICKET_EVENT}_ERROR`;

export const CREATE_APPLICATION = `${namespace}/CREATE_APPLICATION`;
export const CREATE_APPLICATION_SUCCESS = `${CREATE_APPLICATION}_SUCCESS`;
export const CREATE_APPLICATION_ERROR = `${CREATE_APPLICATION}_ERROR`;

export const WITHDRAW_APPLICATION = `${namespace}/WITHDRAW_APPLICATION`;
export const WITHDRAW_APPLICATION_SUCCESS = `${WITHDRAW_APPLICATION}_SUCCESS`;
export const WITHDRAW_APPLICATION_ERROR = `${WITHDRAW_APPLICATION}_ERROR`;

export const ADD_TICKET_TAGS = `${namespace}/ADD_TICKET_TAGS`;
export const ADD_TICKET_TAGS_SUCCESS = `${ADD_TICKET_TAGS}_SUCCESS`;
export const ADD_TICKET_TAGS_ERROR = `${ADD_TICKET_TAGS}_ERROR`;

export const REMOVE_TICKET_TAG = `${namespace}/REMOVE_TICKET_TAG`;
export const REMOVE_TICKET_TAG_SUCCESS = `${REMOVE_TICKET_TAG}_SUCCESS`;
export const REMOVE_TICKET_TAG_ERROR = `${REMOVE_TICKET_TAG}_ERROR`;

export const CREATE_WORKER_RATING = `${namespace}/CREATE_WORKER_RATING`;
export const CREATE_WORKER_RATING_SUCCESS = `${CREATE_WORKER_RATING}_SUCCESS`;
export const CREATE_WORKER_RATING_ERROR = `${CREATE_WORKER_RATING}_ERROR`;

export const UPDATE_WORKER_RATING = `${namespace}/UPDATE_RATING`;
export const UPDATE_WORKER_RATING_SUCCESS = `${UPDATE_WORKER_RATING}_SUCCESS`;
export const UPDATE_WORKER_RATING_ERROR = `${UPDATE_WORKER_RATING}_ERROR`;

export const CREATE_TAG = `${namespace}/CREATE_TAG`;
export const CREATE_TAG_SUCCESS = `${CREATE_TAG}_SUCCESS`;
export const CREATE_TAG_ERROR = `${CREATE_TAG}_ERROR`;

export const SEARCH_TAGS = `${namespace}/SEARCH_TAGS`;
export const SEARCH_TAGS_SUCCESS = `${SEARCH_TAGS}_SUCCESS`;
export const SEARCH_TAGS_ERROR = `${SEARCH_TAGS}_ERROR`;

export default {
    SEARCH_AUDIT_EVENTS,
    SEARCH_AUDIT_EVENTS_ERROR,
    SEARCH_AUDIT_EVENTS_SUCCESS,
    FETCH_CERTIFICATION,
    FETCH_CERTIFICATION_ERROR,
    FETCH_CERTIFICATION_SUCCESS,
    FETCH_ALL_CERTIFICATIONS,
    FETCH_ALL_CERTIFICATIONS_ERROR,
    FETCH_ALL_CERTIFICATIONS_SUCCESS,
    ASSIGN_CERTIFICATIONS,
    ASSIGN_CERTIFICATIONS_ERROR,
    ASSIGN_CERTIFICATIONS_SUCCESS,
    FETCH_CUSTOMER,
    FETCH_CUSTOMER_ERROR,
    FETCH_CUSTOMER_SUCCESS,
    SEARCH_CUSTOMERS,
    SEARCH_CUSTOMERS_ERROR,
    SEARCH_CUSTOMERS_SUCCESS,
    INVITE_CUSTOMERS,
    INVITE_CUSTOMERS_ERROR,
    INVITE_CUSTOMERS_SUCCESS,
    UPDATE_USER,
    UPDATE_USER_ERROR,
    UPDATE_USER_SUCCESS,
    UPDATE_CUSTOMER,
    UPDATE_CUSTOMER_ERROR,
    UPDATE_CUSTOMER_SUCCESS,
    UPDATE_CUSTOMERS,
    UPDATE_CUSTOMERS_ERROR,
    UPDATE_CUSTOMERS_SUCCESS,
    FETCH_ALL_DATATYPES,
    FETCH_ALL_DATATYPES_ERROR,
    FETCH_ALL_DATATYPES_SUCCESS,
    FETCH_DATATYPE,
    FETCH_DATATYPE_ERROR,
    FETCH_DATATYPE_SUCCESS,
    CREATE_DATATYPE,
    CREATE_DATATYPE_ERROR,
    CREATE_DATATYPE_SUCCESS,
    UPDATE_DATATYPE,
    UPDATE_DATATYPE_ERROR,
    UPDATE_DATATYPE_SUCCESS,
    ADD_DATATYPE_ATTACHMENT,
    ADD_DATATYPE_ATTACHMENT_ERROR,
    ADD_DATATYPE_ATTACHMENT_SUCCESS,
    DELETE_DATATYPE_ATTACHMENT,
    DELETE_DATATYPE_ATTACHMENT_ERROR,
    DELETE_DATATYPE_ATTACHMENT_SUCCESS,
    FETCH_GROUP,
    FETCH_GROUP_ERROR,
    FETCH_GROUP_SUCCESS,
    GET_ORGANIZATION_GROUPS,
    GET_ORGANIZATION_GROUPS_ERROR,
    GET_ORGANIZATION_GROUPS_SUCCESS,
    CREATE_GROUP,
    CREATE_GROUP_ERROR,
    CREATE_GROUP_SUCCESS,
    ADD_GROUP_MEMBER,
    ADD_GROUP_MEMBER_ERROR,
    ADD_GROUP_MEMBER_SUCCESS,
    ADD_GROUP_MEMBERS,
    ADD_GROUP_MEMBERS_ERROR,
    ADD_GROUP_MEMBERS_SUCCESS,
    SEARCH_GROUPS,
    SEARCH_GROUPS_ERROR,
    SEARCH_GROUPS_SUCCESS,
    FETCH_LOCATION_LIST,
    FETCH_LOCATION_LIST_ERROR,
    FETCH_LOCATION_LIST_SUCCESS,
    CREATE_LOCATION_LIST,
    CREATE_LOCATION_LIST_ERROR,
    CREATE_LOCATION_LIST_SUCCESS,
    UPDATE_LOCATION_LIST,
    UPDATE_LOCATION_LIST_ERROR,
    UPDATE_LOCATION_LIST_SUCCESS,
    ADD_LOCATION_LIST_ITEM,
    ADD_LOCATION_LIST_ITEM_ERROR,
    ADD_LOCATION_LIST_ITEM_SUCCESS,
    REMOVE_LOCATION_LIST_ITEM,
    REMOVE_LOCATION_LIST_ITEM_ERROR,
    REMOVE_LOCATION_LIST_ITEM_SUCCESS,
    GET_LOCATION_LIST_ITEMS,
    GET_LOCATION_LIST_ITEMS_ERROR,
    GET_LOCATION_LIST_ITEMS_SUCCESS,
    UPLOAD_LOCATION_LIST,
    UPLOAD_LOCATION_LIST_ERROR,
    UPLOAD_LOCATION_LIST_SUCCESS,
    GET_LOCATION_LIST_UPLOAD,
    GET_LOCATION_LIST_UPLOAD_ERROR,
    GET_LOCATION_LIST_UPLOAD_SUCCESS,
    GET_UNRESOLVED_LOCATIONS,
    GET_UNRESOLVED_LOCATIONS_ERROR,
    GET_UNRESOLVED_LOCATIONS_SUCCESS,
    CREATE_LOCATION,
    CREATE_LOCATION_SUCCESS,
    CREATE_LOCATION_ERROR,
    LIST_NOTIFICATIONS,
    LIST_NOTIFICATIONS_ERROR,
    LIST_NOTIFICATIONS_SUCCESS,
    UPDATE_NOTIFICATION,
    UPDATE_NOTIFICATION_ERROR,
    UPDATE_NOTIFICATION_SUCCESS,
    FETCH_ORGANIZATION,
    FETCH_ORGANIZATION_ERROR,
    FETCH_ORGANIZATION_SUCCESS,
    GET_BALANCE,
    GET_BALANCE_ERROR,
    GET_BALANCE_SUCCESS,
    ADD_FUNDS,
    ADD_FUNDS_ERROR,
    ADD_FUNDS_SUCCESS,
    SEARCH_ORGANIZATIONS,
    SEARCH_ORGANIZATIONS_ERROR,
    SEARCH_ORGANIZATIONS_SUCCESS,
    SEARCH_PAYOUTS,
    SEARCH_PAYOUTS_ERROR,
    SEARCH_PAYOUTS_SUCCESS,
    FETCH_SUBSCRIPTION,
    FETCH_SUBSCRIPTION_ERROR,
    FETCH_SUBSCRIPTION_SUCCESS,
    CREATE_SUBSCRIPTION,
    CREATE_SUBSCRIPTION_ERROR,
    CREATE_SUBSCRIPTION_SUCCESS,
    UPDATE_SUBSCRIPTION,
    UPDATE_SUBSCRIPTION_ERROR,
    UPDATE_SUBSCRIPTION_SUCCESS,
    REQUEST_PROJECT_REVIEW,
    REQUEST_PROJECT_REVIEW_ERROR,
    REQUEST_PROJECT_REVIEW_SUCCESS,
    SEARCH_SUBSCRIPTIONS,
    SEARCH_SUBSCRIPTIONS_ERROR,
    SEARCH_SUBSCRIPTIONS_SUCCESS,
    DELETE_SUBSCRIPTION,
    DELETE_SUBSCRIPTION_ERROR,
    DELETE_SUBSCRIPTION_SUCCESS,
    FETCH_TEMPLATE,
    FETCH_TEMPLATE_ERROR,
    FETCH_TEMPLATE_SUCCESS,
    FETCH_TICKET,
    FETCH_TICKET_ERROR,
    FETCH_TICKET_SUCCESS,
    SEARCH_TICKETS,
    SEARCH_TICKETS_ERROR,
    SEARCH_TICKETS_SUCCESS,
    UPDATE_TICKETS,
    UPDATE_TICKETS_ERROR,
    UPDATE_TICKETS_SUCCESS,
    SUBMIT_TICKET,
    SUBMIT_TICKET_ERROR,
    SUBMIT_TICKET_SUCCESS,
    CREATE_DATA_ITEM,
    CREATE_DATA_ITEM_ERROR,
    CREATE_DATA_ITEM_SUCCESS,
    DELETE_DATA_ITEM,
    DELETE_DATA_ITEM_ERROR,
    DELETE_DATA_ITEM_SUCCESS,
    GET_APPLICANTS,
    GET_APPLICANTS_ERROR,
    GET_APPLICANTS_SUCCESS,
    UPDATE_APPLICATION,
    UPDATE_APPLICATION_ERROR,
    UPDATE_APPLICATION_SUCCESS,
    GET_TICKET_EVENTS,
    GET_TICKET_EVENTS_ERROR,
    GET_TICKET_EVENTS_SUCCESS,
    CREATE_TICKET_EVENT,
    CREATE_TICKET_EVENT_ERROR,
    CREATE_TICKET_EVENT_SUCCESS,
    DELETE_TICKET_EVENT,
    DELETE_TICKET_EVENT_ERROR,
    DELETE_TICKET_EVENT_SUCCESS,
    CREATE_APPLICATION,
    CREATE_APPLICATION_ERROR,
    CREATE_APPLICATION_SUCCESS,
    WITHDRAW_APPLICATION,
    WITHDRAW_APPLICATION_SUCCESS,
    WITHDRAW_APPLICATION_ERROR,
    ADD_TICKET_TAGS,
    ADD_TICKET_TAGS_ERROR,
    ADD_TICKET_TAGS_SUCCESS,
    REMOVE_TICKET_TAG,
    REMOVE_TICKET_TAG_ERROR,
    REMOVE_TICKET_TAG_SUCCESS,
    CREATE_WORKER_RATING,
    CREATE_WORKER_RATING_ERROR,
    CREATE_WORKER_RATING_SUCCESS,
    UPDATE_WORKER_RATING,
    UPDATE_WORKER_RATING_ERROR,
    UPDATE_WORKER_RATING_SUCCESS,
    CREATE_TAG,
    CREATE_TAG_ERROR,
    CREATE_TAG_SUCCESS,
    SEARCH_TAGS,
    SEARCH_TAGS_ERROR,
    SEARCH_TAGS_SUCCESS,
};
