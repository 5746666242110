// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback } from 'react';
import { TableFooter, TablePagination, TableRow } from '@material-ui/core';

type Props = {
    classes: Object,
    count: number,
    data: Object[],
    loading: boolean,
    onPageChange: (value: number) => void,
    onPageSizeChange: (value: number) => void,
    page: number,
    pageSize: number,
    pageSizeOptions: number[],
    selected: any[],
};

export function Pagination(props: Props) {
    const {
        classes,
        count,
        loading,
        onPageChange,
        onPageSizeChange,
        page,
        pageSize,
        pageSizeOptions,
    } = props;

    const handleChangeRowsPerPage = useCallback((event: SyntheticInputEvent<*>) => {
        const rowsPerPage = parseInt(event.target.value, 10);
        onPageSizeChange(rowsPerPage);
    }, [onPageSizeChange]);

    const handleChangePage = useCallback((event: SyntheticEvent<*>, value: number) => {
        // TablePagination will call onChangePage when the component receives new props
        // AND the page is out of range. If we are fetching data for the first time, we
        // don't know the record count, so onChangePage will always be called with a
        // value of 0. This breaks url navigation to a specific page.
        if (value >= 0 && !loading) {
            onPageChange(value);
        }
    }, [loading, onPageChange]);

    return (
        <TableFooter className={classes.footer} component="div">
            <TableRow component="div">
                <TablePagination
                  component="div"
                  classes={{
                      caption: classes.paginationCaption,
                      input: classes.paginationInput,
                      select: classes.paginationSelect,
                      selectIcon: classes.paginationSelectIcon,
                  }}
                  count={count}
                  rowsPerPage={pageSize}
                  rowsPerPageOptions={pageSizeOptions}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </TableRow>
        </TableFooter>
    );
}

export default Pagination;
