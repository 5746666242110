var Handlebars = require('/tmp/build_a9d70681abb25b82ca4489607bd6ccee/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                <div class=\"arrow\"><span class=\"im icon-arrow-up\" data-action=\"collapse\"></span></div>";
},"3":function(container,depth0,helpers,partials,data) {
    return "has-metadata";
},"5":function(container,depth0,helpers,partials,data) {
    return "";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"canDelete") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":20},"end":{"line":26,"column":114}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"canEdit") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":20},"end":{"line":27,"column":109}}})) != null ? stack1 : "")
    + "\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "<a class=\"im icon-trash\" href=\"javascript:;\" data-action=\"delete\"></a>";
},"10":function(container,depth0,helpers,partials,data) {
    return "<a class=\"im icon-edit\" href=\"javascript:;\" data-action=\"edit\"></a>";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n        <!-- main action button -->\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"canEdit") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":8},"end":{"line":39,"column":115}}})) != null ? stack1 : "")
    + "\n";
},"13":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a class=\"answerButton\" data-action=\"done\"><span class=\"glyphicon glyphicon-refresh loading-xsm\"\n                                                                        data-buffering></span>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"done") || (depth0 != null ? lookupProperty(depth0,"done") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"done","hash":{},"data":data,"loc":{"start":{"line":39,"column":94},"end":{"line":39,"column":104}}}) : helper)))
    + "</a>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- -------------------- Template --------------------- -->\n<div class=\"instruction template widget-panel\">\n    <!-- widget header -->\n    <div class=\"header\">\n        <div class=\"controls\">\n            <div class=\"ti type\">\n                <span class=\"optional\"></span>\n                <span class=\"type\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"titleBar") || (depth0 != null ? lookupProperty(depth0,"titleBar") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"titleBar","hash":{},"data":data,"loc":{"start":{"line":8,"column":35},"end":{"line":8,"column":49}}}) : helper)))
    + "</span>\n            </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"canToggle") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":11,"column":109}}})) != null ? stack1 : "")
    + "\n            <div class=\"map\">\n                <span class=\"im icon-map-pin\" data-action=\"viewLatLon\" style=\"display:none\"></span>\n            </div>\n            <div class=\"dragger\"><span class=\"im icon-empty\"></span></div>\n        </div>\n        <div class=\"actions\">\n            <div class=\"ti target\" data-action=\"view-metadata\">\n                "
    + alias3((lookupProperty(helpers,"dynamicTarget")||(depth0 && lookupProperty(depth0,"dynamicTarget"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"target") : depth0),{"name":"dynamicTarget","hash":{},"data":data,"loc":{"start":{"line":19,"column":16},"end":{"line":19,"column":42}}}))
    + "\n                <div class=\"metadata popup "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasMetadata") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":43},"end":{"line":20,"column":81}}})) != null ? stack1 : "")
    + "\" data-target=\"target-metadata\"\n                     style=\"display:none;\"></div>\n            </div>\n            <div class=\"viewing icon-buttons\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readMode") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":24,"column":16},"end":{"line":28,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n\n    <!-- widget content -->\n    <div data-target=\"instructions\"></div>\n\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readMode") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":36,"column":4},"end":{"line":40,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});