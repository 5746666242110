// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import EventListener from 'react-event-listener';
import { Route } from 'react-router';
// $FlowTypedIssue update react-redux libdef
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Collapse,
} from '@material-ui/core';
import type { ContextRouter } from 'react-router';
import useDebounceCallback from '../../../hooks/useDebounceCallback';
import { getTicket } from '../../../ducks/ticketDetail';
import GPSVerifiedMap from './GPSVerifiedMap';
import styles from './styles';
import type { State as RootState } from '../../../store';

type Props = ContextRouter & {
    classes: Object,
    className?: string,
};

const useStyles = makeStyles(styles, { name: 'Description' });

export default function Description(props: Props) {
    const { className } = props;

    const classes = useStyles(props);
    const { t } = useTranslation();

    const descriptionRef = useRef(null);

    const [expanded, setExpanded] = useState(false);
    const [collapsedHeight, setCollapsedHeight] = useState(100);

    const ticket = useSelector((state: RootState) => getTicket(state));

    const computeCollapsedHeight = useCallback(() => {
        const node = descriptionRef.current;
        if (node) {
            const nextCollapsedHeight = Math.min(node.offsetHeight, 100);
            setCollapsedHeight(nextCollapsedHeight);
        }
    }, []);

    const handleClick = useCallback(() => {
        setExpanded(!expanded);
    }, [expanded]);

    const [handleResize] = useDebounceCallback(
        () => { computeCollapsedHeight(); },
        500
    );

    useLayoutEffect(() => {
        computeCollapsedHeight();
    });

    const locationTitle = ticket ? ticket.location.title : '';
    const locationAddress = ticket ? ticket.location.formatted_address : '';
    const description = ticket ? ticket.description : '';
    const hasOverflow = collapsedHeight === 100;

    return (
        <Card className={className}>
            <EventListener
              target="window"
              onResize={handleResize}
            />
            <CardHeader
              classes={{
                  title: classes.locationTitle,
                  subheader: classes.locationAddress,
              }}
              title={locationTitle !== locationAddress ? locationTitle : locationAddress}
              subheader={locationTitle !== locationAddress ? locationAddress : null}
            />
            <div style={{ height: 270, width: '100%' }}>
                <Route component={GPSVerifiedMap} />
            </div>
            <CardContent className={classes.cardContent}>
                <div className={classes.label}>{t('ticketDetail.description.description')}</div>
                <Collapse className={classes.description} in={expanded} collapsedHeight={`${collapsedHeight}px`}>
                    <div
                      ref={descriptionRef}
                      style={{ maxHeight: expanded ? 'unset' : `${collapsedHeight}px` }}
                      dangerouslySetInnerHTML={{ __html: description }}
                    />
                    <div className={classes.gradient} style={{ visibility: hasOverflow && !expanded ? 'visible' : 'hidden' }} />
                </Collapse>
                {hasOverflow
                    ? (
                        <Button
                          className={classes.showMoreButton}
                          variant="outlined"
                          fullWidth
                          onClick={handleClick}
                        >
                            {expanded ? t('ticketDetail.description.hideDescription') : t('ticketDetail.description.showDescription')}
                        </Button>
                    )
                    : null}
            </CardContent>
        </Card>
    );
}
