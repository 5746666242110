// @flow
export default (theme: Object) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '54px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: theme.palette.primary.main,
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        outline: 'none',
        minHeight: 150,
    },
    fileList: {
        display: 'flex',

        '& > *': {
            margin: theme.spacing(1.5),
            // box-shadow: 0px 0px 3px 1px #cd1e3d
        },
    },
    badge: {
        transform: 'scale(1) translate(35%, -35%)',
    },
    removeButton: {
        minHeight: 'unset',
        height: 28,
        width: 28,
        fontSize: 18,
    },
    tooltip: {
        borderColor: theme.palette.error.main,
        backgroundColor: theme.palette.error.main,
        maxWidth: 130,
        textAlign: 'center',
    },
});
