// @flow
import React, { Fragment } from 'react';
import * as yup from 'yup';
import { Field, Form, Formik } from 'formik';
// $FlowTypedIssue update react-redux libdef
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import {
    Button,
    Card,
    CardActions,
    CardHeader,
    Input,
} from '@material-ui/core';
import type { ContextRouter } from 'react-router';
import { addComment } from '../../../../ducks/ticketDetail';
import UserAvatar from '../../../../components/UserAvatar';
import SubmitButton from '../../../../components/SubmitButton';
import styles from './styles';
import type { State as RootState } from '../../../../store';

type Props = ContextRouter & {};

const useStyles = makeStyles(styles, { name: 'AddComment' });

const AddCommentSchema = yup.object().shape({
    comment: yup.string().required('validation.required'),
    ticketId: yup.number(),
});

export default function AddComment(props: Props) {
    const { match } = props;
    const ticketId = parseInt(match.params.ticketId, 10);

    const dispatch = useDispatch();
    const classes = useStyles(props);
    const { t } = useTranslation();

    const user = useSelector((state: RootState) => state.session.user);

    return (
        <Formik
          initialValues={{ comment: '', ticketId }}
          onSubmit={(values, { resetForm, setStatus }) => (
              dispatch(addComment(values))
                  .then(() => {
                      setStatus({ success: true });
                      resetForm();
                  })
                  .catch((error: string) => {
                      setStatus({ error });
                      return Promise.reject(error);
                  })
          )}
          validationSchema={AddCommentSchema}
        >
            {(formikProps) => {
                const { dirty, isSubmitting, isValid, resetForm } = formikProps;
                return (
                    <Form>
                        <Card elevation={0}>
                            <CardHeader
                              avatar={user ? <UserAvatar user={user} /> : null}
                              classes={{
                                  avatar: classes.avatar,
                                  title: classes.header,
                                  subheader: classes.content,
                              }}
                              title={(
                                  <Fragment>
                                      <Field
                                        as={Input}
                                        fullWidth
                                        multiline
                                        name="comment"
                                        placeholder={t('ticketDetail.addComment.placeholder')}
                                      />
                                      <CardActions className={classes.actions}>
                                          <Button onClick={() => resetForm()}>
                                              {t('ticketDetail.addComment.cancel')}
                                          </Button>
                                          <SubmitButton
                                            color="primary"
                                            disabled={!dirty || isSubmitting || !isValid}
                                            submitting={isSubmitting}
                                            type="submit"
                                            variant="contained"
                                          >
                                              {t('ticketDetail.addComment.confirm')}
                                          </SubmitButton>
                                      </CardActions>
                                  </Fragment>
                              )}
                            />
                        </Card>
                    </Form>
                );
            }}
        </Formik>
    );
}
