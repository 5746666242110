var Handlebars = require('/tmp/build_a9d70681abb25b82ca4489607bd6ccee/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <!-- question type is Numeric (date) -->\n  <div class=\"type date\" data-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":2,"column":34},"end":{"line":2,"column":42}}}) : helper)))
    + "\" data-targetid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"targetID") || (depth0 != null ? lookupProperty(depth0,"targetID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"targetID","hash":{},"data":data,"loc":{"start":{"line":2,"column":59},"end":{"line":2,"column":73}}}) : helper)))
    + "\">\n    <div class=\"answers\">\n      <div class=\"answer\">\n        <div class=\"description\">\n          "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"question_text") || (depth0 != null ? lookupProperty(depth0,"question_text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"question_text","hash":{},"data":data,"loc":{"start":{"line":6,"column":10},"end":{"line":6,"column":31}}}) : helper))) != null ? stack1 : "")
    + "\n        </div>\n        <input type=\"text\" class=\"datepicker\" data-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":8,"column":55},"end":{"line":8,"column":63}}}) : helper)))
    + "\" data-date-format=\"YYYY-MM-DD\" data-action=\"date\" data-datepicker name=\""
    + ((stack1 = (lookupProperty(helpers,"uniqueID")||(depth0 && lookupProperty(depth0,"uniqueID"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"_answer") : depth0),{"name":"uniqueID","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":136},"end":{"line":8,"column":171}}})) != null ? stack1 : "")
    + " answer date\" placeholder=\""
    + alias4(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"placeholders") : depth0)) != null ? lookupProperty(stack1,"date") : stack1), depth0))
    + "\" />\n      </div>\n    </div>\n  </div><!-- END Numeric (date) -->\n";
},"useData":true});