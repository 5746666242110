// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { FilledInput, Input, OutlinedInput } from '@material-ui/core';
import EditorInput from './EditorInput';
import styles from './styles';

type Props = {
    classes?: Object,
    onClick: (event: SyntheticMouseEvent<any>) => void,
    rows?: number,
    variant: 'standard' | 'outlined' | 'filled',
};

const useStyles = makeStyles(styles, { name: 'Editor' });

const variantComponent = {
    standard: Input,
    filled: FilledInput,
    outlined: OutlinedInput,
};

export default function Editor(props: Props) {
    const {
        classes: classesProp,
        onClick,
        rows,
        variant,
        ...other
    } = props;

    const classes = useStyles(props);
    const editorRef = useRef(null);
    const rootRef = useRef(null);

    const handleClick = useCallback((event: SyntheticMouseEvent<any>) => {
        editorRef.current.focus();
        onClick(event);
    }, [onClick]);

    useEffect(() => {
        const root = rootRef.current;
        const editorContent = root.querySelector('.public-DraftEditor-content');
        if (editorContent) {
            const computedStyle = getComputedStyle(editorContent);
            editorContent.style.minHeight = rows ? `calc(${rows || 1} * ${computedStyle.getPropertyValue('line-height')})` : null;
        }
    }, [classes.inputMultiline, rows]);

    const InputComponent = variantComponent[variant] || Input;

    return (
        <InputComponent
          {...other}
          classes={classes}
          inputComponent={EditorInput}
          inputRef={editorRef}
          multiline
          onClick={handleClick}
          ref={rootRef}
        />
    );
}

Editor.defaultProps = {
    onClick: () => {},
    variant: 'standard',
};
