var Handlebars = require('/tmp/build_a9d70681abb25b82ca4489607bd6ccee/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<table>\n<tr>\n<td>\n	<div class=\"inner\">\n		<div data-target=\"buttons\" class=\"buttons\">\n			<div class=\"button\" data-action=\"filePicker\">\n				<span class=\"im icon-file\"></span>\n				<a href=\"javascript:;\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"file_picker") || (depth0 != null ? lookupProperty(depth0,"file_picker") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"file_picker","hash":{},"data":data,"loc":{"start":{"line":8,"column":27},"end":{"line":8,"column":44}}}) : helper)))
    + "</a>\n			</div>\n			<div class=\"button\" data-action=\"takePhoto\">\n				<span class=\"im icon-camera\"></span>\n				<a href=\"javascript:;\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"camera") || (depth0 != null ? lookupProperty(depth0,"camera") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"camera","hash":{},"data":data,"loc":{"start":{"line":12,"column":27},"end":{"line":12,"column":39}}}) : helper)))
    + "</a>\n			</div>\n		</div>\n		<div data-target=\"preview\" class=\"preview\">\n			<div data-target=\"video\" id=\"webcam-video-stream\"></div>\n			<a href=\"javascript:;\" data-target=\"video\" data-action=\"getStill\"><span class=\"im icon-camera linkly\"></span></a>\n            <div data-target=\"attachment\"></div>\n			<div data-buffering class=\"buffering-outer\">\n				<div><div class=\"loading-xsm black\"><span class=\"glyphicon glyphicon-refresh\"></span></div></div>\n				<a href=\"javascript:;\" data-action=\"cancel\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"cancel") || (depth0 != null ? lookupProperty(depth0,"cancel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cancel","hash":{},"data":data,"loc":{"start":{"line":21,"column":48},"end":{"line":21,"column":60}}}) : helper)))
    + "</a>\n			</div>\n		</div>\n		<div class=\"error\" data-target=\"errors\"></div>\n		<div class=\"controls\">\n			<a href=\"javascript:;\" class=\"btn cancel\" data-action=\"close\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"cancel") || (depth0 != null ? lookupProperty(depth0,"cancel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cancel","hash":{},"data":data,"loc":{"start":{"line":26,"column":65},"end":{"line":26,"column":77}}}) : helper)))
    + "</a>\n			<a href=\"javascript:;\" class=\"btn primary disabled\" data-action=\"save\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"save") || (depth0 != null ? lookupProperty(depth0,"save") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"save","hash":{},"data":data,"loc":{"start":{"line":27,"column":74},"end":{"line":27,"column":84}}}) : helper)))
    + "</a>\n		</div>\n	</div>\n</td>\n</tr>\n</table>\n";
},"useData":true});