/* eslint-disable import/no-commonjs, import/no-unresolved, gigwalk/require-valid-flow-file-annotation */
// @noflow
require('core-js');

// Expose Libraries
require('expose-loader?$!expose-loader?jQuery!jquery');
require('expose-loader?_!underscore');
require('expose-loader?moment!moment-timezone');
require('expose-loader?Backbone!backbone');
require('expose-loader?injector!@gigwalk/injector-js');
require('expose-loader?Handlebars!handlebars/runtime');
require('script-loader!rrule');
require('script-loader!rrule/lib/nlp');

// Libraries that did not expose as expected.
global.navigatorjs = require('@gigwalk/navigator-js');

// Backbone Extensions
// Issues with badly written modules cause properties to be overwritten on backbone
// @todo File issues or shim these in a better way.
require('script-loader!backbone-super');
require('script-loader!@gigwalk/backbone-command');
require('script-loader!@gigwalk/backbone-injector');
require('script-loader!@gigwalk/backbone-recursive-tojson');

(() => {
    const _super = Backbone.Model;

    function InjectableModel(attributes, options) {
        if (options && options.injector !== undefined) {
            options.injector.injectInto(this);
        }
        _super.call(this, attributes, options);
    }

    InjectableModel.prototype = _super.prototype;
    InjectableModel.prototype.injector = 'inject';
    InjectableModel.extend = _super.prototype.constructor.extend;

    Backbone.Model = InjectableModel;
})();

/* Bootstrap extensions */
require('bootstrap');
require('script-loader!bootstrap-datepicker');
require('script-loader!timepicker');
require('script-loader!datepair-js');
require('script-loader!jquery-datepair-js');

/* Supported locales */
require('bootstrap-datepicker/js/locales/bootstrap-datepicker.es');
require('bootstrap-datepicker/js/locales/bootstrap-datepicker.fr');
require('bootstrap-datepicker/js/locales/bootstrap-datepicker.ja');

/* jQuery extensions */
require('@gigwalk/jquery-ui-sortable');

/* Misc Libraries */
require('console-polyfill');
