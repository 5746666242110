// @flow
import React from 'react';
import Helmet from 'react-helmet';
import { Route, Switch } from 'react-router';
import NoMatch from '../NoMatch';
import ProfilePage from '../ProfilePage';

export default () => (
    <div>
        <Helmet title="Member" />
        <Switch>
            <Route path="/member/:orgId/:customerId" component={ProfilePage} />
            <Route component={NoMatch} />
        </Switch>
    </div>
);
