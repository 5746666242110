// @flow
export default (theme: Object): Object => ({
    form: {
        minWidth: 260,
        maxWidth: 350,
        width: '70vw',
        padding: theme.spacing(3, 0, 5, 0),
    },
    selectInput: {
        '@global input': {
            marginLeft: 36,
            textAlign: 'center',
        },
    },
    selectPlaceholder: {
        left: 0,
        textAlign: 'center',
        width: '100%',
    },
});
