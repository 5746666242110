// @flow
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import { denormalize, schema } from 'normalizr';
import isEqual from 'react-fast-compare';
import schemas from './schemas';
import type { State as RootState } from '../../store';

type EntityIds = number | string | number[] | string[]
type IdSelector = (state: RootState, props?: any) => EntityIds | null | void;
type EntitySelector = (state: RootState, props?: any) => Object | Object[];
type SchemaMap = { [key: string]: typeof schema.Entity };

// Create a dictionary mapping an entity's key to its schema
const schemaMap = Object.values(schemas)
    .reduce((map: SchemaMap, value: mixed): SchemaMap => {
        if (value instanceof schema.Entity) {
            return { ...map, [value.key]: value };
        }
        return map;
    }, {});

// Create a custom selector creator that uses react-fast-compare for equality checks.
// We'll use this below to only return a new reference if any part of the denormalized
// result has changed
const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const getEntities = (state: RootState): Object => state.entities;

export const createEntitySelector = (key: string, idSelector?: IdSelector): EntitySelector => (
    createDeepEqualSelector(
        createSelector(
            getEntities,
            idSelector || (() => undefined),
            (entities: Object, ids: EntityIds | null | void) => {
                if (!(key in entities)) {
                    throw new Error(`Cannot find '${key}' in \`state.entities\``);
                }

                const input = !idSelector ? Object.keys(entities[key]) : ids;
                const entitySchema = schemaMap[key];

                if (Array.isArray(input)) {
                    const results = denormalize(input, [entitySchema], entities);
                    return results.filter((entity?: Object): boolean => !!entity);
                }

                return denormalize(input, entitySchema, entities);
            }
        ),
        (result: Object | Object[]) => result,
    )
);

export default {
    createEntitySelector,
    getEntities,
};
