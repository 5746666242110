// @flow
import React, { Component, Fragment } from 'react';
import cx from 'classnames';
import moment from 'moment';
import debounce from 'lodash/debounce';
import numeral from 'numeral';
import { FieldArray } from 'formik';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Trans, withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import {
    Chip,
    CircularProgress,
    Divider,
    Icon,
    List,
    ListItem,
    ListItemText,
    Paper,
    Button,
    FormControl,
    FormLabel,
    InputAdornment,
    IconButton,
} from '@material-ui/core';
import {
    Add as AddIcon,
    Clear as ClearIcon,
    DateRange as DateRangeIcon,
} from '@material-ui/icons';
import { compose } from 'recompose';
import type { Dispatch } from 'redux';
import type { Connector } from 'react-redux';
import type { ContextRouter } from 'react-router';
import type { TFunction } from 'react-i18next';
import type { Location as Gigwalk$Location } from 'gigwalk/lib/api/locationLists/types';
import { OPTIN_TYPES, SCHEDULE_TYPES } from '../../../../../browser/shared/constant/ProjectConstant';
import { USER_ROLES } from '../../../../../browser/shared/constant/UserRoles';
// @todo Create QuestionCard and QuestionFormCard components?
import QuestionCard from '../Questions/components/QuestionCard/QuestionCard';
import FormikMaskedInput from '../../../../components/FormikMaskedInput';
import FormikCheckbox from '../../../../components/FormikCheckbox';
import FormikDateTimePicker from '../../../../components/FormikDateTimePicker';
import InfiniteList from '../../components/InfiniteList';
import Step from '../../components/Step';
import SectionOverview from './components/SectionOverview';
import { actions, selectors } from './duck';
import styles from './styles';
import entitySelector from '../../../../redux/entities/entitySelector';
import type { State as RootState } from '../../../../redux/initialState';

type OwnProps = ContextRouter & {
    classes: Object,
    errors: ?Object,
    setFieldValue: (field: string, value: any) => void,
    t: TFunction,
    values: Object,
};
type StateProps = {
    canAccessPayments: boolean,
    currentBalance: number,
    editMode: boolean,
    isGrandfathered: boolean,
    locationCount: number,
    locations: Object[],
    memberCount: number,
    updatingPrice: boolean,
    user: ?Object,
};
type DispatchProps = {
    getMoreLocations: (locationListId: number) => Promise<void>,
    getPriceEstimate: (subscriptionId: number, values: Object) => Promise<number>,
    resetLocations: () => void,
};
type Props = OwnProps & StateProps & DispatchProps;

const formatCurrency = (value: any): string => numeral(value || 0).format('$0,0.00');
const formatDate = (value: any): string => moment(value).format('lll');

export class Launch extends Component<Props> {
    constructor(props: Props) {
        super(props);
        this.handleGigPriceChange = debounce(this.handleGigPriceChange, 500);
    }

    componentDidMount() {
        const { editMode, getMoreLocations, getPriceEstimate, locationCount, locations, match, setFieldValue, values } = this.props;
        const { locationListId, needsPublicWorkforce, projectFund } = values;
        const subscriptionId = parseInt(match.params.subscriptionId, 10);

        if (locationListId && locations.length === 0 && locationCount > 0) {
            getMoreLocations(locationListId);
        }

        if (needsPublicWorkforce) {
            getPriceEstimate(subscriptionId, values)
                .then((priceEstimate) => {
                    const amountDue = editMode && priceEstimate != null
                        ? priceEstimate - projectFund
                        : priceEstimate;

                    setFieldValue('priceEstimate', priceEstimate);
                    setFieldValue('amountDue', amountDue);
                });
        }
    }

    componentDidUpdate() {
        const { getMoreLocations, locationCount, locations, values } = this.props;
        const { locationListId } = values;

        if (locationListId && locations.length === 0 && locationCount > 0) {
            getMoreLocations(locationListId);
        }
    }

    componentWillUnmount() {
        // Reset locationIds on unmount to make sure modifications to the location list are
        // always reflected on the Launch page
        const { resetLocations } = this.props;
        resetLocations();
    }

    handleInfiniteLoad = (): Promise<void> => {
        const { getMoreLocations, values } = this.props;
        const { locationListId } = values;
        return locationListId ? getMoreLocations(locationListId) : Promise.resolve();
    };

    handleGigPriceChange = () => {
        const { editMode, getPriceEstimate, match, setFieldValue, values } = this.props;
        const { projectFund } = values;
        const subscriptionId = parseInt(match.params.subscriptionId, 10);

        getPriceEstimate(subscriptionId, values)
            .then((priceEstimate) => {
                const amountDue = editMode && priceEstimate != null
                    ? priceEstimate - projectFund
                    : priceEstimate;

                setFieldValue('priceEstimate', priceEstimate);
                setFieldValue('amountDue', amountDue);
            });
    };

    renderQuestions = (): React$Element<*> => {
        const { classes, values } = this.props;
        const { questionList } = values;

        return (
            <ul className={classes.questionList}>
                {questionList.map((question: Object, index: number): React$Element<any> => (
                    <li key={question.id} className={classes.question}>
                        <QuestionCard
                          {...question}
                          index={index}
                          expanded
                          readOnly
                        />
                    </li>
                ))}
            </ul>
        );
    };

    renderWorkers(): React$Element<any> {
        const { classes, memberCount, t, values } = this.props;
        const { certifications, groups, needsApproval, needsPublicWorkforce, optinType, twoWayRatingEnabled } = values;
        const summary = [];

        const transProps = {
            parent: 'div',
            className: classes.workerSummaryItem,
        };

        summary.push(
            needsPublicWorkforce
                ? (
                    <Trans
                      {...transProps}
                      defaults={t('projectBuilder.launch.publicWorkforce')}
                      components={['I am launching a', <strong style={{ fontWeight: 600 }}>crowdsourcing</strong>, 'project']}
                    />
                )
                : (
                    <Trans
                      {...transProps}
                      defaults={t('projectBuilder.launch.privateWorkforce')}
                      components={['I am using my own', <strong style={{ fontWeight: 600 }}>private workforce</strong>]}
                    />
                )
        );

        switch (optinType) {
            case OPTIN_TYPES.DOUBLE_OPTIN:
                summary.push(
                    <Trans
                      {...transProps}
                      defaults={t('projectBuilder.launch.doubleOptIn')}
                      components={['I will', <strong style={{ fontWeight: 600 }}>approve worker applications</strong>, 'to start gigs']}
                    />
                );
                break;

            case OPTIN_TYPES.SIMPLE_OPTIN:
                summary.push(
                    <Trans
                      {...transProps}
                      defaults={t('projectBuilder.launch.simpleOptIn')}
                      components={[<strong style={{ fontWeight: 600 }}>No approval</strong>, 'is necessary for workers to start gigs']}
                    />
                );
                break;

            case OPTIN_TYPES.SYSTEM_APPROVED_OPTIN:
                summary.push(
                    <Trans
                      {...transProps}
                      defaults={t('projectBuilder.launch.systemApprovedOptIn')}
                      components={[
                          'I will let Gigwalk',
                          <strong style={{ fontWeight: 600 }}>automatically select the best applicant</strong>,
                          'for the job',
                      ]}
                    />
                );
                break;

            case OPTIN_TYPES.NO_OPTIN:
            default:
                break;
        }

        if (needsApproval) {
            summary.push(
                <Trans
                  {...transProps}
                  defaults={t('projectBuilder.launch.needsApproval')}
                  components={['I will', <strong style={{ fontWeight: 600 }}>approve gigs</strong>, 'after they are submitted']}
                />
            );
        }

        if (twoWayRatingEnabled) {
            summary.push(
                <Trans
                  {...transProps}
                  defaults={t('projectBuilder.launch.twoWayRatingEnabled')}
                  components={[
                      'I will',
                      <strong style={{ fontWeight: 600 }}>rate workers</strong>,
                      'on how well they completed the gig',
                  ]}
                />
            );
        }

        if (groups.length > 0) {
            summary.push(
                <div className={classes.workerSummaryItem}>
                    <Trans
                      parent="div"
                      defaults={t('projectBuilder.launch.invitedGroups', { workerCount: memberCount, groupCount: groups.length })}
                      components={['I am inviting', <strong style={{ fontWeight: 600 }}>workers</strong>, 'from the following groups']}
                    />
                    <div className={classes.chipList}>
                        {groups.map((group: Object): React$Element<any> => (
                            <Chip
                              className={classes.chip}
                              key={group.id}
                              label={group.name}
                            />
                        ))}
                    </div>
                </div>
            );
        }

        if (certifications.length > 0) {
            summary.push(
                <div className={classes.workerSummaryItem}>
                    <Trans
                      parent="div"
                      defaults={t('projectBuilder.launch.requiredSkills', { count: certifications.length })}
                      components={[
                          'Workers',
                          <strong style={{ fontWeight: 600 }}>must be certified</strong>,
                          'to accept gigs from this project',
                      ]}
                    />
                    <div className={classes.chipList}>
                        {certifications.map((certification: Object): React$Element<any> => (
                            <Chip
                              className={classes.chip}
                              key={certification.id}
                              label={certification.title}
                            />
                        ))}
                    </div>
                </div>
            );
        }

        return <div>{summary}</div>;
    }

    renderLocations(): React$Element<any> {
        const { classes, locations, locationCount, t, values } = this.props;
        const { workerCount } = values;
        return (
            <div>
                <InfiniteList maxHeight={500} rowHeight={78} onInfiniteLoad={this.handleInfiniteLoad}>
                    {locations.map((location: Gigwalk$Location): React$Element<any> => (
                        <ListItem
                          className={classes.location}
                          component="div"
                          key={location.relation_id}
                        >
                            <ListItemText
                              primary={location.title}
                              secondary={location.title !== location.formatted_address ? location.formatted_address : null}
                              primaryTypographyProps={{ color: 'inherit' }}
                              secondaryTypographyProps={{ color: 'inherit' }}
                            />
                        </ListItem>
                    ))}
                </InfiniteList>
                <Trans
                  parent="div"
                  defaults={t('projectBuilder.launch.locationInfo', { locationCount, workerCount: workerCount || 1 })}
                  components={[
                      <strong style={{ fontWeight: 600 }}>locationCount</strong>,
                      'locations,',
                      <strong style={{ fontWeight: 600 }}>workerCount</strong>,
                      'workers per location',
                  ]}
                />
            </div>
        );
    }

    renderPriceSummary(): React$Element<any> {
        const { classes, currentBalance, editMode, errors, isGrandfathered, t, updatingPrice, values } = this.props;
        const { amountDue, priceEstimate, projectFund } = values;

        const listItems = [];

        if (isGrandfathered) {
            listItems.push(
                <ListItem key="gigPrice" className={classes.priceSummaryItem}>
                    <span>{t('projectBuilder.launch.ticketPrice')}</span>
                    <FormikMaskedInput
                      classes={{
                          root: classes.gigPrice,
                          input: classes.gigPriceInput,
                      }}
                      name="gigPrice"
                      onChange={this.handleGigPriceChange}
                      options={{
                          numeral: true,
                          numeralThousandsGroupStyle: 'thousand',
                          numeralPositiveOnly: true,
                          prefix: '$',
                          noImmediatePrefix: true,
                          rawValueTrimPrefix: true,
                      }}
                      required
                    />
                </ListItem>
            );
        }

        listItems.push(
            <ListItem key="currentBalance" className={classes.priceSummaryItem}>
                <span>{t('projectBuilder.launch.currentBalance')}</span>
                <span>{numeral(currentBalance).format('$0,0.00')}</span>
            </ListItem>
        );

        if (editMode) {
            listItems.push(
                <ListItem key="originalPrice" className={classes.priceSummaryItem}>
                    <span>{t('projectBuilder.launch.originalPrice')}</span>
                    <div>{formatCurrency(projectFund)}</div>
                </ListItem>,
                <ListItem key="newPrice" className={classes.priceSummaryItem}>
                    <span>{t('projectBuilder.launch.newPrice')}</span>
                    <div>{formatCurrency(priceEstimate)}</div>
                </ListItem>
            );
        }

        listItems.push(
            <ListItem key="amountDue" className={classes.priceSummaryItem}>
                <span>
                    {amountDue < 0
                        ? t('projectBuilder.launch.amountRefunded')
                        : t('projectBuilder.launch.totalDue')}
                </span>
                <span className={cx(classes.priceTotal, { [classes.overBalance]: errors && errors.amountDue })}>
                    {updatingPrice
                        ? <CircularProgress size={22} />
                        : formatCurrency(Math.abs(amountDue))}
                </span>
            </ListItem>
        );

        return (
            <List className={classes.priceSummary}>
                {listItems.map((listItem: React$Element<any>, index: number) => (
                    <Fragment>
                        {listItem}
                        {index < listItems.length - 1 ? <Divider /> : null}
                    </Fragment>
                ))}
            </List>
        );
    }

    renderAdminTools(): React$Element<any> {
        const { classes, editMode, isGrandfathered, t, values } = this.props;
        const { optinType, resWindowHours, needsPublicWorkforce } = values;

        return (
            <Paper className={classes.adminTools} elevation={0} square>
                <h2>{t('projectBuilder.launch.adminUseOnly')}</h2>
                {!isGrandfathered && needsPublicWorkforce
                    ? (
                        <FormikMaskedInput
                          fullWidth
                          label={t('projectBuilder.launch.ticketPrice')}
                          labelPlacement="top"
                          margin="dense"
                          name="gigPrice"
                          options={{
                              numeral: true,
                              numeralThousandsGroupStyle: 'thousand',
                              numeralPositiveOnly: true,
                              prefix: '$',
                              noImmediatePrefix: true,
                              rawValueTrimPrefix: true,
                          }}
                          required
                          variant="outlined"
                        />
                    )
                    : null}
                {needsPublicWorkforce
                    ? (
                        <FormikMaskedInput
                          fullWidth
                          label={t('projectBuilder.launch.nearTicketDistanceLabel')}
                          labelPlacement="top"
                          margin="dense"
                          name="nearTicketDistance"
                          options={{
                              numeral: true,
                              numeralThousandsGroupStyle: 'thousand',
                              numeralPositiveOnly: true,
                          }}
                          required
                          variant="outlined"
                        />
                    )
                    : null}
                {needsPublicWorkforce
                    ? (
                        <FormikMaskedInput
                          fullWidth
                          label={t('projectBuilder.launch.overSamplingTargetLabel')}
                          labelPlacement="top"
                          margin="dense"
                          name="oversamplingTarget"
                          options={{
                              numeral: true,
                              numeralThousandsGroupStyle: 'thousand',
                              numeralPositiveOnly: true,
                          }}
                          variant="outlined"
                        />
                    )
                    : null}
                {needsPublicWorkforce
                    ? (
                        <FormikMaskedInput
                          fullWidth
                          label={t('projectBuilder.launch.overSamplingOverageLabel')}
                          labelPlacement="top"
                          margin="dense"
                          name="oversamplingOverage"
                          options={{
                              numeral: true,
                              numeralThousandsGroupStyle: 'thousand',
                              numeralPositiveOnly: true,
                          }}
                          variant="outlined"
                        />
                    )
                    : null}
                <FormikDateTimePicker
                  customInputIcon={<DateRangeIcon />}
                  label={t('projectBuilder.launch.submitDeadline')}
                  margin="dense"
                  name="submitDeadline"
                  showClearDate
                />
                {optinType === OPTIN_TYPES.SYSTEM_APPROVED_OPTIN
                    ? (
                        <FormControl margin="normal">
                            <FormLabel>
                                {t('projectBuilder.launch.resWindowHours')}
                            </FormLabel>
                            <FieldArray name="resWindowHours">
                                {(arrayHelpers) => (
                                    <ul className={classes.resWindowHours}>
                                        {resWindowHours.map((option: Object, index: number) => (
                                            <li key={index}>
                                                <FormikMaskedInput
                                                  endAdornment={(
                                                      <InputAdornment position="end">
                                                          {t('projectBuilder.launch.hours')}
                                                      </InputAdornment>
                                                  )}
                                                  margin="dense"
                                                  name={`resWindowHours[${index}]`}
                                                  options={{
                                                      numeral: true,
                                                      numeralThousandsGroupStyle: 'thousand',
                                                      numeralPositiveOnly: true,
                                                  }}
                                                  readOnly={editMode}
                                                  variant="outlined"
                                                />
                                                {!editMode
                                                    ? (
                                                        <IconButton
                                                          className={classes.removeButton}
                                                          onClick={() => arrayHelpers.remove(index)}
                                                        >
                                                            <ClearIcon />
                                                        </IconButton>
                                                    )
                                                    : null}
                                            </li>
                                        ))}
                                        {!editMode
                                            ? (
                                                <li>
                                                    <Button color="primary" onClick={() => arrayHelpers.push('')}>
                                                        <AddIcon />
                                                        {t('projectBuilder.launch.addOption')}
                                                    </Button>
                                                </li>
                                            )
                                            : null}
                                    </ul>
                                )}
                            </FieldArray>
                        </FormControl>
                    )
                    : null}
                {optinType === OPTIN_TYPES.SYSTEM_APPROVED_OPTIN
                    ? (
                        <FormikCheckbox
                          color="primary"
                          label={t('projectBuilder.launch.fifoAssign')}
                          name="fifoAssign"
                        />
                    )
                    : null}
                {needsPublicWorkforce
                    ? (
                        <FormikCheckbox
                          color="primary"
                          label={t('projectBuilder.launch.allowGalleryUpload')}
                          name="allowGalleryUpload"
                        />
                    )
                    : null}
            </Paper>
        );
    }

    render() {
        const { classes, t, user, values } = this.props;
        const {
            asapEndDate,
            description,
            needsPublicWorkforce,
            rangeEndDate,
            rangeStartDate,
            scheduleType,
            title,
        } = values;

        const userRole = user ? user.role : null;
        const shouldRenderAdminTools = (
            userRole === USER_ROLES.PLATFORM_ADMIN
            || userRole === USER_ROLES.SELF_SERVICE
            || (!needsPublicWorkforce && userRole === USER_ROLES.SUPER_ADMIN)
        );

        return (
            <Step title={t('projectBuilder.launch.header')}>
                <form className={classes.form}>
                    <SectionOverview label={t('projectBuilder.launch.title')}>
                        <div className={classes.title}>
                            {title}
                        </div>
                    </SectionOverview>
                    <SectionOverview label={t('projectBuilder.launch.description')}>
                        <div
                          className={classes.description}
                          dangerouslySetInnerHTML={{ __html: description }}
                        />
                    </SectionOverview>
                    <SectionOverview label={t('projectBuilder.launch.dates')}>
                        {scheduleType === SCHEDULE_TYPES.ASAP
                            ? (
                                <div className={classes.dates}>
                                    <span>{t('projectBuilder.launch.now')}</span>
                                    <Icon className={classes.rightArrowIcon}>arrow_right_alt</Icon>
                                    <div>{formatDate(asapEndDate)}</div>
                                </div>
                            )
                            : (
                                <div className={classes.dates}>
                                    <div>{formatDate(rangeStartDate)}</div>
                                    <Icon className={classes.rightArrowIcon}>arrow_right_alt</Icon>
                                    <div>{formatDate(rangeEndDate)}</div>
                                </div>
                            )}
                    </SectionOverview>
                    <SectionOverview label={t('projectBuilder.launch.locations')}>
                        {this.renderLocations()}
                    </SectionOverview>
                    <SectionOverview label={t('projectBuilder.launch.workers')}>
                        {this.renderWorkers()}
                    </SectionOverview>
                    <SectionOverview label={t('projectBuilder.launch.questions')}>
                        {this.renderQuestions()}
                    </SectionOverview>
                    {needsPublicWorkforce
                        ? <SectionOverview>{this.renderPriceSummary()}</SectionOverview>
                        : null}
                    {shouldRenderAdminTools
                        ? <SectionOverview>{this.renderAdminTools()}</SectionOverview>
                        : null}
                </form>
            </Step>
        );
    }
}

const locationListSelector = entitySelector('locationLists');
const organizationSelector = entitySelector('organizations');
const subscriptionSelector = entitySelector('subscriptions');

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => {
    const { user } = state.session;
    const { match, values } = props;
    const organization = organizationSelector(state, match.params.orgId);
    const subscription = subscriptionSelector(state, match.params.subscriptionId);

    return {
        ...state.projectBuilder.launch,
        editMode: subscription ? subscription.state === 'ACTIVE' : false,
        canAccessPayments: state.session.features.canAccessPayments,
        currentBalance: organization ? organization.current_balance : 0,
        isGrandfathered: organization ? organization.grandfathered : false,
        locationCount: locationListSelector(state, values.locationListId, 'location_count') || 0,
        locations: selectors.getLocations(state, props) || [],
        memberCount: selectors.getMemberCount(state, props),
        user,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    getMoreLocations: (locationListId: number): Promise<void> => dispatch(actions.getMoreLocations(locationListId)),
    getPriceEstimate: (subscriptionId: number, values: Object): Promise<number> => dispatch(actions.getPriceEstimate(subscriptionId, values)),
    resetLocations: () => dispatch(actions.resetLocations()),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
const enhance = compose(
    withStyles(styles, { name: 'Launch' }),
    withTranslation(),
    withRouter,
    connector,
);
export default enhance(Launch);
