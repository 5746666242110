// @flow
export const namespace = 'g2/sideNav';

export const TOGGLE = `${namespace}/TOGGLE`;
export const EXPAND = `${namespace}/EXPAND`;
export const COLLAPSE = `${namespace}/COLLAPSE`;
export const START_TRANSITION = `${namespace}/START_TRANSITION`;
export const END_TRANSITION = `${namespace}/END_TRANSITION`;

export default {
    TOGGLE,
    EXPAND,
    COLLAPSE,
    START_TRANSITION,
    END_TRANSITION,
};
