// @flow
import { handleActions } from 'redux-actions';
import type { ActionType } from 'redux-actions';
import types from './types';
import type { resolveAddressSuccess } from './actions';

export type State = {
    addressLine1: string,
    addressLine2: string,
    city: string,
    state: string,
    zipCode: string,
};

export const init: State = {
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
};

export default handleActions({
    [types.RESOLVE_ADDRESS_SUCCESS]: (state: State, action: ActionType<resolveAddressSuccess>) => ({
        ...state,
        ...action.payload,
    }),
}, init);
