// @flow
export default (theme: Object) => ({
    card: {
        position: 'relative',
        overflow: 'visible',
        background: theme.palette.common.white,

        '&$collapsed': {
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            border: [[1, 'solid', theme.palette.grey[300]]], // $neutral-7
            borderBottom: [[3, 'solid', theme.palette.grey[300]]], // $neutral-7
            boxShadow: 'none',
        },
        '&$expanded': {
            borderTop: [[1, 'solid', theme.palette.grey[100]]], // $neutral-2
            borderLeft: [[2, 'solid', theme.palette.primary.light]],
            boxShadow: '2px 2px 6px 0 rgba(0, 0, 0, 0.15)',
        },
        '&$dragging': {
            opacity: 0,
        },
    },
    cardHeaderAction: {
        alignSelf: 'center',
        marginTop: 0,
    },
    sequence: {
        position: 'absolute',
        top: 0,
        left: 0,
        margin: 4,
        fontSize: 9,
        color: theme.palette.grey[500],
    },
    questionForm: {
        display: 'flex',
        flexWrap: 'wrap',
        paddingBottom: 16,
    },
    field: {
        margin: theme.spacing(1, 2),
    },
    description: {
        flexGrow: 2,

        '& > input': {
            fontWeight: 600,
            fontSize: '1rem',
        },
    },
    dropdown: {
        // marginBottom: theme.spacing(1),
        // background: $input-background-color,
        // flexGrow: 1,
        //
        // '&.disabled': {
        //     background: $neutral-7,
        //     color: $disabled-input-color,
        // },
    },
    questionText: {
        flexBasis: '100%',
        fontSize: 16,
    },
    preview: {
        padding: [[16, 16, 0, 16]],
        flexBasis: '100%',
    },
    cardActions: {
        marginLeft: 16,
        justifyContent: 'flex-end',
        borderTop: [[1, 'solid', theme.palette.grey[400]]], // $border-color $neutral-13
    },
    avatar: {
        color: '#fff',
        backgroundColor: '#00c7ae', // $success-color,
    },
    progressBar: {
        position: 'absolute',
        top: 0,
    },
    separator: {
        borderLeft: [[1, 'solid', theme.palette.grey[400]]],
        height: 32,
        margin: [[0, 16]],
        width: 0,
    },
    attachmentsLabel: {
        lineHeight: 1,
        marginBottom: 5,
        fontSize: 12,
    },
    attachment: {
        maxWidth: '100%',
    },
    attachmentLabel: {
        display: 'block',
        width: '100%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    requiredSwitch: {},
    collapsed: {},
    expanded: {},
    dragging: {},
});
