// @flow
export default (theme: Object) => ({
    root: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
    },
    question: {
        flex: 1,
    },
    metadata: {
        flex: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        '& > :not(:last-child)': {
            marginBottom: theme.spacing(2),
        },
    },
    signaturePad: {
        marginTop: theme.spacing(1),
        maxWidth: 350,
    },
    canvasContainer: {
        position: 'relative',
        width: '100%',
    },
    canvas: {
        width: '100%',
        borderBottom: '1px solid black',
        // cursor: 'url(/public/img/draw_cursor.png) 2 16, auto',
    },
    signHereIcon: {
        position: 'absolute',
        bottom: theme.spacing(1.5),
        left: 0,
    },
    actions: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',

        '& > *:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },
});
