// @flow
import DropdownList from './DropdownList';
import type { DropdownListProps } from './DropdownList';

type SimpleDropdownProps = DropdownListProps & {
    items?: Array<Object>,
};

/**
 * @class (View) DropdownList
 * @constructor
 */
const SimpleDropdown = DropdownList.extend({

    className: 'dropdown-list basic',

    /**
     * @method initialize
     */
    initialize(options?: SimpleDropdownProps = {}) {
        const props = {
            items: [],
            labelKey: 'label',
            ...options,
        };

        this._super(props);

        const { items } = props;
        this.setItems(items);
        this.setMenuLabel(this.labelKey);

        // TODO: Calling render in initialize may be problematic for sub-classes if they invoke this._super
        // Render may be called multiple times before all internal properties are initialized.
        this.render();
    },

    /**
     * @method setFilter
     * @param attr
     */
    setFilter(attr: string) {
        this.filterKey = attr;

        if (this.active) {
            const val = this._lastVal;
            this._lastVal = '';
            this.search(val);
        }
    },

    setItems(items: Array<Object>) {
        // Collection of all items that the dropdown holds
        this.collection = new Backbone.Collection();
        this.collection.set(items);

        this.collection.each((item: Backbone.Model) => {
            // Ensure each item in the collection has an id attribute.
            if (!item.has('id')) {
                item.set('id', item.cid);
            }
        });

        this.menu.set(this.collection.slice(0, this.menuSize));
    },

    getItem(selector: number | string | Object): ?Object {
        let item;

        if (typeof selector !== 'string' && typeof selector !== 'number') {
            item = selector.id
                ? this.collection.get(selector.id)
                : this.collection.findWhere(selector);
        } else {
            item = this.collection.get(selector);
        }

        if (item) {
            return item.toJSON();
        }
    },

    search(val: string) {
        if (val.length >= 0) {
            const regex = new RegExp(`^.*${val}.*$`, 'i');
            const matches = this.collection.filter((item: Backbone.Model): boolean => {
                const value = item.get(this.filterKey || this.labelKey);

                if (typeof value === 'string' || typeof value === 'number') {
                    return value.toString().search(regex) !== -1;
                }

                return false;
            }, this);

            this.menu.set(matches.slice(0, this.menuSize));
        } else {
            this.menu.set(this.collection);
        }
    },

    /**
     * similar as a custom toString()
     * @method toString
     * @returns {string}
     */
    toString(): string {
        return 'SimpleDropdown';
    },

});

export default SimpleDropdown;
