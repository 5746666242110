// @flow
export const LOAD_CUSTOMER = 'g/profilePage/LOAD_CUSTOMER';
export const LOAD_CUSTOMER_SUCCESS = `${LOAD_CUSTOMER}_SUCCESS`;
export const LOAD_CUSTOMER_ERROR = `${LOAD_CUSTOMER}_ERROR`;

export default {
    LOAD_CUSTOMER,
    LOAD_CUSTOMER_ERROR,
    LOAD_CUSTOMER_SUCCESS,
};
