// @flow
// $FlowIssue need to update to a more recent flow version
import React, { forwardRef, useEffect, useState, useMemo } from 'react';
import { load, GPSHelper, TagValues } from 'piexifjs';
import cx from 'classnames';
import moment from 'moment-timezone';
import GeoPoint from 'geopoint';
import { PulseLoader } from 'react-spinners';
import { Marker } from 'react-google-maps';
import { Trans, useTranslation } from 'react-i18next';
import { DialogContent, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import {
    DateRange as DateRangeIcon,
    ErrorOutline as ErrorOutlineIcon,
    Place as PlaceIcon,
} from '@material-ui/icons';
import logger from '../../util/logger';
import GoogleMap from '../GoogleMap';
import PhotoMarker from '../icons/PhotoMarker';
import RunMarker from '../icons/RunMarker';
import renderToDataUrl from '../../util/renderToDataUrl';

type Props = {
    classes: Object,
    gigLocation: {
        address: string,
        lat: number,
        lng: number,
    },
    photoUrl: string,
};

export const Container = forwardRef((props: Props, ref: Object) => {
    const { classes, photoUrl, gigLocation } = props;

    const { t } = useTranslation();

    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(false);
    const [exifData, setExifData] = useState(null);

    useEffect(() => {
        if (photoUrl) {
            setLoading(true);
            setExifData(null);

            // Append query string param to prevent cors issue in older versions of Chrome
            // See https://serverfault.com/questions/856904/chrome-s3-cloudfront-no-access-control-allow-origin-header-on-initial-xhr-req
            const url = new URL(photoUrl);
            url.searchParams.append('exif', '1');

            fetch(new Request(url.toString(), { mode: 'cors' }))
                .then((resp: Response) => resp.blob())
                .then((blob: Blob) => (
                    new Promise((resolve, reject) => {
                        const fileReader = new FileReader();
                        fileReader.onload = (event) => resolve(event.target.result);
                        fileReader.onerror = (err) => reject(err);
                        fileReader.readAsDataURL(blob);
                    })
                ))
                .then((dataURL: string) => {
                    try {
                        const data = load(dataURL);
                        setExifData(data);
                    } catch (e) {
                        logger.warn(`Unable to load EXIF data for image '${photoUrl}'`);
                    }
                })
                .catch(() => setErrorMessage('exifViewer.loadError'))
                .then(() => setLoading(false));
        }
    }, [photoUrl]);

    const position = useMemo(() => {
        if (exifData && exifData.GPS) {
            const {
                [TagValues.GPSIFD.GPSLatitude]: latDeg,
                [TagValues.GPSIFD.GPSLatitudeRef]: latRef,
                [TagValues.GPSIFD.GPSLongitude]: lngDeg,
                [TagValues.GPSIFD.GPSLongitudeRef]: lngRef,
            } = exifData.GPS;

            if (latDeg && latRef && lngDeg && lngRef) {
                return {
                    lat: GPSHelper.dmsRationalToDeg(latDeg, latRef),
                    lng: GPSHelper.dmsRationalToDeg(lngDeg, lngRef),
                };
            }
        }
        return null;
    }, [exifData]);

    const datetime = useMemo(() => {
        if (exifData) {
            // There are several fields that may hold timestamp data for the photo, so let's check
            // each one and return the first non-null value we find. The order below is what I perceive
            // to be the most to least precise/accurate

            if (exifData.GPS) {
                const dateStamp = exifData.GPS[TagValues.GPSIFD.GPSDateStamp];
                const timeStamp = exifData.GPS[TagValues.GPSIFD.GPSTimeStamp];
                if (dateStamp && timeStamp) {
                    const time = timeStamp.map(([magnitude, units]) => magnitude / units).join(':');
                    return moment.utc(`${dateStamp} ${time}`, 'YYYY:MM:DD HH:mm:ss');
                }
            }

            if (exifData.Exif) {
                const dateTimeOriginal = exifData.Exif[TagValues.ExifIFD.DateTimeOriginal];
                const dateTimeDigitized = exifData.Exif[TagValues.ExifIFD.DateTimeDigitized];
                if (dateTimeOriginal) {
                    return moment.utc(dateTimeOriginal, 'YYYY:MM:DD HH:mm:ss');
                }
                if (dateTimeDigitized) {
                    return moment.utc(dateTimeDigitized, 'YYYY:MM:DD HH:mm:ss');
                }
            }

            if (exifData['0th']) {
                const dateTime = exifData['0th'][TagValues.ImageIFD.DateTime];
                if (dateTime) {
                    return moment.utc(dateTime, 'YYYY:MM:DD HH:mm:ss');
                }
            }
        }
        return null;
    }, [exifData]);

    const distance = useMemo(() => {
        if (position) {
            const p1 = new GeoPoint(position.lat, position.lng);
            const p2 = new GeoPoint(gigLocation.lat, gigLocation.lng);
            return Math.round(p1.distanceTo(p2) * 100) / 100;
        }
        return null;
    }, [gigLocation, position]);

    if (loading) {
        return (
            <DialogContent ref={ref} className={cx(classes.dialogContent, classes.loading)}>
                <PulseLoader color="#3168aa" loading size={8} margin="1.5px" />
            </DialogContent>
        );
    }

    if (errorMessage) {
        return (
            <DialogContent ref={ref} className={cx(classes.dialogContent, classes.error)}>
                <ErrorOutlineIcon className={classes.errorIcon} />
                <Typography>
                    {t(errorMessage)}
                </Typography>
            </DialogContent>
        );
    }

    const photoTimestamp = datetime && datetime.isValid()
        ? datetime.format('lll (z)')
        : t('exifViewer.timestampNotFound');

    const gpsLocation = position
        ? (
            <Trans
              defaults={t('exifViewer.distanceFromGig', { distance, address: gigLocation.address })}
              components={[
                  'Photo taken',
                  <strong style={{ fontWeight: 600 }}>distance</strong>,
                  'from gig location',
              ]}
            />
        )
        : t('exifViewer.gpsDataNotFound');

    const svgProps = {
        height: 40,
        width: 40,
        xmlns: 'http://www.w3.org/2000/svg',
    };
    const photoMarkerIcon = <PhotoMarker {...svgProps} fill="#00c7ae" />;
    const runMarkerIcon = <RunMarker {...svgProps} width={60} height={60} fill="#3168aa" />;

    return (
        <DialogContent ref={ref} className={classes.dialogContent}>
            <GoogleMap
              defaultCenter={{ lat: gigLocation.lat, lng: gigLocation.lng }}
              defaultOptions={{
                  fullscreenControl: false,
                  mapTypeControl: false,
                  streetViewControl: false,
              }}
              defaultZoom={15}
              height={250}
              width={350}
            >
                {position
                    ? (
                        <Marker
                          icon={{ url: renderToDataUrl(photoMarkerIcon, 'image/svg+xml') }}
                          position={position}
                        />
                    )
                    : null}
                <Marker
                  icon={{ url: renderToDataUrl(runMarkerIcon, 'image/svg+xml') }}
                  position={{ lat: gigLocation.lat, lng: gigLocation.lng }}
                />
            </GoogleMap>
            <List disablePadding>
                <ListItem divider>
                    <ListItemIcon>
                        <DateRangeIcon />
                    </ListItemIcon>
                    <ListItemText primary={photoTimestamp} />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <PlaceIcon />
                    </ListItemIcon>
                    <ListItemText primary={gpsLocation} />
                </ListItem>
            </List>
        </DialogContent>
    );
});

export default Container;
