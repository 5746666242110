// @flow
import React, { Component } from 'react';
import type { ComponentType } from 'react';

type State = {
    ready: boolean,
    intervalId: ?number,
};

const waitForCondition = (condition: () => boolean, interval: number = 10) => (
    <Props: {}>(WrappedComponent: ComponentType<Props>): ComponentType<Props> => (
        class WrapperComponent extends Component<Props, State> {
            constructor(props: Props) {
                super(props);
                this.state = {
                    ready: false,
                    intervalId: null,
                };
            }

            UNSAFE_componentWillMount() {
                const { ready } = this.state;
                const isReady = condition();
                if (isReady !== ready) {
                    this.setState({ ready: isReady });
                }
            }

            componentDidMount() {
                const { ready } = this.state;
                if (!ready) {
                    this.setInterval();
                }
            }

            componentWillUnmount() {
                this.clearInterval();
            }

            setInterval() {
                const intervalId: number = setInterval(() => {
                    if (condition()) {
                        this.clearInterval();
                        this.setState({
                            ready: true,
                        });
                    }
                }, interval);
                this.setState({ intervalId });
            }

            clearInterval() {
                const { intervalId } = this.state;
                if (intervalId != null) clearInterval(intervalId);
            }

            render() {
                const { ready } = this.state;
                if (ready) {
                    return <WrappedComponent {...this.props} />;
                }
                return null;
            }
        }
    )
);

export default waitForCondition;
