// @flow
/* global google */
import React, { Component, Fragment } from 'react';
import qs from 'qs';
import { connect } from 'react-redux';
import { Trans, withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import { IconButton, ListItemText, Toolbar, Tooltip } from '@material-ui/core';
import { Check as CheckIcon, Clear as ClearIcon } from '@material-ui/icons';
import { compose } from 'recompose';
import type { Connector } from 'react-redux';
import type { TFunction } from 'react-i18next';
import type { ContextRouter } from 'react-router';
import highlightMatch from '../../../../util/highlightMatch';
import FilterSelect from '../../../../components/FilterSelect';
import encodeGeoFilter from '../../utils/encodeGeoFilter';
import { selectors } from './duck';
import styles from './styles';
import type { State as RootState } from '../../../../redux/initialState';

type OwnProps = ContextRouter & {
    classes: Object,
    t: TFunction,
};
type StateProps = {
    geoFilterValue: Object[],
    search: {
        geo?: Object[],
        status?: string[],
    },
    statusFilterValue: Object[],
    statusFilterOptions: Object[],
};
type DispatchProps = {};
type Props = OwnProps & StateProps & DispatchProps;

const noFilters = {
    geo: undefined,
    status: undefined,
};

const formatOptionLabel = (option: Object, meta: Object) => {
    const { value: filter } = option;
    const primary = highlightMatch(filter.value.primary, meta.inputValue);
    const secondary = highlightMatch(filter.value.secondary || '', meta.inputValue);

    return (
        <Fragment>
            <ListItemText primary={primary} secondary={secondary} />
            {meta.isSelected ? <CheckIcon /> : null}
        </Fragment>
    );
};

export class FilterToolbar extends Component<Props> {
    loadGeoFilterOptions = (inputValue: string): Promise<Object[]> => {
        const promise = new Promise((resolve, reject) => {
            const service = new google.maps.places.AutocompleteService();
            const params = {
                input: inputValue,
                types: ['(regions)'],
            };
            const callback = (predictions: ?Object[], status: string) => {
                switch (status) {
                    case google.maps.places.PlacesServiceStatus.OK:
                    case google.maps.places.PlacesServiceStatus.ZERO_RESULTS:
                        resolve(predictions || []);
                        break;

                    default:
                        reject(status);
                        break;
                }
            };
            service.getPlacePredictions(params, callback);
        });

        return promise
            .then((places: Object[]): Object[] => (
                places
                    .filter((prediction: Object): Object => (
                        !prediction.types.some((type: string) => (
                            ['country', 'administrative_area_level_2', 'sublocality'].includes(type)
                        ))
                    ))
                    .map((prediction: Object): Object => {
                        const filterKey = prediction.types.reduce((key, type) => {
                            if (type === 'postal_code') return 'zipcode';
                            if (type === 'locality') return 'city';
                            if (type === 'administrative_area_level_1') return 'state';
                            return key;
                        }, '');

                        const filter = {
                            key: filterKey,
                            value: {
                                primary: prediction.structured_formatting.main_text,
                                secondary: prediction.structured_formatting.secondary_text,
                            },
                        };

                        return {
                            label: filter.value.primary,
                            value: filter,
                        };
                    })
            ));
    };

    handleStatusFilterChange = (value: Object[]) => {
        const statusFilters = value.map((option: Object) => option.value);
        this.updateFilters({ status: statusFilters });
    };

    handleGeoFilterChange = (value: Object[]) => {
        const geoFilters = value.map((option: Object) => encodeGeoFilter(option.value));
        this.updateFilters({ geo: geoFilters });
    };

    handleClear = () => {
        this.updateFilters(noFilters);
    };

    updateFilters(filters: Object) {
        const { location, history, search } = this.props;

        history.replace({
            pathname: location.pathname,
            search: qs.stringify(
                { ...search, ...filters },
                { addQueryPrefix: true, encodeValuesOnly: true },
            ),
        });
    }

    renderSelectedCount = (value: Object[]) => {
        const { t } = this.props;
        let count = 0;

        if (value != null) {
            count = Array.isArray(value) ? value.length : 1;
        }

        return count
            ? (
                <Trans
                  defaults={t('gigwalkerList.filters.selectedCount', { count })}
                  components={[<strong style={{ fontWeight: 600 }}>count</strong>, 'selected']}
                />
            )
            : t('gigwalkerList.filters.any');
    };

    render() {
        const {
            classes,
            geoFilterValue,
            statusFilterOptions,
            statusFilterValue,
            t,
        } = this.props;

        return (
            <Toolbar className={classes.root}>
                <FilterSelect
                  ControlProps={{ fullWidth: true }}
                  isMulti
                  label={`${t('gigwalkerList.filters.status')}:`}
                  onChange={this.handleStatusFilterChange}
                  options={statusFilterOptions}
                  placeholder={t('gigwalkerList.filters.statusPlaceholder')}
                  renderValue={this.renderSelectedCount}
                  value={statusFilterValue}
                />
                <FilterSelect
                  ControlProps={{ fullWidth: true }}
                  formatOptionLabel={formatOptionLabel}
                  isMulti
                  label={`${t('gigwalkerList.filters.location')}:`}
                  loadOptions={this.loadGeoFilterOptions}
                  onChange={this.handleGeoFilterChange}
                  placeholder={t('gigwalkerList.filters.locationPlaceholder')}
                  renderValue={this.renderSelectedCount}
                  value={geoFilterValue}
                />
                <Tooltip title={t('gigwalkerList.filters.clearFilters')}>
                    <IconButton className={classes.clearButton} onClick={this.handleClear}>
                        <ClearIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </Toolbar>
        );
    }
}

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => ({
    ...selectors.getAPIParams(state, props),
    geoFilterValue: selectors.getGeoFilterValue(state, props),
    search: selectors.parseSearchParams(state, props),
    statusFilterOptions: selectors.getStatusFilterOptions(),
    statusFilterValue: selectors.getStatusFilterValue(state, props),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps);
const enhance = compose(
    withStyles(styles, { name: 'FilterToolbar' }),
    withTranslation(),
    connector,
);

export default enhance(FilterToolbar);
