// @flow
import { createAction } from 'redux-actions';
import { denormalize } from 'normalizr';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import type { Dispatch } from 'redux';
import type { $AxiosError } from 'axios';
import logger from '../../../util/logger';
import {
    dataType as dataTypeSchema,
    subscription as subscriptionSchema,
} from '../../../redux/entities/schemas';
import {
    fetch as fetchSubscription,
    update as updateSubscription,
    create as createSubscription,
    requestProjectReview,
} from '../../../redux/entities/subscriptions';
import { fetch as fetchGroup } from '../../../redux/entities/groups';
import { fetch as fetchCertification } from '../../../redux/entities/certifications';
import { fetch as fetchLocationList } from '../../../redux/entities/locationLists';
import {
    create as createDataType,
    update as updateDataType,
    addAttachment,
    deleteAttachment,
} from '../../../redux/entities/dataTypes';
import { getBalance } from '../../../redux/entities/organizations';
import * as session from '../../../ducks/session';
import mapValuesToSubscription from '../utils/mapValuesToSubscription';
import mapSubscriptionToValues from '../utils/mapSubscriptionToValues';
import mapQuestionToEntity from '../utils/mapQuestionToEntity';
import mapEntityToQuestion from '../utils/mapEntityToQuestion';
import types from './types';
import type { State as RootState } from '../../../redux/initialState';

type Question = {
    id: number | string,
    children?: Object[],
    description: string,
    valueType: string,
    questionText: string,
    propositions: Array<{ choice: string, alert: boolean }>,
    required: boolean,
    targetListId?: number,
    globalDataTypeId: number,
};

export const loadProjectSuccess = createAction(types.LOAD_PROJECT_SUCCESS);
export const loadProjectError = createAction(types.LOAD_PROJECT_ERROR);
export const loadProject = createAction(
    types.LOAD_PROJECT,
    (subscriptionId: number, orgId: number): Function => (
        (dispatch: Dispatch<any>): Promise<void> => (
            dispatch(fetchSubscription({ organization_subscription_id: subscriptionId }))
                .then((resp: Object) => {
                    const subscription = resp.entities.subscriptions[subscriptionId];
                    const {
                        certifications,
                        groups,
                        organization_location_lists: locationLists,
                    } = subscription;

                    const promises = [];
                    if (locationLists) {
                        locationLists.forEach((id: number) => {
                            promises.push(dispatch(fetchLocationList({ organization_location_list_id: id })));
                        });
                    }
                    if (groups) {
                        groups.forEach((id: number) => {
                            promises.push(dispatch(fetchGroup({ organization_id: orgId, group_id: id })));
                        });
                    }
                    if (certifications) {
                        certifications.forEach((id: number) => {
                            promises.push(dispatch(fetchCertification({ certification_id: id })));
                        });
                    }
                    promises.push(dispatch(getBalance({ organization_id: orgId })));

                    return Promise.all(promises);
                })
                .then(() => {
                    dispatch(loadProjectSuccess());
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(loadProjectError());
                    return Promise.reject(err);
                })
        )
    )
);

export const createProjectSuccess = createAction(types.CREATE_PROJECT_SUCCESS);
export const createProjectError = createAction(types.CREATE_PROJECT_ERROR);
export const createProject = createAction(
    types.CREATE_PROJECT,
    (orgId: number, values: Object): Function => (
        (dispatch: Dispatch<any>): Promise<number> => {
            const data = mapValuesToSubscription(values);
            return Promise.resolve()
                .then((): Promise<Object> => {
                    // Step 1: Create a new subscription using just the fields required for a DRAFT.
                    // That's it! This action doesn't do a whole lot, which is important!! If other actions
                    // are chained to this promise and an error occurs, a subscription will have been
                    // created without the consumer (ie. view) being notified of the new id.
                    const { organization_observation_target_lists: targetLists, ...subscription } = data;
                    // $FlowFixMe need to fix/update gigwalk-node type definitions
                    return dispatch(createSubscription({
                        organization_id: orgId,
                        subscriptions: [{ ...subscription, use_project_data_types: true }],
                    }));
                })
                .then((resp: Object) => {
                    dispatch(session.actions.refresh());
                    dispatch(createProjectSuccess());
                    return resp.result[0];
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(createProjectError());
                    return Promise.reject(err);
                });
        }
    )
);

export const saveProjectSuccess = createAction(types.SAVE_PROJECT_SUCCESS);
export const saveProjectError = createAction(types.SAVE_PROJECT_ERROR);
export const saveProject = createAction(
    types.SAVE_PROJECT,
    (subscriptionId: number, values: Object): Function => (
        (dispatch: Dispatch<any>, getState: () => RootState): Promise<void> => {
            const state = getState();

            // We'll be writing to this variable, so create a shallow copy of questionList to avoid
            // mutations to the form's state
            const questionList = values.questionList ? [...values.questionList] : [];

            const subscription = denormalize(subscriptionId, subscriptionSchema, state.entities);
            const subscriptionData = mapValuesToSubscription(values, subscription);
            const shouldSaveQuestionList = subscriptionData.hasOwnProperty('organization_observation_target_lists');
            const { organization_observation_target_lists: targetLists, ...data } = subscriptionData;

            // Used to store the subscription's data_type ids after saving new/duplicate questions
            let dataTypeIds = [];

            return Promise.resolve()
                .then((): ?Promise<Object[]> => {
                    // Step 1: Create global data_types for any 'new' questions added by the user
                    if (!shouldSaveQuestionList) {
                        return;
                    }

                    const createGlobalDataTypes = [];
                    questionList.forEach((question: Object, index: number) => {
                        const { attachments } = question;
                        const { id, ...dataType } = mapQuestionToEntity(question);

                        if (typeof id === 'string' && id.startsWith('new')) {
                            let dataTypeId;

                            // $FlowFixMe need to fix/update gigwalk-node type definitions
                            const promise = dispatch(createDataType({ dataType, cid: id }))
                                .then((resp: Object) => {
                                    ([dataTypeId] = resp.result);
                                    questionList[index] = {
                                        ...mapEntityToQuestion(resp.entities.dataTypes[dataTypeId]),
                                        attachments,
                                    };
                                })
                                .then(() => {
                                    const addAttachments = [];
                                    attachments.forEach((attachment) => {
                                        const params = { data_type_id: dataTypeId, ...attachment };
                                        addAttachments.push(dispatch(addAttachment(params)));
                                    });
                                    return Promise.all(addAttachments);
                                });

                            createGlobalDataTypes.push(promise);
                        }
                    });

                    return Promise.all(createGlobalDataTypes);
                })
                .then((): Promise<Object> => {
                    // Step 2: Update the subscription. This will create project data_types for any 'new' or
                    // 'duplicate' questions (including ones added via search)
                    let obsTargetLists = [];

                    if (shouldSaveQuestionList) {
                        obsTargetLists = questionList.map((question: Question) => {
                            const { children, id, globalDataTypeId, targetListId } = question;
                            const dataTypeId = typeof id === 'string' && id.startsWith('duplicate') ? globalDataTypeId : id;
                            return {
                                observation_target_list_id: targetListId,
                                data_types: [{ children, data_type_id: dataTypeId }],
                            };
                        });
                    }

                    const params = {
                        organization_subscription_id: subscriptionId,
                        subscription: obsTargetLists && obsTargetLists.length > 0
                            ? { ...data, organization_observation_target_lists: obsTargetLists }
                            : data,
                    };

                    // $FlowFixMe need to fix/update gigwalk-node type definitions
                    return dispatch(updateSubscription(params));
                })
                .then((resp: Object): ?Promise<Object[]> => {
                    const updatedSubscription = resp.original.data[0];
                    const dataTypeMap = resp.original._metadata.data_types;
                    // $FlowFixMe Object.values returns a value of type mixed[], which is incompatible with DataType[]
                    const { questionList: initialQuestionList } = mapSubscriptionToValues(updatedSubscription, Object.values(dataTypeMap));

                    // Set dataTypeIds here so we can quickly fetch from state later. Otherwise, we'd have to iterate through
                    // the subscription's organization_observation_target_lists to enumerate all data_type ids
                    dataTypeIds = Object.keys(dataTypeMap);

                    // Step 3: Update project data_types with any edits made by the user. Since we perform this step
                    // after saving the subscription, the questionList from initialValues will have the same order
                    // as seen in the form.
                    if (!shouldSaveQuestionList) {
                        return;
                    }

                    const updateProjectDataTypes = [];
                    questionList.forEach((question: Object, index: number) => {
                        const value = pick(question, ['description', 'questionText', 'propositions', 'required', 'valueType']);
                        const initialValue = pick(initialQuestionList[index], ['description', 'questionText', 'propositions', 'required', 'valueType']);
                        const isPristine = isEqual(value, initialValue);
                        const { attachments } = question;
                        const { attachments: initialAttachments, id: projectDataTypeId } = initialQuestionList[index];

                        if (!isPristine) {
                            const { id, ...dataType } = mapQuestionToEntity(question);
                            // $FlowFixMe need to fix/update gigwalk-node type definitions
                            const promise = dispatch(updateDataType({ data_type_id: projectDataTypeId, dataType }));
                            updateProjectDataTypes.push(promise);
                        }

                        initialAttachments.forEach((attachment) => {
                            const removed = attachments.every(({ url }) => url !== attachment.url);
                            if (removed) {
                                const params = { data_type_id: projectDataTypeId, url: attachment.url };
                                updateProjectDataTypes.push(dispatch(deleteAttachment(params)));
                            }
                        });

                        attachments.forEach((attachment) => {
                            const added = initialAttachments.every(({ url }) => url !== attachment.url);
                            if (added) {
                                const params = { data_type_id: projectDataTypeId, ...attachment };
                                updateProjectDataTypes.push(dispatch(addAttachment(params)));
                            }
                        });
                    });

                    return Promise.all(updateProjectDataTypes);
                })
                .then(() => {
                    const { entities } = getState();
                    const updatedSubscription = denormalize(subscriptionId, subscriptionSchema, entities);
                    const updatedDataTypes = denormalize(dataTypeIds, [dataTypeSchema], entities);
                    const updatedValues = mapSubscriptionToValues(updatedSubscription, updatedDataTypes);

                    dispatch(saveProjectSuccess(updatedValues));
                    return updatedValues;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(saveProjectError());
                    return Promise.reject(err);
                });
        }
    )
);

export const requestReviewSuccess = createAction(types.REQUEST_REVIEW_SUCCESS);
export const requestReviewError = createAction(types.REQUEST_REVIEW_ERROR);
export const requestReview = createAction(
    types.REQUEST_REVIEW,
    (subscriptionId: number, orgId: number): Function => (
        (dispatch: Dispatch<any>): Promise<void> => (
            Promise.resolve()
                .then((): Promise<void> => (
                    dispatch(requestProjectReview({
                        organization_id: orgId,
                        project_id: subscriptionId,
                    }))
                ))
                .then(() => {
                    dispatch(requestReviewSuccess());
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(requestReviewError());
                    return Promise.reject(err);
                })
        )
    )
);

export const launchProjectSuccess = createAction(types.LAUNCH_PROJECT_SUCCESS);
export const launchProjectError = createAction(types.LAUNCH_PROJECT_ERROR);
export const launchProject = createAction(
    types.LAUNCH_PROJECT,
    (subscriptionId: number): Function => (
        (dispatch: Dispatch<any>): Promise<void> => (
            Promise.resolve()
                .then(() => (
                    // Step 1: Launch the project
                    // $FlowFixMe need to fix/update gigwalk-node type definitions
                    dispatch(updateSubscription({
                        organization_subscription_id: subscriptionId,
                        subscription: { state: 'ACTIVE' },
                    }))
                ))
                .then(() => {
                    // Step 2: Refresh the session to update the organization stats
                    dispatch(session.actions.refresh());
                    dispatch(launchProjectSuccess());
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(launchProjectError());
                    return Promise.reject(err);
                })
        )
    )
);

const resetState = createAction(types.RESET_STATE);

export default {
    createProject,
    createProjectError,
    createProjectSuccess,
    launchProject,
    launchProjectError,
    launchProjectSuccess,
    loadProject,
    loadProjectError,
    loadProjectSuccess,
    requestReview,
    requestReviewSuccess,
    requestReviewError,
    resetState,
    saveProject,
    saveProjectSuccess,
    saveProjectError,
};
