// @flow
// Use depth-first search to traverse skip logic tree and visit each child
export default function traverseSkipLogic(node: Object, visitCallback: (node: Object) => void) {
    visitCallback(node);

    if (node.children) {
        node.children.forEach((expr: Object) => {
            const children = expr.$cond[1];
            const altChildren = expr.$cond[2];

            if (children) {
                children.forEach((d) => traverseSkipLogic(d, visitCallback));
            }
            if (altChildren) {
                altChildren.forEach((d) => traverseSkipLogic(d, visitCallback));
            }
        });
    }
}
