// @flow
// $FlowIssue need to update to a more recent flow version
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Form } from 'formik';
// $FlowTypedIssue update react-redux libdef
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { BarcodeScan as BarcodeIcon } from 'mdi-material-ui';
import { getTicket, registerForm, unregisterForm } from '../../../../ducks/ticketDetail';
import FormikMaskedInput from '../../../../components/FormikMaskedInput';
import DeviceTooltip from '../DeviceTooltip';
import LocationTooltip from '../LocationTooltip';
import styles from './styles';
import type { State as RootState } from '../../../../store';

type Props = {
    dataItem: ?Object,
    dirty: boolean,
    disabled?: boolean,
    expectedValueExpression: ?Object,
    id: string,
    onChange: (values: Object) => void,
    questionText: string,
    submitForm: () => Promise<any>,
    touched: Object,
    values: Object,
};

const useStyles = makeStyles(styles, { name: 'Barcode' });

export default function Barcode(props: Props) {
    const { dataItem, dirty, disabled, id, onChange, questionText, submitForm, touched, values } = props;

    const dispatch = useDispatch();
    const classes = useStyles(props);

    const [, toggleFocus] = useState(false);

    const ticket = useSelector((state: RootState) => getTicket(state));

    const blurTimerRef = useRef(null);
    const valuesRef = useRef(null);
    const submitFormRef = useRef(null);

    valuesRef.current = values;
    submitFormRef.current = () => {
        if (Object.keys(touched).length > 0 || dirty) {
            return submitForm();
        }
    };

    const handleBlur = useCallback(() => {
        blurTimerRef.current = setTimeout(() => {
            toggleFocus(false);
            onChange(valuesRef.current);
        }, 0);
    }, [onChange]);

    const handleFocus = useCallback(() => {
        clearTimeout(blurTimerRef.current);
        blurTimerRef.current = null;
        toggleFocus(true);
    }, []);

    useEffect(() => {
        const helpers = {
            submitForm() {
                return submitFormRef.current();
            },
        };
        dispatch(registerForm(id, helpers));

        return () => {
            dispatch(unregisterForm(id));
        };
    }, [id, dispatch]);

    const maskOptions = {
        numeral: true,
        numeralThousandsGroupStyle: 'none',
        stripLeadingZeroes: false,
    };

    return (
        <Form className={classes.root} name={id} onBlur={handleBlur} onFocus={handleFocus}>
            <div className={classes.question}>
                <div dangerouslySetInnerHTML={{ __html: questionText }} />
                <FormikMaskedInput
                  className={classes.field}
                  disabled={disabled}
                  margin="dense"
                  name="response"
                  options={maskOptions}
                  variant="outlined"
                />
            </div>
            <div className={classes.metadata}>
                <BarcodeIcon color="action" fontSize="small" />
                {ticket && dataItem
                    ? (
                        <Fragment>
                            <DeviceTooltip dataItem={dataItem} />
                            <LocationTooltip dataItem={dataItem} gigLocation={ticket.location} />
                        </Fragment>
                    )
                    : null}
            </div>
        </Form>
    );
}
