// @flow
// $FlowIssue need to update to a more recent flow version
import React, { Fragment, useCallback } from 'react';
import cx from 'classnames';
import { Route } from 'react-router';
// $FlowTypedIssue update react-redux libdef
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import {
    Avatar,
    Card,
    CardHeader,
    CardContent,
    IconButton,
    LinearProgress,
    ListItemIcon,
    ListItemText,
    MenuItem, Button,
} from '@material-ui/core';
import {
    DirectionsRun as DirectionsRunIcon,
    MoreHoriz as MoreHorizIcon,
} from '@material-ui/icons';
import type { ContextRouter } from 'react-router';
import StarRating from '../../../components/StarRating';
import DropdownMenu from '../../../components/DropdownMenu';
import Timeline from './Timeline';
import TimeEstimate from './TimeEstimate';
import ScheduledDate from './ScheduledDate';
import Assignee from './Assignee';
import Payout from './Payout';
import CallToAction from './CallToAction';
import { close as closeDialog, open as openDialog } from '../../../ducks/dialog';
import {
    loadTicket,
    getCallToAction,
    getMenuActions,
    getProgress,
    getTicket,
} from '../../../ducks/ticketDetail';
import styles from './styles';
import type { State as RootState } from '../../../store';

type Props = ContextRouter & {
    classes: Object,
    className?: string,
};

const useStyles = makeStyles(styles, { name: 'Summary' });

export default function Summary(props: Props) {
    const { className, history, match } = props;

    const orgId = parseInt(match.params.orgId, 10);
    const ticketId = parseInt(match.params.ticketId, 10);

    const dispatch = useDispatch();
    const classes = useStyles(props);
    const { t } = useTranslation();

    const ticket = useSelector((state: RootState) => getTicket(state));
    const menuActions = useSelector((state: RootState) => getMenuActions(state));
    const progress = useSelector((state: RootState) => getProgress(state));
    const cta = useSelector((state: RootState) => getCallToAction(state));

    const approvalStatus = ticket ? ticket.approval_status : '';
    const rating = ticket ? ticket.rating : false;
    const status = ticket ? ticket.status : '';
    const title = ticket ? ticket.title : '';
    const needsApproval = ticket ? ticket.subscription.needs_approval : false;
    const needsPublicWorkforce = ticket ? ticket.subscription.needs_public_workforce : false;
    const projectId = ticket ? ticket.subscription.id : -1;
    const projectState = ticket ? ticket.subscription.state : '';

    const handleActionClick = useCallback((event: SyntheticEvent<any>) => {
        const { action } = event.currentTarget.dataset;

        const dialogProps = {
            onClose: () => {
                dispatch(closeDialog());
            },
            onSubmitSuccess: () => {
                dispatch(closeDialog());
                dispatch(loadTicket(ticketId));
            },
        };

        switch (action) {
            case 'print': {
                window.open(`/tickets/${orgId}/print/${ticketId}/detail`, '_blank');
                break;
            }

            case 'viewProjectSummary': {
                history.push(`/projects/${orgId}/${projectState === 'ACTIVE' ? 'active' : 'archive'}/${projectId}`);
                break;
            }

            case 'apply':
            case 'withdraw':
            case 'review':
            case 'extendReservationWindow':
            case 'viewMetadata':
                dispatch(openDialog(action, { ...dialogProps, ticketId }));
                break;

            default:
                dispatch(openDialog(action, { ...dialogProps, ticketIds: [ticketId] }));
                break;
        }
    }, [dispatch, history, orgId, projectId, projectState, ticketId]);

    const renderCallToAction = () => {
        if (cta) {
            const { action, icon: Icon, key, variant } = cta;
            return (
                <CallToAction
                  variant={variant}
                  action={(
                      action
                          ? (
                              <Button
                                color="inherit"
                                data-action={action}
                                onClick={handleActionClick}
                                variant="outlined"
                              >
                                  {t(`ticketDetail.summary.callToAction.${key}.action`)}
                              </Button>
                          )
                          : null
                  )}
                >
                    {Icon ? <Icon className={classes.ctaIcon} /> : null}
                    {t(`ticketDetail.summary.callToAction.${key}.text`)}
                </CallToAction>
            );
        }

        return (
            <LinearProgress
              classes={{
                  root: classes.progress,
                  bar: classes.progressBar,
              }}
              variant="determinate"
              value={progress}
            />
        );
    };

    return (
        <Card className={cx(className, classes.root)}>
            <CardHeader
              classes={{
                  action: classes.actionMenu,
                  title: classes.title,
                  root: classes.header,
                  subheader: classes.status,
              }}
              avatar={<Avatar className={classes.avatar}><DirectionsRunIcon /></Avatar>}
              title={(
                  <Fragment>
                      <span>{title}</span>
                      <span>
                          {rating != null && approvalStatus !== 'UNREVIEWED'
                              ? <StarRating disableHover value={rating} />
                              : null}
                      </span>
                  </Fragment>
              )}
              titleTypographyProps={{ variant: 'headline' }}
              subheader={(
                  <Fragment>
                      <span className={classes.label}>{t('ticketDetail.summary.status')}</span>
                      <span>
                          {needsApproval
                              ? `${t(`ticketDetail.summary.statusEnum.${status}`)} / ${t(`ticketDetail.summary.approvalStatusEnum.${approvalStatus}`)}`
                              : t(`ticketDetail.summary.statusEnum.${status}`)}
                      </span>
                  </Fragment>
              )}
              action={
                  menuActions.length > 0
                      ? (
                          <DropdownMenu anchor={(<IconButton><MoreHorizIcon /></IconButton>)}>
                              {() => (
                                  menuActions.map((action) => {
                                      const Icon = action.icon;
                                      return (
                                          <MenuItem
                                            className={classes.menuItem}
                                            data-action={action.name}
                                            disabled={action.disabled}
                                            key={action.name}
                                            onClick={handleActionClick}
                                          >
                                              <ListItemIcon className={classes.icon}><Icon /></ListItemIcon>
                                              <ListItemText disableTypography primary={t(`ticketDetail.summary.${action.name}`)} />
                                          </MenuItem>
                                      );
                                  })
                              )}
                          </DropdownMenu>
                      )
                      : null
              }
            />
            {renderCallToAction()}
            <CardContent className={classes.cardContent}>
                <Route component={Timeline} />
                <Route component={ScheduledDate} />
                <Route component={TimeEstimate} />
                <Route component={Assignee} />
                {needsPublicWorkforce ? <Route component={Payout} /> : null}
            </CardContent>
        </Card>
    );
}
