// @flow
import { createAction } from 'redux-actions';
import type { $AxiosError } from 'axios';
import type { Dispatch } from 'redux';
import logger from '../../../../../util/logger';
import * as api from '../../../../../ducks/api';
import * as googleMaps from '../../../../../ducks/googleMaps';
import { update as updateCustomer } from '../../../../../redux/entities/customers';
import types from './types';
import type { State as RootState } from '../../../../../redux/initialState';

function getAddressComponents(place: Object) {
    const { address_components: addressComponents } = place;

    let streetName = '';
    let streetNumber = '';
    let unit = '';
    let city = '';
    let state = '';
    let zipCode = '';

    addressComponents.forEach((component: Object) => {
        switch (component.types[0]) {
            case 'route':
                streetName = component.short_name;
                break;
            case 'street_number':
                streetNumber = component.long_name;
                break;
            case 'subpremise':
                unit = component.long_name;
                break;
            case 'locality':
                city = component.long_name;
                break;
            case 'administrative_area_level_1':
                state = component.short_name;
                break;
            case 'postal_code':
                zipCode = component.long_name;
                break;
            default:
                break;
        }
    });

    return {
        addressLine1: `${streetNumber} ${streetName}`.trim(),
        addressLine2: unit,
        city,
        state,
        zipCode,
    };
}

export const resolveAddressError = createAction(types.RESOLVE_ADDRESS_ERROR);
export const resolveAddressSuccess = createAction(types.RESOLVE_ADDRESS_SUCCESS);
export const resolveAddress = createAction(
    types.RESOLVE_ADDRESS,
    (address: string): Function => (
        (dispatch: Dispatch<any>): Promise<any> => (
            dispatch(googleMaps.actions.geocode({ address }))
                .then((results: Object[]) => {
                    const place = results[0];
                    const values = getAddressComponents(place);
                    dispatch(resolveAddressSuccess(values));
                })
                .catch((err) => {
                    logger.error(err);
                    dispatch(resolveAddressError());
                    return Promise.reject(err);
                })
        )
    )
);

export const submitError = createAction(types.SUBMIT_ERROR);
export const submitSuccess = createAction(types.SUBMIT_SUCCESS);
export const submit = createAction(
    types.SUBMIT,
    (values: Object): Function => (
        (dispatch: Dispatch<any>, getState: () => RootState): Promise<any> => (
            Promise.resolve()
                .then(() => {
                    const { addressLine1, addressLine2, city, state, zipCode } = values;
                    const parts = [
                        `${addressLine1} ${addressLine2 ? `#${addressLine2}` : ''}`.trim(),
                        `${city}, ${state} ${zipCode}`.replace(/^,|,$/, '').trim(),
                    ];
                    const address = parts.join(', ').trim();
                    return dispatch(googleMaps.actions.geocode({ address }));
                })
                .then((results: Object[]) => {
                    const { currentPassword, firstName, id, lastName, newPassword, phoneNumber } = values;

                    let addressLine1 = '';
                    let addressLine2 = '';
                    let city = '';
                    let state = '';
                    let zipCode = '';

                    if (results.length > 0) {
                        const place = results[0];
                        ({ addressLine1, addressLine2, city, state, zipCode } = getAddressComponents(place));
                    }

                    // We store city/state/zip in address_line_2, though that it not standard.
                    // Typically, address_line_2 is an optional field used for unit/apt/suite number
                    // and you'd have separate fields for city/state/zip
                    const params = {
                        first_name: firstName,
                        last_name: lastName,
                        phonenumber: phoneNumber,
                        address_line_1: `${addressLine1} ${addressLine2 ? `#${addressLine2}` : ''}`.trim(),
                        address_line_2: `${city}, ${state} ${zipCode}`.replace(/^,|,$/, '').trim(),
                        ...(
                            newPassword
                                ? { password: newPassword, current_password: currentPassword }
                                : undefined
                        ),
                    };

                    const { user } = getState().session;
                    const action = user && user.id === id
                        ? api.actions.updateUser(params)
                        : updateCustomer({ customer_id: id, ...params });

                    return dispatch(action)
                        .then(() => {
                            dispatch(submitSuccess());
                        })
                        .catch((err: $AxiosError<Object>) => {
                            dispatch(submitError(err));
                            return Promise.reject(err);
                        });
                })
        )
    )
);

export default {
    resolveAddress,
    resolveAddressError,
    resolveAddressSuccess,
    submit,
    submitError,
    submitSuccess,
};
