// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback, useRef } from 'react';
// $FlowTypedIssue need to update the react-dnd libdef
import { useDrop } from 'react-dnd';
import type { Node } from 'react';

type Props = {
    children: Node,
}

export default function QuestionList(props: Props) {
    const { children, ...other } = props;
    const threshold = Math.max(140, window.innerHeight / 4);

    const frameRef = useRef(null);
    const deltaRef = useRef(null);

    const autoScroll = useCallback(() => {
        const dy = deltaRef.current;

        if (!dy) {
            frameRef.current = null;
            return;
        }

        window.scrollTo(0, window.scrollY + dy);
        frameRef.current = window.requestAnimationFrame(autoScroll);
    }, []);

    const cancelScroll = useCallback(() => {
        const frame = frameRef.current;

        if (frame) {
            window.cancelAnimationFrame(frame);
            frameRef.current = null;
        }
    }, []);

    const [, drop] = useDrop({
        accept: 'CARD',
        hover(item, monitor) {
            const { y: clientY } = monitor.getClientOffset();

            let speed;
            if (clientY < threshold) {
                // -1 to 0 as we move from 0 to threshold
                speed = -1 + clientY / threshold;
            } else if (clientY > window.innerHeight - threshold) {
                // 0 to 1 as we move from (innerHeight - threshold) to innerHeight
                speed = 1 - (window.innerHeight - clientY) / threshold;
            } else {
                speed = 0;
            }

            const direction = speed < 0 ? -1 : 1;
            const dy = direction * (Math.round(speed * 5) ** 2);
            deltaRef.current = dy;

            if (!frameRef.current) {
                frameRef.current = window.requestAnimationFrame(autoScroll);
            }
        },
        drop() {
            cancelScroll();
        },
    });

    return (
        <ul {...other} onMouseEnter={cancelScroll} onMouseLeave={cancelScroll} ref={drop}>
            {children}
        </ul>
    );
}
