var Handlebars = require('/tmp/build_a9d70681abb25b82ca4489607bd6ccee/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"selected-items\" data-target=\"selected-items\"></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dropdown\" data-target=\"dropdown\">\n    <button class=\"btn btn-default dropdown-toggle\" type=\"button\" data-toggle=\"dropdown\">\n        <span class=\"caret\"></span>\n        <span data-target=\"button-label\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"select_from_options") || (depth0 != null ? lookupProperty(depth0,"select_from_options") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"select_from_options","hash":{},"data":data,"loc":{"start":{"line":4,"column":41},"end":{"line":4,"column":66}}}) : helper)))
    + "</span>\n    </button>\n    <ul class=\"dropdown-menu\" role=\"menu\">\n        <li class=\"c-search\" data-target=\"search\"></li>\n        <li class=\"menu-list\" data-target=\"dropdown-list\">\n            <div>\n                <!-- Retrieved list goes in this div -->\n            </div>\n        </li>\n    </ul>\n</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"multiselect") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":0},"end":{"line":18,"column":7}}})) != null ? stack1 : "");
},"useData":true});