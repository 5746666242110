// @flow
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import type { ContextRouter } from 'react-router';
import type { DataType } from 'gigwalk/lib/api/dataTypes/types';
import entitySelector from '../../../redux/entities/entitySelector';
import mapSubscriptionToValues from '../utils/mapSubscriptionToValues';
import type { State as RootState } from '../../../redux/initialState';
import { subscription as subscriptionSchema } from '../../../redux/entities/schemas';

const getEntities = (state: RootState): Object => state.entities;

// const getOrganizationId = <P: {}>(state: RootState, props: { ...P, ...ContextRouter }): number => {
//     const { match } = props;
//     return parseInt(match.params.orgId, 10);
// };
// const getOrganization = createSelector(
//     getEntities,
//     getOrganizationId,
//     (entities: Object, organizationId: number) => denormalize(organizationId, organizationSchema, entities),
// );

const getSubscriptionId = <P: {}>(state: RootState, props: { ...P, ...ContextRouter }): number => {
    const { match } = props;
    return parseInt(match.params.subscriptionId, 10);
};
const getSubscription = createSelector(
    getEntities,
    getSubscriptionId,
    (entities: Object, subscriptionId: number) => denormalize(subscriptionId, subscriptionSchema, entities),
);

const getDataTypes = (state: RootState): DataType[] => entitySelector('dataTypes')(state);

export const getInitialValues = createSelector(
    getSubscription,
    getDataTypes,
    (subscription: ?Object, dataTypes: DataType[]) => {
        const values = mapSubscriptionToValues(subscription, dataTypes);
        return {
            ...values,
            amountDue: 0,
            priceEstimate: 0,
        };
    }
);

export default {
    getInitialValues,
};
