// @flow
import moment from 'moment';
import isMatch from 'lodash/isMatch';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import type { $AxiosError } from 'axios';
import ticketTpl from '../../templates/ticket/Ticket.hbs';
import SelfDirectedWork from './widgets/SelfDirectedWork';
import BulkBarcode from './widgets/BulkBarcode';
import Hint from './widgets/Hint';
import Photo from './widgets/Photo';
import Signature from './widgets/Signature';
import Question from './widgets/Question';
import Task from './widgets/Task';
import InstructionList from '../../../shared/model/InstructionListModel/InstructionList';
import StatedView from '../../../shared/view/StatedView';
import PopupConfirm from '../../../shared/view/PopupConfirm';
import ProjectConstant from '../../../shared/constant/ProjectConstant';
import { STATUS } from '../../../shared/constant/TicketConstant';
import { USER_ROLES } from '../../../shared/constant/UserRoles';
import { format } from '../../../shared/util/gigwalkApiErrorUtil';
import isCrossmark from '../../../shared/util/isCrossmark';
import logger from '../../../../common/util/logger';
import { getTicket } from '../../../../client/ducks/ticketDetail';
import {
    createDataItem,
    deleteDataItem,
    fetch as fetchTicket,
    submit as submitTicket,
    update as updateTickets,
} from '../../../../common/redux/entities/tickets';
import * as snackbar from '../../../../common/ducks/snackbar';
import connectBackbone from '../../../../common/components/connectBackbone';
import typeof SkipLogicTree from '../../../shared/model/SkipLogicTree';

type QuestionItem = {
    dataTypeId: string,
    targetId: number,
    templateId: ?number,
};

type TargetDropdownItem = {
    id: number,
    title: string,
};

const Instructions = StatedView.extend({

    className: '__detail__',

    events: {
        'click [data-action="submit"]:last': 'onSubmit',
        click: '_validate',
        keyup: '_validate',
    },

    initialize(options) {
        this.$el.addClass('detail');

        this.text = {
            ...GW.localisation.tickets.tickets.details,
            states: GW.localisation.tickets.tickets.exe_states,
        };

        this.setIsAnswered = this.setIsAnswered.bind(this);

        this.props = options.props;
        this.firstRender();
        this.widgets = [];
    },

    firstRender() {
        this.$el.html('<div class="glyphicon glyphicon-refresh loading-tab" data-buffering></div>')
            .append($('<div></div>').attr('data-module', 'ticket'));

        this.$els = {
            ticket: this.$el.find('[data-module="ticket"]'),
        };
    },

    render() {
        const { ticket, user } = this.props;

        this.setReadMode();

        if (!ticket) {
            return;
        }

        this.$el.find('[data-module]').show();
        this.$el.find('[data-buffering]:first').hide();

        this.$el.find('[data-module="ticket"]').html('')
            .append(ticketTpl(this.text));

        const collapsers = this.$el.find('[data-action="collapse"]');
        const collapsing = this.$el.find('[data-target="collapse"]');

        _.each(collapsers, (arrow: HTMLElement, i: number) => {
            $(arrow).off('click').on('click', () => {
                $(this).parent().toggleClass('collapsed');
                $(collapsing[i]).toggle();
            });
        });

        this.instructionList = new InstructionList({
            id: ticket.id,
            instructions: ticket.data_types,
            timingTemplate: {
                id: user.organization.config.timing_template_id,
                targetListId: user.organization.config.timing_target_list_id,
            },
        });

        const instructions = this.instructionList.getInstructions();
        const { data_items: dataItems, data_type_map: dataTypeMap } = ticket;

        this.unsaved = [];
        this.required = [];
        instructions.each((instruction: SkipLogicTree) => {
            instruction.traverse((node: SkipLogicTree) => {
                const templateId = node.get('template_id');
                const targetId = node.get('observation_target_id');
                const dataTypeId = node.get('data_type_id');
                const dataType = dataTypeMap[dataTypeId];

                // @todo: Add support for bulk barcode and self-directed templates
                if (dataTypeId == null) {
                    return;
                }

                const dataItem = dataItems.find((di: Object) => (
                    di.data_type_id === dataTypeId
                    && di.observation_target_id === targetId
                    && (templateId ? di.template_id === templateId : true)
                ));

                if (dataType.is_required && !dataItem) {
                    this.required.push({
                        dataTypeId,
                        targetId,
                        templateId,
                    });
                }
            });
        });

        this.renderInstructions();
        this._validate();
    },

    renderInstructions() {
        this.$widgets = this.$el.find('[data-target="widgets"]');
        this.widgets = [];

        const { templateMap, ticket } = this.props;
        const instructions = this.instructionList.getInstructions();
        const targetIdsToIgnore = ticket.ticket_metadata.ignore_target_ids || [];

        instructions.each((instruction: Object, index: number) => {
            const templateId = instruction.get('template_id');
            const template = this.props.templateMap[templateId];
            let widget;

            const targetID = instruction.get('observation_target_id');
            if (targetIdsToIgnore.indexOf(targetID) >= 0) {
                return;
            }

            if (templateId && templateMap[templateId]) {
                const targetIds = instruction.get('observation_target_ids');

                const targets = targetIds
                    .filter((id: number): boolean => targetIdsToIgnore.indexOf(id) === -1)
                    .map((id: number): TargetDropdownItem => ({ id, title: ticket.observation_target_map[id] }));

                switch (true) {
                    case template.self_directed === true:
                        if (targets.length > 0) {
                            widget = new SelfDirectedWork({
                                ticketID: this.ticketID,
                                templateID: templateId,
                                targets,
                                readMode: this.readMode,
                                props: {
                                    ...this.props,
                                    instructions,
                                    template,
                                    setIsAnswered: this.setIsAnswered,
                                },
                            });
                        }
                        break;

                    case template.type === 'BULK_BARCODE':
                        if (targets.length > 0) {
                            widget = new BulkBarcode({
                                ticketID: this.ticketID,
                                templateID: templateId,
                                targets,
                                readMode: this.readMode,
                                props: {
                                    ...this.props,
                                    instructions,
                                    template,
                                    setIsAnswered: this.setIsAnswered,
                                },
                            });
                        }
                        break;

                    default:
                        widget = this._createInstructionWidget(instruction, index);
                        break;
                }
            } else {
                widget = this._createInstructionWidget(instruction, index);
            }

            if (!widget) {
                return;
            }
            widget.onSave = _.bind(this.onSave, this);

            this.widgets.push(widget);
            this.$widgets.append(widget.$el);
        });
    },

    _createInstructionWidget(instruction: Object, index?: number) {
        const { ticket } = this.props;
        const dataTypeID = instruction.get('data_type_id');
        const dataType = ticket.data_type_map[dataTypeID];
        const options = {
            instruction,
            ticketID: this.ticketID,
            readMode: this.readMode,
            showLocations: false,
            props: {
                ...this.props,
                index,
                setIsAnswered: this.setIsAnswered,
            },
        };

        let valueType = dataType.value_type;
        let widget = null;

        // TODO: Add method to SkipLogicTree to access links/children
        if (instruction.links.length) {
            valueType = ProjectConstant.VALUE_TYPE.TASK;
        }

        switch (valueType) {
            case ProjectConstant.VALUE_TYPE.HINT:
            case ProjectConstant.VALUE_TYPE.CHECK:
                widget = new Hint(options);
                break;

            case ProjectConstant.VALUE_TYPE.PHOTO:
                widget = new Photo(options);
                break;

            case ProjectConstant.VALUE_TYPE.SIGNATURE:
                widget = new Signature(options);
                break;

            case ProjectConstant.VALUE_TYPE.BARCODE:
            case ProjectConstant.VALUE_TYPE.MULTIPLE_CHOICE:
            case ProjectConstant.VALUE_TYPE.MULTI_SELECT:
            case ProjectConstant.VALUE_TYPE.CHECKBOXES:
            case ProjectConstant.VALUE_TYPE.NUMBER:
            case ProjectConstant.VALUE_TYPE.CURRENCY:
            case ProjectConstant.VALUE_TYPE.DATE:
            case ProjectConstant.VALUE_TYPE.DATE_TIME:
            case ProjectConstant.VALUE_TYPE.TIME:
            case ProjectConstant.VALUE_TYPE.FREE_TEXT:
            case ProjectConstant.VALUE_TYPE.PHONE_NUMBER:
                widget = new Question(options);
                break;

            case ProjectConstant.VALUE_TYPE.TASK:
                widget = new Task(options);
                break;

            default:
                logger.warn(`${valueType} data_types are not supported yet.`);
                break;
        }

        return widget;
    },

    setIsAnswered(isAnswered: boolean, dataTypeId: number, targetId: number, templateId: ?number, dataItem: Object) {
        const { ticket } = this.props;
        const {
            data_items: dataItems,
            data_type_map: dataTypeMap,
            ticket_metadata: metadata,
        } = ticket;

        const dataType = dataTypeMap[dataTypeId];
        const record = {
            dataTypeId,
            targetId,
            ...(templateId ? { templateId } : null),
        };

        this.required = this.required.filter((item: Object): boolean => isMatch(item, record));
        this.unsaved = this.unsaved.filter((item: Object) => isMatch(item, record));

        const ignoreTargetIds = metadata.ignore_target_ids || [];
        if (!isAnswered && dataType.is_required && !ignoreTargetIds.includes(targetId)) {
            this.required.push(record);
        }

        const source = {
            data_type_id: dataTypeId,
            observation_target_id: targetId,
            ...(templateId ? { template_id: templateId } : null),
        };
        const savedDataItem = dataItems.find((di: Object) => isMatch(di, source));

        const savedValue = savedDataItem ? savedDataItem.data_item_value : [];
        const value = dataItem ? dataItem.data_item_value : [];
        if (
            dataType.value_type !== 'TASK'
            && dataType.value_type !== 'CHECK'
            && (savedValue.length === 0 || savedValue.join(',') !== value.join(','))
            && this.required.find((item) => isMatch(item, record)) === null
        ) {
            this.unsaved.push({
                ...record,
                ...(dataItem ? { dataItem } : null),
            });
        }

        this._validate();
    },

    setReadMode() {
        const { ticket, user } = this.props;

        if (!ticket) {
            return;
        }

        // is within 24 hour grace period
        const isExpiring = ticket.status !== STATUS.SUBMITTED
            && moment(ticket.due_date).isBefore(moment())
            && moment(ticket.due_date).add(1, 'day').isAfter(moment());

        // ticket.date_created is before today && ticket.end_date is after today
        const isBeforeDueDate = moment(ticket.due_date).isAfter(moment());

        // readMode is true if  its not editable or the status is submitted
        const isEditable = isExpiring || isBeforeDueDate;
        this.readMode = !isEditable || ticket.status === 'SUBMITTED';

        // CSMK CSMK CSMK CSMK CSMK CSMK

        // If this is Crossmark, the ticket is assigned to me, the status is submitted,
        // AND we are still within the 3 week grace period...
        const isTimingTemplateEditable = isCrossmark(user.organization.id)
            && user.id === ticket.assigned_customer_id
            && ticket.status === 'SUBMITTED'
            && moment(ticket.due_date).add(3, 'weeks').isAfter(moment());

        if (isTimingTemplateEditable) {
            if (this.$popup) {
                this.$popup.hide();
            }

            this.$popup = new PopupConfirm({
                text: {
                    subject: this.text.submitted_or_expired,
                    subtext: this.text.edit_time_only,
                    yes: GW.localisation.shared.popupConfirm.okay,
                },
                showCancelButton: false,
                onSubmit: () => {
                    this.$popup.remove();
                },
            });
        }

        // CSMK CSMK CSMK CSMK CSMK CSMK
    },

    onSave(value: Object, buttonEl: HTMLElement, callback: Function) {
        const $buttonEl = $(buttonEl);
        $buttonEl.find('[data-buffering]:first').css('visibility', 'visible');
        this.active$buttonEls.push($buttonEl);

        const { ticket } = this.props;
        const startedStates = [
            STATUS.STARTED,
            STATUS.EXPIRED,
            STATUS.SUBMITTED,
            STATUS.CANCELED,
            STATUS.AUTO_CANCELED,
        ];

        let promise;
        if (!startedStates.includes(ticket.status)) {
            promise = this.saveAndStart(value, callback);
        } else {
            const params = { ticket_id: ticket.id, ...value };
            promise = this.props.createDataItem(params)
                .then(() => {
                    if (typeof callback === 'function') callback();
                    return this.props.fetchTicket({ ticket_id: ticket.id });
                })
                .catch((err: $AxiosError<Object>) => {
                    const resp = err ? err.response : null;
                    if (resp && resp.data && resp.data.gw_api_response) {
                        const message = format(resp.data.gw_api_response);
                        this.props.enqueueSnackbar(message, { variant: 'error' });
                    }
                })
                .catch(() => {
                    this.onSaved();
                });
        }
        promise.then(() => {
            $buttonEl.trigger('blur');
            $buttonEl.find('[data-buffering]:first').css('visibility', 'hidden');
        });
    },

    _validate() {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(this.__validate.bind(this), 100);
    },

    __validate() {
        const { ticket, user } = this.props;
        this.$submit = this.$el.find('[data-action="submit"]:last');

        if (!ticket) {
            return;
        }

        const invalidStatus = ticket.status === 'SUBMITTED'
            || (ticket.status !== 'SUBMITTED' && moment().subtract(1, 'day').isAfter(moment(ticket.due_date)))
            || moment(ticket.start_date).isAfter(moment());

        const canSubmit = !invalidStatus && this.required.length === 0;
        const isAssignee = (user.id === ticket.assigned_customer_id);
        const submitErrors = !isAssignee || !ticket || !canSubmit || this.readMode;
        this.$submit
            .toggleClass('disabled', submitErrors)
            .toggleClass('pink', this.required.length > 0);
    },

    submit() {
        const { ticket } = this.props;
        this.props.submitTicket({ ticket_id: ticket.id })
            .then(() => this.props.fetchTicket({ ticket_id: ticket.id }))
            .catch((err: $AxiosError<Object>) => {
                const resp = err ? err.response : null;
                if (resp && resp.data && resp.data.gw_api_response) {
                    const message = format(resp.data.gw_api_response);
                    this.props.enqueueSnackbar(message, { variant: 'error' });
                }
            });
    },

    saves: 0,

    onSubmit() {
        if (this.required.length > 0) {
            const firstTodo = this.required[0];
            const templateIdSelector = firstTodo.templateId ? `[data-template_id="${firstTodo.templateId}"]` : '';
            const firstTodo$el = this.$(
                `[data-data_type_id="${firstTodo.dataTypeId}"][data-observation_target_id="${firstTodo.targetId}"]${templateIdSelector}`
            );

            if (firstTodo$el.length) {
                $(window).scrollTop(firstTodo$el.offset().top);
            }

            // Flash required data types left to do
            _.each(this.required, (ids: QuestionItem) => {
                this.$(`[data-data_type_id="${ids.dataTypeId}"] .instruction .header .actions`)
                    .removeClass('all-1500ms').addClass('required-incomplete');
            });

            setTimeout(() => {
                _.each(this.required, (ids: QuestionItem) => {
                    this.$(`[data-data_type_id="${ids.dataTypeId}"] .instruction .header .actions`)
                        .addClass('all-1500ms').removeClass('required-incomplete');
                });
            }, 17);
        }

        if (this.$submit.hasClass('disabled')) {
            return;
        }

        this.saves = 0;
        const saves = [];

        this.unsaved.forEach((ids: QuestionItem) => {
            const widget = _.filter(this.widgets, (wdgt: Object): any => {
                const subMatch = _.where(wdgt.dts, {
                    id: ids.dataTypeId,
                });
                return subMatch.length
                    && (wdgt.target.id === ids.targetId || _.filter(subMatch, (dt: Object) => (dt.target && dt.target.id === ids.targetId)).length)
                    && (ids.templateId ? wdgt.templateID === ids.templateId : true);
            }).pop();

            if (!widget) {
                return;
            }

            this.saves += 1;
            saves.push(() => {
                widget.save(() => {
                    this.saves += 1;
                    if (this.saves <= 0) {
                        this.submit();
                    }
                });
            });
        });

        _.each(saves, (fn: Function) => {
            fn();
        });

        if (!this.unsaved.length) {
            this.submit();
        }
    },

    start() {
        const { ticket } = this.props;
        const params = {
            action: 'start',
            ticket_ids: [ticket.id],
        };

        return this.props.updateTickets(params)
            .then(() => this.props.fetchTicket({ ticket_id: ticket.id }))
            .catch((err: $AxiosError<Object>) => {
                const resp = err ? err.response : null;
                if (resp && resp.data && resp.data.gw_api_response) {
                    const message = format(resp.data.gw_api_response);
                    this.props.enqueueSnackbar(message, { variant: 'error' });
                }
            });
    },

    saveAndStart(value: Object, callback: ?Function) {
        const { ticket } = this.props;
        const params = {
            ticket_id: ticket.id,
            ...value,
        };
        return this.props.createDataItem(params)
            .then(() => {
                if (typeof callback === 'function') callback();
                return this.start();
            })
            .catch((err: $AxiosError<any>) => {
                const resp = err ? err.response : null;
                if (resp && resp.data && resp.data.gw_api_response) {
                    const message = format(resp.data.gw_api_response);
                    this.props.enqueueSnackbar(message, { variant: 'error' });
                }
            });
    },

    onSaved() {
        _.each(this.active$buttonEls, (button: HTMLElement) => {
            const $button = $(button);
            $button.trigger('blur');
            $button.find('[data-buffering]:first').css('visibility', 'hidden');
        }, this);

        this.active$buttonEls = [];
    },

    active$buttonEls: [],

    transitionIn(...args: Array<any>) {
        this.ticketID = this.props.ticket.id;
        this.validateTicketForWorker();

        this.setReadMode();

        this.$el.find('[data-buffering]:first').show();
        this.$el.find('[data-module]').hide();

        this.render();

        this._super(...args);
    },

    validateTicketForWorker() {
        const { ticket, user } = this.props;

        if (!ticket) {
            return;
        }

        if (user.role === USER_ROLES.WORKER && ticket.assigned_customer_id && ticket.assigned_customer_id !== user.id) {
            // if ticket is not assigned to another user and you are worker, then redirect to ticket list
            this.request(`/tickets/${user.organization.id}/list`);
        }
    },

    transitionOut(...args: Array<any>) {
        this.ticketID = null;
        this._super(...args);
    },
});

const mapStateToProps = (state) => ({
    templateMap: state.entities.templates || {},
    ticket: getTicket(state),
    user: state.session.user,
});

const mapDispatchToProps = (dispatch) => ({
    createDataItem: (params) => dispatch(createDataItem(params)),
    deleteDataItem: (params) => dispatch(deleteDataItem(params)),
    enqueueSnackbar: (message, options) => dispatch(snackbar.actions.enqueue(message, options)),
    fetchTicket: (params) => dispatch(fetchTicket(params)),
    submitTicket: (params) => dispatch(submitTicket(params)),
    updateTickets: (params) => dispatch(updateTickets(params)),
});

const enhance = compose(
    withTranslation(),
    connectBackbone(mapStateToProps, mapDispatchToProps),
);

export default enhance(Instructions);
