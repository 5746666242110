// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Card, CardActionArea, CardHeader, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import {
    Check as CheckIcon,
    DirectionsRun as DirectionsRunIcon,
    InfoOutlined as InfoOutlinedIcon,
} from '@material-ui/icons';
import type { Dispatch } from 'redux';
import type { Connector } from 'react-redux';
import type { ContextRouter } from 'react-router';
import LoadingPage from '../../../../components/LoadingPage';
import { actions, selectors } from './duck';
import styles from './styles';
import type { State as RootState } from '../../../../redux/initialState';

type OwnProps = ContextRouter & {}
type StateProps = {
    activeCount: number,
    certifications: Object[],
    customer: Object,
    submittedCount: number,
}
type DispatchProps = {
    fetchTicketStats: (customerId: number) => Promise<void>,
}
type Props = OwnProps & StateProps & DispatchProps;

const useStyles = makeStyles(styles, { name: 'Activity' });

export function Activity(props: Props) {
    const { activeCount, certifications, customer, fetchTicketStats, match, submittedCount } = props;
    const { group_memberships: groupMemberships } = customer;
    const customerId = parseInt(match.params.customerId, 10);
    const orgId = parseInt(match.params.orgId, 10);

    const { t } = useTranslation();
    const classes = useStyles(props);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        fetchTicketStats(customerId)
            .then(() => {
                setLoading(false);
            });
    }, [customerId, fetchTicketStats]);

    if (loading) {
        return <LoadingPage />;
    }

    return (
        <div>
            <section className={classes.section}>
                <Typography className={classes.sectionHeader} variant="subtitle1">
                    {t('profilePage.activity.workHistory')}
                </Typography>
                <div className={classes.cards}>
                    <Card elevation={2}>
                        <CardActionArea
                          className={classes.link}
                          component={Link}
                          to={`/tickets/${orgId}/list?status[0]=ASSIGNED&status[1]=SCHEDULED&status[2]=STARTED&assignee[0]=${customerId}`}
                        >
                            <CardHeader
                              avatar={(
                                  <Avatar className={cx(classes.cardAvatar, classes.active)}>
                                      <DirectionsRunIcon fontSize="large" />
                                  </Avatar>
                              )}
                              subheader={activeCount}
                              subheaderTypographyProps={{ color: 'textPrimary', variant: 'h5' }}
                              title={t('profilePage.activity.activeGigs')}
                              titleTypographyProps={{ color: 'textSecondary', variant: 'subtitle' }}
                            />
                        </CardActionArea>
                    </Card>
                    <Card elevation={2}>
                        <CardActionArea
                          className={classes.link}
                          component={Link}
                          to={`/tickets/${orgId}/list?status[0]=SUBMITTED&assignee[0]=${customerId}`}
                        >
                            <CardHeader
                              avatar={(
                                  <Avatar className={cx(classes.cardAvatar, classes.submitted)}>
                                      <CheckIcon fontSize="large" />
                                  </Avatar>
                              )}
                              subheader={submittedCount}
                              subheaderTypographyProps={{ color: 'textPrimary', variant: 'h5' }}
                              title={t('profilePage.activity.submittedGigs')}
                              titleTypographyProps={{ color: 'textSecondary', variant: 'subtitle' }}
                            />
                        </CardActionArea>
                    </Card>
                </div>
            </section>
            <section className={classes.section}>
                <Typography className={classes.sectionHeader} variant="subtitle1">
                    {t('profilePage.activity.groups')}
                </Typography>
                {groupMemberships.length > 0
                    ? (
                        <List classes={{ root: classes.list, padding: classes.listPadding }}>
                            {groupMemberships.map((membership: Object, index: number) => (
                                <ListItem
                                  button
                                  classes={classes.listItem}
                                  data-index={index}
                                  disableRipple
                                  disableFocuseRipple
                                  divider={index < groupMemberships.length - 1}
                                  key={index}
                                >
                                    <ListItemText primary={membership.group} primaryTypographyProps={{ color: 'primary' }} />
                                </ListItem>
                            ))}
                        </List>
                    )
                    : (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <InfoOutlinedIcon className={classes.infoIcon} />
                            <Typography variant="body2">
                                {t('profilePage.activity.noGroups')}
                            </Typography>
                        </div>
                    )}
            </section>
            <section className={classes.section}>
                <Typography className={classes.sectionHeader} variant="subtitle1">
                    {t('profilePage.activity.certifications')}
                </Typography>
                {certifications.length > 0
                    ? (
                        <List classes={{ root: classes.list, padding: classes.listPadding }}>
                            {certifications.map((certification: Object, index: number) => (
                                <ListItem
                                  button
                                  data-index={index}
                                  disableRipple
                                  disableFocuseRipple
                                  divider={index < certifications.length - 1}
                                  key={index}
                                >
                                    <ListItemText primary={certification.title} primaryTypographyProps={{ color: 'primary' }} />
                                </ListItem>
                            ))}
                        </List>
                    )
                    : (
                        <div className={classes.info}>
                            <InfoOutlinedIcon className={classes.infoIcon} />
                            <Typography variant="body2">
                                {t('profilePage.activity.noCertifications')}
                            </Typography>
                        </div>
                    )}
            </section>
        </div>
    );
}

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => ({
    ...state.profilePage.activity,
    certifications: selectors.getCertifications(state, props),
    customer: selectors.getCustomer(state, props),
});
const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    fetchTicketStats: (customerId: number) => dispatch(actions.fetchTicketStats(customerId)),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
export default connector(Activity);
