// @flow
import { createSelector } from 'reselect';
import qs from 'qs';
import moment from 'moment';
import type { ContextRouter } from 'react-router';
import { createEntitySelector } from '../gigwalk';
import type { State as RootState } from '../../store';

type TableData = Array<{
    autoassign: boolean,
    creator: string,
    endDate: string,
    execution: number,
    id: number,
    locations: number,
    startDate: string,
    state: string,
    status: string,
    title: string,
}>;

type SearchParams = {
    size?: number,
    page?: number,
    q?: string,
    sort?: string,
    order?: string,
    organization?: string[],
}

type PathParams = {
    orgId: string,
    state: string,
};

type APIParams = {
    filters?: Object[],
    keywords?: string,
    sort?: Object[],
    limit?: number,
    offset?: number,
}

// Maps the key we use in the url query to the key expected by the API
const apiParams = {
    creator: 'created_customer',
    endDate: 'end_date',
    execution: 'execution',
    locations: 'total_locations',
    startDate: 'start_date',
    state: 'state',
    title: 'title',
};

export const getTableData = createSelector(
    createEntitySelector('subscriptions', (state: RootState) => state.subscriptionList.data),
    (subscriptions: Object[]): TableData => (
        subscriptions
            .filter((subscription) => !!subscription)
            .map((subscription) => {
                const {
                    autoassign,
                    created_customer: creator,
                    description,
                    end_date: endDate,
                    execution,
                    id,
                    organization_id: organizationId,
                    start_date: startDate,
                    state,
                    title,
                    total_locations: locations,
                } = subscription;

                // Note: subscription start/end date has no timezone info. This is intentional. If the project
                // ends at 5:30, it should end at 5:30 everywhere. This makes it hard to determine if the project
                // is actually complete based off end date, as we don't know the timezone of the location with
                // the most negative offset
                const today = moment();
                const isCompleted = moment(endDate).isBefore(today) ? true : execution === 1;
                const isInProgress = !isCompleted && moment(startDate).isBefore(today);

                let status = state === 'ACTIVE' ? 'launched' : '';
                if (isCompleted) {
                    status = 'completed';
                } else if (isInProgress) {
                    status = 'inProgress';
                }

                return {
                    autoassign: !!autoassign,
                    creator,
                    description,
                    endDate: moment(endDate).format(),
                    execution: Math.round(execution * 100),
                    id,
                    locations,
                    organizationId,
                    startDate: moment(startDate).format(),
                    state,
                    status,
                    title,
                };
            })
    )
);

export const parseSearchParams = createSelector(
    <P: {}>(state: RootState, props: { ...P, ...ContextRouter }): string => {
        const { location } = props;
        return location.search;
    },
    (search: string): SearchParams => qs.parse(search, { ignoreQueryPrefix: true })
);

const getOrgIdFromPath = (state: RootState, props: any): string => {
    const { match } = props;
    return match.params.orgId || '';
};

const getStateFromPath = (state: RootState, props: any): string => {
    const { match } = props;
    return match.params.state || '';
};

const getMatchParams = createSelector(
    getOrgIdFromPath,
    getStateFromPath,
    (orgId: string, state: string): PathParams => ({ orgId, state }),
);

export const getAPIParams = createSelector(
    parseSearchParams,
    getMatchParams,
    (state: RootState) => state.session.user,
    (search: SearchParams, path: PathParams, user: ?Object): APIParams => {
        const { order, page, q, size, sort, ...filters } = search;
        const offset = Math.max(parseInt(page, 10) - 1, 0) || 0;
        const limit = Math.max(parseInt(size, 10), 0) || 10;
        const defaultSort = [{ start_date: order || 'desc' }];
        const apiFilters = [];

        if (user && user.role === 'PLATFORM_ADMIN') {
            if (filters.organization) {
                apiFilters.push({
                    key: 'organization_id',
                    value: filters.organization.map((id: string): number => parseInt(id, 10)),
                });
            }
        } else {
            apiFilters.push({
                key: 'organization_id',
                value: parseInt(path.orgId, 10),
            });
        }

        switch (path.state) {
            case 'active':
                apiFilters.push({
                    key: 'state',
                    value: 'ACTIVE',
                });
                break;

            case 'draft':
                apiFilters.push({
                    key: 'state',
                    value: 'DRAFT',
                });
                break;

            case 'archive':
                apiFilters.push({
                    filters: [
                        { key: 'state', value: 'CANCELED' },
                        { key: 'state', value: 'ARCHIVED' },
                    ],
                    filter_type: 'or',
                });
                break;

            default:
                break;
        }

        return {
            filters: apiFilters,
            keywords: q,
            sort: (sort && sort in apiParams) ? [{ [apiParams[sort]]: order || 'asc' }] : defaultSort,
            limit,
            offset,
        };
    }
);

export const getActiveFilters = createSelector(
    createEntitySelector('organizations', createSelector(parseSearchParams, (search: SearchParams) => search.organization)),
    (organization: ?Object[]): ?Object => {
        const filters = { organization };
        return Object.entries(filters)
            .reduce((acc: Object, [key, value]: [string, mixed]) => (
                value !== undefined ? { ...acc, [key]: value } : acc
            ), {});
    }
);

export const getActiveFilterCount = createSelector(
    parseSearchParams,
    (search: Object): number => (
        Object.entries(search).reduce((cnt: number, [key, filter]: [string, mixed]): number => {
            if (['q', 'page', 'size', 'sort', 'order'].includes(key)) {
                return cnt;
            }
            return Array.isArray(filter) ? cnt + filter.length : cnt + 1;
        }, 0)
    )
);

export default {
    getActiveFilterCount,
    getActiveFilters,
    getAPIParams,
    getTableData,
    parseSearchParams,
};
