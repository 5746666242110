// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import ResizeObserver from 'resize-observer-polyfill';
import { Route } from 'react-router';
// $FlowTypedIssue update react-redux libdef
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Link, Switch } from '@material-ui/core';
import { KeyboardArrowLeft as KeyboardArrowLeftIcon } from '@material-ui/icons';
import type { $AxiosError } from 'axios';
import type { ContextRouter } from 'react-router';
import { enqueue as enqueueSnackbar } from '../../ducks/snackbar';
import { loadTicket } from '../../ducks/ticketDetail';
import Instructions from '../../../browser/tickets/view/info/Instructions';
import ApplyDialog from '../../components/ApplyDialog';
import ReviewDialog from '../../components/ReviewDialog';
// import TicketInfoDialog from '../../components/TicketInfoDialog';
import WithdrawDialog from '../../components/WithdrawDialog';
import ExtendReservationWindowDialog from '../../components/ExtendReservationWindowDialog';
import AssignApplicantDialog from '../../components/AssignApplicantDialog';
import AssignDialog from '../../components/AssignDialog';
import CancelDialog from '../../components/CancelDialog';
import ExtendDialog from '../../components/ExtendDialog';
import OptinDialog from '../../components/OptInDialog';
import ReopenDialog from '../../components/ReopenDialog';
import RescheduleDialog from '../../components/RescheduleDialog';
import ScheduleDialog from '../../components/ScheduleDialog';
import UnassignDialog from '../../components/UnassignDialog';
import LoadingPage from '../../components/LoadingPage';
import SimplePane from '../../components/SimplePane';
import Summary from './Summary';
import Tags from './Tags';
import Description from './Description';
import Comments from './Comments';
import Survey from './Survey';
import styles from './styles';

type Props = ContextRouter & {};

const useStyles = makeStyles(styles, { name: 'TicketDetail' });

export default function TicketDetail(props: Props) {
    const { history, match } = props;
    const ticketId = parseInt(match.params.ticketId, 10);
    const orgId = parseInt(match.params.orgId, 10);

    const dispatch = useDispatch();
    const classes = useStyles(props);
    const { t } = useTranslation();

    const [loading, setLoading] = useState(true);

    const gridRef = useRef(null);
    const resizeObserverRef = useRef(null);

    const resizeGrid = useCallback(() => {
        const grid = gridRef.current;

        if (grid instanceof HTMLElement) {
            let leftHeight = 0;
            let rightHeight = 0;

            for (let i = 0; i < grid.children.length; i += 1) {
                const item = grid.children[i];
                const { height } = item.firstElementChild.getBoundingClientRect();

                if (item instanceof HTMLElement) {
                    if (item.classList.contains(classes.leftColumn)) {
                        item.style.top = `${leftHeight}px`; // eslint-disable-line no-param-reassign
                        leftHeight += height;
                    } else {
                        item.style.top = `${rightHeight}px`; // eslint-disable-line no-param-reassign
                        rightHeight += height;
                    }
                }
            }

            grid.style.height = `${Math.max(leftHeight, rightHeight)}px`;
        }
    }, [classes.leftColumn]);

    const goBack = useCallback(() => {
        const historyState = window.history.state;
        if (historyState && historyState.breadcrumb) {
            history.goBack();
        } else {
            history.push(`/tickets/${orgId}/list`);
        }
    }, [history, orgId]);

    useEffect(() => {
        setLoading(true);
        dispatch(loadTicket(ticketId))
            .then(() => {
                setLoading(false);
            })
            .catch((err: $AxiosError<any>) => {
                const resp = err ? err.response : null;
                if (resp && resp.data && resp.data.gw_api_response) {
                    dispatch(enqueueSnackbar(resp.data.gw_api_response, { variant: 'error' }));
                }

                // @todo Handle permission errors in a more general way across the app
                if (resp && (resp.status === 404 || resp.status === 403)) {
                    // Redirect to ticket list after a short delay to avoid weirdness caused
                    // by navigator and AppStateUrlSyncer
                    setTimeout(() => goBack(), 1000);
                }
            });
    }, [dispatch, goBack, ticketId]);

    useEffect(() => {
        if (!loading) {
            resizeGrid();
        }
    }, [loading, resizeGrid]);

    useEffect(() => {
        const grid = gridRef.current;
        let resizeObserver = resizeObserverRef.current;

        if (!loading && !resizeObserver && grid instanceof HTMLElement) {
            resizeObserver = new ResizeObserver(() => {
                resizeGrid();
            });

            for (let i = 0; i < grid.children.length; i += 1) {
                const item = grid.children[i];
                resizeObserver.observe(item.firstElementChild);
            }

            resizeObserverRef.current = resizeObserver;
        }

        return () => {
            if (resizeObserver) {
                resizeObserver.disconnect();
                resizeObserverRef.current = null;
            }
        };
    }, [loading, resizeGrid]);

    if (loading) {
        return (
            <SimplePane>
                <LoadingPage />
            </SimplePane>
        );
    }

    return (
        <SimplePane className={classes.root}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Link className={classes.link} onClick={goBack} underline="none" variant="body2">
                    <KeyboardArrowLeftIcon fontSize="small" />
                    {t('ticketDetail.breadcrumbText')}
                </Link>
                <Switch
                  checked={match.params.page === 'detail'}
                  color="primary"
                  onChange={() => {
                      const page = match.params.page === 'detail' ? 'info' : 'detail';
                      history.replace(`/tickets/${orgId}/${page}/${ticketId}`);
                  }}
                />
            </div>
            <div className={classes.grid} ref={gridRef}>
                <div className={cx(classes.item, classes.leftColumn)}>
                    <div className={classes.contentWrapper}>
                        <Route component={Summary} />
                    </div>
                </div>
                <div className={cx(classes.item, classes.leftColumn)}>
                    <div className={classes.contentWrapper}>
                        {match.params.page === 'detail'
                            ? <Route component={Survey} />
                            : <Route component={Instructions} />}
                    </div>
                </div>
                <div className={cx(classes.item, classes.rightColumn)}>
                    <div className={classes.contentWrapper}>
                        <Route component={Tags} />
                    </div>
                </div>
                <div className={cx(classes.item, classes.rightColumn)}>
                    <div className={classes.contentWrapper}>
                        <Route component={Description} />
                    </div>
                </div>
                <div className={cx(classes.item, classes.rightColumn)}>
                    <div className={classes.contentWrapper}>
                        <Route component={Comments} />
                    </div>
                </div>
            </div>
            <ReviewDialog />
            {/* <TicketInfoDialog /> */}
            <ApplyDialog />
            <WithdrawDialog />
            <ExtendReservationWindowDialog />
            <AssignApplicantDialog />
            <AssignDialog />
            <CancelDialog />
            <ExtendDialog />
            <OptinDialog />
            <ReopenDialog />
            <RescheduleDialog />
            <ScheduleDialog />
            <UnassignDialog />
        </SimplePane>
    );
}
