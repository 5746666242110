// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback, useState } from 'react';
import merge from 'lodash/merge';
import uniqueId from 'lodash/uniqueId';
import { Form } from 'formik';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { ClickAwayListener, Fab } from '@material-ui/core';
import {
    Add as AddIcon,
    Search as SearchIcon,
} from '@material-ui/icons';
import { compose } from 'recompose';
import type { Dispatch } from 'redux';
import type { Connector } from 'react-redux';
import type { ContextRouter } from 'react-router';
import type { $AxiosXHR } from 'axios';
import { client as gigwalk } from '../../../../api/createGigwalkClient';
import mapEntityToQuestion from '../../utils/mapEntityToQuestion';
import Select from '../../../../components/Select';
import Step from '../../components/Step';
import AttachFileDialog from './components/AttachFileDialog';
import QuestionList from './components/QuestionList';
import QuestionCard from './components/QuestionCard';
import { actions } from './duck';
import styles from './styles';
import { VALUE_TYPE } from '../../../../../browser/shared/constant/ProjectConstant';
import type { State as RootState } from '../../../../redux/initialState';

type OwnProps = ContextRouter & {
    errors: Object,
    setFieldValue: (field: string, value: any) => void,
    values: Object,
};
type StateProps = {
    expanded: ?number,
};
type DispatchProps = {
    collapseQuestion: (index: number) => void,
    expandQuestion: (index: number) => void,
};
type Props = OwnProps & StateProps & DispatchProps;

const maxFileSize = 15 * 1024 * 1024; // 15 MB (15728640 bytes)
const preventDefault = (event: SyntheticEvent<any>) => {
    event.preventDefault();
};

const useStyles = makeStyles(styles, { name: 'Questions' });

export function Questions(props: Props) {
    const {
        collapseQuestion,
        errors,
        expanded,
        expandQuestion,
        match,
        setFieldValue,
        values,
    } = props;

    const { questionList } = values;
    const orgId = parseInt(match.params.orgId, 10);

    const classes = useStyles(props);
    const { t } = useTranslation();

    const [dialogOpen, setDialogOpen] = useState(false);

    const search = useCallback((query: string): Promise<Object[]> => {
        const params = {
            organization_id: orgId,
            query_string: query,
        };

        return gigwalk.dataTypes.search(params)
            .then((resp: $AxiosXHR<Object>) => {
                const searchResults = resp.data.data ? resp.data.data.search_results || [] : [];
                const dataTypes = searchResults.reduce((results: Object[], record: { score: number, data: Object }) => {
                    results.push(record.data);
                    return results;
                }, []);

                return dataTypes.map((dataType: Object) => ({
                    label: dataType.questions.question_text,
                    value: dataType,
                }));
            });
    }, [orgId]);

    const handleAddNew = useCallback(() => {
        const question = {
            attachments: [],
            id: uniqueId('new_'),
            description: '',
            valueType: VALUE_TYPE.FREE_TEXT,
            questionText: '',
            propositions: [],
            required: false,
        };

        setFieldValue('questionList', [...questionList, question]);
        expandQuestion(questionList.length);
    }, [expandQuestion, questionList, setFieldValue]);

    const handleAddExisting = useCallback((option: Object) => {
        const question = mapEntityToQuestion(option.value);
        question.id = uniqueId(`duplicate_${question.id}_`);
        setFieldValue('questionList', [...questionList, question]);
    }, [questionList, setFieldValue]);

    const handleDialogClose = (event: SyntheticEvent<any>) => {
        event.preventDefault();
        setDialogOpen(false);
    };

    const handleAddAttachments = (event: SyntheticEvent<any>, uploads: Object[]) => {
        event.preventDefault();

        if (expanded != null) {
            const { attachments } = questionList[expanded];
            const newAttachments = uploads.map((upload: Object) => ({
                file_name: upload.file.name,
                file_type: upload.file.type,
                url: upload.url,
            }));

            setFieldValue(`questionList.${expanded}.attachments`, [...attachments, ...newAttachments]);
        }

        setDialogOpen(false);
    };

    return (
        <Step className={classes.container} title={t('projectBuilder.data.header')}>
            <Form className={classes.form} onSubmit={preventDefault}>
                <AttachFileDialog
                  fullWidth
                  maxSize={maxFileSize}
                  open={dialogOpen}
                  onClose={handleDialogClose}
                  onConfirm={handleAddAttachments}
                />
                <Select
                  className={classes.autocomplete}
                  ControlProps={{
                      fullWidth: true,
                      variant: 'outlined',
                  }}
                  controlShouldRenderValue={false}
                  disabled={expanded != null}
                  dropdownIcon={<SearchIcon />}
                  loadOptions={search}
                  menuPlacement="auto"
                  onChange={handleAddExisting}
                  placeholder={t('projectBuilder.data.searchPlaceholder')}
                />
                <QuestionList className={classes.questionList}>
                    {questionList.map((question: Object, index: number) => {
                        const isQuestionValid = errors && errors.questionList && errors.questionList[index]
                            ? Object.keys(errors.questionList[index]).length === 0
                            : true;

                        const handleDuplicate = () => {
                            const duplicate = merge({}, question);
                            const { id } = duplicate;

                            // Don't use 'duplicate' as the prefix for new questions. We rely on the 'new' prefix to
                            // identify questions that need to be created and then added to the subscription.
                            // For simplicity's sake, a duplicate of a new question is just a new question
                            duplicate.id = typeof id === 'string' && id.startsWith('new')
                                ? uniqueId(`new_${id}_`)
                                : uniqueId(`duplicate_${id}_`);

                            const duplicateIndex = index + 1;
                            const copy = [...questionList];
                            copy.splice(duplicateIndex, 0, duplicate);
                            setFieldValue('questionList', copy);
                            expandQuestion(duplicateIndex);
                        };

                        const handleDelete = () => {
                            const copy = [...questionList];
                            copy.splice(index, 1);
                            setFieldValue('questionList', copy);
                            collapseQuestion(index);
                        };

                        const handleClick = () => {
                            // Only allow one question to be expanded at a time
                            if (expanded == null) {
                                expandQuestion(index);
                            }
                        };

                        const handleClickAway = () => {
                            if (!dialogOpen && expanded === index && isQuestionValid) {
                                collapseQuestion(index);
                            }
                        };

                        const handleAttachFile = () => {
                            setDialogOpen(true);
                        };

                        return (
                            <ClickAwayListener onClickAway={handleClickAway}>
                                <li key={question.id} className={classes.question}>
                                    <QuestionCard
                                      {...question}
                                      moveCard={(indexA: number, indexB: number) => {
                                          const copy = [...questionList];
                                          const a = questionList[indexA];
                                          copy[indexA] = questionList[indexB];
                                          copy[indexB] = a;
                                          setFieldValue('questionList', copy);
                                      }}
                                      draggable={expanded == null}
                                      index={index}
                                      onAttachFile={handleAttachFile}
                                      onDelete={handleDelete}
                                      onDuplicate={handleDuplicate}
                                      onClick={handleClick}
                                      expanded={index === expanded}
                                      valid={isQuestionValid}
                                    />
                                </li>
                            </ClickAwayListener>
                        );
                    })}
                </QuestionList>
                <Fab
                  className={classes.button}
                  color="secondary"
                  disabled={expanded != null}
                  onClick={handleAddNew}
                >
                    <AddIcon />
                </Fab>
            </Form>
        </Step>
    );
}

const mapStateToProps = (state: RootState): StateProps => ({
    ...state.projectBuilder.questions,
});

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    collapseQuestion: (index: number) => dispatch(actions.collapseQuestion(index)),
    expandQuestion: (index: number) => dispatch(actions.expandQuestion(index)),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
const enhance = compose(
    withRouter,
    connector,
);

export default enhance(Questions);
