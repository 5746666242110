// @flow
export default (theme: Object) => ({
    container: {
        position: 'relative',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    },
    autocomplete: {
        width: '100%',
        maxWidth: 600,
        marginBottom: theme.spacing(2),
    },
    questionList: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        marginBottom: 40,
    },
    question: {
        width: '100%',
        maxWidth: 600,
        marginBottom: theme.spacing(1),
    },
    button: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        zIndex: 10,
    },
});
