// @flow
export default () => ({
    root: {
        position: 'relative',
        overflow: 'hidden',
        width: 40,
        height: 40,
        borderRadius: '50%',

        '&$sizeMedium': {
            width: 74,
            height: 74,

            '& > $initials': {
                fontSize: 40,
            },
        },

        '&$sizeLarge': {
            width: 115,
            height: 115,

            '& > $initials': {
                fontSize: 50,
            },
        },
    },
    image: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    portrait: {
        '& $image': {
            width: '100%',
            height: 'auto',
        },
    },
    landscape: {
        '& $image': {
            width: 'auto',
            height: '100%',
            maxWidth: 'none',
        },
    },
    initials: {
        fontWeight: 600,
        lineHeight: 'normal',
    },
    sizeMedium: {},
    sizeLarge: {},
});
