// @flow
// $FlowIssue need to update to a more recent flow version
import React, { Fragment, useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { ListItemAvatar, ListItemText } from '@material-ui/core';
import { Check as CheckIcon, Search as SearchIcon } from '@material-ui/icons';
import UserAvatar from '../../UserAvatar';
import StarRating from '../../StarRating';
import Select from '../../Select';
import styles from './styles';

type Props = {
    classes?: Object,
    options: Object[],
};

const sortOptions = [
    { label: 'Rating', value: 'rating' },
    { label: 'Application date', value: 'applicationDate' },
    { label: 'Distance from gig', value: 'distance' },
];

const sortByRating = ({ value: a }: Object, { value: b }: Object): number => (
    parseFloat(b.rating_score || 0) - parseFloat(a.rating_score || 0)
);

// @todo Implement sortByApplicationDate and sortByDistance
const sortByApplicationDate = () => 0;
const sortByDistance = () => 0;

const stopPropagation = (event: SyntheticEvent<any>) => {
    event.stopPropagation();
    event.preventDefault();
};

const useStyles = makeStyles(styles, { name: 'ApplicantSelect' });

export default function ApplicantSelect(props: Props) {
    const { options } = props;

    const classes = useStyles(props);

    const [sort, setSort] = useState(sortOptions[0]);

    const handleSortChange = useCallback((value: Object) => {
        setSort(value);
    }, []);

    const formatOptionLabel = (option: Object, meta: Object) => {
        const { label, value } = option;
        const { comment, rating_score: score } = value;
        const userRating = Math.round(score * 10) / 10;

        const primary = (
            <Fragment>
                <div>{label}</div>
                <div className={classes.rating}>
                    <StarRating className={classes.stars} value={userRating || 0} disableHover />
                    {userRating ? userRating.toFixed(1) : 'N/A'}
                </div>
            </Fragment>
        );

        return (
            <Fragment>
                <ListItemAvatar>
                    <UserAvatar user={value} />
                </ListItemAvatar>
                <ListItemText
                  style={{ height: 'auto' }}
                  classes={{ secondary: classes.comment }}
                  primary={primary}
                  secondary={comment}
                />
                {meta.isSelected ? <CheckIcon /> : null}
            </Fragment>
        );
    };

    const renderMenuList = (menuListProps: Object) => {
        const { children, innerProps, innerRef } = menuListProps;

        // Create a wrapper around the Select element to capture and prevent event
        // propagation to parent component
        return (
            <Fragment>
                <div onMouseDown={stopPropagation} onMouseMove={stopPropagation}>
                    <Select
                      classes={{
                          menu: classes.sortMenu,
                          singleValue: classes.sortValue,
                      }}
                      ControlProps={{ disableUnderline: true }}
                      controlShouldRenderValue
                      isSearchable={false}
                      onChange={handleSortChange}
                      options={sortOptions}
                      value={sort}
                      isDisabled
                    />
                </div>
                <div {...innerProps} ref={innerRef} style={{ overflowY: 'auto' }}>
                    {children}
                </div>
            </Fragment>
        );
    };

    let compareFunction;
    switch (sort.value) {
        case 'rating':
            compareFunction = sortByRating;
            break;
        case 'applicationDate':
            compareFunction = sortByApplicationDate;
            break;
        case 'distance':
            compareFunction = sortByDistance;
            break;
        default:
            break;
    }

    return (
        <Select
          {...props}
          backspaceRemovesValue={false}
          ControlProps={{ fullWidth: true }}
          components={{ MenuList: renderMenuList }}
          controlShouldRenderValue={false}
          dropdownIcon={<SearchIcon />}
          formatOptionLabel={formatOptionLabel}
          menuIsOpen
          menuType="list"
          minMenuHeight={400}
          maxMenuHeight={400}
          options={[...(options || [])].sort(compareFunction)}
        />
    );
}
