// @flow
export default (theme: Object) => ({
    toolbar: {
        fontSize: 16,
        paddingTop: 4,
        paddingBottom: 4,
    },
    comments: {
        borderTop: [[1, 'solid', theme.palette.divider]],
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', { duration: theme.transitions.duration.shortest }),
        marginLeft: 'auto',
        marginRight: -16,
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    gigwalkAvatar: {
        backgroundColor: theme.palette.primary.main,
    },
    commentAvatar: {
        marginBottom: 'auto',
        marginTop: 6,
    },
    commentHeader: {
        fontSize: 15,
    },
    commentText: {
        fontSize: 16,
    },
    author: {
        marginRight: theme.spacing(1),
        fontWeight: 600,
    },
    date: {
        color: 'rgb(170, 170, 170)',
    },
});
