// @flow
import { VALUE_TYPE } from '../../../../browser/shared/constant/ProjectConstant';

type Proposition = {
    alert: boolean,
    choice: string,
};

type Question = {
    attachments: Object[],
    children?: Object[],
    description: string,
    globalDataTypeId: number,
    id: number | string,
    propositions: Proposition[],
    questionText: string,
    required: boolean,
    targetListId?: number,
    valueType: string,
};

export default function mapQuestionToEntity(question: Question) {
    const {
        description,
        globalDataTypeId,
        id,
        questionText,
        propositions,
        required,
        valueType,
    } = question;

    const alerted = [];
    const notAlerted = [];
    propositions.forEach((p: Proposition) => {
        if (p.alert) alerted.push(p.choice);
        else notAlerted.push(p.choice);
    });

    let expectedValueExpression;
    if (propositions.length === 0) {
        expectedValueExpression = null;
    } else if (alerted.length === propositions.length) {
        // All proposition have alerts
        expectedValueExpression = {
            $and: alerted.map((choice: string): Object => ({
                $ne: ['$data_item_value', choice],
            })),
        };
    } else if (notAlerted.length === 1) {
        // All but one proposition has an alert
        expectedValueExpression = {
            $eq: ['$data_item_value', notAlerted[0]],
        };
    } else if (alerted.length > 0) {
        // Some propositions have alerts
        expectedValueExpression = {
            $or: notAlerted.map((choice: string): Object => ({
                $eq: ['$data_item_value', choice],
            })),
        };
    }

    // todo: figure out if there are other restrictions to question_type
    let questionType;
    switch (valueType) {
        case VALUE_TYPE.SIGNATURE:
            questionType = VALUE_TYPE.PHOTO;
            break;

        case VALUE_TYPE.HINT:
        case VALUE_TYPE.TASK:
            questionType = VALUE_TYPE.CHECK;
            break;

        default:
            questionType = valueType;
            break;
    }

    return {
        dashboard_visible: true,
        description,
        expected_value_expression: expectedValueExpression || null,
        global_data_type_id: globalDataTypeId,
        id,
        is_required: required || false,
        questions: {
            question_text: questionText,
            question_type: questionType,
            propositions: propositions.map((p: Proposition): string => p.choice),
        },
        value_type: valueType,
    };
}
