// @flow
import debounce from 'lodash/debounce';
import isMatch from 'lodash/isMatch';
import isEqual from 'lodash/isEqual';
import Component from '../Component';
import template from '../../templates/ui/dropdown/DropdownList.hbs';
import listItemTpl from '../../templates/ui/dropdown/ListItem.hbs';
import selectedItemTpl from '../../templates/ui/dropdown/SelectedItem.hbs';
import searchTpl from '../../templates/Search.hbs';

export type DropdownListProps = {
    localisation?: Object,
    menuSize?: number,
    searchable?: boolean,
    multiselect?: boolean,
    maxSelect?: number,
    labelKey?: string,
};

/**
 * @class (View) DropdownList
 * @constructor
 */
const DropdownList = Component.extend({

    className: 'dropdown-list',

    events: {
        'keyup [data-search-input]': 'onSearchUpdate',
        'click [data-search-input]': 'onSearchUpdate',
        'wheel [data-target="dropdown-list"]': 'onWheelScroll',
        'click [data-target="list-item"]': 'onSelect',
        'click [data-action="remove"]': 'onDeselect',
        'click .dropdown-menu': 'preventClose',
    },

    /**
     * @method preventClose
     * @param event
     */
    preventClose(event: Event) {
        event.stopImmediatePropagation();
    },

    /**
     * @method initialize
     */
    initialize(options: DropdownListProps = {}) {
        const props = {
            localisation: GW.localisation.shared.dropdown,
            menuSize: Number.MAX_VALUE,
            searchable: false,
            multiselect: false,
            labelKey: '',
            ...options,
            maxSelect: (options.maxSelect && options.maxSelect > 0) ? options.maxSelect : 1,
        };

        const { labelKey, localisation, maxSelect, menuSize, multiselect, searchable } = props;
        this.localisation = localisation;

        // Parse options
        this.menuSize = menuSize;
        this.searchable = searchable;
        this.multiselect = multiselect;
        this.maxSelect = maxSelect;

        // Collection of items that are visible in the dropdown menu
        this.menu = new Backbone.Collection();

        // List of ids which reference selected items from the dropdown list
        this.selected = [];

        // List of selectors (objects) that disable any dropdown items which match
        this.disabled = [];

        // Wait for user to stop typing before executing search
        this.onSearch = debounce(() => this.onSearch(), 250);

        this._lastVal = '';
        this.labelKey = labelKey;

        // TODO: Calling render in initialize may be problematic for sub-classes if they invoke this._super
        // Render may be called multiple times before all internal properties are initialized.
        this.render();
    },

    /**
     * @method render
     */
    render() {
        this.$el.html(template({
            searchable: this.searchable,
            multiselect: this.multiselect,
            ...this.localisation,
        }));

        if (this.searchable) {
            const searchProps = {
                showList: false,
                placeholder: '',
                submit: false,
            };

            this.$el.find('[data-target="search"]').append(searchTpl(searchProps));
            this.$els = {
                ...(this.$els || {}),
                searchField: this.$el.find('[data-search-input]'),
                searchIcon: this.$el.find('[data-search-icon]'),
                buffering: this.$el.find('[data-buffering]'),
                clearButton: this.$el.find('[data-search-clear]'),
            };
            this.$els.clearButton.on('click', () => this.clearSearch());
            this.$els.clearButton.hide();
        }

        this.$els = {
            ...(this.$els || {}),
            buttonLabel: this.$el.find('[data-target="button-label"]'),
            list: this.$el.find('[data-target="dropdown-list"]'),
            listInner: this.$el.find('[data-target="dropdown-list"] > div:first'),
            selectedItems: this.$el.find('[data-target="selected-items"]'),
            dropdown: this.$el.find('[data-target="dropdown"]'),
            searchIcon: this.$el.find('[data-search-icon]'),
        };
    },

    /**
     * @method renderMenu
     */
    renderMenu() {
        this.$els.listInner.html('');

        const frag = document.createDocumentFragment();

        this.menu.each((menuItem: Backbone.Model) => {
            const item = menuItem.toJSON();

            const menuItemProps = {
                label: item[this.labelKey] || item[this.labelKey.toLowerCase()],
                ...item,
            };
            const $listItem = $(this.listItemTpl(menuItemProps));
            const disabled = this.disabled.some((selector: Object): boolean => isMatch(item, selector));

            $listItem
                .attr('data-id', item.id)
                .data('id', item.id)
                .toggleClass('disabled', disabled);

            frag.appendChild($listItem[0]);
        }, this);

        this.$els.list.scrollTop(0);
        this.$els.listInner.append(frag);
        this.setIcon(false);
    },

    renderSelected() {
        this.$els.selectedItems.html('');

        const frag = document.createDocumentFragment();

        this.selected.forEach((id: number) => {
            // Due to inconsistencies in metadata key naming, we need to check for lowercase
            // key if first lookup fails. (ex. Client_Name vs client_name)
            const item = this.getItem(id);
            if (!item) {
                return;
            }

            const selectedItemProps = {
                label: item[this.labelKey] || item[this.labelKey.toLowerCase()],
                ...item,
            };
            const $selectedItem = $(this.selectedItemTpl(selectedItemProps));

            $selectedItem
                .attr('data-id', item.id)
                .data('id', item.id);

            frag.appendChild($selectedItem[0]);
        });

        this.$els.selectedItems.append(frag);
    },

    listItemTpl(data: Object) {
        return listItemTpl({
            ...data,
            ...this.localisation,
        });
    },

    selectedItemTpl(data: Object) {
        return selectedItemTpl({
            ...data,
            ...this.localisation,
        });
    },

    /**
     * @method onSearchUpdate
     */
    onSearchUpdate(event: Event) {
        const val = this.$els.searchField.val().trim();

        if (val !== this._lastVal) {
            this._lastVal = val;
            this.search(val);
        }

        event.stopImmediatePropagation();
    },

    /**
     * Prevent scrolling window at end of child box
     * @method onWheelScroll
     * @returns {boolean}
     */
    onWheelScroll(event: WheelEvent) {
        const scrollTop = this.$els.list.scrollTop();
        // $FlowFixMe jquery libdef doesn't define an event object for wheel events
        const dy = event.deltaY || event.originalEvent.deltaY;
        const dh = this.$els.listInner.height() - this.$els.list.height();

        if (scrollTop === dh && dy >= 0 || scrollTop === 0 && dy <= 0) {
            event.preventDefault();
            event.stopImmediatePropagation();
            return false;
        }
    },

    onSelect(event: Event) {
        const $target = $(event.currentTarget);
        const id = $target.data('id');

        if ($target.hasClass('disabled')) {
            return;
        }

        this.select(id);
        this.$els.dropdown.removeClass('open');

        event.stopImmediatePropagation();
    },

    onDeselect(event: Event) {
        const $target = $(event.currentTarget).closest('[data-target="list-item"]');
        const id = $target.data('id');

        this.deselect(id);

        event.stopImmediatePropagation();
    },

    /**
     * @method clearSearch
     */
    clearSearch() {
        if (!this.searchable) {
            return;
        }

        this.$els.searchField.val('');
        this.search('');
    },

    /**
     * @method buffering
     */
    buffering() {
        this.clearMenu();
        this.setIcon(true);
    },

    /**
     *
     * @returns {Array}
     */
    getSelected() {
        return this.selected.map((id: number) => this.getItem(id));
    },

    /**
     * @method clearSelected
     */
    clearSelected() {
        this.trigger('remove', this.getSelected());

        this.selected = [];
        this.$els.selectedItems.html('');
        this.$els.buttonLabel.html(this.localisation.select_from_options);
    },

    /**
     * @method clearMenu
     */
    clearMenu() {
        this.$els.listInner.html('');
        this.menu.reset();
    },

    /**
     * Adds an item to the list of selected options
     * @method select
     * @param selector
     */
    select(selector: Object) {
        const item = this.getItem(selector);
        let lastItem;

        // If item is invalid or it's already selected, don't do anything
        if (!item || this.selected.indexOf(item.id) >= 0) {
            return;
        }

        // Have we reached the maximum number of selected items?
        if (this.selected.length >= this.maxSelect) {
            lastItem = this.selected[this.selected.length - 1];
            this.deselect(lastItem);
        }

        // Update view
        if (this.multiselect) {
            const selectedItemProps = {
                label: item[this.labelKey],
                ...item,
            };
            const $selectedItem = $(this.selectedItemTpl(selectedItemProps));

            $selectedItem
                .attr('data-id', item.id)
                .data('id', item.id);

            this.$els.selectedItems.append($selectedItem);
        } else {
            // Set the button to the selected label
            this.$els.buttonLabel.html(item[this.labelKey]);
        }

        this.selected.push(item.id);
        this.trigger('select', item);
    },

    /**
     * Removes an item from the list of selected options
     * @method deselect
     * @param selector
     */
    deselect(selector: Object) {
        const item = this.getItem(selector);
        const index = this.selected.indexOf(item.id);

        if (!item || index === -1) {
            return;
        }

        // Update view
        if (this.multiselect) {
            this.$els.selectedItems.find(`[data-id="${item.id}"]`).remove();
        } else {
            this.$els.buttonLabel.html(this.localisation.select_from_options);
        }

        this.selected.splice(index, 1);
        this.trigger('deselect', item);
    },

    /**
     * Sets the attribute/key to be used as the label when displaying dropdown items.
     * @param attr
     */
    setMenuLabel(attr: string) {
        this.labelKey = attr;
    },

    /**
     * Disables any dropdown items that match the given selector (attribute hash)
     * @method disableOption
     * @param selector
     */
    disableOption(selector: Object) {
        if (!selector) {
            return;
        }

        // Check disabled collection to see if selector already exists
        const exists = this.disabled.some((element: Object) => isEqual(element, selector));

        if (!exists) {
            this.disabled.push(selector);
        }

        // Find any menu items that match the selector and disable them in the view
        this.menu.each((item: Backbone.Model) => {
            if (item.matches(selector)) {
                const id = item.get('id');
                if (typeof id === 'number' || typeof id === 'string') {
                    this.$els.listInner
                        .find(`[data-id="${id}"]`)
                        .addClass('disabled');
                }
            }
        });
    },

    /**
     * Enables any dropdown items that matches the given selector (attribute hash)
     * @method disableOption
     * @param selector
     */
    enableOption(selector: Object) {
        if (!selector) {
            return;
        }

        // Remove any items from the disabled collection that match the selector
        this.disabled = this.disabled.filter((element: Object): boolean => !isMatch(element, selector));

        // Find any menu items that match the selector and enable them in the view
        this.menu.each((item: Backbone.Model) => {
            if (item.matches(selector)) {
                const id = item.get('id');
                if (typeof id === 'number' || typeof id === 'string') {
                    this.$els.listInner
                        .find(`[data-id="${id}"]`)
                        .removeClass('disabled');
                }
            }
        }, this);
    },

    enableAllOptions() {
        this.disabled = [];
        this.$els.listInner.find('.disabled').removeClass('disabled');
    },

    activate() {
        this._super();
        this.listenTo(this.menu, 'update', this.renderMenu);
        this._lastVal = '';
        this.clearSearch();
        this.renderMenu();
    },

    /**
     * @method setIcon
     */
    setIcon(isBuffering: boolean) {
        if (!this.searchable) {
            return;
        }

        if (!isBuffering) {
            if (this.$els.searchField.val().length > 0) {
                this.$els.searchIcon.hide();
                this.$els.clearButton.show();
            } else {
                this.$els.searchIcon.show();
                this.$els.clearButton.hide();
            }

            this.$els.buffering.hide();
        } else {
            this.$els.buffering.show();
            this.$els.searchIcon.hide();
            this.$els.clearButton.hide();
        }
    },

    /**
     * @method toString
     * @returns {string}
     */
    toString(): string {
        return 'DropdownList';
    },
});

export default DropdownList;
