// @flow
import { createAction } from 'redux-actions';
import { matchPath } from 'react-router';
import identity from 'lodash/identity';
import omitBy from 'lodash/omitBy';
import type { Dispatch } from 'redux';
import type { $AxiosError } from 'axios';
import { findPlaceFromQuery } from '../googleMaps';
import { fetchOrganization, searchSubscriptions, searchTickets } from '../gigwalk';
import types from './types';
import { getAPIParams } from './selectors';
import type { State as RootState } from '../../store';

export const select = createAction(types.SELECT);
export const selectAll = createAction(types.SELECT_ALL);
export const deselect = createAction(types.DESELECT);
export const deselectAll = createAction(types.DESELECT_ALL);
export const clearSelected = createAction(types.CLEAR_SELECTED);

export const toggleFilterDrawer = createAction(types.TOGGLE_FILTER_DRAWER);

export const resetColumns = createAction(types.RESET_COLUMNS);
export const toggleColumn = createAction(types.TOGGLE_COLUMN, (name: string, enabled: boolean) => ({ enabled, name }));

export const fetchDataSuccess = createAction(types.FETCH_DATA_SUCCESS, identity, (data: Object, meta: Object): Object => meta);
export const fetchDataError = createAction(types.FETCH_DATA_ERROR);
export const fetchData = createAction(
    types.FETCH_DATA,
    (filters: ?Object[], keywords: ?string, sort: ?Object[], limit: ?number, offset: ?number): Function => (
        (dispatch: Dispatch<any>): Promise<void> => {
            const data = omitBy({ filters, keywords, offset, limit, sort }, (value) => value == null);
            return dispatch(searchTickets(data))
                .then((resp: Object) => {
                    dispatch(fetchDataSuccess(resp.result, resp.metadata));
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(fetchDataError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const loadFiltersSuccess = createAction(types.LOAD_FILTERS_SUCCESS);
export const loadFiltersError = createAction(types.LOAD_FILTERS_ERROR);
export const loadFilters = createAction(
    types.LOAD_FILTERS,
    (search: Object) => (
        (dispatch: Dispatch<any>) => Promise.resolve()
            .then(() => {
                const promises = [];
                if (search.organization) {
                    search.organization.forEach((id) => {
                        promises.push(dispatch(fetchOrganization({ organization_id: id })));
                    });
                }
                if (search.subscription) {
                    const apiFilters = [{ key: 'id', value: search.subscription.map((id) => parseInt(id, 10)) }];
                    promises.push(dispatch(searchSubscriptions({ filters: apiFilters })));
                }
                if (search.location) {
                    search.location.forEach((location: Object) => {
                        const [specificity, value] = Object.entries(location)[0];

                        if (specificity !== 'name') {
                            const params = {
                                fields: ['formatted_address', 'place_id'],
                                query: value,
                            };
                            promises.push(dispatch(findPlaceFromQuery(params)));
                        }
                    });
                }
                return Promise.all(promises);
            })
            .then(() => {
                dispatch(loadFiltersSuccess());
            })
            .catch((err: $AxiosError<any>) => {
                dispatch(loadFiltersError(err));
                return Promise.reject(err);
            })
    )
);

export const refreshError = createAction(types.REFRESH_ERROR);
export const refreshSuccess = createAction(types.REFRESH_SUCCESS);
export const refresh = createAction(
    types.REFRESH,
    (): Function => (
        (dispatch: Dispatch<any>, getState: () => RootState): Promise<void> => {
            const state = getState();

            // @todo: Consider using another method to compute match to pass to selectors
            // I think the next best approach would be to pass props/match to action creators. The
            // issue with the current approach is that state.router.location is not guaranteed to always
            // be in sync with react-router. For instance, server-side matching and rendering happens
            // synchronously. Redux state will be updated in response, but content will already be rendered.
            const { location } = state.router;
            const match = matchPath(location.pathname, { path: '/tickets/:orgId/:view(list|map)' });

            if (!match) {
                return Promise.reject();
            }

            const { filters, keywords, sort, limit, offset } = getAPIParams(state, { location, match });

            // $FlowFixMe - flow doesn't seem to understand function signature allows for possibly null values
            return dispatch(fetchData(filters, keywords, sort, limit, offset))
                .then(() => {
                    dispatch(refreshSuccess());
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(refreshError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export default {
    clearSelected,
    deselect,
    deselectAll,
    fetchData,
    fetchDataError,
    fetchDataSuccess,
    loadFilters,
    loadFiltersError,
    loadFiltersSuccess,
    refresh,
    refreshError,
    refreshSuccess,
    resetColumns,
    select,
    selectAll,
    toggleColumn,
    toggleFilterDrawer,
};
