// @flow
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import type { ContextRouter } from 'react-router';
import { customer as customerSchema } from '../../../redux/entities/schemas';
import type { State as RootState } from '../../../redux/initialState';

const getUser = (state: RootState): ?Object => state.session.user;
const getEntities = (state: RootState): Object => state.entities;
const getCustomerId = <P: {}>(state: RootState, props: { ...P, ...ContextRouter }): number => {
    const { match } = props;
    return parseInt(match.params.customerId, 10);
};

export const getCustomer = createSelector(
    getUser,
    getEntities,
    getCustomerId,
    (user: ?Object, entities: Object, customerId: number) => (
        user && user.id === customerId ? user : denormalize(customerId, customerSchema, entities)
    )
);

export default {
    getCustomer,
};
