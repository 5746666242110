// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback, useState } from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { CircularProgress, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import styles from './styles';

type Props = {
    classes?: Object,
    className?: string,
    disabled?: boolean,
    onClick: (event: SyntheticEvent<>) => ?Promise<void>
};

const useStyles = makeStyles(styles, { name: 'UnsubscribeButton' });

export function UnsubscribeButton(props: Props) {
    const {
        classes: classesProp,
        className: classNameProp,
        disabled,
        onClick,
        ...other
    } = props;

    const classes = useStyles(props);
    const [submitting, setSubmitting] = useState(false);

    const handleClick = useCallback((event: SyntheticEvent<any>) => {
        const promise = onClick(event);

        if (promise instanceof Promise) {
            setSubmitting(true);
            promise
                .catch(() => {})
                .then(() => {
                    setSubmitting(false);
                });
        }
    }, [onClick]);

    const className = cx(classes.root, classNameProp, {
        [classes.submitting]: submitting === true,
    });

    return (
        <IconButton
          className={className}
          onClick={handleClick}
          disabled={submitting || disabled}
          {...other}
        >
            {submitting
                ? <CircularProgress className={classes.progress} size={18} thickness={4} />
                : <CloseIcon fontSize="inherit" />}
        </IconButton>
    );
}

UnsubscribeButton.defaultProps = {
    onClick: () => {},
};

export default UnsubscribeButton;
