// @flow
const namespace = 'g2/ticketDetail';

export const TOGGLE_LOCATION_ENABLED = `${namespace}/TOGGLE_LOCATION_ENABLED`;
export const REGISTER_FORM = `${namespace}/REGISTER_FORM`;
export const UNREGISTER_FORM = `${namespace}/UNREGISTER_FORM`;

export const LOAD_TICKET = `${namespace}/LOAD_TICKET`;
export const LOAD_TICKET_SUCCESS = `${LOAD_TICKET}_SUCCESS`;
export const LOAD_TICKET_ERROR = `${LOAD_TICKET}_ERROR`;

export const FETCH_ALL_APPLICANTS = `${namespace}/FETCH_ALL_APPLICANTS`;
export const FETCH_ALL_APPLICANTS_SUCCESS = `${FETCH_ALL_APPLICANTS}_SUCCESS`;
export const FETCH_ALL_APPLICANTS_ERROR = `${FETCH_ALL_APPLICANTS}_ERROR`;

export const FETCH_ALL_EVENTS = `${namespace}/FETCH_ALL_EVENTS`;
export const FETCH_ALL_EVENTS_SUCCESS = `${FETCH_ALL_EVENTS}_SUCCESS`;
export const FETCH_ALL_EVENTS_ERROR = `${FETCH_ALL_EVENTS}_ERROR`;

export const ADD_COMMENT = `${namespace}/ADD_COMMENT`;
export const ADD_COMMENT_SUCCESS = `${ADD_COMMENT}_SUCCESS`;
export const ADD_COMMENT_ERROR = `${ADD_COMMENT}_ERROR`;

export const DELETE_COMMENT = `${namespace}/DELETE_COMMENT`;
export const DELETE_COMMENT_SUCCESS = `${DELETE_COMMENT}_SUCCESS`;
export const DELETE_COMMENT_ERROR = `${DELETE_COMMENT}_ERROR`;

export const UPDATE_TICKET = `${namespace}/UPDATE_TICKET`;
export const UPDATE_TICKET_SUCCESS = `${UPDATE_TICKET}_SUCCESS`;
export const UPDATE_TICKET_ERROR = `${UPDATE_TICKET}_ERROR`;

export const ADD_TAG = `${namespace}/ADD_TAG`;
export const ADD_TAG_SUCCESS = `${ADD_TAG}_SUCCESS`;
export const ADD_TAG_ERROR = `${ADD_TAG}_ERROR`;

export const REMOVE_TAG = `${namespace}/REMOVE_TAG`;
export const REMOVE_TAG_SUCCESS = `${REMOVE_TAG}_SUCCESS`;
export const REMOVE_TAG_ERROR = `${REMOVE_TAG}_ERROR`;

export const SAVE_RESPONSE = `${namespace}/SAVE_RESPONSE`;
export const SAVE_RESPONSE_SUCCESS = `${SAVE_RESPONSE}_SUCCESS`;
export const SAVE_RESPONSE_ERROR = `${SAVE_RESPONSE}_ERROR`;

export const EDIT_RESPONSE = `${namespace}/EDIT_RESPONSE`;
export const EDIT_RESPONSE_SUCCESS = `${EDIT_RESPONSE}_SUCCESS`;
export const EDIT_RESPONSE_ERROR = `${EDIT_RESPONSE}_ERROR`;

export const SAVE_CHANGES = `${namespace}/SAVE_CHANGES`;
export const SAVE_CHANGES_SUCCESS = `${SAVE_CHANGES}_SUCCESS`;
export const SAVE_CHANGES_ERROR = `${SAVE_CHANGES}_ERROR`;

export default {
    ADD_COMMENT,
    ADD_COMMENT_ERROR,
    ADD_COMMENT_SUCCESS,
    ADD_TAG,
    ADD_TAG_ERROR,
    ADD_TAG_SUCCESS,
    DELETE_COMMENT,
    DELETE_COMMENT_ERROR,
    DELETE_COMMENT_SUCCESS,
    EDIT_RESPONSE,
    EDIT_RESPONSE_ERROR,
    EDIT_RESPONSE_SUCCESS,
    FETCH_ALL_APPLICANTS,
    FETCH_ALL_APPLICANTS_ERROR,
    FETCH_ALL_APPLICANTS_SUCCESS,
    FETCH_ALL_EVENTS,
    FETCH_ALL_EVENTS_ERROR,
    FETCH_ALL_EVENTS_SUCCESS,
    LOAD_TICKET,
    LOAD_TICKET_ERROR,
    LOAD_TICKET_SUCCESS,
    REGISTER_FORM,
    REMOVE_TAG,
    REMOVE_TAG_ERROR,
    REMOVE_TAG_SUCCESS,
    SAVE_CHANGES,
    SAVE_CHANGES_ERROR,
    SAVE_CHANGES_SUCCESS,
    SAVE_RESPONSE,
    SAVE_RESPONSE_ERROR,
    SAVE_RESPONSE_SUCCESS,
    TOGGLE_LOCATION_ENABLED,
    UNREGISTER_FORM,
    UPDATE_TICKET,
    UPDATE_TICKET_ERROR,
    UPDATE_TICKET_SUCCESS,
};
