// @flow
const namespace = 'g2/api';

export const UPDATE_PROFILE = `${namespace}/UPDATE_PROFILE`;
export const UPDATE_PROFILE_SUCCESS = `${UPDATE_PROFILE}_SUCCESS`;
export const UPDATE_PROFILE_ERROR = `${UPDATE_PROFILE}_ERROR`;

export const UPDATE_USER = `${namespace}/UPDATE_USER`;
export const UPDATE_USER_SUCCESS = `${UPDATE_USER}_SUCCESS`;
export const UPDATE_USER_ERROR = `${UPDATE_USER}_ERROR`;

export default {
    UPDATE_PROFILE,
    UPDATE_PROFILE_ERROR,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_USER,
    UPDATE_USER_ERROR,
    UPDATE_USER_SUCCESS,
};
