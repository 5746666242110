// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { CircularProgress, Fade, FilledInput, Select } from '@material-ui/core';
import {
    Check as CheckIcon,
    Clear as ClearIcon,
} from '@material-ui/icons';
import type { Node } from 'react';
import styles from './styles';

type Props = {
    children: Node,
    onChange: (event: SyntheticEvent<any>, child: React$Element<any>) => Promise<void>,
    readOnly?: boolean,
    value?: string,
};

const useStyles = makeStyles(styles, { name: 'RoleSelect' });

const RoleSelect = (props: Props) => {
    const {
        children,
        onChange,
        readOnly,
        value: initialValue,
        ...other
    } = props;

    const classes = useStyles(props);
    const { t } = useTranslation();

    const [submitting, setSubmitting] = useState(false);
    const [status, setStatus] = useState(null);
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const handleChange = useCallback((event: SyntheticEvent<any>, child: React$Element<any>) => {
        const promise = onChange(event, child);

        if (promise instanceof Promise) {
            const prevValue = value;
            // $FlowFixMe material-ui changes the target to be a plain JS object
            const newValue = event.target.value;

            setValue(newValue);
            setSubmitting(true);
            setStatus(null);

            promise
                .then(() => {
                    setSubmitting(false);
                    setStatus({ success: true });
                })
                .catch((error) => {
                    setValue(prevValue);
                    setSubmitting(false);
                    setStatus({ error });
                })
                .then(() => {
                    setTimeout(() => setStatus(null), 1500);
                });
        }
    }, [onChange, value]);

    return (
        <Select
          {...other}
          onChange={handleChange}
          value={value}
          readOnly={submitting || readOnly}
          input={<FilledInput classes={{ input: classes.filledInput }} />}
          renderValue={(selectValue: any) => (
              <div className={classes.selectValue}>
                  <span>{t(`customerList.roleEnum.${selectValue}`)}</span>
                  {submitting
                      ? <CircularProgress className={classes.progress} size={18} thickness={4} />
                      : (
                          <Fade in={status != null} timeout={{ exit: 250 }}>
                              {status && status.success
                                  ? <CheckIcon className={classes.successIcon} />
                                  : <ClearIcon className={classes.errorIcon} />}
                          </Fade>
                      )}
              </div>
          )}
        >
            {children}
        </Select>
    );
};

RoleSelect.defaultProps = {
    onChange: () => {},
};

export default RoleSelect;
