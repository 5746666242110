var Handlebars = require('/tmp/build_a9d70681abb25b82ca4489607bd6ccee/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"sub-target\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"for_target") || (depth0 != null ? lookupProperty(depth0,"for_target") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"for_target","hash":{},"data":data,"loc":{"start":{"line":5,"column":98},"end":{"line":5,"column":114}}}) : helper)))
    + ": "
    + alias4(((helper = (helper = lookupProperty(helpers,"targetName") || (depth0 != null ? lookupProperty(depth0,"targetName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"targetName","hash":{},"data":data,"loc":{"start":{"line":5,"column":116},"end":{"line":5,"column":132}}}) : helper)))
    + "</span><br>";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"answer\">\n        <label class=\"plainLabel\" data-choice=\""
    + alias2(alias1(depth0, depth0))
    + "\" data-id=\""
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"id") : depths[1]), depth0))
    + "\" data-targetid=\""
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"targetID") : depths[1]), depth0))
    + "\">\n            <input type=\""
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"type") : depths[1]), depth0))
    + "\" data-choice=\""
    + alias2(alias1(depth0, depth0))
    + "\" data-id=\""
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"id") : depths[1]), depth0))
    + "\" data-input data-targetid=\""
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"targetID") : depths[1]), depth0))
    + "\" value=\""
    + alias2(alias1(depth0, depth0))
    + "\" name=\""
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"uniqueID") : depths[1]), depth0))
    + "\">\n            "
    + alias2(alias1(depth0, depth0))
    + "\n        </label>\n      </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- question type is logic choices (Item Grid) -->\n<div class=\"type item-grid\" data-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":2,"column":37},"end":{"line":2,"column":45}}}) : helper)))
    + "\" data-targetid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"targetID") || (depth0 != null ? lookupProperty(depth0,"targetID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"targetID","hash":{},"data":data,"loc":{"start":{"line":2,"column":62},"end":{"line":2,"column":76}}}) : helper)))
    + "\">\n  <div class=\"answers\">\n    <div class=\"answer exception\">\n      <div class=\"description\" data-target=\"question\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"targetName") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":54},"end":{"line":5,"column":151}}})) != null ? stack1 : "")
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"question_text") || (depth0 != null ? lookupProperty(depth0,"question_text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"question_text","hash":{},"data":data,"loc":{"start":{"line":5,"column":151},"end":{"line":5,"column":172}}}) : helper))) != null ? stack1 : "")
    + "</div>\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"choices") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":14,"column":14}}})) != null ? stack1 : "")
    + "  </div>\n</div><!-- END Item Grid -->\n";
},"useData":true,"useDepths":true});