// @flow
import { Component } from 'react';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import type { ContextRouter } from 'react-router';
import type { Connector } from 'react-redux';
import * as ui from '../../ducks/ui';
import type { State as RootState } from '../../redux/initialState';

type OwnProps = { ...ContextRouter };

type StateProps = {
    backboneActive: boolean,
};

type DispatchProps = {
    toggleBackbone: (active: boolean) => void,
};

type Props = OwnProps & StateProps & DispatchProps;

export class NoMatch extends Component<Props> {
    componentDidMount() {
        const { backboneActive, toggleBackbone } = this.props;
        if (!backboneActive) {
            toggleBackbone(true);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        const { backboneActive, toggleBackbone } = nextProps;
        if (!backboneActive) {
            toggleBackbone(true);
        }
    }

    render() {
        return null;
    }
}

const mapStateToProps = (state: RootState) => ({
    backboneActive: state.ui.backboneActive,
});

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    toggleBackbone: (active: boolean) => dispatch(ui.actions.toggleBackbone(active)),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
export default connector(NoMatch);
