// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import stringify from 'json-stable-stringify';
import uniqueId from 'lodash/uniqueId';
// $FlowIssue update to more recent flow version and un-ignore react-select module
import Select from 'react-select/lib/index';
import { makeStyles } from '@material-ui/styles';
import { CircularProgress } from '@material-ui/core';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@material-ui/icons';
import type { Node } from 'react';
import Control from './Control';
import Input from './Input';
import Menu from './Menu';
import MultiValue from './MultiValue';
import Option from './Option';
import Placeholder from './Placeholder';
import SingleValue from './SingleValue';
import ValueContainer from './ValueContainer';
import styles from './styles';

type Props = {
    cacheOptions?: any,
    classes?: Object,
    className?: string,
    components?: Object,
    defaultOptions?: Object[] | boolean,
    disableSelectedOptions?: boolean,
    dropdownIcon?: Node,
    formatOptionLabel?: (option: Object, meta: Object) => Node,
    getOptionValue: (option: Object) => any,
    isLoading?: boolean,
    loadOptions?: (inputValue: string) => Promise<Object[]>,
    loadingIcon?: Node,
    menuType?: 'list' | 'menu',
    onBlur: () => void,
    options?: Object[],
    placeholder?: string,
    showSelectedOptions?: boolean | 'top',
    value?: ?(Object | Object[]),
};

// const NoOptionsMessage = (props: Object) => {
//     const { children, innerProps, selectProps } = props;
//     const { classes } = selectProps;
//     return (
//         <Typography color="textSecondary" className={classes.noOptionsMessage} {...innerProps}>
//             {children}
//         </Typography>
//     );
// };

// const MenuList = (props: Object) => {
//     const { children, innerProps, innerRef } = props;
//     return (
//         <div {...innerProps} ref={innerRef}>
//             <MenuList>
//                 {children}
//             </MenuList>
//         </div>
//     );
// };

const getOptionValue = (option: Object) => {
    let { value } = option;
    if (typeof value === 'object') {
        value = value.hasOwnProperty('id') ? value.id : value;
    }
    return stringify(value);
};

const defaultComponents = {
    Control,
    Input,
    Menu,
    MultiValue,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
};

const useStyles = makeStyles(styles, { name: 'SelectController' });

function SelectController(props: Props) {
    const {
        cacheOptions,
        className,
        components,
        defaultOptions: defaultOptionsProp,
        disableSelectedOptions,
        formatOptionLabel: formatOptionLabelProp,
        getOptionValue: getOptionValueProp,
        isLoading,
        loadOptions,
        menuType,
        options: optionsProp,
        placeholder,
        onBlur,
        showSelectedOptions,
        value,
        ...other
    } = props;

    const classes = useStyles(props);

    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadedOptions, setLoadedOptions] = useState([]);
    const [defaultOptions, setDefaultOptions] = useState(Array.isArray(defaultOptionsProp) ? defaultOptionsProp : []);

    const selectRef = useRef(null);
    const lastRequestRef = useRef(null);

    const isAsync = typeof loadOptions === 'function';
    const showDefaultOptions = inputValue === '';

    useEffect(() => {
        if (defaultOptionsProp === true && loadOptions) {
            setLoading(true);
            loadOptions('')
                .catch(() => {})
                .then((results: Object[] = []) => {
                    setDefaultOptions(results);
                    setLoading(false);
                });
        } else {
            setDefaultOptions(Array.isArray(defaultOptionsProp) ? defaultOptionsProp : []);
        }
    }, [defaultOptionsProp, loadOptions]);

    const mergeSelectedOptions = useCallback((opts: Object[], selectValue: ?(Object | Object[])): Object[] => {
        if (!selectValue) {
            return opts;
        }

        const val = Array.isArray(selectValue) ? selectValue : [selectValue];

        return [
            ...val.filter((option) => {
                const candidate = getOptionValueProp(option);
                return !opts.some((v) => getOptionValueProp(v) === candidate);
            }),
            ...opts,
        ];
    }, [getOptionValueProp]);

    const reorderSelectedOptions = useCallback((opts: Object[], selectValue: ?(Object | Object[])) => {
        if (!selectValue) {
            return opts;
        }

        const top = [];
        const bottom = [];
        const val = Array.isArray(selectValue) ? selectValue : [selectValue];

        opts.forEach((option) => {
            const candidate = getOptionValueProp(option);
            const isOptionSelected = val.some((v) => getOptionValueProp(v) === candidate);
            if (isOptionSelected) {
                top.push(option);
            } else {
                bottom.push(option);
            }
        });

        return [...top, ...bottom];
    }, [getOptionValueProp]);

    const options = useMemo(() => {
        let selectOptions = optionsProp || [];

        if (isAsync) {
            selectOptions = showDefaultOptions ? defaultOptions : loadedOptions;
        }

        switch (showSelectedOptions) {
            case 'top':
                selectOptions = mergeSelectedOptions(selectOptions, value);
                return reorderSelectedOptions(selectOptions, value);
            case true:
                return mergeSelectedOptions(selectOptions, value);
            default:
                return selectOptions;
        }
    }, [defaultOptions, isAsync, loadedOptions, mergeSelectedOptions, optionsProp, reorderSelectedOptions, showDefaultOptions, showSelectedOptions, value]);

    const formatOptionLabel = useCallback((option: Object, meta: Object) => {
        if (formatOptionLabelProp) {
            // This is a little hacky, but it's currently the only way to expose isSelected to formatOptionLabel
            const node = selectRef.current;
            const select = node ? node.select : null;

            // @todo Submit a feature request to react-select to expose option props to formatOptionLabel
            const isSelected = select ? select.isOptionSelected(option, meta.selectValue) : false;
            return formatOptionLabelProp(option, { ...meta, isSelected });
        }
    }, [formatOptionLabelProp]);

    const handleBlur = useCallback(() => onBlur(), [onBlur]);

    const handleInputChange = useCallback((newValue: string) => {
        setInputValue(newValue);

        if (loadOptions && newValue !== '') {
            const request = uniqueId('request_');
            lastRequestRef.current = request;

            setLoading(true);
            loadOptions(newValue)
                .catch(() => {})
                .then((results: Object[] = []) => {
                    if (request !== lastRequestRef.current) {
                        return;
                    }

                    lastRequestRef.current = null;
                    setLoadedOptions(results);
                    setLoading(false);
                });
        }
    }, [loadOptions]);

    const handleKeyDown = useCallback((event: SyntheticKeyboardEvent<any>) => {
        const node = selectRef.current;
        const select = node ? node.select : null;

        if (select) {
            const { focusedOption, selectValue } = select.state;
            if (disableSelectedOptions && focusedOption && select.isOptionSelected(focusedOption, selectValue)) {
                switch (event.key) {
                    case 'Tab':
                    case 'Enter':
                    case ' ':
                        event.preventDefault();
                        break;

                    default:
                        break;
                }
            }
        }
    }, [disableSelectedOptions]);

    return (
        <Select
          {...other}
          {...(isAsync ? { filterOption: null } : undefined)}
          classes={classes}
          className={cx(classes.root, { [classes.fullHeight]: menuType === 'list' }, className)}
          components={{
              ...defaultComponents,
              ...components,
              ClearIndicator: null,
              DropdownIndicator: null,
              IndicatorSeparator: null,
              LoadingIndicator: null,
          }}
          disableSelectedOptions={disableSelectedOptions}
          formatOptionLabel={formatOptionLabelProp ? formatOptionLabel : undefined}
          getOptionValue={getOptionValueProp}
          hideSelectedOptions={!showSelectedOptions}
          inputValue={inputValue}
          isClearable={false}
          isLoading={isLoading != null ? isLoading : loading}
          menuType={menuType}
          onBlur={handleBlur}
          onInputChange={handleInputChange}
          onKeyDown={handleKeyDown}
          options={options}
          placeholder={placeholder || ''}
          ref={selectRef}
          value={value}
        />
    );
}

SelectController.defaultProps = {
    components: defaultComponents,
    dropdownIcon: <KeyboardArrowDownIcon />,
    getOptionValue,
    loadingIcon: <CircularProgress size={18} thickness={4.4} />,
    menuType: 'menu',
    onBlur: () => {},
    showSelectedOptions: true,
};

export default SelectController;
