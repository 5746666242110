// @flow
// $FlowIssue need to update to a more recent flow version
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Form, FieldArray } from 'formik';
// $FlowTypedIssue update react-redux libdef
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Badge, Button, Fab } from '@material-ui/core';
import {
    AddAPhoto as AddAPhotoIcon,
    Close as CloseIcon,
    PhotoCamera as PhotoCameraIcon,
} from '@material-ui/icons';
import getThumbnailUrl from '../../../../utils/getThumbnailUrl';
import { dataUriToBlob } from '../../../../utils/FileUtil';
import { getTicket, registerForm, unregisterForm } from '../../../../ducks/ticketDetail';
import Lightbox from '../../../../components/Lightbox';
import ImageEditor from '../../../../components/ImageEditor';
import DeviceTooltip from '../DeviceTooltip';
import LocationTooltip from '../LocationTooltip';
import UploadFileDialog from '../UploadFileDialog';
import styles from './styles';
import type { State as RootState } from '../../../../store';

type Props = {
    dataItem: ?Object,
    dirty: boolean,
    disabled?: boolean,
    expectedValueExpression: ?Object,
    id: string,
    onChange: (values: Object) => void,
    questionText: string,
    setFieldValue: (field: string, value: any) => void,
    submitForm: () => Promise<any>,
    touched: Object,
    values: Object,
};

const maxFileSize = 15 * 1024 * 1024; // 15 MB (15728640 bytes)

const useStyles = makeStyles(styles, { name: 'Photo' });

export default function Photo(props: Props) {
    const { dataItem, dirty, disabled, id, onChange, questionText, setFieldValue, submitForm, touched, values } = props;
    const { response } = values;
    const dataItemValue = dataItem ? dataItem.data_item_value : [];

    const dispatch = useDispatch();
    const classes = useStyles(props);

    const ticket = useSelector((state: RootState) => getTicket(state));

    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
    const [slideIndex, setSlideIndex] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [versions, setVersions] = useState({});
    const [timestamp] = useState(Date.now());

    const valuesRef = useRef(null);
    const submitFormRef = useRef(null);

    valuesRef.current = values;
    submitFormRef.current = () => {
        if (Object.keys(touched).length > 0 || dirty) {
            return submitForm();
        }
    };

    const handleLightboxClose = useCallback(() => {
        setSlideIndex(null);
    }, []);

    const handleEditorClose = useCallback(() => {
        setEditMode(false);
    }, []);

    const handleUploadDialogClose = useCallback(() => {
        setUploadDialogOpen(false);
    }, []);

    const handleEditClick = useCallback(() => {
        setEditMode(true);
    }, []);

    const handleAddPhotoClick = useCallback(() => {
        setUploadDialogOpen(true);
    }, []);

    const handlePhotoClick = useCallback((event: SyntheticEvent<HTMLElement>) => {
        const index = parseInt(event.currentTarget.dataset.index, 10);
        setSlideIndex(index);
    }, []);

    const handleLightboxChange = useCallback((index: number) => {
        setSlideIndex(index);
    }, []);

    const handleSaveImage = useCallback((dataURL: string) => {
        if (slideIndex == null) {
            return;
        }

        const originalSrc = dataItemValue[slideIndex].photo_url;
        const blob = dataUriToBlob(dataURL, 'image/jpeg');
        const formData = new FormData();
        formData.append('url', originalSrc);
        formData.append('file', blob);

        return axios.put('/upload', formData)
            .then(() => {
                setEditMode(false);
                setVersions({
                    ...versions,
                    [originalSrc]: (versions[originalSrc] || 0) + 1,
                });
            });
    }, [dataItemValue, slideIndex, versions]);

    const handleAddPhotos = useCallback((event: SyntheticEvent<any>, uploads: Object[]) => {
        const newPhotos = uploads.map((upload: Object) => ({
            photo_url: upload.url,
        }));

        setFieldValue('response', [...response, ...newPhotos]);
        setUploadDialogOpen(false);

        setTimeout(() => {
            onChange(valuesRef.current);
        }, 0);
    }, [onChange, response, setFieldValue]);

    useEffect(() => {
        const helpers = {
            submitForm() {
                return submitFormRef.current();
            },
        };
        dispatch(registerForm(id, helpers));

        return () => {
            dispatch(unregisterForm(id));
        };
    }, [id, dispatch]);

    const images = [];

    dataItemValue.forEach((photo: Object) => {
        const version = versions[photo.photo_url] || 0;
        const thumbnail = getThumbnailUrl(photo.photo_url);

        images.push({
            src: `${photo.photo_url}?t=${timestamp}&v=${version}`,
            thumbnail: thumbnail ? `${thumbnail}?t=${timestamp}&v=${version}` : null,
            title: '', // question title/description
            description: questionText,
        });
    });

    return (
        <Form className={classes.root} name={id}>
            <div className={classes.question}>
                <div dangerouslySetInnerHTML={{ __html: questionText }} />
                <FieldArray name="response">
                    {(arrayHelpers) => (
                        <div className={classes.gridList}>
                            {response.map((photo: Object, index: number) => {
                                const version = versions[photo.photo_url] || 0;
                                const thumbnail = getThumbnailUrl(photo.photo_url);

                                const handleRemovePhoto = () => {
                                    arrayHelpers.remove(index);
                                    setTimeout(() => {
                                        onChange(valuesRef.current);
                                    }, 0);
                                };

                                // Note: the onClick handler must be attached to the img element so that clicking the
                                // empty space between tiles does not trigger the Lightbox to open
                                /* eslint-disable jsx-a11y/alt-text, jsx-a11y/no-noninteractive-element-interactions */
                                return (
                                    <Badge
                                      badgeContent={(
                                          <Fab
                                            className={classes.removeButton}
                                            data-index={index}
                                            onClick={handleRemovePhoto}
                                          >
                                              <CloseIcon fontSize="inherit" />
                                          </Fab>
                                      )}
                                      classes={{ badge: classes.badge }}
                                      invisible={disabled}
                                      key={index}
                                    >
                                        <div className={classes.image}>
                                            <img
                                              data-index={index}
                                              style={{ maxWidth: 'unset', cursor: 'pointer' }}
                                              src={thumbnail ? `${thumbnail}?t=${timestamp}&v=${version}` : null}
                                              onClick={handlePhotoClick}
                                            />
                                        </div>
                                    </Badge>
                                );
                                /* eslint-enable jsx-a11y/alt-text, jsx-a11y/no-noninteractive-element-interactions */
                            })}
                            <Button
                              className={classes.button}
                              color="primary"
                              disabled={disabled}
                              fullWidth
                              onClick={handleAddPhotoClick}
                              variant="outlined"
                            >
                                <AddAPhotoIcon />
                            </Button>
                        </div>
                    )}
                </FieldArray>
            </div>
            <div className={classes.metadata}>
                <PhotoCameraIcon color="action" fontSize="small" />
                {ticket && dataItem
                    ? (
                        <Fragment>
                            <DeviceTooltip dataItem={dataItem} />
                            <LocationTooltip dataItem={dataItem} gigLocation={ticket.location} />
                        </Fragment>
                    )
                    : null}
            </div>
            <Lightbox
              images={images}
              currentImage={slideIndex || 0}
              onClose={handleLightboxClose}
              onEditClick={!disabled ? handleEditClick : null}
              onChange={handleLightboxChange}
              open={slideIndex != null}
            />
            <ImageEditor
              imageUrl={slideIndex != null ? images[slideIndex].src : ''}
              onSave={handleSaveImage}
              onClose={handleEditorClose}
              open={editMode}
            />
            <UploadFileDialog
              fullWidth
              maxSize={maxFileSize}
              open={uploadDialogOpen}
              onClose={handleUploadDialogClose}
              onConfirm={handleAddPhotos}
            />
        </Form>
    );
}
