// @flow
import { handleActions } from 'redux-actions';
import type { ActionType } from 'redux-actions';
import types from './types';
import typeof { findPlaceFromQuery, geocodeSuccess, getPlaceDetailsSuccess } from './actions';

export type State = {
    places: Object,
}

export const init: State = {
    places: {},
};

export default handleActions({
    [types.FIND_PLACE_FROM_QUERY_SUCCESS]: (state: State, action: ActionType<findPlaceFromQuery>) => ({
        ...state,
        places: {
            ...state.places,
            ...action.payload.reduce((acc, place) => ({ ...acc, [place.place_id]: place }), {}),
        },
    }),
    [types.GEOCODE_SUCCESS]: (state: State, action: ActionType<geocodeSuccess>) => ({
        ...state,
        places: {
            ...state.places,
            ...action.payload.reduce((acc, result) => ({ ...acc, [result.place_id]: result }), {}),
        },
    }),
    [types.GET_PLACE_DETAILS_SUCCESS]: (state: State, action: ActionType<getPlaceDetailsSuccess>) => ({
        ...state,
        places: {
            ...state.places,
            [action.payload.place_id]: action.payload,
        },
    }),
}, init);
