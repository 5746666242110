// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback, useRef, useState } from 'react';
import cx from 'classnames';
import mime from 'mime';
import bytes from 'bytes';
import uuidv4 from 'uuid/v4';
import axios from 'axios';
import { fromEvent } from 'file-selector';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Badge, Fab, Tooltip, Typography } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import type { $AxiosXHR } from 'axios';
import { createBlobFromFile } from '../../../browser/shared/util/FileUtil';
import Preview from './Preview';
import styles from './styles';

type Upload = {
    error?: string,
    file: File,
    id: string,
    progress: number,
    status: 'complete' | 'failed' | 'pending',
}

// The structure of this object depends on the AmazonS3 API response and how
// xml2js.parseString parses the response text
type AmazonS3Error = {
    Error: {
        Code: [string],
    },
};

type Props = {
    accept?: string | string[],
    bucket?: 'default' | 'locationList' | 'locationListUpdate' | 'customer',
    className?: string,
    maxSize?: number,
    minSize?: number,
    multiple?: boolean,
    onDrop: (acceptedFiles: File[], rejectedFiles: File[], event: SyntheticInputEvent<any> | SyntheticDragEvent<any>) => void,
    onFileRemoved: (file: File) => void,
    onUploadError: (upload: Upload, err: string | AmazonS3Error) => void,
    onUploadQueued: (upload: Upload) => void,
    onUploadSuccess: (upload: Upload) => void,
};

const stopPropagation = (event: SyntheticEvent<any>) => {
    event.stopPropagation();
    event.preventDefault();
};

const useStyles = makeStyles(styles, { name: 'Dropzone' });

export function Dropzone(props: Props): React$Element<any> {
    const {
        accept,
        bucket,
        className,
        maxSize,
        minSize,
        multiple,
        onUploadError,
        onUploadQueued,
        onUploadSuccess,
        onDrop,
        onFileRemoved,
    } = props;

    const options = { accept, maxSize, minSize, multiple };

    const classes = useStyles(props);
    const { t } = useTranslation();

    // We need to maintain a reference to all uploads initiated by the user so that we can
    // update the progress/status of each as time passes. This is not possible with useState
    // since any async code initialized in the onDrop callback will reference the old instance.
    const [updateFlag, setUpdateFlag] = useState(0); // eslint-disable-line no-unused-vars
    const uploadsRef = useRef([]);

    // Similar to the issue described above, we need to keep a reference to the current
    // onUploadError and onUploadSuccess callbacks. These are unlikely to change, but in case
    // they do, async code initialized inside onDrop will always reference the current value
    // @todo formik implements a useEventCallback hook which I believe solves this problem
    const onUploadErrorRef = useRef(null);
    const onUploadQueuedRef = useRef(null);
    const onUploadSuccessRef = useRef(null);
    onUploadErrorRef.current = onUploadError;
    onUploadQueuedRef.current = onUploadQueued;
    onUploadSuccessRef.current = onUploadSuccess;

    const handleRemoveClick = useCallback((event: SyntheticEvent<any>) => {
        const index = parseInt(event.currentTarget.dataset.index, 10);
        const uploads = uploadsRef.current;
        const upload = uploads.splice(index, 1)[0];

        onFileRemoved(upload.file);
        setUpdateFlag(Math.random());
    }, [onFileRemoved]);

    const handleDrop = useCallback(async (acceptedFiles: File[], rejectedFiles: File[], event: SyntheticInputEvent<any> | SyntheticDragEvent<any>) => {
        const files = await fromEvent(event);
        const uploads = uploadsRef.current;

        onDrop(acceptedFiles, rejectedFiles, event);

        files.forEach((file: File) => {
            const upload = {};
            const mimeType = file.type || mime.getType(file.name);
            upload.id = uuidv4();
            upload.progress = 0;
            upload.status = 'pending';

            // $FlowFixMe return type is a Blob, although it functionally equivalent to a File
            upload.file = createBlobFromFile(file, mimeType);

            Promise.resolve()
                .then(() => {
                    const onUploadQueuedCb = onUploadQueuedRef.current;
                    onUploadQueuedCb({ ...upload });
                });

            if (acceptedFiles.includes(file)) {
                const formData = new FormData();
                formData.append('file', file);

                if (bucket != null) {
                    formData.append('bucket', bucket);
                }

                const onUploadProgress = (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    upload.progress = Math.round((loaded / total) * 10000) / 100;
                    setUpdateFlag(Math.random());
                };

                // $FlowIssue flow does not seem to be aware of Promise.prototype.finally()
                axios.post('/upload', formData, { onUploadProgress })
                    .then((resp: $AxiosXHR<Object>) => {
                        const { key, url } = resp.data;
                        upload.status = 'complete';
                        upload.url = url;
                        upload.key = key;

                        const onUploadSuccessCb = onUploadSuccessRef.current;
                        onUploadSuccessCb({ ...upload });
                    })
                    .catch((err) => {
                        upload.status = 'failed';
                        upload.error = 'internalServerError';

                        const onUploadErrorCb = onUploadErrorRef.current;
                        onUploadErrorCb({ ...upload }, err);
                    })
                    .finally(() => {
                        setUpdateFlag(Math.random());
                    });
            } else if (rejectedFiles.includes(file)) {
                upload.status = 'failed';

                switch (true) {
                    case minSize != null && file.size < minSize:
                        upload.error = 'fileTooSmall';
                        break;
                    case maxSize != null && file.size > maxSize:
                        upload.error = 'fileTooBig';
                        break;
                    case !multiple && acceptedFiles.length > 1:
                        upload.error = 'maxFilesExceeded';
                        break;
                    default:
                        upload.error = 'invalidFileType';
                        break;
                }

                Promise.resolve()
                    .then(() => {
                        const onUploadErrorCb = onUploadErrorRef.current;
                        onUploadErrorCb({ ...upload }, upload.error);
                    });
            }

            uploads.push(upload);
        });

        setUpdateFlag(Math.random());
    }, [bucket, maxSize, minSize, multiple, onDrop]);

    const { getRootProps, getInputProps } = useDropzone({ ...options, onDrop: handleDrop });

    return (
        <div {...getRootProps()} className={cx(classes.root, className)}>
            <input {...getInputProps()} />
            {uploadsRef.current.length > 0
                ? (
                    <div className={classes.fileList}>
                        {uploadsRef.current.map((upload: Upload, index: number, uploads: Object[]) => {
                            const { error, file, id } = upload;
                            const tOptions = {
                                minSize: bytes(minSize),
                                maxSize: bytes(maxSize),
                                size: bytes(file.size),
                            };

                            return (
                                <div key={id} onClick={stopPropagation}>
                                    <Tooltip
                                      classes={{ tooltip: classes.tooltip }}
                                      disableFocusListener={!error}
                                      disableHoverListener={!error}
                                      disableTouchListener={!error}
                                      title={error ? t(`dropzone.${error}`, tOptions) : ''}
                                      open={error ? uploads.length : false}
                                    >
                                        <Badge
                                          badgeContent={(
                                              <Fab
                                                className={classes.removeButton}
                                                data-index={index}
                                                onClick={handleRemoveClick}
                                              >
                                                  <CloseIcon fontSize="inherit" />
                                              </Fab>
                                          )}
                                          classes={{ badge: classes.badge }}
                                        >
                                            <Preview {...upload} />
                                        </Badge>
                                    </Tooltip>
                                </div>
                            );
                        })}
                    </div>
                )
                : (
                    <Typography variant="body1">
                        {t('dropzone.defaultMessage')}
                    </Typography>
                )}
        </div>
    );
}

Dropzone.defaultProps = {
    onDrop: () => {},
    onFileRemoved: () => {},
    onUploadError: () => {},
    onUploadQueued: () => {},
    onUploadSuccess: () => {},
};

export default Dropzone;
