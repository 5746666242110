// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback } from 'react';
import qs from 'qs';
import stringify from 'json-stable-stringify';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import {
    Chip,
    Drawer,
    FormControl,
    FormLabel,
    IconButton,
    Toolbar,
    Tooltip,
} from '@material-ui/core';
import {
    Close as CloseIcon,
    ClearAll as ClearAllIcon,
    Search as SearchIcon,
} from '@material-ui/icons';
import type { Dispatch } from 'redux';
import type { Connector } from 'react-redux';
import type { ContextRouter } from 'react-router';
import { USER_ROLES } from '../../../../../browser/shared/constant/UserRoles';
import { search as searchOrganizations } from '../../../../redux/entities/organizations';
import Select from '../../../../components/Select';
import { actions, selectors } from './duck';
import styles from './styles';
import type { State as RootState } from '../../../../redux/initialState';

type OwnProps = ContextRouter & {};
type StateProps = {
    open: boolean,
    organizationFilterValue: Object[],
    search: {
        organization?: string[],
    },
    user: ?Object,
};
type DispatchProps = {
    searchOrganizations: (params: Object) => Promise<Object>,
    toggleFilterDrawer: (open: boolean) => void,
};
type Props = OwnProps & StateProps & DispatchProps;

// @todo Move to a utils folder
const getOptionValue = (option: Object): string => {
    let { value } = option;
    if (typeof value === 'object') {
        value = value.hasOwnProperty('id') ? value.id : value;
        value = value.hasOwnProperty('place_id') ? value.place_id : value;
    }
    return typeof value === 'string' ? value : stringify(value);
};

const noFilters = {
    organization: undefined,
};

const useStyles = makeStyles(styles, { name: 'FilterDrawer' });

export function FilterDrawer(props: Props) {
    const {
        history,
        location,
        organizationFilterValue,
        open,
        search,
        searchOrganizations: _searchOrganizations,
        toggleFilterDrawer,
        user,
    } = props;

    const classes = useStyles(props);
    const { t } = useTranslation();

    const loadOrganizationOptions = useCallback((inputValue: string) => {
        const params = {
            q: inputValue ? `*${inputValue}*` : null,
            from: 0,
            size: 20,
            sort_field: 'organization_name',
            sort_order: 'asc',
        };

        return _searchOrganizations(params)
            .then((resp) => {
                const { entities, result } = resp;
                // @todo: Consider using denormalize to get denormalized entity
                return result.map((id: number) => ({
                    label: entities.organizations[id].organization_name,
                    value: entities.organizations[id],
                }));
            });
    }, [_searchOrganizations]);

    const updateFilters = useCallback((filters: Object) => {
        history.replace({
            pathname: location.pathname,
            search: qs.stringify(
                { ...search, ...filters },
                { addQueryPrefix: true, encodeValuesOnly: true },
            ),
        });
    }, [history, location.pathname, search]);

    const handleClear = useCallback(() => {
        updateFilters(noFilters);
    }, [updateFilters]);

    const handleOrganizationFilterChange = useCallback((value: Object[]) => {
        const organizationFilters = value.map((option: Object) => option.value.id);
        updateFilters({ organization: organizationFilters });
    }, [updateFilters]);

    const handleChipDelete = useCallback((event: SyntheticEvent<any>) => {
        const { name, value } = event.currentTarget.parentElement.dataset;
        let filterValue;

        switch (name) {
            case 'organization':
                filterValue = organizationFilterValue;
                break;
            default:
                return;
        }

        const index = filterValue.findIndex((option: Object) => (
            getOptionValue(option) === value
        ));

        if (index >= 0) {
            const filter = search[name].filter((el, i) => i !== index);
            updateFilters({ [name]: filter });
        }
    }, [organizationFilterValue, search, updateFilters]);

    const handleDrawerClose = useCallback(() => {
        toggleFilterDrawer(false);
    }, [toggleFilterDrawer]);

    return (
        <Drawer
          anchor="right"
          classes={{ paper: classes.paper }}
          onClose={handleDrawerClose}
          open={open}
        >
            <Toolbar>
                <div className={classes.title}>
                    {t('subscriptionList.filters.filterProjects')}
                </div>
                <div className={classes.actions}>
                    <Tooltip title={t('subscriptionList.filters.clearFilters')}>
                        <IconButton onClick={handleClear}>
                            <ClearAllIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('subscriptionList.filters.close')}>
                        <IconButton onClick={handleDrawerClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </div>
            </Toolbar>
            <div className={classes.filters}>
                {user && user.role === USER_ROLES.PLATFORM_ADMIN
                    ? (
                        <FormControl className={classes.organizationFilter}>
                            <FormLabel>{t('subscriptionList.filters.organization')}</FormLabel>
                            <Select
                              ControlProps={{
                                  fullWidth: true,
                                  margin: 'dense',
                                  variant: 'outlined',
                              }}
                              controlShouldRenderValue={false}
                              defaultOptions
                              dropdownIcon={<SearchIcon />}
                              isMulti
                              loadOptions={loadOrganizationOptions}
                              menuPlacement="auto"
                              onChange={handleOrganizationFilterChange}
                              placeholder="Search organizations..."
                              showSelectedOptions={false}
                              value={organizationFilterValue}
                            />
                            <div className={classes.chipArray}>
                                {organizationFilterValue.map((option: Object) => (
                                    <Chip
                                      classes={{
                                          root: classes.chip,
                                          label: classes.chipLabel,
                                      }}
                                      color="primary"
                                      data-name="organization"
                                      data-value={getOptionValue(option)}
                                      key={getOptionValue(option)}
                                      label={option.label}
                                      onDelete={handleChipDelete}
                                    />
                                ))}
                            </div>
                        </FormControl>
                    )
                    : null}
            </div>
        </Drawer>
    );
}

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => ({
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    open: state.subscriptionList.drawerOpen,
    organizationFilterValue: selectors.getOrganizationFilterValue(state, props),
    search: selectors.parseSearchParams(state, props),
    user: state.session.user,
});

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    searchOrganizations: (params: Object) => dispatch(searchOrganizations(params)),
    toggleFilterDrawer: (open: boolean) => dispatch(actions.toggleFilterDrawer(open)),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
export default connector(FilterDrawer);
