// @flow
import { createAction } from 'redux-actions';
import type { Dispatch } from 'redux';
import { client as gigwalk } from '../../../../../api/createGigwalkClient';
import { getLocations } from '../../../../../redux/entities/locationLists';
import mapValuesToSubscription from '../../../utils/mapValuesToSubscription';
import types from './types';
import type { State as RootState } from '../../../../../redux/initialState';

export const resetLocations = createAction(types.RESET_LOCATIONS);

export const getMoreLocationsError = createAction(types.GET_MORE_LOCATIONS_ERROR);
export const getMoreLocationsSuccess = createAction(types.GET_MORE_LOCATIONS_SUCCESS);
export const getMoreLocations = createAction(
    types.GET_MORE_LOCATIONS,
    (locationListId: number): Function => (
        (dispatch: Dispatch<any>, getState: () => RootState): Promise<void> => {
            const state = getState();
            const { locationIds } = state.projectBuilder.launch;

            if (locationListId <= 0) {
                dispatch(getMoreLocationsSuccess([]));
                return Promise.resolve();
            }

            const params = {
                organization_location_list_id: locationListId,
                query: {
                    limit: 20,
                    offset: Math.floor(locationIds.length / 20),
                    order_by: 'title',
                },
            };

            return dispatch(getLocations(params))
                .then((page: Object) => {
                    dispatch(getMoreLocationsSuccess(page.result));
                })
                .catch(() => {
                    dispatch(getMoreLocationsError());
                });
        }
    )
);

export const getPriceEstimateError = createAction(types.GET_PRICE_ESTIMATE_ERROR);
export const getPriceEstimateSuccess = createAction(types.GET_PRICE_ESTIMATE_SUCCESS);
export const getPriceEstimate = createAction(
    types.GET_PRICE_ESTIMATE,
    (subscriptionId: number, values: Object) => (
        (dispatch: Dispatch<any>): Promise<void> => {
            const data = mapValuesToSubscription(values);

            // Replace temporary, non-numeric data_type_ids with a 0 in the payload. This is a workaround
            // to avoid schema validation errors when calling the price_estimate API. Note that this API
            // doesn't actually use these ids to calculate the estimate, so providing bogus ones shouldn't
            // cause any issues.
            const targetLists = (data.organization_observation_target_lists || []).map((targetList: Object) => {
                const {
                    data_types: dataTypes,
                    template_id: templateId,
                } = targetList;

                return templateId
                    ? targetList
                    : {
                        ...targetList,
                        data_types: dataTypes.map((dataType: Object) => ({
                            ...dataType,
                            data_type_id: typeof dataType.data_type_id === 'string' ? 0 : dataType.data_type_id,
                        })),
                    };
            });

            const payload = {
                min_gig_price: data.min_gig_price || null,
                organization_observation_target_lists: targetLists,
                organization_location_lists: data.organization_location_lists,
            };

            const url = `/v1/organization_subscriptions/${subscriptionId}/price_estimate`;
            return gigwalk.client.post(url, payload)
                .then((resp) => {
                    const priceEstimate = resp.data.data[0].project_fund;
                    dispatch(getPriceEstimateSuccess(priceEstimate));
                    return priceEstimate;
                })
                .catch(() => {
                    dispatch(getPriceEstimateError());
                });
        }
    )
);

export default {
    getMoreLocations,
    getMoreLocationsError,
    getMoreLocationsSuccess,
    getPriceEstimate,
    getPriceEstimateError,
    getPriceEstimateSuccess,
    resetLocations,
};
