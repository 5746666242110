// @flow
import { createSelector } from 'reselect';
import qs from 'qs';
import { isEmail } from 'validator';
import type { ContextRouter } from 'react-router';
import { createEntitySelector } from '../gigwalk';
import type { State as RootState } from '../../store';

type TableData = Array<{
    customer: Object,
    email: string,
    id: number,
    paypalEmail: ?string,
    rating: ?number,
    status: string,
}>;

type SearchParams = {
    geo?: Object[],
    order?: string,
    page?: string,
    q?: string,
    size?: string,
    sort?: string,
    status?: string[],
};

type APIParams = {
    filters?: Object[],
    keywords?: string,
    sort?: Object[],
    limit?: number,
    offset?: number,
};

// Maps the key we use in the url query to the key expected by the API
const apiParams = {
    email: 'email',
    name: 'first_name',
    paypalEmail: 'paypal_email',
    rating: 'rating_score',
    status: 'customer_status',
};

export const getTableData = createSelector(
    createEntitySelector('customers', (state: RootState) => state.gigwalkerList.data),
    (customers: Object[]): TableData => (
        customers
            .filter((customer) => !!customer)
            .map((customer) => {
                const {
                    // current_latitude: latitude,
                    // current_longitude: longitude,
                    customer_status: status,
                    email,
                    id,
                    paypal_email: paypalEmail,
                    rating_score: rating,
                } = customer;

                // const geocoder = new google.maps.Geocoder();
                //
                // const currentLocation = {
                //     lat: latitude,
                //     lng: longitude,
                // };
                // geocoder.geocode({ location: currentLocation }, callback);

                return {
                    customer,
                    email,
                    id,
                    paypalEmail,
                    rating: rating != null ? Math.round(rating * 10) / 10 : null,
                    status,
                };
            })
    )
);

export const parseSearchParams = createSelector(
    <P: {}>(state: RootState, props: { ...P, ...ContextRouter }): string => {
        const { location } = props;
        return location.search || '';
    },
    (search: string): SearchParams => qs.parse(search, { ignoreQueryPrefix: true })
);

export const getAPIParams = createSelector(
    parseSearchParams,
    (search: SearchParams): APIParams => {
        const { order, page, q, size, sort, ...filters } = search;
        const offset = Math.max(parseInt(page, 10) - 1, 0) || 0;
        const limit = Math.max(parseInt(size, 10), 0) || 10;
        const defaultSort = [{ first_name: order || 'asc' }];

        const apiFilters = [{
            key: 'organization_id',
            value: 5,
        }];

        if (filters.geo) {
            if (filters.geo.length > 1) {
                apiFilters.push({
                    filter_type: 'or',
                    filters: filters.geo.reduce((geoFilters: Object[], geo: Object): Object[] => {
                        const [type, address] = Object.entries(geo)[0];
                        geoFilters.push({
                            filter_type: 'address',
                            key: type,
                            value: address,
                        });
                        return geoFilters;
                    }, []),
                });
            } else {
                const [specificity, address] = Object.entries(filters.geo[0])[0];
                apiFilters.push({
                    filter_type: 'address',
                    key: specificity,
                    value: address,
                });
            }
        }

        if (filters.status) {
            apiFilters.push({
                key: 'customer_status',
                value: filters.status,
            });
        }

        const searchByEmail = q != null && isEmail(q);
        if (searchByEmail) {
            apiFilters.push(
                {
                    filter_type: 'or',
                    filters: [
                        { key: 'email', value: q },
                        { key: 'paypal_email', value: q },
                    ],
                }
            );
        }

        return {
            filters: apiFilters,
            keywords: searchByEmail ? undefined : q,
            sort: (sort && sort in apiParams) ? [{ [apiParams[sort]]: order || 'asc' }] : defaultSort,
            limit,
            offset,
        };
    }
);

export const getActiveFilters = createSelector(
    createSelector(parseSearchParams, (search: SearchParams) => search.status),
    createSelector(
        createSelector(parseSearchParams, (search: SearchParams) => search.geo),
        createSelector((state: RootState) => state.googleMaps.places, (places: Object) => Object.values(places)),
        // $FlowFixMe - places is actually mixed[]
        (filter: ?Object[], places: Object[]): ?Object[] => (
            filter
                ? (
                    filter.reduce((acc: Object[], geo: Object) => {
                        const [specificity, address] = Object.entries(geo)[0];
                        const place = places.find((p) => p.formatted_address === address);
                        if (place != null) {
                            return [
                                ...acc,
                                { ...place, filter_key: specificity },
                            ];
                        }
                        return acc;
                    }, [])
                )
                : undefined
        ),
    ),
    (status: string[], geo: Object[]): ?Object => {
        const filters = { geo, status };
        return Object.entries(filters)
            .reduce((acc: Object, [key, value]: [string, mixed]) => (
                value !== undefined ? { ...acc, [key]: value } : acc
            ), {});
    }
);

export const getActiveFilterCount = createSelector(
    parseSearchParams,
    (search: Object): number => (
        Object.entries(search).reduce((cnt: number, [key, filter]: [string, mixed]): number => {
            if (['q', 'page', 'size', 'sort', 'order'].includes(key)) {
                return cnt;
            }
            return Array.isArray(filter) ? cnt + filter.length : cnt + 1;
        }, 0)
    )
);

export default {
    getActiveFilterCount,
    getActiveFilters,
    getAPIParams,
    getTableData,
    parseSearchParams,
};
