// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { DialogActions, DialogContent } from '@material-ui/core';
import type { $AxiosError, $AxiosXHR } from 'axios';
import type { APIResponse } from 'gigwalk/lib/api/resource';
import logger from '../../../../util/logger';
import { client as gigwalk } from '../../../../api/createGigwalkClient';
import getDisplayName from '../../../../util/getDisplayName';
import SubmitButton from '../../../../components/SubmitButton';
import ApplicantSelect from '../ApplicantSelect';
import styles from './styles';

type Props = {
    applicants: ?Object[],
    dirty: boolean,
    handleSubmit: Function,
    isSubmitting: boolean,
    isValid: boolean,
    setFieldValue: (field: string, value: any) => void,
    setStatus: (status: any) => void,
    status?: any,
    values: Object,
};

const fetchAllApplicants = (ticketId: number) => {
    const applicants = [];

    return Promise.resolve()
        .then(() => {
            const params = { limit: 200, offset: 0 };
            return gigwalk.client.get(`/v1/tickets/${ticketId}/applicants`, { params });
        })
        .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
            const { data, _metadata: metadata } = resp.data;
            applicants.push(...data);

            const promises = [];
            if (metadata) {
                const { page_count: pageCount } = metadata;
                for (let i = 1; i < pageCount; i += 1) {
                    const params = { limit: 200, offset: i };
                    promises.push(gigwalk.client.get(`/v1/tickets/${ticketId}/applicants`, { params }));
                }
            }
            return Promise.all(promises);
        })
        .then((results: Object[]) => {
            results.forEach((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                applicants.push(...resp.data.data);
            });
            return applicants;
        })
        .catch((err: $AxiosError<any>) => {
            logger.error(err);
            return Promise.reject(err);
        });
};

const useStyles = makeStyles(styles, { name: 'AssignApplicantForm' });

export default function AssignApplicantForm(props: Props) {
    const { handleSubmit, isSubmitting, isValid, setFieldValue, values } = props;
    const { assignee, ticketId } = values;

    const classes = useStyles(props);
    const { t } = useTranslation();
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        setOptions([]);

        // We need to make API calls to fetch all applicants since the ticket's applications
        // field does not include the comment that may have been left
        fetchAllApplicants(ticketId)
            .then((applicants: Object[]) => {
                const loadedOptions = applicants.map((customer: Object) => {
                    const { application } = customer;
                    return {
                        label: getDisplayName(customer),
                        value: {
                            ...customer,
                            comment: application ? application.comment || null : null,
                        },
                    };
                });
                setLoading(false);
                setOptions(loadedOptions);
            })
            .catch(() => {
                setLoading(false);
            });
    }, [ticketId]);

    const handleChange = useCallback((value) => {
        setFieldValue('assignee', value);
    }, [setFieldValue]);

    return (
        <Form onSubmit={handleSubmit} className={classes.root}>
            <DialogContent className={classes.dialogContent}>
                <ApplicantSelect
                  isLoading={loading}
                  onChange={handleChange}
                  options={options}
                  value={values.assignee}
                />
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <SubmitButton
                  classes={{
                      disabled: classes.disabled,
                      label: classes.submitLabel,
                      root: classes.submit,
                  }}
                  color="primary"
                  disabled={isSubmitting || !isValid}
                  fullWidth
                  size="large"
                  submitting={isSubmitting}
                  type="submit"
                  variant="outlined"
                >
                    {!assignee
                        ? t('assignApplicantDialog.invalidSubmit')
                        : (
                            <Trans
                              defaults={t('assignApplicantDialog.validSubmit', { customer: assignee.label })}
                              components={['Assign', <strong style={{ fontWeight: 600 }}>customer</strong>]}
                            />
                        )}
                </SubmitButton>
            </DialogActions>
        </Form>
    );
}
