// @flow
import { createSelector } from 'reselect';
import { matchPath } from 'react-router';
import {
    Assignment as AssignmentIcon,
    CreditCard as CreditCardIcon,
    GroupWork as GroupWorkIcon,
    GpsFixed as GpsFixedIcon,
    Group as GroupIcon,
    HelpOutline as HelpOutlineIcon,
    HeadsetMic as HeadsetMicIcon,
    Place as PlaceIcon,
    Storage as StorageIcon,
    VerifiedUser as VerifiedUserIcon,
} from '@material-ui/icons';
import type { ComponentType } from 'react';
import type { Location, LocationShape, Match } from 'react-router';
import { USER_ROLES } from '../../../../../../browser/shared/constant/UserRoles';
import isCrossmark from '../../../../../util/isCrossmark';
import type { State } from '../../../../../redux/initialState';
import type { User } from '../../../../../store_utils/types/User';

type NavItem = {
    href?: string,
    icon: ComponentType<*>,
    id: string,
    isActive?: Function,
    to?: string | LocationShape,
}

export const getLogoUri = createSelector(
    (state: State): ?User => state.session.user,
    (state: State): boolean => {
        const { open, inTransition, expanded } = state.layout.sideNav;
        return open || expanded || inTransition;
    },
    (user: ?User, isSideNavExpanded: boolean): string => {
        const defaultLogoUri = '/public/images/gigwalk-logo.png';
        const defaultMiniLogoUri = '/public/images/gigwalk-logo-mini.png';

        if (user == null) {
            return isSideNavExpanded ? defaultLogoUri : defaultMiniLogoUri;
        }

        const orgConfig = user.organization.config;
        return isSideNavExpanded
            ? orgConfig.logo_uri || defaultLogoUri
            // $FlowFixMe need to update gigwalk-node type definitions
            : orgConfig.mini_icon_uri || defaultMiniLogoUri;
    }
);

export const getNavItems = createSelector(
    (state: State): ?User => state.session.user,
    (user: ?User): NavItem[] => {
        if (user == null) {
            return [];
        }

        // $FlowFixMe need to update gigwalk-node type definitions
        const showLocations = !!user.organization.config.show_locations;
        const showTargets = !!user.organization.config.targets_enabled;

        const orgId = user.organization.id;
        const adminRoles = [
            USER_ROLES.PLATFORM_ADMIN,
            USER_ROLES.SUPER_ADMIN,
            USER_ROLES.SELF_SERVICE,
            USER_ROLES.ADMIN,
        ];
        const supervisorRoles = [
            ...adminRoles,
            USER_ROLES.OPERATOR,
        ];
        const isSupervisor = supervisorRoles.includes(user.role);
        const isSuperAdmin = user.role !== USER_ROLES.ADMIN && adminRoles.includes(user.role);
        const items = [];

        items.push({
            id: 'projects',
            icon: StorageIcon,
            to: `/projects/${orgId}/active/list`,
            isActive: (match: Match, location: Location): boolean => (
                !!matchPath(location.pathname, { path: '/projects/:orgId' })
            ),
        });

        if (isSupervisor) {
            items.push(
                {
                    id: 'tickets',
                    icon: AssignmentIcon,
                    to: `/tickets/${orgId}/list`,
                    isActive: (match: Match, location: Location): boolean => (
                        !!matchPath(location.pathname, { path: '/tickets/:orgId' })
                    ),
                },
                {
                    id: 'myTeam',
                    icon: GroupIcon,
                    to: `/admin/${orgId}/my-team/users/list`,
                    isActive: (match: Match, location: Location): boolean => (
                        !!matchPath(location.pathname, { path: '/admin/:orgId/my-team' })
                    ),
                }
            );
        }

        items.push({
            id: 'certifications',
            icon: VerifiedUserIcon,
            to: `/resources/${orgId}/certifications/list`,
            isActive: (match: Match, location: Location): boolean => (
                !!matchPath(location.pathname, { path: '/resources/:orgId/certifications' })
            ),
        });

        if (showLocations) {
            items.push({
                id: 'locations',
                icon: PlaceIcon,
                to: `/resources/${orgId}/location-lists/list`,
                isActive: (match: Match, location: Location): boolean => (
                    !!matchPath(location.pathname, { path: '/resources/:orgId/(location-lists|locations)' })
                ),
            });
        }

        if (showTargets) {
            items.push({
                id: 'targets',
                icon: GpsFixedIcon,
                to: `/resources/${orgId}/target-lists/list`,
                isActive: (match: Match, location: Location): boolean => (
                    !!matchPath(location.pathname, { path: '/resources/:orgId/(target-lists|targets)' })
                ),
            });
        }

        items.push({
            id: 'questions',
            icon: HelpOutlineIcon,
            to: `/resources/${orgId}/questions/list`,
            isActive: (match: Match, location: Location): boolean => (
                !!matchPath(location.pathname, { path: '/resources/:orgId/questions' })
            ),
        });

        if (user.role === USER_ROLES.PLATFORM_ADMIN) {
            items.push({
                id: 'organizations',
                icon: GroupWorkIcon,
                to: `/admin/${orgId}/organizations/list`,
                isActive: (match: Match, location: Location): boolean => (
                    !!matchPath(location.pathname, { path: '/admin/:orgId/organizations' })
                ),
            });
        }

        if (isSuperAdmin) {
            items.push({
                id: 'transactions',
                icon: CreditCardIcon,
                to: `/admin/${orgId}/transactions/list`,
                isActive: (match: Match, location: Location): boolean => (
                    !!matchPath(location.pathname, { path: '/admin/:orgId/transactions' })
                ),
            });
        }

        if (!isCrossmark(user)) {
            items.push({
                id: 'support',
                icon: HeadsetMicIcon,
                href: 'https://mygigwalk.zendesk.com/hc/en-us',
            });
        }

        return items;
    }
);

export default {
    getLogoUri,
    getNavItems,
};
