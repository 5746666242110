// @flow
import React, { Component, createElement } from 'react';
import cx from 'classnames';
import sanitizeHtml from 'sanitize-html';
import { Field, FieldArray } from 'formik';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import {
    Avatar,
    Card,
    CardActions,
    CardHeader,
    CardContent,
    Chip,
    IconButton,
    LinearProgress,
    List,
    ListItem,
    ListSubheader,
    Tooltip,
} from '@material-ui/core';
import {
    AttachFile as AttachFileIcon,
    AttachMoney as AttachMoneyIcon,
    Attachment as AttachmentIcon,
    CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
    Delete as DeleteIcon,
    Done as DoneIcon,
    Event as EventIcon,
    Gesture as GestureIcon,
    HelpOutline as HelpOutlineIcon,
    LibraryBooks as LibraryBooksIcon,
    Phone as PhoneIcon,
    Photo as PhotoIcon,
    RadioButtonUnchecked as RadioButtonUncheckedIcon,
    Schedule as ScheduleIcon,
    ShoppingCart as ShoppingCartIcon,
    Subject as SubjectIcon,
    Timer as TimerIcon,
} from '@material-ui/icons';
import { Numeric as NumericIcon } from 'mdi-material-ui';
import type { TFunction } from 'react-i18next';
import { VALUE_TYPE } from '../../../../../../../browser/shared/constant/ProjectConstant';
import FormikEditor from '../../../../../../components/FormikEditor';
import FormikInput from '../../../../../../components/FormikInput';
import FormikSwitch from '../../../../../../components/FormikSwitch';
import Ellipsis from '../../../../../../components/Ellipsis';
import TypeDropdown from './TypeDropdown';
import MultipleChoice from './MultipleChoice';
import Number from './Number';
import styles from './styles';

type Props = {
    attachments: Object[],
    classes: Object,
    description: string,
    dragButton?: React$Element<*>,
    expanded?: boolean,
    formKey: string, // the unique key that identifies this question in the form
    icon: string,
    id: number | string, // the dataType id
    isDragging?: boolean,
    index: number,
    onAttachFile: () => void,
    onClick: () => void,
    onDelete: () => void,
    onDuplicate: () => void,
    onValueTypeChange: (valueType: string) => void,
    propositions: Object[],
    questionText: string,
    readOnly?: boolean,
    saving: boolean,
    t: TFunction,
    valid?: boolean,
    valueType: string,
};

// Create a component to display question text in readOnly mode. This component needs to
// be able to render hyperlinks that are clickable and open to a new window/tab
const handleAnchorClick = (event: SyntheticEvent<any>) => {
    const { target } = event;
    if (target instanceof HTMLAnchorElement) {
        event.preventDefault();
        window.open(target.href, '_blank');
    }
};
const ReadOnlyHtml = (props: Object) => {
    const { className, value } = props;
    return (
        <div
          className={className}
          dangerouslySetInnerHTML={{ __html: value }}
          onClick={handleAnchorClick}
          style={{ height: 'auto' }}
        />
    );
};

const icons = {
    [VALUE_TYPE.MULTIPLE_CHOICE]: RadioButtonUncheckedIcon,
    [VALUE_TYPE.MULTI_SELECT]: CheckBoxOutlineBlankIcon,
    [VALUE_TYPE.NUMBER]: NumericIcon,
    [VALUE_TYPE.CURRENCY]: AttachMoneyIcon,
    [VALUE_TYPE.PHONE_NUMBER]: PhoneIcon,
    [VALUE_TYPE.PHOTO]: PhotoIcon,
    [VALUE_TYPE.FREE_TEXT]: SubjectIcon,
    [VALUE_TYPE.DATE]: EventIcon,
    [VALUE_TYPE.DATE_TIME]: ScheduleIcon,
    [VALUE_TYPE.TIME]: TimerIcon,
    [VALUE_TYPE.TASK]: DoneIcon,
    [VALUE_TYPE.BARCODE]: ShoppingCartIcon,
    [VALUE_TYPE.HINT]: HelpOutlineIcon,
    [VALUE_TYPE.CHECKBOXES]: CheckBoxOutlineBlankIcon,
    [VALUE_TYPE.SIGNATURE]: GestureIcon,
};

const questionComponents = {
    [VALUE_TYPE.MULTIPLE_CHOICE]: MultipleChoice,
    [VALUE_TYPE.MULTI_SELECT]: MultipleChoice,
    [VALUE_TYPE.CHECKBOXES]: MultipleChoice,
    [VALUE_TYPE.NUMBER]: Number,
    [VALUE_TYPE.CURRENCY]: Number,
    [VALUE_TYPE.PHONE_NUMBER]: Number,
    [VALUE_TYPE.TIME]: Number,
};

export class QuestionCard extends Component<Props> {
    static defaultProps = {
        onAttachFile: () => {},
        onClick: () => {},
        onDelete: () => {},
        onDuplicate: () => {},
    };

    renderQuestionForm = () => {
        const {
            classes,
            id,
            index,
            propositions,
            questionText,
            readOnly,
            t,
            valueType,
        } = this.props;

        const component = questionComponents[valueType];
        const componentProps = {
            className: classes.preview,
            id,
            index,
            questionText,
            propositions,
            readOnly,
            valueType,
        };

        return (
            <div className={classes.questionForm}>
                <FormikInput
                  className={cx(classes.description, classes.field)}
                  label={t('projectBuilder.data.questionTag')}
                  name={`questionList.${index}.description`}
                  placeholder={t('projectBuilder.data.questionTagPlaceholder')}
                  readOnly={readOnly}
                  required={!readOnly}
                />
                <Field name={`questionList.${index}.valueType`}>
                    {({ field, form }) => {
                        const handleChange = (value: string) => {
                            const shouldAddPropositions = (
                                propositions.length === 0
                                && (value === VALUE_TYPE.MULTI_SELECT || value === VALUE_TYPE.MULTIPLE_CHOICE)
                            );
                            const initialPropositions = [
                                { choice: '', alert: false },
                                { choice: '', alert: false },
                            ];
                            form.setFieldValue(`questionList.${index}.propositions`, shouldAddPropositions ? initialPropositions : []);
                            form.setFieldValue(field.name, value);
                        };
                        return (
                            <TypeDropdown
                              {...field}
                              className={cx(classes.dropdown, classes.field)}
                              disabled={readOnly || typeof id !== 'string' || !id.startsWith('new')}
                              onChange={handleChange}
                            />
                        );
                    }}
                </Field>
                <FormikEditor
                  className={cx(classes.questionText, classes.field)}
                  inputComponent={readOnly ? ReadOnlyHtml : 'textarea'}
                  label={t('projectBuilder.data.questionText')}
                  name={`questionList.${index}.questionText`}
                  placeholder={t('projectBuilder.data.questionTextPlaceholder')}
                  readOnly={readOnly}
                  required={!readOnly}
                />
                {component ? createElement(component, componentProps) : null}
            </div>
        );
    };

    renderExpanded() {
        const {
            attachments,
            classes,
            index,
            onAttachFile,
            onDelete,
            onDuplicate,
            readOnly,
            t,
            valid,
            valueType,
        } = this.props;

        const attachmentsLabel = attachments.length > 0
            ? (
                <ListSubheader className={classes.attachmentsLabel} disableSticky>
                    {t('projectBuilder.data.attachments')}
                </ListSubheader>
            )
            : null;

        return (
            <Card className={cx(classes.card, classes.expanded)} elevation={0} raised>
                <span className={classes.sequence}>{`#${index + 1}`}</span>
                <CardContent>
                    {this.renderQuestionForm()}
                    <List subheader={attachmentsLabel}>
                        <FieldArray name={`questionList.${index}.attachments`}>
                            {(arrayHelpers) => (
                                attachments.map((attachment: Object, i: number) => {
                                    const { file_name: fileName, url } = attachment;
                                    return (
                                        <ListItem dense>
                                            <Chip
                                              classes={{
                                                  label: classes.attachmentLabel,
                                                  root: classes.attachment,
                                              }}
                                              icon={<AttachmentIcon />}
                                              label={<a href={url} rel="noopener noreferrer" target="_blank">{fileName}</a>}
                                              onDelete={!readOnly ? () => arrayHelpers.remove(i) : null}
                                            />
                                        </ListItem>
                                    );
                                })
                            )}
                        </FieldArray>
                    </List>
                </CardContent>
                {!readOnly
                    ? (
                        <CardActions className={classes.cardActions} disableSpacing>
                            <IconButton disabled={false} onClick={onDelete}>
                                <Tooltip placement="top" title={t('projectBuilder.data.remove')}>
                                    <DeleteIcon />
                                </Tooltip>
                            </IconButton>
                            <IconButton disabled={!valid} onClick={onDuplicate}>
                                <Tooltip placement="top" title={t('projectBuilder.data.duplicate')}>
                                    <LibraryBooksIcon />
                                </Tooltip>
                            </IconButton>
                            <IconButton disabled={false} onClick={onAttachFile}>
                                <Tooltip placement="top" title={t('projectBuilder.data.attachFiles')}>
                                    <AttachFileIcon />
                                </Tooltip>
                            </IconButton>
                            <div className={classes.separator} />
                            <FormikSwitch
                              className={classes.requiredSwitch}
                              name={`questionList[${index}].required`}
                              label={t('projectBuilder.data.required')}
                              disabled={valueType === VALUE_TYPE.HINT}
                              type="checkbox"
                            />
                        </CardActions>
                    )
                    : null}
            </Card>
        );
    }

    renderCollapsed() {
        const {
            classes,
            description,
            dragButton,
            index,
            isDragging,
            onClick,
            questionText,
            saving,
            valueType,
        } = this.props;

        const Icon = icons[valueType];
        const avatar = (
            <Avatar className={classes.avatar}>
                <Icon />
            </Avatar>
        );
        const className = cx(classes.card, classes.collapsed, {
            [classes.dragging]: isDragging,
        });

        return (
            <Card className={className} elevation={0} onClick={onClick}>
                { saving ? <LinearProgress className={classes.progressBar} variant="indeterminate" /> : null }
                <span className={classes.sequence}>{`#${index + 1}`}</span>
                <CardHeader
                  action={dragButton || null}
                  avatar={avatar}
                  classes={{ action: classes.cardHeaderAction }}
                  subheader={(
                      <Ellipsis clamp={3} text={sanitizeHtml(questionText, { allowedTags: [], allowedAttributes: [] })} />
                  )}
                  title={description}
                />
            </Card>
        );
    }

    render() {
        const { expanded } = this.props;
        return expanded ? this.renderExpanded() : this.renderCollapsed();
    }
}

const enhance = compose(
    withStyles(styles, { name: 'QuestionCard' }),
    withTranslation(),
);
export default enhance(QuestionCard);
