// @flow
import React, { Component } from 'react';
import classnames from 'classnames';
import DateTimePicker from './DateTimePicker';
import type { Props as DateTimePickerProps } from './DateTimePicker';

type Status = {
    classNames: Array<string>,
    text: string,
    visible?: boolean,
};

type Props = DateTimePickerProps & {
    label: string,
    labelClass?: string,
    statuses?: { [key: string]: Status },
};

type State = {
    statusList?: { [key: string]: Status },
    disabled: boolean,
};

export default class LabeledDateTimePicker extends Component<Props, State> {
    static isREACT = true;

    $dateTimePicker: ?DateTimePicker;

    constructor() {
        super();
        this.state = {
            statusList: {},
            disabled: false,
        };
    }

    UNSAFE_componentWillMount() {
        const { inputProps, statuses } = this.props;
        this.setState({
            statusList: statuses || {},
            disabled: inputProps.readOnly,
        });
    }

    setMinDate(newMinDate: ?moment) {
        if (this.$dateTimePicker) {
            this.$dateTimePicker.setMinDate(newMinDate);
        }
    }

    setMaxDate(newMaxDate: moment) {
        if (this.$dateTimePicker) {
            this.$dateTimePicker.setMaxDate(newMaxDate);
        }
    }

    getValue(): moment {
        if (this.$dateTimePicker) {
            return this.$dateTimePicker.getValue();
        }
    }

    setValue(newValue: moment) {
        if (this.$dateTimePicker) {
            this.$dateTimePicker.setValue(newValue);
        }
    }

    toggleStatus = (id: string, visible: boolean) => {
        const { statusList } = this.state;
        this.setState({
            statusList: {
                ...statusList,
                [id]: {
                    // $FlowFixMe
                    ...statusList[id],
                    visible,
                },
            },
        });
    };

    show() {
        if (this.$dateTimePicker) {
            this.$dateTimePicker.$date.datepicker('show');
        }
    }

    disable(disabled: boolean = true) {
        this.setState({
            disabled,
        });
    }

    empty() {
        if (this.$dateTimePicker) {
            this.$dateTimePicker.$date.datepicker('update', '');
        }
    }

    render() {
        const { statusList, disabled } = this.state;
        const { label, labelClass, statuses, inputProps, ...props } = this.props; // eslint-disable-line
        const statusComponents = [];
        _.each(statusList, (status: Status, key: string) => {
            statusComponents.push(
                <span key={key} className={classnames('c-edit__icon', ...status.classNames, { 'c--show': status.visible })}>
                    {status.text}
                </span>
            );
        });

        const _inputProps = { ...inputProps, readOnly: disabled };

        return (
            <div className="c-form__row c-form-inline-group">
                {label
                    ? <span className={classnames('c-choose-date__label', labelClass)}>{label}</span>
                    : null}
                <DateTimePicker {...props} inputProps={_inputProps} ref={(c) => { this.$dateTimePicker = c; }} />
                {statusComponents}
            </div>
        );
    }
}
