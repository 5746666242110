// @flow
export const FETCH_TICKET_STATS = 'g/activity/FETCH_TICKET_STATS';
export const FETCH_TICKET_STATS_SUCCESS = `${FETCH_TICKET_STATS}_SUCCESS`;
export const FETCH_TICKET_STATS_ERROR = `${FETCH_TICKET_STATS}_ERROR`;

export default {
    FETCH_TICKET_STATS,
    FETCH_TICKET_STATS_ERROR,
    FETCH_TICKET_STATS_SUCCESS,
};
