// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import { validateYupSchema, yupToFormErrors } from 'formik';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Hidden, Step, StepButton, StepIcon, Stepper } from '@material-ui/core';
import { Error as ErrorIcon } from '@material-ui/icons';
import styles from './styles';

type Props = {
    context: ?Object,
    currentStep: string,
    isSubmitting: boolean,
    onStepClick: (event: SyntheticEvent<any>, step: string) => void,
    steps: Object[],
    values: Object,
};

const useStyles = makeStyles(styles, { name: 'ProgressTracker' });

export function ProgressTracker(props: Props) {
    const { context, currentStep, isSubmitting, onStepClick, steps, values } = props;
    const subscription = context ? context.subscription : null; // eslint-disable-line react/destructuring-assignment

    const classes = useStyles(props);
    const { t } = useTranslation();
    const [errors, setErrors] = useState({});

    // We only want to run validations when the page changes. By storing values using a
    // ref, we can prevent annoying eslint errors in useEffect below
    const valuesRef = useRef(null);
    const contextRef = useRef(null);

    valuesRef.current = values;
    contextRef.current = context;

    useEffect(() => {
        const stepErrors = {};
        const promises = [];

        steps.forEach((step: Object) => {
            const { name, validationSchema } = step;
            promises.push(
                validateYupSchema(valuesRef.current, validationSchema, false, contextRef.current)
                    .catch((err: any) => {
                        if (err.name === 'ValidationError') {
                            stepErrors[name] = stepErrors[name] || [];
                            stepErrors[name].push(yupToFormErrors(err));
                        }
                    })
            );
        });

        Promise.all(promises)
            .then(() => {
                setErrors(stepErrors);
            });
    }, [currentStep, steps]);

    const stepNames = useMemo(() => {
        const set = new Set();
        steps.forEach((step: Object) => {
            set.add(step.name);
        });
        return Array.from(set);
    }, [steps]);

    const handleStepClick = useCallback((event: SyntheticEvent<any>) => {
        const { name } = event.currentTarget;
        onStepClick(event, name);
    }, [onStepClick]);

    const name = currentStep.split('/')[0];
    const currentIndex = stepNames.indexOf(name);

    return (
        <Stepper
          className={classes.root}
          activeStep={currentIndex}
          nonLinear={subscription != null}
          alternativeLabel
        >
            {stepNames.map((stepName: string, index: number) => {
                const valid = !errors[stepName] || errors[stepName].length === 0;
                const icon = index < currentIndex && !valid
                    ? <StepIcon icon={<ErrorIcon className={cx(classes.stepIcon, classes.error)} />} />
                    : index + 1;

                return (
                    <Step
                      key={stepName}
                      classes={{ horizontal: classes.stepHorizontal }}
                      completed={currentIndex > index}
                      {...(isSubmitting ? { disabled: true } : {})}
                    >
                        <StepButton
                          icon={icon}
                          name={stepName}
                          onClick={handleStepClick}
                        >
                            <Hidden xsDown implementation="css">
                                {t(`projectBuilder.${stepName}.sectionTitle`)}
                            </Hidden>
                        </StepButton>
                    </Step>
                );
            })}
        </Stepper>
    );
}

export default ProgressTracker;
