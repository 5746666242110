// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback, useEffect, useState } from 'react';
import i18next from 'i18next';
import numeral from 'numeral';
import * as yup from 'yup';
import Card from 'react-credit-cards';
import { Form, Formik } from 'formik';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Hidden } from '@material-ui/core';
import type { Connector } from 'react-redux';
import type { Dispatch } from 'redux';
import FormikMaskedInput from '../../components/FormikMaskedInput';
import SimplePane from '../../components/SimplePane';
import SubmitButton from '../../components/SubmitButton';
import actions from './duck/actions';
import type { State as RootState } from '../../redux/initialState';

type OwnProps = {};
type StateProps = {
    addedFunds: number,
    currentFunds: number,
};
type DispatchProps = {
    addFunds: (values: Object) => Promise<void>,
    getBalance: () => void,
};
type Props = OwnProps & StateProps & DispatchProps;

const AddFundsSchema = yup.object().shape({
    amount: yup.number().required('validation.required'),
    cardNumber: yup.number()
        .required('validation.required')
        .test({
            message: '${path} must be a valid card number', // eslint-disable-line no-template-curly-in-string
            name: 'cardNumber',
            test(value) {
                if (!value) {
                    return true;
                }

                if (typeof Stripe === 'undefined') {
                    throw new Error('Stripe.js client library not loaded!!');
                }

                if (!Stripe.card.validateCardNumber(value)) {
                    const message = i18next.t('validation.invalidCardNumber');
                    return this.createError({ message });
                }

                return true;
            },
        }),
    cvc: yup.number()
        .required('validation.required')
        .test({
            message: '${path} must be a valid cvc', // eslint-disable-line no-template-curly-in-string
            name: 'cvc',
            test(value) {
                if (!value) {
                    return true;
                }

                if (typeof Stripe === 'undefined') {
                    throw new Error('Stripe.js client library not loaded!!');
                }

                if (!Stripe.card.validateCVC(value)) {
                    const message = i18next.t('validation.invalidCVC');
                    return this.createError({ message });
                }

                return true;
            },
        }),
    expiry: yup.number()
        .required('validation.required')
        .test({
            message: '${path} must be a valid expiration date', // eslint-disable-line no-template-curly-in-string
            name: 'expiry',
            test(value) {
                if (!value) {
                    return true;
                }

                if (typeof Stripe === 'undefined') {
                    throw new Error('Stripe.js client library not loaded!!');
                }

                const month = `${value || ''}`.substr(0, 2);
                const year = `${value || ''}`.substr(2);

                if (!Stripe.card.validateExpiry(month, year)) {
                    const message = i18next.t('validation.invalidExpiry');
                    return this.createError({ message });
                }

                return true;
            },
        }),
});

export function AddFunds(props: Props) {
    const {
        addFunds,
        addedFunds,
        currentFunds,
        getBalance,
    } = props;

    // Format currentFunds for display. This should match the formatter used
    // by Cleave for the 'amount' field in CreditCardForm as close as possible.
    // Using numeral.js here since it has better support for currency.
    const balance = numeral(currentFunds).format('$0,0.00');
    const added = numeral(addedFunds).format('$0,0.00');

    const { t } = useTranslation();
    const [focused, setFocused] = useState(null);
    const [dialogVisible, setDialogVisible] = useState(false);

    const handleFocus = useCallback((event: SyntheticInputEvent<any>) => {
        const { name } = event.target;
        setFocused(name);
    }, []);

    const handleDialogClose = useCallback(() => {
        setDialogVisible(false);
    }, []);

    const handleBlur = useCallback(() => {
        setFocused(null);
    }, []);

    useEffect(() => {
        getBalance();
    }, [getBalance]);

    return (
        <Formik
          initialStatus={{}}
          initialValues={{
              amount: '',
              cardNumber: '',
              cvc: '',
              expiry: '',
          }}
          onSubmit={(values, { resetForm, setStatus }) => (
              addFunds(values)
                  .then(() => {
                      setStatus({ success: true });
                      setDialogVisible(true);
                      resetForm();
                  })
                  .catch((error: string) => {
                      setStatus({ error });
                      return Promise.reject(error);
                  })
          )}
          validationSchema={AddFundsSchema}
        >
            {(formikProps) => {
                const { isSubmitting, isValid, values } = formikProps;
                return (
                    <SimplePane>
                        <Dialog
                          open={dialogVisible}
                          onClose={handleDialogClose}
                          maxWidth="xs"
                        >
                            <DialogTitle>{t('payments.confirmationDialog.title')}</DialogTitle>
                            <DialogContent>
                                {t('payments.confirmationDialog.message', { amount: added })}
                            </DialogContent>
                            <DialogActions>
                                <Button variant="contained" onClick={handleDialogClose}>
                                    {t('payments.confirmationDialog.confirm')}
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Form noValidate>
                            <h2>
                                {t('payments.currentFunds')}
                                <span className="amount">{balance}</span>
                            </h2>
                            <Grid container spacing={3}>
                                <Grid container item spacing={2} xs={12} sm={8} md={6}>
                                    <Grid item xs={4}>
                                        <FormikMaskedInput
                                          fullWidth
                                          inputMode="numeric"
                                          label={t('payments.form.amount')}
                                          name="amount"
                                          onBlur={handleBlur}
                                          onFocus={handleFocus}
                                          options={{
                                              numeral: true,
                                              numeralThousandsGroupStyle: 'thousand',
                                              numeralPositiveOnly: true,
                                              prefix: '$',
                                              noImmediatePrefix: true,
                                              rawValueTrimPrefix: true,
                                          }}
                                          pattern="[0-9.,]*"
                                          placeholder={t('payments.form.amountPlaceholder')}
                                          required
                                          variant="filled"
                                        />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FormikMaskedInput
                                          fullWidth
                                          inputMode="numeric"
                                          label={t('payments.form.cardNumber')}
                                          name="cardNumber"
                                          onBlur={handleBlur}
                                          onFocus={handleFocus}
                                          options={{ creditCard: true }}
                                          pattern="[0-9]*"
                                          placeholder={t('payments.form.cardNumberPlaceholder')}
                                          required
                                          variant="filled"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormikMaskedInput
                                          fullWidth
                                          inputMode="numeric"
                                          label={t('payments.form.expiry')}
                                          name="expiry"
                                          onBlur={handleBlur}
                                          onFocus={handleFocus}
                                          options={{
                                              date: true,
                                              datePattern: ['m', 'y'],
                                          }}
                                          pattern="[0-9]*"
                                          placeholder={t('payments.form.expiryPlaceholder')}
                                          required
                                          variant="filled"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormikMaskedInput
                                          fullWidth
                                          inputMode="numeric"
                                          label={t('payments.form.cvc')}
                                          name="cvc"
                                          onBlur={handleBlur}
                                          onFocus={handleFocus}
                                          options={{
                                              blocks: [4],
                                              numericOnly: true,
                                          }}
                                          pattern="[0-9]*"
                                          placeholder={t('payments.form.cvcPlaceholder')}
                                          required
                                          variant="filled"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SubmitButton
                                          color="secondary"
                                          disabled={isSubmitting || !isValid}
                                          submitting={isSubmitting}
                                          type="submit"
                                          variant="contained"
                                        >
                                            {t('payments.form.addFunds')}
                                        </SubmitButton>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Hidden implementation="css" smDown>
                                        <Card
                                          name=""
                                          number={values.cardNumber || ''}
                                          expiry={values.expiry || ''}
                                          cvc={values.cvc || ''}
                                          focused={focused}
                                        />
                                    </Hidden>
                                </Grid>
                            </Grid>
                        </Form>
                    </SimplePane>
                );
            }}
        </Formik>
    );
}

const mapStateToProps = (state: RootState): StateProps => ({
    ...state.addFunds,
});
const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    addFunds: (values: Object) => dispatch(actions.addFunds(values)),
    getBalance: () => dispatch(actions.getBalance()),
});
const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
export default connector(AddFunds);
