// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback } from 'react';
import moment from 'moment';
import cx from 'classnames';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Field, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { FormControl, FormControlLabel, FormLabel, Radio } from '@material-ui/core';
import { DateRange as DateRangeIcon } from '@material-ui/icons';
import type { Connector } from 'react-redux';
import type { ContextRouter } from 'react-router';
import type { Subscription } from 'gigwalk/lib/api/subscriptions/types';
import FormikInput from '../../../../components/FormikInput';
import FormikDateTimePicker from '../../../../components/FormikDateTimePicker';
import FormikEditor from '../../../../components/FormikEditor';
import { SCHEDULE_TYPES } from '../../../../../browser/shared/constant/ProjectConstant';
import Step from '../../components/Step';
import entitySelector from '../../../../redux/entities/entitySelector';
import styles from './styles';
import type { State as RootState } from '../../../../redux/initialState';

type OwnProps = ContextRouter & {
    dirty: boolean,
    handleSubmit: Function,
    isSubmitting: boolean,
    isValid: boolean,
    setFieldTouched: (field: string, touched: boolean) => void,
    setFieldValue: (field: string, value: any) => void,
    setStatus: (any) => void,
    status?: any,
    values: Object,
};
type StateProps = {
    inProgress: boolean,
    subscription: ?Subscription,
};
type DispatchProps = {};
type Props = OwnProps & StateProps & DispatchProps;

const useStyles = makeStyles(styles, { name: 'BasicInfo' });

export function BasicInfo(props: Props) {
    const { inProgress, setFieldTouched, setFieldValue, values } = props;
    const { scheduleType } = values;

    const classes = useStyles(props);
    const { t } = useTranslation();

    const handleScheduleTypeChange = useCallback((event: SyntheticEvent<any>) => {
        const { target } = event;

        if (target instanceof HTMLInputElement) {
            const { name, value } = target;
            setFieldValue(name, value);

            switch (value) {
                case SCHEDULE_TYPES.ASAP:
                    setFieldValue('rangeStartDate', '');
                    setFieldValue('rangeEndDate', '');
                    setFieldTouched('rangeStartDate', false);
                    setFieldTouched('rangeEndDate', false);
                    break;

                case SCHEDULE_TYPES.RANGE:
                    setFieldValue('asapEndDate', '');
                    setFieldTouched('asapEndDate', false);
                    break;

                default:
                    break;
            }
        }
    }, [setFieldTouched, setFieldValue]);

    return (
        <Step title={t('projectBuilder.info.header')}>
            <Form className={classes.form}>
                <div className={cx('c-form__row', classes.title)}>
                    <FormikInput
                      fullWidth
                      label={t('projectBuilder.info.title')}
                      labelPlacement="top"
                      name="title"
                      placeholder={t('projectBuilder.info.titlePlaceholder')}
                      required
                      variant="outlined"
                    />
                </div>
                <div className={cx('c-form__row', classes.description)}>
                    <FormikEditor
                      fullWidth
                      label={t('projectBuilder.info.description')}
                      labelPlacement="top"
                      name="description"
                      placeholder={t('projectBuilder.info.descriptionPlaceholder')}
                      required
                      rows={6}
                      variant="outlined"
                    />
                </div>
                <FormControl>
                    <FormLabel>{t('projectBuilder.info.gigDates')}</FormLabel>
                    <div>
                        <div className={cx('c-form__row', classes.asap)}>
                            <Field name="scheduleType" type="radio" value={SCHEDULE_TYPES.ASAP}>
                                {({ field }) => (
                                    <FormControlLabel
                                      {...field}
                                      onChange={handleScheduleTypeChange}
                                      control={<Radio color="primary" />}
                                      disabled={inProgress && scheduleType !== SCHEDULE_TYPES.ASAP}
                                      label={t('projectBuilder.info.beginImmediately')}
                                    />
                                )}
                            </Field>
                            <div className={classes.control}>
                                <div className={classes.datePicker}>
                                    <FormikDateTimePicker
                                      classes={{ label: classes.datePickerLabel }}
                                      customInputIcon={<DateRangeIcon />}
                                      disabled={scheduleType !== SCHEDULE_TYPES.ASAP}
                                      label={t('projectBuilder.info.endsOn')}
                                      name="asapEndDate"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={cx('c-form__row', classes.range)}>
                            <Field name="scheduleType" type="radio" value={SCHEDULE_TYPES.RANGE}>
                                {({ field }) => (
                                    <FormControlLabel
                                      {...field}
                                      onChange={handleScheduleTypeChange}
                                      control={<Radio color="primary" />}
                                      disabled={inProgress && scheduleType !== SCHEDULE_TYPES.RANGE}
                                      label={t('projectBuilder.info.specificDateRange')}
                                    />
                                )}
                            </Field>
                            <div className={classes.control}>
                                <div className={classes.datePicker}>
                                    <FormikDateTimePicker
                                      classes={{ label: classes.datePickerLabel }}
                                      customInputIcon={<DateRangeIcon />}
                                      disabled={inProgress || scheduleType !== SCHEDULE_TYPES.RANGE}
                                      label={t('projectBuilder.info.startsOn')}
                                      name="rangeStartDate"
                                    />
                                </div>
                                <div className={classes.datePicker}>
                                    <FormikDateTimePicker
                                      classes={{ label: classes.datePickerLabel }}
                                      customInputIcon={<DateRangeIcon />}
                                      disabled={scheduleType !== SCHEDULE_TYPES.RANGE}
                                      label={t('projectBuilder.info.endsOn')}
                                      name="rangeEndDate"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </FormControl>
            </Form>
        </Step>
    );
}

const subscriptionSelector = entitySelector('subscriptions');

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => {
    const { match } = props;
    const subscription = subscriptionSelector(state, match.params.subscriptionId);
    return {
        inProgress: subscription ? subscription.state === 'ACTIVE' && moment(subscription.start_date).isBefore(moment()) : false,
        subscription,
    };
};

const mapDispatchToProps: DispatchProps = {};

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
const enhance = compose(
    withRouter,
    connector,
);

export default enhance(BasicInfo);
