// @flow
import React from 'react';
import Helmet from 'react-helmet';
import { Route, Switch } from 'react-router';
import NoMatch from '../NoMatch';
import TicketList from '../TicketList';
import TicketDetail from '../../../client/pages/ticketDetail';
import AssignApplicantDialog from '../AssignApplicantDialog';
import AssignDialog from '../AssignDialog';
import CancelDialog from '../CancelDialog';
import ExtendDialog from '../ExtendDialog';
import OptinDialog from '../OptInDialog';
import ReopenDialog from '../ReopenDialog';
import RescheduleDialog from '../RescheduleDialog';
import ScheduleDialog from '../ScheduleDialog';
import UnassignDialog from '../UnassignDialog';

export default () => (
    <div>
        <Helmet title="Tickets" />
        <Switch>
            <Route path="/tickets/:orgId/:view(list|map)" component={TicketList} />
            <Route path="/tickets/:orgId/:page(detail|info)/:ticketId" component={TicketDetail} />
            <Route component={NoMatch} />
        </Switch>
        <AssignApplicantDialog />
        <AssignDialog />
        <CancelDialog />
        <ExtendDialog />
        <OptinDialog />
        <ReopenDialog />
        <RescheduleDialog />
        <ScheduleDialog />
        <UnassignDialog />
    </div>
);
