// @flow
// $FlowIssue need to update to a more recent flow version
import React, { forwardRef, useCallback, useLayoutEffect, useEffect, useRef, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import cx from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import { dump, insert, load, TagValues } from 'piexifjs';
import ResizeObserver from 'resize-observer-polyfill';
import { CircularProgress, IconButton, Fab, Toolbar } from '@material-ui/core';
import {
    ArrowBack as ArrowBackIcon,
    Crop as CropIcon,
    Flip as FlipIcon,
    RotateLeft as RotateLeftIcon,
    Save as SaveIcon,
} from '@material-ui/icons';
import logger from '../../util/logger';
import CropMenu from './CropMenu';
import FlipMenu from './FlipMenu';
import RotateMenu from './RotateMenu';
import Grid from './Grid';

let TuiImageEditor;
if (typeof window !== 'undefined') {
    TuiImageEditor = require('tui-image-editor'); // eslint-disable-line global-require
}

type Props = {
    classes: Object,
    imageUrl: string,
    onClose: () => void,
    onSave: (dataURL: string) => ?Promise<void>,
};

const menuComponents = {
    crop: CropMenu,
    flip: FlipMenu,
    rotate: RotateMenu,
};

export const Container = forwardRef((props: Props, ref: Object) => {
    const { classes, imageUrl, onClose, onSave } = props;

    const layoutRef = useRef(null);
    const containerRef = useRef(null);
    const rootRef = useRef(null);
    const imageEditorRef = useRef(null);
    const resizeObserverRef = useRef(null);
    const exifDataRef = useRef(null);

    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [submenu, toggleSubmenu] = useState(null);

    const resizeEditor = () => {
        const container = containerRef.current;
        const imageEditor = imageEditorRef.current;
        const layout = layoutRef.current;
        const root = rootRef.current;

        if (!imageEditor) {
            return;
        }

        let cssMaxHeight = root.clientHeight;
        for (let i = 0; i < root.children.length; i += 1) {
            const element = root.children[i];
            const { position } = getComputedStyle(element);
            if (element !== layout && position !== 'absolute') {
                cssMaxHeight -= element.clientHeight;
            }
        }

        const graphics = imageEditor._graphics;
        const canvasImage = graphics.getCanvasImage();

        graphics.setCssMaxDimension({
            height: cssMaxHeight,
            width: root.clientWidth,
        });

        if (canvasImage) {
            graphics.adjustCanvasDimension();

            const canvasElement = graphics.getCanvasElement();
            const height = parseFloat(canvasElement.parentElement.style.maxHeight);
            container.style.height = `${height}px`;

            const cropper = graphics.getComponent('CROPPER');
            if (cropper._cropzone) {
                const canvas = graphics.getCanvas();
                const scale = canvas.getHeight() / height;
                cropper._cropzone.cornerSize = Math.round(10 * scale);
                cropper._cropzone._renderControls(canvas.getContext());
            }
        }
    };

    useEffect(() => {
        const root = rootRef.current;
        let resizeObserver = resizeObserverRef.current;

        if (!resizeObserver) {
            resizeObserver = new ResizeObserver(() => {
                resizeEditor();
            });
            resizeObserver.observe(root);
            resizeObserverRef.current = resizeObserver;
        }

        return () => {
            resizeObserver.disconnect();
            resizeObserverRef.current = null;
        };
    }, []);

    useEffect(() => {
        const container = containerRef.current;
        let imageEditor = imageEditorRef.current;

        if (!imageEditor && TuiImageEditor) {
            const options = {
                applyCropSelectionStyle: true,
                applyGroupSelectionStyle: true,
                selectionStyle: {
                    cornerStyle: 'circle',
                    cornerSize: 10,
                    cornerColor: '#fff',
                    cornerStrokeColor: '#fff',
                    transparentCorners: false,
                    lineWidth: 2,
                    borderColor: '#fff',
                },
                usageStatistics: false,
            };
            imageEditor = new TuiImageEditor(container, options);
            imageEditorRef.current = imageEditor;

            const gridVisual = document.createElement('div');
            gridVisual.className = 'tui-image-editor-grid-visual';
            gridVisual.innerHTML = renderToStaticMarkup(<Grid />);

            const canvasContainer = container.querySelector('.tui-image-editor-canvas-container');
            canvasContainer.appendChild(gridVisual);
        }

        return () => {
            if (imageEditor) {
                imageEditor.destroy();
            }
        };
    }, []);

    useEffect(() => {
        const imageEditor = imageEditorRef.current;

        if (imageUrl && imageEditor) {
            setLoading(true);

            // Append query string param to prevent cors issue in older versions of Chrome
            // See https://serverfault.com/questions/856904/chrome-s3-cloudfront-no-access-control-allow-origin-header-on-initial-xhr-req
            const url = new URL(imageUrl);
            url.searchParams.append('edit', '1');

            fetch(new Request(url.toString(), { mode: 'cors' }))
                .then((resp: Response) => resp.blob())
                .then((blob: Blob) => (
                    new Promise((resolve, reject) => {
                        const fileReader = new FileReader();
                        fileReader.onload = (event) => resolve(event.target.result);
                        fileReader.onerror = (err) => reject(err);
                        fileReader.readAsDataURL(blob);
                    })
                ))
                .then((dataURL: string) => {
                    try {
                        exifDataRef.current = load(dataURL);
                    } catch (e) {
                        logger.warn(`Unable to load EXIF data for image '${imageUrl}'`);
                    }
                    return imageEditor.loadImageFromURL(dataURL, 'image');
                })
                .then(() => resizeEditor())
                .catch(() => setErrorMessage('There was a problem loading the image'))
                .then(() => setLoading(false));
        }
    }, [imageUrl]);

    useLayoutEffect(() => {
        resizeEditor();
    });

    const rootRefCallback = useCallback((node: React$Element<*>) => {
        rootRef.current = node;
        if (ref && ref.hasOwnProperty('current')) {
            ref.current = node; // eslint-disable-line no-param-reassign
        } else if (typeof ref === 'function') {
            ref(node);
        }
    }, [ref]);

    const handleMenuItemClick = useCallback((event: SyntheticEvent<any>) => {
        const selected = event.currentTarget.name;
        toggleSubmenu(selected === submenu ? null : selected);
    }, [submenu]);

    const handleSave = useCallback(() => {
        const imageEditor = imageEditorRef.current;
        let dataURL = imageEditor.toDataURL({ format: 'jpeg' });

        const exifData = cloneDeep(exifDataRef.current);
        if (exifData) {
            // Delete orientation metadata if it exists
            if (exifData['0th']) {
                delete exifData['0th'][TagValues.ImageIFD.Orientation];
            }
            const exifBinary = dump(exifData);
            dataURL = insert(exifBinary, dataURL);
        }

        const maybePromise = onSave(dataURL);
        if (maybePromise instanceof Promise) {
            setSaving(true);
            maybePromise
                .catch(() => {})
                .then(() => setSaving(false));
        }
    }, [onSave]);

    const handleSubmenuClose = useCallback(() => {
        toggleSubmenu(null);
    }, []);

    const Submenu = menuComponents[submenu] || null;

    return (
        <div className={classes.root} ref={rootRefCallback}>
            <Fab className={classes.close} size="small" onClick={onClose}>
                <ArrowBackIcon />
            </Fab>
            <Fab
              classes={{
                  root: classes.save,
                  disabled: classes.disabled,
              }}
              color="primary"
              disabled={saving}
              onClick={handleSave}
              size="medium"
              variant="extended"
            >
                <SaveIcon className={classes.saveIcon} />
                Save
                {saving ? <CircularProgress size={24} style={{ position: 'absolute' }} /> : null}
            </Fab>
            <div className={classes.layout} ref={layoutRef}>
                {loading ? <CircularProgress /> : null}
                {!loading && errorMessage ? errorMessage : null}
                <div
                  className={cx(classes.editor, { [classes.showGrid]: submenu === 'rotate' })}
                  ref={containerRef}
                  style={{ display: loading || errorMessage ? 'none' : 'block' }}
                />
            </div>
            <div style={{ width: '100%' }}>
                {Submenu && imageEditorRef.current
                    ? <Submenu editor={imageEditorRef.current} onClose={handleSubmenuClose} />
                    : null}
            </div>
            <Toolbar className={classes.menuToolbar}>
                <IconButton
                  className={cx(classes.toggleButton, { [classes.selected]: submenu === 'crop' })}
                  color="inherit"
                  name="crop"
                  onClick={handleMenuItemClick}
                >
                    <CropIcon />
                </IconButton>
                <IconButton
                  className={cx(classes.toggleButton, { [classes.selected]: submenu === 'flip' })}
                  color="inherit"
                  name="flip"
                  onClick={handleMenuItemClick}
                >
                    <FlipIcon />
                </IconButton>
                <IconButton
                  className={cx(classes.toggleButton, { [classes.selected]: submenu === 'rotate' })}
                  color="inherit"
                  name="rotate"
                  onClick={handleMenuItemClick}
                >
                    <RotateLeftIcon />
                </IconButton>
            </Toolbar>
        </div>
    );
});

Container.defaultProps = {
    onClose: () => {},
    onSave: () => {},
};

export default Container;
