// @flow
// $FlowIssue need to update to a more recent flow version
import React, { Component, Fragment, createRef } from 'react';
import cx from 'classnames';
import EventListener from 'react-event-listener';
import { withStyles } from '@material-ui/styles';
import { CircularProgress } from '@material-ui/core';

type State = {
    loading: boolean,
    rotate: number,
    scale: number,
    tx: number,
    ty: number,
};

type Props = {
    alt?: string,
    classes: Object,
    className?: string,
    onMouseEnter?: (event: SyntheticEvent<*>) => void,
    onMouseLeave?: (event: SyntheticEvent<*>) => void,
    src: string,
};

const ZOOM_STEP = 1.10;
const MAX_ZOOM_SIZE = ZOOM_STEP ** 30;
const MIN_ZOOM_SIZE = (1 / ZOOM_STEP) ** 10;

const styles = () => ({
    root: {
        width: '100%',
        height: '100%',
        textAlign: 'center',
        overflow: 'hidden',

        '&::before': {
            content: '""',
            display: 'inline-block',
            height: '100%',
            verticalAlign: 'middle',
        },
    },
    image: {
        maxHeight: '100%',
        maxWidth: '100%',
        verticalAlign: 'middle',
    },
    progressBar: {
        verticalAlign: 'middle',
    },
});

function contains(clientRect, coordinate) {
    const { x, y } = coordinate;
    const { bottom, left, right, top } = clientRect;
    return y >= top && y <= bottom && x >= left && x <= right;
}

export class Image extends Component<Props, State> {
    containerRef: Object;

    imgRef: Object;

    captureMouseEvents: boolean;

    clientX: ?number;

    clientY: ?number;

    constructor(props: Props) {
        super(props);

        this.state = {
            loading: true,
            rotate: 0,
            scale: 1,
            tx: 0,
            ty: 0,
        };

        this.containerRef = createRef();
        this.imgRef = createRef();

        this.captureMouseEvents = false;
        this.clientX = null;
        this.clientY = null;
    }

    componentDidMount() {
        const { src } = this.props;
        const img = document.createElement('img');
        img.onload = () => this.setState({ loading: false });
        img.src = src;
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { tx, ty, scale } = this.state;
        const { src } = this.props;

        if (prevProps.src !== src) {
            const img = document.createElement('img');
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                loading: true,
                rotate: 0,
                scale: 1,
                tx: 0,
                ty: 0,
            });
            img.onload = () => this.setState({ loading: false });
            img.src = src;
        }

        if (prevState.scale !== scale) {
            const container = this.containerRef.current;
            const img = this.imgRef.current;
            const { height: containerHeight, width: containerWidth, x: containerX, y: containerY } = container.getBoundingClientRect();
            const { height, width, x, y } = img.getBoundingClientRect();

            const translateX = (x >= containerX || x + width <= containerX + containerWidth) ? 0 : tx;
            const translateY = (y >= containerY || y + height <= containerY + containerHeight) ? 0 : ty;

            if (translateX !== tx || translateY !== ty) {
                // eslint-disable-next-line react/no-did-update-set-state
                this.setState({
                    tx: translateX,
                    ty: translateY,
                });
            }
        }
    }

    handleMouseEnter = (event: SyntheticEvent<*>) => {
        this.captureMouseEvents = true;

        const { onMouseEnter } = this.props;
        if (onMouseEnter) {
            onMouseEnter(event);
        }
    };

    handleMouseLeave = (event: SyntheticEvent<*>) => {
        this.captureMouseEvents = false;

        const { onMouseLeave } = this.props;
        if (onMouseLeave) {
            onMouseLeave(event);
        }
    };

    handleMouseDown = (event: SyntheticMouseEvent<any>) => {
        const img = this.imgRef.current;
        const clientRect = img.getBoundingClientRect();
        const coordinate = {
            x: event.clientX,
            y: event.clientY,
        };

        if (!this.captureMouseEvents || !contains(clientRect, coordinate) || event.button !== 0) {
            return;
        }

        this.clientX = event.clientX;
        this.clientY = event.clientY;
    };

    handleMouseMove = (event: SyntheticMouseEvent<any>) => {
        const { tx, ty, scale } = this.state;
        const { clientX, clientY } = this;

        if (!this.captureMouseEvents || clientX == null || clientY == null) {
            return;
        }

        const container = this.containerRef.current;
        const img = this.imgRef.current;
        const { height: containerHeight, width: containerWidth, x: containerX, y: containerY } = container.getBoundingClientRect();
        const { height, width, x, y } = img.getBoundingClientRect();

        const dx = event.clientX - clientX;
        const dy = event.clientY - clientY;

        let translateX = tx + dx / scale;
        let translateY = ty + dy / scale;

        if (x + dx >= containerX || x + dx + width <= containerX + containerWidth) {
            translateX = tx;
        }

        if (y + dy >= containerY || y + dy + height <= containerY + containerHeight) {
            translateY = ty;
        }

        this.clientX = event.clientX;
        this.clientY = event.clientY;

        this.setState({
            tx: translateX,
            ty: translateY,
        });
    };

    handleMouseUp = (event: SyntheticMouseEvent<any>) => {
        if (!this.captureMouseEvents || event.button !== 0) {
            return;
        }

        this.clientX = null;
        this.clientY = null;
    };

    handleWheel = (event: SyntheticWheelEvent<any>) => {
        if (!this.captureMouseEvents) {
            return;
        }

        const img = this.imgRef.current;
        const clientRect = img.getBoundingClientRect();
        const coordinate = {
            x: event.clientX,
            y: event.clientY,
        };

        if (contains(clientRect, coordinate)) {
            this.zoom(event.deltaY);
        }
    };

    rotate(angle: number) {
        const { rotate } = this.state;
        this.setState({
            rotate: (360 + rotate + angle) % 360,
        });
    }

    zoom(direction: number, magnitude?: number = 1) {
        const { scale } = this.state;
        const factor = direction > 0 ? ZOOM_STEP ** magnitude : (1 / ZOOM_STEP) ** magnitude;

        this.setState({
            scale: Math.max(
                Math.min(MAX_ZOOM_SIZE, scale * factor),
                MIN_ZOOM_SIZE
            ),
        });
    }

    render() {
        const { loading, rotate, scale, tx, ty } = this.state;
        const { alt, classes, className, onMouseEnter, onMouseLeave, src, ...other } = this.props;

        const cssTransform = `scale(${scale}) translate(${tx}px, ${ty}px) rotate(${rotate}deg)`;

        return (
            <div
              className={cx(classes.root, className)}
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
              ref={this.containerRef}
              {...other}
            >
                {loading
                    ? <CircularProgress className={classes.progressBar} />
                    : (
                        <Fragment>
                            <EventListener
                              target="document"
                              onMouseDown={this.handleMouseDown}
                              onMouseMove={this.handleMouseMove}
                              onMouseUp={this.handleMouseUp}
                              onWheel={this.handleWheel}
                            />
                            <img
                              alt={alt}
                              src={src}
                              className={classes.image}
                              draggable={false}
                              ref={this.imgRef}
                              style={{ transform: cssTransform }}
                            />
                        </Fragment>
                    )}
            </div>
        );
    }
}

export default withStyles(styles, { name: 'Image' })(Image);
