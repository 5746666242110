// @flow
import React from 'react';
import cx from 'classnames';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { FormControlLabel, FormHelperText, Switch } from '@material-ui/core';
import styles from './styles';

type Props = {
    checked?: boolean,
    classes?: Object,
    className?: string,
    disabled?: boolean,
    label?: string,
    labelPlacement?: 'end' | 'start' | 'top' | 'bottom',
    type: 'checkbox',
};

const useStyles = makeStyles(styles, { name: 'FormikSwitch' });

export default function FormikSwitch(props: Props) {
    const {
        checked: checkedProp,
        classes: classesProp,
        className,
        disabled,
        label,
        labelPlacement,
        ...other
    } = props;

    const classes = useStyles(props);
    const { i18n, t } = useTranslation();
    const [field, meta] = useField(props);

    const { error, touched } = meta;

    let errorMessage = null;
    if (touched && error) {
        if (typeof error === 'string') {
            errorMessage = i18n.exists(error) ? t(error) : error;
        } else if ('message' in error) {
            const { message, ...options } = error;
            errorMessage = i18n.exists(message) ? t(message, { ...options }) : message;
        }
    }

    const {
        root: rootClassName,
        wrapper: wrapperClassName,
        label: labelClassName,
        switch: switchClassName,
        errorMessage: errorClassName,
        ...otherClasses
    } = classes;

    const FormControlLabelProps = { disabled, label, labelPlacement };

    return (
        <div className={cx(rootClassName, className)}>
            <FormControlLabel
              classes={{
                  disabled: classes.disabled,
                  label: labelClassName,
                  root: wrapperClassName,
              }}
              control={(
                  <Switch
                    {...other}
                    {...field}
                    classes={{ root: switchClassName, ...otherClasses }}
                  />
              )}
              {...FormControlLabelProps}
            />
            {errorMessage
                ? <FormHelperText error className={errorClassName}>{errorMessage}</FormHelperText>
                : null}
        </div>
    );
}
