// @flow
import { createSelector } from 'reselect';
import type { State as RootState } from '../../store';

const ADMIN_ROLES = [
    'PLATFORM_ADMIN',
    'SUPER_ADMIN',
    'SELF_SERVICE',
    'ADMIN',
];

const SUPERVISOR_ROLES = [
    ...ADMIN_ROLES,
    'OPERATOR',
];

const getUserRole = (state: RootState): string => (state.session.user ? state.session.user.role : '');
const getGroupMemberships = (state: RootState): Object[] => (state.session.user ? state.session.user.group_memberships : []);

export const isFirstTimeUser = createSelector(
    (state: RootState): ?Object => (state.session.user ? state.session.user.organization : null),
    (organization: ?Object): boolean => {
        if (organization) {
            return (
                organization.stats.team.group_count <= 1
                && organization.stats.projects.active_count === 0
                && organization.stats.projects.archived_count === 0
                && organization.stats.projects.canceled_count === 0
                && organization.stats.projects.completed_count === 0
            );
        }
        return false;
    }
);

export const isGigwalker = createSelector(
    getUserRole,
    (state: RootState): number => (state.session.user ? state.session.user.organization.id : -1),
    (role: string, orgId: number) => (role === 'WORKER' && orgId === 5)
);

export const isWorker = createSelector(getUserRole, (role: string): boolean => role === 'WORKER');
export const isOperator = createSelector(getUserRole, (role: string): boolean => role === 'OPERATOR');
export const isSupervisor = createSelector(getUserRole, (role: string): boolean => SUPERVISOR_ROLES.includes(role));
export const isAdmin = createSelector(getUserRole, (role: string): boolean => ADMIN_ROLES.includes(role));
export const isSuperAdmin = createSelector(getUserRole, (role: string): boolean => role !== 'ADMIN' && ADMIN_ROLES.includes(role));
export const isPlatformAdmin = createSelector(getUserRole, (role: string): boolean => role === 'PLATFORM_ADMIN');

export const canLaunchPublicProject = createSelector(
    getUserRole,
    (role: string) => ['PLATFORM_ADMIN', 'SELF_SERVICE'].includes(role)
);

export const canManageGroups = createSelector(
    getUserRole,
    getGroupMemberships,
    (role: string, groupMemberships: Object[]) => {
        if (ADMIN_ROLES.includes(role)) {
            return true;
        }
        return groupMemberships.some((membership: Object) => membership.role === 'OWNER');
    }
);

export const canEditOrgConfig = createSelector(
    getUserRole,
    (role: string) => (role === 'PLATFORM_ADMIN')
);

export const canAddFunds = createSelector(
    getUserRole,
    (state: RootState): boolean => (state.session.features ? state.session.features.canAccessPayments : false),
    (role: string, canAccessPayments: boolean) => (
        ['SUPER_ADMIN', 'SELF_SERVICE'].includes(role) && canAccessPayments
    ),
);

export default {
    canAddFunds,
    canEditOrgConfig,
    canLaunchPublicProject,
    canManageGroups,
    isAdmin,
    isFirstTimeUser,
    isGigwalker,
    isOperator,
    isPlatformAdmin,
    isSuperAdmin,
    isSupervisor,
    isWorker,
};
