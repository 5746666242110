// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import Dropzone from '../../../../../../components/Dropzone';

type Props = {
    accept?: string | string[],
    bucket?: 'locationList' | 'locationListUpdate' | 'customer',
    maxSize?: number,
    minSize?: number,
    multiple?: boolean,
    onClose: (event: SyntheticEvent<any>, reason: string) => void,
    onConfirm: (event: SyntheticEvent<any>, uploads: Object[]) => void,
    open?: boolean,
};

export function AttachFileDialog(props: Props) {
    const {
        bucket,
        accept,
        multiple,
        minSize,
        maxSize,
        onClose,
        onConfirm,
        open,
        ...other
    } = props;

    const dropzoneProps = { bucket, accept, multiple, minSize, maxSize };

    const { t } = useTranslation();
    const [uploads, setUploads] = useState([]);

    const invalid = useMemo(() => (
        uploads.length === 0 || uploads.some((upload) => upload.status !== 'complete')
    ), [uploads]);

    const handleConfirm = useCallback((event: SyntheticEvent<any>) => {
        onConfirm(event, uploads);
    }, [onConfirm, uploads]);

    const handleCancel = useCallback((event: SyntheticEvent<any>) => {
        onClose(event, 'cancelClick');
    }, [onClose]);

    const handleFileRemoved = useCallback((file: File) => {
        setUploads(uploads.filter((upload) => upload.file !== file));
    }, [setUploads, uploads]);

    const handleUploadComplete = useCallback((upload) => {
        setUploads(uploads.map((orig) => (orig.id === upload.id ? upload : orig)));
    }, [setUploads, uploads]);

    const handleUploadQueued = useCallback((upload: Object) => {
        setUploads([...uploads, upload]);
    }, [setUploads, uploads]);

    useEffect(() => {
        if (!open) {
            setUploads([]);
        }
    }, [open]);

    return (
        <Dialog {...other} onClose={onClose} open={open}>
            <DialogTitle>
                {t('projectBuilder.data.attachFiles')}
            </DialogTitle>
            <DialogContent>
                <Dropzone
                  {...dropzoneProps}
                  onFileRemoved={handleFileRemoved}
                  onUploadError={handleUploadComplete}
                  onUploadQueued={handleUploadQueued}
                  onUploadSuccess={handleUploadComplete}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>
                    {t('projectBuilder.data.cancel')}
                </Button>
                <Button
                  color="primary"
                  disabled={invalid}
                  onClick={handleConfirm}
                  variant="contained"
                >
                    {t('projectBuilder.data.confirm')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

AttachFileDialog.defaultProps = {
    onClose: () => {},
    onConfirm: () => {},
};

export default AttachFileDialog;
