// @flow
/* global google */
// $FlowIssue need to update to a more recent flow version
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { load, GPSHelper, TagValues } from 'piexifjs';
import { GoogleMap, Marker, InfoWindow, withGoogleMap } from 'react-google-maps';
import { compose, withProps } from 'recompose';
// $FlowTypedIssue update react-redux libdef
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Chip, CircularProgress } from '@material-ui/core';
import { Check as CheckIcon } from '@material-ui/icons';
import type { ContextRouter } from 'react-router';
import { getDataItems, getTicket } from '../../../../ducks/ticketDetail';
import logger from '../../../../utils/logger';
import renderToDataUrl from '../../../../utils/renderToDataUrl';
import fetchAsDataURL from '../../../../utils/fetchAsDataURL';
import MapControl from '../../../../components/MapControl';
import RunMarker from '../../../../components/icons/RunMarker';
import EditMarker from '../../../../components/icons/EditMarker';
import PhotoMarker from '../../../../components/icons/PhotoMarker';
import styles from './styles';
import type { State as RootState } from '../../../../store';

type MarkerProps = {
    icon: {
        url: string,
        scaledSize?: Object,
    },
    info: string | React$Element<any>,
    position: { lat: number, lng: number },
}

type Props = ContextRouter & {
    height?: number,
    width?: number,
}

/**
 * @todo checklist
 * [-] Add a loading indicator while marker data is loading
 * [-] Figure out a good solution to group multiple pins in close proximity
 * [-] Assign a unique color to pins from different assignees
 */

const useStyles = makeStyles(styles, { name: 'GPSVerifiedMap' });

export function GPSVerifiedMap(props: Props) {
    const classes = useStyles(props);
    const { t, i18n } = useTranslation();

    const mapRef = useRef(null);

    const [loading, setLoading] = useState(true);
    const [ready, setReady] = useState(false);
    const [dataItemMarkers, setDataItemMarkers] = useState([]);
    const [activeMarker, setActiveMarker] = useState(null);

    const ticket = useSelector((state: RootState) => getTicket(state));
    const dataItems = useSelector((state: RootState) => getDataItems(state));

    const location = ticket ? ticket.location : null;

    const locationMarker = useMemo(() => {
        if (location) {
            const mapCenter = {
                lat: location.latitude,
                lng: location.longitude,
            };
            return {
                icon: {
                    url: renderToDataUrl(
                        <RunMarker width={60} height={60} fill="#3168aa" xmlns="http://www.w3.org/2000/svg" />,
                        'image/svg+xml'
                    ),
                },
                info: location.formatted_address,
                position: mapCenter,
            };
        }

        return null;
    }, [location]);

    useEffect(() => {
        const promises = [];
        const svgProps = {
            height: 40,
            width: 40,
            xmlns: 'http://www.w3.org/2000/svg',
        };

        dataItems.forEach((dataItem: Object) => {
            const {
                data_item_value: values,
                data_type_value_type: valueType,
                data_item_latitude: latitude,
                data_item_longitude: longitude,
            } = dataItem;

            const lat = parseFloat(latitude);
            const lng = parseFloat(longitude);

            if (!Number.isNaN(lat) && !Number.isNaN(lng)) {
                promises.push(
                    Promise.resolve({
                        icon: {
                            url: renderToDataUrl(
                                <EditMarker {...svgProps} fill="#00c7ae" />,
                                'image/svg+xml'
                            ),
                        },
                        info: valueType === 'PHOTO'
                            ? 'gpsVerifiedMap.photoUploaded'
                            : 'gpsVerifiedMap.answerSubmitted',
                        position: { lat, lng },
                    })
                );
            }

            if (valueType === 'PHOTO') {
                values.forEach((value: { photo_url: string }) => {
                    let imgSrc;

                    // Append query string param to prevent cors issue in older versions of Chrome
                    // See https://serverfault.com/questions/856904/chrome-s3-cloudfront-no-access-control-allow-origin-header-on-initial-xhr-req
                    const url = new URL(value.photo_url);
                    url.searchParams.append('exif', '1');

                    promises.push(
                        fetchAsDataURL(value.photo_url)
                            .then((dataURL: string) => {
                                imgSrc = dataURL;
                                try {
                                    return load(dataURL);
                                } catch (e) {
                                    logger.warn(`Unable to load EXIF data for image '${value.photo_url}'`);
                                    return null;
                                }
                            })
                            .then((exifData: ?Object) => {
                                if (exifData && exifData.GPS) {
                                    const {
                                        [TagValues.GPSIFD.GPSLatitude]: latDeg,
                                        [TagValues.GPSIFD.GPSLatitudeRef]: latRef,
                                        [TagValues.GPSIFD.GPSLongitude]: lngDeg,
                                        [TagValues.GPSIFD.GPSLongitudeRef]: lngRef,
                                    } = exifData.GPS;

                                    let photoLat = Number.NaN;
                                    let photoLng = Number.NaN;

                                    if (latDeg && latRef && lngDeg && lngRef) {
                                        photoLat = GPSHelper.dmsRationalToDeg(latDeg, latRef);
                                        photoLng = GPSHelper.dmsRationalToDeg(lngDeg, lngRef);
                                    }

                                    if (!Number.isNaN(photoLat) && !Number.isNaN(photoLng)) {
                                        const imgStyle = {
                                            maxHeight: 'calc(100% - 12px)',
                                            maxWidth: 'calc(100% - 12px)',
                                            padding: '4px 4px 0 4px',
                                        };
                                        return {
                                            icon: {
                                                url: renderToDataUrl(
                                                    <PhotoMarker {...svgProps} fill="#00c7ae" />,
                                                    'image/svg+xml'
                                                ),
                                            },
                                            info: <img style={imgStyle} src={imgSrc} alt="" />,
                                            position: {
                                                lat: photoLat,
                                                lng: photoLng,
                                            },
                                        };
                                    }
                                }
                            })
                    );
                });
            }
        });

        setLoading(true);
        Promise.all(promises)
            // $FlowIssue Flow is dumb, switch to TypeScript
            .then((results: MarkerProps[]) => {
                const markers = results.filter((v: MarkerProps): boolean => v != null);
                setDataItemMarkers(markers);
                setLoading(false);
            });
    }, [dataItems]);

    useEffect(() => {
        const map = mapRef.current;

        // Adjust the bounds of the map to include all markers. Make sure that map
        // is initialized before calling fitBounds
        if (map && ready) {
            const currentBounds = map.getBounds();
            const bounds = new google.maps.LatLngBounds();

            if (locationMarker) {
                bounds.extend(locationMarker.position);
            }

            dataItemMarkers.forEach((marker: MarkerProps) => {
                bounds.extend(marker.position);
            });

            bounds.union(currentBounds);

            if (!currentBounds.equals(bounds)) {
                map.fitBounds(bounds);
            }
        }
    }, [dataItemMarkers, locationMarker, ready]);

    const handleMapIdle = useCallback(() => {
        setReady(true);
    }, []);

    const handleMarkerClick = useCallback((index: number) => {
        setActiveMarker(index);
    }, []);

    const handleInfoWindowClose = useCallback(() => {
        setActiveMarker(null);
    }, []);

    const allMarkers = [locationMarker, ...dataItemMarkers];

    return (
        <Fragment>
            {loading
                ? (
                    <div className={classes.loading}>
                        <CircularProgress color="primary" />
                    </div>
                )
                : null}
            <GoogleMap
              onIdle={handleMapIdle}
              defaultCenter={{
                  lat: location ? location.latitude : 0,
                  lng: location ? location.longitude : 0,
              }}
              defaultOptions={{
                  fullscreenControl: false,
                  mapTypeControl: false,
                  streetViewControl: false,
              }}
              defaultZoom={15}
              ref={mapRef}
            >
                <MapControl controlPosition={google.maps.ControlPosition.TOP_RIGHT}>
                    <Chip
                      classes={{
                          root: classes.chip,
                          label: classes.chipLabel,
                      }}
                      label={(
                          <Fragment>
                              <span><strong>GPS</strong> Verified</span>
                              <CheckIcon className={classes.checkIcon} />
                          </Fragment>
                      )}
                    />
                </MapControl>
                {allMarkers.map((marker: MarkerProps, index: number) => {
                    if (!marker) {
                        return null;
                    }

                    const { info, ...markerProps } = marker;
                    return (
                        <Marker
                          {...markerProps}
                          key={index}
                          onClick={() => { handleMarkerClick(index); }}
                        >
                            {activeMarker === index
                                ? (
                                    <InfoWindow onCloseClick={handleInfoWindowClose}>
                                        <span>
                                            {typeof info === 'string' && i18n.exists(info) ? t(info) : info}
                                        </span>
                                    </InfoWindow>
                                )
                                : null}
                        </Marker>
                    );
                })}
            </GoogleMap>
        </Fragment>
    );
}

const enhance = compose(
    withProps((ownProps: Props) => {
        const { height, width } = ownProps;
        const containerStyle = {
            position: 'relative',
            height: height ? `${height}px` : '100%',
            width: width ? `${width}px` : '100%',
        };

        return {
            containerElement: <div style={containerStyle} />,
            mapElement: <div style={{ height: '100%' }} />,
        };
    }),
    withGoogleMap,
);

export default enhance(GPSVerifiedMap);
