// @flow
export default () => ({
    root: {
        minWidth: 200,

        '@global ol, ul': {
            marginTop: 10,
            marginBottom: 10,
            paddingLeft: 40,
        },

        '@global ol': {
            listStyleType: 'decimal',
        },

        '@global ul': {
            listStyleType: 'disc',
        },
    },
    inputMultiline: {},
});
