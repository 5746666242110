// @flow
import { fade } from '@material-ui/core';

export default (theme: Object) => ({
    root: {
        fontFamily: ['ProximaNova', '!important'],
        fontSize: 16,
        justifyContent: 'flex-start',
    },
    input: {
        backgroundColor: theme.palette.common.white,
        marginRight: theme.spacing(1),
    },
    badge: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.contrastText,
    },
    settings: {
        width: 350,
        padding: theme.spacing(1.5),
    },
    settingsLabel: {
        fontFamily: 'ProximaNova',
        fontSize: 16,
    },
    columnsToolbar: {
        display: 'flex',
        // alignItems: 'center',
        alignItems: 'baseline',
        padding: theme.spacing(0.5, 1, 1.5),
        margin: theme.spacing(0, 0, 1, 0),
        borderBottom: [[1, 'solid', theme.palette.grey[300]]],
    },
    resetButton: {
        marginLeft: 'auto',
        fontFamily: 'ProximaNova',
        fontSize: 14,
        // marginRight: -8,
    },
    toggleButtons: {
        display: 'flex',
        flexFlow: 'row wrap',
        margin: theme.spacing(-0.5),
    },
    toggleButton: {
        fontFamily: 'ProximaNova',
        fontSize: 16,
        flexBasis: 'calc(50% - 8px)',
        margin: theme.spacing(0.5),
        boxShadow: 'none',
        border: [[1, 'solid', theme.palette.primary.main]],
        borderRadius: 4,
        color: theme.palette.primary.main,
        paddingTop: 2,
        paddingBottom: 2,

        '&:active': {
            boxShadow: 'none',
        },

        '&:not($selected):hover': {
            backgroundColor: fade(theme.palette.primary.main, theme.palette.action.hoverOpacity),
            color: theme.palette.primary.main,
        },
    },
    selected: {},
});
