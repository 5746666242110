// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback } from 'react';
import noop from 'lodash/noop';
import cx from 'classnames';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { FormControl, FormHelperText, FormLabel } from '@material-ui/core';
import DateTimePicker from '../DateTimePicker';
import styles from './styles';

type Props = {
    classes?: Object,
    className?: string,
    disabled?: boolean,
    label?: string,
    margin?: string,
    onBlur: (event: Object) => void,
    onChange: (event: Object) => void,
    required?: boolean,
};

const createFakeEvent = (properties: Object) => {
    const event = {
        ...properties,
        defaultPrevented: false,
        preventDefault() {
            event.defaultPrevented = true;
        },
    };
    return event;
};

const useStyles = makeStyles(styles, { name: 'FormikDateTimePicker' });

export default function FormikDateTimePicker(props: Props) {
    const {
        classes: classesProp,
        className,
        disabled,
        label,
        margin,
        onBlur,
        onChange,
        required,
        ...other
    } = props;

    const classes = useStyles(props);
    const { i18n, t } = useTranslation();
    const [field, meta] = useField(props);

    const { error, touched } = meta;
    const {
        name,
        onBlur: fieldOnBlur,
        onChange: fieldOnChange,
    } = field;

    // @todo Fix focus management in DateTimePicker so that focus/blur event is passed
    const handleBlur = useCallback(() => {
        const event = createFakeEvent({ target: { name } });

        onBlur(event);
        if (!event.defaultPrevented) {
            fieldOnBlur(event);
        }
    }, [fieldOnBlur, name, onBlur]);

    const handleChange = useCallback((value) => {
        const event = createFakeEvent({ target: { name, value } });

        onChange(event);
        if (!event.defaultPrevented) {
            fieldOnChange(event);
        }
    }, [fieldOnChange, name, onChange]);

    let errorMessage = null;
    if (touched && error) {
        if (typeof error === 'string') {
            errorMessage = i18n.exists(error) ? t(error) : error;
        } else if ('message' in error) {
            const { message, ...translateOptions } = error;
            errorMessage = i18n.exists(message) ? t(message, { ...translateOptions }) : message;
        }
    }

    const {
        root: rootClassName,
        label: labelClassName,
        dateTimePicker: dateTimePickerClassName,
        errorMessage: errorClassName,
        ...otherClasses
    } = classes;

    return (
        <FormControl
          className={cx(rootClassName, className)}
          disabled={disabled}
          error={!!errorMessage}
          margin={margin}
          required={required}
        >
            {label ? <FormLabel className={labelClassName}>{label}</FormLabel> : null}
            <DateTimePicker
              {...other}
              {...field}
              classes={{ root: dateTimePickerClassName, ...otherClasses }}
              disabled={disabled}
              error={!!errorMessage}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errorMessage
                ? <FormHelperText className={errorClassName}>{errorMessage}</FormHelperText>
                : null}
        </FormControl>
    );
}

FormikDateTimePicker.defaultProps = {
    onBlur: noop,
    onChange: noop,
};
