// @flow
export default (theme: Object) => ({
    '@global body': {
        // background: theme.palette.background.default, // $neutral-2
        background: `linear-gradient(${theme.palette.primary.main} 350px, ${theme.palette.background.default} 350px)`,
        backgroundRepeat: 'no-repeat',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(5),
    },
    name: {
        fontWeight: 600,
    },
    user: {
        color: theme.palette.primary.contrastText,
    },
    userPrimary: {
        display: 'flex',
        alignItems: 'center',

        '& > :not(:last-child)': {
            marginRight: theme.spacing(1.5),
        },
    },
    userSecondary: {
        marginTop: 4,
    },
    rating: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 2,
        fontWeight: 600,

        '& > :not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },
    avatar: {
        marginRight: 18,
    },
    avatarBadge: {
        width: 'auto',
        height: 'auto',
        minWidth: 'unset',
        padding: 0,
        top: 'calc(100% - 14px)',
        right: 14,
    },
    editButton: {
        minHeight: 'unset',
        height: 28,
        width: 28,
        fontSize: 18,
    },
    tabs: {
        marginBottom: -2,
        minHeight: 'auto',
    },
    tab: {
        fontSize: theme.typography.pxToRem(16),
        minWidth: 120,
        minHeight: 'auto',
        textTransform: 'none',
        color: theme.palette.getContrastText(theme.palette.primary.main),
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,

        '&$active': {
            backgroundColor: theme.palette.common.white,
            opacity: 0.77,
            color: theme.palette.primary.main,
        },
    },
    active: {},
});
