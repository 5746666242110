// @flow
import { createAction } from 'redux-actions';
import type { Dispatch } from 'redux';
import type { $AxiosError } from 'axios';
import logger from '../../../../../util/logger';
import { search as searchTickets } from '../../../../../redux/entities/tickets';
import types from './types';

export const fetchTicketStatsSuccess = createAction(types.FETCH_TICKET_STATS_SUCCESS);
export const fetchTicketStatsError = createAction(types.FETCH_TICKET_STATS_ERROR);
export const fetchTicketStats = createAction(
    types.FETCH_TICKET_STATS,
    (customerId: number): Function => (
        (dispatch: Dispatch<any>): Promise<void> => {
            const getActiveTickets = Promise.resolve()
                .then(() => {
                    const apiFilters = [
                        { key: 'ticket_status', value: ['ASSIGNED', 'SCHEDULED', 'STARTED'] },
                        { key: 'assigned_customer_id', value: customerId },
                    ];
                    return dispatch(searchTickets({ filters: apiFilters }));
                });

            const getSubmittedTickets = Promise.resolve()
                .then(() => {
                    const apiFilters = [
                        { key: 'ticket_status', value: ['SUBMITTED'] },
                        { key: 'assigned_customer_id', value: customerId },
                    ];
                    return dispatch(searchTickets({ filters: apiFilters }));
                });

            return Promise.all([getActiveTickets, getSubmittedTickets])
                .then((results: [Object, Object]) => {
                    const [activeTickets, submittedTickets] = results;
                    const activeCount = activeTickets.metadata.record_count;
                    const submittedCount = submittedTickets.metadata.record_count;
                    dispatch(fetchTicketStatsSuccess({ activeCount, submittedCount }));
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(fetchTicketStatsError());
                    return Promise.reject(err);
                });
        }
    )
);

export default {
    fetchTicketStats,
    fetchTicketStatsError,
    fetchTicketStatsSuccess,
};
