// @flow
import { createAction } from 'redux-actions';
import { normalize } from 'normalizr';
import omitBy from 'lodash/omitBy';
import identity from 'lodash/identity';
import blacklist from 'blacklist';
import type { Dispatch } from 'redux';
import type { $AxiosError, $AxiosXHR } from 'axios';
import { client as gigwalk } from '../../api/createGigwalkClient';
import types from './types';
import {
    auditEvent as auditEventSchema,
    certification as certificationSchema,
    customer as customerSchema,
    dataType as dataTypeSchema,
    group as groupSchema,
    location as locationSchema,
    locationList as locationListSchema,
    notification as notificationSchema,
    organization as organizationSchema,
    payout as payoutSchema,
    relation as relationSchema,
    subscription as subscriptionSchema,
    tag as tagSchema,
    template as templateSchema,
    ticket as ticketSchema,
    unresolvedLocation as unresolvedLocationSchema,
} from './schemas';

// For reference only - need to refactor entities and figure out where this should live
// type Normalized<T> = {
//     result: number[],
//     entities: Object[],
//     metadata?: Object,
//     original: APIResponse<T>,
// }

type APIResponse<T> = {
    _meta: Object,
    _metadata: Object,
    warnings: mixed,
    errors: mixed,
    gw_api_response: Object[],
    code: number,
    data: T,
}

// @todo create logger middleware to log any errors dispatched to store

function normalizeResponse(resp: $AxiosXHR<any>, schema?: Object) {
    if (!resp.data || !resp.data.data) {
        return {
            entities: {},
            result: null,
            original: null,
            metadata: null,
        };
    }

    const _schema = Array.isArray(resp.data.data) ? [schema] : schema;
    const normalized = normalize(resp.data.data, _schema || {});
    normalized.original = resp.data;
    normalized.metadata = resp.data._metadata;

    return normalized;
}

// This function will normalize the locations returned by /v1/location_lists/{id}/locations,
// which include a special relation_id attribute. We refer to these locations as relations.
// There could be duplicate locations in a list, but each entry will have a unique relation_id.
// Since we want entities.locations to remain the source of truth for location data, we need
// to extract that data from entities.relations and merge it into entities.locations
function normalizeRelations(data: Object[]): Object {
    const normalized = normalize(data, [relationSchema]);

    if (normalized.entities.relations == null) {
        return normalized;
    }

    const relations = {};
    const locations = {};

    Object.entries(normalized.entities.relations).forEach(([id, relation]: [string, mixed]) => {
        if (relation == null || typeof relation !== 'object') return;
        const location = { ...relation };
        delete location.relation_id;

        relations[id] = {
            relation_id: parseInt(id, 10),
            id: location.id,
        };

        locations[location.id] = location;
    });

    return {
        ...normalized,
        entities: {
            ...blacklist(normalized.entities, 'relations'),
            relations,
            locations,
        },
    };
}

const mapESRecordToTicket = (record: Object) => {
    const {
        assigned_customer_email: assigneeEmail,
        assigned_customer_first_name: assigneeFirstName,
        assigned_customer_id: assigneeId,
        assigned_customer_last_name: assigneeLastName,
        assigned_customer_name: assigneeName,
        calendar_event_end: calendarEventEnd,
        calendar_event_event_type: calendarEventType,
        calendar_event_id: calendarEventId,
        calendar_event_start: calendarEventStart,
        is_double_optin: subscriptionIsDoubleOptin,
        location_administrative_area_level_1: locationAdministrativeAreaLevel1,
        location_administrative_area_level_2: locationAdministrativeAreaLevel2,
        location_country: locationCountry,
        location_date_created: locationDateCreated,
        location_date_updated: locationDateUpdated,
        location_formatted_address: locationFormattedAddress,
        location_geopoint: locationGeopoint,
        location_id: locationId,
        location_locality: locationLocality,
        location_organization_id: locationOrganizationId,
        location_postal_code: locationPostalCode,
        location_specificity: locationSpecificity,
        location_status: locationStatus,
        location_title: locationTitle,
        location_tzid: locationTzId,
        needs_public_workforce: subscriptionNeedsPublicWorkforce,
        optin_type: subscriptionOptinType,
        organization_subscription_can_reschedule: canReschedule,
        organization_subscription_description: description,
        organization_subscription_groups: subscriptionGroups,
        organization_subscription_id: subscriptionId,
        ticket_id: id,
        ticket_status: status,
        title,
        ...ticket
    } = record;

    const latLng = locationGeopoint.split(',');
    const customer = assigneeId
        ? { email: assigneeEmail, first_name: assigneeFirstName, id: assigneeId, last_name: assigneeLastName }
        : null;

    return {
        ...ticket,
        assigned_customer_email: assigneeEmail,
        assigned_customer_id: assigneeId,
        assigned_customer_name: assigneeName,
        calendar_event: {
            end: calendarEventEnd || null,
            event_type: calendarEventType || null,
            id: calendarEventId || 0,
            start: calendarEventStart || null,
        },
        can_reschedule: canReschedule,
        customer,
        description,
        id,
        location: {
            administrative_area_level_1: locationAdministrativeAreaLevel1,
            administrative_area_level_2: locationAdministrativeAreaLevel2,
            country: locationCountry,
            date_created: locationDateCreated,
            date_updated: locationDateUpdated,
            formatted_address: locationFormattedAddress,
            id: locationId,
            latitude: parseFloat(latLng[0]),
            locality: locationLocality,
            longitude: parseFloat(latLng[1]),
            organization_id: locationOrganizationId,
            postal_code: locationPostalCode,
            specificity: locationSpecificity,
            status: locationStatus,
            title: locationTitle,
            tzid: locationTzId,
        },
        status,
        subscription: {
            can_reschedule: canReschedule,
            description,
            groups: subscriptionGroups,
            id: subscriptionId,
            is_double_optin: subscriptionIsDoubleOptin,
            needs_public_workforce: subscriptionNeedsPublicWorkforce,
            optin_type: subscriptionOptinType,
            title,
        },
        subscription_id: subscriptionId,
        title,
    };
};

export const searchAuditEventsSuccess = createAction(types.SEARCH_AUDIT_EVENTS_SUCCESS);
export const searchAuditEventsError = createAction(types.SEARCH_AUDIT_EVENTS_ERROR);
export const searchAuditEvents = createAction(
    types.SEARCH_AUDIT_EVENTS,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            const { limit, offset, sort_field: sortField, sort_order: sortOrder, ...data } = params;
            const config = {
                params: omitBy({ limit, offset, sort_field: sortField, sort_order: sortOrder }, (value) => value == null),
            };
            return gigwalk.client.post('v1/audit_events', data, config)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalizeResponse(resp, auditEventSchema);
                    dispatch(searchAuditEventsSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(searchAuditEventsError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const fetchCertificationSuccess = createAction(types.FETCH_CERTIFICATION_SUCCESS);
export const fetchCertificationError = createAction(types.FETCH_CERTIFICATION_ERROR);
export const fetchCertification = createAction(
    types.FETCH_CERTIFICATION,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => (
            gigwalk.client.get(`/v1/certifications/${params.certification_id}`)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalizeResponse(resp, certificationSchema);
                    dispatch(fetchCertificationSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(fetchCertificationError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export const fetchAllCertificationsSuccess = createAction(types.FETCH_ALL_CERTIFICATIONS_SUCCESS);
export const fetchAllCertificationsError = createAction(types.FETCH_ALL_CERTIFICATIONS_ERROR);
export const fetchAllCertifications = createAction(
    types.FETCH_ALL_CERTIFICATIONS,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            const { organization_id: orgId, ...urlParams } = params;
            const config = {
                params: omitBy(urlParams, (value) => value == null),
            };
            return gigwalk.client.get(`/v1/organizations/${orgId}/certifications`, config)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalizeResponse(resp, certificationSchema);
                    dispatch(fetchAllCertificationsSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(fetchAllCertificationsError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const assignCertificationsSuccess = createAction(types.ASSIGN_CERTIFICATIONS_SUCCESS);
export const assignCertificationsError = createAction(types.ASSIGN_CERTIFICATIONS_ERROR);
export const assignCertifications = createAction(
    types.ASSIGN_CERTIFICATIONS,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            const { organization_id: orgId, ...payload } = params;
            return gigwalk.client.post(`v1/organizations/${orgId}/certifications/bulk_assign`, payload)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalizeResponse(resp, certificationSchema);
                    dispatch(assignCertificationsSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(assignCertificationsError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const fetchCustomerSuccess = createAction(types.FETCH_CUSTOMER_SUCCESS);
export const fetchCustomerError = createAction(types.FETCH_CUSTOMER_ERROR);
export const fetchCustomer = createAction(
    types.FETCH_CUSTOMER,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => (
            gigwalk.client.get(`/v1/organizations/${params.organization_id}/customers/${params.customer_id}`)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalizeResponse(resp, customerSchema);
                    dispatch(fetchCustomerSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(fetchCustomerError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export const searchCustomersSuccess = createAction(types.SEARCH_CUSTOMERS_SUCCESS);
export const searchCustomersError = createAction(types.SEARCH_CUSTOMERS_ERROR);
export const searchCustomers = createAction(
    types.SEARCH_CUSTOMERS,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            const { csv, offset, limit, ...data } = params;
            const config = {
                params: omitBy({ csv, offset, limit }, (value) => value == null),
            };
            return gigwalk.client.post('/v1/search/customers', data, config)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalizeResponse(resp, customerSchema);
                    dispatch(searchCustomersSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(searchCustomersError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const inviteCustomersSuccess = createAction(types.INVITE_CUSTOMERS_SUCCESS);
export const inviteCustomersError = createAction(types.INVITE_CUSTOMERS_ERROR);
export const inviteCustomers = createAction(
    types.INVITE_CUSTOMERS,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => (
            gigwalk.client.post('/v1/signup/bulk', params)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalizeResponse(resp, customerSchema);
                    dispatch(inviteCustomersSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(inviteCustomersError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export const updateUserSuccess = createAction(types.UPDATE_USER_SUCCESS);
export const updateUserError = createAction(types.UPDATE_USER_ERROR);
export const updateUser = createAction(
    types.UPDATE_USER,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => (
            gigwalk.client.put('/v1/customer', params)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalizeResponse(resp, customerSchema);
                    dispatch(updateUserSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(updateUserError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export const updateCustomerSuccess = createAction(types.UPDATE_CUSTOMER_SUCCESS);
export const updateCustomerError = createAction(types.UPDATE_CUSTOMER_ERROR);
export const updateCustomer = createAction(
    types.UPDATE_CUSTOMER,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            const {
                customer_id: customerId,
                organization_id: orgId,
                ...data
            } = params;
            return gigwalk.client.put(`v1/organizations/${orgId}/customers/${customerId}`, data)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalizeResponse(resp, customerSchema);
                    dispatch(updateCustomerSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(updateCustomerError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const updateCustomersSuccess = createAction(types.UPDATE_CUSTOMERS_SUCCESS);
export const updateCustomersError = createAction(types.UPDATE_CUSTOMERS_ERROR);
export const updateCustomers = createAction(
    types.UPDATE_CUSTOMERS,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            const { organization_id: orgId, ...data } = params;
            return gigwalk.client.put(`v1/organizations/${orgId}/customers`, data)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalizeResponse(resp, {});
                    dispatch(updateCustomersSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(updateCustomersError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const fetchAllDatatypesSuccess = createAction(types.FETCH_ALL_DATATYPES_SUCCESS);
export const fetchAllDatatypesError = createAction(types.FETCH_ALL_DATATYPES_ERROR);
export const fetchAllDatatypes = createAction(
    types.FETCH_ALL_DATATYPES,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            const config = {
                params: omitBy(params, (value) => value == null),
            };
            return gigwalk.client.get('/v1/data_types', config)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalizeResponse(resp, dataTypeSchema);
                    dispatch(fetchAllDatatypesSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(fetchAllDatatypesError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const fetchDatatypeSuccess = createAction(types.FETCH_DATATYPE_SUCCESS);
export const fetchDatatypeError = createAction(types.FETCH_DATATYPE_ERROR);
export const fetchDatatype = createAction(
    types.FETCH_DATATYPE,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => (
            gigwalk.client.get(`/v1/data_types/${params.data_type_id}`)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalizeResponse(resp, dataTypeSchema);
                    dispatch(fetchDatatypeSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(fetchDatatypeError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export const createDatatypeSuccess = createAction(types.CREATE_DATATYPE_SUCCESS);
export const createDatatypeError = createAction(types.CREATE_DATATYPE_ERROR);
export const createDatatype = createAction(
    types.CREATE_DATATYPE,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            const { cid, dataType } = params;
            // @todo Address gigwalk-node queueing issue
            // Use cid to create a unique request. This will prevent the gigwalk client
            // from combining similar requests into one API call
            const config = {
                params: { ...(cid ? { cid } : undefined) },
            };
            return gigwalk.client.post('/v1/data_types', { ...dataType }, config)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalizeResponse(resp, dataTypeSchema);
                    dispatch(createDatatypeSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(createDatatypeError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const updateDatatypeSuccess = createAction(types.UPDATE_DATATYPE_SUCCESS);
export const updateDatatypeError = createAction(types.UPDATE_DATATYPE_ERROR);
export const updateDatatype = createAction(
    types.UPDATE_DATATYPE,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            const { data_type_id: dataTypeId, ...payload } = params;
            return gigwalk.client.put(`/v1/data_types/${dataTypeId}`, payload)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalizeResponse(resp, dataTypeSchema);
                    dispatch(updateDatatypeSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(updateDatatypeError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const addDatatypeAttachmentSuccess = createAction(types.ADD_DATATYPE_ATTACHMENT_SUCCESS, identity, (data: Object, meta: Object): Object => meta);
export const addDatatypeAttachmentError = createAction(types.ADD_DATATYPE_ATTACHMENT_ERROR);
export const addDatatypeAttachment = createAction(
    types.ADD_DATATYPE_ATTACHMENT,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            const { data_type_id: dataTypeId, ...payload } = params;
            return gigwalk.client.post(`/v1/data_types/${dataTypeId}/attachments`, payload)
                .then((resp: $AxiosXHR<APIResponse<Object>>) => {
                    const normalized = normalizeResponse(resp, {});
                    dispatch(addDatatypeAttachmentSuccess(normalized, { data_type_id: dataTypeId, attachment: payload }));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(addDatatypeAttachmentError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const deleteDatatypeAttachmentSuccess = createAction(types.DELETE_DATATYPE_ATTACHMENT_SUCCESS, identity, (data: Object, meta: Object): Object => meta);
export const deleteDatatypeAttachmentError = createAction(types.DELETE_DATATYPE_ATTACHMENT_ERROR);
export const deleteDatatypeAttachment = createAction(
    types.DELETE_DATATYPE_ATTACHMENT,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            const { data_type_id: dataTypeId, ...payload } = params;
            return gigwalk.client.delete(`/v1/data_types/${dataTypeId}/attachments`, { data: payload })
                .then((resp: $AxiosXHR<APIResponse<Object>>) => {
                    const normalized = normalizeResponse(resp, {});
                    dispatch(deleteDatatypeAttachmentSuccess(normalized, { data_type_id: dataTypeId, attachment: payload }));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(deleteDatatypeAttachmentError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const fetchGroupSuccess = createAction(types.FETCH_GROUP_SUCCESS);
export const fetchGroupError = createAction(types.FETCH_GROUP_ERROR);
export const fetchGroup = createAction(
    types.FETCH_GROUP,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => (
            gigwalk.client.get(`/v1/organizations/${params.organization_id}/groups/${params.group_id}`)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalizeResponse(resp, groupSchema);
                    dispatch(fetchGroupSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(fetchGroupError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export const getOrganizationGroupsSuccess = createAction(types.GET_ORGANIZATION_GROUPS_SUCCESS);
export const getOrganizationGroupsError = createAction(types.GET_ORGANIZATION_GROUPS_ERROR);
export const getOrganizationGroups = createAction(
    types.GET_ORGANIZATION_GROUPS,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            const { organization_id: orgId, ...urlParams } = params;
            const config = {
                params: omitBy(urlParams, (value) => value == null),
            };
            return gigwalk.client.get(`/v1/organizations/${orgId}/groups`, config)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalizeResponse(resp, groupSchema);
                    dispatch(getOrganizationGroupsSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(getOrganizationGroupsError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const createGroupSuccess = createAction(types.CREATE_GROUP_SUCCESS);
export const createGroupError = createAction(types.CREATE_GROUP_ERROR);
export const createGroup = createAction(
    types.CREATE_GROUP,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            const { organization_id: orgId, ...payload } = params;
            return gigwalk.client.post(`/v1/organizations/${orgId}/groups`, payload)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalizeResponse(resp, groupSchema);
                    dispatch(createGroupSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(createGroupError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const addGroupMemberSuccess = createAction(types.ADD_GROUP_MEMBER_SUCCESS);
export const addGroupMemberError = createAction(types.ADD_GROUP_MEMBER_ERROR);
export const addGroupMember = createAction(
    types.ADD_GROUP_MEMBER,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            const { group_id: groupId, ...payload } = params;
            return gigwalk.client.post(`/v1/groups/${groupId}/customers`, payload)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    // @todo This endpoint returns the customer that was added
                    const normalized = normalize(resp.data.data, [groupSchema]);
                    dispatch(addGroupMemberSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(addGroupMemberError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const addGroupMembersSuccess = createAction(types.ADD_GROUP_MEMBERS_SUCCESS);
export const addGroupMembersError = createAction(types.ADD_GROUP_MEMBERS_ERROR);
export const addGroupMembers = createAction(
    types.ADD_GROUP_MEMBERS,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            const { group_id: groupId, ...payload } = params;
            return gigwalk.client.post(`/v1/groups/${params.group_id}/customers`, payload)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalizeResponse(resp, groupSchema);
                    dispatch(addGroupMembersSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(addGroupMembersError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const searchGroupsSuccess = createAction(types.SEARCH_GROUPS_SUCCESS);
export const searchGroupsError = createAction(types.SEARCH_GROUPS_ERROR);
export const searchGroups = createAction(
    types.SEARCH_GROUPS,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            const { organization_id: orgId, ...urlParams } = params;
            const config = {
                params: omitBy(urlParams, (value) => value == null),
            };
            return gigwalk.client.get(`/v1/organizations/${orgId}/search/groups`, config)
                .then((resp: $AxiosXHR<Object>) => {
                    const searchResults = resp.data.hits ? resp.data.hits.hits || [] : [];
                    const data = searchResults.map((record: { _source: Object }) => record._source);
                    const normalized = normalize(data, [groupSchema]);
                    dispatch(searchGroupsSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(searchGroupsError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const fetchLocationListSuccess = createAction(types.FETCH_LOCATION_LIST_SUCCESS);
export const fetchLocationListError = createAction(types.FETCH_LOCATION_LIST_ERROR);
export const fetchLocationList = createAction(
    types.FETCH_LOCATION_LIST,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            const { location_list_id: locationListId, ...urlParams } = params;
            const config = {
                params: omitBy(urlParams, (value) => value == null),
            };
            return gigwalk.client.get(`/v1/location_lists/${locationListId}`, { config })
                .then((resp: $AxiosXHR<APIResponse<Object>>) => {
                    const normalized = normalizeResponse(resp, locationListSchema);
                    dispatch(fetchLocationListSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(fetchLocationListError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const getLocationListItemsSuccess = createAction(types.GET_LOCATION_LIST_ITEMS_SUCCESS);
export const getLocationListItemsError = createAction(types.GET_LOCATION_LIST_ITEMS_ERROR);
export const getLocationListItems = createAction(
    types.GET_LOCATION_LIST_ITEMS,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            const { location_list_id: locationListId, ...urlParams } = params;
            const config = {
                params: omitBy(urlParams, (value) => value == null),
            };
            return gigwalk.client.get(`/v1/location_lists/${locationListId}/locations`, { config })
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalizeRelations(resp.data.data);
                    dispatch(getLocationListItemsSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(getLocationListItemsError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const createLocationListSuccess = createAction(types.CREATE_LOCATION_LIST_SUCCESS);
export const createLocationListError = createAction(types.CREATE_LOCATION_LIST_ERROR);
export const createLocationList = createAction(
    types.CREATE_LOCATION_LIST,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            const { organization_id: orgId, ...payload } = params;
            return gigwalk.client.post(`/v1/organizations/${orgId}/location_lists`, payload)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalizeResponse(resp, locationListSchema);
                    dispatch(createLocationListSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(createLocationListError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const updateLocationListSuccess = createAction(types.UPDATE_LOCATION_LIST_SUCCESS);
export const updateLocationListError = createAction(types.UPDATE_LOCATION_LIST_ERROR);
export const updateLocationList = createAction(
    types.UPDATE_LOCATION_LIST,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            const { location_list_id: locationListId, ...payload } = params;
            return gigwalk.client.put(`/v1/organization_location_lists/${locationListId}`, payload)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalizeResponse(resp, locationListSchema);
                    dispatch(updateLocationListSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(updateLocationListError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const addLocationListItemSuccess = createAction(types.ADD_LOCATION_LIST_ITEM_SUCCESS);
export const addLocationListItemError = createAction(types.ADD_LOCATION_LIST_ITEM_ERROR);
export const addLocationListItem = createAction(
    types.ADD_LOCATION_LIST_ITEM,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            const { location_list_id: locationListId, locations } = params;
            const payload = locations.map((id: number) => ({ id }));
            return gigwalk.client.post(`/v1/location_lists/${locationListId}/locations`, payload)
                .then((resp: $AxiosXHR<APIResponse<Object>>) => {
                    const normalized = normalizeResponse(resp, locationListSchema);
                    dispatch(addLocationListItemSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(addLocationListItemError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const removeLocationListItemSuccess = createAction(types.REMOVE_LOCATION_LIST_ITEM_SUCCESS);
export const removeLocationListItemError = createAction(types.REMOVE_LOCATION_LIST_ITEM_ERROR);
export const removeLocationListItem = createAction(
    types.REMOVE_LOCATION_LIST_ITEM,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            const { location_list_id: locationListId, ...payload } = params;
            return gigwalk.client.put(`/v1/location_lists/${locationListId}/locations`, { action: 'remove', ...payload })
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalizeResponse(resp, {});
                    dispatch(removeLocationListItemSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(removeLocationListItemError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const uploadLocationListSuccess = createAction(types.UPLOAD_LOCATION_LIST_SUCCESS);
export const uploadLocationListError = createAction(types.UPLOAD_LOCATION_LIST_ERROR);
export const uploadLocationList = createAction(
    types.UPLOAD_LOCATION_LIST,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            const { organization_id: orgId, ...payload } = params;
            return gigwalk.client.post(`/v1/organizations/${orgId}/location_lists/upload`, payload)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalizeResponse(resp, locationListSchema);
                    dispatch(uploadLocationListSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(uploadLocationListError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const getLocationListUploadSuccess = createAction(types.GET_LOCATION_LIST_UPLOAD_SUCCESS);
export const getLocationListUploadError = createAction(types.GET_LOCATION_LIST_UPLOAD_ERROR);
export const getLocationListUpload = createAction(
    types.GET_LOCATION_LIST_UPLOAD,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => (
            gigwalk.client.get(`/v1/location_lists/${params.location_list_id}/upload`)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalizeResponse(resp, locationListSchema);
                    dispatch(getLocationListUploadSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(getLocationListUploadError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export const getUnresolvedLocationsSuccess = createAction(types.GET_UNRESOLVED_LOCATIONS_SUCCESS);
export const getUnresolvedLocationsError = createAction(types.GET_UNRESOLVED_LOCATIONS_ERROR);
export const getUnresolvedLocations = createAction(
    types.GET_UNRESOLVED_LOCATIONS,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => (
            gigwalk.client.get(`/v1/location_lists/${params.location_list_id}/upload/${params.file_upload_id}/unresolved_locations`)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalizeResponse(resp, unresolvedLocationSchema);
                    dispatch(getUnresolvedLocationsSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(getUnresolvedLocationsError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export const createLocationSuccess = createAction(types.CREATE_LOCATION_SUCCESS);
export const createLocationError = createAction(types.CREATE_LOCATION_ERROR);
export const createLocation = createAction(
    types.CREATE_LOCATION,
    (params: Object): Function => (
        (dispatch: Dispatch<*>): Promise<any> => {
            const { organization_id: orgId, ...payload } = params;
            return gigwalk.client.post(`/v1/organizations/${orgId}/locations/geocode`, payload)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalizeResponse(resp, locationSchema);
                    dispatch(createLocationSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(createLocationError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const listNotificationsSuccess = createAction(types.LIST_NOTIFICATIONS_SUCCESS);
export const listNotificationsError = createAction(types.LIST_NOTIFICATIONS_ERROR);
export const listNotifications = createAction(
    types.LIST_NOTIFICATIONS,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            const { limit, offset, sort_field: sortField, sort_order: sortOrder, ...data } = params;
            const config = {
                params: omitBy({ limit, offset, sort_field: sortField, sort_order: sortOrder }, (value) => value == null),
            };
            return gigwalk.client.post('v1/notifications', data, config)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalizeResponse(resp, notificationSchema);
                    dispatch(listNotificationsSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(listNotificationsError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const updateNotificationSuccess = createAction(types.UPDATE_NOTIFICATION_SUCCESS);
export const updateNotificationError = createAction(types.UPDATE_NOTIFICATION_ERROR);
export const updateNotification = createAction(
    types.UPDATE_NOTIFICATION,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            const { notification_id: id, ...data } = params;
            return gigwalk.client.put(`v1/notifications/${id}`, data)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalizeResponse(resp, notificationSchema);
                    dispatch(updateNotificationSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(updateNotificationError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const fetchOrganizationSuccess = createAction(types.FETCH_ORGANIZATION_SUCCESS);
export const fetchOrganizationError = createAction(types.FETCH_ORGANIZATION_ERROR);
export const fetchOrganization = createAction(
    types.FETCH_ORGANIZATION,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => (
            gigwalk.client.get(`/v1/organizations/${params.organization_id}`)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalizeResponse(resp, organizationSchema);
                    dispatch(fetchOrganizationSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(fetchOrganizationError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export const getBalanceSuccess = createAction(types.GET_BALANCE_SUCCESS);
export const getBalanceError = createAction(types.GET_BALANCE_ERROR);
export const getBalance = createAction(
    types.GET_BALANCE,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => (
            gigwalk.client.get(`/v1/organizations/${params.organization_id}/payments`)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const data = resp.data.data.map((entity) => ({
                        id: params.organization_id,
                        ...entity,
                    }));
                    const normalized = normalize(data, [organizationSchema]);
                    dispatch(getBalanceSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(getBalanceError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export const addFundsSuccess = createAction(types.ADD_FUNDS_SUCCESS);
export const addFundsError = createAction(types.ADD_FUNDS_ERROR);
export const addFunds = createAction(
    types.ADD_FUNDS,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<void> => {
            const { organization_id: orgId, ...payload } = params;
            return gigwalk.client.post(`/v1/organizations/${orgId}/payments`, payload)
                .then(() => {
                    dispatch(addFundsSuccess());
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(addFundsError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const searchOrganizationsSuccess = createAction(types.SEARCH_ORGANIZATIONS_SUCCESS);
export const searchOrganizationsError = createAction(types.SEARCH_ORGANIZATIONS_ERROR);
export const searchOrganizations = createAction(
    types.SEARCH_ORGANIZATIONS,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            const config = {
                params: omitBy(params, (value) => value == null),
            };
            return gigwalk.client.get('/v1/organizations/search', config)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalizeResponse(resp, organizationSchema);
                    dispatch(searchOrganizationsSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(searchOrganizationsError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const searchPayoutsSuccess = createAction(types.SEARCH_PAYOUTS_SUCCESS);
export const searchPayoutsError = createAction(types.SEARCH_PAYOUTS_ERROR);
export const searchPayouts = createAction(
    types.SEARCH_PAYOUTS,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            const { limit, offset, sort_field: sortField, sort_order: sortOrder, ...data } = params;
            const config = {
                params: omitBy({ limit, offset, sort_field: sortField, sort_order: sortOrder }, (value) => value == null),
            };
            // @todo Have BE change this from v1/payouts/ -> v1/payouts
            return gigwalk.client.post('v1/payouts/', data, config)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalizeResponse(resp, payoutSchema);
                    dispatch(searchPayoutsSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(searchPayoutsError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const fetchSubscriptionSuccess = createAction(types.FETCH_SUBSCRIPTION_SUCCESS);
export const fetchSubscriptionError = createAction(types.FETCH_SUBSCRIPTION_ERROR);
export const fetchSubscription = createAction(
    types.FETCH_SUBSCRIPTION,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => (
            gigwalk.client.get(`/v1/organization_subscriptions/${params.organization_subscription_id}`)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalizeResponse(resp, subscriptionSchema);
                    normalized.entities.dataTypes = resp.data._metadata.data_types;
                    dispatch(fetchSubscriptionSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(fetchSubscriptionError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export const createSubscriptionSuccess = createAction(types.CREATE_SUBSCRIPTION_SUCCESS);
export const createSubscriptionError = createAction(types.CREATE_SUBSCRIPTION_ERROR);
export const createSubscription = createAction(
    types.CREATE_SUBSCRIPTION,
    (params: Object) => (
        (dispatch: Dispatch<any>): Promise<any> => {
            const { organization_id: orgId, ...payload } = params;
            return gigwalk.client.post(`/v1/organizations/${orgId}/subscriptions`, payload)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalizeResponse(resp, subscriptionSchema);
                    dispatch(createSubscriptionSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(createSubscriptionError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const updateSubscriptionSuccess = createAction(types.UPDATE_SUBSCRIPTION_SUCCESS);
export const updateSubscriptionError = createAction(types.UPDATE_SUBSCRIPTION_ERROR);
export const updateSubscription = createAction(
    types.UPDATE_SUBSCRIPTION,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            const { organization_subscription_id: subscriptionId, ...payload } = params;
            return gigwalk.client.put(`/v1/organization_subscriptions/${subscriptionId}`, payload)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalizeResponse(resp, subscriptionSchema);
                    normalized.entities.dataTypes = resp.data._metadata.data_types;
                    dispatch(updateSubscriptionSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(updateSubscriptionError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const requestProjectReviewSuccess = createAction(types.REQUEST_PROJECT_REVIEW_SUCCESS);
export const requestProjectReviewError = createAction(types.REQUEST_PROJECT_REVIEW_ERROR);
export const requestProjectReview = createAction(
    types.REQUEST_PROJECT_REVIEW,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<void> => (
            gigwalk.client.post('/v1/self_service/request_project_review', params)
                .then(() => {
                    dispatch(requestProjectReviewSuccess());
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(requestProjectReviewError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export const searchSubscriptionsSuccess = createAction(types.SEARCH_SUBSCRIPTIONS_SUCCESS);
export const searchSubscriptionsError = createAction(types.SEARCH_SUBSCRIPTIONS_ERROR);
export const searchSubscriptions = createAction(
    types.SEARCH_SUBSCRIPTIONS,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            const { offset, limit, ...data } = params;
            const config = {
                params: omitBy({ offset, limit }, (value) => value == null),
            };
            return gigwalk.client.post('/v1/search/subscriptions', data, config)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    // Note: schema is little different for search, but this should still work
                    const normalized = normalizeResponse(resp, subscriptionSchema);
                    dispatch(searchSubscriptionsSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(searchSubscriptionsError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const deleteSubscriptionSuccess = createAction(types.DELETE_SUBSCRIPTION_SUCCESS);
export const deleteSubscriptionError = createAction(types.DELETE_SUBSCRIPTION_ERROR);
export const deleteSubscription = createAction(
    types.DELETE_SUBSCRIPTION,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => (
            gigwalk.client.delete(`/v1/organizations/${params.organization_id}/subscriptions/${params.organization_subscription_id}`)
                .then(() => {
                    const id = params.organization_subscription_id;
                    const normalized = normalize([id], [{}]);
                    dispatch(deleteSubscriptionSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(deleteSubscriptionError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export const fetchTemplateSuccess = createAction(types.FETCH_TEMPLATE_SUCCESS);
export const fetchTemplateError = createAction(types.FETCH_TEMPLATE_ERROR);
export const fetchTemplate = createAction(
    types.FETCH_TEMPLATE,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => (
            gigwalk.client.get(`/v1/templates/${params.template_id}`)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalizeResponse(resp, templateSchema);
                    dispatch(fetchTemplateSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(fetchTemplateError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export const fetchTicketSuccess = createAction(types.FETCH_TICKET_SUCCESS);
export const fetchTicketError = createAction(types.FETCH_TICKET_ERROR);
export const fetchTicket = createAction(
    types.FETCH_TICKET,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => (
            gigwalk.client.get(`/v1/tickets/${params.ticket_id}`)
                .then((resp: $AxiosXHR<APIResponse<Object>>) => {
                    const { data } = resp.data;
                    const normalized = normalize(resp.data.data, ticketSchema);
                    normalized.entities.dataTypes = data.data_type_map;
                    normalized.entities.templates = data.template_map;
                    normalized.entities.targets = Object.entries(data.observation_target_map)
                        .reduce((map, [id, title]) => ({
                            ...map,
                            [id]: { id: parseInt(id, 10), title },
                        }), {});
                    dispatch(fetchTicketSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(fetchTicketError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export const searchTicketsSuccess = createAction(types.SEARCH_TICKETS_SUCCESS);
export const searchTicketsError = createAction(types.SEARCH_TICKETS_ERROR);
export const searchTickets = createAction(
    types.SEARCH_TICKETS,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            const { offset, limit, ...data } = params;
            const config = {
                params: omitBy({ offset, limit }, (value) => value == null),
            };
            return gigwalk.client.post('/v1/search/tickets', data, config)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    // Results returned by this endpoint now follow the normal ticket schema,
                    // but we should check the response just in case we are running against an
                    // older API version
                    let results = resp.data.data || [];
                    if (results[0] && results[0].hasOwnProperty('location_id')) {
                        results = results.map(mapESRecordToTicket);
                    }

                    const normalized = normalize(results, [ticketSchema]);
                    normalized.metadata = resp.data._metadata;
                    dispatch(searchTicketsSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(searchTicketsError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const updateTicketsSuccess = createAction(types.UPDATE_TICKETS_SUCCESS);
export const updateTicketsError = createAction(types.UPDATE_TICKETS_ERROR);
export const updateTickets = createAction(
    types.UPDATE_TICKETS,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => (
            gigwalk.client.put('/v1/tickets', params)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalizeResponse(resp, ticketSchema);
                    dispatch(updateTicketsSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(updateTicketsError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export const submitTicketSuccess = createAction(types.SUBMIT_TICKET_SUCCESS);
export const submitTicketError = createAction(types.SUBMIT_TICKET_ERROR);
export const submitTicket = createAction(
    types.SUBMIT_TICKET,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => (
            gigwalk.client.post(`/v1/tickets/${params.ticket_id}/submit`)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    // API only responds with the ticket id, so we'll need to fetch the ticket
                    // again to get updated info.
                    const normalized = normalize(resp.data.data, [{}]);
                    normalized.original = resp.data;
                    dispatch(submitTicketSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(submitTicketError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export const createDataItemSuccess = createAction(types.CREATE_DATA_ITEM_SUCCESS);
export const createDataItemError = createAction(types.CREATE_DATA_ITEM_ERROR);
export const createDataItem = createAction(
    types.CREATE_DATA_ITEM,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            const { ticket_id: ticketId, ...payload } = params;
            return gigwalk.client.post(`/v1/tickets/${ticketId}/data_items`, payload)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    // API responds with the data_item id, so we'll need to fetch the ticket
                    // again to get updated info.
                    const normalized = normalizeResponse(resp, {});
                    dispatch(createDataItemSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(createDataItemError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const deleteDataItemSuccess = createAction(types.DELETE_DATA_ITEM_SUCCESS);
export const deleteDataItemError = createAction(types.DELETE_DATA_ITEM_ERROR);
export const deleteDataItem = createAction(
    types.DELETE_DATA_ITEM,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => (
            gigwalk.client.delete(`/v1/tickets/${params.ticket_id}/data_items/${params.data_item_id}`)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    // API responds with the data_item id, so we'll need to fetch the ticket
                    // again to get updated info.
                    const normalized = normalizeResponse(resp, {});
                    dispatch(deleteDataItemSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(deleteDataItemError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export const getApplicantsSuccess = createAction(types.GET_APPLICANTS_SUCCESS, identity, (data: Object, meta: Object): Object => meta);
export const getApplicantsError = createAction(types.GET_APPLICANTS_ERROR);
export const getApplicants = createAction(
    types.GET_APPLICANTS,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            const { limit, offset } = params;
            const config = {
                params: omitBy({ offset, limit }, (value) => value == null),
            };
            return gigwalk.client.get(`/v1/tickets/${params.ticket_id}/applicants`, config)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    // This endpoint returns a list of applicants for a ticket, as well as some information
                    // about each customer's application. Since a customer can apply to multiple tickets,
                    // it's desirable to normalize the data such that each customer has an `applications`
                    // attribute instead of the singular `application` returned in the response
                    // @todo Investigate moving this logic to schema definition using processStrategy option
                    const data = resp.data.data.map((customer: Object) => {
                        const { application, ...other } = customer;
                        if (application) {
                            return {
                                ...other,
                                applications: [{
                                    ...application,
                                    cid: `${customer.id}_${params.ticket_id}`, // create a client id that will uniquely identify this application
                                    ticket: params.ticket_id,
                                }],
                            };
                        }
                        return customer;
                    });
                    const normalized = normalize(data, [customerSchema]);
                    normalized.original = resp.data;
                    dispatch(getApplicantsSuccess(normalized, { ticket_id: params.ticket_id }));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(getApplicantsError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const updateApplicationSuccess = createAction(types.UPDATE_APPLICATION_SUCCESS);
export const updateApplicationError = createAction(types.UPDATE_APPLICATION_ERROR);
export const updateApplication = createAction(
    types.UPDATE_APPLICATION,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            const { ticket_id: ticketId, ...data } = params;
            return gigwalk.client.put(`/v1/tickets/${ticketId}/applicants`, data)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    // @todo Find and update application for the customer
                    const normalized = normalize(resp.data.data, [{}]);
                    dispatch(updateApplicationSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(updateApplicationError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const getTicketEventsSuccess = createAction(types.GET_TICKET_EVENTS_SUCCESS, identity, (data: Object, meta: Object): Object => meta);
export const getTicketEventsError = createAction(types.GET_TICKET_EVENTS_ERROR);
export const getTicketEvents = createAction(
    types.GET_TICKET_EVENTS,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            const { limit, offset } = params;
            const config = {
                params: omitBy({ offset, limit }, (value) => value == null),
            };
            return gigwalk.client.get(`/v1/tickets/${params.ticket_id}/events`, config)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalizeResponse(resp, {});
                    dispatch(getTicketEventsSuccess(normalized, { ticket_id: params.ticket_id }));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(getTicketEventsError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const createTicketEventSuccess = createAction(types.CREATE_TICKET_EVENT_SUCCESS, identity, (data: Object, meta: Object): Object => meta);
export const createTicketEventError = createAction(types.CREATE_TICKET_EVENT_ERROR);
export const createTicketEvent = createAction(
    types.CREATE_TICKET_EVENT,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            const { ticket_id: ticketId, ...payload } = params;
            return gigwalk.client.post(`/v1/tickets/${ticketId}/events`, payload)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalizeResponse(resp, {});
                    dispatch(createTicketEventSuccess(normalized, { ticket_id: ticketId }));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(createTicketEventError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const deleteTicketEventSuccess = createAction(types.DELETE_TICKET_EVENT_SUCCESS, identity, (data: Object, meta: Object): Object => meta);
export const deleteTicketEventError = createAction(types.DELETE_TICKET_EVENT_ERROR);
export const deleteTicketEvent = createAction(
    types.DELETE_TICKET_EVENT,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<void> => (
            gigwalk.client.delete(`/v1/ticket_events/${params.ticket_event_id}`)
                .then(() => {
                    dispatch(deleteTicketEventSuccess(null, { ticket_event_id: params.ticket_event_id }));
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(deleteTicketEventError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export const createApplicationSuccess = createAction(types.CREATE_APPLICATION_SUCCESS);
export const createApplicationError = createAction(types.CREATE_APPLICATION_ERROR);
export const createApplication = createAction(
    types.CREATE_APPLICATION,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            const { ticket_id: ticketId, ...payload } = params;
            return gigwalk.client.post(`/v1/tickets/${ticketId}/applicants`, payload)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    // @todo Add application for the customer
                    const normalized = normalizeResponse(resp, {});
                    dispatch(createApplicationSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(createApplicationError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const withdrawApplicationSuccess = createAction(types.WITHDRAW_APPLICATION_SUCCESS);
export const withdrawApplicationError = createAction(types.WITHDRAW_APPLICATION_ERROR);
export const withdrawApplication = createAction(
    types.WITHDRAW_APPLICATION,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => (
            gigwalk.client.delete(`/v1/tickets/${params.ticket_id}/applicants`)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    // @todo Find and remove application for the customer
                    const normalized = normalize(resp.data.data, [{}]);
                    dispatch(withdrawApplicationSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(withdrawApplicationError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export const addTicketTagsSuccess = createAction(types.ADD_TICKET_TAGS_SUCCESS);
export const addTicketTagsError = createAction(types.ADD_TICKET_TAGS_ERROR);
export const addTicketTags = createAction(
    types.ADD_TICKET_TAGS,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            const { ticket_id: ticketId, ...payload } = params;
            return gigwalk.client.post(`/v1/tickets/${ticketId}/tags`, payload)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalizeResponse(resp, ticketSchema);
                    dispatch(addTicketTagsSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(addTicketTagsError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const removeTicketTagSuccess = createAction(types.REMOVE_TICKET_TAG_SUCCESS, identity, (data: Object, meta: Object): Object => meta);
export const removeTicketTagError = createAction(types.REMOVE_TICKET_TAG_ERROR);
export const removeTicketTag = createAction(
    types.REMOVE_TICKET_TAG,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => (
            gigwalk.client.delete(`/v1/tickets/${params.ticket_id}/tags/${params.tag_id}`)
                .then(() => {
                    dispatch(removeTicketTagSuccess(null, { tag_id: params.tag_id, ticket_id: params.ticket_id }));
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(removeTicketTagError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export const createWorkerRatingSuccess = createAction(types.CREATE_WORKER_RATING_SUCCESS);
export const createWorkerRatingError = createAction(types.CREATE_WORKER_RATING_ERROR);
export const createWorkerRating = createAction(
    types.CREATE_WORKER_RATING,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            const { ticket_id: ticketId, ...payload } = params;
            return gigwalk.client.post(`/v1/ratings/ticket/${ticketId}`, payload)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalizeResponse(resp, {});
                    dispatch(createWorkerRatingSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(createWorkerRatingError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const updateWorkerRatingSuccess = createAction(types.UPDATE_WORKER_RATING_SUCCESS);
export const updateWorkerRatingError = createAction(types.UPDATE_WORKER_RATING_ERROR);
export const updateWorkerRating = createAction(
    types.UPDATE_WORKER_RATING,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            const {
                customer_id: customerId,
                ticket_id: ticketId,
                ...payload
            } = params;
            return gigwalk.client.put(`/v1/ratings/ticket/${ticketId}/worker/${customerId}`, payload)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalizeResponse(resp, {});
                    dispatch(updateWorkerRatingSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(updateWorkerRatingError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const createTagSuccess = createAction(types.CREATE_TAG_SUCCESS);
export const createTagError = createAction(types.CREATE_TAG_ERROR);
export const createTag = createAction(
    types.CREATE_TAG,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => (
            gigwalk.client.post('/v1/tags', params)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalizeResponse(resp, tagSchema);
                    dispatch(createTagSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(createTagError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export const searchTagsSuccess = createAction(types.SEARCH_TAGS_SUCCESS);
export const searchTagsError = createAction(types.SEARCH_TAGS_ERROR);
export const searchTags = createAction(
    types.CREATE_TAG,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            const { offset, limit, ...data } = params;
            const config = {
                params: omitBy({ offset, limit }, (value) => value == null),
            };
            return gigwalk.client.post('/v1/search/tags', data, config)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalizeResponse(resp, tagSchema);
                    dispatch(searchTagsSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(searchTagsError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export default {
    searchAuditEvents,
    searchAuditEventsError,
    searchAuditEventsSuccess,
    fetchCertification,
    fetchCertificationError,
    fetchCertificationSuccess,
    fetchAllCertifications,
    fetchAllCertificationsError,
    fetchAllCertificationsSuccess,
    assignCertifications,
    assignCertificationsError,
    assignCertificationsSuccess,
    fetchCustomer,
    fetchCustomerError,
    fetchCustomerSuccess,
    searchCustomers,
    searchCustomersError,
    searchCustomersSuccess,
    inviteCustomers,
    inviteCustomersError,
    inviteCustomersSuccess,
    updateUser,
    updateUserError,
    updateUserSuccess,
    updateCustomer,
    updateCustomerError,
    updateCustomerSuccess,
    updateCustomers,
    updateCustomersError,
    updateCustomersSuccess,
    fetchAllDatatypes,
    fetchAllDatatypesError,
    fetchAllDatatypesSuccess,
    fetchDatatype,
    fetchDatatypeError,
    fetchDatatypeSuccess,
    createDatatype,
    createDatatypeError,
    createDatatypeSuccess,
    updateDatatype,
    updateDatatypeError,
    updateDatatypeSuccess,
    addDatatypeAttachment,
    addDatatypeAttachmentError,
    addDatatypeAttachmentSuccess,
    deleteDatatypeAttachment,
    deleteDatatypeAttachmentError,
    deleteDatatypeAttachmentSuccess,
    fetchGroup,
    fetchGroupError,
    fetchGroupSuccess,
    getOrganizationGroups,
    getOrganizationGroupsError,
    getOrganizationGroupsSuccess,
    createGroup,
    createGroupError,
    createGroupSuccess,
    addGroupMember,
    addGroupMemberError,
    addGroupMemberSuccess,
    addGroupMembers,
    addGroupMembersError,
    addGroupMembersSuccess,
    searchGroups,
    searchGroupsError,
    searchGroupsSuccess,
    fetchLocationList,
    fetchLocationListError,
    fetchLocationListSuccess,
    getLocationListItems,
    getLocationListItemsError,
    getLocationListItemsSuccess,
    createLocationList,
    createLocationListError,
    createLocationListSuccess,
    updateLocationList,
    updateLocationListError,
    updateLocationListSuccess,
    addLocationListItem,
    addLocationListItemError,
    addLocationListItemSuccess,
    removeLocationListItem,
    removeLocationListItemError,
    removeLocationListItemSuccess,
    uploadLocationList,
    uploadLocationListError,
    uploadLocationListSuccess,
    getLocationListUpload,
    getLocationListUploadError,
    getLocationListUploadSuccess,
    getUnresolvedLocations,
    getUnresolvedLocationsError,
    getUnresolvedLocationsSuccess,
    createLocation,
    createLocationError,
    createLocationSuccess,
    listNotifications,
    listNotificationsError,
    listNotificationsSuccess,
    updateNotification,
    updateNotificationError,
    updateNotificationSuccess,
    fetchOrganization,
    fetchOrganizationError,
    fetchOrganizationSuccess,
    getBalance,
    getBalanceError,
    getBalanceSuccess,
    addFunds,
    addFundsError,
    addFundsSuccess,
    searchOrganizations,
    searchOrganizationsError,
    searchOrganizationsSuccess,
    searchPayouts,
    searchPayoutsError,
    searchPayoutsSuccess,
    fetchSubscription,
    fetchSubscriptionError,
    fetchSubscriptionSuccess,
    createSubscription,
    createSubscriptionError,
    createSubscriptionSuccess,
    updateSubscription,
    updateSubscriptionError,
    updateSubscriptionSuccess,
    requestProjectReview,
    requestProjectReviewError,
    requestProjectReviewSuccess,
    searchSubscriptions,
    searchSubscriptionsError,
    searchSubscriptionsSuccess,
    deleteSubscription,
    deleteSubscriptionError,
    deleteSubscriptionSuccess,
    fetchTemplate,
    fetchTemplateError,
    fetchTemplateSuccess,
    fetchTicket,
    fetchTicketError,
    fetchTicketSuccess,
    searchTickets,
    searchTicketsError,
    searchTicketsSuccess,
    updateTickets,
    updateTicketsError,
    updateTicketsSuccess,
    submitTicket,
    submitTicketError,
    submitTicketSuccess,
    createDataItem,
    createDataItemError,
    createDataItemSuccess,
    deleteDataItem,
    deleteDataItemError,
    deleteDataItemSuccess,
    getApplicants,
    getApplicantsError,
    getApplicantsSuccess,
    updateApplication,
    updateApplicationError,
    updateApplicationSuccess,
    getTicketEvents,
    getTicketEventsError,
    getTicketEventsSuccess,
    createTicketEvent,
    createTicketEventError,
    createTicketEventSuccess,
    deleteTicketEvent,
    deleteTicketEventError,
    deleteTicketEventSuccess,
    createApplication,
    createApplicationError,
    createApplicationSuccess,
    withdrawApplication,
    withdrawApplicationError,
    withdrawApplicationSuccess,
    addTicketTags,
    addTicketTagsError,
    addTicketTagsSuccess,
    removeTicketTag,
    removeTicketTagError,
    removeTicketTagSuccess,
    createWorkerRating,
    createWorkerRatingError,
    createWorkerRatingSuccess,
    updateWorkerRating,
    updateWorkerRatingError,
    updateWorkerRatingSuccess,
    createTag,
    createTagError,
    createTagSuccess,
    searchTags,
    searchTagsError,
    searchTagsSuccess,
};
