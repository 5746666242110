// @flow
// $FlowIssue need to update to a more recent flow version
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import canUseDom from 'can-use-dom';
import PluginsEditor from 'draft-js-plugins-editor';
import createInlineToolbarPlugin, { Separator } from 'draft-js-inline-toolbar-plugin';
import createLinkPlugin from 'draft-js-anchor-plugin';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import { EditorState, convertFromRaw } from 'draft-js';
import {
    ItalicButton,
    BoldButton,
    UnderlineButton,
    HeadlineTwoButton,
    HeadlineThreeButton,
    UnorderedListButton,
    OrderedListButton,
    BlockquoteButton,
} from 'draft-js-buttons';
import inlineToolbarTheme from './InlineToolbarPlugin.scss';
// import sanitize from '../../../browser/shared/util/gigwalkSanitize';

type Props = {
    className?: string,
    id: string,
    inputRef: Function | Object,
    onChange?: (value: string) => void,
    placeholder?: string,
    value?: string,
};

const linkPlugin = createLinkPlugin({ linkTarget: '_blank' });
const inlineToolbarPlugin = createInlineToolbarPlugin({
    theme: {
        buttonStyles: inlineToolbarTheme,
        toolbarStyles: inlineToolbarTheme,
    },
});
const { InlineToolbar } = inlineToolbarPlugin;
const { LinkButton } = linkPlugin;
const plugins = [inlineToolbarPlugin, linkPlugin];

// Need to specify a custom entityStyleFn for links in order to set the target attribute
// See https://github.com/draft-js-plugins/draft-js-plugins/issues/1038
const stateToHTMLOptions = {
    entityStyleFn: (entity) => {
        const entityType = entity.get('type').toLowerCase();
        if (entityType === 'link') {
            const data = entity.getData();
            return {
                element: 'a',
                attributes: {
                    href: data.url,
                    target: '_blank',
                },
            };
        }
    },
};

// Construct an empty ContentState object that will have the same key on both
// server and client. This should prevent any SSR issues.
const emptyContentState = convertFromRaw({
    blocks: [{
        depth: 0,
        entityRanges: [],
        inlineStyleRanges: [],
        key: 'empty',
        text: '',
        type: 'unstyled',
    }],
    entityMap: {},
});

export default function EditorInput(props: Props) {
    const {
        className,
        inputRef,
        placeholder,
        onChange,
        value,
        ...editorProps
    } = props;

    const [editorState, setEditorState] = useState(() => {
        const contentState = value && canUseDom ? stateFromHTML(value) : emptyContentState;
        return EditorState.createWithContent(contentState);
    });

    useEffect(() => {
        // Don't use props to compare current and next values!! We are trying to determine
        // if value changed by means outside the user's control (for example, a state change
        // caused by loading an API resource). The source of truth is always editorState.

        // Compute nextEditorState using new value
        const nextContentState = value ? stateFromHTML(value) : emptyContentState;
        const nextEditorState = EditorState.createWithContent(nextContentState);

        // Compare the current and next string representations of editorState to determine
        // if anything has changed.
        const currentValue = stateToHTML(editorState.getCurrentContent(), stateToHTMLOptions);
        const nextValue = stateToHTML(nextEditorState.getCurrentContent(), stateToHTMLOptions);
        if (currentValue !== nextValue) {
            setEditorState(nextEditorState);
        }
    }, [editorState, value]);

    const handleChange = useCallback((newEditorState: Object) => {
        setEditorState(newEditorState);

        if (typeof onChange === 'function') {
            const newContent = newEditorState.getCurrentContent();
            onChange(stateToHTML(newContent, stateToHTMLOptions));
        }
    }, [onChange]);

    return (
        <div className={className} style={{ position: 'relative' }}>
            <PluginsEditor
              {...editorProps}
              ref={inputRef}
              editorKey={editorProps.id}
              editorState={editorState}
              plugins={plugins}
              onChange={handleChange}
            />
            <InlineToolbar>
                {
                    (toolbarProps: Object) => (
                        <Fragment>
                            <BoldButton {...toolbarProps} />
                            <ItalicButton {...toolbarProps} />
                            <UnderlineButton {...toolbarProps} />
                            <LinkButton {...toolbarProps} />
                            <Separator {...toolbarProps} className={inlineToolbarTheme.separator} />
                            <HeadlineTwoButton {...toolbarProps} />
                            <HeadlineThreeButton {...toolbarProps} />
                            <UnorderedListButton {...toolbarProps} />
                            <OrderedListButton {...toolbarProps} />
                            <BlockquoteButton {...toolbarProps} />
                        </Fragment>
                    )
                }
            </InlineToolbar>
        </div>
    );
}
