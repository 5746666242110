// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useMemo, useState } from 'react';
import moment from 'moment-timezone';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
// $FlowTypedIssue update react-redux libdef
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { CircularProgress, Fade } from '@material-ui/core';
import {
    Check as CheckIcon,
    Clear as ClearIcon,
    Schedule as ScheduleIcon,
} from '@material-ui/icons';
import type { ContextRouter } from 'react-router';
import {
    getAvailableActions,
    getPermittedActions,
    getTicket,
    updateTicket,
} from '../../../../ducks/ticketDetail';
import FormikDateTimePicker from '../../../../components/FormikDateTimePicker';
import styles from './styles';
import type { State as RootState } from '../../../../store';

type Props = ContextRouter & {
    className?: string,
};

const useStyles = makeStyles(styles, { name: 'ScheduledDate' });

const ScheduledDateSchema = yup.object().shape({
    scheduledDate: yup.date().required(''),
});

export default function ScheduledDate(props: Props) {
    const { match } = props;
    const ticketId = parseInt(match.params.ticketId, 10);

    const dispatch = useDispatch();
    const classes = useStyles(props);
    const { t } = useTranslation();

    const [statusTimer, setStatusTimer] = useState(null);

    const ticket = useSelector((state: RootState) => getTicket(state));
    const permittedActions = useSelector((state: RootState) => getPermittedActions(state));
    const availableActions = useSelector((state: RootState) => getAvailableActions(state));

    const disabled = useMemo(() => {
        const fields = [];
        if (!permittedActions.includes('schedule') || !availableActions.includes('schedule')) {
            fields.push('scheduledDate');
        }
        return fields;
    }, [availableActions, permittedActions]);

    const initialValues = useMemo(() => {
        if (!ticket) {
            return { scheduledDate: undefined };
        }

        const {
            calendar_event: calendarEvent,
            location,
        } = ticket;

        // @todo Double check the timezone of the calendar event
        // I'm not sure if it's relative to the ticket's location or the user's location when the gig was scheduled
        return {
            scheduledDate: calendarEvent && calendarEvent.start
                ? moment.tz(calendarEvent.start, location.tzid).format('YYYY-MM-DDTHH:mm:ss')
                : undefined,
        };
    }, [ticket]);

    return (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={(values, { setStatus }) => (
              dispatch(updateTicket(ticketId, values))
                  .then(() => {
                      clearTimeout(statusTimer);
                      setStatusTimer(setTimeout(() => setStatusTimer(null), 1500));
                      setStatus({ success: true });
                  })
                  .catch((error: string) => {
                      clearTimeout(statusTimer);
                      setStatusTimer(setTimeout(() => setStatusTimer(null), 1500));
                      setStatus({ error });
                      return Promise.reject(error);
                  })
          )}
          validationSchema={ScheduledDateSchema}
        >
            {(formikProps) => {
                const { dirty, isSubmitting, isValid, status, submitForm } = formikProps;

                const handleBlur = () => {
                    if (dirty && isValid) {
                        submitForm();
                    }
                };

                return (
                    <Form className={classes.container}>
                        <ScheduleIcon className={classes.icon} />
                        <span className={classes.label}>{t('ticketDetail.summary.scheduledDate')}</span>
                        <FormikDateTimePicker
                          name="scheduledDate"
                          classes={{ dateInput: classes.dateInput }}
                          disabled={disabled.includes('scheduledDate')}
                          placeholder="unscheduled"
                          onBlur={handleBlur}
                        />
                        {isSubmitting
                            ? <CircularProgress size={18} thickness={4} />
                            : (
                                <Fade in={!!statusTimer} timeout={{ exit: 250 }}>
                                    {status && status.success
                                        ? <CheckIcon className={classes.successIcon} />
                                        : <ClearIcon className={classes.errorIcon} />}
                                </Fade>
                            )}
                    </Form>
                );
            }}
        </Formik>
    );
}
