// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useEffect } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { hoistStatics } from 'recompose';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Button, Dialog, DialogTitle } from '@material-ui/core';
import {
    Clear as ClearIcon,
    Person as PersonIcon,
} from '@material-ui/icons';
import type { Dispatch } from 'redux';
import type { Connector } from 'react-redux';
import AssignForm from './components/AssignForm';
import * as dialog from '../../ducks/dialog';
import { actions } from './duck';
import styles from './styles';
import type { State as RootState } from '../../redux/initialState';

type OwnProps = {};
type StateProps = {
    onClose: () => void,
    onSubmitFail: () => void,
    onSubmitSuccess: () => void,
    open: boolean,
    ticketIds: Object[],
};
type DispatchProps = {
    registerDialog: (name: string) => void,
    submit: (values: Object) => Promise<void>,
    unregisterDialog: (name: string) => void,
};
type Props = OwnProps & StateProps & DispatchProps;

const AssignSchema = yup.object().shape({
    assignee: yup.object()
        .required('validation.required'),
    force: yup.boolean(),
    ticketIds: yup.array().of(yup.number()),
});

const useStyles = makeStyles(styles, { name: 'AssignDialog' });

export function AssignDialog(props: Props) {
    const {
        onClose,
        onSubmitFail,
        onSubmitSuccess,
        open,
        registerDialog,
        submit,
        ticketIds,
        unregisterDialog,
    } = props;

    const classes = useStyles(props);
    const { t } = useTranslation();

    useEffect(() => {
        registerDialog(AssignDialog.NAME);
        return () => {
            unregisterDialog(AssignDialog.NAME);
        };
    }, [registerDialog, unregisterDialog]);

    return (
        <Formik
          enableReinitialize
          initialValues={{ assignee: null, force: false, ticketIds }}
          onSubmit={(values, { setStatus }) => (
              submit(values)
                  .then(() => {
                      setStatus({ success: true });
                      onSubmitSuccess();
                  })
                  .catch((error) => {
                      setStatus({ error });
                      onSubmitFail();
                      return Promise.reject(error);
                  })
          )}
          validationSchema={AssignSchema}
        >
            {(formikProps: Object) => {
                const { handleReset } = formikProps;
                return (
                    <Dialog
                      classes={{ paper: classes.dialogPaper }}
                      fullWidth
                      maxWidth="xs"
                      onClose={onClose}
                      onExited={handleReset}
                      open={open}
                    >
                        <Button className={classes.cancel} color="inherit" onClick={onClose}>
                            <ClearIcon />
                        </Button>
                        <DialogTitle className={classes.dialogTitle} disableTypography>
                            <Avatar className={classes.avatar}>
                                <PersonIcon className={classes.avatarIcon} />
                            </Avatar>
                            <h1>{t('assignDialog.text')}</h1>
                        </DialogTitle>
                        <AssignForm {...formikProps} />
                    </Dialog>
                );
            }}
        </Formik>
    );
}

AssignDialog.NAME = 'assign';
AssignDialog.defaultProps = {
    onClose: () => {},
    onSubmitFail: () => {},
    onSubmitSuccess: () => {},
    open: false,
    ticketIds: [],
};

const mapStateToProps = (state: RootState): StateProps => ({
    ...AssignDialog.defaultProps,
    ...state.dialog.registered[AssignDialog.NAME],
});

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    registerDialog: (name: string) => dispatch(dialog.actions.register(name)),
    submit: (values: Object) => dispatch(actions.submit(values)),
    unregisterDialog: (name: string) => dispatch(dialog.actions.unregister(name)),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
export default hoistStatics(connector)(AssignDialog);
