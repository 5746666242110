// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useMemo, useState } from 'react';
import moment from 'moment';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
// $FlowTypedIssue update react-redux libdef
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { CircularProgress, Fade } from '@material-ui/core';
import {
    Check as CheckIcon,
    Clear as ClearIcon,
    DateRange as DateRangeIcon,
    KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@material-ui/icons';
import type { ContextRouter } from 'react-router';
import {
    getAvailableActions,
    getPermittedActions,
    getTicket,
    updateTicket,
} from '../../../../ducks/ticketDetail';
import FormikDateTimePicker from '../../../../components/FormikDateTimePicker';
import styles from './styles';
import type { State as RootState } from '../../../../store';

type Props = ContextRouter & {
    className?: string,
};

const useStyles = makeStyles(styles, { name: 'Timeline' });

const TimelineSchema = yup.object().shape({
    startDate: yup.date()
        .required('')
        .when('canReschedule', {
            is: true,
            then: yup.date()
                .test({
                    message: '${path} field must be earlier than ${max}', // eslint-disable-line no-template-curly-in-string
                    name: 'max',
                    test(value) {
                        if (!value) {
                            return true;
                        }

                        const dueDate = this.resolve(yup.ref('dueDate'));
                        const limit = moment(dueDate).subtract(1, 'hours');

                        if (!moment(value).isSameOrBefore(limit)) {
                            return this.createError({ message: '' });
                        }

                        return true;
                    },
                }),
        }),
    dueDate: yup.date()
        .when('canReschedule', {
            is: true,
            then: yup.date()
                .required('')
                .test({
                    message: '${path} field must be later than ${min}', // eslint-disable-line no-template-curly-in-string
                    name: 'min',
                    test(value) {
                        if (!value) {
                            return true;
                        }

                        const startDate = this.resolve(yup.ref('startDate'));
                        const limit = moment(startDate).add(1, 'hours');

                        if (!moment(value).isSameOrAfter(limit)) {
                            return this.createError({ message: '' });
                        }

                        return true;
                    },
                }),
        }),
    canReschedule: yup.boolean(),
});

export default function Timeline(props: Props) {
    const { match } = props;
    const ticketId = parseInt(match.params.ticketId, 10);

    const dispatch = useDispatch();
    const classes = useStyles(props);
    const { t } = useTranslation();

    const [statusTimer, setStatusTimer] = useState(null);

    const ticket = useSelector((state: RootState) => getTicket(state));
    const permittedActions = useSelector((state: RootState) => getPermittedActions(state));
    const availableActions = useSelector((state: RootState) => getAvailableActions(state));

    const disabled = useMemo(() => {
        const fields = [];
        const canEditStartDate = permittedActions.includes('editStartDate') && availableActions.includes('editStartDate');
        const canEditDueDate = permittedActions.includes('editDueDate') && availableActions.includes('editDueDate');
        const canExtend = permittedActions.includes('extend') && availableActions.includes('extend');

        if (!canEditStartDate) {
            fields.push('startDate');
        }

        if (!canEditDueDate && !canExtend) {
            fields.push('dueDate');
        }

        return fields;
    }, [availableActions, permittedActions]);

    const initialValues = useMemo(() => {
        if (!ticket) {
            return {
                canReschedule: undefined,
                dueDate: undefined,
                startDate: undefined,
                ticketId: undefined,
            };
        }

        const {
            can_reschedule: canReschedule,
            due_date: dueDate,
            location,
            start_date: startDate,
        } = ticket;

        return {
            canReschedule,
            // $FlowTypedIssue - need to add moment-timezome libdef or extend moment$Moment
            dueDate: moment.tz(dueDate, location.tzid).format('YYYY-MM-DDTHH:mm:ss'),
            // $FlowTypedIssue - need to add moment-timezome libdef or extend moment$Moment
            startDate: moment.tz(startDate, location.tzid).format('YYYY-MM-DDTHH:mm:ss'),
        };
    }, [ticket]);

    return (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={(values, { setStatus }) => (
              dispatch(updateTicket(ticketId, values))
                  .then(() => {
                      clearTimeout(statusTimer);
                      setStatusTimer(setTimeout(() => setStatusTimer(null), 1500));
                      setStatus({ success: true });
                  })
                  .catch((error: string) => {
                      clearTimeout(statusTimer);
                      setStatusTimer(setTimeout(() => setStatusTimer(null), 1500));
                      setStatus({ error });
                      return Promise.reject(error);
                  })
          )}
          validationSchema={TimelineSchema}
        >
            {(formikProps: Object) => {
                const { dirty, isSubmitting, isValid, status, submitForm } = formikProps;

                const handleBlur = () => {
                    if (dirty && isValid) {
                        submitForm();
                    }
                };

                return (
                    <Form className={classes.container}>
                        <DateRangeIcon className={classes.icon} />
                        <span className={classes.label}>{t('ticketDetail.summary.timeline')}</span>
                        <FormikDateTimePicker
                          name="startDate"
                          disabled={disabled.includes('startDate')}
                          classes={{ dateInput: classes.dateInput }}
                          onBlur={handleBlur}
                        />
                        <KeyboardArrowRightIcon className={classes.rightArrowIcon} />
                        <FormikDateTimePicker
                          name="dueDate"
                          disabled={disabled.includes('dueDate')}
                          classes={{ dateInput: classes.dateInput }}
                          onBlur={handleBlur}
                        />
                        {isSubmitting
                            ? <CircularProgress size={18} thickness={4} />
                            : (
                                <Fade in={!!statusTimer} timeout={{ exit: 250 }}>
                                    {status && status.success
                                        ? <CheckIcon className={classes.successIcon} />
                                        : <ClearIcon className={classes.errorIcon} />}
                                </Fade>
                            )}
                    </Form>
                );
            }}
        </Formik>
    );
}
