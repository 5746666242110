// @flow
import React from 'react';
import cx from 'classnames';
import { TableCell } from '@material-ui/core';
import type { Node } from 'react';

type Props = {
    children: Node,
    classes: Object,
    className?: string,
    data: Object[],
    loading: boolean,
    padding: 'default' | 'checkbox' | 'none',
    selected: any[],
};

export function Cell(props: Props) {
    const { children, classes, className, data, loading, padding, selected, ...other } = props;
    return (
        <TableCell
          {...other}
          component="div"
          className={cx(className, { [classes.defaultPadding]: padding === 'default' })}
          classes={{ body: classes.cell }}
          padding={padding}
        >
            {children}
        </TableCell>
    );
}

export default Cell;
