// @flow
import { handleActions } from 'redux-actions';
import type { ActionType } from 'redux-actions';
import { INITIALIZE as INITIALIZE_SESSION } from '../session';
import types from './types';
import typeof { fetchDataSuccess, select, deselect } from './actions';

export type State = {
    data: number[],
    drawerOpen: boolean,
    fetching: boolean,
    metadata: Object,
    selected: number[],
    settings: {
        columns: string[],
    }
};

export const init: State = {
    data: [],
    drawerOpen: false,
    fetching: true,
    metadata: {},
    selected: [],
    settings: {
        columns: ['status', 'title', 'location', 'assignee', 'startDate', 'dueDate'],
    },
};

export default handleActions({
    [types.FETCH_DATA]: (state: State) => ({
        ...state,
        fetching: true,
    }),
    [types.FETCH_DATA_ERROR]: (state: State) => ({
        ...state,
        fetching: false,
    }),
    [types.FETCH_DATA_SUCCESS]: (state: State, action: ActionType<fetchDataSuccess>) => ({
        ...state,
        data: action.payload,
        fetching: false,
        metadata: action.meta,
    }),
    [types.SELECT]: (state: State, action: ActionType<select>) => {
        const { selected } = state;
        return {
            ...state,
            selected: Array.from(new Set([...selected, action.payload])),
        };
    },
    [types.SELECT_ALL]: (state: State) => {
        const { data, selected } = state;
        return {
            ...state,
            selected: Array.from(new Set([...selected, ...data])),
        };
    },
    [types.DESELECT]: (state: State, action: ActionType<deselect>) => {
        const { selected } = state;
        return {
            ...state,
            selected: selected.filter((id: number): boolean => id !== action.payload),
        };
    },
    [types.DESELECT_ALL]: (state: State) => {
        const { data, selected } = state;
        return {
            ...state,
            selected: selected.filter((id: number): boolean => !data.includes(id)),
        };
    },
    [types.CLEAR_SELECTED]: (state: State) => ({
        ...state,
        selected: [],
    }),
    [types.TOGGLE_FILTER_DRAWER]: (state: State, action: ActionType<*>) => ({
        ...state,
        drawerOpen: action.payload,
    }),
    [types.TOGGLE_COLUMN]: (state: State, action: ActionType<*>) => {
        const { enabled, name } = action.payload;
        const columns = new Set(state.settings.columns);

        if (enabled) columns.add(name);
        else columns.delete(name);

        return {
            ...state,
            settings: {
                ...state.settings,
                columns: Array.from(columns),
            },
        };
    },
    [types.RESET_COLUMNS]: (state: State) => ({
        ...state,
        settings: {
            ...state.settings,
            columns: init.settings.columns,
        },
    }),
    [INITIALIZE_SESSION]: (state: State, action: ActionType<*>) => {
        const { profile } = action.payload;
        const ticketColumns = profile ? profile.ticketColumns || [] : [];

        if (ticketColumns.length > 0) {
            return {
                ...state,
                settings: {
                    ...state.settings,
                    columns: ticketColumns,
                },
            };
        }

        return state;
    },
}, init);
