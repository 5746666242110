// @flow
export default (theme: Object) => ({
    section: {
        marginBottom: theme.spacing(3),
    },
    sectionHeader: {
        textTransform: 'uppercase',
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
    },
    cards: {
        display: 'flex',

        '& > :not(:last-child)': {
            marginRight: theme.spacing(2),
        },
    },
    cardAvatar: {
        width: 60,
        height: 60,
    },
    active: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
    },
    submitted: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.primary.contrastText,
    },
    listPadding: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    list: {
        backgroundColor: theme.palette.grey[100],
        border: [[1, 'solid', theme.palette.divider]],
        borderRadius: 3,
    },
    info: {
        display: 'flex',
        alignItems: 'center',
        // padding: theme.spacing(1, 0, 1),
    },
    infoIcon: {
        color: theme.palette.primary.light,
        marginRight: theme.spacing(0.5),
    },
    link: {
        '&:hover': {
            color: 'unset',
        },
    },
});
