// @flow
/**
 * @property TicketsConstant
 */
export const STATUS = Object.freeze({
    UNASSIGNED: 'UNASSIGNED', // ticket has not been assigned
    ASSIGNED: 'ASSIGNED', // ticket has been assigned to a worker
    SCHEDULED: 'SCHEDULED', // ticket has been scheduled by a worker
    STARTED: 'STARTED', // a worker has started work for ticket
    SUBMITTED: 'SUBMITTED', // work has been submitted for ticket
    EXPIRED: 'EXPIRED', // ticket was expired
    CANCELED: 'CANCELED', // ticket was canceled
    AUTO_CANCELED: 'AUTO_CANCELED', // ticket was paused PAUSED,
    UNSCHEDULED: 'UNSCHEDULED',
});

/**
 * Fixes Backward compatibilities
 * @property DEPRECATED_STATUS
 */
export const DEPRECATED_STATUS = Object.freeze({
    NO_ISSUES: STATUS.SUBMITTED,
    ISSUES_FOUND: STATUS.SUBMITTED,
    POSTED: STATUS.UNASSIGNED,
});

export const EVENTS = Object.freeze({
    CREATED: 'CREATED',
    CANCELED: 'CANCELED',
    STARTED: 'STARTED',
    PAUSED: 'PAUSED',
    RESTARTED: 'RESTARTED',
    SUBMITTED: 'SUBMITTED',
    PRINTED: 'PRINTED',
    SCHEDULED: 'SCHEDULED',
    UNSCHEDULED: 'UNSCHEDULED',
    EXTENDED: 'EXTENDED',
    ASSIGNED: 'ASSIGNED',
    UNASSIGNED: 'UNASSIGNED',
    AUTO_ASSIGNED: 'AUTO_ASSIGNED',
    AUTO_UNASSIGNED: 'AUTO_UNASSIGNED',
    COMMENT: 'COMMENT',
    EDIT: 'EDIT',
    GROUPS_CHANGED: 'GROUPS CHANGED',
    PROJ_START_DATE_CHANGED: 'START DATE CHANGED IN PROJECT',
    PROJ_DUE_DATE_CHANGED: 'DUE DATE CHANGED IN PROJECT',
    PROJ_TIME_ESTIMATE_CHANGED: 'TIME ESTIMATE CHANGED IN PROJECT',
    START_DATE_EDITED: 'START_DATE_EDITED',
    END_DATE_EDITED: 'END_DATE_EDITED',
    TIME_ESTIMATE_EDITED: 'TIME_ESTIMATE_EDITED',
    PROJECT_UPDATE: 'PROJECT_UPDATE',
    APPROVED: 'APPROVED',
    REJECTED: 'REJECTED',
    REOPENED: 'REOPENED',
    SUBMIT_DEADLINE_EDITED: 'SUBMIT_DEADLINE_EDITED',
    RATED: 'RATED',
    PAID: 'PAID',
    DEADLINE_SET: 'DEADLINE_SET',
});

/**
 * @property APPLICATION
 */
export const APPLICATION = Object.freeze({
    NONE: 'NONE',
    PENDING: 'PENDING',
    WITHDRAWN: 'WITHDRAWN',
});

/**
 * @property EXECUTION
 */
export const EXECUTION = Object.freeze({
    NO_ISSUES: 'NO_ISSUES',
    ISSUES_FOUND: 'ISSUES_FOUND',
    POSTED: 'POSTED',
});

/**
 * @property FILTERS
 */
export const FILTERS = Object.freeze({
    OPTIN: 'OPTIN',
    LOCATION_AT: 'LOCATION_AT',
    LOCATION_NEAR: 'LOCATION_NEAR',
    OPEN: 'OPEN',
    UNSCHEDULED: 'UNSCHEDULED',
    SCHEDULED: 'SCHEDULED',
    PAUSED: 'PAUSED',
    DUE: 'DUE',
    SUBMITTED: 'SUBMITTED',
    ASSIGNED: 'ASSIGNED',
    UNASSIGNED: 'UNASSIGNED',
    EXPIRED: 'EXPIRED',
    EXPIRING: 'EXPIRING', // is AUTO_CANCELED or EXPIRED with a due date in the last 24 hours
    CALENDAR: 'CALENDAR',
    CUSTOMER_SUBMITTED: 'CUSTOMER_SUBMITTED',
    CUSTOMER_OPEN: 'CUSTOMER_OPEN',
    CUSTOMER_ASSIGNED: 'CUSTOMER_ASSIGNED',
    STARTED: 'STARTED',
    PENDING: 'PENDING',
    DELAYED: 'DELAYED',
    APPROVED: 'APPROVED',
    REJECTED: 'REJECTED',
    REOPENED: 'REOPENED',
});

/**
 * @property SORTING_OPTION
 */
export const SORTING_OPTION = Object.freeze({
    // for the list of customers that can be assigned to a ticket
    customer_username: 'assigned_customer_name',
    customer_location: 'address_line_2',
    customer_availability: 'availability',

    // for the list of ticket
    'project-title': 'title',
    location: 'location_title',
    assigned: 'assigned_customer_name',
    start_date: 'start_date',
    due: 'due_date',
    status: 'ticket_status',
    scheduled: 'date_scheduled',
    estimated: 'time_estimate',
});

/**
 * @property DATE_RANGE
 */
export const DATE_RANGE = Object.freeze({
    TODAY: 'today',
    TOMORROW: 'tomorrow',
    THIS_WEEK: 'this_week',
    CUSTOM: 'custom',
});

/**
 * @property COMPARATORS
 */
export const COMPARATORS = Object.freeze({
    HAS: 'has',
    IN: 'in',
    NOT_IN: 'not_in',
    EQUAL: '=',
    DIFF: '!=',
    SUP: '>',
    INF: '<',
    SUP_EQUAL: '>=',
    INF_EQUAL: '<=',
});

export const PENDING_WORK: string = 'pendingWork';

export default {
    STATUS,
    APPLICATION,
    EVENTS,
    DEPRECATED_STATUS,
    EXECUTION,
    FILTERS,
    SORTING_OPTION,
    DATE_RANGE,
    COMPARATORS,
};
