// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useEffect, useMemo, useState } from 'react';
import isEqual from 'lodash/isEqual';
import moment from 'moment-timezone';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
// $FlowTypedIssue update react-redux libdef
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import logger from '../../../../utils/logger';
import SkipLogicTree from '../../../../helpers/SkipLogicTree';
import getValidationSchema from '../utils/getValidationSchema';
import { getDataItemMap, getTicket, getTicketId, saveResponse, editResponse } from '../../../../ducks/ticketDetail';
import MultipleChoice from '../MultipleChoice';
import Barcode from '../Barcode';
import Number from '../Number';
import Signature from '../Signature';
import Photo from '../Photo';
import FreeText from '../FreeText';
import DateTime from '../DateTime';
import Duration from '../Duration';
import Hint from '../Hint';
import Task from '../Task';
import styles from './styles';
import type { State as RootState } from '../../../../store';

type Props = {
    disabled?: boolean,
    disableValidation?: boolean,
    json: Object,
};

const QuestionSchema = getValidationSchema();

const useStyles = makeStyles(styles, { name: 'Question' });

export default function Question(props: Props) {
    const { disabled, disableValidation, json } = props;

    const classes = useStyles(props);
    const dispatch = useDispatch();

    const [unsavedChanges, setUnsavedChanges] = useState({});

    const ticket = useSelector((state: RootState) => getTicket(state));
    const ticketId = useSelector((state: RootState) => getTicketId(state));
    const dataItemMap = useSelector((state: RootState) => getDataItemMap(state));

    const questions = useMemo(() => {
        const questionTree = new SkipLogicTree(json);
        const questionList = [];

        // Use depth-first search to get steps in correct order
        const flatten = (node: SkipLogicTree) => {
            questionList.push(node);

            const {
                data_type: dataType,
                observation_target: observationTarget,
                template,
            } = node.value;

            const questionId = [dataType.id, observationTarget.id, template ? template.id : null].join('_');
            const dataItem = dataItemMap[questionId];
            const dataItemValue = unsavedChanges[questionId] || (dataItem ? dataItem.data_item_value : []);

            if (dataItemValue.length > 0) {
                const children = node.evaluate(dataItemValue);
                children.forEach((n: SkipLogicTree) => {
                    flatten(n);
                });
            }
        };

        // TODO: Add a flatten method to SkipLogicTree. Will need to pass in data_items map
        flatten(questionTree);

        return questionList;
    }, [dataItemMap, json, unsavedChanges]);

    useEffect(() => {
        setUnsavedChanges({});
    }, [dataItemMap]);

    if (!ticket) {
        return null;
    }

    return (
        <div className={classes.root}>
            {questions.map((node: SkipLogicTree) => {
                const {
                    data_type: dataType,
                    observation_target: observationTarget,
                    template,
                } = node.value;

                const {
                    expected_value_expression: expectedValueExpression,
                    value_type: valueType,
                    questions: {
                        question_text: questionText,
                        propositions,
                    },
                } = dataType;

                const questionId = [dataType.id, observationTarget.id, template ? template.id : null].join('_');
                const dataItem = dataItemMap[questionId];
                const dataItemValue = dataItem ? dataItem.data_item_value : [];
                const context = { dataType, location: ticket.location };

                let QuestionForm;
                let initialValues;

                switch (valueType) {
                    case 'HINT':
                        QuestionForm = Hint;
                        initialValues = { response: dataItemValue[0] || '' };
                        break;

                    case 'PHOTO':
                        QuestionForm = Photo;
                        initialValues = { response: dataItemValue };
                        break;

                    case 'SIGNATURE':
                        QuestionForm = Signature;
                        initialValues = { response: dataItemValue[0] || null };
                        break;

                    case 'MULTIPLE_CHOICE':
                    case 'MULTI_SELECT':
                    case 'CHECKBOXES':
                        QuestionForm = MultipleChoice;
                        initialValues = valueType === 'MULTIPLE_CHOICE'
                            ? { response: dataItemValue[0] || '' }
                            : { response: dataItemValue || [] };
                        break;

                    case 'DATE':
                        QuestionForm = DateTime;
                        initialValues = {
                            response: dataItemValue[0] || '',
                        };
                        break;

                    case 'DATE_TIME':
                        QuestionForm = DateTime;
                        initialValues = {
                            response: dataItemValue[0]
                                ? moment.tz(dataItemValue[0], ticket.location.tzid).format('YYYY-MM-DDTHH:mm:ss')
                                : '',
                        };
                        break;

                    case 'TIME':
                        QuestionForm = Duration;
                        initialValues = { response: dataItemValue[0] || '' };
                        break;

                    case 'BARCODE':
                        QuestionForm = Barcode;
                        initialValues = { response: dataItemValue[0] || '' };
                        break;

                    case 'NUMBER':
                    case 'CURRENCY':
                    case 'PHONE_NUMBER':
                        QuestionForm = Number;
                        initialValues = { response: dataItemValue[0] || '' };
                        break;

                    case 'FREE_TEXT':
                        QuestionForm = FreeText;
                        initialValues = { response: dataItemValue[0] || '' };
                        break;

                    case 'TASK':
                        QuestionForm = Task;
                        initialValues = { response: dataItemValue[0] || '' };
                        break;

                    default:
                        logger.error(`Unsupported question type: ${valueType}`);
                        return null;
                }
                return (
                    <Formik
                      enableReinitialize
                      initialValues={initialValues}
                      onSubmit={(values, { resetForm, setStatus }) => {
                          const { response } = QuestionSchema.cast(values, { context });
                          const submit = disableValidation ? editResponse : saveResponse;

                          return dispatch(submit(ticketId, questionId, !Array.isArray(response) ? [response] : response))
                              .then(() => {
                                  setStatus({ success: true });
                                  resetForm();
                              })
                              .catch((error: string) => {
                                  setStatus({ error });
                                  return Promise.reject(error);
                              });
                      }}
                      validate={(values) => {
                          const emptyErrors = {};

                          // if (disableValidation) {
                          //     return emptyErrors;
                          // }

                          return validateYupSchema(values, QuestionSchema, false, context)
                              .then(() => emptyErrors)
                              .catch((err: any) => (
                                  err.name === 'ValidationError'
                                      ? yupToFormErrors(err)
                                      : Promise.reject(err)
                              ));
                      }}
                    >
                        {(formikProps) => {
                            const handleChange = (values) => {
                                const { response } = QuestionSchema.cast(values, { context });
                                const value = !Array.isArray(response) ? [response] : response;
                                // console.log(value);

                                if (!isEqual(dataItemValue, value)) {
                                    setUnsavedChanges({ ...unsavedChanges, [questionId]: value });
                                } else if (questionId in unsavedChanges) {
                                    const copy = { ...unsavedChanges };
                                    delete copy[questionId];
                                    setUnsavedChanges(copy);
                                }
                            };

                            return (
                                <QuestionForm
                                  {...formikProps}
                                  dataItem={dataItem}
                                  disabled={disabled}
                                  expectedValueExpression={expectedValueExpression}
                                  id={questionId}
                                  key={questionId}
                                  questionText={questionText}
                                  valueType={valueType}
                                  onChange={handleChange}
                                  propositions={propositions}
                                />
                            );
                        }}
                    </Formik>
                );
            })}
        </div>
    );
}
