// @flow
import React from 'react';
import { Form } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Paper } from '@material-ui/core';
import FormikInput from '../../../../components/FormikInput';
import FormikSelect from '../../../../components/FormikSelect';
import FormikCheckbox from '../../../../components/FormikCheckbox';
import SubmitButton from '../../../../components/SubmitButton';
import styles from './styles';

type Props = {
    dirty: boolean,
    handleSubmit: Function,
    isSubmitting: boolean,
    isValid: boolean,
    setStatus: (status: any) => void,
    status?: any,
};

const useStyles = makeStyles(styles, { name: 'SignupForm' });

const verticals = [
    'CPG',
    'CONSULTING',
    'MARKET_RESEARCH',
    'RETAIL_MERCHANDISING',
    'STAFFING',
];

export default function SignupForm(props: Props) {
    const { dirty, handleSubmit, isSubmitting, isValid, setStatus, status } = props;

    const classes = useStyles(props);
    const { t } = useTranslation();

    const linkProps = {
        rel: 'noopener noreferrer',
        target: '_blank',
    };
    const termsOfService = (
        <Trans
          defaults={t('shared.auth.terms')}
          components={[
              'I agree with the',
              <a {...linkProps} href="http://www.gigwalk.com/terms-of-service">Terms of Service</a>,
              'and',
              <a {...linkProps} href="http://www.gigwalk.com/privacy-policy/">Privacy Policy</a>,
          ]}
        />
    );

    return (
        <Paper className={classes.paper} elevation={0}>
            <Form
              onChange={() => {
                  if (status && status.error) {
                      setStatus({ error: null });
                  }
              }}
            >
                <h1>{t('signup.title')}</h1>
                {status && status.error
                    ? <div className={classes.error}>{status.error}</div>
                    : null}
                <fieldset className={classes.fieldset}>
                    <div>
                        <FormikInput
                          className={classes.firstName}
                          fullWidth
                          label={t('shared.auth.firstName')}
                          labelPlacement="top"
                          margin="dense"
                          name="firstName"
                          placeholder={t('shared.auth.firstNamePlaceholder')}
                          required
                          variant="outlined"
                        />
                        <FormikInput
                          className={classes.lastName}
                          fullWidth
                          label={t('shared.auth.lastName')}
                          labelPlacement="top"
                          margin="dense"
                          name="lastName"
                          placeholder={t('shared.auth.lastNamePlaceholder')}
                          required
                          variant="outlined"
                        />
                        <FormikInput
                          fullWidth
                          label={t('shared.auth.email')}
                          labelPlacement="top"
                          margin="dense"
                          name="email"
                          placeholder={t('shared.auth.emailPlaceholder')}
                          required
                          type="email"
                          variant="outlined"
                        />
                        <FormikInput
                          fullWidth
                          label={t('signup.form.companyName')}
                          labelPlacement="top"
                          margin="dense"
                          name="companyName"
                          placeholder={t('signup.form.companyNamePlaceholder')}
                          required
                          variant="outlined"
                        />
                        <FormikSelect
                          fullWidth
                          label={t('signup.form.industry')}
                          labelPlacement="top"
                          margin="dense"
                          name="industry"
                          options={
                              verticals.map((vertical: string) => ({
                                  label: t(`signup.form.vertical_types.${vertical}`),
                                  value: vertical,
                              }))
                          }
                          placeholder={t('signup.form.chooseIndustry')}
                          required
                          variant="outlined"
                        />
                        <FormikCheckbox
                          classes={{ checkbox: classes.checkbox }}
                          color="primary"
                          label={termsOfService}
                          name="termsOfService"
                          required
                        />
                    </div>
                </fieldset>
                <SubmitButton
                  className={classes.submitButton}
                  color="secondary"
                  disabled={!dirty || isSubmitting || !isValid}
                  onClick={handleSubmit}
                  size="large"
                  submitting={isSubmitting}
                  type="submit"
                  variant="contained"
                >
                    {t('signup.form.createAccount')}
                </SubmitButton>
            </Form>
        </Paper>
    );
}
