// @flow
import { createAction } from 'redux-actions';
import axios from 'axios';
import type { Dispatch } from 'redux';
import type { $AxiosError } from 'axios';
import types from './types';

type FormValues = {
    companyName: string,
    industry: string,
    email: string,
    firstName: string,
    lastName: string,
};

/* eslint-disable quote-props */
const errorsByStatusCode = {
    '500': 'internalServerError',
    default: 'unknownError',
};
/* eslint-enable quote-props */

export const submitError = createAction(types.SUBMIT_ERROR);
export const submitSuccess = createAction(types.SUBMIT_SUCCESS);
export const submit = createAction(
    types.SUBMIT,
    (values: FormValues): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            const { companyName, email, industry, firstName, lastName } = values;
            const params = {
                email,
                companyName,
                industry,
                firstName,
                lastName,
            };

            // if someone tries to signup with a crossmark email address, redirect to enterprise login
            if (email.includes('@crossmark.com')) {
                window.location = 'https://enterprise.gigwalk.com';
                return Promise.resolve();
            }

            return axios.post('/auth/signup', params)
                .then(() => {
                    dispatch(submitSuccess());
                })
                .catch((err: $AxiosError<Object>) => {
                    let error = 'networkError';
                    if ('response' in err) {
                        const { data, status } = err.response;
                        if (typeof data.message === 'string') error = data.message;
                        else if (status in errorsByStatusCode) error = errorsByStatusCode[status];
                        else error = errorsByStatusCode.default;
                    }
                    dispatch(submitError(err));
                    return Promise.reject(error);
                });
        }
    )
);

export default {
    submit,
    submitError,
    submitSuccess,
};
