// @flow
import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/styles';
import { ListItemAvatar, ListItemText } from '@material-ui/core';
import {
    Check as CheckIcon,
    Search as SearchIcon,
} from '@material-ui/icons';
import UserAvatar from '../../../../components/UserAvatar';
import StarRating from '../../../../components/StarRating';
import Select from '../../../../components/Select';
import styles from './styles';

type State = {
    sort: Object,
};

type Props = {
    classes: Object,
    options: Object[],
};

const sortOptions = [
    { label: 'Rating', value: 'rating' },
    { label: 'Application date', value: 'applicationDate' },
    { label: 'Distance from gig', value: 'distance' },
];

const sortByRating = ({ value: a }: Object, { value: b }: Object): number => (
    parseFloat(b.rating_score || 0) - parseFloat(a.rating_score || 0)
);

// @todo Implement sortByApplicationDate and sortByDistance
const sortByApplicationDate = () => 0;
const sortByDistance = () => 0;

const stopPropagation = (event: SyntheticEvent<any>) => {
    event.stopPropagation();
    event.preventDefault();
};

export class ApplicantSelect extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            sort: sortOptions[0],
        };
    }

    _formatOptionLabel = (option: Object, meta: Object) => {
        const { classes } = this.props;
        const { label, value } = option;
        const { comment, rating_score: score } = value;
        const userRating = Math.round(score * 10) / 10;

        const primary = (
            <Fragment>
                <div>{label}</div>
                <div className={classes.rating}>
                    <StarRating className={classes.stars} value={userRating || 0} disableHover />
                    {userRating ? userRating.toFixed(1) : 'N/A'}
                </div>
            </Fragment>
        );

        return (
            <Fragment>
                <ListItemAvatar>
                    <UserAvatar user={value} />
                </ListItemAvatar>
                <ListItemText
                  style={{ height: 'auto' }}
                  classes={{ secondary: classes.comment }}
                  primary={primary}
                  secondary={comment}
                />
                {meta.isSelected ? <CheckIcon /> : null}
            </Fragment>
        );
    };

    handleSortChange = (value: Object) => {
        this.setState({ sort: value });
    };

    renderMenuList = (props: Object) => {
        const { children, innerProps, innerRef } = props;
        const { classes } = this.props;
        const { sort } = this.state;

        // Create a wrapper around the Select element to capture and prevent event
        // propagation to parent component
        return (
            <Fragment>
                <div onMouseDown={stopPropagation} onMouseMove={stopPropagation}>
                    <Select
                      classes={{
                          menu: classes.sortMenu,
                          singleValue: classes.sortValue,
                      }}
                      ControlProps={{ disableUnderline: true }}
                      controlShouldRenderValue
                      isSearchable={false}
                      onChange={this.handleSortChange}
                      options={sortOptions}
                      value={sort}
                      isDisabled
                    />
                </div>
                <div {...innerProps} ref={innerRef} style={{ overflowY: 'auto' }}>
                    {children}
                </div>
            </Fragment>
        );
    };

    render() {
        const { options } = this.props;
        const { sort } = this.state;

        let compareFunction;
        switch (sort.value) {
            case 'rating':
                compareFunction = sortByRating;
                break;
            case 'applicationDate':
                compareFunction = sortByApplicationDate;
                break;
            case 'distance':
                compareFunction = sortByDistance;
                break;
            default:
                break;
        }

        return (
            <Select
              {...this.props}
              backspaceRemovesValue={false}
              ControlProps={{ fullWidth: true }}
              components={{ MenuList: this.renderMenuList }}
              controlShouldRenderValue={false}
              dropdownIcon={<SearchIcon />}
              formatOptionLabel={this._formatOptionLabel}
              menuIsOpen
              menuType="list"
              minMenuHeight={400}
              maxMenuHeight={400}
              options={[...(options || [])].sort(compareFunction)}
            />
        );
    }
}

export default withStyles(styles, { name: 'ApplicantSelect' })(ApplicantSelect);
