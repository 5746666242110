// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback, useState } from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { Button, fade } from '@material-ui/core';
import { Flip as FlipIcon } from '@material-ui/icons';
import typeof TuiImageEditor from 'tui-image-editor';

type Props = {
    editor: TuiImageEditor,
}

const useStyles = makeStyles((theme: Object) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: theme.spacing(2, 0),
    },
    button: {
        margin: theme.spacing(0, 1),
    },
    toggleButton: {
        color: fade(theme.palette.common.white, 0.5),

        '&$selected': {
            color: theme.palette.common.white,
        },
    },
    buttonLabel: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    selected: {},
}), { name: 'FlipMenu' });

export default function FlipMenu(props: Props) {
    const { editor } = props;
    const classes = useStyles(props);

    const graphics = editor._graphics;
    const flipper = graphics.getComponent('FLIP');
    const { flipX: initialFlipX, flipY: initialFlipY } = flipper.getCurrentSetting();

    const [flipX, setFlipX] = useState(initialFlipX);
    const [flipY, setFlipY] = useState(initialFlipY);

    const handleFlipClick = useCallback((event: SyntheticEvent<any>) => {
        const { name } = event.currentTarget;
        switch (name) {
            case 'flipX':
                editor.flipX();
                setFlipX(!flipX);
                break;
            case 'flipY':
                editor.flipY();
                setFlipY(!flipY);
                break;
            default:
                break;
        }
    }, [editor, flipX, flipY]);

    return (
        <div className={classes.root}>
            <div>
                <Button
                  className={cx(classes.toggleButton, { [classes.selected]: flipX })}
                  classes={{
                      root: classes.button,
                      label: classes.buttonLabel,
                  }}
                  name="flipX"
                  onClick={handleFlipClick}
                >
                    <FlipIcon fontSize="large" />
                    Flip X
                </Button>
                <Button
                  className={cx(classes.toggleButton, { [classes.selected]: flipY })}
                  classes={{
                      root: classes.button,
                      label: classes.buttonLabel,
                  }}
                  name="flipY"
                  onClick={handleFlipClick}
                >
                    <FlipIcon fontSize="large" style={{ transform: 'rotate(90deg)' }} />
                    Flip Y
                </Button>
            </div>
        </div>
    );
}
