// @flow
import moment from 'moment';
import { setIn } from 'formik';
import type { Certification } from 'gigwalk/lib/api/certifications/types';
import type { Group } from 'gigwalk/lib/api/groups/types';
import { SCHEDULE_TYPES } from '../../../../browser/shared/constant/ProjectConstant';

type Proposition = {
    choice: string,
    alert: boolean,
};

type Question = {
    id: number | string,
    children?: Object[],
    description: string,
    valueType: string,
    questionText: string,
    propositions: Proposition[],
    required: boolean,
    targetListId?: number,
    globalDataTypeId: number,
};

function setProperty(subscription: Object, path: string, value: any, transform?: (value: any) => any) {
    const transformedValue = transform && value !== undefined ? transform(value) : value;
    const retVal = setIn(subscription, path, transformedValue);
    Object.assign(subscription, retVal);
}

export default function mapValuesToSubscription(values: Object, subscription: ?Object): Object {
    const isLegacyProject = subscription && subscription.state !== 'DRAFT' && subscription.needs_core === true;
    const data = {};

    let startDate;
    let endDate;

    if (values.hasOwnProperty('scheduleType')) {
        if (values.scheduleType === SCHEDULE_TYPES.RANGE) {
            startDate = values.rangeStartDate;
            endDate = values.rangeEndDate;
        } else if (values.scheduleType === SCHEDULE_TYPES.ASAP) {
            // Hack to make sure ticket start date is now or in the past for any domestic locations
            // so that workers can begin work immediately. This should be handled by BE during
            // ticket creation
            if (subscription) {
                startDate = subscription.state === 'DRAFT'
                    ? moment().subtract(6, 'hours')
                    : undefined;
            } else {
                startDate = moment().subtract(6, 'hours');
            }
            endDate = values.asapEndDate;
        }
    }

    setProperty(data, 'autoassign', values.autoassign);
    setProperty(data, 'certifications', values.certifications, (value) => (
        value.map((cert: Certification) => cert.id)
    ));
    setProperty(data, 'description', values.description);
    setProperty(data, 'end_date', endDate, (value) => moment(value).format('YYYY-MM-DDTHH:mm'));
    setProperty(data, 'groups', values.groups, (value) => (
        value.map((group: Group) => group.id)
    ));
    setProperty(data, 'min_gig_price', values.gigPrice);
    setProperty(data, 'near_ticket_distance', values.nearTicketDistance);
    setProperty(data, 'needs_approval', values.needsApproval);
    setProperty(data, 'needs_core', values.needsPublicWorkforce, (value) => (
        isLegacyProject ? value : false
    ));
    setProperty(data, 'needs_public_workforce', values.needsPublicWorkforce, (value) => (
        isLegacyProject ? false : value
    ));
    setProperty(data, 'optin_type', values.optinType);
    setProperty(data, 'organization_data.fifo_assign_for_system_approved_optin', values.fifoAssign);
    setProperty(data, 'organization_data.allowGalleryUpload', values.allowGalleryUpload);
    setProperty(data, 'organization_location_lists', values.locationListId, (value) => (
        value !== null ? [value] : []
    ));
    setProperty(data, 'organization_observation_target_lists', values.questionList, (value) => (
        value.map((question: Question) => {
            const { children, id, globalDataTypeId, targetListId } = question;
            const dataTypeId = typeof id === 'string' && id.startsWith('duplicate') ? globalDataTypeId : id;
            return {
                observation_target_list_id: targetListId,
                data_types: [{ children, data_type_id: dataTypeId }],
            };
        })
    ));
    setProperty(data, 'res_window_hours', values.resWindowHours, (value) => {
        // Reservation window cannot be changed after launch
        if (subscription && subscription.state !== 'DRAFT') {
            return undefined;
        }
        return value.length > 0 ? value.sort((a, b) => a - b).join(',') : null;
    });
    setProperty(data, 'schedule_type', values.scheduleType);
    setProperty(data, 'start_date', startDate, (value) => moment(value).format('YYYY-MM-DDTHH:mm'));
    setProperty(data, 'submit_deadline', values.submitDeadline, (value) => (
        value !== null ? moment(value).format('YYYY-MM-DDTHH:mm') : value
    ));
    setProperty(data, 'ticket_time_estimate', 1800); // Default to 30 mins until support is added for timeEstimate and canReschedule fields
    setProperty(data, 'tickets_overage', values.oversamplingOverage);
    setProperty(data, 'tickets_target', values.oversamplingTarget);
    setProperty(data, 'title', values.title);
    setProperty(data, 'two_way_rating_enabled', values.twoWayRatingEnabled);
    setProperty(data, 'worker_count', values.worker_count);

    return Object.entries(data)
        .reduce((acc: Object, [key, value]: [string, mixed]) => (
            value !== undefined ? { ...acc, [key]: value } : acc
        ), {});
}
