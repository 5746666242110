// @flow
import * as yup from 'yup';
import moment from 'moment-timezone';
import i18next from 'i18next';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import CondExpression from '../../../../helpers/CondExpression';

const MultipleChoiceSchema = yup.object().shape({
    response: yup.mixed()
        .when('$dataType.value_type', {
            is: 'MULTIPLE_CHOICE',
            then: yup.string(),
        })
        .when('$dataType.value_type', {
            is: (valueType) => valueType === 'MULTI_SELECT' || valueType === 'CHECKBOXES',
            then: yup.array().of(yup.string()),
        }),
});

const NumberSchema = yup.object().shape({
    response: yup.number(),
});

const CurrencySchema = yup.object().shape({
    response: yup.number(),
});

const PhoneNumberSchema = yup.object().shape({
    response: yup.string()
        .test({
            message: '${path} must be a valid phone number', // eslint-disable-line no-template-curly-in-string
            name: 'phoneNumber',
            exclusive: true,
            test(value) {
                if (value == null) {
                    return true;
                }

                // @todo Add support for other regions
                const phoneNumber = parsePhoneNumberFromString(value, 'US');

                if (!phoneNumber.isPossible()) {
                    const message = i18next.t('validation.invalidPhoneNumber');
                    return this.createError({ message });
                }

                return true;
            },
        })
        .transform((value) => {
            // @todo Add support for other regions
            const phoneNumber = parsePhoneNumberFromString(value, 'US');
            return phoneNumber.isPossible() ? phoneNumber.formatNational() : value;
        }),
});

const FreeTextSchema = yup.object().shape({
    response: yup.string(),
});

const BarcodeSchema = yup.object().shape({
    response: yup.string(),
});

const DateTimeSchema = yup.object().shape({
    response: yup.string()
        .test({
            message: '${path} must be a valid date', // eslint-disable-line no-template-curly-in-string
            name: 'date',
            exclusive: true,
            test(value) {
                if (value == null) {
                    return true;
                }

                if (!moment(value).isValid()) {
                    const message = i18next.t('validation.invalidDate');
                    return this.createError({ message });
                }

                return true;
            },
        })
        .when('$location.tzid', (tzid, schema) => (
            schema
                .transform((value) => {
                    const date = value != null ? moment(value) : null;
                    if (date && date.isValid()) {
                        const dateStr = date.format('YYYY-MM-DDTHH:mm:ss');
                        return moment.tz(dateStr, tzid).utc().format('YYYY-MM-DDTHH:mm:ssZ');
                    }
                    return value;
                })
        )),
});

const DateSchema = yup.object().shape({
    response: yup.string()
        .test({
            message: '${path} must be a valid date', // eslint-disable-line no-template-curly-in-string
            name: 'date',
            exclusive: true,
            test(value) {
                if (value == null) {
                    return true;
                }

                if (!moment(value).isValid()) {
                    const message = i18next.t('validation.invalidDate');
                    return this.createError({ message });
                }

                return true;
            },
        })
        .transform((value) => {
            const date = value != null ? moment(value) : null;
            return date && date.isValid() ? date.format('YYYY-MM-DDT00:00:00') : value;
        }),
});

const TimeSchema = yup.object().shape({
    response: yup.number()
        .when('timeUnit', {
            is: 'minutes',
            then: yup.number()
                .transform((value) => (typeof value === 'number' ? value * 60 : value)),
        })
        .when('timeUnit', {
            is: 'hours',
            then: yup.number()
                .transform((value) => (typeof value === 'number' ? value * 3600 : value)),
        }),
    timeUnit: yup.string()
        .default('minutes')
        .oneOf(['minutes', 'hours']),
});

const PhotoSchema = yup.object().shape({
    response: yup.array()
        .of(
            yup.object().shape({
                photo_url: yup.string(),
            })
        ),
});

const SignatureSchema = yup.object().shape({
    response: yup.object().shape({
        photo_url: yup.string(),
    }),
});

const QuestionSchema = yup.object()
    .shape({
        response: yup.mixed()
            .transform((value, originalValue) => (originalValue === '' ? null : value))
            .nullable()
            .withMutation((schema) => (
                schema
                    .test({
                        message: '${path} is a required field', // eslint-disable-line no-template-curly-in-string
                        name: 'required',
                        exclusive: true,
                        test(value) {
                            const { context } = this.options;
                            const { is_required: isRequired } = context.dataType;

                            if (isRequired && value == null) {
                                const message = i18next.t('validation.required');
                                return this.createError({ message });
                            }

                            return true;
                        },
                    })
                    .test({
                        message: '${path} does not match the expected value', // eslint-disable-line no-template-curly-in-string
                        name: 'expectedValue',
                        exclusive: true,
                        test(value) {
                            if (value == null) {
                                return true;
                            }

                            const { context } = this.options;
                            const {
                                expected_value_expression: expectedValueExpression,
                                questions: {
                                    data_boundary_flexible: dataBoundaryFlexible,
                                },
                            } = context.dataType;

                            const condExpression = dataBoundaryFlexible === false
                                ? new CondExpression(expectedValueExpression)
                                : null;

                            if (condExpression && !condExpression.evaluate([value])) {
                                const options = { expression: condExpression.humanize() };
                                const message = i18next.t('ticketDetail.survey.validation.response.doesNotMatchExpectedValue', options);
                                return this.createError({ message });
                            }

                            return true;
                        },
                    })
            )),
    })
    .when('$dataType.value_type', {
        is: (valueType) => valueType === 'MULTIPLE_CHOICE' || valueType === 'MULTI_SELECT' || valueType === 'CHECKBOXES',
        then: MultipleChoiceSchema,
    })
    .when('$dataType.value_type', {
        is: 'NUMBER',
        then: NumberSchema,
    })
    .when('$dataType.value_type', {
        is: 'CURRENCY',
        then: CurrencySchema,
    })
    .when('$dataType.value_type', {
        is: 'PHONE_NUMBER',
        then: PhoneNumberSchema,
    })
    .when('$dataType.value_type', {
        is: 'FREE_TEXT',
        then: FreeTextSchema,
    })
    .when('$dataType.value_type', {
        is: 'BARCODE',
        then: BarcodeSchema,
    })
    .when('$dataType.value_type', {
        is: 'DATE_TIME',
        then: DateTimeSchema,
    })
    .when('$dataType.value_type', {
        is: 'DATE',
        then: DateSchema,
    })
    .when('$dataType.value_type', {
        is: 'TIME',
        then: TimeSchema,
    })
    .when('$dataType.value_type', {
        is: 'PHOTO',
        then: PhotoSchema,
    })
    .when('$dataType.value_type', {
        is: 'SIGNATURE',
        then: SignatureSchema,
    });

export default () => QuestionSchema;
