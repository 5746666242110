var Handlebars = require('/tmp/build_a9d70681abb25b82ca4489607bd6ccee/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"sub-target\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"for_target") || (depth0 != null ? lookupProperty(depth0,"for_target") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"for_target","hash":{},"data":data,"loc":{"start":{"line":5,"column":50},"end":{"line":5,"column":66}}}) : helper)))
    + ": "
    + alias4(((helper = (helper = lookupProperty(helpers,"targetName") || (depth0 != null ? lookupProperty(depth0,"targetName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"targetName","hash":{},"data":data,"loc":{"start":{"line":5,"column":68},"end":{"line":5,"column":84}}}) : helper)))
    + "</span><br>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- question type is photo -->\n<div class=\"type photo\" data-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":2,"column":33},"end":{"line":2,"column":41}}}) : helper)))
    + "\" data-targetid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"targetID") || (depth0 != null ? lookupProperty(depth0,"targetID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"targetID","hash":{},"data":data,"loc":{"start":{"line":2,"column":58},"end":{"line":2,"column":72}}}) : helper)))
    + "\">\n  <div class=\"answers\">\n    <div class=\"answer image\">\n      "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"targetName") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":6},"end":{"line":5,"column":103}}})) != null ? stack1 : "")
    + "\n      <div class=\"description\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"question_text") || (depth0 != null ? lookupProperty(depth0,"question_text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"question_text","hash":{},"data":data,"loc":{"start":{"line":6,"column":31},"end":{"line":6,"column":52}}}) : helper))) != null ? stack1 : "")
    + "</div>\n\n      <!-- the image the worker takes -->\n      <div class=\"center\">\n        <div class=\"placement\">\n          <label class=\"plainLabel\">\n            "
    + alias4(((helper = (helper = lookupProperty(helpers,"photo") || (depth0 != null ? lookupProperty(depth0,"photo") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"photo","hash":{},"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":12,"column":23}}}) : helper)))
    + "\n          </label>\n          <div class=\"wrapper\">\n            <a href=\"javascript:;\" class=\"bigButton photo\" data-action=\"uploadImage\">\n              "
    + alias4(((helper = (helper = lookupProperty(helpers,"add_photo") || (depth0 != null ? lookupProperty(depth0,"add_photo") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"add_photo","hash":{},"data":data,"loc":{"start":{"line":16,"column":14},"end":{"line":16,"column":29}}}) : helper)))
    + "\n            </a>\n          </div>\n          <div data-target=\"added\" data-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":19,"column":44},"end":{"line":19,"column":52}}}) : helper)))
    + "\"></div>\n        </div>\n      </div>\n\n    </div>\n  </div>\n</div><!-- END photo -->\n";
},"useData":true});