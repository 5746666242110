// @flow
export default () => ({
    root: {
        height: 26,
        borderRadius: 4,
    },
    label: {
        fontSize: 12.5,

        display: 'block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100%',

        '&$deletable': {
            width: 'calc(100% - 16px)',
        },
    },
    deleteIcon: {
        width: 16,
        height: 16,
    },
    deletable: {},
});
