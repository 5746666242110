// @flow
// $FlowIssue need to update to a more recent flow version
import React, { Fragment, forwardRef, useEffect, useState } from 'react';
import mime from 'mime';
import bytes from 'bytes';
import { useTransition, animated } from 'react-spring';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress, Paper } from '@material-ui/core';
import { File as FileIcon } from 'mdi-material-ui';
import UploadErrorIcon from './icons/UploadError';
import UploadSuccessIcon from './icons/UploadSuccess';

type Props = {
    file: File,
    progress: number,
    status: 'complete' | 'failed' | 'pending',
}

const styles = (theme: Object) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        backgroundColor: theme.palette.grey[300],
        borderRadius: 12,
        width: 120,
        height: 120,

        '&:hover': {
            '& $image > *': {
                transform: 'scale(1.05, 1.05)',
                filter: 'blur(8px)',
            },
            '& $details': {
                opacity: 1,
            },
        },
    },

    image: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 'inherit',
        width: '100%',
        height: '100%',
        overflow: 'hidden',

        '& > img': {
            objectFit: 'cover',
            width: '100%',
            height: '100%',
        },
    },

    extension: {
        textTransform: 'uppercase',
        fontWeight: 600,
        color: theme.palette.action.active,
    },

    details: {
        position: 'absolute',
        top: 0,
        left: 0,
        opacity: 0,
        width: '100%',
        height: '100%',
        padding: '2em 1em',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },

    size: {
        fontSize: 16,
        whiteSpace: 'nowrap',
        padding: '0 0.4em',
        borderRadius: 3,
        backgroundColor: 'rgba(255, 255, 255, 0.4)',
    },

    filename: {
        whiteSpace: 'nowrap',
        padding: '0 0.4em',
        borderRadius: 3,

        '&:not(:hover)': {
            width: '90%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            backgroundColor: 'rgba(255, 255, 255, 0.4)',
        },

        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
        },
    },

    progress: {
        position: 'absolute',
        left: '50%',
        top: '75%',
        width: 80,
        height: 10,
        marginLeft: -40,
    },

    progressBar: {
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        borderRadius: 8,
    },
});

const useStyles = makeStyles(styles, { name: 'Preview' });

export const Preview = forwardRef((props: Props, ref: Object) => {
    const { file, progress, status } = props;

    const classes = useStyles(props);
    const [dataURL, setDataURL] = useState(null);
    const [statusIconVisible, setStatusIconVisible] = useState(true);

    let statusIcon = null;
    if (status === 'failed') {
        statusIcon = <UploadErrorIcon fontSize="inherit" />;
    } else if (status === 'complete') {
        statusIcon = <UploadSuccessIcon fontSize="inherit" />;
    }

    const transitions = useTransition(statusIconVisible ? statusIcon : null, null, {
        from: {
            position: 'absolute',
            lineHeight: 0,
            fontSize: 54,
            bottom: 0,
            opacity: 0,
        },
        enter: { bottom: 33, opacity: 1 },
        leave: { bottom: 66, opacity: 0 },
    });

    useEffect(() => {
        if (status === 'complete' || status === 'failed') {
            setStatusIconVisible(true);
            setTimeout(() => {
                setStatusIconVisible(false);
            }, 2000);
        }
    }, [status]);

    useEffect(() => {
        const reader = new FileReader();
        reader.onload = () => {
            setDataURL(reader.result);
        };
        reader.readAsDataURL(file);
    }, [file]);

    const mimeType = file.type || mime.getType(file.name);
    const extension = mime.getExtension(file.type);
    let image;

    if (dataURL && mimeType.startsWith('image')) {
        image = <img alt={file.name} src={dataURL} />;
    } else {
        image = (
            <Fragment>
                <FileIcon color="action" fontSize="large" />
                <div className={classes.extension}>{extension}</div>
            </Fragment>
        );
    }

    return (
        <Paper className={classes.root} elevation={0} ref={ref}>
            <div className={classes.image}>{image}</div>
            <div className={classes.details}>
                <span className={classes.size}>{bytes(file.size)}</span>
                <span className={classes.filename}>{file.name}</span>
            </div>
            <div className={classes.progress}>
                {status === 'pending'
                    ? <LinearProgress className={classes.progressBar} value={progress} variant="determinate" />
                    : null}
            </div>
            {transitions.map((transition) => (
                <animated.div key={transition.key} style={transition.props}>
                    {transition.item}
                </animated.div>
            ))}
        </Paper>
    );
});

export default Preview;
