// @flow
import mergeWith from 'lodash/mergeWith';
import reduceReducers from 'reduce-reducers';
import type { ActionType } from 'redux-actions';
import auditEvents from './auditEvents';
import certifications from './certifications';
import customers from './customers';
import dataTypes from './dataTypes';
import groups from './groups';
import locationLists from './locationLists';
import locations from './locations';
import notifications from './notifications';
import organizations from './organizations';
import payouts from './payouts';
import subscriptions from './subscriptions';
import tickets from './tickets';
import { init } from './initialState';
import type { State } from './initialState';

const customizer = (objValue: any, srcValue: any, key: string): any => {
    if (Array.isArray(objValue) || key.includes('metadata')) {
        return srcValue;
    }
};

export function updateEntities(state: State = init, action: ActionType<any>): State {
    if (action.payload && action.payload.entities) {
        return mergeWith({}, state, action.payload.entities, customizer);
    }
    return state;
}

export default reduceReducers(
    updateEntities, // keep updateEntities at the top to ensure the new/updated entities are merged before any of the other reducers read/update the state
    auditEvents,
    certifications,
    customers,
    dataTypes,
    groups,
    locationLists,
    locations,
    notifications,
    organizations,
    payouts,
    subscriptions,
    tickets,
);
