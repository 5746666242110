// @flow
// $FlowIssue need to update to a more recent flow version
import React, { forwardRef, useCallback, useRef } from 'react';
import qs from 'qs';
import { IconButton, Toolbar, Typography } from '@material-ui/core';
import {
    Close as CloseIcon,
    CloudDownload as CloudDownloadIcon,
    Edit as EditIcon,
    Info as InfoIcon,
    RotateLeft as RotateLeftIcon,
    RotateRight as RotateRightIcon,
    ZoomIn as ZoomInIcon,
    ZoomOut as ZoomOutIcon,
} from '@material-ui/icons';
import Carousel from './Carousel';
import Image from './Image';

type Props = {
    classes: Object,
    images: Array<{
        src: string,
        title?: string,
        description?: string,
    }>,
    currentImage: number,
    onChange: (index: number) => void,
    onClose: () => void,
    onEditClick?: (index: number) => void,
    onInfoClick?: (index: number) => void,
};

export const Container = forwardRef((props: Props, ref: Object) => {
    const { images, classes, currentImage, onClose, onChange, onEditClick, onInfoClick } = props;

    const imageRef = useRef(null);

    const handleEditClick = useCallback(() => {
        if (onEditClick) {
            onEditClick(currentImage);
        }
    }, [onEditClick, currentImage]);

    const handleInfoClick = useCallback(() => {
        if (onInfoClick) {
            onInfoClick(currentImage);
        }
    }, [onInfoClick, currentImage]);

    const handleControlClick = useCallback((event: SyntheticEvent<any>) => {
        const image = imageRef.current;
        switch (event.currentTarget.name) {
            case 'rotateRight':
                // @todo Consider using a initialRotate/orientation prop to control rotation
                // Then we could make the Image component a functional component
                image.rotate(90);
                break;
            case 'rotateLeft':
                image.rotate(-90);
                break;
            case 'zoomIn':
                image.zoom(1, 5);
                break;
            case 'zoomOut':
                image.zoom(-1, 5);
                break;
            default:
                break;
        }
    }, []);

    const img = images[currentImage];
    const downloadLink = img ? `/download?${qs.stringify({ url: img.src })}` : '';
    const showEditButton = onEditClick != null;

    return (
        <div className={classes.container} ref={ref}>
            <Toolbar className={classes.toolbar} variant="dense">
                <IconButton className={classes.closeButton} color="inherit" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
                <IconButton color="inherit" name="zoomIn" onClick={handleControlClick}>
                    <ZoomInIcon />
                </IconButton>
                <IconButton color="inherit" name="zoomOut" onClick={handleControlClick}>
                    <ZoomOutIcon />
                </IconButton>
                <IconButton color="inherit" name="rotateRight" onClick={handleControlClick}>
                    <RotateRightIcon />
                </IconButton>
                <IconButton color="inherit" name="rotateLeft" onClick={handleControlClick}>
                    <RotateLeftIcon />
                </IconButton>
                <IconButton color="inherit" href={downloadLink}>
                    <CloudDownloadIcon />
                </IconButton>
                {showEditButton
                    ? (
                        <IconButton color="inherit" onClick={handleEditClick}>
                            <EditIcon />
                        </IconButton>
                    )
                    : null}
                {onInfoClick
                    ? (
                        <IconButton color="inherit" onClick={handleInfoClick}>
                            <InfoIcon />
                        </IconButton>
                    )
                    : null}
            </Toolbar>
            <Carousel
              currentSlide={currentImage}
              className={classes.carousel}
              infinite={false}
              onNextClick={(index: number) => onChange(index)}
              onPrevClick={(index: number) => onChange(index)}
            >
                {images.map((image, index) => (
                    <Image innerRef={index === currentImage ? imageRef : null} key={index} src={image.src} />
                ))}
            </Carousel>
            <div className={classes.info}>
                <Typography className={classes.title} variant="h6">
                    {img ? img.title : null}
                </Typography>
                <Typography className={classes.description} variant="body2">
                    {img ? img.description : null}
                </Typography>
            </div>
        </div>
    );
});

Container.defaultProps = {
    images: [],
    currentImage: 0,
    onClose: () => {},
    onChange: () => {},
};

export default Container;
