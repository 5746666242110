// @flow
import { normalize } from 'normalizr';
import { createAction, handleActions } from 'redux-actions';
import identity from 'lodash/identity';
import omitBy from 'lodash/omitBy';
import uniqBy from 'lodash/uniqBy';
import type { Dispatch } from 'redux';
import type { ActionType } from 'redux-actions';
import type { $AxiosXHR, $AxiosError } from 'axios';
import type { APIResponse, APIPromise } from 'gigwalk/lib/api/resource';
import logger from '../../util/logger';
import { client as gigwalk } from '../../api/createGigwalkClient';
import {
    customer as customerSchema,
    ticket as ticketSchema,
} from './schemas';
import { init } from './initialState';
import type { State } from './initialState';

// Actions
// -------
export const FETCH = 'g/tickets/FETCH';
export const FETCH_SUCCESS = `${FETCH}_SUCCESS`;
export const FETCH_ERROR = `${FETCH}_ERROR`;

export const SEARCH = 'g/tickets/SEARCH';
export const SEARCH_SUCCESS = `${SEARCH}_SUCCESS`;
export const SEARCH_ERROR = `${SEARCH}_ERROR`;

export const UPDATE = 'g/tickets/UPDATE';
export const UPDATE_SUCCESS = `${UPDATE}_SUCCESS`;
export const UPDATE_ERROR = `${UPDATE}_ERROR`;

export const SUBMIT = 'g/tickets/SUBMIT';
export const SUBMIT_SUCCESS = `${SUBMIT}_SUCCESS`;
export const SUBMIT_ERROR = `${SUBMIT}_ERROR`;

export const CREATE_DATA_ITEM = 'g/tickets/CREATE_DATA_ITEM';
export const CREATE_DATA_ITEM_SUCCESS = `${CREATE_DATA_ITEM}_SUCCESS`;
export const CREATE_DATA_ITEM_ERROR = `${CREATE_DATA_ITEM}_ERROR`;

export const DELETE_DATA_ITEM = 'g/tickets/DELETE_DATA_ITEM';
export const DELETE_DATA_ITEM_SUCCESS = `${DELETE_DATA_ITEM}_SUCCESS`;
export const DELETE_DATA_ITEM_ERROR = `${DELETE_DATA_ITEM}_ERROR`;

export const GET_APPLICANTS = 'g/tickets/GET_APPLICANTS';
export const GET_APPLICANTS_SUCCESS = `${GET_APPLICANTS}_SUCCESS`;
export const GET_APPLICANTS_ERROR = `${GET_APPLICANTS}_ERROR`;

export const UPDATE_APPLICATION = 'g/tickets/UPDATE_APPLICATION';
export const UPDATE_APPLICATION_SUCCESS = `${UPDATE_APPLICATION}_SUCCESS`;
export const UPDATE_APPLICATION_ERROR = `${UPDATE_APPLICATION}_ERROR`;

export const GET_EVENTS = 'g/tickets/GET_EVENTS';
export const GET_EVENTS_SUCCESS = `${GET_EVENTS}_SUCCESS`;
export const GET_EVENTS_ERROR = `${GET_EVENTS}_ERROR`;

export const CREATE_EVENT = 'g/tickets/CREATE_EVENT';
export const CREATE_EVENT_SUCCESS = `${CREATE_EVENT}_SUCCESS`;
export const CREATE_EVENT_ERROR = `${CREATE_EVENT}_ERROR`;

export const DELETE_EVENT = 'g/tickets/DELETE_EVENT';
export const DELETE_EVENT_SUCCESS = `${DELETE_EVENT}_SUCCESS`;
export const DELETE_EVENT_ERROR = `${DELETE_EVENT}_ERROR`;

export const APPLY = 'g/tickets/APPLY';
export const APPLY_SUCCESS = `${APPLY}_SUCCESS`;
export const APPLY_ERROR = `${APPLY}_ERROR`;

export const WITHDRAW = 'g/tickets/WITHDRAW';
export const WITHDRAW_SUCCESS = `${WITHDRAW}_SUCCESS`;
export const WITHDRAW_ERROR = `${WITHDRAW}_ERROR`;

export const CREATE_RATING = 'g/tickets/CREATE_RATING';
export const CREATE_RATING_SUCCESS = `${CREATE_RATING}_SUCCESS`;
export const CREATE_RATING_ERROR = `${CREATE_RATING}_ERROR`;

export const UPDATE_RATING = 'g/tickets/UPDATE_RATING';
export const UPDATE_RATING_SUCCESS = `${UPDATE_RATING}_SUCCESS`;
export const UPDATE_RATING_ERROR = `${UPDATE_RATING}_ERROR`;

const mapESRecordToTicket = (record: Object) => {
    const {
        assigned_customer_email: assigneeEmail,
        assigned_customer_first_name: assigneeFirstName,
        assigned_customer_id: assigneeId,
        assigned_customer_last_name: assigneeLastName,
        assigned_customer_name: assigneeName,
        calendar_event_end: calendarEventEnd,
        calendar_event_event_type: calendarEventType,
        calendar_event_id: calendarEventId,
        calendar_event_start: calendarEventStart,
        is_double_optin: subscriptionIsDoubleOptin,
        location_administrative_area_level_1: locationAdministrativeAreaLevel1,
        location_administrative_area_level_2: locationAdministrativeAreaLevel2,
        location_country: locationCountry,
        location_date_created: locationDateCreated,
        location_date_updated: locationDateUpdated,
        location_formatted_address: locationFormattedAddress,
        location_geopoint: locationGeopoint,
        location_id: locationId,
        location_locality: locationLocality,
        location_organization_id: locationOrganizationId,
        location_postal_code: locationPostalCode,
        location_specificity: locationSpecificity,
        location_status: locationStatus,
        location_title: locationTitle,
        location_tzid: locationTzId,
        needs_public_workforce: subscriptionNeedsPublicWorkforce,
        optin_type: subscriptionOptinType,
        organization_subscription_can_reschedule: canReschedule,
        organization_subscription_description: description,
        organization_subscription_groups: subscriptionGroups,
        organization_subscription_id: subscriptionId,
        ticket_id: id,
        ticket_status: status,
        title,
        ...ticket
    } = record;

    const latLng = locationGeopoint.split(',');
    const customer = assigneeId
        ? { email: assigneeEmail, first_name: assigneeFirstName, id: assigneeId, last_name: assigneeLastName }
        : null;

    return {
        ...ticket,
        assigned_customer_email: assigneeEmail,
        assigned_customer_id: assigneeId,
        assigned_customer_name: assigneeName,
        calendar_event: {
            end: calendarEventEnd || null,
            event_type: calendarEventType || null,
            id: calendarEventId || 0,
            start: calendarEventStart || null,
        },
        can_reschedule: canReschedule,
        customer,
        description,
        id,
        location: {
            administrative_area_level_1: locationAdministrativeAreaLevel1,
            administrative_area_level_2: locationAdministrativeAreaLevel2,
            country: locationCountry,
            date_created: locationDateCreated,
            date_updated: locationDateUpdated,
            formatted_address: locationFormattedAddress,
            id: locationId,
            latitude: parseFloat(latLng[0]),
            locality: locationLocality,
            longitude: parseFloat(latLng[1]),
            organization_id: locationOrganizationId,
            postal_code: locationPostalCode,
            specificity: locationSpecificity,
            status: locationStatus,
            title: locationTitle,
            tzid: locationTzId,
        },
        status,
        subscription: {
            can_reschedule: canReschedule,
            description,
            groups: subscriptionGroups,
            id: subscriptionId,
            is_double_optin: subscriptionIsDoubleOptin,
            needs_public_workforce: subscriptionNeedsPublicWorkforce,
            optin_type: subscriptionOptinType,
            title,
        },
        subscription_id: subscriptionId,
        title,
    };
};

// Action Creators
// ---------------
export const fetchSuccess = createAction(FETCH_SUCCESS);
export const fetchError = createAction(FETCH_ERROR);
export const fetch = createAction(
    FETCH,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): APIPromise<Object[]> => (
            gigwalk.client.get(`/v1/tickets/${params.ticket_id}`)
                .then((resp: $AxiosXHR<APIResponse<Object>>) => {
                    const { data } = resp.data;
                    data.customer = data.customer || null; // customer field won't be present unless ticket is assigned
                    const normalized = normalize(resp.data.data, ticketSchema);
                    dispatch(fetchSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(fetchError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export const searchSuccess = createAction(SEARCH_SUCCESS);
export const searchError = createAction(SEARCH_ERROR);
export const search = createAction(
    SEARCH,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): APIPromise<Object[]> => {
            const { offset, limit, ...data } = params;
            const config = {
                params: omitBy({ offset, limit }, (value) => value == null),
            };
            return gigwalk.client.post('/v1/search/tickets', data, config)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    // Results returned by this endpoint now follow the normal ticket schema,
                    // but we should check the response just in case we are running against an
                    // older API version
                    let results = resp.data.data || [];
                    if (results[0] && results[0].hasOwnProperty('location_id')) {
                        results = results.map(mapESRecordToTicket);
                    }

                    const normalized = normalize(results, [ticketSchema]);
                    normalized.metadata = resp.data._metadata;
                    dispatch(searchSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(searchError(err));
                    return Promise.reject(err);
                });
        }
    )
);

// For reference only - need to refactor entities and figure out where this should live
// type Normalized<T> = {
//     result: number[],
//     entities: Object[],
//     metadata?: Object,
//     original: APIResponse<T>,
// }

export const updateSuccess = createAction(UPDATE_SUCCESS);
export const updateError = createAction(UPDATE_ERROR);
export const update = createAction(
    UPDATE,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): APIPromise<Object[]> => (
            gigwalk.client.put('/v1/tickets', params)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalize(resp.data.data, [ticketSchema]);
                    normalized.original = resp.data;
                    dispatch(updateSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(updateError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export const submitSuccess = createAction(SUBMIT_SUCCESS);
export const submitError = createAction(SUBMIT_ERROR);
export const submit = createAction(
    SUBMIT,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): APIPromise<Object[]> => (
            gigwalk.client.post(`/v1/tickets/${params.ticket_id}/submit`)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    // API only responds with the ticket id, so we'll need to fetch the ticket
                    // again to get updated info.
                    const normalized = normalize(resp.data.data, [{}]);
                    normalized.original = resp.data;
                    dispatch(submitSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(submitError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export const createDataItemSuccess = createAction(CREATE_DATA_ITEM_SUCCESS);
export const createDataItemError = createAction(CREATE_DATA_ITEM_ERROR);
export const createDataItem = createAction(
    CREATE_DATA_ITEM,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): APIPromise<Object[]> => {
            const { ticket_id: ticketId, ...payload } = params;
            return gigwalk.client.post(`/v1/tickets/${ticketId}/data_items`, payload)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    // API responds with the data_item id, so we'll need to fetch the ticket
                    // again to get updated info.
                    const normalized = normalize(resp.data.data, [{}]);
                    normalized.original = resp.data;
                    dispatch(createDataItemSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(createDataItemError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const deleteDataItemSuccess = createAction(DELETE_DATA_ITEM_SUCCESS);
export const deleteDataItemError = createAction(DELETE_DATA_ITEM_ERROR);
export const deleteDataItem = createAction(
    DELETE_DATA_ITEM,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): APIPromise<Object[]> => (
            gigwalk.client.delete(`/v1/tickets/${params.ticket_id}/data_items/${params.data_item_id}`)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    // API responds with the data_item id, so we'll need to fetch the ticket
                    // again to get updated info.
                    const normalized = normalize(resp.data.data, [{}]);
                    normalized.original = resp.data;
                    dispatch(deleteDataItemSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(deleteDataItemError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export const getApplicantsSuccess = createAction(GET_APPLICANTS_SUCCESS, identity, (data: Object, meta: Object): Object => meta);
export const getApplicantsError = createAction(GET_APPLICANTS_ERROR);
export const getApplicants = createAction(
    GET_APPLICANTS,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): APIPromise<Object[]> => {
            const { limit, offset } = params;
            const config = {
                params: omitBy({ offset, limit }, (value) => value == null),
            };
            return gigwalk.client.get(`/v1/tickets/${params.ticket_id}/applicants`, config)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    // This endpoint returns a list of applicants for a ticket, as well as some information
                    // about each customer's application. Since a customer can apply to multiple tickets,
                    // it's desirable to normalize the data such that each customer has an `applications`
                    // attribute instead of the singular `application` returned in the response
                    // @todo Investigate moving this logic to schema definition using processStrategy option
                    const data = resp.data.data.map((customer: Object) => {
                        const { application, ...other } = customer;
                        if (application) {
                            return {
                                ...other,
                                applications: [{
                                    ...application,
                                    cid: `${customer.id}_${params.ticket_id}`, // create a client id that will uniquely identify this application
                                    ticket: params.ticket_id,
                                }],
                            };
                        }
                        return customer;
                    });
                    const normalized = normalize(data, [customerSchema]);
                    normalized.original = resp.data;
                    dispatch(getApplicantsSuccess(normalized, { ticket_id: params.ticket_id }));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(getApplicantsError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const updateApplicationSuccess = createAction(UPDATE_APPLICATION_SUCCESS);
export const updateApplicationError = createAction(UPDATE_APPLICATION_ERROR);
export const updateApplication = createAction(
    UPDATE_APPLICATION,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): APIPromise<Object[]> => {
            const { ticket_id: ticketId, ...data } = params;
            return gigwalk.client.put(`/v1/tickets/${ticketId}/applicants`, data)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    // @todo Find and update application for the customer
                    const normalized = normalize(resp.data.data, [{}]);
                    dispatch(updateApplicationSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(updateApplicationError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const getEventsSuccess = createAction(GET_EVENTS_SUCCESS, identity, (data: Object, meta: Object): Object => meta);
export const getEventsError = createAction(GET_EVENTS_ERROR);
export const getEvents = createAction(
    GET_EVENTS,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): APIPromise<Object[]> => {
            const { limit, offset } = params;
            const config = {
                params: omitBy({ offset, limit }, (value) => value == null),
            };
            return gigwalk.client.get(`/v1/tickets/${params.ticket_id}/events`, config)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalize(resp.data.data, [{}]);
                    normalized.original = resp.data;
                    dispatch(getEventsSuccess(normalized, { ticket_id: params.ticket_id }));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(getEventsError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const createEventSuccess = createAction(CREATE_EVENT_SUCCESS, identity, (data: Object, meta: Object): Object => meta);
export const createEventError = createAction(CREATE_EVENT_ERROR);
export const createEvent = createAction(
    CREATE_EVENT,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): APIPromise<Object[]> => {
            const { ticket_id: ticketId, ...payload } = params;
            return gigwalk.client.post(`/v1/tickets/${ticketId}/events`, payload)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalize(resp.data.data, [{}]);
                    dispatch(createEventSuccess(normalized, { ticket_id: ticketId }));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(createEventError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const deleteEventSuccess = createAction(DELETE_EVENT_SUCCESS, identity, (data: Object, meta: Object): Object => meta);
export const deleteEventError = createAction(DELETE_EVENT_ERROR);
export const deleteEvent = createAction(
    DELETE_EVENT,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): APIPromise<void> => (
            gigwalk.client.delete(`/v1/ticket_events/${params.ticket_event_id}`)
                .then(() => {
                    dispatch(deleteEventSuccess(null, { ticket_event_id: params.ticket_event_id }));
                    return null;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(deleteEventError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export const applySuccess = createAction(APPLY_SUCCESS, identity, (data: Object, meta: Object): Object => meta);
export const applyError = createAction(APPLY_ERROR);
export const apply = createAction(
    APPLY,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): APIPromise<Object[]> => {
            const { ticket_id: ticketId, ...payload } = params;
            return gigwalk.client.post(`/v1/tickets/${ticketId}/applicants`, payload)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    // @todo Add application for the customer
                    const normalized = normalize(resp.data.data, [{}]);
                    dispatch(applySuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(applyError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const withdrawSuccess = createAction(WITHDRAW_SUCCESS, identity, (data: Object, meta: Object): Object => meta);
export const withdrawError = createAction(WITHDRAW_ERROR);
export const withdraw = createAction(
    WITHDRAW,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): APIPromise<Object[]> => (
            gigwalk.client.delete(`/v1/tickets/${params.ticket_id}/applicants`)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    // @todo Find and remove application for the customer
                    const normalized = normalize(resp.data.data, [{}]);
                    dispatch(withdrawSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(withdrawError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export const createRatingSuccess = createAction(CREATE_RATING_SUCCESS, identity, (data: Object, meta: Object): Object => meta);
export const createRatingError = createAction(CREATE_RATING_ERROR);
export const createRating = createAction(
    CREATE_RATING,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): APIPromise<Object[]> => {
            const {
                ticket_id: ticketId,
                ...payload
            } = params;
            return gigwalk.client.post(`/v1/ratings/ticket/${ticketId}`, payload)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalize(resp.data.data, [{}]);
                    dispatch(createRatingSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(createRatingError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const updateRatingSuccess = createAction(UPDATE_RATING_SUCCESS, identity, (data: Object, meta: Object): Object => meta);
export const updateRatingError = createAction(UPDATE_RATING_ERROR);
export const updateRating = createAction(
    UPDATE_RATING,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): APIPromise<Object[]> => {
            const {
                customer_id: customerId,
                ticket_id: ticketId,
                ...payload
            } = params;
            return gigwalk.client.put(`/v1/ratings/ticket/${ticketId}/worker/${customerId}`, payload)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalize(resp.data.data, [{}]);
                    dispatch(updateRatingSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(updateRatingError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export default handleActions({
    [GET_APPLICANTS_SUCCESS]: (state: State, action: ActionType<typeof getApplicantsSuccess>): State => {
        const { meta, payload } = action;
        const ticketId = meta.ticket_id;
        const ticket = state.tickets[ticketId];
        const applicants = ticket.applicants || [];

        return {
            ...state,
            tickets: {
                ...state.tickets,
                [ticketId]: {
                    ...ticket,
                    // Since applicants are normalized to an array of customer ids, we can use
                    // a Set to prevent duplicates
                    applicants: Array.from(new Set([...applicants, ...payload.result])),
                },
            },
        };
    },
    [GET_EVENTS_SUCCESS]: (state: State, action: ActionType<typeof getEventsSuccess>): State => {
        const { meta, payload } = action;
        const ticketId = meta.ticket_id;
        const ticket = state.tickets[ticketId];
        const events = ticket ? ticket.events || [] : [];

        return {
            ...state,
            tickets: {
                ...state.tickets,
                [ticketId]: {
                    ...ticket,
                    events: uniqBy([...events, ...payload.result], (event) => event.id),
                },
            },
        };
    },
    [CREATE_EVENT_SUCCESS]: (state: State, action: ActionType<typeof createEventSuccess>): State => {
        const { meta, payload } = action;
        const ticketId = meta.ticket_id;
        const ticket = state.tickets[ticketId];
        const ticketEvents = ticket ? ticket.events || [] : [];

        return {
            ...state,
            tickets: {
                ...state.tickets,
                [ticketId]: {
                    ...ticket,
                    events: [
                        ...payload.result,
                        ...ticketEvents,
                    ],
                },
            },
        };
    },
    [DELETE_EVENT_SUCCESS]: (state: State, action: ActionType<typeof deleteEventSuccess>): State => {
        const { meta } = action;
        const { tickets } = state;
        const eventId = meta.ticket_event_id;

        // Remove deleted event from all tickets that include this event (should only by 1 such ticket)
        const updatedTickets = { ...tickets };
        Object.entries(tickets).forEach(([id, ticket]) => {
            // $FlowIssue https://github.com/facebook/flow/issues/2174
            const events = ticket.events || [];
            const index = events.findIndex((event: Object): boolean => event.id === eventId);
            if (index !== -1) {
                updatedTickets[id] = {
                    ...ticket,
                    events: [
                        ...events.slice(0, index),
                        ...events.slice(index + 1),
                    ],
                };
            }
        });

        return {
            ...state,
            tickets: updatedTickets,
        };
    },
}, init);
