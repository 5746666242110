// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Table as MuiTable } from '@material-ui/core';
import cx from 'classnames';
import type { Node } from 'react';

type Props = {
    children: Node,
    classes: Object,
    className?: string,
    data: Object[],
    loading: boolean,
    selected: any[],
};

export function Table(props: Props) {
    const { children, classes, className, data, loading, selected, ...other } = props;

    const { t } = useTranslation();

    let overlay = null;
    if (loading) {
        overlay = <div className={classes.loading}><CircularProgress /></div>;
    } else if (data.length === 0) {
        overlay = <div className={classes.noData}>{t('table.noData')}</div>;
    }

    return (
        <div className={cx(classes.table, className)} {...other}>
            {overlay}
            <MuiTable className={classes.tableInner} component="div">
                {children}
            </MuiTable>
        </div>
    );
}

export default Table;
