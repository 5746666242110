// @flow
export default (theme: Object) => ({
    root: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
    },
    question: {
        flex: 1,
    },
    metadata: {
        flex: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        '& > :not(:last-child)': {
            marginBottom: theme.spacing(2),
        },
    },
    field: {
        width: 200,
    },
});
