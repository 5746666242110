// @flow
import { normalize } from 'normalizr';
import { createAction } from 'redux-actions';
import omitBy from 'lodash/omitBy';
import type { Dispatch } from 'redux';
import type { $AxiosXHR, $AxiosError } from 'axios';
import type { APIResponse, APIPromise } from 'gigwalk/lib/api/resource';
import logger from '../../util/logger';
import { client as gigwalk } from '../../api/createGigwalkClient';
import { init } from './initialState';
import { notification as notificationSchema } from './schemas';
import type { State } from './initialState';

// Actions
// -------
export const LIST = 'g/notifications/LIST';
export const LIST_SUCCESS = `${LIST}_SUCCESS`;
export const LIST_ERROR = `${LIST}_ERROR`;

export const UPDATE = 'g/notifications/UPDATE';
export const UPDATE_SUCCESS = `${UPDATE}_SUCCESS`;
export const UPDATE_ERROR = `${UPDATE}_ERROR`;

// Action Creators
// ---------------
export const listSuccess = createAction(LIST_SUCCESS);
export const listError = createAction(LIST_ERROR);
export const list = createAction(
    LIST,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): APIPromise<Object[]> => {
            const { limit, offset, sort_field: sortField, sort_order: sortOrder, ...data } = params;
            const config = {
                params: omitBy({ limit, offset, sort_field: sortField, sort_order: sortOrder }, (value) => value == null),
            };
            return gigwalk.client.post('v1/notifications', data, config)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalize(resp.data.data, [notificationSchema]);
                    normalized.metadata = resp.data._metadata;
                    normalized.original = resp.data;
                    dispatch(listSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(listError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const updateSuccess = createAction(UPDATE_SUCCESS);
export const updateError = createAction(UPDATE_ERROR);
export const update = createAction(
    UPDATE,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): APIPromise<Object[]> => {
            const { notification_id: id, ...data } = params;
            return gigwalk.client.put(`v1/notifications/${id}`, data)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalize(resp.data.data, [notificationSchema]);
                    dispatch(updateSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(updateError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export default function(state: State = init): State {
    return state;
}
