// @flow
export default (theme: Object) => ({
    root: {},
    content: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    tagIcon: {
        color: theme.palette.grey[400],
        transform: 'scaleX(-1)',
        alignSelf: 'flex-start',
        marginRight: 16,
    },
    tagList: {
        margin: -3,

        '& > *': {
            margin: 3,
        },
    },
    noTags: {
        alignSelf: 'center',
        fontStyle: 'italic',
        color: theme.palette.text.hint,
    },
    addTagButton: {
        fontSize: 14,
        fontWeight: 600,
        marginLeft: theme.spacing(1),
    },
    dropdownAnchor: {
        flexShrink: 0,
        marginLeft: 'auto',
    },
    dropdownPaper: {
        padding: theme.spacing(1.5),
        height: 250,
        width: 350,
    },
});
