// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import sanitizeHtml from 'sanitize-html';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import {
    FormControl,
    FormHelperText,
    FormLabel,
    InputLabel,
} from '@material-ui/core';
import Editor from '../Editor';
import styles from './styles';

type Props = {
    classes?: Object,
    className?: string,
    disabled?: boolean,
    fullWidth?: boolean,
    label?: string,
    labelPlacement: 'default' | 'top',
    margin?: string,
    required?: boolean,
    variant: 'standard' | 'outlined' | 'filled',
};

const useStyles = makeStyles(styles, { name: 'FormikEditor' });

export default function FormikEditor(props: Props) {
    const {
        classes: classesProp,
        className,
        disabled,
        fullWidth,
        label,
        labelPlacement,
        margin,
        required,
        variant,
        ...other
    } = props;

    const classes = useStyles(props);
    const { i18n, t } = useTranslation();
    const [field, meta] = useField(props);

    const [focused, setFocused] = useState(false);
    const isMounted = useRef(false);

    const { error, touched } = meta;
    const { name, onBlur, onChange, value } = field;

    const shrink = useMemo(() => (
        focused || (value && sanitizeHtml(value, { allowedTags: [], allowedAttributes: [] }) !== '')
    ), [focused, value]);

    const handleChange = useCallback((newValue) => {
        // The PluginsEditor will call onChange on componentWillMount. Calling setFieldValue during the
        // rendering phase will throw an error, so this protects against that.
        if (isMounted.current) {
            const fakeEvent = { target: { value: newValue, name } };
            onChange(fakeEvent);
        }
    }, [name, onChange]);

    const handleBlur = useCallback(() => {
        const fakeEvent = { target: { name } };
        onBlur(fakeEvent);
        setFocused(false);
    }, [name, onBlur]);

    const handleFocus = useCallback(() => {
        setFocused(true);
    }, []);

    let errorMessage = null;
    if (touched && error) {
        if (typeof error === 'string') {
            errorMessage = i18n.exists(error) ? t(error) : error;
        } else if ('message' in error) {
            const { message, ...options } = error;
            errorMessage = i18n.exists(message) ? t(message, { ...options }) : message;
        }
    }

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    const {
        root: rootClassName,
        editor: editorClassName,
        errorMessage: errorClassName,
        ...otherClasses
    } = classes;

    const LabelComponent = labelPlacement === 'top' ? FormLabel : InputLabel;
    const labelProps = LabelComponent === InputLabel ? { shrink } : {};

    return (
        <FormControl
          className={cx(rootClassName, className)}
          disabled={disabled}
          error={!!errorMessage}
          fullWidth={fullWidth}
          margin={margin}
          required={required}
          variant={variant}
        >
            {label ? <LabelComponent {...labelProps}>{label}</LabelComponent> : null}
            <Editor
              {...other}
              {...field}
              classes={{ root: editorClassName, ...otherClasses }}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              variant={variant}
            />
            {errorMessage
                ? <FormHelperText className={errorClassName}>{errorMessage}</FormHelperText>
                : null}
        </FormControl>
    );
}

FormikEditor.defaultProps = {
    labelPlacement: 'default',
    variant: 'standard',
};
