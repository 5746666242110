// @flow
import React from 'react';

export default function Input(props: Object) {
    const { cx: classnames, innerRef, isDisabled, isHidden, getStyles, selectProps, ...other } = props;
    const { classes } = selectProps;

    return (
        <input
          className={classes.inputElement}
          disabled={isDisabled}
          ref={innerRef}
          style={{ opacity: isHidden ? 0 : 1 }}
          {...other}
        />
    );
}
