// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useEffect } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { hoistStatics } from 'recompose';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Button, Dialog, DialogTitle } from '@material-ui/core';
import {
    Clear as ClearIcon,
    Person as PersonIcon,
} from '@material-ui/icons';
import type { Dispatch } from 'redux';
import type { Connector } from 'react-redux';
import AssignApplicantForm from './components/AssignApplicantForm';
import * as dialog from '../../ducks/dialog';
import { actions } from './duck';
import styles from './styles';
import type { State as RootState } from '../../redux/initialState';

type OwnProps = {};
type StateProps = {
    onClose: () => void,
    onSubmitFail: () => void,
    onSubmitSuccess: () => void,
    open: boolean,
    ticketId: ?number,
};
type DispatchProps = {
    registerDialog: (name: string) => void,
    submit: (values: Object) => Promise<void>,
    unregisterDialog: (name: string) => void,
};
type Props = OwnProps & StateProps & DispatchProps;

const AssignApplicantSchema = yup.object().shape({
    assignee: yup.object()
        .required('validation.required'),
    ticketId: yup.number(),
});

const useStyles = makeStyles(styles, { name: 'AssignApplicantDialog' });

export function AssignApplicantDialog(props: Props) {
    const {
        onClose,
        onSubmitFail,
        onSubmitSuccess,
        open,
        registerDialog,
        submit,
        ticketId,
        unregisterDialog,
    } = props;

    const classes = useStyles(props);
    const { t } = useTranslation();

    useEffect(() => {
        registerDialog(AssignApplicantDialog.NAME);
        return () => {
            unregisterDialog(AssignApplicantDialog.NAME);
        };
    }, [registerDialog, unregisterDialog]);

    return (
        <Formik
          enableReinitialize
          initialValues={{ assignee: null, ticketId }}
          onSubmit={(values, { setStatus }) => (
              submit(values)
                  .then(() => {
                      setStatus({ success: true });
                      onSubmitSuccess();
                  })
                  .catch((error) => {
                      setStatus({ error });
                      onSubmitFail();
                      return Promise.reject(error);
                  })
          )}
          validationSchema={AssignApplicantSchema}
        >
            {(formikProps: Object) => {
                const { handleReset } = formikProps;
                return (
                    <Dialog
                      classes={{ paper: classes.dialogPaper }}
                      fullWidth
                      maxWidth="xs"
                      onClose={onClose}
                      onExited={handleReset}
                      open={open}
                    >
                        <Button className={classes.cancel} color="inherit" onClick={onClose}>
                            <ClearIcon />
                        </Button>
                        <DialogTitle className={classes.dialogTitle} disableTypography>
                            <Avatar className={classes.avatar}>
                                <PersonIcon className={classes.avatarIcon} />
                            </Avatar>
                            <h1>{t('assignApplicantDialog.text')}</h1>
                        </DialogTitle>
                        <AssignApplicantForm {...formikProps} />
                    </Dialog>
                );
            }}
        </Formik>
    );
}

AssignApplicantDialog.NAME = 'assignApplicant';
AssignApplicantDialog.defaultProps = {
    onClose: () => {},
    onSubmitFail: () => {},
    onSubmitSuccess: () => {},
    open: false,
    ticketId: null,
};

const mapStateToProps = (state: RootState): StateProps => ({
    ...AssignApplicantDialog.defaultProps,
    ...state.dialog.registered[AssignApplicantDialog.NAME],
});

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    registerDialog: (name: string) => dispatch(dialog.actions.register(name)),
    submit: (values: Object) => dispatch(actions.submit(values)),
    unregisterDialog: (name: string) => dispatch(dialog.actions.unregister(name)),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
export default hoistStatics(connector)(AssignApplicantDialog);
