// @flow
import React from 'react';
import { Form } from 'formik';
import { makeStyles } from '@material-ui/styles';
import { Check as CheckIcon } from '@material-ui/icons';
import styles from './styles';

type Props = {
    dataItem: ?Object,
    expectedValueExpression: ?Object,
    id: string,
    questionText: string,
};

const useStyles = makeStyles(styles, { name: 'Task' });

export default function Task(props: Props) {
    const { id, questionText } = props;
    const classes = useStyles(props);

    return (
        <Form className={classes.root} name={id}>
            <div className={classes.question}>
                <div dangerouslySetInnerHTML={{ __html: questionText }} />
            </div>
            <div className={classes.metadata}>
                <CheckIcon color="action" fontSize="small" />
            </div>
        </Form>
    );
}
