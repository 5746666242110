// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback, useEffect, useRef } from 'react';
import isMatch from 'lodash/isMatch';
import cx from 'classnames';
import { TableRow } from '@material-ui/core';
import type { Node } from 'react';

type Props = {
    actions: Object[],
    children: Node,
    classes: Object,
    className?: string,
    data: Object[],
    loading: boolean,
    onSelect: (item: Object) => void,
    rowInfo: Object,
    selected: any[],
}

export function RowGroup(props: Props) {
    const {
        actions,
        children,
        classes,
        className,
        data,
        loading,
        onSelect,
        rowInfo,
        selected,
        ...other
    } = props;

    const rowData = rowInfo ? rowInfo.original : null;
    const isSelected = rowData !== null
        ? selected.some((item: any) => (rowData === item || rowData.id === item.id || isMatch(rowData, item)))
        : false;

    const rowGroupRef = useRef(null);
    const actionToolbarRef = useRef(null);

    const positionToolbar = useCallback(() => {
        const actionToolbar = actionToolbarRef.current;
        const rowGroup = rowGroupRef.current;
        const table = rowGroup ? rowGroup.closest('[data-table-root]') : null;

        if (table) {
            const { height, y } = rowGroup.getBoundingClientRect();
            const { y: tableY } = table.getBoundingClientRect();
            actionToolbar.style.height = `${height - 25}px`; // Subtract 25px for margin and bottom border
            actionToolbar.style.top = `${y - tableY}px`;
        }
    }, []);

    const handleSelect = useCallback(() => {
        if (rowData !== null) {
            onSelect(rowData);
        }
    }, [onSelect, rowData]);

    const handleMouseEnter = useCallback(() => {
        positionToolbar();
    }, [positionToolbar]);

    useEffect(() => {
        positionToolbar();
    }, [positionToolbar]);

    if (!rowData) {
        return null;
    }

    return (
        <TableRow
          {...other}
          className={cx(classes.rowGroup, className, { [classes.selected]: isSelected })}
          component="div"
          hover
          onClick={handleSelect}
          onMouseEnter={handleMouseEnter}
          ref={rowGroupRef}
        >
            {children}
            <div className={classes.actionToolbar} ref={actionToolbarRef}>
                <div className={classes.actions}>
                    {actions.map((action) => {
                        const { Button } = action;
                        return <Button {...rowInfo} />;
                    })}
                </div>
            </div>
        </TableRow>
    );
}

export default RowGroup;
