// @flow
// $FlowIssue need to update to a more recent flow version
import React, { Fragment, useCallback, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Popover } from '@material-ui/core';
import type { Node } from 'react';
import styles from './styles';

type Props = {
    anchor: Node,
    children: (props: Object) => Node,
    classes?: Object,
    onClose?: (event: SyntheticEvent<any>, reason: string) => void,
};

const useStyles = makeStyles(styles, { name: 'Dropdown' });

export default function Dropdown(props: Props) {
    const { anchor, children, onClose, ...other } = props;

    const classes = useStyles(props);

    const [open, setOpen] = useState(null);
    const anchorRef = useRef(null);

    const closePopover = useCallback(() => {
        setOpen(false);
    }, []);

    const togglePopover = useCallback(() => {
        setOpen(!open);
    }, [open]);

    const handleClose = (event: SyntheticEvent<any>, reason: string) => {
        setOpen(false);
        if (typeof onClose === 'function') {
            onClose(event, reason);
        }
    };

    return (
        <Fragment>
            <div className={classes.anchor} ref={anchorRef} onClick={togglePopover}>
                {anchor}
            </div>
            <Popover
              {...other}
              anchorEl={anchorRef.current}
              classes={{
                  paper: classes.paper,
                  root: classes.popover,
              }}
              onClose={handleClose}
              open={open}
            >
                {children({ close: closePopover })}
            </Popover>
        </Fragment>
    );
}
