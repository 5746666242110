// @flow
export default (theme: Object) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',

        '& > :not(:last-child)': {
            marginBottom: theme.spacing(2),
        },
    },
});
