// @flow
import React, { Fragment } from 'react';
import { ListItemAvatar, ListItemText } from '@material-ui/core';
import { Check as CheckIcon, Search as SearchIcon } from '@material-ui/icons';
import highlightMatch from '../../../utils/highlightMatch';
import UserAvatar from '../../UserAvatar';
import Select from '../../Select';

type Props = {};

const formatOptionLabel = (option: Object, meta: Object) => {
    const { label, value } = option;
    const primary = highlightMatch(label, meta.inputValue);
    const secondary = highlightMatch(value.email, meta.inputValue);

    return (
        <Fragment>
            <ListItemAvatar>
                <UserAvatar user={value} />
            </ListItemAvatar>
            <ListItemText primary={primary} secondary={secondary} />
            {meta.isSelected ? <CheckIcon /> : null}
        </Fragment>
    );
};

export default function UserSelect(props: Props) {
    return (
        <Select
          {...props}
          backspaceRemovesValue={false}
          ControlProps={{ fullWidth: true }}
          controlShouldRenderValue={false}
          dropdownIcon={<SearchIcon />}
          formatOptionLabel={formatOptionLabel}
          menuIsOpen
          menuType="list"
          minMenuHeight={400}
          maxMenuHeight={400}
        />
    );
}
