// @flow
import { createSelector } from 'reselect';
import type { Location as Gigwalk$Location } from 'gigwalk/lib/api/locationLists/types';
import type { Group } from 'gigwalk/lib/api/groups/types';
import type { State as RootState } from '../../../../../redux/initialState';
import type { Relation, LocationRelation } from '../../../../../redux/entities/locationLists';

export const getMemberCount = createSelector(
    (state: RootState, props: Object) => props.values.groups,
    (groups: Group[]) => (groups || []).reduce((count: number, group: Group) => count + group.member_count, 0)
);

export const getLocations = createSelector(
    (state: RootState) => state.projectBuilder.launch.locationIds,
    (state: RootState) => state.entities.locations,
    (state: RootState) => state.entities.relations,
    // $FlowFixMe need to fix gigwalk-node type definitions
    (ids: number[], locationsMap: { [key: string]: Gigwalk$Location }, relationsMap: { [key: string]: Relation }): LocationRelation[] => (
        ids.reduce((locations: LocationRelation[], id: number): LocationRelation[] => {
            const relation = relationsMap[`${id}`];
            if (relation) {
                const location = locationsMap[relation.id];
                return location ? [...locations, { ...location, relation_id: id }] : locations;
            }
            return locations;
        }, [])
    )
);

export default {
    getLocations,
    getMemberCount,
};
