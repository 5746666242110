// @flow
import React from 'react';
import { TableHead } from '@material-ui/core';
import cx from 'classnames';
import type { Node } from 'react';

type Props = {
    children: Node,
    classes: Object,
    className: ?string,
    data: Object[],
    loading: boolean,
    selected: any[],
}

export function HeaderGroup(props: Props) {
    const { children, classes, className, data, loading, selected, ...other } = props;
    return (
        <TableHead
          {...other}
          component="div"
          className={cx(classes.headerGroup, className)}
        >
            {children}
        </TableHead>
    );
}

export default HeaderGroup;
