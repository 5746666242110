// @flow
import { normalize } from 'normalizr';
import { createAction } from 'redux-actions';
import omitBy from 'lodash/omitBy';
import type { Dispatch } from 'redux';
import type { $AxiosXHR, $AxiosError } from 'axios';
import type { APIResponse } from 'gigwalk/lib/api/resource';
import type { Certification, GetCertificationParams } from 'gigwalk/lib/api/certifications/types';
import logger from '../../util/logger';
import { client as gigwalk } from '../../api/createGigwalkClient';
import { init } from './initialState';
import { certification as certificationSchema } from './schemas';
import type { State } from './initialState';

// Actions
// -------
export const FETCH = 'g/certifications/FETCH';
export const FETCH_SUCCESS = `${FETCH}_SUCCESS`;
export const FETCH_ERROR = `${FETCH}_ERROR`;

// Get/search certifications
export const FETCH_ALL = 'g/certifications/FETCH_ALL';
export const FETCH_ALL_SUCCESS = `${FETCH_ALL}_SUCCESS`;
export const FETCH_ALL_ERROR = `${FETCH_ALL}_ERROR`;

// Assign certifications
export const ASSIGN = 'g/certifications/ASSIGN';
export const ASSIGN_SUCCESS = `${ASSIGN}_SUCCESS`;
export const ASSIGN_ERROR = `${ASSIGN}_ERROR`;

// Action Creators
// ---------------
export const fetchSuccess = createAction(FETCH_SUCCESS);
export const fetchError = createAction(FETCH_ERROR);
export const fetch = createAction(
    FETCH,
    (params: GetCertificationParams): Function => (
        (dispatch: Dispatch<any>): Promise<Object> => (
            gigwalk.certifications.get(params)
                .then((resp: $AxiosXHR<APIResponse<[Certification]>>) => {
                    const normalized = normalize(resp.data.data, [certificationSchema]);
                    dispatch(fetchSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(fetchError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export const fetchAllSuccess = createAction(FETCH_ALL_SUCCESS);
export const fetchAllError = createAction(FETCH_ALL_ERROR);
export const fetchAll = createAction(
    FETCH_ALL,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<Object> => {
            const { organization_id: orgId, ...urlParams } = params;
            const config = {
                params: omitBy(urlParams, (value) => value == null),
            };
            return gigwalk.client.get(`/v1/organizations/${orgId}/certifications`, config)
                .then((resp: $AxiosXHR<APIResponse<Certification[]>>) => {
                    const normalized = normalize(resp.data.data, [certificationSchema]);
                    dispatch(fetchAllSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(fetchAllError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const assignSuccess = createAction(ASSIGN_SUCCESS);
export const assignError = createAction(ASSIGN_ERROR);
export const assign = createAction(
    ASSIGN,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<Object> => {
            const { organization_id: orgId, ...payload } = params;
            return gigwalk.client.post(`v1/organizations/${orgId}/certifications/bulk_assign`, payload)
                .then((resp: $AxiosXHR<APIResponse<Certification[]>>) => {
                    const normalized = normalize(resp.data.data, [certificationSchema]);
                    dispatch(assignSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(assignError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export default function(state: State = init): State {
    return state;
}
