// @flow
import CondExpression from './CondExpression';

type Link = {
    altChildren: SkipLogicTree[], // eslint-disable-line no-use-before-define
    children: SkipLogicTree[], // eslint-disable-line no-use-before-define
    condition: CondExpression,
}

/**
 * @class SkipLogicTree
 * @constructor
 */
export default class SkipLogicTree {
    value: any;

    parent: ?Object;

    links: Link[];

    constructor(json: Object) {
        this.value = json;
        this.parent = null;
        this.links = [];

        this.parse(json);
    }

    parse(json: Object) {
        const children = json.children || [];

        children.forEach(($cond: Object) => {
            const keys = Object.keys($cond);

            if (keys.length !== 1) {
                throw new Error('Exception: There can only be one element in a dictionary expression');
            }

            const statement = $cond[keys[0]];
            if (statement.length !== 3) {
                throw new Error('Exception: A $cond expression must contain 3 elements');
            }

            // if (condition) then (consequence) else (alternative)
            const condition = new CondExpression(statement[0]);
            const consequence = statement[1];
            const alternative = statement[2];

            consequence.forEach((item: Object) => {
                const node = new SkipLogicTree(item);
                this.linkChild(condition, node, null);
            }, this);

            alternative.forEach((item: Object) => {
                const node = new SkipLogicTree(item);
                this.linkChild(condition, null, node);
            });
        });
    }

    /**
     * Loosely links a child node to this node. A direct association is only formed when
     * condition is evaluated. If condition evaluates to true, node becomes a child. Otherwise,
     * altNode becomes a child.
     * @param condition {CondExpression}
     * @param node {SkipLogicTree}
     * @param altNode {SkipLogicTree}
     */
    linkChild(condition: CondExpression, node: ?SkipLogicTree, altNode: ?SkipLogicTree) {
        if (!(condition instanceof CondExpression)) {
            throw new Error('Exception: condition is not a CondExpression');
        }

        const index = this.links.findIndex((link) => link.condition.id === condition.id);
        const link = index >= 0
            ? this.links[index]
            : { condition, children: [], altChildren: [] };

        if (node) {
            if (!(node instanceof SkipLogicTree)) {
                throw new Error('Exception: node is not a SkipLogicTree');
            }

            link.children.push(node);
            node.parent = this; // eslint-disable-line no-param-reassign
        }

        if (altNode) {
            if (!(altNode instanceof SkipLogicTree)) {
                throw new Error('Exception: altNode is not a SkipLogicTree');
            }

            link.altChildren.push(altNode);
            altNode.parent = this; // eslint-disable-line no-param-reassign
        }

        if (index === -1) {
            this.links.push(link);
        }
    }

    unlinkChild(condition: CondExpression, node: ?SkipLogicTree, altNode: ?SkipLogicTree) {
        if (!(condition instanceof CondExpression)) {
            throw new Error('Exception: condition is not a CondExpression');
        }

        const { links } = this;
        const index = this.links.findIndex((link) => link.condition.id === condition.id);
        const link = index >= 0 ? this.links[index] : null;

        if (link) {
            if (node) {
                if (!(node instanceof SkipLogicTree)) {
                    throw new Error('Exception: node is not a SkipLogicTree');
                }

                const childIndex = link.children.indexOf(node);
                if (childIndex >= 0) {
                    link.children.splice(childIndex, 1);
                }

                node.parent = null; // eslint-disable-line no-param-reassign
            }

            if (altNode) {
                if (!(altNode instanceof SkipLogicTree)) {
                    throw new Error('Exception: altNode is not a SkipLogicTree');
                }

                const childIndex = link.altChildren.indexOf(altNode);
                if (childIndex >= 0) {
                    link.children.splice(childIndex, 1);
                }

                altNode.parent = null; // eslint-disable-line no-param-reassign
            }

            if (link.children.length === 0 && link.altChildren.length === 0) {
                links.splice(index, 1);
            }
        }
    }

    /**
     *
     * @param dataItemValue
     * @returns {Array}
     */
    evaluate(dataItemValue: any[]) {
        const nextSteps = new Set();

        for (let i = 0; i < this.links.length; i += 1) {
            const result = this.links[i].condition.evaluate(dataItemValue);
            if (result) {
                if (this.links[i].children.length) {
                    this.links[i].children.forEach((node) => {
                        nextSteps.add(node);
                    });
                }
            } else if (this.links[i].altChildren.length) {
                this.links[i].altChildren.forEach((node) => {
                    nextSteps.add(node);
                });
            }
        }

        return Array.from(nextSteps);
    }

    traverse(visitCallback: Function) {
        if (this.visited) {
            return;
        }

        visitCallback(this);

        // $FlowIssue current version of flow does seem to support private properties
        this.visited = true;

        // Traverse children
        this.links.forEach((link) => {
            link.children.forEach((child) => {
                child.traverse(visitCallback);
            });

            link.altChildren.forEach((child) => {
                child.traverse(visitCallback);
            });
        });

        // $FlowIssue current version of flow does seem to support private properties
        delete this.visited;
    }

    toJSON() {
        const json = {};

        json.children = [];
        this.links.forEach((link) => {
            json.children.push({
                $cond: [
                    link.condition.toJSON(),
                    link.children.map((child) => child.toJSON()),
                    link.altChildren.map((child) => child.toJSON()),
                ],
            });
        });

        Object.keys(json).forEach((key: string) => {
            if (!json[key]) {
                delete json[key];
                return;
            }

            if (Array.isArray(json[key])) {
                if (json[key].length === 0) {
                    delete json[key];
                }
                return;
            }

            // Check if this is a plain object with no keys. If so, remove it.
            if (Object.getPrototypeOf(json[key]) === Object.getPrototypeOf({})) {
                if (Object.keys(json[key]).length === 0) {
                    delete json[key];
                }
            }
        });

        return json;
    }
}
