// @flow
export default (theme: Object) => ({
    root: {
        background: 'transparent',
    },
    stepHorizontal: {
        '&:first-child': {
            paddingLeft: theme.spacing(1),
        },
        '&:last-child': {
            paddingRight: theme.spacing(1),
        },
    },
    stepIcon: {
        display: 'block',

        '@global path': {
            // material-ui uses custom internal icons for the default step icons, which are
            // slightly bigger than ones from @material-ui/icons. This transform should make
            // those from @material-ui/icons looks about the same size
            transform: 'translate(-2px,-2px) scale(1.18)',
        },
    },
    error: {
        color: '#cd1e3d',
    },
});
