// @flow
// $FlowIssue need to update to a more recent flow version
import React, { Fragment, useCallback } from 'react';
import cx from 'classnames';
// $FlowTypedIssue update react-redux libdef
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Link, ListItemText } from '@material-ui/core';
import {
    LocalOffer as LocalOfferIcon,
    Search as SearchIcon,
} from '@material-ui/icons';
import type { ContextRouter } from 'react-router';
import highlightMatch from '../../../utils/highlightMatch';
import colorHash from '../../../utils/colorHash';
import { searchTags } from '../../../ducks/gigwalk';
import { addTag, getTicket, removeTag } from '../../../ducks/ticketDetail';
import Tag from '../../../components/Tag';
import Dropdown from '../../../components/Dropdown';
import Select from '../../../components/Select';
import styles from './styles';
import type { State as RootState } from '../../../store';

type Props = ContextRouter & {};

const formatOptionLabel = (option: Object, meta: Object) => {
    if (typeof option === 'string') {
        return `Create new tag "${option}"`;
    }

    const [id, name] = option;
    const primary = highlightMatch(name, meta.inputValue);
    const color = colorHash([name, id].join(''));

    const tagColorStyle = {
        backgroundColor: color,
        borderRadius: 4,
        height: 16,
        width: 16,
        marginRight: 12,
    };

    return (
        <Fragment>
            <div style={tagColorStyle} />
            <ListItemText primary={primary} />
        </Fragment>
    );
};

const getOptionValue = (option: Object): string => (
    typeof option === 'string' ? option : option[0]
);

const useStyles = makeStyles(styles, { name: 'Tags' });

export default function Tags(props: Props) {
    const { match } = props;
    const orgId = parseInt(match.params.orgId, 10);
    const ticketId = parseInt(match.params.ticketId, 10);

    const dispatch = useDispatch();
    const classes = useStyles(props);
    const { t } = useTranslation();

    const user = useSelector((state: RootState) => state.session.user);
    const ticket = useSelector((state: RootState) => getTicket(state));

    // Limit the user roles that can create tags to help keep the total number low
    const userRole = user ? user.role : '';
    const canCreateTag = userRole === 'PLATFORM_ADMIN' || userRole === 'SELF_SERVICE';

    const loadTagOptions = useCallback((inputValue: string) => {
        const params = {};
        params.organization_id = orgId;

        if (inputValue) {
            params.name = inputValue;
        }

        return dispatch(searchTags(params))
            .then((resp) => {
                const { entities, result } = resp;
                let exactMatch = false;

                const options = result.map((id: number) => {
                    const tagName = entities.tags[id].name;
                    exactMatch = tagName === inputValue;
                    return [id, tagName];
                });

                if (inputValue && !exactMatch && canCreateTag) {
                    options.push(inputValue);
                }

                return options;
            });
    }, [canCreateTag, dispatch, orgId]);

    const tags = ticket ? ticket.tags : [];

    return (
        <Card className={classes.root}>
            <CardContent className={classes.content}>
                <LocalOfferIcon className={classes.tagIcon} />
                <div className={cx(classes.tagList, { [classes.noTags]: tags.length === 0 })}>
                    {tags.length === 0 ? t('ticketDetail.tags.hint') : null}
                    {tags.map(([id, name]) => {
                        const handleTagDelete = () => {
                            dispatch(removeTag(ticketId, id));
                        };
                        return (
                            <Tag
                              id={id}
                              key={id}
                              label={name}
                              onDelete={handleTagDelete}
                            />
                        );
                    })}
                </div>
                <Dropdown
                  anchor={(
                      <Link className={classes.addTagButton} role="button" underline="always">
                          {t('ticketDetail.tags.addTag')}
                      </Link>
                  )}
                  anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                  }}
                  classes={{
                      anchor: classes.dropdownAnchor,
                      paper: classes.dropdownPaper,
                  }}
                  transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                  }}
                >
                    {({ close: closeDropdown }) => {
                        const handleChange = (value: Object[], meta: Object) => {
                            const { action, option } = meta;
                            if (action === 'select-option') {
                                const tag = typeof option === 'string' ? option : option[0];
                                dispatch(addTag(ticketId, tag))
                                    .then(() => {
                                        closeDropdown();
                                    });
                            }
                        };

                        return (
                            <Select
                              ControlProps={{
                                  fullWidth: true,
                                  margin: 'dense',
                                  variant: 'outlined',
                              }}
                              controlShouldRenderValue={false}
                              defaultOptions
                              dropdownIcon={<SearchIcon />}
                              formatOptionLabel={formatOptionLabel}
                              getOptionValue={getOptionValue}
                              isMulti
                              loadOptions={loadTagOptions}
                              menuIsOpen
                              menuType="list"
                              onChange={handleChange}
                              placeholder={t('ticketDetail.tags.searchPlaceholder')}
                              showSelectedOptions={false}
                              value={tags}
                            />
                        );
                    }}
                </Dropdown>
            </CardContent>
        </Card>
    );
}
