// @flow
import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import ReactTable from 'react-table';
import type { ComponentType } from 'react';
import Table from './Table';
import Cell from './Cell';
import Header from './Header';
import HeaderGroup from './HeaderGroup';
import Pagination from './Pagination';
import Body from './Body';
import Row from './Row';
import RowGroup from './RowGroup';
import styles from './styles';

type Column = {
    // Renderers
    Cell?: any,
    Header?: any,
    Footer?: any,
    Aggregated?: any,
    Pivot?: any,
    PivotValue?: any,
    Expander?: any,
    Filter?: any,

    // Standard options
    sortable?: boolean,
    resizable?: boolean,
    filterable?: boolean,
    show?: boolean,
    minWidth?: number,

    // Cells only
    className?: string,
    style?: Object,
    getProps?: Function,

    // Headers only
    headerClassName?: string,
    headerStyle?: Object,
    getHeaderProps?: Function,

    // Footers only
    footerClassName?: string,
    footerStyle?: Object,
    getFooterProps?: Function,
    filterAll?: boolean,
    filterMethod?: Function,
    sortMethod?: Function,
    defaultSortDesc?: boolean,
}

type Props = {
    actions: Object[],
    className?: string,
    columns: Column[],
    count: number,
    data: Object[],
    defaultSortDesc: boolean,
    loading: boolean,
    onSelect: (item: Object) => void,
    page: number,
    pageSize: number,
    selected: any[],
    TableComponent: ComponentType<any>,
    TheadComponent: ComponentType<any>,
    TbodyComponent: ComponentType<any>,
    TrGroupComponent: ComponentType<any>,
    TrComponent: ComponentType<any>,
    ThComponent: ComponentType<any>,
    TdComponent: ComponentType<any>,
    PaginationComponent: ComponentType<any>,
};

const Null = () => null;

const useStyles = makeStyles(styles, { name: 'DataTable' });

export function DataTable(props: Props) {
    const { actions, className, data, defaultSortDesc, loading, onSelect, selected, ...other } = props;

    const classes = useStyles(props);

    const getCommonProps = () => ({
        classes,
        data,
        loading,
        selected,
    });
    const getTdProps = (state, rowInfo, column) => ({
        ...getCommonProps(),
        padding: column.padding || 'default',
    });
    const getTrGroupProps = (state, rowInfo) => ({
        ...getCommonProps(),
        actions,
        rowInfo,
        onSelect,
    });
    const getTheadThProps = (state, rowInfo, column) => ({
        ...getCommonProps(),
        defaultSortDesc: column.defaultSortDesc != null
            ? column.defaultSortDesc
            : defaultSortDesc,
        padding: column.padding || 'default',
    });

    return (
        <ReactTable
          {...other}
          data={data}
          manual
          sortable
          pageSizeOptions={[10, 20, 50]}
          defaultPageSize={10}
          TfootComponent={Null}
          ExpanderComponent={Null}
          AggregatedComponent={Null}
          PivotValueComponent={Null}
          PivotComponent={Null}
          FilterComponent={Null}
          PreviousComponent={Null}
          NextComponent={Null}
          LoadingComponent={Null}
          NoDataComponent={Null}
          ResizerComponent={Null}
          getTableProps={getCommonProps}
          getTheadGroupProps={getCommonProps}
          getTheadGroupTrProps={getCommonProps}
          getTheadGroupThProps={getCommonProps}
          getTheadProps={getCommonProps}
          getTheadTrProps={getCommonProps}
          getTheadThProps={getTheadThProps}
          getTbodyProps={getCommonProps}
          getTrGroupProps={getTrGroupProps}
          getTrProps={getCommonProps}
          getThProps={getCommonProps}
          getTdProps={getTdProps}
          getPaginationProps={getCommonProps}
        >
            {(state, makeTable) => (
                <div data-table-root className={cx(classes.root, className)}>
                    {makeTable()}
                </div>
            )}
        </ReactTable>
    );
}

DataTable.defaultProps = {
    actions: [],
    defaultSortDesc: false,
    loading: false,
    onSelect: () => {},
    selected: [],
    TableComponent: Table,
    TheadComponent: HeaderGroup,
    TbodyComponent: Body,
    TrGroupComponent: RowGroup,
    TrComponent: Row,
    ThComponent: Header,
    TdComponent: Cell,
    PaginationComponent: Pagination,
};

export default DataTable;
