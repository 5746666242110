// @flow
import { normalize } from 'normalizr';
import { createAction, handleActions } from 'redux-actions';
import identity from 'lodash/identity';
import type { Dispatch } from 'redux';
import type { ActionType } from 'redux-actions';
import type { $AxiosXHR, $AxiosError } from 'axios';
import type { APIResponse, APIPromise } from 'gigwalk/lib/api/resource';
import type { DataType } from 'gigwalk/lib/api/dataTypes/types';
import logger from '../../util/logger';
import { client as gigwalk } from '../../api/createGigwalkClient';
import { init } from './initialState';
import { dataType as dataTypeSchema } from './schemas';
import type { State } from './initialState';
// import blacklist from "blacklist";

// Actions
// -------
export const FETCH_ALL = 'g/dataTypes/FETCH_ALL';
export const FETCH_ALL_SUCCESS = `${FETCH_ALL}_SUCCESS`;
export const FETCH_ALL_ERROR = `${FETCH_ALL}_ERROR`;

export const FETCH = 'g/dataTypes/FETCH';
export const FETCH_SUCCESS = `${FETCH}_SUCCESS`;
export const FETCH_ERROR = `${FETCH}_ERROR`;

export const CREATE = 'g/dataTypes/CREATE';
export const CREATE_SUCCESS = `${CREATE}_SUCCESS`;
export const CREATE_ERROR = `${CREATE}_ERROR`;

export const UPDATE = 'g/dataTypes/UPDATE';
export const UPDATE_SUCCESS = `${UPDATE}_SUCCESS`;
export const UPDATE_ERROR = `${UPDATE}_ERROR`;

export const ADD_ATTACHMENT = 'g/dataTypes/ADD_ATTACHMENT';
export const ADD_ATTACHMENT_SUCCESS = `${ADD_ATTACHMENT}_SUCCESS`;
export const ADD_ATTACHMENT_ERROR = `${ADD_ATTACHMENT}_ERROR`;

export const DELETE_ATTACHMENT = 'g/dataTypes/DELETE_ATTACHMENT';
export const DELETE_ATTACHMENT_SUCCESS = `${DELETE_ATTACHMENT}_SUCCESS`;
export const DELETE_ATTACHMENT_ERROR = `${DELETE_ATTACHMENT}_ERROR`;

type NormalizedDataTypes = {
    entities: { [key: string]: DataType },
    result: Array<number>,
};


// Action Creators
// ---------------
export const fetchAllSuccess = createAction(FETCH_ALL_SUCCESS);
export const fetchAllError = createAction(FETCH_ALL_ERROR);
export const fetchAll = createAction(
    FETCH_ALL,
    (params): Function => (
        (dispatch: Dispatch<any>): APIPromise<NormalizedDataTypes> => (
            gigwalk.dataTypes.getAll(params)
                .then((resp: $AxiosXHR<APIResponse<DataType[]>>) => {
                    const normalized = normalize(resp.data.data, [dataTypeSchema]);
                    dispatch(fetchAllSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(fetchAllError(err));
                    return Promise.reject(err);
                })
        )
    )
);

// Get dataType. Returns information about the specified dataType.
export const fetchSuccess = createAction(FETCH_SUCCESS);
export const fetchError = createAction(FETCH_ERROR);
export const fetch = createAction(
    FETCH,
    (params): Function => (
        (dispatch: Dispatch<any>): APIPromise<NormalizedDataTypes> => (
            gigwalk.dataTypes.get(params)
                .then((resp: $AxiosXHR<APIResponse<[DataType]>>) => {
                    const normalized = normalize(resp.data.data, [dataTypeSchema]);
                    dispatch(fetchSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(fetchError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export const createSuccess = createAction(CREATE_SUCCESS);
export const createError = createAction(CREATE_ERROR);
export const create = createAction(
    CREATE,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): APIPromise<NormalizedDataTypes> => {
            const { cid, dataType } = params;
            // @todo Address gigwalk-node queueing issue
            // Use cid to create a unique request. This will prevent the gigwalk client
            // from combining similar requests into one API call
            const config = {
                params: { ...(cid ? { cid } : undefined) },
            };
            return gigwalk.client.post('/v1/data_types', { ...dataType }, config)
                .then((resp: $AxiosXHR<APIResponse<[DataType]>>) => {
                    const normalized = normalize(resp.data.data, [dataTypeSchema]);
                    dispatch(createSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(createError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const updateSuccess = createAction(UPDATE_SUCCESS);
export const updateError = createAction(UPDATE_ERROR);
export const update = createAction(
    UPDATE,
    (params): Function => (
        (dispatch: Dispatch<any>): APIPromise<NormalizedDataTypes> => (
            // $FlowFixMe need to fix/update gigwalk-node type definitions
            gigwalk.dataTypes.update(params)
                .then((resp: $AxiosXHR<APIResponse<[DataType]>>) => {
                    const normalized = normalize(resp.data.data, [dataTypeSchema]);
                    dispatch(updateSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(updateError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export const addAttachmentSuccess = createAction(ADD_ATTACHMENT_SUCCESS, identity, (data: Object, meta: Object): Object => meta);
export const addAttachmentError = createAction(ADD_ATTACHMENT_ERROR);
export const addAttachment = createAction(
    ADD_ATTACHMENT,
    (params): Function => (
        (dispatch: Dispatch<any>): APIPromise<NormalizedDataTypes> => {
            const { data_type_id: dataTypeId, ...payload } = params;
            return gigwalk.client.post(`/v1/data_types/${dataTypeId}/attachments`, payload)
                .then((resp: $AxiosXHR<APIResponse<Object>>) => {
                    const normalized = normalize(resp.data.data, {});
                    dispatch(addAttachmentSuccess(normalized, { data_type_id: dataTypeId, attachment: payload }));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(addAttachmentError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const deleteAttachmentSuccess = createAction(DELETE_ATTACHMENT_SUCCESS, identity, (data: Object, meta: Object): Object => meta);
export const deleteAttachmentError = createAction(DELETE_ATTACHMENT_ERROR);
export const deleteAttachment = createAction(
    DELETE_ATTACHMENT,
    (params): Function => (
        (dispatch: Dispatch<any>): APIPromise<NormalizedDataTypes> => {
            const { data_type_id: dataTypeId, ...payload } = params;
            return gigwalk.client.delete(`/v1/data_types/${dataTypeId}/attachments`, { data: payload })
                .then((resp: $AxiosXHR<APIResponse<Object>>) => {
                    const normalized = normalize(resp.data.data, {});
                    dispatch(deleteAttachmentSuccess(normalized, { data_type_id: dataTypeId, attachment: payload }));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(deleteAttachmentError(err));
                    return Promise.reject(err);
                });
        }
    )
);

// Reducer
// ---------------
export default handleActions({
    [ADD_ATTACHMENT_SUCCESS]: (state: State, action: ActionType<typeof addAttachmentSuccess>): State => {
        const { data_type_id: dataTypeId, attachment } = action.meta;
        const { attachments, ...dataType } = state.dataTypes[dataTypeId];
        return {
            ...state,
            dataTypes: {
                ...state.dataTypes,
                [dataTypeId]: {
                    ...dataType,
                    attachments: Array.isArray(attachments)
                        ? [...attachments, attachment]
                        : [attachment],
                },
            },
        };
    },
    [DELETE_ATTACHMENT_SUCCESS]: (state: State, action: ActionType<typeof deleteAttachmentSuccess>): State => {
        const { data_type_id: dataTypeId, attachment } = action.meta;
        const { attachments, ...dataType } = state.dataTypes[dataTypeId];
        return {
            ...state,
            dataTypes: {
                ...state.dataTypes,
                [dataTypeId]: {
                    ...dataType,
                    attachments: Array.isArray(attachments)
                        ? attachments.filter(({ url }) => url !== attachment.url)
                        : attachments,
                },
            },
        };
    },
}, init);
