// @flow
import stringHash from 'string-hash';
import { interpolateSinebow } from 'd3-scale-chromatic';
import { lighten } from '@material-ui/core';

const MAX_VALUE = 4294967295;

export default function colorHash(input: string): string {
    const value = stringHash(input);
    const color = interpolateSinebow(value / MAX_VALUE);
    return lighten(color, 0.4);
}
