// @flow
import React from 'react';

export default function ValueContainer(props: Object) {
    const { children, selectProps } = props;
    const { classes } = selectProps;

    return (
        <div className={classes.valueContainer}>
            {children}
        </div>
    );
}
