// @flow
// $FlowIssue need to update to a more recent flow version
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import ResizeObserver from 'resize-observer-polyfill';
import SignatureCanvas from 'react-signature-canvas';
import { Form } from 'formik';
// $FlowTypedIssue update react-redux libdef
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import { Close as CloseIcon, Gesture as GestureIcon } from '@material-ui/icons';
import fetchAsDataURL from '../../../../utils/fetchAsDataURL';
import { dataUriToBlob } from '../../../../utils/FileUtil';
import { getTicket, registerForm, unregisterForm } from '../../../../ducks/ticketDetail';
import SubmitButton from '../../../../components/SubmitButton';
import DeviceTooltip from '../DeviceTooltip';
import LocationTooltip from '../LocationTooltip';
import styles from './styles';
import type { State as RootState } from '../../../../store';

type Props = {
    dataItem: ?Object,
    dirty: boolean,
    disabled?: boolean,
    expectedValueExpression: ?Object,
    id: string,
    onChange: (values: Object) => void,
    questionText: string,
    setFieldValue: (field: string, value: any) => void,
    submitForm: () => Promise<any>,
    touched: Object,
    values: Object,
};

const canvasHeight = 150;

const useStyles = makeStyles(styles, { name: 'Signature' });

export default function Signature(props: Props) {
    const { dataItem, dirty, disabled, id, onChange, questionText, setFieldValue, submitForm, touched, values } = props;
    const dataItemValue = dataItem ? dataItem.data_item_value : [];

    const dispatch = useDispatch();
    const classes = useStyles(props);

    const [dataURL, setDataURL] = useState(null);
    const [aspectRatio, setAspectRatio] = useState(null);
    const [canvasWidth, setCanvasWidth] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [uploadDisabled, setUploadDisabled] = useState(true);

    const ticket = useSelector((state: RootState) => getTicket(state));

    const valuesRef = useRef(null);
    const submitFormRef = useRef(null);
    const resizeObserverRef = useRef(null);
    const containerRef = useRef(null);
    const signatureCanvasRef = useRef(null);

    valuesRef.current = values;
    submitFormRef.current = () => {
        if (Object.keys(touched).length > 0 || dirty) {
            return submitForm();
        }
    };

    const handleStrokeBegin = useCallback(() => {
        setUploadDisabled(false);
    }, []);

    const handleUpload = useCallback(() => {
        const signatureCanvas = signatureCanvasRef.current;
        const blob = dataUriToBlob(signatureCanvas.toDataURL('image/jpeg'), 'image/jpeg');
        const formData = new FormData();
        formData.append('file', blob);

        setUploading(true);

        // $FlowIssue flow does not seem to be aware of Promise.prototype.finally()
        axios.post('/upload', formData)
            .then((resp) => {
                const { url } = resp.data;
                setFieldValue('response', { photo_url: url });
                setTimeout(() => {
                    onChange(valuesRef.current);
                }, 0);
            })
            .finally(() => {
                setUploading(false);
                setUploadDisabled(true);
            });
    }, [onChange, setFieldValue]);

    const handleClear = useCallback(() => {
        const signatureCanvas = signatureCanvasRef.current;
        signatureCanvas.clear();
        setUploadDisabled(true);

        setFieldValue('response', null);
        setTimeout(() => {
            onChange(valuesRef.current);
        }, 0);
    }, [onChange, setFieldValue]);

    useEffect(() => {
        const helpers = {
            submitForm() {
                return submitFormRef.current();
            },
        };
        dispatch(registerForm(id, helpers));

        return () => {
            dispatch(unregisterForm(id));
        };
    }, [id, dispatch]);

    useEffect(() => {
        if (dataItemValue[0]) {
            fetchAsDataURL(dataItemValue[0].photo_url)
                .then((value) => {
                    const img = new Image();
                    img.onload = () => {
                        const { width, height } = img;
                        setAspectRatio(width / height);
                        setDataURL(value);
                    };
                    img.src = value;
                });
        }
    }, [dataItemValue]);

    useEffect(() => {
        let resizeObserver = resizeObserverRef.current;

        if (!resizeObserver) {
            resizeObserver = new ResizeObserver((entries) => {
                const { target, contentRect } = entries[0];
                if (target instanceof HTMLElement) {
                    setCanvasWidth(contentRect.width);
                }
            });

            resizeObserverRef.current = resizeObserver;
            resizeObserver.observe(containerRef.current);
        }

        return () => {
            if (resizeObserver) {
                resizeObserver.disconnect();
                resizeObserverRef.current = null;
            }
        };
    }, []);

    useEffect(() => {
        const signatureCanvas = signatureCanvasRef.current;
        const canvas = signatureCanvas.getCanvas();
        const ratio = Math.max(window.devicePixelRatio || 1, 1);

        canvas.width = canvasWidth * ratio;
        canvas.height = canvasHeight * ratio;
        canvas.getContext('2d').scale(ratio, ratio);

        if (dataURL && aspectRatio) {
            let width;
            let height;

            if (aspectRatio > (canvasWidth / canvasHeight)) {
                width = canvasWidth;
                height = width / aspectRatio;
            } else {
                height = canvasHeight;
                width = aspectRatio * height;
            }

            signatureCanvas.fromDataURL(dataURL, { height, width });
        } else {
            signatureCanvas.clear();
        }
    }, [aspectRatio, canvasWidth, dataURL]);

    useEffect(() => {
        const signatureCanvas = signatureCanvasRef.current;
        if (disabled) {
            signatureCanvas.off();
        } else {
            signatureCanvas.on();
        }
    }, [disabled]);

    return (
        <Form className={classes.root} name={id}>
            <div className={classes.question}>
                <div dangerouslySetInnerHTML={{ __html: questionText }} />
                <div className={classes.signaturePad}>
                    <div className={classes.canvasContainer} ref={containerRef}>
                        <SignatureCanvas
                          backgroundColor="rgba(255,255,255)"
                          canvasProps={{ height: canvasHeight, className: classes.canvas }}
                          clearOnResize={false}
                          ref={signatureCanvasRef}
                          onBegin={handleStrokeBegin}
                        />
                        <CloseIcon className={classes.signHereIcon} color="action" />
                    </div>
                    {!disabled
                        ? (
                            <div className={classes.actions}>
                                <Button onClick={handleClear}>Clear</Button>
                                <SubmitButton
                                  color="primary"
                                  disableElevation
                                  disabled={uploading || uploadDisabled}
                                  onClick={handleUpload}
                                  submitting={uploading}
                                  variant="contained"
                                >
                                    Apply
                                </SubmitButton>
                            </div>
                        )
                        : null}
                </div>
            </div>
            <div className={classes.metadata}>
                <GestureIcon color="action" fontSize="small" />
                {ticket && dataItem
                    ? (
                        <Fragment>
                            <DeviceTooltip dataItem={dataItem} />
                            <LocationTooltip dataItem={dataItem} gigLocation={ticket.location} />
                        </Fragment>
                    )
                    : null}
            </div>
        </Form>
    );
}
