// @flow
import { createAction } from 'redux-actions';
import type { Dispatch } from 'redux';
import type { $AxiosError } from 'axios';
import { geocode } from '../googleMaps';
import { updateUser } from '../api';
import {
    fetchCertification,
    fetchCustomer,
    searchSubscriptions,
    searchTickets,
    updateCustomer,
    updateSubscription,
} from '../gigwalk';
import types from './types';
import type { State as RootState } from '../../store';

export const loadCustomerSuccess = createAction(types.LOAD_CUSTOMER_SUCCESS);
export const loadCustomerError = createAction(types.LOAD_CUSTOMER_ERROR);
export const loadCustomer = createAction(
    types.LOAD_CUSTOMER,
    (customerId: number, orgId: number): Function => (
        (dispatch: Dispatch<any>): Promise<void> => (
            dispatch(fetchCustomer({ customer_id: customerId, organization_id: orgId }))
                .then((resp: Object) => {
                    const customer = resp.entities.customers[customerId];
                    const { certifications } = customer;
                    const promises = [];

                    if (certifications) {
                        certifications.forEach((certification: Object) => {
                            promises.push(dispatch(fetchCertification({ certification_id: certification.id })));
                        });
                    }

                    return Promise.all(promises);
                })
                .then(() => {
                    dispatch(loadCustomerSuccess());
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(loadCustomerError());
                    return Promise.reject(err);
                })
        )
    )
);

function getAddressComponents(place: Object) {
    const { address_components: addressComponents } = place;

    let streetName = '';
    let streetNumber = '';
    let unit = '';
    let city = '';
    let state = '';
    let zipCode = '';

    addressComponents.forEach((component: Object) => {
        switch (component.types[0]) {
            case 'route':
                streetName = component.short_name;
                break;
            case 'street_number':
                streetNumber = component.long_name;
                break;
            case 'subpremise':
                unit = component.long_name;
                break;
            case 'locality':
                city = component.long_name;
                break;
            case 'administrative_area_level_1':
                state = component.short_name;
                break;
            case 'postal_code':
                zipCode = component.long_name;
                break;
            default:
                break;
        }
    });

    return {
        addressLine1: `${streetNumber} ${streetName}`.trim(),
        addressLine2: unit,
        city,
        state,
        zipCode,
    };
}

export const resolveAddressError = createAction(types.RESOLVE_ADDRESS_ERROR);
export const resolveAddressSuccess = createAction(types.RESOLVE_ADDRESS_SUCCESS);
export const resolveAddress = createAction(
    types.RESOLVE_ADDRESS,
    (address: string): Function => (
        (dispatch: Dispatch<any>): Promise<any> => (
            dispatch(geocode({ address }))
                .then((results: Object[]) => {
                    const place = results[0];
                    const values = getAddressComponents(place);
                    dispatch(resolveAddressSuccess(values));
                })
                .catch((err) => {
                    dispatch(resolveAddressError());
                    return Promise.reject(err);
                })
        )
    )
);

export const updateAccountError = createAction(types.UPDATE_ACCOUNT_ERROR);
export const updateAccountSuccess = createAction(types.UPDATE_ACCOUNT_SUCCESS);
export const updateAccount = createAction(
    types.UPDATE_ACCOUNT,
    (values: Object): Function => (
        (dispatch: Dispatch<any>, getState: () => RootState): Promise<any> => (
            Promise.resolve()
                .then(() => {
                    const { addressLine1, addressLine2, city, state, zipCode } = values;
                    const parts = [
                        `${addressLine1} ${addressLine2 ? `#${addressLine2}` : ''}`.trim(),
                        `${city}, ${state} ${zipCode}`.replace(/^,|,$/, '').trim(),
                    ];
                    const address = parts.join(', ').trim();
                    return dispatch(geocode({ address }));
                })
                .then((results: Object[]) => {
                    const { currentPassword, firstName, id, lastName, newPassword, phoneNumber } = values;

                    let addressLine1 = '';
                    let addressLine2 = '';
                    let city = '';
                    let state = '';
                    let zipCode = '';

                    if (results.length > 0) {
                        const place = results[0];
                        ({ addressLine1, addressLine2, city, state, zipCode } = getAddressComponents(place));
                    }

                    // We store city/state/zip in address_line_2, though that it not standard.
                    // Typically, address_line_2 is an optional field used for unit/apt/suite number
                    // and you'd have separate fields for city/state/zip
                    const params = {
                        first_name: firstName,
                        last_name: lastName,
                        phonenumber: phoneNumber,
                        address_line_1: `${addressLine1} ${addressLine2 ? `#${addressLine2}` : ''}`.trim(),
                        address_line_2: `${city}, ${state} ${zipCode}`.replace(/^,|,$/, '').trim(),
                        ...(
                            newPassword
                                ? { password: newPassword, current_password: currentPassword }
                                : undefined
                        ),
                    };

                    const { user } = getState().session;
                    const action = user && user.id === id
                        ? updateUser(params)
                        : updateCustomer({ customer_id: id, ...params });

                    return dispatch(action)
                        .then(() => {
                            dispatch(updateAccountSuccess());
                        })
                        .catch((err: $AxiosError<Object>) => {
                            dispatch(updateAccountError(err));
                            return Promise.reject(err);
                        });
                })
        )
    )
);

export const fetchTicketStatsSuccess = createAction(types.FETCH_TICKET_STATS_SUCCESS);
export const fetchTicketStatsError = createAction(types.FETCH_TICKET_STATS_ERROR);
export const fetchTicketStats = createAction(
    types.FETCH_TICKET_STATS,
    (customerId: number): Function => (
        (dispatch: Dispatch<any>): Promise<void> => {
            const getActiveTickets = Promise.resolve()
                .then(() => {
                    const apiFilters = [
                        { key: 'ticket_status', value: ['ASSIGNED', 'SCHEDULED', 'STARTED'] },
                        { key: 'assigned_customer_id', value: customerId },
                    ];
                    return dispatch(searchTickets({ filters: apiFilters }));
                });

            const getSubmittedTickets = Promise.resolve()
                .then(() => {
                    const apiFilters = [
                        { key: 'ticket_status', value: ['SUBMITTED'] },
                        { key: 'assigned_customer_id', value: customerId },
                    ];
                    return dispatch(searchTickets({ filters: apiFilters }));
                });

            return Promise.all([getActiveTickets, getSubmittedTickets])
                .then((results: [Object, Object]) => {
                    const [activeTickets, submittedTickets] = results;
                    const activeTicketCount = activeTickets.metadata.record_count;
                    const submittedTicketCount = submittedTickets.metadata.record_count;
                    dispatch(fetchTicketStatsSuccess({ activeTicketCount, submittedTicketCount }));
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(fetchTicketStatsError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const fetchAllSubscriptionsSuccess = createAction(types.FETCH_ALL_SUBSCRIPTIONS_SUCCESS);
export const fetchAllSubscriptionsError = createAction(types.FETCH_ALL_SUBSCRIPTIONS_ERROR);
export const fetchAllSubscriptions = createAction(
    types.FETCH_ALL_SUBSCRIPTIONS,
    (customerId: number) => (
        (dispatch: Dispatch<any>) => {
            const apiFilters = [{ key: 'subscriber_ids', value: customerId }];
            const ids = [];

            return dispatch(searchSubscriptions({ filters: apiFilters, limit: 200, offset: 0 }))
                .then((resp: Object) => {
                    const promises = [];
                    if (resp.original && resp.original._metadata) {
                        const { page_count: pageCount } = resp.original._metadata;
                        for (let i = 1; i < pageCount; i += 1) {
                            promises.push(dispatch(searchSubscriptions({ filters: apiFilters, limit: 200, offset: i })));
                        }
                    }
                    ids.push(...resp.result);
                    return Promise.all(promises);
                })
                .then((results: Object[]) => {
                    results.forEach((resp: Object) => {
                        ids.push(...resp.result);
                    });
                    dispatch(fetchAllSubscriptionsSuccess(ids));
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(fetchAllSubscriptionsError());
                    return Promise.reject(err);
                });
        }
    )
);

export const unsubscribeError = createAction(types.UNSUBSCRIBE_ERROR);
export const unsubscribeSuccess = createAction(types.UNSUBSCRIBE_SUCCESS);
export const unsubscribe = createAction(
    types.UNSUBSCRIBE,
    (subscriptionId: number, customerId: number) => (
        (dispatch: Dispatch<any>, getState: () => RootState) => {
            const state = getState();
            const subscription = state.entities.subscriptions[`${subscriptionId}`];
            const { subscriber_ids: subscriberIds } = subscription;

            const params = {
                organization_subscription_id: subscriptionId,
                subscription: {
                    subscriber_ids: subscriberIds.filter((id: number) => id !== customerId),
                },
            };

            return dispatch(updateSubscription(params))
                .then(() => {
                    dispatch(unsubscribeSuccess(subscriptionId));
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(unsubscribeError());
                    return Promise.reject(err);
                });
        }
    )
);

export const savePreferencesError = createAction(types.SAVE_PREFERENCES_ERROR);
export const savePreferencesSuccess = createAction(types.SAVE_PREFERENCES_SUCCESS);
export const savePreferences = createAction(
    types.SAVE_PREFERENCES,
    (values: Object): Function => (
        (dispatch: Dispatch<any>, getState: () => RootState): Promise<any> => (
            Promise.resolve()
                .then(() => {
                    const { disabledNotifications, enabledNotifications, id, idealWeeklyHours, maxWeeklyHours } = values;

                    const params = {
                        ideal_hours_week: parseInt(idealWeeklyHours, 10),
                        max_hours_week: parseInt(maxWeeklyHours, 10),
                        notification_preferences: {
                            disabled: disabledNotifications,
                            enabled: enabledNotifications,
                        },
                    };

                    const { user } = getState().session;
                    const action = user && user.id === id
                        ? updateUser(params)
                        : updateCustomer({ customer_id: id, ...params });

                    return dispatch(action)
                        .then(() => {
                            dispatch(savePreferencesSuccess());
                        })
                        .catch((err: $AxiosError<Object>) => {
                            dispatch(savePreferencesError(err));
                            return Promise.reject(err);
                        });
                })
        )
    )
);

export default {
    fetchAllSubscriptions,
    fetchAllSubscriptionsError,
    fetchAllSubscriptionsSuccess,
    fetchTicketStats,
    fetchTicketStatsError,
    fetchTicketStatsSuccess,
    loadCustomer,
    loadCustomerError,
    loadCustomerSuccess,
    savePreferences,
    savePreferencesError,
    savePreferencesSuccess,
    resolveAddress,
    resolveAddressError,
    resolveAddressSuccess,
    updateAccount,
    updateAccountError,
    updateAccountSuccess,
};
