// @flow
import React from 'react';
import cx from 'classnames';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { MenuItem, TextField } from '@material-ui/core';
import { VALUE_TYPE } from '../../../../../../../browser/shared/constant/ProjectConstant';

type Props = {
    classes: Object,
    className?: string,
    id: number,
    index: number,
    questionText: string,
    readOnly?: boolean,
    valueType: string,
};

const styles = () => ({
    root: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    input: {
        flexBasis: '70%',
    },
    numberFormat: {
        flexBasis: '30%',
    },
    menuItem: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',

        label: {
            flexGrow: 2,
            display: 'flex',
            fontSize: 14, // $font-size
        },
    },
});

const valueTypes = [
    { label: 'Number', value: VALUE_TYPE.NUMBER },
    { label: 'Currency', value: VALUE_TYPE.CURRENCY },
    { label: 'Phone Number', value: VALUE_TYPE.PHONE_NUMBER },
    { label: 'Duration', value: VALUE_TYPE.TIME },
];

const preventDefault = (event: SyntheticEvent<any>) => {
    event.preventDefault();
};

const useStyles = makeStyles(styles, { name: 'Number' });

export default function Number(props: Props) {
    const { className, id, index, readOnly } = props;

    const classes = useStyles(props);
    const { t } = useTranslation();

    const renderValue = (value: string) => {
        const valueType = valueTypes.find((item: Object) => item.value === value);
        if (!valueType) {
            return null;
        }
        return (
            <div className={classes.menuItem}>
                <span className={classes.label}>{valueType.label}</span>
            </div>
        );
    };

    // Call event.preventDefault() to keep the ClickAwayListener from triggering
    const MenuProps = { onClick: preventDefault };

    return (
        <div className={cx(className, classes.root)}>
            <Field name={`questionList.${index}.valueType`}>
                {({ field }) => (
                    <TextField
                      {...field}
                      className={classes.numberFormat}
                      disabled={readOnly || id > 0}
                      label={t('shared.question_form.number_format')}
                      renderValue={renderValue}
                      select
                      SelectProps={{ MenuProps }}
                    >
                        {valueTypes.map((item: Object) => (
                            <MenuItem key={item.value} className={classes.menuItem} value={item.value}>
                                <span className={classes.label}>{item.label}</span>
                            </MenuItem>
                        ))}
                    </TextField>
                )}
            </Field>
        </div>
    );
}
