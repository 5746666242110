// @flow
import { createAction } from 'redux-actions';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import type { Dispatch } from 'redux';
import type { $AxiosError } from 'axios';
import logger from '../../utils/logger';
import mapValuesToTicket from './utils/mapValuesToTicket';
import { enqueue as enqueueSnackbar } from '../snackbar';
import {
    addTicketTags,
    createDataItem,
    createTag,
    createTicketEvent,
    deleteTicketEvent,
    fetchTicket,
    fetchTemplate,
    getApplicants,
    getTicketEvents,
    removeTicketTag,
    updateTickets,
} from '../gigwalk';
import types from './types';
import { getDataItemMap } from './selectors';
import type { State as RootState } from '../../store';

export const toggleLocationEnabled = createAction(types.TOGGLE_LOCATION_ENABLED);

export const registerForm = createAction(types.REGISTER_FORM, (name, helpers) => ({ name, helpers }));
export const unregisterForm = createAction(types.UNREGISTER_FORM);

export const fetchAllApplicantsSuccess = createAction(types.FETCH_ALL_APPLICANTS_SUCCESS);
export const fetchAllApplicantsError = createAction(types.FETCH_ALL_APPLICANTS_ERROR);
export const fetchAllApplicants = createAction(
    types.FETCH_ALL_APPLICANTS,
    (ticketId: number) => (
        (dispatch: Dispatch<any>) => (
            dispatch(getApplicants({ ticket_id: ticketId, limit: 200, offset: 0 }))
                .then((resp) => {
                    const promises = [];
                    if (resp.original && resp.original._metadata) {
                        const { page_count: pageCount } = resp.original._metadata;
                        for (let i = 1; i < pageCount; i += 1) {
                            promises.push(dispatch(getApplicants({ ticket_id: ticketId, limit: 200, offset: i })));
                        }
                    }
                    return Promise.all(promises);
                })
                .then(() => {
                    dispatch(fetchAllApplicantsSuccess());
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(fetchAllApplicantsError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export const fetchAllEventsSuccess = createAction(types.FETCH_ALL_EVENTS_SUCCESS);
export const fetchAllEventsError = createAction(types.FETCH_ALL_EVENTS_ERROR);
export const fetchAllEvents = createAction(
    types.FETCH_ALL_EVENTS,
    (ticketId: number) => (
        (dispatch: Dispatch<any>) => (
            dispatch(getTicketEvents({ ticket_id: ticketId, limit: 200, offset: 0 }))
                .then((resp) => {
                    const promises = [];
                    if (resp.original && resp.original._metadata) {
                        const { limit, record_count: recordCount } = resp.original._metadata;
                        for (let i = limit; i < recordCount; i += limit) {
                            promises.push(dispatch(getTicketEvents({ ticket_id: ticketId, limit, offset: i })));
                        }
                    }
                    return Promise.all(promises);
                })
                .then(() => {
                    dispatch(fetchAllEventsSuccess());
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(fetchAllEventsError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export const loadTicketSuccess = createAction(types.LOAD_TICKET_SUCCESS);
export const loadTicketError = createAction(types.LOAD_TICKET_ERROR);
export const loadTicket = createAction(
    types.LOAD_TICKET,
    (ticketId: number): Function => (
        (dispatch: Dispatch<any>, getState: () => RootState): Promise<void> => (
            dispatch(fetchTicket({ ticket_id: ticketId }))
                .then((result: Object) => {
                    const promises = [];
                    const ticket = result.entities.tickets[ticketId];
                    const {
                        status,
                        subscription,
                        template_map: templateMap,
                    } = ticket;

                    promises.push(dispatch(fetchAllEvents(ticketId)));

                    const state = getState();
                    const userRole = state.session.user ? state.session.user.role : '';
                    const canFetchApplicants = subscription.needs_public_workforce
                        ? ['PLATFORM_ADMIN', 'SELF_SERVICE'].includes(userRole)
                        : userRole !== 'WORKER';

                    if (canFetchApplicants && subscription.is_double_optin && status === 'UNASSIGNED') {
                        promises.push(dispatch(fetchAllApplicants(ticketId)));
                    }

                    const templateIds = Object.keys(templateMap) || [];
                    templateIds.forEach((id: number) => {
                        promises.push(dispatch(fetchTemplate({ template_id: id })));
                    });

                    return Promise.all(promises);
                })
                .then(() => {
                    dispatch(loadTicketSuccess());
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(loadTicketError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export const addCommentSuccess = createAction(types.ADD_COMMENT_SUCCESS);
export const addCommentError = createAction(types.ADD_COMMENT_ERROR);
export const addComment = createAction(
    types.ADD_COMMENT,
    (values: Object): Function => (
        (dispatch: Dispatch<any>): Promise<void> => {
            const { comment, ticketId } = values;
            const params = {
                ticket_id: ticketId,
                ticket_event_type: 'COMMENT',
                ticket_event_data: { comment },
            };

            return dispatch(createTicketEvent(params))
                .then(() => {
                    dispatch(addCommentSuccess());
                })
                .catch((err: $AxiosError<Object>) => {
                    dispatch(addCommentError(err));
                    const resp = err ? err.response : null;
                    if (resp && resp.data && resp.data.gw_api_response) {
                        dispatch(enqueueSnackbar(resp.data.gw_api_response, { variant: 'error' }));
                    }
                    return Promise.reject(err);
                });
        }
    )
);

export const deleteCommentSuccess = createAction(types.DELETE_COMMENT_SUCCESS);
export const deleteCommentError = createAction(types.DELETE_COMMENT_ERROR);
export const deleteComment = createAction(
    types.DELETE_COMMENT,
    (id: string): Function => (
        (dispatch: Dispatch<any>): Promise<void> => {
            const params = { ticket_event_id: id };
            return dispatch(deleteTicketEvent(params))
                .then(() => {
                    dispatch(deleteCommentSuccess());
                })
                .catch((err: $AxiosError<Object>) => {
                    dispatch(deleteCommentError(err));
                    const resp = err ? err.response : null;
                    if (resp && resp.data && resp.data.gw_api_response) {
                        dispatch(enqueueSnackbar(resp.data.gw_api_response, { variant: 'error' }));
                    }
                    return Promise.reject();
                });
        }
    )
);

export const updateTicketError = createAction(types.UPDATE_TICKET_ERROR);
export const updateTicketSuccess = createAction(types.UPDATE_TICKET_SUCCESS);
export const updateTicket = createAction(
    types.UPDATE_TICKET,
    (ticketId: number, values: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            const data = mapValuesToTicket(values);
            const scheduleData = pick(data, ['date_scheduled', 'time_zone']);
            const editData = omit(data, ['date_scheduled', 'time_zone']);

            const promises = [];

            if (Object.keys(scheduleData).length > 0) {
                const scheduleParams = {
                    action: 'schedule',
                    ticket_ids: [ticketId],
                    ...scheduleData,
                };
                promises.push(dispatch(updateTickets(scheduleParams)));
            }

            if (Object.keys(editData).length > 0) {
                const editParams = {
                    action: 'edit',
                    ticket_ids: [ticketId],
                    ...editData,
                };
                promises.push(dispatch(updateTickets(editParams)));
            }

            return Promise.all(promises)
                .then(() => dispatch(fetchTicket({ ticket_id: ticketId })))
                .then(() => {
                    dispatch(updateTicketSuccess());
                })
                .catch((err: $AxiosError<Object>) => {
                    dispatch(updateTicketError(err));
                    const resp = err ? err.response : null;
                    if (resp && resp.data && resp.data.gw_api_response) {
                        dispatch(enqueueSnackbar(resp.data.gw_api_response, { variant: 'error' }));
                    }
                    return Promise.reject(err);
                });
        }
    )
);

export const addTagSuccess = createAction(types.ADD_TAG_SUCCESS);
export const addTagError = createAction(types.ADD_TAG_ERROR);
export const addTag = createAction(
    types.ADD_TAG_SUCCESS,
    (ticketId: number, tag: number | string): Function => (
        (dispatch: Dispatch<any>, getState: () => RootState): Promise<void> => {
            const state = getState();
            const ticket = state.entities.tickets[ticketId];
            let promise;

            if (typeof tag === 'string') {
                const params = {
                    name: tag,
                    organization_id: ticket.organization_id,
                };
                promise = dispatch(createTag(params))
                    .then((resp: Object): number => resp.result[0]);
            } else {
                promise = Promise.resolve(tag);
            }

            return promise
                .then((tagId: number) => {
                    const params = {
                        tag_ids: [tagId],
                        ticket_id: ticket.id,
                    };
                    return dispatch(addTicketTags(params));
                })
                .then(() => {
                    dispatch(addTagSuccess());
                })
                .catch((err: $AxiosError<Object>) => {
                    dispatch(addTagError(err));
                    const resp = err ? err.response : null;
                    if (resp && resp.data && resp.data.gw_api_response) {
                        dispatch(enqueueSnackbar(resp.data.gw_api_response, { variant: 'error' }));
                    }
                    return Promise.reject();
                });
        }
    )
);

export const removeTagSuccess = createAction(types.REMOVE_TAG_SUCCESS);
export const removeTagError = createAction(types.REMOVE_TAG_ERROR);
export const removeTag = createAction(
    types.REMOVE_TAG,
    (ticketId: number, tagId: number): Function => (
        (dispatch: Dispatch<any>): Promise<void> => {
            const params = {
                tag_id: tagId,
                ticket_id: ticketId,
            };
            return dispatch(removeTicketTag(params))
                .then(() => {
                    dispatch(removeTagSuccess());
                })
                .catch((err: $AxiosError<Object>) => {
                    dispatch(removeTagError(err));
                    const resp = err ? err.response : null;
                    if (resp && resp.data && resp.data.gw_api_response) {
                        dispatch(enqueueSnackbar(resp.data.gw_api_response, { variant: 'error' }));
                    }
                    return Promise.reject(err);
                });
        }
    )
);

export const saveResponseSuccess = createAction(types.SAVE_RESPONSE_SUCCESS);
export const saveResponseError = createAction(types.SAVE_RESPONSE_ERROR);
export const saveResponse = createAction(
    types.SAVE_RESPONSE,
    (ticketId: number, questionId: string, value: any[]): Function => (
        (dispatch: Dispatch<any>): Promise<void> => {
            const getUserAgentAndLocation = new Promise((resolve) => {
                if (global.hasOwnProperty('navigator')) {
                    const success = (position: Position) => {
                        resolve({
                            location: position.coords,
                            userAgent: navigator.userAgent,
                        });
                    };
                    const error = (err: PositionError) => {
                        logger.warn(err);
                        resolve({
                            location: null,
                            userAgent: navigator.userAgent,
                        });
                    };
                    navigator.geolocation.getCurrentPosition(success, error);
                } else {
                    resolve({ location: null, userAgent: null });
                }
            });

            return getUserAgentAndLocation
                .then((result: Object) => {
                    const { location, userAgent } = result;
                    const [dataTypeId, observationTargetId, templateId] = questionId.split('_');
                    let latitude = null;
                    let longitude = null;

                    if (location) {
                        ({ latitude, longitude } = location);
                    }

                    const params = {
                        data_item_value: value,
                        data_type_id: parseInt(dataTypeId, 10),
                        latitude,
                        longitude,
                        observation_target_id: parseInt(observationTargetId, 10),
                        template_id: templateId ? parseInt(templateId, 10) : null,
                        ticket_id: ticketId,
                        // timestamp: Date.now(),
                        user_agent: userAgent,
                    };

                    return dispatch(createDataItem(params));
                })
                .then(() => {
                    dispatch(saveResponseSuccess());
                })
                .catch((err: $AxiosError<Object>) => {
                    dispatch(saveResponseError(err));
                    const resp = err ? err.response : null;
                    if (resp && resp.data && resp.data.gw_api_response) {
                        dispatch(enqueueSnackbar(resp.data.gw_api_response, { variant: 'error' }));
                    }
                    return Promise.reject(err);
                });
        }
    )
);

export const editResponseSuccess = createAction(types.EDIT_RESPONSE_SUCCESS);
export const editResponseError = createAction(types.EDIT_RESPONSE_ERROR);
export const editResponse = createAction(
    types.EDIT_RESPONSE,
    (ticketId: number, questionId: string, value: any[]): Function => (
        (dispatch: Dispatch<any>, getState: () => RootState): Promise<void> => {
            const state = getState();
            const dataItemMap = getDataItemMap(state);
            const {
                app_version: appVersion,
                data_item_latitude: latitude,
                data_item_longitude: longitude,
                // data_item_timestamp: timestamp,
                data_type_id: dataTypeId,
                device_id: deviceId,
                observation_target_id: observationTargetId,
                template_id: templateId,
                user_agent: userAgent,
            } = dataItemMap[questionId];

            const params = {
                app_version: appVersion,
                data_item_value: value,
                data_type_id: dataTypeId,
                device_id: deviceId,
                latitude,
                longitude,
                observation_target_id: observationTargetId,
                template_id: templateId,
                ticket_id: ticketId,
                // timestamp: moment.utc(timestamp).valueOf(),
                user_agent: userAgent,
            };

            return dispatch(createDataItem(params))
                .then(() => {
                    dispatch(editResponseSuccess());
                })
                .catch((err: $AxiosError<Object>) => {
                    dispatch(editResponseError(err));
                    const resp = err ? err.response : null;
                    if (resp && resp.data && resp.data.gw_api_response) {
                        dispatch(enqueueSnackbar(resp.data.gw_api_response, { variant: 'error' }));
                    }
                    return Promise.reject(err);
                });
        }
    )
);

export const saveChangesSuccess = createAction(types.SAVE_CHANGES_SUCCESS);
export const saveChangesError = createAction(types.SAVE_CHANGES_ERROR);
export const saveChanges = createAction(
    types.SAVE_CHANGES,
    (): Function => (
        (dispatch: Dispatch<any>, getState: () => RootState): Promise<void> => {
            const state = getState();
            const promises = Object.values(state.ticketDetail.forms)
                .map((helpers) => (
                    helpers && typeof helpers === 'object' && typeof helpers.submitForm === 'function'
                        ? helpers.submitForm()
                        : undefined
                ));

            return Promise.all(promises)
                .then(() => {
                    dispatch(saveChangesSuccess());
                })
                .catch((err: $AxiosError<Object>) => {
                    dispatch(saveChangesError(err));
                    const resp = err ? err.response : null;
                    if (resp && resp.data && resp.data.gw_api_response) {
                        dispatch(enqueueSnackbar(resp.data.gw_api_response, { variant: 'error' }));
                    }
                    return Promise.reject(err);
                });
        }
    )
);

export default {
    addComment,
    addCommentError,
    addCommentSuccess,
    addTag,
    addTagError,
    addTagSuccess,
    deleteComment,
    deleteCommentError,
    deleteCommentSuccess,
    editResponse,
    editResponseError,
    editResponseSuccess,
    loadTicket,
    loadTicketError,
    loadTicketSuccess,
    registerForm,
    removeTag,
    removeTagError,
    removeTagSuccess,
    saveChanges,
    saveChangesError,
    saveChangesSuccess,
    saveResponse,
    saveResponseError,
    saveResponseSuccess,
    toggleLocationEnabled,
    unregisterForm,
    updateTicket,
    updateTicketError,
    updateTicketSuccess,
};
