// @flow
import { handleActions } from 'redux-actions';
import type { ActionType } from 'redux-actions';
import types from './types';
import typeof { fetchDataSuccess, toggleFilterDrawer } from './actions';

export type State = {
    data: Array<number>,
    drawerOpen: boolean,
    fetching: boolean,
    metadata: Object,
};

export const init: State = {
    data: [],
    drawerOpen: false,
    fetching: true,
    metadata: {},
};

export default handleActions({
    [types.FETCH_DATA]: (state: State) => ({
        ...state,
        fetching: true,
    }),
    [types.FETCH_DATA_ERROR]: (state: State) => ({
        ...state,
        fetching: false,
    }),
    [types.FETCH_DATA_SUCCESS]: (state: State, action: ActionType<fetchDataSuccess>) => ({
        ...state,
        data: action.payload,
        fetching: false,
        metadata: action.meta,
    }),
    [types.TOGGLE_FILTER_DRAWER]: (state: State, action: ActionType<toggleFilterDrawer>) => ({
        ...state,
        drawerOpen: action.payload,
    }),
}, init);
