// @flow
export default (theme: Object) => ({
    root: {
        fontFamily: ['ProximaNova', '!important'],
        fontSize: 16,
        justifyContent: 'flex-start',

        '& > *:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },
    input: {
        backgroundColor: theme.palette.common.white,
    },
    badge: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.contrastText,
    },
    addButton: {
        fontFamily: 'ProximaNova',
        fontSize: 14,
        flexShrink: 0,

        '&:hover, &:active, &:focus': {
            color: '#fff',
        },
    },
    addIcon: {
        marginRight: 5,
    },
    selected: {},
});
