// @flow
import React, { Component } from 'react';
import moment from 'moment-timezone';
import cx from 'classnames';
import { withStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import {
    KeyboardArrowUp as KeyboardArrowUpIcon,
    KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@material-ui/icons';
import convertTo24Hour from '../../utils/convertTo24Hour';

type Props = {
    className?: string,
    classes: Object,
    disabled?: boolean,
    error?: string,
    format?: '12hr' | '24hr',
    onChange?: (value: string) => void,
    required?: boolean,
    value?: string,
};

type State = {
    value: ?moment$Moment,
};

const styles = (theme: Object) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1),
        background: theme.palette.common.white,
        boxShadow: [
            '0 2px 6px rgba(0, 0, 0, 0.05)',
            '0 0 0 1px rgba(0, 0, 0, 0.07)',
        ],
        borderRadius: 3,
    },

    control: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(1),
    },

    hour: { fontSize: 20 },
    minute: { fontSize: 20 },

    arrowUp: { fontSize: 40 },
    arrowDown: { fontSize: 40 },
});

export class TimePicker extends Component<Props, State> {
    timer: ?number;

    constructor(props: Props) {
        super(props);

        const { value } = this.props;
        const initValue = value
            ? moment(`1970-01-01T${convertTo24Hour(value) || '00:00'}Z`).tz('GMT')
            : null;

        this.state = { value: initValue };
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        const { value } = this.props;
        const { value: nextValue } = nextProps;

        if (!nextValue) {
            this.setState({ value: null });
        } else if (value !== nextValue) {
            const time = convertTo24Hour(nextValue) || '00:00';
            this.setState({ value: moment(`1970-01-01T${time}Z`).tz('GMT') });
        }
    }

    handleChangeMeridiem = () => {
        const { value } = this.state;
        const { onChange } = this.props;
        const newValue = moment(value);

        newValue.add(12, 'hours');

        if (typeof onChange === 'function') {
            onChange(newValue.format('h:mm a'));
        }
    };

    handleMouseDown = (op: string, unit: string) => {
        clearTimeout(this.timer);

        if (op === 'decrement') this.decrement(1, unit);
        else if (op === 'increment') this.increment(1, unit);
    };

    handleMouseUp = () => {
        clearTimeout(this.timer);
        this.timer = null;
    };

    handleMouseLeave = () => {
        clearTimeout(this.timer);
        this.timer = null;
    };

    increment(step: number, unit: string) {
        const { value } = this.state;
        const { onChange } = this.props;
        const newValue = moment(value);

        newValue.add(step, unit);

        if (typeof onChange === 'function') {
            onChange(newValue.format('h:mm a'));
        }

        clearTimeout(this.timer);
        this.timer = setTimeout(() => { this.increment(step, unit); }, 100);
    }

    decrement(step: number, unit: string) {
        const { value } = this.state;
        const { onChange } = this.props;
        const newValue = moment(value);

        newValue.subtract(step, unit);

        if (typeof onChange === 'function') {
            onChange(newValue.format('h:mm a'));
        }

        clearTimeout(this.timer);
        this.timer = setTimeout(() => { this.decrement(step, unit); }, 100);
    }

    render() {
        const { value } = this.state;
        const { classes, ...other } = this.props;

        const date = value || moment('1970-01-01T00:00Z').tz('GMT');

        return (
            <div {...other} className={cx(classes.root, other.className)}>
                <div className={classes.control}>
                    <KeyboardArrowUpIcon
                      className={classes.arrowUp}
                      onMouseDown={() => { this.handleMouseDown('increment', 'hour'); }}
                      onMouseUp={this.handleMouseUp}
                      onMouseLeave={this.handleMouseLeave}
                    />
                    <div className={classes.hour}>{date.format('h')}</div>
                    <KeyboardArrowDownIcon
                      className={classes.arrowDown}
                      onMouseDown={() => { this.handleMouseDown('decrement', 'hour'); }}
                      onMouseUp={this.handleMouseUp}
                      onMouseLeave={this.handleMouseLeave}
                    />
                </div>
                <div>:</div>
                <div className={classes.control}>
                    <KeyboardArrowUpIcon
                      className={classes.arrowUp}
                      onMouseDown={() => { this.handleMouseDown('increment', 'minute'); }}
                      onMouseUp={this.handleMouseUp}
                      onMouseLeave={this.handleMouseLeave}
                    />
                    <div className={classes.minute}>{date.format('mm')}</div>
                    <KeyboardArrowDownIcon
                      className={classes.arrowDown}
                      onMouseDown={() => { this.handleMouseDown('decrement', 'minute'); }}
                      onMouseUp={this.handleMouseUp}
                      onMouseLeave={this.handleMouseLeave}
                    />
                </div>
                <div className={classes.control}>
                    <Button variant="contained" color="primary" onClick={this.handleChangeMeridiem}>
                        {date.format('a')}
                    </Button>
                </div>
            </div>
        );
    }
}

export default withStyles(styles, { name: 'TimePicker' })(TimePicker);
