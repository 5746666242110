// @flow
import { createSelector } from 'reselect';
import moment from 'moment';
import numeral from 'numeral';
import { matchPath } from 'react-router';
import type { ContextRouter } from 'react-router';
import { createEntitySelector } from '../gigwalk';
import { canEditOrgConfig, canManageGroups, isPlatformAdmin, isWorker } from '../session';
import type { State as RootState } from '../../store';

const i18nKeysByComment = {
    // 'Gigwalk Ticket Comment Alert': 'gigComment',
    // 'Gigwalk Payment Alert': 'gigPayment',
    // 'Gigwalk Reopen Gig Alert': 'gigReopened',
    // 'Gigwalk Reject Gig Alert': 'gigRejected',
    // 'Gigwalk Assign Gig Alert': 'gigAssigned',
    // 'Gigwalk Unassign Gig Alert': 'gigUnassigned',
    'Gigwalk Comment': 'gigComment',
    'Gigwalk Payment Failed Alert': 'gigPaymentFailed',
    'Gigwalk Payment': 'gigPayment',
    'Gigwalk Submission': 'gigSubmitted',
    'Gigwalk Reopen': 'gigReopened',
    'Gigwalk Reject': 'gigRejected',
    'Gigwalk Assign': 'gigAssigned',
    'Gigwalk Unassign': 'gigUnassigned',
    'Applicant for': 'workerApplied',
};

const getUserId = (state: RootState): number => (state.session.user ? state.session.user.id : 0);
const getPathname = <P: {}>(state: RootState, props: { ...P, ...ContextRouter }): string => {
    const { location } = props;
    return location.pathname;
};
const getRoutePath = <P: {}>(state: RootState, props: { ...P, ...ContextRouter }): string => {
    const { match } = props;
    return match.path;
};

export const getNotifications = createSelector(
    createEntitySelector('notifications', (state: RootState) => state.header.notificationIds),
    (notifications: Object[]) => (
        notifications.reduce((list: Object[], notification: Object) => {
            const { date_created: dateCreated, id, resource, status, type } = notification;
            let data = null;
            let i18nKey = null;

            switch (type) {
                case 'FILE_DOWNLOAD': {
                    const { link, comment } = resource;
                    const regex = /(?:Data for (.*))|(?:Photos from (.*(?=\sproject$)))/;
                    const match = regex.exec(comment);

                    // As far as I know, dataDownload and photoDownload are the only 2 async
                    // tasks that will generate FILE_DOWNLOAD notifications
                    if (!match) {
                        return list;
                    }

                    i18nKey = match[1] ? 'dataDownloadReady' : 'photoDownloadReady';
                    data = { link, projectTitle: match[1] || match[2] };
                    break;
                }

                case 'PROJECT_LAUNCH': {
                    const { project } = resource;
                    const { id: projectId, organization_id: orgId, title: projectTitle } = project;
                    i18nKey = 'projectLaunched';
                    data = {
                        link: `/projects/${orgId}/active/${projectId}`,
                        projectTitle,
                    };
                    break;
                }

                case 'TICKET_EVENT':
                case 'COMMENT': {
                    const { comment, project, ticket } = resource;
                    const { organization_id: orgId, title: gigTitle } = project;
                    const { id: ticketId, price } = ticket;

                    // Currently, all ticket events that generate notifications target the workers
                    // assigned to a gig. If we want other users to receive notifications for such
                    // events, we should tailor the message we display to them, taking into account
                    // their role and whether or not they are/were assigned the gig.
                    const match = Object.keys(i18nKeysByComment)
                        .find((pattern: string) => comment.startsWith(pattern));

                    i18nKey = match ? i18nKeysByComment[match] : undefined;
                    data = {
                        gigTitle,
                        gigPrice: numeral(price).format('$0,0.00'),
                        link: `/tickets/${orgId}/info/${ticketId}`,
                    };
                    break;
                }

                default:
                    return list;
            }

            list.push({
                data,
                date: moment.utc(dateCreated).local().format(),
                i18nKey,
                id,
                status,
                type,
            });

            return list;
        }, [])
    ),
);

export const getPermissions = createSelector(
    canEditOrgConfig,
    canManageGroups,
    isPlatformAdmin,
    isWorker,
    (...args: boolean[]) => ({
        canEditOrgConfig: args[0],
        canManageGroups: args[1],
        isPlatformAdmin: args[2],
        isWorker: args[3],
    })
);

export const getNavItems = createSelector(
    getPathname,
    getRoutePath,
    getUserId,
    getPermissions,
    (pathname: string, routePath: string, userId: number, permissions: Object): Object[] => {
        const match = matchPath(pathname, { path: '/:app/:orgId?/:0?/:1?' });

        if (!match) {
            return [];
        }

        const app = match.params.app || '';
        const orgId = parseInt(match.params.orgId, 10);
        const baseUrl = `/${app}/${orgId}`;
        const navItems = [];

        switch (app) {
            case 'projects':
                // @todo Operators cannot currently see Drafts or Archived/Cancelled projects
                navItems.push(
                    { key: 'active', isActive: match.params['0'] === 'active', to: `${baseUrl}/active/list` },
                    { key: 'draft', isActive: match.params['0'] === 'draft', to: `${baseUrl}/draft/list` },
                    { key: 'archive', isActive: match.params['0'] === 'archive', to: `${baseUrl}/archive/list` }
                );
                break;

            case 'tickets': {
                const calendarPath = permissions.isWorker
                    ? `${baseUrl}/calendar/month/${userId}`
                    : `${baseUrl}/calendar/search`;

                navItems.push(
                    { key: 'tickets', isActive: /(list|map)/.test(match.params['0'] || ''), to: `${baseUrl}/list` },
                    { key: 'calendar', isActive: match.params['0'] === 'calendar', to: calendarPath },
                );
                break;
            }

            case 'resources':
                switch (match.params['0']) {
                    case 'location-lists':
                    case 'locations':
                        navItems.push(
                            { key: 'locationLists', isActive: match.params['0'] === 'location-lists', to: `${baseUrl}/location-lists/list` },
                            { key: 'locations', isActive: match.params['0'] === 'locations', to: `${baseUrl}/locations/list` },
                        );
                        break;

                    case 'target-lists':
                    case 'targets':
                        navItems.push(
                            { key: 'targetLists', isActive: match.params['0'] === 'target-lists', to: `${baseUrl}/target-lists/list` },
                            { key: 'targets', isActive: match.params['0'] === 'targets', to: `${baseUrl}/targets/list` },
                        );
                        break;

                    case 'certifications':
                        navItems.push({
                            key: 'certifications',
                            isActive: true,
                            to: `${baseUrl}/certifications/list`,
                        });
                        break;

                    case 'questions':
                        navItems.push({
                            key: 'questions',
                            isActive: true,
                            to: `${baseUrl}/questions/list`,
                        });
                        break;

                    default:
                        break;
                }
                break;

            case 'admin':
                switch (match.params['0']) {
                    case 'organizations':
                        // user && user.role === USER_ROLES.PLATFORM_ADMIN
                        navItems.push({
                            key: 'organizations',
                            isActive: true,
                            to: `${baseUrl}/organizations`,
                        });
                        break;

                    case 'payouts':
                    case 'transactions':
                        navItems.push({
                            key: 'transactions',
                            isActive: match.params['0'] === 'transactions',
                            to: `${baseUrl}/transactions/list`,
                        });
                        if (permissions.isPlatformAdmin) {
                            navItems.push({
                                key: 'payouts',
                                isActive: match.params['0'] === 'payouts',
                                to: `${baseUrl}/payouts/list`,
                            });
                        }
                        break;

                    case 'my-team':
                        navItems.push({
                            key: 'users',
                            isActive: match.params['1'] === 'users',
                            to: `${baseUrl}/my-team/users/list`,
                        });
                        if (permissions.canManageGroups) {
                            navItems.push({
                                key: 'groups',
                                isActive: match.params['1'] === 'groups',
                                to: `${baseUrl}/my-team/groups/list`,
                            });
                        }
                        if (permissions.isPlatformAdmin) {
                            navItems.push({
                                key: 'gigwalkers',
                                isActive: match.params['1'] === 'gigwalkers',
                                to: `${baseUrl}/my-team/gigwalkers/list`,
                            });
                        }
                        break;

                    case 'my-org':
                        if (permissions.canEditOrgConfig) {
                            navItems.push({
                                key: 'settings',
                                isActive: match.params['1'] === 'settings',
                                to: `${baseUrl}/my-org/settings`,
                            });
                        }
                        break;

                    default:
                        break;
                }
                break;

            default:
                break;
        }

        return navItems;
    }
);

export default {
    getNotifications,
    getNavItems,
};
