// @flow
import React from 'react';
import { TableBody } from '@material-ui/core';
import cx from 'classnames';
import type { Node } from 'react';

type Props = {
    children: Node,
    classes: Object,
    className?: string,
    data: Object[],
    loading: boolean,
    selected: any[],
};

export function Body(props: Props) {
    const { children, classes, className, data, loading, selected, ...other } = props;
    return (
        <TableBody
          {...other}
          component="div"
          className={cx(classes.body, className)}
          style={{ opacity: loading ? 0 : 1 }}
        >
            {children}
        </TableBody>
    );
}

export default Body;
