// @flow
import React from 'react';
import cx from 'classnames';
import {
    TableCell,
    TableSortLabel,
} from '@material-ui/core';
import type { Node } from 'react';

type Props = {
    children: Node,
    classes: Object,
    className?: string,
    data: Object[],
    defaultSortDesc: boolean,
    loading: boolean,
    padding: 'default' | 'checkbox' | 'none',
    selected: any[],
    toggleSort: Function,
};

export function Header(props: Props) {
    const {
        children,
        classes,
        className,
        data,
        defaultSortDesc,
        loading,
        padding,
        selected,
        toggleSort,
        ...other
    } = props;

    const sortable = className && className.includes('-cursor-pointer');
    const isSortedDesc = className && className.includes('-sort-desc');
    const isSortedAsc = className && className.includes('-sort-asc');
    const sorted = isSortedAsc || isSortedDesc;
    const sortDirection = (!sorted && defaultSortDesc) || isSortedDesc ? 'desc' : 'asc';

    return (
        <TableCell
          {...other}
          component="div"
          className={cx({
              [classes.sortable]: sortable,
              [classes.sorted]: sorted,
              [classes.defaultPadding]: padding === 'default',
          })}
          classes={{ head: classes.header }}
          padding={padding}
        >
            {sortable
                ? (
                    <TableSortLabel
                      active={sorted}
                      direction={sortDirection}
                      onClick={toggleSort}
                    >
                        {children}
                    </TableSortLabel>
                )
                : children}
        </TableCell>
    );
}

export default Header;
