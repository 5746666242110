// @flow
import { handleActions } from 'redux-actions';
import type { ActionType } from 'redux-actions';
import types from './types';
import typeof {
    expandQuestion,
    collapseQuestion,
} from './actions';

export type State = {
    expanded: ?number,
};

export const init: State = {
    expanded: null,
};

export default handleActions({
    [types.EXPAND_QUESTION]: (state: State, action: ActionType<expandQuestion>): State => {
        if (action.payload == null) return state;
        return {
            ...state,
            expanded: action.payload,
        };
    },
    [types.COLLAPSE_QUESTION]: (state: State, action: ActionType<collapseQuestion>): State => {
        if (action.payload == null) return state;
        return {
            ...state,
            expanded: action.payload === state.expanded ? null : state.expanded,
        };
    },
}, init);
