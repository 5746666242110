// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback, useMemo } from 'react';
import cx from 'classnames';
import noop from 'lodash/noop';
// $FlowTypedIssue update react-redux libdef
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import {
    IconButton,
    InputAdornment,
    TextField,
} from '@material-ui/core';
import {
    AccountCircle as AccountCircleIcon,
    Clear as ClearIcon,
} from '@material-ui/icons';
import type { ContextRouter } from 'react-router';
import getDisplayName from '../../../../utils/getDisplayName';
import { close as closeDialog, open as openDialog } from '../../../../ducks/dialog';
import {
    getAvailableActions,
    getPermittedActions,
    getTicket,
    loadTicket,
} from '../../../../ducks/ticketDetail';
import styles from './styles';
import type { State as RootState } from '../../../../store';

type Props = ContextRouter & {
    className?: string,
};

const useStyles = makeStyles(styles, { name: 'Assignee' });

export default function Assignee(props: Props) {
    const { match } = props;
    const ticketId = parseInt(match.params.ticketId, 10);

    const dispatch = useDispatch();
    const classes = useStyles(props);
    const { t } = useTranslation();

    const user = useSelector((state: RootState) => state.session.user);
    const ticket = useSelector((state: RootState) => getTicket(state));
    const permittedActions = useSelector((state: RootState) => getPermittedActions(state));
    const availableActions = useSelector((state: RootState) => getAvailableActions(state));

    let userId;
    let userRole;
    if (user) {
        ({ id: userId, role: userRole } = user);
    }

    const assignee = ticket ? ticket.customer : null;
    const isDoubleOptin = ticket ? ticket.subscription.is_double_optin : false;
    const needsPublicWorkforce = ticket ? ticket.subscription.needs_public_workforce : false;

    const disabled = useMemo(() => {
        const fields = [];
        const canAssign = permittedActions.includes('assign') && availableActions.includes('assign');
        const canUnassign = permittedActions.includes('unassign') && availableActions.includes('unassign');
        if (!canAssign && !canUnassign) {
            fields.push('assignee');
        }
        return fields;
    }, [availableActions, permittedActions]);

    const displayName = useMemo(() => {
        if (assignee) {
            const hideDetails = userRole !== 'PLATFORM_ADMIN' && userId !== assignee.id && needsPublicWorkforce;
            return getDisplayName(assignee, { obfuscate: hideDetails });
        }
        return '';
    }, [assignee, needsPublicWorkforce, userId, userRole]);

    const handleUnassignClick = useCallback(() => {
        const dialogProps = {
            onClose: () => {
                dispatch(closeDialog());
            },
            onSubmitSuccess: () => {
                dispatch(closeDialog());
                dispatch(loadTicket(ticketId));
            },
        };

        dispatch(openDialog('unassign', { ...dialogProps, ticketIds: [ticketId] }));
    }, [dispatch, ticketId]);

    const handleAssignClick = useCallback(() => {
        const dialogProps = {
            onClose: () => {
                dispatch(closeDialog());
            },
            onSubmitSuccess: () => {
                dispatch(closeDialog());
                dispatch(loadTicket(ticketId));
            },
        };

        if (isDoubleOptin) {
            dispatch(openDialog('assignApplicant', { ...dialogProps, ticketId }));
        } else {
            dispatch(openDialog('assign', { ...dialogProps, ticketIds: [ticketId] }));
        }
    }, [dispatch, isDoubleOptin, ticketId]);

    const canUnassign = assignee && !disabled.includes('assignee');
    const canAssign = !assignee && !disabled.includes('assignee');
    let endAdornment = null;

    if (canUnassign) {
        endAdornment = (
            <InputAdornment position="end">
                <IconButton className={classes.clearButton} onClick={handleUnassignClick}>
                    <ClearIcon fontSize="inherit" />
                </IconButton>
            </InputAdornment>
        );
    }

    return (
        <div className={classes.container}>
            <AccountCircleIcon className={classes.icon} />
            <span className={classes.label}>{t('ticketDetail.summary.assignee')}</span>
            <TextField
              disabled={disabled.includes('assignee')}
              InputProps={{
                  classes: {
                      root: classes.input,
                      input: cx(classes.inputElement, { [classes.assigned]: assignee }),
                      disabled: classes.disabled,
                  },
                  endAdornment,
                  inputProps: { size: 1 },
                  readOnly: true,
              }}
              margin="dense"
              onClick={canAssign ? handleAssignClick : noop}
              value={assignee ? displayName : 'unassigned'}
              variant="outlined"
            />
        </div>
    );
}
