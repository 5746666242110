// @flow
import React from 'react';
import cx from 'classnames';
import {
    CircularProgress,
    FilledInput,
    Input,
    InputAdornment,
    OutlinedInput,
} from '@material-ui/core';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@material-ui/icons';

const variantComponent = {
    standard: Input,
    filled: FilledInput,
    outlined: OutlinedInput,
};

const inputComponent = (props: Object) => {
    const { inputRef, ...other } = props;
    return <div ref={inputRef} {...other} />;
};

const LoadingIndicator = (props: Object) => {
    const { selectProps } = props;
    const { classes, loadingIcon } = selectProps;

    return (
        <div className={classes.loadingIndicator}>
            {loadingIcon || <CircularProgress size={18} thickness={4.4} />}
        </div>
    );
};

const DropdownIndicator = (props: Object) => {
    const { selectProps } = props;
    const { classes, dropdownIcon, isLoading } = selectProps;

    if (isLoading) {
        return null;
    }

    return (
        <div className={classes.dropdownIndicator}>
            {dropdownIcon || <KeyboardArrowDownIcon />}
        </div>
    );
};

export default function Control(props: Object) {
    const { children, innerProps, innerRef, selectProps, ...other } = props;
    const { classes, ControlProps, isLoading } = selectProps;
    const { inputProps, variant, ...otherControlProps } = ControlProps || {};

    const className = cx(classes.control, {
        [classes.outlined]: variant === 'outlined',
        [classes.filled]: variant === 'filled',
    });

    const InputComponent = variantComponent[variant] || Input;
    const commonProps = { selectProps, ...other };
    const endAdornment = (
        <InputAdornment position="end">
            {isLoading
                ? <LoadingIndicator {...commonProps} />
                : <DropdownIndicator {...commonProps} />}
        </InputAdornment>
    );

    return (
        <InputComponent
          {...otherControlProps}
          className={className}
          inputComponent={inputComponent}
          inputProps={{
              ...innerProps,
              ...inputProps,
              className: classes.input,
              inputRef: innerRef,
              children,
          }}
          endAdornment={endAdornment}
        />
    );
}
