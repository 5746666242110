// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback } from 'react';
import { FormControlLabel, Switch } from '@material-ui/core';
import { VALUE_TYPE } from '../../../../../../../browser/shared/constant/ProjectConstant';

type Props = {
    className?: string,
    label?: string,
    onChange: (value: 'MULTI_SELECT' | 'MULTIPLE_CHOICE') => void,
    value?: 'MULTI_SELECT' | 'MULTIPLE_CHOICE',
};

export function MultiSelectSwitch(props: Props) {
    // To prevent flow errors, remove onBlur/onChange from switchProps. Flow does not seem
    // to understand that the onChange/onBlur props specified below take precedence over
    // those in switchProps
    const { label, onChange, value, ...other } = props;

    const handleChange = useCallback((event: SyntheticInputEvent<any>) => {
        const { checked } = event.target;
        onChange(checked ? VALUE_TYPE.MULTI_SELECT : VALUE_TYPE.MULTIPLE_CHOICE);
    }, [onChange]);

    return (
        <FormControlLabel
          {...other}
          checked={value === VALUE_TYPE.MULTI_SELECT}
          control={<Switch />}
          onChange={handleChange}
          label={label}
        />
    );
}

MultiSelectSwitch.defaultProps = {
    onChange: () => {},
};

export default MultiSelectSwitch;
