var Handlebars = require('/tmp/build_a9d70681abb25b82ca4489607bd6ccee/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"sub-target\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"for_target") || (depth0 != null ? lookupProperty(depth0,"for_target") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"for_target","hash":{},"data":data,"loc":{"start":{"line":6,"column":54},"end":{"line":6,"column":70}}}) : helper)))
    + ": "
    + alias4(((helper = (helper = lookupProperty(helpers,"targetName") || (depth0 != null ? lookupProperty(depth0,"targetName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"targetName","hash":{},"data":data,"loc":{"start":{"line":6,"column":72},"end":{"line":6,"column":88}}}) : helper)))
    + "</span><br>";
},"3":function(container,depth0,helpers,partials,data) {
    return "";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- question type is PHONE_NUMBER -->\n  <div class=\"type numeric phone-number\" data-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":2,"column":50},"end":{"line":2,"column":58}}}) : helper)))
    + "\" data-targetid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"targetID") || (depth0 != null ? lookupProperty(depth0,"targetID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"targetID","hash":{},"data":data,"loc":{"start":{"line":2,"column":75},"end":{"line":2,"column":89}}}) : helper)))
    + "\">\n    <div class=\"answers\">\n      <div class=\"answer\">\n        <div class=\"description\">\n          "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"targetName") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":10},"end":{"line":6,"column":107}}})) != null ? stack1 : "")
    + "\n          "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"question_text") || (depth0 != null ? lookupProperty(depth0,"question_text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"question_text","hash":{},"data":data,"loc":{"start":{"line":7,"column":10},"end":{"line":7,"column":31}}}) : helper))) != null ? stack1 : "")
    + "\n        </div>\n        <input type=\"tel\" style=\"width:200px;\" data-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":9,"column":56},"end":{"line":9,"column":64}}}) : helper)))
    + "\" data-input name=\""
    + ((stack1 = (lookupProperty(helpers,"uniqueID")||(depth0 && lookupProperty(depth0,"uniqueID"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"answer_") : depth0),{"name":"uniqueID","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":83},"end":{"line":9,"column":118}}})) != null ? stack1 : "")
    + " answer number\" placeholder=\""
    + alias4(container.lambda(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"placeholders") : depths[1])) != null ? lookupProperty(stack1,"ex_7") : stack1), depth0))
    + "\" />\n      </div>\n    </div>\n</div>\n<!-- END PHONE_NUMBER -->\n";
},"useData":true,"useDepths":true});