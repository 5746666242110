// @flow
import type { Certification } from 'gigwalk/lib/api/certifications/types';
import type { Group } from 'gigwalk/lib/api/groups/types';
import type { DataType } from 'gigwalk/lib/api/dataTypes/types';
import { SCHEDULE_TYPES } from '../../../../browser/shared/constant/ProjectConstant';
import mapEntityToQuestion from './mapEntityToQuestion';

export default function mapSubscriptionToValues(subscription: ?Object, dataTypes: DataType[]): Object {
    if (!subscription) {
        return {
            allowGalleryUpload: false,
            asapEndDate: '',
            autoassign: false,
            certifications: [],
            description: '',
            fifoAssign: false,
            gigPrice: '',
            groups: [],
            nearTicketDistance: '',
            needsApproval: false,
            needsPublicWorkforce: false,
            locationListId: -1,
            questionList: [],
            rangeEndDate: '',
            rangeStartDate: '',
            scheduleType: '',
            submitDeadline: '',
            optinType: '',
            oversamplingOverage: '',
            oversamplingTarget: '',
            projectFund: '',
            resWindowHours: [],
            title: '',
            twoWayRatingEnabled: false,
            workerCount: 1,
        };
    }

    const obsTargetLists = subscription.organization_observation_target_lists || [];
    const records = obsTargetLists.reduce((arr: Object[], targetList: Object) => {
        if (targetList.data_types) {
            const record = targetList.data_types.map((d: Object): Object => ({
                children: d.children,
                id: d.data_type_id,
                targetListId: targetList.observation_target_list_id,
            }));
            return [...arr, ...record];
        }
        return arr;
    }, []);

    const questionList = [];
    records.forEach((record: Object) => {
        const dataType = dataTypes.find(({ id }: DataType) => id === record.id);
        if (dataType) {
            questionList.push({
                ...mapEntityToQuestion(dataType),
                children: record.children,
                targetListId: record.targetListId,
            });
        }
    });

    let gigPrice = '';
    let nearTicketDistance = '';
    if (subscription.needs_public_workforce) {
        gigPrice = typeof subscription.min_gig_price === 'number' ? subscription.min_gig_price : 3;
        nearTicketDistance = typeof subscription.near_ticket_distance === 'number' ? subscription.near_ticket_distance : 5;
    }

    return {
        allowGalleryUpload: subscription.organization_data ? subscription.organization_data.allowGalleryUpload || false : false,
        asapEndDate: subscription.schedule_type === SCHEDULE_TYPES.ASAP ? subscription.end_date || '' : '',
        autoassign: !!subscription.autoassign,
        certifications: subscription.certifications
            ? subscription.certifications.filter((certification: ?Certification) => !!certification)
            : [],
        description: subscription.description || '',
        fifoAssign: subscription.organization_data ? subscription.organization_data.fifo_assign_for_system_approved_optin || false : false,
        gigPrice,
        groups: subscription.groups
            ? subscription.groups.filter((group: ?Group) => !!group)
            : [],
        nearTicketDistance,
        needsApproval: !!subscription.needs_approval,
        needsPublicWorkforce: !!(subscription.needs_public_workforce || subscription.needs_core),
        locationCount: subscription.organization_location_lists && subscription.organization_location_lists[0]
            ? subscription.organization_location_lists[0].location_count
            : 0,
        locationListId: subscription.organization_location_lists && subscription.organization_location_lists[0]
            ? subscription.organization_location_lists[0].id
            : -1,
        questionList,
        rangeEndDate: subscription.schedule_type === SCHEDULE_TYPES.RANGE ? subscription.end_date || '' : '',
        rangeStartDate: subscription.schedule_type === SCHEDULE_TYPES.RANGE ? subscription.start_date || '' : '',
        scheduleType: subscription.schedule_type || '',
        submitDeadline: subscription.submit_deadline || '',
        optinType: subscription.optin_type || '',
        oversamplingOverage: subscription.tickets_overage || '',
        oversamplingTarget: subscription.tickets_target || '',
        projectFund: subscription.project_fund || '',
        title: subscription.title || '',
        twoWayRatingEnabled: !!subscription.two_way_rating_enabled,
        resWindowHours: subscription.res_window_hours ? subscription.res_window_hours.split(',') : [],
        workerCount: subscription.worker_count || 1,
    };
}
