// @flow
import { createAction } from 'redux-actions';
import { matchPath } from 'react-router';
import identity from 'lodash/identity';
import omitBy from 'lodash/omitBy';
import type { Dispatch } from 'redux';
import type { $AxiosError } from 'axios';
import { searchCustomers } from '../gigwalk';
import { findPlaceFromQuery } from '../googleMaps';
import { getAPIParams } from './selectors';
import types from './types';
import type { State as RootState } from '../../store';

export const toggleFilterDrawer = createAction(types.TOGGLE_FILTER_DRAWER);

export const fetchDataSuccess = createAction(types.FETCH_DATA_SUCCESS, identity, (data: Object, meta: Object): Object => meta);
export const fetchDataError = createAction(types.FETCH_DATA_ERROR);
export const fetchData = createAction(
    types.FETCH_DATA,
    (filters: ?Object[], keywords: ?string, sort: ?Object[], limit: ?number, offset: ?number): Function => (
        (dispatch: Dispatch<any>): Promise<void> => {
            const data = omitBy({ filters, keywords, offset, limit, sort }, (value) => value == null);
            return dispatch(searchCustomers(data))
                .then((resp: Object) => dispatch(fetchDataSuccess(resp.result, resp.metadata)))
                .catch((err: $AxiosError<any>) => {
                    dispatch(fetchDataError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const loadFiltersSuccess = createAction(types.LOAD_FILTERS_SUCCESS);
export const loadFiltersError = createAction(types.LOAD_FILTERS_ERROR);
export const loadFilters = createAction(
    types.LOAD_FILTERS,
    (search: Object) => (
        (dispatch: Dispatch<any>) => Promise.resolve()
            .then(() => {
                const promises = [];
                if (search.geo) {
                    search.geo.forEach((geo: Object) => {
                        const [, address] = Object.entries(geo)[0];
                        const params = {
                            fields: ['formatted_address', 'place_id'],
                            query: address,
                        };
                        promises.push(dispatch(findPlaceFromQuery(params)));
                    });
                }
                return Promise.all(promises);
            })
            .then(() => {
                dispatch(loadFiltersSuccess());
            })
            .catch((err: $AxiosError<any>) => {
                dispatch(loadFiltersError(err));
                return Promise.reject(err);
            })
    )
);

export const refreshError = createAction(types.REFRESH_ERROR);
export const refreshSuccess = createAction(types.REFRESH_SUCCESS);
export const refresh = createAction(
    types.REFRESH,
    (): Function => (
        (dispatch: Dispatch<any>, getState: () => RootState): Promise<void> => {
            const state = getState();

            // @todo: Consider using another method to compute match to pass to selectors
            // I think the next best approach would be to pass props/match to action creators. The
            // issue with the current approach is that state.router.location is not guaranteed to always
            // be in sync with react-router. For instance, server-side matching and rendering happens
            // synchronously. Redux state will be updated in response, but content will already be rendered.
            const { location } = state.router;
            const match = matchPath(location.pathname, { path: '/admin/:orgId/my-team/gigwalkers/list' });

            if (!match) {
                return Promise.reject();
            }

            const { filters, keywords, sort, limit, offset } = getAPIParams(state, { location, match });

            return dispatch(fetchData(filters, keywords, sort, limit, offset))
                .then(() => dispatch(refreshSuccess()))
                .catch((err: $AxiosError<any>) => {
                    dispatch(refreshError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const emailDataError = createAction(types.EMAIL_DATA_ERROR);
export const emailDataSuccess = createAction(types.EMAIL_DATA_SUCCESS);
export const emailData = createAction(
    types.EMAIL_DATA,
    (): Function => (
        (dispatch: Dispatch<any>, getState: () => RootState): Promise<void> => {
            const state = getState();

            // @todo: Consider using another method to compute match to pass to selectors
            // I think the next best approach would be to pass props/match to action creators. The
            // issue with the current approach is that state.router.location is not guaranteed to always
            // be in sync with react-router. For instance, server-side matching and rendering happens
            // synchronously. Redux state will be updated in response, but content will already be rendered.
            const { location } = state.router;
            const match = matchPath(location.pathname, { path: '/admin/:orgId/my-team/gigwalkers/list' });

            if (!match) {
                return Promise.reject();
            }

            const { filters, keywords, sort } = getAPIParams(state, { location, match });
            const data = omitBy({ filters, keywords, sort }, (value) => value == null);

            // Specifying csv=1 will email the search results to the caller
            return dispatch(searchCustomers({ ...data, csv: 1 }))
                .then(() => dispatch(emailDataSuccess()))
                .catch((err: $AxiosError<any>) => {
                    dispatch(emailDataError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export default {
    emailData,
    emailDataError,
    emailDataSuccess,
    fetchData,
    fetchDataError,
    fetchDataSuccess,
    loadFilters,
    loadFiltersError,
    loadFiltersSuccess,
    refresh,
    refreshError,
    refreshSuccess,
};
