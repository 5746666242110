// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback } from 'react';
import cx from 'classnames';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { FormControlLabel, FormHelperText, Radio } from '@material-ui/core';
import styles from './styles';

type Props = {
    allowUncheck?: boolean,
    classes?: Object,
    className?: string,
    disabled?: boolean,
    label?: string,
    labelPlacement?: 'end' | 'start' | 'top' | 'bottom',
    onClick?: (event: SyntheticEvent<any>) => void
};

const useStyles = makeStyles(styles, { name: 'FormikRadio' });

export default function FormikRadio(props: Props) {
    const {
        allowUncheck,
        classes: classesProp,
        className,
        disabled,
        label,
        labelPlacement,
        onClick,
        ...other
    } = props;

    const classes = useStyles(props);
    const { i18n, t } = useTranslation();
    const [field, meta, helpers] = useField({ ...props, type: 'radio' });

    const { checked } = field;
    const { error, touched } = meta;
    const { setValue } = helpers;

    const handleClick = useCallback((event: SyntheticEvent<any>) => {
        if (checked && allowUncheck) {
            setValue();
        }
        if (onClick) {
            onClick(event);
        }
    }, [allowUncheck, checked, onClick, setValue]);

    let errorMessage = null;
    if (touched && error) {
        if (typeof error === 'string') {
            errorMessage = i18n.exists(error) ? t(error) : error;
        } else if ('message' in error) {
            const { message, ...translateOptions } = error;
            errorMessage = i18n.exists(message) ? t(message, { ...translateOptions }) : message;
        }
    }

    const {
        root: rootClassName,
        wrapper: wrapperClassName,
        label: labelClassName,
        radio: radioClassName,
        errorMessage: errorClassName,
        ...otherClasses
    } = classes;

    const FormControlLabelProps = { disabled, label, labelPlacement };

    return (
        <div className={cx(rootClassName, className)}>
            <FormControlLabel
              classes={{
                  disabled: classes.disabled,
                  label: labelClassName,
                  root: wrapperClassName,
              }}
              control={(
                  <Radio
                    {...other}
                    {...field}
                    onClick={handleClick}
                    classes={{ root: radioClassName, ...otherClasses }}
                  />
              )}
              {...FormControlLabelProps}
            />
            {errorMessage
                ? <FormHelperText error className={errorClassName}>{errorMessage}</FormHelperText>
                : null}
        </div>
    );
}
