// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback } from 'react';
import { Form } from 'formik';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import {
    Public as PublicIcon,
    Work as WorkIcon,
} from '@material-ui/icons';
import type { Connector } from 'react-redux';
import type { ContextRouter } from 'react-router';
import { OPTIN_TYPES } from '../../../../../browser/shared/constant/ProjectConstant';
import ToggleButton from '../../../../components/ToggleButton';
import entitySelector from '../../../../redux/entities/entitySelector';
import Step from '../../components/Step';
import styles from './styles';
import type { State as RootState } from '../../../../redux/initialState';

type OwnProps = ContextRouter & {
    setFieldValue: (field: string, value: any) => void,
    values: Object,
};
type StateProps = {
    editMode: boolean,
};
type Props = OwnProps & StateProps;

const useStyles = makeStyles(styles, { name: 'Workforce' });

export function Workforce(props: Props) {
    const { editMode, setFieldValue, values } = props;
    const { needsPublicWorkforce, optinType } = values;

    const classes = useStyles(props);
    const { t } = useTranslation();

    const handleChange = useCallback((event: SyntheticEvent<any>) => {
        const { name } = event.currentTarget;
        const enabled = name === 'publicWorkforce';

        if (needsPublicWorkforce !== enabled) {
            setFieldValue('needsPublicWorkforce', enabled);
            setFieldValue('needsApproval', enabled);
            setFieldValue('twoWayRatingEnabled', enabled);

            if (enabled) {
                if (optinType) {
                    setFieldValue('optinType', OPTIN_TYPES.SYSTEM_APPROVED_OPTIN);
                    setFieldValue('autoassign', false);
                }
                setFieldValue('groups', []);
                setFieldValue('fifoAssign', false);
                setFieldValue('allowGalleryUpload', false);
                setFieldValue('resWindowHours', []);
                setFieldValue('nearTicketDistance', 5);
                setFieldValue('gigPrice', 3);
            } else {
                if (optinType) {
                    setFieldValue('optinType', OPTIN_TYPES.SIMPLE_OPTIN);
                    setFieldValue('autoassign', false);
                }
                setFieldValue('groups', []);
                setFieldValue('fifoAssign', false);
                setFieldValue('allowGalleryUpload', true);
                setFieldValue('resWindowHours', []);
                setFieldValue('nearTicketDistance', '');
                setFieldValue('gigPrice', '');
            }

            // Since you can't mix private and public certifications, we need to clear any certifications
            // previously added to the project
            setFieldValue('certifications', []);
        }
    }, [needsPublicWorkforce, optinType, setFieldValue]);

    return (
        <Step title={t('projectBuilder.people.workforce.header')} subheader={t('projectBuilder.people.workforce.subheader')}>
            <Form className={classes.form}>
                <Typography className={classes.label} align="center">
                    {t('projectBuilder.people.workforce.selectOne')}
                </Typography>
                <div className={classes.grid}>
                    <ToggleButton
                      classes={{
                          root: classes.toggleButton,
                          label: classes.toggleButtonLabel,
                          selected: classes.selected,
                      }}
                      color="primary"
                      disabled={editMode}
                      name="privateWorkforce"
                      onChange={handleChange}
                      selected={values.needsPublicWorkforce === false}
                    >
                        <WorkIcon className={classes.icon} />
                        <p className={classes.caption}>
                            {t('projectBuilder.people.workforce.privateWorkforce')}
                        </p>
                    </ToggleButton>
                    <ToggleButton
                      classes={{
                          root: classes.toggleButton,
                          label: classes.toggleButtonLabel,
                          selected: classes.selected,
                      }}
                      color="primary"
                      disabled={editMode}
                      name="publicWorkforce"
                      onChange={handleChange}
                      selected={values.needsPublicWorkforce === true}
                    >
                        <PublicIcon className={classes.icon} />
                        <p className={classes.caption}>
                            {t('projectBuilder.people.workforce.publicWorkforce')}
                        </p>
                    </ToggleButton>
                </div>
            </Form>
        </Step>
    );
}

const subscriptionSelector = entitySelector('subscriptions');
const mapStateToProps = (state: RootState, props: OwnProps): StateProps => {
    const { match } = props;
    return {
        editMode: subscriptionSelector(state, match.params.subscriptionId, 'state') === 'ACTIVE',
    };
};

const connector: Connector<OwnProps, Props> = connect(mapStateToProps);
export default withRouter(connector(Workforce));
