// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback } from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import {
    Notifications as NotificationsIcon,
    NotificationsOff as NotificationsOffIcon,
} from '@material-ui/icons';

type Props = {
    className?: string,
    onChange: (value: boolean) => void,
    readOnly?: boolean,
    tooltip?: string,
    value?: boolean,
};

export function Alert(props: Props) {
    const { className, readOnly, tooltip, value, onChange } = props;

    const handleClick = useCallback(() => {
        onChange(!value);
    }, [onChange, value]);

    if (readOnly) {
        return (
            <div className={className} style={{ padding: 12 }}>
                {value ? <NotificationsIcon color="secondary" /> : <NotificationsOffIcon />}
            </div>
        );
    }

    return (
        <Tooltip title={tooltip} placement="top">
            <IconButton
              className={className}
              color={value ? 'secondary' : 'inherit'}
              onClick={handleClick}
            >
                {value ? <NotificationsIcon /> : <NotificationsOffIcon />}
            </IconButton>
        </Tooltip>
    );
}

Alert.defaultProps = {
    onChange: () => {},
};

export default Alert;
