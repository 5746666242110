// @flow
import { darken } from '@material-ui/core/styles';

export default (theme: Object) => ({
    form: {
        padding: theme.spacing(3, 0, 5, 0),
    },
    label: {
        fontWeight: 600,
    },
    grid: {
        display: 'flex',

        '& > *': {
            margin: theme.spacing(1),
        },
    },
    toggleButton: {
        width: 140,
        height: 140,
        padding: theme.spacing(0.5, 1),

        [theme.breakpoints.down('sm')]: {
            width: 120,
            height: 120,
        },

        color: theme.palette.grey[500],
        backgroundColor: theme.palette.common.white,

        '&:hover': {
            color: theme.palette.grey[500],
            backgroundColor: darken(theme.palette.common.white, 0.1),
        },

        '&$selected': {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.light,

            '&:hover': {
                backgroundColor: darken(theme.palette.primary.light, 0.1),
            },
        },
    },
    toggleButtonLabel: {
        display: 'inline-block',
        lineHeight: 'normal',

        // '&:before, &:after': {
        //     content: '""',
        // },
    },
    icon: {
        width: '40%',
        height: '40%',
    },
    caption: {
        width: '100%',
        textAlign: 'center',
    },
    selected: {},
});
