// @flow
import React from 'react';
import cx from 'classnames';
import type { Node } from 'react';

export type Props = {
    children?: Node,
    className?: string,
    title?: string,
};

export default function SimplePane(props: Props) {
    const { children, className, title } = props;
    return (
        <div className={cx('c-content', className)}>
            {title ? <h2>{title}</h2> : ''}
            <div className="u-margin-bottom-lg">
                {children}
            </div>
        </div>
    );
}
