// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { NavLink } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Badge, Fab, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import type { $AxiosError } from 'axios';
import type { Dispatch } from 'redux';
import type { Connector } from 'react-redux';
import type { ContextRouter } from 'react-router';
import { format } from '../../../browser/shared/util/gigwalkApiErrorUtil';
import getDisplayName from '../../util/getDisplayName';
import waitForCondition from '../../components/waitForCondition';
import SimplePane from '../../components/SimplePane';
import LoadingPage from '../../components/LoadingPage';
import UserAvatar from '../../components/UserAvatar';
import StarRating from '../../components/StarRating';
import Account from './containers/Account';
import Preferences from './containers/Preferences';
import Activity from './containers/Activity';
import * as snackbar from '../../ducks/snackbar';
import { actions, selectors } from './duck';
import styles from './styles';
import type { State as RootState } from '../../redux/initialState';

type OwnProps = ContextRouter & {};
type StateProps = {
    customer: Object,
    user: ?Object,
};
type DispatchProps = {
    enqueueSnackbar: (message: string, options: Object) => void,
    loadCustomer: (customerId: number, orgId: number) => Promise<void>,
};
type Props = OwnProps & StateProps & DispatchProps;

const tabs = [
    'account',
    'preferences',
    'activity',
];

const useStyles = makeStyles(styles, { name: 'ProfilePage' });

export function ProfilePage(props: Props) {
    const { customer, enqueueSnackbar, loadCustomer, match, user } = props;
    const customerId = parseInt(match.params.customerId, 10);
    const orgId = parseInt(match.params.orgId, 10);
    const userId = user ? user.id : -1;

    const classes = useStyles(props);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        loadCustomer(customerId, orgId)
            .then(() => setLoading(false))
            .catch((err: $AxiosError<any>) => {
                const resp = err ? err.response : null;
                if (resp && resp.data && resp.data.gw_api_response) {
                    const message = format(resp.data.gw_api_response);
                    enqueueSnackbar(message, { variant: 'error' });
                }
            });
    }, [customerId, enqueueSnackbar, loadCustomer, orgId, userId]);

    if (loading) {
        return (
            <SimplePane>
                <LoadingPage />
            </SimplePane>
        );
    }

    const { organization, role } = customer;
    const rating = parseFloat(customer.rating_score);

    return (
        <SimplePane>
            <div className={classes.header}>
                <Badge
                  badgeContent={(
                      <Fab className={classes.editButton}>
                          <EditIcon fontSize="inherit" />
                      </Fab>
                  )}
                  classes={{
                      root: classes.avatar,
                      badge: classes.avatarBadge,
                  }}
                  invisible
                >
                    <UserAvatar size="medium" user={customer} />
                </Badge>

                <div className={classes.user}>
                    <div className={classes.userPrimary}>
                        <Typography className={classes.name} color="inherit" variant="h5">
                            {getDisplayName(customer)}
                        </Typography>
                        <div className={classes.rating}>
                            <StarRating value={rating || 0} disableHover />
                            <span>{rating ? rating.toFixed(1) : 'N/A'}</span>
                        </div>
                    </div>
                    <div className={classes.userSecondary}>
                        {`${organization.organization_name} | ${role}`}
                    </div>
                </div>
            </div>

            <Tabs className={classes.tabs} scrollButtons="off" value={false} variant="scrollable">
                {tabs.map((tab: string) => (
                    <Tab
                      activeClassName={classes.active}
                      component={NavLink}
                      className={classes.tab}
                      key={tab}
                      label={t(`profilePage.tabs.${tab}`)}
                      to={`${match.url}/${tab}`}
                    />
                ))}
            </Tabs>

            <Paper style={{ padding: 24 }}>
                <Switch>
                    <Route path={`${match.path}/account`} component={Account} />
                    <Route path={`${match.path}/preferences`} component={Preferences} />
                    <Route path={`${match.path}/activity`} component={Activity} />
                    <Redirect to={`${match.url}/account`} />
                </Switch>
            </Paper>
        </SimplePane>
    );
}

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => ({
    customer: selectors.getCustomer(state, props),
    user: state.session.user,
});
const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    enqueueSnackbar: (message: string, options: Object) => dispatch(snackbar.actions.enqueue(message, options)),
    loadCustomer: (customerId: number, orgId: number) => dispatch(actions.loadCustomer(customerId, orgId)),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
const enhance = compose(
    waitForCondition(() => global.hasOwnProperty('google'), 100),
    connector,
);

export default enhance(ProfilePage);
