// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Chip, Typography } from '@material-ui/core';
import {
    Cancel as CancelIcon,
    VerifiedUser as VerifiedUserIcon,
} from '@material-ui/icons';
import styles from './styles';

type Props = {
    form: Object,
    remove: (index: number) => any,
};

const useStyles = makeStyles(styles, { name: 'CertificationList' });

export default function CertificationList(props: Props) {
    const { form, remove } = props;
    const { certifications } = form.values;

    const classes = useStyles(props);
    const { t } = useTranslation();

    const handleDelete = useCallback((event: SyntheticEvent<any>) => {
        const { index } = event.currentTarget.dataset;
        remove(index);
    }, [remove]);

    if (certifications.length === 0) {
        return null;
    }

    return (
        <div className={classes.root}>
            <Typography className={classes.label} align="center">
                {t('projectBuilder.people.certifications.requiredCertifications')}
            </Typography>
            <ul>
                {certifications.map((certification: Object, index: number) => (
                    <Chip
                      avatar={<Avatar><VerifiedUserIcon /></Avatar>}
                      className={classes.certification}
                      classes={{
                          root: classes.chip,
                          avatar: classes.chipAvatar,
                          avatarChildren: classes.chipAvatarChildren,
                          label: classes.chipLabel,
                          deleteIcon: classes.chipDeleteIcon,
                      }}
                      deleteIcon={<CancelIcon data-index={index} />}
                      key={index}
                      label={certification.title}
                      onDelete={handleDelete}
                    />
                ))}
            </ul>
        </div>
    );
}
