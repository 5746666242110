var Handlebars = require('/tmp/build_a9d70681abb25b82ca4489607bd6ccee/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"added\">\n  <div class=\"attachment image\">\n    <img data-url=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"url") || (depth0 != null ? lookupProperty(depth0,"url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":3,"column":19},"end":{"line":3,"column":28}}}) : helper)))
    + "\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"url") || (depth0 != null ? lookupProperty(depth0,"url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":3,"column":35},"end":{"line":3,"column":44}}}) : helper)))
    + "\" />\n    <span class=\"im icon-magnify\" data-index=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":4,"column":46},"end":{"line":4,"column":57}}}) : helper)))
    + "\" data-action=\"magnify\"></span>\n  </div>\n  <div class=\"icon-buttons\">\n    <!--<a class=\"im icon-edit\" href=\"javascript:;\" data-action=\"edit\"></a>-->\n    <a class=\"im icon-trash\" href=\"javascript:;\" data-action=\"trash\"></a>\n  </div>\n</div>\n";
},"useData":true});