// @flow
import uniqueId from 'lodash/uniqueId';

export default class Tree {
    id: string;

    value: Object;

    parent: ?Object;

    children: Tree[];

    constructor(value: any) {
        this.id = uniqueId('node_');
        this.value = value;
        this.parent = null;
        this.children = [];
    }

    /**
     * Adds a child node to the current node
     *
     * @method addChild
     * @constraints
     *      - All children must be unique
     *      - Node can only have one parent
     * @param node
     */
    addChild(_node: Tree) {
        if (!(_node instanceof Tree)) {
            throw new TypeError('argument is not a TreeNode');
        }

        const node = _node;
        const foundNode = this.children.find((child) => child.id === node.id);

        if (!foundNode) {
            this.children.push(node);
            if (node.parent) {
                node.parent.removeChild(node);
            }

            node.parent = this;
        }
    }

    /**
     * @method removeChild
     * @param node
     */
    removeChild(_node: Tree) {
        if (!(_node instanceof Tree)) {
            throw new TypeError('argument is not a TreeNode');
        }

        const node = _node;
        const i = this.children.findIndex((child) => child.id === node.id);

        if (i >= 0) {
            this.children.splice(i, 1);
            node.parent = null;
        }
    }
}
