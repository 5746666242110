// @flow
import React from 'react';
import { Field, FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Button, Icon, IconButton } from '@material-ui/core';
import {
    Add as AddIcon,
    Clear as ClearIcon,
} from '@material-ui/icons';
import FormikInput from '../../../../../../components/FormikInput';
import { VALUE_TYPE } from '../../../../../../../browser/shared/constant/ProjectConstant';
import MultiSelectSwitch from './MultiSelectSwitch';
import Alert from './Alert';

type Props = {
    className?: string,
    id: number,
    index: number,
    propositions: Object[],
    readOnly?: boolean,
    valueType: string,
};

const styles = (theme: Object) => ({
    choice: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'nowrap',
        margin: theme.spacing(2),
    },
    choiceValue: {
        flex: 1,
    },
    choiceActions: {
        flexBasis: '17%',
        height: 46,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    icon: {
        width: 48,
        textAlign: 'center',
        color: 'rgba(0, 0, 0, 0.26)',
    },
    actions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
});

const useStyles = makeStyles(styles, { name: 'MultipleChoice' });

export default function MultipleChoice(props: Props) {
    const { className, id, index, propositions, readOnly, valueType } = props;

    const classes = useStyles(props);
    const { t } = useTranslation();

    const renderChoices = (arrayHelpers: Object) => {
        const { push, remove } = arrayHelpers;
        const icon = valueType === VALUE_TYPE.MULTIPLE_CHOICE
            ? 'radio_button_unchecked'
            : 'check_box_outline_blank';

        return (
            <ul>
                {propositions.map((proposition: Object, i: number) => (
                    <li key={i} className={classes.choice}>
                        <Icon className={classes.icon}>{icon}</Icon>
                        <FormikInput
                          className={classes.choiceValue}
                          name={`questionList[${index}].propositions[${i}].choice`}
                          readOnly={readOnly}
                        />
                        <div className={classes.choiceActions}>
                            <Field name={`questionList[${index}].propositions[${i}].alert`}>
                                {({ field, form }) => {
                                    const handleChange = (value: boolean) => {
                                        form.setFieldValue(field.name, value);
                                    };
                                    return (
                                        <Alert
                                          {...field}
                                          onChange={handleChange}
                                          readOnly={readOnly}
                                          tooltip={t('projectBuilder.data.markAsException')}
                                        />
                                    );
                                }}
                            </Field>
                            {!readOnly && propositions.length > 2
                                ? (
                                    <IconButton onClick={() => remove(i)}>
                                        <ClearIcon />
                                    </IconButton>
                                )
                                : null}
                        </div>
                    </li>
                ))}
                {!readOnly
                    ? (
                        <li className={classes.actions}>
                            <Button
                              color="primary"
                              onClick={() => push({ choice: '', alert: false })}
                            >
                                <AddIcon />{t('projectBuilder.data.addOption')}
                            </Button>
                            <Field name={`questionList[${index}].valueType`}>
                                {({ field, form }) => {
                                    const handleChange = (value: string) => {
                                        form.setFieldValue(field.name, value);
                                    };
                                    return (
                                        <MultiSelectSwitch
                                          {...field}
                                          disabled={id > 0}
                                          label={t('projectBuilder.data.checkboxes')}
                                          onChange={handleChange}
                                        />
                                    );
                                }}
                            </Field>

                        </li>
                    )
                    : null}
            </ul>
        );
    };

    return (
        <div className={className}>
            <FieldArray
              name={`questionList[${index}].propositions`}
              render={renderChoices}
            />
        </div>
    );
}
