// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback } from 'react';
import qs from 'qs';
import stringify from 'json-stable-stringify';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import {
    Checkbox,
    Chip,
    Drawer,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    IconButton,
    ListItemText,
    Toolbar,
    Tooltip,
} from '@material-ui/core';
import {
    Close as CloseIcon,
    ClearAll as ClearAllIcon,
    Search as SearchIcon,
} from '@material-ui/icons';
import type { Dispatch } from 'redux';
import type { Connector } from 'react-redux';
import type { ContextRouter } from 'react-router';
import highlightMatch from '../../../../util/highlightMatch';
import { USER_ROLES } from '../../../../../browser/shared/constant/UserRoles';
import * as googleMaps from '../../../../ducks/googleMaps';
import { search as searchOrganizations } from '../../../../redux/entities/organizations';
import Select from '../../../../components/Select';
import { actions, selectors } from './duck';
import styles from './styles';
import type { State as RootState } from '../../../../redux/initialState';

type OwnProps = ContextRouter & {};
type StateProps = {
    geoFilterValue: Object[],
    organizationFilterValue: Object[],
    open: boolean,
    search: {
        geo?: Object[],
        organization?: string[],
        q?: string,
        status?: string[],
    },
    statusFilterValue: Object[],
    statusFilterOptions: Object[],
    user: ?Object,
};
type DispatchProps = {
    getPlaceDetails: (params: Object) => Promise<Object>,
    getPlacePredictions: (params: Object) => Promise<Object[]>,
    searchOrganizations: (params: Object) => Promise<Object>,
    toggleFilterDrawer: (open: boolean) => void,
};
type Props = OwnProps & StateProps & DispatchProps;

// @todo Move to a utils folder
const getOptionValue = (option: Object): string => {
    let { value } = option;
    if (typeof value === 'object') {
        value = value.hasOwnProperty('id') ? value.id : value;
        value = value.hasOwnProperty('place_id') ? value.place_id : value;
    }
    return typeof value === 'string' ? value : stringify(value);
};

// @todo Move to a utils folder
const isOptionSelected = (option: Object, value: Object[]): boolean => {
    if (value.indexOf(option) > -1) return true;
    const candidate = getOptionValue(option);
    return value.some((i: Object) => getOptionValue(i) === candidate);
};

const formatOptionLabel = (option: Object, meta: Object) => {
    const { value } = option;
    const primary = highlightMatch(value.structured_formatting.main_text, meta.inputValue);
    const secondary = highlightMatch(value.structured_formatting.secondary_text || '', meta.inputValue);

    return (<ListItemText primary={primary} secondary={secondary} />);
};

const noFilters = {
    geo: undefined,
    organization: undefined,
    status: undefined,
};

const useStyles = makeStyles(styles, { name: 'FilterDrawer' });

export function FilterDrawer(props: Props) {
    const {
        geoFilterValue,
        getPlaceDetails,
        getPlacePredictions,
        history,
        location,
        organizationFilterValue,
        open,
        search,
        searchOrganizations: _searchOrganizations,
        statusFilterOptions,
        statusFilterValue,
        toggleFilterDrawer,
        user,
    } = props;

    const classes = useStyles(props);
    const { t } = useTranslation();

    const loadOrganizationOptions = useCallback((inputValue: string) => {
        const params = {
            q: inputValue ? `*${inputValue}*` : null,
            from: 0,
            size: 20,
            sort_field: 'organization_name',
            sort_order: 'asc',
        };

        return _searchOrganizations(params)
            .then((resp) => {
                const { entities, result } = resp;
                // @todo: Consider using denormalize to get denormalized entity
                return result.map((id: number) => ({
                    label: entities.organizations[id].organization_name,
                    value: entities.organizations[id],
                }));
            });
    }, [_searchOrganizations]);

    const loadGeoFilterOptions = useCallback((inputValue: string): Promise<Object[]> => (
        getPlacePredictions({ input: inputValue, types: ['(regions)'] })
            .then((predictions: Object[]): Object[] => (
                predictions
                    .filter((prediction: Object): Object => (
                        !prediction.types.some((type: string) => (
                            ['country', 'administrative_area_level_2', 'sublocality'].includes(type)
                        ))
                    ))
                    .map((prediction: Object): Object => {
                        const filterKey = prediction.types.reduce((key, type) => {
                            if (type === 'postal_code') return 'zipcode';
                            if (type === 'locality') return 'city';
                            if (type === 'administrative_area_level_1') return 'state';
                            return key;
                        }, '');

                        return {
                            label: prediction.structured_formatting.main_text,
                            value: {
                                ...prediction,
                                filter_key: filterKey,
                            },
                        };
                    })
            ))
    ), [getPlacePredictions]);

    const updateFilters = useCallback((filters: Object) => {
        history.replace({
            pathname: location.pathname,
            search: qs.stringify(
                { ...search, ...filters },
                { addQueryPrefix: true, encodeValuesOnly: true },
            ),
        });
    }, [history, location.pathname, search]);

    const handleClear = useCallback(() => {
        updateFilters(noFilters);
    }, [updateFilters]);

    const handleOrganizationFilterChange = useCallback((value: Object[]) => {
        const organizationFilters = value.map((option: Object) => option.value.id);
        updateFilters({ organization: organizationFilters });
    }, [updateFilters]);

    const handleCheckboxChange = useCallback((event: SyntheticInputEvent<HTMLInputElement>) => {
        const { checked, name, value } = event.target;
        let filterValue;

        switch (name) {
            case 'status':
                filterValue = statusFilterValue;
                break;
            default:
                return;
        }

        const index = filterValue.findIndex((option: Object) => (
            getOptionValue(option) === value
        ));

        if (checked && index === -1) {
            const filter = [
                ...filterValue.map((option: Object) => getOptionValue(option)),
                value,
            ];
            updateFilters({ [name]: filter });
        } else if (!checked && index >= 0) {
            const filter = filterValue
                .reduce((acc: string[], option: Object): string[] => {
                    const optionValue = getOptionValue(option);
                    if (optionValue !== value) return [...acc, optionValue];
                    return acc;
                }, []);
            updateFilters({ [name]: filter });
        }
    }, [statusFilterValue, updateFilters]);

    const handleGeoFilterChange = useCallback((value: Object[], meta: Object) => {
        const { action, option } = meta;

        Promise.resolve()
            .then(() => {
                if (action === 'select-option') {
                    const params = { placeId: option.value.place_id };
                    return getPlaceDetails(params);
                }
            })
            .then((selectedPlace: ?Object) => {
                const geoFilters = geoFilterValue.map((opt: Object) => {
                    const { filter_key: type, formatted_address: address } = opt.value;
                    return { [type]: address };
                });

                if (selectedPlace) {
                    const type = option.value.filter_key;
                    geoFilters.push({ [type]: selectedPlace.formatted_address });
                }

                updateFilters({ geo: geoFilters });
            });
    }, [getPlaceDetails, geoFilterValue, updateFilters]);

    const handleChipDelete = useCallback((event: SyntheticEvent<any>) => {
        const { name, value } = event.currentTarget.parentElement.dataset;
        let filterValue;

        switch (name) {
            case 'geo':
                filterValue = geoFilterValue;
                break;
            case 'organization':
                filterValue = organizationFilterValue;
                break;
            default:
                return;
        }

        const index = filterValue.findIndex((option: Object) => (
            getOptionValue(option) === value
        ));

        if (index >= 0) {
            const filter = search[name].filter((el, i) => i !== index);
            updateFilters({ [name]: filter });
        }
    }, [geoFilterValue, organizationFilterValue, search, updateFilters]);

    const handleDrawerClose = useCallback(() => {
        toggleFilterDrawer(false);
    }, [toggleFilterDrawer]);

    return (
        <Drawer
          anchor="right"
          classes={{ paper: classes.paper }}
          onClose={handleDrawerClose}
          open={open}
        >
            <Toolbar>
                <div className={classes.title}>
                    {t('customerList.filters.filterUsers')}
                </div>
                <div className={classes.actions}>
                    <Tooltip title={t('customerList.filters.clearFilters')}>
                        <IconButton onClick={handleClear}>
                            <ClearAllIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('customerList.filters.close')}>
                        <IconButton onClick={handleDrawerClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </div>
            </Toolbar>

            <div className={classes.filters}>
                {user && user.role === USER_ROLES.PLATFORM_ADMIN
                    ? (
                        <FormControl className={classes.organizationFilter}>
                            <FormLabel>{t('customerList.filters.organization')}</FormLabel>
                            <Select
                              ControlProps={{
                                  fullWidth: true,
                                  margin: 'dense',
                                  variant: 'outlined',
                              }}
                              controlShouldRenderValue={false}
                              defaultOptions
                              dropdownIcon={<SearchIcon />}
                              isMulti
                              loadOptions={loadOrganizationOptions}
                              menuPlacement="auto"
                              onChange={handleOrganizationFilterChange}
                              placeholder="Search organizations..."
                              showSelectedOptions={false}
                              value={organizationFilterValue}
                            />
                            <div className={classes.chipArray}>
                                {organizationFilterValue.map((option: Object) => (
                                    <Chip
                                      classes={{
                                          root: classes.chip,
                                          label: classes.chipLabel,
                                      }}
                                      color="primary"
                                      data-name="organization"
                                      data-value={getOptionValue(option)}
                                      key={getOptionValue(option)}
                                      label={option.label}
                                      onDelete={handleChipDelete}
                                    />
                                ))}
                            </div>
                        </FormControl>
                    )
                    : null}
                <FormControl className={classes.statusFilter}>
                    <FormLabel>{t('customerList.filters.status')}</FormLabel>
                    <FormGroup className={classes.filterOptions}>
                        {statusFilterOptions.map((option) => (
                            <FormControlLabel
                              className={classes.checkboxContainer}
                              control={(
                                  <Checkbox
                                    checked={isOptionSelected(option, statusFilterValue)}
                                    className={classes.checkbox}
                                    name="status"
                                    onChange={handleCheckboxChange}
                                    value={getOptionValue(option)}
                                  />
                              )}
                              key={getOptionValue(option)}
                              label={option.label}
                            />
                        ))}
                    </FormGroup>
                </FormControl>
                <FormControl className={classes.geoFilter}>
                    <FormLabel>{t('customerList.filters.location')}</FormLabel>
                    <Select
                      ControlProps={{
                          fullWidth: true,
                          margin: 'dense',
                          variant: 'outlined',
                      }}
                      controlShouldRenderValue={false}
                      dropdownIcon={<SearchIcon />}
                      formatOptionLabel={formatOptionLabel}
                      isMulti
                      loadOptions={loadGeoFilterOptions}
                      menuPlacement="auto"
                      onChange={handleGeoFilterChange}
                      placeholder={t('customerList.filters.locationPlaceholder')}
                      showSelectedOptions={false}
                      value={geoFilterValue}
                    />
                    <div className={classes.chipArray}>
                        {geoFilterValue.map((option: Object) => (
                            <Chip
                              classes={{
                                  root: classes.chip,
                                  label: classes.chipLabel,
                              }}
                              color="primary"
                              data-name="geo"
                              data-value={getOptionValue(option)}
                              key={getOptionValue(option)}
                              label={option.label}
                              onDelete={handleChipDelete}
                            />
                        ))}
                    </div>
                </FormControl>
            </div>
        </Drawer>
    );
}

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => ({
    geoFilterValue: selectors.getGeoFilterValue(state, props),
    open: state.customerList.drawerOpen,
    organizationFilterValue: selectors.getOrganizationFilterValue(state, props),
    search: selectors.parseSearchParams(state, props),
    statusFilterOptions: selectors.getStatusFilterOptions(),
    statusFilterValue: selectors.getStatusFilterValue(state, props),
    user: state.session.user,
});

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    getPlaceDetails: (params: Object) => dispatch(googleMaps.actions.getPlaceDetails(params)),
    getPlacePredictions: (params: Object) => dispatch(googleMaps.actions.getPlacePredictions(params)),
    searchOrganizations: (params: Object) => dispatch(searchOrganizations(params)),
    toggleFilterDrawer: (open: boolean) => dispatch(actions.toggleFilterDrawer(open)),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
export default connector(FilterDrawer);
