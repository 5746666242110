// @flow
import { createAction } from 'redux-actions';
import axios from 'axios';
import type { Dispatch } from 'redux';
import type { $AxiosXHR, $AxiosError } from 'axios';
import types from './types';

export const updateProfileSuccess = createAction(types.UPDATE_PROFILE_SUCCESS);
export const updateProfileError = createAction(types.UPDATE_PROFILE_ERROR);
export const updateProfile = createAction(
    types.UPDATE_PROFILE,
    (params: Object): Function => (
        (dispatch: Dispatch<*>): Promise<Object> => (
            axios.put('/user/profile', params)
                .then((resp: $AxiosXHR<*>) => {
                    const session = resp.data;
                    dispatch(updateProfileSuccess(session));
                    return Promise.resolve(session);
                })
                .catch((err: $AxiosError<*>) => {
                    dispatch(updateProfileError());
                    return Promise.reject(err);
                })
        )
    )
);

export const updateUserSuccess = createAction(types.UPDATE_USER_SUCCESS);
export const updateUserError = createAction(types.UPDATE_USER_ERROR);
export const updateUser = createAction(
    types.UPDATE_USER,
    (params: Object): Function => (
        (dispatch: Dispatch<*>): Promise<Object> => (
            axios.put('/user', params)
                .then((resp: $AxiosXHR<*>) => {
                    const session = resp.data;
                    dispatch(updateUserSuccess(session));
                    return Promise.resolve(session);
                })
                .catch((err: $AxiosError<*>) => {
                    dispatch(updateUserError());
                    return Promise.reject(err);
                })
        )
    )
);

export default {
    updateProfile,
    updateProfileError,
    updateProfileSuccess,
    updateUser,
    updateUserError,
    updateUserSuccess,
};
