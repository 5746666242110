// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { matchPath } from 'react-router';
import cx from 'classnames';
import isEqual from 'react-fast-compare';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import type { Dispatch } from 'redux';
import type { $AxiosError } from 'axios';
import type { ContextRouter } from 'react-router';
import type { Connector } from 'react-redux';
import type { Organization } from 'gigwalk/lib/api/organizations/types';
import type { Subscription } from 'gigwalk/lib/api/subscriptions/types';
import entitySelector from '../../redux/entities/entitySelector';
import { getBalance } from '../../redux/entities/organizations';
import * as session from '../../ducks/session';
import * as snackbar from '../../ducks/snackbar';
import { format } from '../../../browser/shared/util/gigwalkApiErrorUtil';
import LoadingPage from '../../components/LoadingPage';
import WizardForm from './components/WizardForm';
import BasicInfo from './containers/BasicInfo';
import Locations from './containers/Locations';
import Workforce from './containers/Workforce';
import WorkAllocation from './containers/WorkAllocation';
import Certifications from './containers/Certifications';
import Approval from './containers/Approval';
import Groups from './containers/Groups';
import Questions from './containers/Questions';
import Launch from './containers/Launch';
import getValidationSchema from './utils/getValidationSchema';
import filterSchema from './utils/filterSchema';
import { actions, selectors } from './duck';
import styles from './styles';
import type { State as RootState } from '../../redux/initialState';

type OwnProps = ContextRouter & {};

type StateProps = {
    canLaunchPublicProject: boolean,
    editMode: boolean,
    initialValues: Object,
    isLocationListUploadVisible: boolean,
    organization: ?Organization,
    subscription: ?Subscription,
};

type DispatchProps = {
    createProject: (orgId: number, values: Object) => Promise<number>,
    enqueueSnackbar: (message: string, options: Object) => void,
    getCurrentBalance: (params: { organization_id: number }) => Promise<Object>,
    launchProject: (subscriptionId: number) => Promise<void>,
    loadProject: (subscriptionId: number, orgId: number) => Promise<void>,
    requestReview: (subscriptionId: number, orgId: number) => Promise<void>,
    resetState: () => void,
    saveProject: (subscriptionId: number, values: Object) => Promise<void>,
};

type Props = OwnProps & StateProps & DispatchProps;

const ProjectBuilderSchema = getValidationSchema();
const stepConfig = [
    {
        component: BasicInfo,
        name: 'info',
        validationSchema: filterSchema(
            ProjectBuilderSchema,
            ['asapEndDate', 'description', 'rangeEndDate', 'rangeStartDate', 'scheduleType', 'title']
        ),
    },
    {
        component: Locations,
        name: 'locations',
        validationSchema: filterSchema(
            ProjectBuilderSchema,
            ['locationCount', 'locationListId']
        ),
    },
    {
        component: Workforce,
        name: 'people',
        skip: false,
        // Skip validations for fields that depend on needsPublicWorkforce, but cannot be updated
        // by the user on this step.
        validationSchema: filterSchema(
            ProjectBuilderSchema,
            ['needsPublicWorkforce'],
            [
                'groups',
                'certifications',
                'optinType',
                'autoassign',
                'needsApproval',
                'twoWayRatingEnabled',
                'gigPrice',
                'nearTicketDistance',
                'resWindowHours',
                'fifoAssign',
                'allowGalleryUpload',
            ]
        ),
    },
    {
        component: WorkAllocation,
        name: 'people',
        // Skip validations for fields that depend on optinType, but cannot be updated
        // by the user on this step.
        validationSchema: filterSchema(
            ProjectBuilderSchema,
            ['optinType', 'autoassign'],
            ['fifoAssign', 'resWindowHours']
        ),
    },
    {
        component: Approval,
        name: 'people',
        skip: (values: Object): boolean => values.needsPublicWorkforce,
        validationSchema: filterSchema(
            ProjectBuilderSchema,
            ['needsApproval', 'twoWayRatingEnabled']
        ),
    },
    {
        component: Groups,
        name: 'people',
        skip: (values: Object): boolean => values.needsPublicWorkforce,
        validationSchema: filterSchema(
            ProjectBuilderSchema,
            ['groups'],
            ['needsPublicWorkforce']
        ),
    },
    {
        component: Certifications,
        name: 'people',
        skip: (values: Object, context: ?Object): boolean => {
            const { needsPublicWorkforce } = values;
            const subscription = context ? context.subscription : null;

            // @todo Check to see if this is the correct logic.
            // Can you change certifications for an ACTIVE project? Do we need to support needs_core still?
            return needsPublicWorkforce && subscription
                ? subscription.state === 'ACTIVE' && subscription.needs_core
                : false;
        },
        validationSchema: filterSchema(
            ProjectBuilderSchema,
            ['certifications'],
            ['needsPublicWorkforce']
        ),
    },
    {
        component: Questions,
        name: 'data',
        validationSchema: filterSchema(
            ProjectBuilderSchema,
            ['questionList']
        ),
    },
    {
        component: Launch,
        name: 'launch',
        validationSchema: ProjectBuilderSchema,
    },
];

const useStyles = makeStyles(styles, { name: 'ProjectBuilder' });

export function ProjectBuilder(props: Props) {
    const {
        canLaunchPublicProject,
        createProject,
        editMode,
        enqueueSnackbar,
        getCurrentBalance,
        history,
        initialValues,
        launchProject,
        loadProject,
        location,
        match,
        organization,
        requestReview,
        resetState,
        saveProject,
        subscription,
    } = props;

    const orgId = parseInt(match.params.orgId, 10);
    const subscriptionId = parseInt(match.params.subscriptionId, 10);
    const context = { subscription, organization };

    const classes = useStyles(props);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);

    const submitActionRef = useRef('saveAndContinue');
    const validationSchemaRef = useRef(null);
    const projectIdRef = useRef(null);
    projectIdRef.current = subscriptionId;

    const currentStep = useMemo(() => {
        const fullMatch = matchPath(location.pathname, { path: `${match.path}/:step?/:page?` });
        if (fullMatch) {
            const { page, step } = fullMatch.params;
            return `${step || ''}/${page || ''}`;
        }
        return '';
    }, [location.pathname, match.path]);

    useEffect(() => {
        const promises = [getCurrentBalance({ organization_id: orgId })];
        if (subscriptionId > 0) {
            promises.push(loadProject(subscriptionId, orgId));
        }

        setLoading(true);
        Promise.all(promises)
            .then(() => {
                setLoading(false);
            });
    }, [getCurrentBalance, loadProject, orgId, setLoading, subscriptionId]);

    useEffect(() => (
        // This will reset projectBuilder state when component unmounts
        () => resetState()
    ), [resetState]);

    if (loading) {
        return (
            <div className={cx(classes.root)}>
                <LoadingPage />
            </div>
        );
    }

    return (
        <Formik
          initialStatus={{}}
          initialValues={initialValues}
          onSubmit={(values, { resetForm, setStatus }) => {
              // Create a promise that we can use to chain API calls and other actions
              let promise = Promise.resolve();
              let projectId = projectIdRef.current;
              let updatedValues;

              const validationSchema = validationSchemaRef.current;
              const validatedValues = Object.entries(validationSchema.cast(values))
                  .reduce((acc: Object, [key, value]: [string, mixed]) => (
                      key in validationSchema.fields ? { ...acc, [key]: value } : acc
                  ), {});

              if (!projectId) {
                  promise = promise
                      .then(() => createProject(orgId, validatedValues))
                      .then((id: number) => {
                          projectId = id;
                          history.replace(location.pathname.replace('new', `${projectId}`));
                      });
              }

              promise = promise
                  .then(() => saveProject(projectId, validatedValues))
                  .then((v: Object) => {
                      updatedValues = v;
                  });

              switch (submitActionRef.current) {
                  case 'launch':
                      // Only launch the project if it's in the DRAFT state. Changes to ACTIVE projects
                      // propagate to the ticket level immediately
                      if (!editMode) {
                          promise = promise.then(() => launchProject(projectId));
                      }
                      break;

                  case 'requestReview':
                      promise = promise.then(() => requestReview(projectId, orgId));
                      break;

                  default:
                      break;
              }

              return promise
                  .then(() => {
                      // Set projectIdRef.current in case we are creating a new project. This will allow us
                      // to navigate to the correct url further down in the promise chain
                      projectIdRef.current = projectId;

                      const changedValues = {};
                      Object.entries(updatedValues)
                          .forEach(([key, value]) => {
                              if (!isEqual(value, values[key])) {
                                  changedValues[key] = value;
                              }
                          });

                      setStatus({ success: true });
                      resetForm({
                          values: {
                              ...values,
                              ...changedValues,
                          },
                      });
                  })
                  .catch((error: $AxiosError<any>) => {
                      const resp = error ? error.response : null;
                      if (resp && resp.data && resp.data.gw_api_response) {
                          const message = format(resp.data.gw_api_response);
                          enqueueSnackbar(message, { variant: 'error' });
                      }
                      setStatus({ error });
                      return Promise.reject(error);
                  });
          }}
          validate={(values) => {
              const emptyErrors = {};
              const validationSchema = validationSchemaRef.current;

              return validateYupSchema(values, validationSchema, false, context)
                  .then(() => emptyErrors)
                  .catch((err: any) => (
                      err.name === 'ValidationError'
                          ? yupToFormErrors(err)
                          : Promise.reject(err)
                  ));
          }}
        >
            {(formikProps: Object) => {
                const { submitForm, values } = formikProps;
                const { needsPublicWorkforce } = values;

                const handleNextClick = (event: SyntheticEvent<any>, nextStep: ?string) => {
                    if (editMode && nextStep) {
                        history.push(`${match.url}/${nextStep}`);
                    } else {
                        let submitAction = 'saveAndContinue';
                        if (!nextStep) {
                            submitAction = needsPublicWorkforce && !canLaunchPublicProject
                                ? 'requestReview'
                                : 'launch';
                        }

                        // Set submitActionRef.current before calling submitForm so that the submit handler
                        // knows what to do.
                        submitActionRef.current = submitAction;

                        submitForm()
                            .then(() => {
                                const projectId = projectIdRef.current;

                                switch (submitAction) {
                                    case 'launch':
                                        history.push(`/projects/${orgId}/active/${projectId}`);
                                        break;

                                    case 'requestReview': {
                                        const message = t('projectBuilder.launch.reviewConfirmation');
                                        enqueueSnackbar(message, { variant: 'success' });
                                        history.push(`/projects/${orgId}/active/list`);
                                        break;
                                    }

                                    case 'saveAndContinue':
                                        if (nextStep) {
                                            history.push(`/projects/${orgId}/create/${projectId}/${nextStep}`);
                                        }
                                        break;

                                    default:
                                        break;
                                }
                            })
                            .finally(() => {
                                submitActionRef.current = null;
                            });
                    }
                };

                const handlePrevClick = (event, prevStep: ?string) => {
                    if (prevStep) {
                        history.push(`/projects/${orgId}/create/${subscriptionId}/${prevStep}`);
                    }
                };

                const handleStepChange = (step: string, reason: string) => {
                    const url = `${match.url}/${step}`;
                    if (reason === 'redirect') {
                        history.replace(url);
                    } else {
                        history.push(url);
                    }
                };

                return (
                    <div className={cx(classes.root)}>
                        <WizardForm
                          {...formikProps}
                          config={stepConfig}
                          context={context}
                          currentStep={currentStep}
                          onNextClick={handleNextClick}
                          onPrevClick={handlePrevClick}
                          onStepChange={handleStepChange}
                          validationSchemaRef={validationSchemaRef}
                        />
                    </div>
                );
            }}
        </Formik>
    );
}

const organizationSelector = entitySelector('organizations');
const subscriptionSelector = entitySelector('subscriptions');

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => {
    const { match } = props;
    const organization = organizationSelector(state, match.params.orgId);
    const subscription = subscriptionSelector(state, match.params.subscriptionId);

    return {
        canLaunchPublicProject: session.selectors.canLaunchPublicProject(state),
        editMode: subscription ? subscription.state === 'ACTIVE' : false,
        // $FlowIssue weird error message - could be a bug in flow or reselect typedef
        initialValues: selectors.getInitialValues(state, props),
        isLocationListUploadVisible: state.projectBuilder.locations.isLocationListUploadVisible,
        organization,
        subscription,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    createProject: (orgId: number, values: Object) => dispatch(actions.createProject(orgId, values)),
    enqueueSnackbar: (message: string, options: Object) => dispatch(snackbar.actions.enqueue(message, options)),
    getCurrentBalance: (params: { organization_id: number }) => dispatch(getBalance(params)),
    launchProject: (subscriptionId: number) => dispatch(actions.launchProject(subscriptionId)),
    loadProject: (subscriptionId: number, orgId: number) => dispatch(actions.loadProject(subscriptionId, orgId)),
    requestReview: (subscriptionId: number, orgId: number) => dispatch(actions.requestReview(subscriptionId, orgId)),
    resetState: () => dispatch(actions.resetState()),
    saveProject: (subscriptionId: number, values: Object) => dispatch(actions.saveProject(subscriptionId, values)),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
export default connector(ProjectBuilder);
