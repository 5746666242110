// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useMemo, useState } from 'react';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
// $FlowTypedIssue update react-redux libdef
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { CircularProgress, Fade } from '@material-ui/core';
import {
    Check as CheckIcon,
    Clear as ClearIcon,
    MonetizationOn as MonetizationOnIcon,
} from '@material-ui/icons';
import type { ContextRouter } from 'react-router';
import {
    getAvailableActions,
    getPermittedActions,
    getTicket,
    updateTicket,
} from '../../../../ducks/ticketDetail';
import FormikMaskedInput from '../../../../components/FormikMaskedInput';
import styles from './styles';
import type { State as RootState } from '../../../../store';

type Props = ContextRouter & {
    className?: string,
};

const useStyles = makeStyles(styles, { name: 'Payout' });

const PayoutSchema = yup.object().shape({
    price: yup.number().required(''),
});

export default function Payout(props: Props) {
    const { match } = props;
    const ticketId = parseInt(match.params.ticketId, 10);

    const dispatch = useDispatch();
    const classes = useStyles(props);
    const { t } = useTranslation();

    const [statusTimer, setStatusTimer] = useState(null);

    const ticket = useSelector((state: RootState) => getTicket(state));
    const permittedActions = useSelector((state: RootState) => getPermittedActions(state));
    const availableActions = useSelector((state: RootState) => getAvailableActions(state));

    const disabled = useMemo(() => {
        const fields = [];
        if (!permittedActions.includes('editPrice') || !availableActions.includes('editPrice')) {
            fields.push('price');
        }
        return fields;
    }, [availableActions, permittedActions]);

    const initialValues = useMemo(() => {
        if (!ticket) {
            return { price: undefined };
        }

        return {
            price: ticket.price,
        };
    }, [ticket]);

    // Hide ticket price if user is not permitted to edit
    if (!permittedActions.includes('editPrice')) {
        return null;
    }

    return (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={(values, { setStatus }) => (
              dispatch(updateTicket(ticketId, values))
                  .then(() => {
                      clearTimeout(statusTimer);
                      setStatusTimer(setTimeout(() => setStatusTimer(null), 1500));
                      setStatus({ success: true });
                  })
                  .catch((error: string) => {
                      clearTimeout(statusTimer);
                      setStatusTimer(setTimeout(() => setStatusTimer(null), 1500));
                      setStatus({ error });
                      return Promise.reject(error);
                  })
          )}
          validationSchema={PayoutSchema}
        >
            {(formikProps) => {
                const { dirty, isSubmitting, isValid, status, submitForm } = formikProps;

                const handleBlur = () => {
                    if (dirty && isValid) {
                        submitForm();
                    }
                };

                return (
                    <Form className={classes.container}>
                        <MonetizationOnIcon className={classes.icon} />
                        <span className={classes.label}>{t('ticketDetail.summary.payout')}</span>
                        <FormikMaskedInput
                          classes={{
                              input: classes.input,
                              disabled: classes.disabled,
                          }}
                          disabled={disabled.includes('price')}
                          margin="dense"
                          name="price"
                          onBlur={handleBlur}
                          options={{
                              numeral: true,
                              numeralThousandsGroupStyle: 'thousand',
                              numeralPositiveOnly: true,
                              prefix: '$',
                              noImmediatePrefix: true,
                              rawValueTrimPrefix: true,
                          }}
                          variant="outlined"
                        />
                        {isSubmitting
                            ? <CircularProgress size={18} thickness={4} />
                            : (
                                <Fade in={!!statusTimer} timeout={{ exit: 250 }}>
                                    {status && status.success
                                        ? <CheckIcon className={classes.successIcon} />
                                        : <ClearIcon className={classes.errorIcon} />}
                                </Fade>
                            )}
                    </Form>
                );
            }}
        </Formik>
    );
}
