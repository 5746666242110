// @flow
// $FlowIssue need to update to a more recent flow version
import React, { forwardRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Chip, fade } from '@material-ui/core';
import { Clear as ClearIcon } from '@material-ui/icons';
import type { Node } from 'react';
import colorHash from '../../utils/colorHash';
import styles from './styles';

type Props = {
    id?: number | string,
    label: Node,
    color?: string,
};

const useStyles = makeStyles(styles, { name: 'Tag' });

export const Tag = forwardRef((props: Props, ref: Object) => {
    const { color, id, label, ...other } = props;
    const theme = useTheme();
    const classes = useStyles(props);

    const background = !color ? colorHash([label, id].join('')) : color;
    const contrastText = theme.palette.getContrastText(background);

    return (
        <Chip
          {...other}
          classes={classes}
          deleteIcon={<ClearIcon style={{ color: fade(contrastText, 0.7) }} />}
          ref={ref}
          style={{
              backgroundColor: background,
              color: contrastText,
          }}
          label={label}
        />
    );
});

export default Tag;
