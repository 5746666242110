// @flow
import { createAction } from 'redux-actions';
import formatAPIError from '../../api/formatAPIError';
import types from './types';
import type { GWError } from '../../api/formatAPIError';

export const enqueue = createAction(
    types.ENQUEUE_SNACKBAR,
    (message: string | GWError[], options: Object) => ({
        key: `${new Date().getTime() + Math.random()}`,
        message: typeof message === 'string' ? message : formatAPIError(message),
        options,
    })
);

export const remove = createAction(types.REMOVE_SNACKBAR);

export default {
    enqueue,
    remove,
};
