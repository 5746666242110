// @flow
import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';
import account, { init as accountInitState } from '../containers/Account/duck/reducer';
import activity, { init as activityInitState } from '../containers/Activity/duck/reducer';
import preferences, { init as preferencesInitState } from '../containers/Preferences/duck/reducer';
import type { State as AccountState } from '../containers/Account/duck/reducer';
import type { State as ActivityState } from '../containers/Activity/duck/reducer';
import type { State as PreferencesState } from '../containers/Preferences/duck/reducer';

export type State = {
    account: AccountState,
    activity: ActivityState,
    preferences: PreferencesState,
};

export const init: State = {
    account: accountInitState,
    activity: activityInitState,
    preferences: preferencesInitState,
};

export default reduceReducers(
    combineReducers({
        account,
        activity,
        preferences,
    })
);
