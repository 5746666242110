// @flow
import type { Location } from 'react-router';
import { init as configInit } from '../../client/ducks/config';
import { init as dialogInit } from '../../client/ducks/dialog';
import { init as entitiesInit } from '../../client/ducks/gigwalk';
import { init as gigwalkerListInit } from '../../client/ducks/gigwalkerList';
import { init as googleMapsInit } from '../../client/ducks/googleMaps';
import { init as headerInit } from '../../client/ducks/header';
import { init as profileInit } from '../../client/ducks/profile';
import { init as sessionInit } from '../../client/ducks/session';
import { init as sideNavInit } from '../../client/ducks/sideNav';
import { init as snackbarInit } from '../../client/ducks/snackbar';
import { init as subscriptionListInit } from '../../client/ducks/subscriptionList';
import { init as ticketDetailInit } from '../../client/ducks/ticketDetail';
import { init as ticketListInit } from '../../client/ducks/ticketList';
import { init as legacyAddFundsInit } from '../containers/AddFunds/duck';
import { init as legacyConfigInit } from '../ducks/config';
import { init as legacyCustomerListInit } from '../containers/CustomerList/duck';
import { init as legacyDialogInit } from '../ducks/dialog';
import { init as legacyEntitiesInit } from './entities/initialState';
import { init as legacyGigwalkerListInit } from '../containers/GigwalkerList/duck';
import { init as legacyGoogleMapsInit } from '../ducks/googleMaps';
import { init as legacyLayoutInit } from '../containers/Layout/duck';
import { init as legacyLocationListUploadInit } from '../../browser/shared/components/LocationListUpload/redux';
import { init as legacyPayoutListInit } from '../containers/PayoutList/duck';
import { init as legacyProfilePageInit } from '../containers/ProfilePage/duck';
import { init as legacyProjectBuilderInit } from '../containers/ProjectBuilder/duck';
import { init as legacyProjectManagementInit } from '../../browser/dashboard/view/active/Management/duck';
import { init as legacyProjectSummaryInit } from '../../browser/dashboard/view/active/Summary/duck';
import { init as legacySessionInit } from '../ducks/session';
import { init as legacySignupInit } from '../containers/Signup/duck';
import { init as legacySnackbarInit } from '../ducks/snackbar';
import { init as legacySubscriptionListInit } from '../containers/SubscriptionList/duck';
import { init as legacyTicketListInit } from '../containers/TicketList/duck';
import { init as legacyTransactionListInit } from '../containers/TransactionList/duck';
import { init as legacyUiInit } from '../ducks/ui';
import type { State as ConfigState } from '../../client/ducks/config';
import type { State as DialogState } from '../../client/ducks/dialog';
import type { State as EntitiesState } from '../../client/ducks/gigwalk';
import type { State as GigwalkerListState } from '../../client/ducks/gigwalkerList';
import type { State as GoogleMapsState } from '../../client/ducks/googleMaps';
import type { State as HeaderState } from '../../client/ducks/header';
import type { State as ProfileState } from '../../client/ducks/profile';
import type { State as SessionState } from '../../client/ducks/session';
import type { State as SideNavState } from '../../client/ducks/sideNav';
import type { State as SnackbarState } from '../../client/ducks/snackbar';
import type { State as SubscriptionListState } from '../../client/ducks/subscriptionList';
import type { State as TicketDetailState } from '../../client/ducks/ticketDetail';
import type { State as TicketListState } from '../../client/ducks/ticketList';
import type { State as LegacyAddFundsState } from '../containers/AddFunds/duck';
import type { State as LegacyConfigState } from '../ducks/config';
import type { State as LegacyCustomerListState } from '../containers/CustomerList/duck';
import type { State as LegacyDialogState } from '../ducks/dialog';
import type { State as LegacyEntitiesState } from './entities/initialState';
import type { State as LegacyGigwalkerListState } from '../containers/GigwalkerList/duck';
import type { State as LegacyGoogleMapsState } from '../ducks/googleMaps';
import type { State as LegacyLayoutState } from '../containers/Layout/duck';
import type { State as LegacyLocationListUploadState } from '../../browser/shared/components/LocationListUpload/redux';
import type { State as LegacyPayoutListState } from '../containers/PayoutList/duck';
import type { State as LegacyProfilePageState } from '../containers/ProfilePage/duck';
import type { State as LegacyProjectBuilderState } from '../containers/ProjectBuilder/duck';
import type { State as LegacyProjectManagementState } from '../../browser/dashboard/view/active/Management/duck';
import type { State as LegacyProjectSummaryState } from '../../browser/dashboard/view/active/Summary/duck';
import type { State as LegacyTicketListState } from '../containers/TicketList/duck';
import type { State as LegacyTransactionListState } from '../containers/TransactionList/duck';
import type { State as LegacySessionState } from '../ducks/session';
import type { State as LegacySignupState } from '../containers/Signup/duck';
import type { State as LegacySnackbarState } from '../ducks/snackbar';
import type { State as LegacySubscriptionListState } from '../containers/SubscriptionList/duck';
import type { State as LegacyUiState } from '../ducks/ui';


// Root state for the application
export type State = {
    addFunds: LegacyAddFundsState,
    config: LegacyConfigState & ConfigState,
    customerList: LegacyCustomerListState,
    dialog: LegacyDialogState & DialogState,
    entities: LegacyEntitiesState & EntitiesState,
    gigwalkerList: LegacyGigwalkerListState & GigwalkerListState,
    googleMaps: LegacyGoogleMapsState & GoogleMapsState,
    header: HeaderState,
    layout: LegacyLayoutState,
    locationListUpload: LegacyLocationListUploadState,
    payoutList: LegacyPayoutListState,
    profile: ProfileState,
    profilePage: LegacyProfilePageState,
    projectBuilder: LegacyProjectBuilderState,
    projectSummary: LegacyProjectSummaryState,
    projectManagement: LegacyProjectManagementState,
    router: {
        action: string,
        location: Location,
    },
    session: LegacySessionState & SessionState,
    sideNav: SideNavState,
    signup: LegacySignupState,
    snackbar: LegacySnackbarState & SnackbarState,
    subscriptionList: LegacySubscriptionListState & SubscriptionListState,
    ticketDetail: TicketDetailState,
    ticketList: LegacyTicketListState & TicketListState,
    transactionList: LegacyTransactionListState,
    ui: LegacyUiState,
};

export const init: State = {
    addFunds: legacyAddFundsInit,
    config: { ...legacyConfigInit, ...configInit },
    customerList: legacyCustomerListInit,
    dialog: { ...legacyDialogInit, ...dialogInit },
    entities: { ...legacyEntitiesInit, ...entitiesInit },
    gigwalkerList: { ...legacyGigwalkerListInit, ...gigwalkerListInit },
    googleMaps: { ...legacyGoogleMapsInit, ...googleMapsInit },
    header: headerInit,
    layout: legacyLayoutInit,
    locationListUpload: legacyLocationListUploadInit,
    payoutList: legacyPayoutListInit,
    profile: profileInit,
    profilePage: legacyProfilePageInit,
    projectBuilder: legacyProjectBuilderInit,
    projectSummary: legacyProjectSummaryInit,
    projectManagement: legacyProjectManagementInit,
    router: {
        action: 'POP',
        location: {
            hash: '',
            pathname: '/',
            search: '',
        },
    },
    session: { ...legacySessionInit, ...sessionInit },
    sideNav: sideNavInit,
    signup: legacySignupInit,
    snackbar: { ...legacySnackbarInit, ...snackbarInit },
    subscriptionList: { ...legacySubscriptionListInit, ...subscriptionListInit },
    ticketDetail: ticketDetailInit,
    ticketList: { ...legacyTicketListInit, ...ticketListInit },
    transactionList: legacyTransactionListInit,
    ui: legacyUiInit,
};
