// @flow
import { createAction } from 'redux-actions';
import types from './types';

export const expandQuestion = createAction(types.EXPAND_QUESTION);
export const collapseQuestion = createAction(types.COLLAPSE_QUESTION);

export default {
    expandQuestion,
    collapseQuestion,
};
