// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import type { Dispatch } from 'redux';
import type { ContextRouter } from 'react-router';
import type { Connector } from 'react-redux';
import type { $AxiosError } from 'axios';
import { format } from '../../../../../browser/shared/util/gigwalkApiErrorUtil';
import LoadingPage from '../../../../components/LoadingPage';
import PreferencesForm from './components/PreferencesForm';
import * as snackbar from '../../../../ducks/snackbar';
import { actions, selectors } from './duck';
import type { State as RootState } from '../../../../redux/initialState';

type OwnProps = ContextRouter & {};
type StateProps = {
    initialValues: Object,
    subscriptions: Object[],
    readOnly: boolean,
};
type DispatchProps = {
    enqueueSnackbar: (message: string, option: Object) => void,
    fetchAllSubscriptions: (customerId: number) => Promise<void>,
    submit: (values: Object) => Promise<void>,
    unsubscribe: (subscriptionId: number, customerId: number) => Promise<void>
};
type Props = OwnProps & StateProps & DispatchProps;

const PreferencesSchema = yup.object().shape({
    disabledNotifications: yup.array().of(yup.string()),
    enabledNotifications: yup.array().of(yup.string()),
    idealWeeklyHours: yup.number()
        .required('validation.required'),
    maxWeeklyHours: yup.number()
        .required('validation.required'),
});

export function Preferences(props: Props) {
    const {
        enqueueSnackbar,
        fetchAllSubscriptions,
        initialValues,
        match,
        submit,
        subscriptions,
        readOnly,
        unsubscribe,
    } = props;

    const customerId = parseInt(match.params.customerId, 10);

    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);

    const handleUnsubscribe = useCallback((subscriptionId: number) => (
        unsubscribe(subscriptionId, customerId)
            .then(() => {})
            .catch((err: $AxiosError<any>) => {
                const resp = err ? err.response : null;
                if (resp && resp.data && resp.data.gw_api_response) {
                    const message = format(resp.data.gw_api_response);
                    enqueueSnackbar(message, { variant: 'error' });
                }
            })
    ), [customerId, enqueueSnackbar, unsubscribe]);

    useEffect(() => {
        setLoading(true);
        fetchAllSubscriptions(customerId)
            .then(() => setLoading(false))
            .catch((err: $AxiosError<any>) => {
                const resp = err ? err.response : null;
                if (resp && resp.data && resp.data.gw_api_response) {
                    const message = format(resp.data.gw_api_response);
                    enqueueSnackbar(message, { variant: 'error' });
                }
            });
    }, [customerId, enqueueSnackbar, fetchAllSubscriptions]);

    if (loading) {
        return <LoadingPage />;
    }

    return (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          isInitialValid
          onSubmit={(values, { resetForm, setStatus }) => (
              submit(values)
                  .then(() => {
                      setStatus({ success: true });
                      resetForm();
                      enqueueSnackbar(t('profilePage.preferences.updateSuccessful'), { variant: 'success' });
                  })
                  .catch((error: string) => {
                      setStatus({ error });
                      return Promise.reject(error);
                  })
          )}
          validationSchema={PreferencesSchema}
        >
            {(formikProps: Object) => (
                <PreferencesForm
                  {...formikProps}
                  onUnsubscribe={handleUnsubscribe}
                  readOnly={readOnly}
                  subscriptions={subscriptions}
                />
            )}
        </Formik>
    );
}

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => ({
    initialValues: selectors.getInitialValues(state, props),
    subscriptions: selectors.getSubscriptions(state),
    readOnly: selectors.isReadOnly(state, props),
});
const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    enqueueSnackbar: (message: string, options: Object) => dispatch(snackbar.actions.enqueue(message, options)),
    fetchAllSubscriptions: (customerId: number) => dispatch(actions.fetchAllSubscriptions(customerId)),
    submit: (values: Object) => dispatch(actions.submit(values)),
    unsubscribe: (subscriptionId: number, customerId: number) => dispatch(actions.unsubscribe(subscriptionId, customerId)),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
export default connector(Preferences);
