// @flow
import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import { Trans, withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import { Button, IconButton, Toolbar } from '@material-ui/core';
import { Refresh as RefreshIcon } from '@material-ui/icons';
import { compose } from 'recompose';
import type { Dispatch } from 'redux';
import type { Connector } from 'react-redux';
import type { TFunction } from 'react-i18next';
import type { ContextRouter } from 'react-router';
import ActivateUsersDialog from '../../../ActivateUsersDialog';
import DeactivateUsersDialog from '../../../DeactivateUsersDialog';
import DeleteUsersDialog from '../../../DeleteUsersDialog';
import SuspendUsersDialog from '../../../SuspendUsersDialog';
// import ManageUsersDialog from '../ManageUsersDialog';
// import GroupMembershipDialog from '../GroupMembershipDialog';
// import AssignCertificationsDialog from '../AssignCertificationsDialog';
import * as dialog from '../../../../ducks/dialog';
import * as snackbar from '../../../../ducks/snackbar';
import { actions, selectors } from './duck';
import styles from './styles';
import type { State as RootState } from '../../../../redux/initialState';

type OwnProps = ContextRouter & {
    classes: Object,
    t: TFunction,
};
type StateProps = {
    actions: string[],
    recordCount: number,
    search: {
        size?: string,
        page?: string,
        sort?: string,
        order?: string,
    },
    selectedCustomerIds: number[],
    user: ?Object,
};
type DispatchProps = {
    closeDialog: () => void,
    deselectAll: () => void,
    enqueueSnackbar: (message: string, options: Object) => void,
    openDialog: (name: string, props: Object) => void,
    refresh: () => Promise<void>,
};
type Props = OwnProps & StateProps & DispatchProps;

export class ActionToolbar extends Component<Props> {
    constructor(props: Props) {
        super(props);
        this.handleRefresh = debounce(this.handleRefresh, 500);
    }

    handleActionClick = (event: SyntheticEvent<*>) => {
        const { closeDialog, deselectAll, openDialog, refresh, selectedCustomerIds, user } = this.props;
        const dialogProps = {
            customerIds: selectedCustomerIds,
            onClose: () => {
                closeDialog();
            },
            onSubmitSuccess: () => {
                closeDialog();
                refresh();
                deselectAll();
            },
            // @todo orgId is required to bulk update customers but this won't work for PAs
            orgId: user ? user.organization.id : -1,
        };

        openDialog(event.currentTarget.name, dialogProps);
    };

    handleRefresh = () => {
        const { refresh } = this.props;
        refresh();
    };

    renderRecordCount = () => {
        const { classes, recordCount, search, t } = this.props;
        const page = Math.max(parseInt(search.page, 10) - 1, 0) || 0;
        const pageSize = Math.max(parseInt(search.size, 10), 0) || 10;

        const translateOptions = {
            count: recordCount,
            from: recordCount === 0 ? 0 : (page * pageSize) + 1,
            to: Math.min(recordCount, (page + 1) * pageSize),
        };

        return (
            <div className={classes.recordCount}>
                <Trans
                  parent="span"
                  defaults={t('customerList.toolbar.recordCount', translateOptions)}
                  components={[
                      <strong style={{ fontWeight: 600 }}>from</strong>, '-',
                      <strong style={{ fontWeight: 600 }}>to</strong>, 'of',
                      <strong style={{ fontWeight: 600 }}>count</strong>, 'users',
                  ]}
                />
                <IconButton onClick={this.handleRefresh} size="small">
                    <RefreshIcon fontSize="small" />
                </IconButton>
            </div>
        );
    };

    renderSelectedCount() {
        const { selectedCustomerIds, t } = this.props;
        const translateOptions = {
            count: selectedCustomerIds.length,
        };
        return (
            <Trans
              defaults={t('customerList.toolbar.selectedCount', translateOptions)}
              components={[<strong style={{ fontWeight: 600 }}>count</strong>, 'users selected']}
            />
        );
    }

    renderActions = () => {
        const { actions: customerActions, classes, selectedCustomerIds, t } = this.props;
        const buttonProps = {
            classes: {
                root: classes.button,
                disabled: classes.buttonDisabled,
                contained: classes.buttonContained,
                containedPrimary: classes.buttonContainedPrimary,
            },
            variant: 'contained',
            color: 'primary',
            disabled: selectedCustomerIds.length === 0,
            onClick: this.handleActionClick,
        };

        return (
            <div>
                {customerActions.map((action: string) => (
                    <Button {...buttonProps} key={action} name={action}>
                        {t(`customerList.toolbar.${action}`)}
                    </Button>
                ))}
            </div>
        );
    };

    render() {
        const { classes } = this.props;

        return (
            <Toolbar className={classes.root}>
                {this.renderRecordCount()}
                {this.renderSelectedCount()}
                {this.renderActions()}
                <ActivateUsersDialog />
                <DeactivateUsersDialog />
                <DeleteUsersDialog />
                <SuspendUsersDialog />
            </Toolbar>
        );
    }
}

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => ({
    actions: selectors.getCustomerActions(state),
    recordCount: state.customerList.metadata.record_count || 0,
    search: selectors.parseSearchParams(state, props),
    selectedCustomerIds: state.customerList.selected,
    user: state.session.user,
});
const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    closeDialog: () => dispatch(dialog.actions.close()),
    deselectAll: () => dispatch(actions.deselectAll()),
    enqueueSnackbar: (message: string, options: Object) => dispatch(snackbar.actions.enqueue(message, options)),
    openDialog: (name: string, props: Object) => dispatch(dialog.actions.open(name, props)),
    refresh: () => dispatch(actions.refresh()),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
const enhance = compose(
    withStyles(styles),
    withTranslation(),
    connector,
);

export default enhance(ActionToolbar);
