// @flow
// $FlowIssue need to update to a more recent flow version
import React, { Fragment, useCallback, useState } from 'react';
import platform from 'platform';
import Helmet from 'react-helmet';
import * as yup from 'yup';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import type { Dispatch } from 'redux';
import type { Connector } from 'react-redux';
import type { ContextRouter } from 'react-router';
import AcceptInviteForm from './components/AcceptInviteForm';
import MobileRedirect from './components/MobileRedirect';
import actions from './duck/actions';
import selectors from './duck/selectors';
import styles from './styles';
import type { State as RootState } from '../../redux/initialState';

type FormValues = {
    code: string,
    confirmPassword: string,
    email: string,
    firstName: string,
    lastName: string,
    password: string,
    stayUpdated: boolean,
    termsOfService: boolean,
};

type OwnProps = ContextRouter & {};
type StateProps = {
    initialValues: FormValues,
};
type DispatchProps = {
    submit: (values: FormValues) => Promise<void>,
};
type Props = OwnProps & StateProps & DispatchProps;

const logoSrc = process.env.GWP_COMPANY_LOGO || '/public/images/gigwalk-logo.png';
const osFamily = platform.os.family ? platform.os.family.toLowerCase() : '';
const isMobile = osFamily === 'android' || osFamily === 'ios';
const useStyles = makeStyles(styles, { name: 'AcceptInvite' });

const AcceptInviteSchema = yup.object().shape({
    confirmPassword: yup.string()
        .min(6, 'acceptInvite.validation.password.tooShort')
        .oneOf([yup.ref('password')], 'acceptInvite.validation.password.doesNotMatch')
        .required('validation.required'),
    email: yup.string().email().required(),
    firstName: yup.string()
        .required('validation.required'),
    lastName: yup.string()
        .required('validation.required'),
    password: yup.string()
        .min(6, 'acceptInvite.validation.password.tooShort')
        .required('validation.required'),
    stayUpdated: yup.boolean(),
    termsOfService: yup.boolean()
        .oneOf([true], 'acceptInvite.form.errors.acceptTerms'),
});

export function AcceptInvite(props: Props) {
    const { initialValues, location, submit } = props;

    const classes = useStyles(props);
    const { t } = useTranslation();
    const [continueOnWeb, setContinueOnWeb] = useState(false);

    const handleContinueOnWeb = useCallback((event: SyntheticEvent<any>) => {
        event.preventDefault();
        setContinueOnWeb(true);
    }, []);

    return (
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setStatus }) => (
              submit(values)
                  .then(() => setStatus({ success: true }))
                  .catch((error: string) => {
                      setStatus({ error });
                      throw Promise.reject(error);
                  })
          )}
          validationSchema={AcceptInviteSchema}
        >
            {(formikProps: Object) => {
                let mainContent;

                if (isMobile && !continueOnWeb) {
                    const appPath = location.pathname.startsWith('/')
                        ? location.pathname.slice(1)
                        : location.pathname;

                    let appLink = '';
                    if (osFamily === 'android') {
                        appLink = `app://${global.location.hostname}/${appPath}${location.search}`;
                    } else if (osFamily === 'ios') {
                        appLink = `gigwalk://${appPath}${location.search}`;
                    }

                    mainContent = (
                        <Fragment>
                            <MobileRedirect appLink={appLink} />
                            <div className={classes.footer}>
                                <p>{t('mobileRedirect.notAGigwalker')}</p>
                                <a className={classes.link} onClick={handleContinueOnWeb}>
                                    {t('mobileRedirect.continueOnWeb')}
                                </a>
                            </div>
                        </Fragment>
                    );
                } else {
                    mainContent = <AcceptInviteForm {...formikProps} />;
                }

                return (
                    <main>
                        <Helmet title={t('acceptInvite.title')} />
                        <img alt="Gigwalk" className={classes.logo} src={logoSrc} />
                        {mainContent}
                    </main>
                );
            }}
        </Formik>
    );
}

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => ({
    initialValues: selectors.getInitialValues(state, props),
});
const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    submit: (values: Object) => dispatch(actions.submit(values)),
});
const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
export default connector(AcceptInvite);
