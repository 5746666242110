// @flow
const namespace = 'g2/gigwalkerList';

export const TOGGLE_FILTER_DRAWER = `${namespace}/TOGGLE_FILTER_DRAWER`;

export const FETCH_DATA = `${namespace}/FETCH_DATA`;
export const FETCH_DATA_SUCCESS = `${FETCH_DATA}_SUCCESS`;
export const FETCH_DATA_ERROR = `${FETCH_DATA}_ERROR`;

export const LOAD_FILTERS = `${namespace}/LOAD_FILTERS`;
export const LOAD_FILTERS_SUCCESS = `${LOAD_FILTERS}_SUCCESS`;
export const LOAD_FILTERS_ERROR = `${LOAD_FILTERS}_ERROR`;

export const EMAIL_DATA = 'g/actionToolbar/EMAIL_DATA';
export const EMAIL_DATA_SUCCESS = `${EMAIL_DATA}_SUCCESS`;
export const EMAIL_DATA_ERROR = `${EMAIL_DATA}_ERROR`;

export const REFRESH = 'g/actionToolbar/REFRESH';
export const REFRESH_SUCCESS = `${REFRESH}_SUCCESS`;
export const REFRESH_ERROR = `${REFRESH}_ERROR`;

export default {
    EMAIL_DATA,
    EMAIL_DATA_ERROR,
    EMAIL_DATA_SUCCESS,
    FETCH_DATA,
    FETCH_DATA_ERROR,
    FETCH_DATA_SUCCESS,
    LOAD_FILTERS,
    LOAD_FILTERS_ERROR,
    LOAD_FILTERS_SUCCESS,
    REFRESH,
    REFRESH_ERROR,
    REFRESH_SUCCESS,
    TOGGLE_FILTER_DRAWER,
};
